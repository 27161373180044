import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IDistanceOption } from '../../../common/interfaces/iOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-distance-filter',
  templateUrl: './distanceFilter.html'
})
export class DistanceFilterComponent extends BaseComponent {
  filterContent = this.content?.result?.filter;
  distanceOptions = this.filterContent?.distanceOptions;
  selectedDistanceValue: string;

  constructor(
    @Inject(AppSession) private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute
  ) {
    super(route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.selectedDistanceValue = this._appSession.searchParams.distanceNm;
  }

  selectedDistanceOptions(option: IDistanceOption) {
    this.selectedDistanceValue = option.value;
    this._appSession.searchParams.distanceNm = option.value;
  }
}
