import { Component, Input } from '@angular/core';

@Component({
  moduleId: module.id,
  selector: 'app-fc-online-pharmacy-label-cmp',
  templateUrl: './onlinePharmacyLabelCmp.html'
})
export class OnlinePharmacyLabelComponent {
  @Input() title: string;
  @Input() tooltipMsg: string;
  @Input() ariaLabel: string;
}
