import { HttpHeaders } from '@angular/common/http';
import { FindCareWebIntegrationClient } from '../constants/app-constants';
import { IHttpHeader } from '../interfaces/iHttpHeader';
import { AppSession } from '../values/appSession';

export class RequestHederUtil {
  static setFor(url: string, appSession: AppSession, headers: Array<IHttpHeader> = []): HttpHeaders {
    let headerObj = new HttpHeaders();
    const _headerSet = new Set<string>();

    if (Array.isArray(headers) && headers.length > 0) {
      headers.forEach((header) => {
        if (typeof header.value !== 'undefined' && header.value !== null) {
          _headerSet.add(header.name);
          headerObj = headerObj.set(header.name, header.value);
        }
      });
    }

    if (typeof appSession === 'undefined' || appSession === null) {
      return headerObj;
    }

    if (this.isFindCareApiUrl(url)) {
      if (!_headerSet.has('Authorization')) {
        headerObj = headerObj.set('Authorization', `Bearer ${appSession.appState?.opsState?.jwtToken || ''}`);
      }
      if (!_headerSet.has('meta-webview') && appSession.appState?.opsState?.isWebView) {
        headerObj = headerObj.set('meta-webview', appSession.mobileOS || 'yes');
      }
      if (!_headerSet.has('em_mode') && appSession.appState?.opsState?.isEmulation) {
        headerObj = headerObj.set('em_mode', 'yes');
      }
      if (!_headerSet.has('meta-brandcd') && appSession.appState?.opsState?.brandCd) {
        headerObj = headerObj.set('meta-brandcd', appSession.appState?.opsState?.brandCd);
      }
      if (!_headerSet.has('meta-locale') && appSession.metaData?.locale) {
        headerObj = headerObj.set('meta-locale', appSession.metaData?.locale);
      }

      // meta-consumerapp header
      const _client = appSession.isIntegratedMode ? appSession.appState?.opsState?.client || FindCareWebIntegrationClient.SYDNEYWEB : FindCareWebIntegrationClient.FINDCARE;
      headerObj = headerObj.set('meta-consumerapp', _client);

      // personalization or data science - member session id
      if (appSession.appState?.opsState?.dsSessionId) {
        headerObj = headerObj.set('x-correlationid', appSession.appState.opsState.dsSessionId);
      }

      // secure credentials
      if (appSession.appState?.opsState?.encryptedUserName) {
        headerObj = headerObj.set('smuniversalid', appSession.appState.opsState.encryptedUserName);
      }
      if (appSession.appState?.opsState?.encryptedMbrUid) {
        headerObj = headerObj.set('meta-mbruid', appSession.appState.opsState.encryptedMbrUid);
      }

      // TODO: Remove this header after consulting with solution central
      if (appSession.metaData.metaSrcEnv) {
        headerObj = headerObj.set('meta-src-env', appSession.metaData.metaSrcEnv);
      }
    }

    return headerObj;
  }

  private static isFindCareApiUrl(url: string): boolean {
    const urlPattern = /\/(fad|precare)\/api\//gi;
    return urlPattern.test(url);
  }
}
