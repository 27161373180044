import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BRAND_CODE, PLANTYPE_OON } from '../../../common/constants/app-constants';
import { IntentScenario } from '../../../common/enums/intent';
import { IField, IRule } from '../../../common/interfaces/iSearchStrategyResponse';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { SEARCH_IMPROVEMENTS } from '../../../fad/search-results/values/providerSearchConstants';
import { SydneyChatbotEventChannelUtil } from '../../../fad/utilities/sydneyChatbotEventChannelUtil';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { PageAlertHandler } from '../../common/components/shareable/page-alert/service/pageAlertHandler';
import { IResultPageAlertProps } from '../../common/interfaces/iPageAlert';
import { ISearchParameters } from '../../common/interfaces/iSearchParameters';

@Component({
  moduleId: module.id,
  selector: 'app-fc-no-result-cmp',
  templateUrl: './noResultCmp.html'
})
export class NoResultComponent extends BaseComponent implements OnInit {
  @Input() noResultProps: IResultPageAlertProps;
  userIntent: IntentScenario = IntentScenario.IGNORE;
  noResultMessage: string;
  isNeedHelpToShow: boolean = true;

  constructor(
    public _route: ActivatedRoute,
    public _eventHandler: EventHandler,
    private sanitizer: DomSanitizer,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.handleNoResultMessage();
  }

  handleNoResultMessage() {
    const { response, providers, isColdStateEYC, isCptSearch } = this.noResultProps;
    const { isEyc, isSecureState, searchParams, feature, searchTerm } = this._appSession;
    const { NR_EXPANDED_SEARCH, NR_EXPANDED_SEARCH_RESULT, NR_NO_RESULTS, NR_SEARCH_GEORGIA, NR_UMAAS_SEARCH } = this.content.common.noResults;

    if (response?.criteriaChange?.increasedRadius) {
      this.noResultMessage = providers?.length > 0 ? NR_EXPANDED_SEARCH_RESULT : NR_EXPANDED_SEARCH;
    } else if (isEyc && !isSecureState && !isColdStateEYC) {
      if ((response.eycHospitalSearch && response.eycDoctorSearch) || providers?.length === 0) {
        this.noResultMessage = NR_SEARCH_GEORGIA;
      }
    } else if (response?.rules?.length > 0) {
      const improvedSearchCriteria = response.rules.find((rule: IRule) => rule.code === SEARCH_IMPROVEMENTS.IMPROVED_SEARCH_CRITERIA);
      if (improvedSearchCriteria?.fieldList?.length > 0) {
        this.handleImprovedSearchCriteria(improvedSearchCriteria);
      } else if (providers?.length === 0) {
        if (!feature?.isSNYMember && !isCptSearch) {
          this.handleNoProviders(searchTerm, searchParams);
        } else if (isCptSearch) {
          this.needHelpShouldShow();
          this.noResultMessage = NR_NO_RESULTS;
        }
      }
    } else if (providers?.length === 0 && isSecureState && this._appSession.metaData.brandCd === BRAND_CODE.WLP) {
      this.noResultMessage = NR_UMAAS_SEARCH;
    } else {
      this.needHelpShouldShow();
      this.noResultMessage = NR_NO_RESULTS;
    }
    this.noResultMessage = PageAlertHandler.replaceLiteralwithContent(this.noResultMessage, response, this._appSession, this.content);
  }

  handleImprovedSearchCriteria(improvedSearchCriteria: IRule) {
    const { NR_EXPANDED_SEARCH_RESULT, NR_EXPANDED_SPECIALITY_RESULT, NR_CAHMO_INCREASED_SEARCH, NR_CAHMO_SEARCH } = this.content.common.noResults;
    const { searchParams } = this._appSession;

    const subSpecRemoved = improvedSearchCriteria.fieldList.find((field: IField) => field.name === SEARCH_IMPROVEMENTS.REMOVED_SUBSPECIALTIES);
    const increasedRadius = improvedSearchCriteria.fieldList.find((radius: IField) => radius.name === SEARCH_IMPROVEMENTS.INCREASED_RADIUS);
    const removedPrimeGroup = improvedSearchCriteria.fieldList.find((field: IField) => field.name === SEARCH_IMPROVEMENTS.REMOVED_PRIMEGROUP);

    if (removedPrimeGroup?.value.length) {
      this.noResultMessage = increasedRadius.value.length ? NR_CAHMO_INCREASED_SEARCH : NR_CAHMO_SEARCH;
    } else if (increasedRadius?.valueList?.length && searchParams) {
      searchParams.distanceNm = increasedRadius.valueList[0];
      this.noResultMessage = NR_EXPANDED_SEARCH_RESULT;
    }
    if (subSpecRemoved?.valueList?.length > 0) {
      this.noResultMessage = NR_EXPANDED_SPECIALITY_RESULT;
    }
  }

  handleNoProviders(searchTerm: string, searchParams: ISearchParameters) {
    const { NR_EXPANDED_SPECIALITY, NR_EXPANDED_SEARCH: NR_EXPANDED_SEARCH, NR_NO_RESULTS } = this.content.common.noResults;
    const _provName = searchTerm ? searchTerm.trim() : '';
    const _distance = '100';

    if (_provName !== '' && _distance !== searchParams.distanceNm) {
      this.noResultMessage = searchParams.planTypeList?.includes(PLANTYPE_OON) ? NR_EXPANDED_SPECIALITY : NR_EXPANDED_SEARCH;
    } else if (_distance !== searchParams.distanceNm) {
      this.noResultMessage = NR_EXPANDED_SPECIALITY;
    } else {
      this.needHelpShouldShow();
      this.noResultMessage = NR_NO_RESULTS;
    }
  }

  needHelpShouldShow() {
    // if live chat not available then contact us also not required to show
    this.isNeedHelpToShow = this.isLiveChatEnabled;
  }

  navigateToLiveChat() {
    SydneyChatbotEventChannelUtil.publishEventForIntent(this.userIntent, '', this._appSession, this._eventHandler);
  }

  getContactUs() {
    let contactUsMessage = this._appSession.isSecureState ? this.content.common.noResults.contactUsSecure : this.content.common.noResults.contactUs;
    contactUsMessage = contactUsMessage.replace(/{brandUrl}/gi, this._appSession.metaData.brandName);
    return this.sanitizer.bypassSecurityTrustHtml(contactUsMessage);
  }
}
