import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { apiNameList } from '../../../../environments/api-name-list';
import { SEARCH_SCENARIOS, SELECT_DEFAULT_ZERO } from '../../../common/constants/strategy-search-constant';
import { IProviderMiscFilterInRequest } from '../../../common/interfaces/iCommonSearchRequest';
import { IPlanNetworkPrefixContent } from '../../../common/interfaces/iPlanNetworkPrefixContent';
import { ISecurePharmacyEmailRequest } from '../../../common/interfaces/iSecurePharmacyEmailRequest';
import { ISecurePharmacyDemographicFilter, ISecurePharmacyRequest, ISecurePharmacySearchCriteria } from '../../../common/interfaces/iSecurePharmacySearchRequest';
import { ISecurePharmacyResponse } from '../../../common/interfaces/iSecurePharmacySearchResponse';
import { BaseService } from '../../../common/services/baseService';
import { SummaryEmailSvc } from '../../../common/services/summaryEmailSvc';
import { SummaryPrintSvc } from '../../../common/services/summaryPrintSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { SearchResponseUtil } from '../../../fad/utilities/searchResponseUtil';
import { IAddressSummary, IProvider, IProviderList } from '../../result/interfaces/iProvider';
import { SearchFilter } from '../../result/services/searchFilter';
import { PROVIDESFLUSHOTS } from '../constants/providerSearchConstants';
import { HttpClientService } from './../../../common/services/httpClientService';
import { SecureV2RxSpecialty } from './secureRxSvc';

@Injectable({
  providedIn: 'root'
})
export class SecureRxAdapterSvc extends BaseService {
  private pdfPageNumber = 1;
  private pdfPageSize = this.appSession.appConfig?.paginationSize.summaryPrintPage;
  constructor(
    appSession: AppSession,
    private _securePharmacySvc: SecureV2RxSpecialty,
    private _summaryPrintSvc: SummaryPrintSvc,
    private _summaryEmailSvc: SummaryEmailSvc,
    appUtility: AppUtility,
    httpClientSvc: HttpClientService,
    private _searchFilter: SearchFilter
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async search(pageNumber: number, pageSize: number) {
    const transformedRequest = this.transformRequest(pageNumber, pageSize);
    if (transformedRequest.searchCriteria.isInitialSearch) {
      this.appSession.filterSearchParams = this.appSession.searchParams;
    }
    const apiResponse = await this._securePharmacySvc.execute(transformedRequest);
    const transformedResponse = this.transformResponse(apiResponse);
    if ((pageNumber ?? 1) <= 1 && apiResponse?.providers?.length) {
      this._searchFilter.initialize(this.transformSearchFilterRequest(pageNumber, pageSize));
    }
    return transformedResponse;
  }

  print<T extends { summaryComponent: IPlanNetworkPrefixContent }>(content: T) {
    const printRequest: ISecurePharmacyRequest = { ...this.transformRequest(this.pdfPageNumber, this.pdfPageSize) };
    return this._summaryPrintSvc.execute(printRequest, apiNameList.restApi.secureV2RxSpecialty);
  }

  email<T extends { summaryComponent: IPlanNetworkPrefixContent }>(email: string, content: T) {
    const emailRequest: ISecurePharmacyEmailRequest = {
      ...this.transformRequest(this.pdfPageNumber, this.pdfPageSize),
      email
    };
    return this._summaryEmailSvc.execute(emailRequest, apiNameList.restApi.secureV2RxSpecialty);
  }

  transformSearchFilterRequest(pageNumber: number, pageSize: number): ISecurePharmacyRequest {
    const securePharmacyRequest = this.transformRequest(pageNumber, pageSize);
    const filterReq: ISecurePharmacyRequest = {
      ...securePharmacyRequest,
      scenario: SEARCH_SCENARIOS.SECURE_V2_RX_SPECIALTY
    };
    return filterReq;
  }

  transformRequest(pageNumber: number, pageSize: number): ISecurePharmacyRequest {
    const searchParams = this.appSession.searchParams;
    const commonSearchRequest = SearchRequestUtil.buildCommonSearchRequest(this.appSession, pageNumber, pageSize);
    const demographicFilter: ISecurePharmacyDemographicFilter = {
      coverageTypeCode: searchParams?.coverageTypeCode
    };
    const miscFilter: IProviderMiscFilterInRequest = {
      smartSearchRequestId: searchParams?.smartSearchRequestId
    };
    if (searchParams?.languages?.length && !searchParams.languages.includes(SELECT_DEFAULT_ZERO)) {
      demographicFilter.languageCodes = searchParams.languages;
    }
    if (searchParams?.provNm || this.appSession.isMedicalAffSearch || this.appSession.isHospAffSearch) {
      demographicFilter.providerName = this.appSession.isMedicalAffSearch
        ? this.appSession.medicalGrpSearchTerm
        : this.appSession.isHospAffSearch
          ? this.appSession.hospSearchTerm
          : searchParams?.provNm;
    } else {
      if (
        !this.appSession.isSpecialitySearch &&
        !searchParams?.nationalProviderIdentifier &&
        !this.appSession.isEyc &&
        !this.appSession.isAoeSearch &&
        !this.appSession.isOfcSrvcSearch &&
        !this.appSession.isSearchTerm
      ) {
        demographicFilter.providerName = this.appSession.searchTerm;
      }
    }
    demographicFilter.specialtyCategoryCodes =
      searchParams?.specialtySelectNm && searchParams?.specialtySelectNm.length > 0 ? searchParams.specialtySelectNm.filter((x) => x !== SELECT_DEFAULT_ZERO) : [];
    demographicFilter.specialtyCategoryCodes = SearchRequestUtil.getSpecialSpecialties(this.appSession, demographicFilter.specialtyCategoryCodes);

    demographicFilter.taxonomyCodes = searchParams?.taxonomySelectNm || [];
    demographicFilter.typeCodes = searchParams?.providerTypeCodeList?.length ? searchParams?.providerTypeCodeList : [searchParams?.typeSelectNm];
    this.setRXFluShotParams();
    const rxFilter = SearchRequestUtil.buildRXFilterRequest(searchParams);

    const searchCriteria: ISecurePharmacySearchCriteria = {
      brandCode: this.appSession.metaData.brandCd,
      cobrandCode: SearchRequestUtil.setCobrandCode(this.appSession),
      isInitialSearch: this.appSession?.initialSearch,
      demographicFilter,
      rxFilter,
      miscFilter
    };
    const request: ISecurePharmacyRequest = {
      ...commonSearchRequest,
      searchCriteria
    };
    return request;
  }

  transformResponse(apiResponse: ISecurePharmacyResponse): IProviderList {
    let response = {} as IProviderList;
    const finalProviders: IProvider[] = [];

    if (!isEmpty(apiResponse)) {
      if (apiResponse.providers?.length) {
        apiResponse.providers.forEach((provider) => {
          if (provider) {
            const finalProvider = this.transformProvider(provider);
            finalProviders.push(finalProvider);
          }
        });
      }
      response = { ...apiResponse, providers: finalProviders };
    }
    return response;
  }

  private transformProvider(provider: any): IProvider {
    const finalProvider = SearchResponseUtil.getCommonProviderInformation(provider) as IProvider;
    const address = provider?.location?.address;
    finalProvider.addressSummary = this.transformAddress(address);
    return finalProvider;
  }

  private transformAddress(address: any): IAddressSummary {
    return {
      phoneNumberList: [address?.phone],
      addressIdentifier: address?.addressId,
      addressOne: address?.addressOne,
      addressTwo: address?.addressTwo,
      cityName: address?.city,
      stateCode: address?.state,
      postalCode: address?.postalCode,
      latitude: address?.latitude,
      longitude: address?.longitude,
      distance: address?.distance?.toString()
    } as IAddressSummary;
  }

  setRXFluShotParams() {
    if (this.appSession.searchParams.providesFluShots) {
      if (!isEmpty(this.appSession.searchParams.pharmacyFeatures)) {
        this.appSession.searchParams.pharmacyFeatures.push(PROVIDESFLUSHOTS);
      } else {
        this.appSession.searchParams.pharmacyFeatures = [PROVIDESFLUSHOTS];
      }
      this.appSession.searchParams.providesFluShots = false;
    }
  }
}
