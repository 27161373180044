import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { AddressLocationResponse, IAddressSummary } from '../control-list/interfaces/iAffiliationOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-affiliation-locations-cmp',
  templateUrl: './affiliationLocationsCmp.html'
})
export class AffiliationLocationsComponent extends BaseComponent implements OnInit {
  @Input()
  addressLocationResponse: AddressLocationResponse;
  addressLocationList: IAddressSummary[] = [];
  selectedAffiliationName: string;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  formatPhone(phone: string): string {
    return this._phonePatternPipe?.transform(phone);
  }

  ngOnInit(): void {
    this.addressLocationList = this.addressLocationResponse.addressList;
    this.selectedAffiliationName = this.addressLocationResponse?.addressList?.length ? this.addressLocationResponse?.addressList[0]?.affiliationName : '';
  }
}
