import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { AddressLocationResponse } from '../../../../common/components/control-list/interfaces/iAffiliationOption';
import { TIER1 } from '../../../../common/constants/app-constants';
import { SEARCH_SCENARIOS, SELECT_DEFAULT_ZERO } from '../../../../common/constants/strategy-search-constant';
import { ControlType } from '../../../../common/enums/controlType';
import { IBaseCode } from '../../../../common/interfaces/iBaseCode';
import { IPlaceOfServices } from '../../../../common/interfaces/iCptMedicalResponse';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../common/services/eventHandler';
import { SearchSvc } from '../../../../common/services/searchSvc';
import { AppSession } from '../../../../common/values/appSession';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IBaseOption, IOption } from '../../../common/interfaces/iOption';
import { IProviderSearchDdlValues } from '../../../common/interfaces/iProviderDetail';
import { CommonUtility } from '../../../common/utilities/commonUtil';
import { FilterCategory, FilterValue, INDEX_FIFTY, INDEX_ZERO } from '../../constants/result';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';
import { CptSummaryModel } from '../../services/cptSummaryModel';
import { PrimeGroupFilter } from '../../services/primeGroupFilter';
import { IAffiliationOption } from '../control-list/interfaces/iAffiliationOption';
import { ISearchFilterResponse } from './../../../common/interfaces/iSearchFilter';
import { ActionService } from './../../../common/services/actionSvc';
import { SearchFilter } from './../../services/searchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-side-panel-cmp',
  templateUrl: './filterSidePanelCmp.html'
})
export class FilterSidePanelComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('searchResultsFilterModal') searchResultsFilterModal: TemplateRef<HTMLElement>;
  selectedFilterCount: number;
  filterContent = this.content?.result?.filter;
  filterCategory = FilterCategory;
  filterOptions: ISearchFilterResponse;
  appliedFilterItems: IAppliedSearchFilter[] = [];
  selectedPosCode: string = '';
  selectedModifierCode: string = '';
  posList: IPlaceOfServices[] = [];
  posCodes: IBaseOption[] = [];
  modifierCodes: IBaseOption[] = [];
  medicalModifierOptionCode: string;
  medicalPosCode: string;
  showCptFilters = false;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  distanceOptions = this.filterContent?.distanceOptions;
  searchCriteria: ISearchParameters;
  keyFilterPreSelection: string[] = [];
  private searchFilterSubscription: Subscription;
  private appliedFilter: Subscription;
  private primeGroupFilter: Subscription;
  groupAffiliationFilterValue: string = '';
  hospitalAffiliationFilterValue: string = '';
  feature: IRulesResponse;
  ctrlType = ControlType;

  private appliedFiltersSubscription: Subscription;
  hasHealthSystem: boolean;
  resultContent: any;
  primeGroupAffiliationList: IProviderSearchDdlValues;
  noPreference: string;
  primeGroupFilterHeader: string;
  selectedPrimeGroupAffiliation: string;
  private _filterCategory: string;
  addressLocationResponse: AddressLocationResponse;
  showAddressLocations: boolean = false;
  planNetworkOptions: IBaseCode[];
  preSelectedCode: string;
  @Output() resetProviderCard: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(AppSession) private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute,
    public _sidePanel: SidePanel,
    private _searchFilter: SearchFilter,
    private _appliedFilter: AppliedSearchFilter,
    private _actionService: ActionService,
    private _cptSummaryModel: CptSummaryModel,
    private searchService: SearchSvc,
    private _primeGroupFilter: PrimeGroupFilter,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(route, _eventHandler, _appSession);
  }

  /**
   * Returns the text indicating the number of applied filters.
   * @returns A string indicating the number of applied filters.
   */
  get appliedFilterCountText(): string {
    return `${this.selectedFilterCount} ${this.filterContent.filterApplied}`;
  }

  get showYourAffiliationFilter(): boolean {
    return (
      !this.showCptFilters && this.feature?.showMedicalGroupAffiliation && !this._appSession.searchParams?.ableToServePcp && this._appSession.isSecureState && this.primeGroupAffiliationList?.options?.length > 1
    );
  }

  /**
   * Initializes the component and subscribes to necessary observables.
   */
  ngOnInit(): void {
    this.resultContent = this.content.result;
    this.feature = this._appSession.feature;
    this.noPreference = this.resultContent?.filter?.noPreference;
    this.hasHealthSystem = this._appSession.hasHealthSystem;
    this.preSelectedCode = this._appSession?.searchParams?.planTypeList?.[0] ?? 'ALL';

    this.searchFilterSubscription = this._searchFilter.filters.subscribe((filters: ISearchFilterResponse) => {
      if (filters) {
        this.filterOptions = filters;
        this.setKeyFilters(filters);
      }
    });

    this.setBillingCodeFilters();
    this.appliedFilter = this._appliedFilter.getAppliedFilters.subscribe((data) => {
      this.appliedFilterItems = data || [];
      this.selectedFilterCount = this.appliedFilterItems.length || 0;
      const primeGroupFilterCategory = this.appliedFilterItems.find((item) => item.category === FilterCategory.YOURPRIMEGROUP);
      if (primeGroupFilterCategory && this._appSession.searchParams.includePrimeGroupSearch) {
        this.selectedPrimeGroupAffiliation = primeGroupFilterCategory.value;
      } else {
        this.selectedPrimeGroupAffiliation = SELECT_DEFAULT_ZERO;
      }
    });

    this.primeGroupFilter = this._primeGroupFilter.primeGroup.subscribe((data) => {
      if (data) {
        this.primeGroupFilterHeader = this.hasHealthSystem ? this.resultContent.summaryComponent?.filter?.yourHealthSystem : this.resultContent.summaryComponent?.filter?.yourMedicalGroup;
        this.populateMbrPrimeGroupFilters();
      }
    });
  }

  showAffiliationLocations(data): void {
    if (!isEmpty(data)) {
      this.addressLocationResponse = data;
      this.showAddressLocations = true;
    }
  }
  /** Populates the prime group affiliation filters. */
  private populateMbrPrimeGroupFilters(): void {
    const groupAffiliations = this._appSession.primeGroupAffiliations;
    const affiliationList = this.hasHealthSystem ? this.getHealthSystemProviderName(groupAffiliations?.affiliationList) : groupAffiliations?.affiliationList;

    this.primeGroupAffiliationList = CommonUtility.buildDropDownValues(affiliationList, 'cb_result_filter_membermedicalGrp_', this.noPreference, SELECT_DEFAULT_ZERO, 'provider');

    if (this.primeGroupAffiliationList?.options?.length > 1) {
      const primeGroupFilterCategory = this.appliedFilterItems.find((item) => item.category === FilterCategory.YOURPRIMEGROUP);
      let selectedPrimeGroup: IOption = undefined;
      this._appliedFilter.clearFilters(FilterCategory.YOURPRIMEGROUP);

      // If the prime group filter is applied, set the selected prime group to the applied value.
      if (this._appSession.searchParams.includePrimeGroupSearch && this.showYourAffiliationFilter) {
        if (!primeGroupFilterCategory) {
          this.selectedPrimeGroupAffiliation = this.primeGroupAffiliationList.options[1].value;
          selectedPrimeGroup = this.getOptionObject(this.selectedPrimeGroupAffiliation, this.primeGroupAffiliationList.options[1].label, true);
        } else {
          this.selectedPrimeGroupAffiliation = primeGroupFilterCategory?.value;
          selectedPrimeGroup = this.getOptionObject(this.selectedPrimeGroupAffiliation, primeGroupFilterCategory.name, true);
        }
        this._appliedFilter.addFilterOption(FilterCategory.YOURPRIMEGROUP, selectedPrimeGroup, true);
      } else {
        this._appliedFilter.removeInitialAppliedFilterItem(primeGroupFilterCategory);
      }
    }
  }

  /** Returns the health system provider name. */
  private getHealthSystemProviderName(affiliationList: Array<IAffiliationOption>): Array<IAffiliationOption> {
    const healthSystemPrefix = this.resultContent.summaryComponent?.filter?.healthSystem;
    const medicalGroupPrefix = this.resultContent.summaryComponent?.filter?.medicalGroup;

    if (!Array.isArray(affiliationList)) {
      return [];
    }

    return affiliationList.map((provider) => {
      if (this.isEligibleForHealthSystem(provider)) {
        provider.providerName = this.getMbrMedicalGrpPrefix(provider.providerName, healthSystemPrefix, true);
      } else {
        provider.providerName = this.getMbrMedicalGrpPrefix(provider.providerName, medicalGroupPrefix, false);
      }
      return provider;
    });
  }

  /** Returns whether the provider is eligible for the health system. */
  private isEligibleForHealthSystem(provider: IAffiliationOption): boolean {
    return provider?.extendedProviderInfo?.isEligibleForHealthSystem && !!provider?.providerName;
  }

  /** Returns the provider name with the specified prefix. */
  private getMbrMedicalGrpPrefix(providerName: string, prefix: string, toUpperCase: boolean): string {
    if (providerName?.indexOf(prefix) === -1) {
      return toUpperCase ? prefix + providerName?.toUpperCase() : prefix + providerName;
    }
    return providerName;
  }

  /**
   * Event handler for the prime group affiliation change.
   * @param $event The prime group affiliation value.
   */
  onPrimeGroupAffiliationChange($event: string): void {
    this._filterCategory = this.filterCategory.YOURPRIMEGROUP;
    this._appliedFilter.clearFilters(this._filterCategory);
    if ($event !== SELECT_DEFAULT_ZERO) {
      let selectedGroupFilterData = '';
      const selectedAffiliation = this.primeGroupAffiliationList.options.find((option) => option.value === $event);
      if (selectedAffiliation) {
        selectedGroupFilterData = selectedAffiliation.label;
        this._appliedFilter.addFilterOption(this._filterCategory, this.getOptionObject(selectedAffiliation.value, selectedGroupFilterData, true));
      }
    } else {
      // Reset the prime group and health system search flags - applicable if the prime group filter is 'No Preference'
      this._appSession.searchParams.includeHealthSystemSearch = false;
      this._appSession.searchParams.includePrimeGroupSearch = false;
    }
  }

  getOptionObject(value: string, name: string, isChecked: boolean = false): IOption {
    return {
      label: name,
      value: value,
      id: `input-${this._filterCategory?.toLowerCase()}-${value?.toLowerCase()}`,
      name: `input-${this._filterCategory?.toLowerCase()}-${value?.toLowerCase()}`,
      trueValue: value,
      falseValue: '',
      isChecked: isChecked
    } as IOption;
  }

  /**
   * Sets the key filters based on the provided filter response.
   * @param filters - The search filter response containing filter options.
   */
  setKeyFilters(filters: ISearchFilterResponse): void {
    this.filterOptions.keyFilters = [];
    this.keyFilterPreSelection = [];
    this.searchCriteria = this._appSession?.searchParams;

    if (filters) {
      if (filters.ableToServeAsPCP) {
        this.filterOptions.keyFilters.push({
          code: filters.ableToServeAsPCP.code,
          name: filters.ableToServeAsPCP.name,
          description: filters.ableToServeAsPCP.description
        } as IBaseCode);
        if (this.searchCriteria?.ableToServePcp) {
          this.keyFilterPreSelection.push(filters.ableToServeAsPCP.code);
        }
      }

      if (filters.acceptsPatients) {
        this.filterOptions.keyFilters.push({
          code: filters.acceptsPatients.code,
          name: filters.acceptsPatients.name,
          description: filters.acceptsPatients.description
        } as IBaseCode);
        if (this.searchCriteria?.acceptingNewPatients) {
          this.keyFilterPreSelection.push(filters.acceptsPatients.code);
        }
      }

      if (filters.keyRecognitions?.length) {
        filters.keyRecognitions.forEach((recognition) => {
          this.filterOptions.keyFilters.push({
            code: recognition.code,
            name: recognition.name,
            description: recognition.description
          } as IBaseCode);
          const isTier1 = CommonUtility.isKeyTierFilterOption(recognition.code);
          const isRecognized = this.searchCriteria?.recognitionCodes?.includes(recognition.code);
          if ((isTier1 && this.searchCriteria?.providerTier) || (!isTier1 && isRecognized)) {
            this.keyFilterPreSelection.push(recognition.code);
          }
        });
      }
    }
  }

  /**
   * Cleans up subscriptions when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this.searchFilterSubscription) {
      this.searchFilterSubscription.unsubscribe();
    }
    if (this.appliedFilter) {
      this.appliedFilter.unsubscribe();
    }
    this.primeGroupFilter?.unsubscribe();
  }

  /**
   * Opens the side panel in the specified direction.
   * @param direction The direction to open the side panel.
   */
  openSidePanel(direction: any): void {
    this.showAddressLocations = false;
    this.showCptFilters = CommonUtil.isCptSearch(this._appSession);
    this.sidePanelRef = this._sidePanel.open(direction, this.searchResultsFilterModal);
  }

  /**
   * Event handler for the cancel button click.
   * Closes the side panel.
   */
  onCancel(): void {
    this.checkAffiliationFilters();
    this._appliedFilter.resetFilters(this._appliedFilter.initialAppliedFilterItems);
    this.sidePanelRef.close();
  }

  /**
   * Event handler for the All Filters button click.
   * Closes the locations view.
   */
  hideLocations(): void {
    this.showAddressLocations = false;
  }

  /**
   * Calls the undoFilter method on the AppliedSearchFilter service to undo the last applied filter.
   */
  undoFilter(): void {
    this._appliedFilter.undoFilter();
  }

  onApply(): void {
    if (this.searchService.currentScenario !== SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY) {
      this._appSession.searchParams.specialtySelectNm = [];
      this._appSession.searchParams.providerTypeCodeList = [];
    }
    this._appSession.initialSearch = false;
    this._appSession.searchParams.genderCodeList = [];
    this._appSession.searchParams.taxonomySelectNm = [];
    this._appSession.searchParams.typeSelectNm = '';
    this._appSession.searchParams.languages = [];
    this._appSession.searchParams.adaSelectNm = [];
    this._appSession.searchParams.aoeSelectNm = [];
    this._appSession.searchParams.ethnicityList = [];
    this._appSession.searchParams.levelOfCareList = [];
    this._appSession.searchParams.ofcSrvcSelectNm = [];
    this._appSession.searchParams.ofcFeatureList = [];
    this._appSession.medicalPosCode = this.medicalPosCode;
    this._appSession.medicalModifierOptionCode = '';
    this._appSession.searchParams.groupAffiliationSelectNm = '';
    this._appSession.searchParams.hospitalAffiliationSelectNm = '';
    this._appSession.searchParams.patientPreferenceSelectNm = [];
    this._appSession.searchParams.visionSrvcAvailableList = [];
    this._appSession.searchParams.teleHealthInd = false;
    this._appSession.searchParams.extendedHoursOnly = false;
    this._appSession.searchParams.medicaidInd = false;
    this._appSession.searchParams.ableToServePcp = false;
    this._appSession.searchParams.acceptingNewPatients = false;
    this._appSession.searchParams.virtualOnly = false;
    this._appSession.searchParams.boardCertification = false;
    this._appSession.searchParams.recognitionCodes = [];
    this._appSession.searchParams.blueDistinctions = [];
    this._appSession.searchParams.pharmacyLevel = '';
    this._appSession.searchParams.pharmacyFeatures = [];
    this._appSession.searchParams.dispenseTypeCode = [];
    this._appSession.searchParams.providerTier = undefined;
    this._appSession.searchParams.planTypeList = [];
    this._appSession.searchParams.cmeValue = [];
    this._appSession.pageNumber = '';
    this.resetProviderCard.emit(true);

    const filterItems = this._appliedFilter?.mapAppliedFilterItemsByCategory();
    if (filterItems) {
      Object.keys(filterItems).forEach((category) => {
        const values = filterItems[category].map((filter) => filter.value) || [];
        switch (category) {
          case FilterCategory.GENDER:
            this._appSession.searchParams.genderCodeList = values;
            break;
          case FilterCategory.SPECIALTY:
            this._appSession.searchParams.specialtySelectNm = values;
            break;
          case FilterCategory.RECOGNITION:
            this._appSession.searchParams.recognitionCodes = values;
            break;
          case FilterCategory.TAXONOMY:
            this._appSession.searchParams.taxonomySelectNm = values;
            break;
          case FilterCategory.PROVIDER_TYPE:
            this._appSession.searchParams.providerTypeCodeList = values;
            break;
          case FilterCategory.LANGUAGE:
          case FilterCategory.PHARMACY_LANGUAGES:
            this._appSession.searchParams.languages = values;
            break;
          case FilterCategory.ADAACCSIBILE:
            this._appSession.searchParams.adaSelectNm = values;
            break;
          case FilterCategory.AREAOFEXPERTISE:
            this._appSession.searchParams.aoeSelectNm = values;
            break;
          case FilterCategory.ETHNICITY:
            this._appSession.searchParams.ethnicityList = values;
            break;
          case FilterCategory.LEVELOFCARE:
            this._appSession.searchParams.levelOfCareList = values;
            break;
          case FilterCategory.OFFICESERVICES:
            this._appSession.searchParams.ofcSrvcSelectNm = values;
            break;
          case FilterCategory.OFFICEFEATURES:
            this._appSession.searchParams.ofcFeatureList = values;
            break;
          case FilterCategory.PATIENT_POPULATION:
            this._appSession.searchParams.patientPreferenceSelectNm = values;
            break;
          case FilterCategory.SERVICES_AVAILABLE:
            this._appSession.searchParams.visionSrvcAvailableList = values;
            break;
          case FilterCategory.MEDICALGROUP:
            this._appSession.searchParams.groupAffiliationSelectNm = this.groupAffiliationFilterValue;
            break;
          case FilterCategory.YOURPRIMEGROUP:
            this.applyPrimeGroupFilters(values);
            break;
          case FilterCategory.HOSPITALAFFILIATION:
            this._appSession.searchParams.hospitalAffiliationSelectNm = this.hospitalAffiliationFilterValue;
            break;
          case FilterCategory.BLUE_DISTINCTION:
            this._appSession.searchParams.blueDistinctions = values;
            break;
          case FilterCategory.PROFILE:
            this.applyProfileFilters(values);
            break;
          case FilterCategory.KEYFILTERS:
            this.applyKeyFilters(values);
            break;
          case FilterCategory.PHARMACY_FEATURES:
            this._appSession.searchParams.pharmacyFeatures = values;
            break;
          case FilterCategory.PHARMACY_TYPES:
            this._appSession.searchParams.dispenseTypeCode = values;
            break;
          case FilterCategory.PHARMACY_LEVEL:
            this._appSession.searchParams.pharmacyLevel = values.join(', ');
            break;
          case FilterCategory.PLAN_NETWORKS:
            this._appSession.searchParams.planTypeList = values;
            break;
          case FilterCategory.CME_DESIGNATIONS:
            this._appSession.searchParams.cmeValue = values;
            break;
        }
      });
    }
    this._actionService.setSearchClick(false);
    this.checkAffiliationFilters();
    this.sidePanelRef?.close();
  }

  /**
   * Applies the prime group filters to the search parameters based on the provided values.
   * @param values The prime group filter values to apply.
   */
  private applyPrimeGroupFilters(values: string[]) {
    // set the appsession search criteria to include the prime group / health system search
    this._appSession.searchParams.includePrimeGroupSearch = true;
    this._appSession.searchParams.includeHealthSystemSearch = values[0].toLowerCase().includes('healthsystem');
  }

  getModifierCodes(posCd: string): IBaseOption[] {
    const selectedPos = (this.posList || []).find((item) => {
      return item.pos.code === posCd;
    });
    return (selectedPos?.modifierList || []).map((modifierCd) => {
      const modifierCode = {} as IBaseOption;
      modifierCode.label = modifierCode.value = modifierCd?.name?.slice(INDEX_ZERO, INDEX_FIFTY) ?? '';
      modifierCode.id = modifierCd.code;
      return modifierCode;
    });
  }

  onChangePosCodes(event: IBaseOption): void {
    this.selectedPosCode = event.value;
    this.medicalPosCode = event.id;
    this.modifierCodes = this.getModifierCodes(event.id);
    this.medicalModifierOptionCode = this.modifierCodes[this.modifierCodes.length - 1]?.id;
    this.selectedModifierCode = this.modifierCodes[this.modifierCodes.length - 1]?.value;
  }

  onChangeModifierCodes(event: IBaseOption): void {
    this.selectedModifierCode = event.value;
    this.medicalModifierOptionCode = event.id;
  }

  setBillingCodeFilters(): void {
    this._cptSummaryModel.cptSummaryResponse.subscribe((data) => {
      if (data) {
        this.posList = data.posInfos || [];
        this.posCodes = this.posList.map((posCd) => {
          const posCode = {} as IBaseOption;
          posCode.value = posCode.label = posCd?.pos?.name?.slice(INDEX_ZERO, INDEX_FIFTY) ?? '';
          posCode.id = posCd.pos.code;
          return posCode;
        });
        this.modifierCodes = this.getModifierCodes(data.pos?.code);
        this.selectedPosCode = data.pos?.name;
        this._appSession.selectedPosCode = this.selectedPosCode;
        this.selectedModifierCode = data.modifier?.name;
        this._appSession.selectedModifierCode = this.selectedModifierCode;
      }
    });
  }

  clearFilters(): void {
    this._appliedFilter.resetFilters(this._appliedFilter.initialAppliedFilterItems);
    this._appSession.initialSearch = true;
    if (this._appSession.filterSearchParams) {
      this._appSession.searchParams = this._appSession.filterSearchParams;
    }
  }

  updateDeselectionMedical(event: IAffiliationOption) {
    this.groupAffiliationFilterValue = event.providerIdentifier;
  }
  updateDeselectionHospital(event: IAffiliationOption) {
    this.hospitalAffiliationFilterValue = event.providerIdentifier;
  }

  checkAffiliationFilters() {
    if (!this._appSession.searchParams.hospitalAffiliationSelectNm) {
      this._appSession.hospSearchTerm = '';
    }
    if (!this._appSession.searchParams.groupAffiliationSelectNm) {
      this._appSession.medicalGrpSearchTerm = '';
    }
  }

  profileFilterOptions() {
    const optionsMap = [
      { condition: this.feature?.showAcceptMedicaid, option: this.filterOptions?.acceptsMedicaid },
      { condition: this.feature?.showExtendedHoursFilter, option: this.filterOptions?.extendedHours },
      { condition: this.feature?.showTeleHealth, option: this.filterOptions?.teleHealth },
      { condition: this.filterOptions?.virtualOnly, option: this.filterOptions?.virtualOnly },
      { condition: this.filterOptions?.boardCertified, option: this.filterOptions?.boardCertified }
    ];
    return optionsMap.filter((item) => item.condition).map((item) => item.option);
  }

  /**
   * Applies the profile filters to the search parameters based on the provided values.
   * @param values The profile filter values to apply.
   */
  private applyProfileFilters(values: string[]): void {
    if (this._appSession.searchParams) {
      (values || []).forEach((value) => {
        switch (value) {
          case FilterValue.TELEHEALTH:
            this._appSession.searchParams.teleHealthInd = true;
            break;
          case FilterValue.EXTENDED_HOURS:
            this._appSession.searchParams.extendedHoursOnly = true;
            break;
          case FilterValue.MEDICAID:
            this._appSession.searchParams.medicaidInd = true;
            break;
          case FilterValue.VIRTUAL_PROVIDER:
            this._appSession.searchParams.virtualOnly = true;
            break;
          case FilterValue.BOARD_CERTIFIED:
            this._appSession.searchParams.boardCertification = true;
            break;
        }
      });
    }
  }

  /**
   * Applies the key filters to the search parameters based on the provided values.
   * @param values The key filter values to apply.
   */
  private applyKeyFilters(values: string[]): void {
    if (this._appSession.searchParams) {
      (values || []).forEach((value) => {
        if (value === FilterValue.ABLE_TO_SERVE_AS_PCP) {
          this._appSession.searchParams.ableToServePcp = true;
        } else if (value === FilterValue.ACCEPT_PATIENTS) {
          this._appSession.searchParams.acceptingNewPatients = true;
        } else if (CommonUtility.isKeyTierFilterOption(value)) {
          this._appSession.searchParams.providerTier = TIER1;
        }
      });
    }
  }
}
