import { ContentKey } from '../../common/constants/common';

export const routeContentKeys = {
  'FC-HOME': [ContentKey.COMMON, ContentKey.HOME],
  'FC-LANDING': [ContentKey.COMMON, ContentKey.LANDING],
  'FC-RESULT': [ContentKey.COMMON, ContentKey.RESULT],
  'FC-SAV': [ContentKey.COMMON],
  'FC-CARE-NOW': [ContentKey.CARE_NOW],
  'FC-CARE-CIRCLE': [ContentKey.COMMON, ContentKey.CARE_CIRCLE]
};
