import { Injectable } from '@angular/core';
import { IAppMetadata } from '../../common/interfaces/iAppMetadata';
import { IMemberCriteria } from '../../common/interfaces/iCommonSearchRequest';
import { INetworkStatus, ISpecialty } from '../../common/interfaces/iCommonSearchResponse';
import { AppSession } from '../../common/values/appSession';
import { IAddress } from '../provider-details/models/iAddress';
import { IOutputFlags } from '../provider-details/models/iDetailsParameters';
import { IAccreditations, IDetailsResponse, IProviderDetail, IProviderNetworkStatus, ISpecialty as ISpecialties } from '../provider-details/models/iDetailsResponse';
import { IPlanList } from '../provider-details/models/iPlanList';
import { IProviderDetailsRequest } from '../provider-details/models/iProviderDetailsRequest';
import { IAccreditation, ILocation, IProviderDetails, IProviderDetailsResponse } from '../provider-details/models/iProviderDetailsResponse';
import { ISelectedProvider } from '../provider-details/models/iSelectedProvider';
import { ISearchParameters } from '../search-providers/interfaces/iSearchParameters';
import { OUT_OF_NETWORK, PROVIDER_TYPE } from '../search-providers/values/providerSearchConstants';
import { TP_ACCREDITED, TP_NOTACCREDITED, providerOutputFlags } from '../search-results/values/providerSearchConstants';
import { CommonUtil } from './commonUtil';
import { SearchRequestUtil } from './searchRequestUtil';

@Injectable({
  providedIn: 'root',
})
export class ProviderDetailsUtils {

  static getSpecialty(specialty: ISpecialty): ISpecialties[] {
    const specialities: ISpecialties[] = [];
    if (specialty?.taxonomies?.length) {
      specialty.taxonomies.forEach((taxonomy) => {
        if (taxonomy) {
          const mappedSpecialty = {} as ISpecialties;
          mappedSpecialty.specialtyCode = taxonomy.code;
          mappedSpecialty.specialtyCodeName = taxonomy.name;
          mappedSpecialty.boardCertificationCode = taxonomy.boardCertificationCode || '';
          specialities.push(mappedSpecialty);
        }
      });
    }
    return specialities;
  }

  static getAccreditations(accreditation: IAccreditation): IAccreditations {
    const accreditations = {} as IAccreditations;
    if (accreditation) {
      if (Array.isArray(accreditation.approved) && accreditation.approved.length) {
        accreditations.status = TP_ACCREDITED;
        accreditations.body = accreditation.approved.map((accreditationObj) => accreditationObj.name);

      } else if (Array.isArray(accreditation.unapproved) && accreditation.unapproved.length) {
        accreditations.status = TP_NOTACCREDITED;
        accreditations.body = accreditation.unapproved.map((accreditationObj) => accreditationObj.name);
      }
    }
    return accreditations;
  }

  static buildDetailsResponse(apiResponse: IProviderDetailsResponse): IDetailsResponse {
    const response = {} as IDetailsResponse;
    const providerDetail = {} as IProviderDetail;
    if (apiResponse && apiResponse.providerDetail) {
      const _providerDetail: IProviderDetails = apiResponse.providerDetail;
      providerDetail.providerIdentifier = _providerDetail.id;
      providerDetail.providerTypeCode = _providerDetail.providerTypeCode;
      providerDetail.providerTypeCodeList = _providerDetail.typeCodes;
      if (_providerDetail.categoryCodes?.length) {
        providerDetail.providerCategoryCode = { code: _providerDetail.categoryCodes[0] };
      }

      providerDetail.name = {
        firstName: _providerDetail.firstName,
        lastName: _providerDetail.lastName,
        middleName: _providerDetail.middleName,
        providerName: _providerDetail.providerName,
        doingBusinessAs: _providerDetail.doingBusinessAs
      };
      providerDetail.accreditations = ProviderDetailsUtils.getAccreditations(_providerDetail.accreditation);
      providerDetail.gender = _providerDetail.gender;
      providerDetail.specialities = ProviderDetailsUtils.getSpecialty(_providerDetail.specialty);
      providerDetail.educations = _providerDetail.educations;
      providerDetail.pdtKey = _providerDetail.pdtKey;
      providerDetail.ethnicityCodes = _providerDetail.ethnicityCodes;
      providerDetail.areaOfExpertises = _providerDetail.areaOfExpertises;
      providerDetail.essentialProviderPrograms = _providerDetail.essentialProviderPrograms;
      providerDetail.levelOfCares = _providerDetail.levelOfCares;
      providerDetail.patientPopulations = _providerDetail.patientPopulations;
      providerDetail.agePrefLowRange = _providerDetail.agePrefLowRange;
      providerDetail.agePrefHighRange = _providerDetail.agePrefHighRange;
      providerDetail.patientGenderPreference = _providerDetail.patientGenderPreference;
      providerDetail.licenseTypes = _providerDetail.licenseTypes;
      providerDetail.licenseNumbers = _providerDetail.licenseNumbers;
      providerDetail.visibilityRule = _providerDetail.visibilityRule;
      providerDetail.visionServiceList = _providerDetail.visionServiceList;
      providerDetail.isFutureEffective = _providerDetail.isFutureEffective;
      providerDetail.pcp = _providerDetail.pcp;
      providerDetail.pcpInfoList = _providerDetail.pcpInfoList;
      providerDetail.activeFlagList = _providerDetail.activeFlagList;
      providerDetail.isIPA = _providerDetail.isIPA;
      providerDetail.sourceSystemName = _providerDetail.sourceSystemName;
      providerDetail.stateLicense = _providerDetail.stateLicense;

      // Pharmacy details
      providerDetail.dispenseTypeCode = _providerDetail.dispenseTypeCode;
      providerDetail.dispenseTypeDescription = _providerDetail.dispenseTypeDescription;
      providerDetail.pharmacyFeatures = _providerDetail.pharmacyFeatures;
      providerDetail.ttyNumber = _providerDetail.ttyNumber;
      providerDetail.specialtyCategory = _providerDetail.specialty?.specialtyCategories;
      providerDetail.providerTier = _providerDetail.providerTier;
      providerDetail.profilePicUrl = _providerDetail.profilePicUrl;
      providerDetail.biography = _providerDetail.biography;
      providerDetail.visualIndicator = _providerDetail.visualIndicator;
      providerDetail.teleHealthProvider = _providerDetail.teleHealthProvider;

      providerDetail.ihspProvider = _providerDetail.ihspProvider;
      providerDetail.isEssentialProvider = _providerDetail.isEssentialProvider;
      providerDetail.isVirtualProvider = _providerDetail.isVirtualProvider;
      providerDetail.callToAction = _providerDetail.callToAction;
      providerDetail.isShipDirectToDoor = _providerDetail?.isShipDirectToDoor;
      providerDetail.isRegionalProviderIndicator = _providerDetail.isRegionalProviderIndicator;
      providerDetail.isRemoteProviderIndicator = _providerDetail.isRemoteProviderIndicator;
      providerDetail.isOnlineProviderIndicator = _providerDetail.isOnlineProviderIndicator;
      providerDetail.acceptsNewPatients = _providerDetail.anp?.code;
      providerDetail.titleList = _providerDetail?.titleList;

      if (_providerDetail.location) {
        const _location: ILocation = _providerDetail.location;
        providerDetail.adaAccessibilityList = _location.adaAccessibilities;
        providerDetail.anp = _location.anp;
        providerDetail.languages = _location.languages;
        providerDetail.officeFeatureCodeList = _location.officeFeatureCodeList;
        providerDetail.languageAtPractice = _location.languageAtPractice;
        providerDetail.nationalProviderIdentifier = _location.npiIds;
        providerDetail.performance = _location.performance;
        providerDetail.reviewRatings = _location.reviewRatings;
        providerDetail.countiesList = _location.countiesList;
        providerDetail.stateBasedServiceArea = _location.stateBasedServiceArea;
        providerDetail.participatingNetworks = _location.participatingNetworks;
        providerDetail.careTeamProvider = _location.careTeamProvider;
        providerDetail.providerNetworkStatus = ProviderDetailsUtils.buildNetworkStatus(_location.networkStatus);
      }
    }
    response.providerDetail = providerDetail;
    response.ruleList = apiResponse.ruleList;
    return response;
  }

  static buildDetailsRequest(appSession: AppSession, selectedProvider: ISelectedProvider, selectedAddressId: string, defaultAddressId: string): IProviderDetailsRequest {
    const searchParams: ISearchParameters = appSession?.searchParams;
    const metaData: IAppMetadata = appSession?.metaData;
    const detailsRequest: IProviderDetailsRequest = {} as IProviderDetailsRequest;
    if (searchParams) {

      let outputFlags = {} as IOutputFlags;
      if (providerOutputFlags) {
        outputFlags = {
          includeTaxonomy: true,
          includeReviewRatings: providerOutputFlags.reviewRatingsFlag,
          imposeAddressConsolidation: providerOutputFlags.imposeAddressConsolidation,
          includeProviderNetworks: providerOutputFlags.includeProviderNetworks,
          includeAllNetworks: !appSession.isEyc && (appSession.hasStandaloneDentalContract || appSession.hasStandaloneVisionContract)
            && appSession.hasMedicalContracts
        }
      }

      detailsRequest.id = selectedProvider?.providerIdentifier;
      detailsRequest.addressId = !selectedAddressId ? selectedProvider?.location?.address?.addressId : selectedAddressId;
      detailsRequest.distance = searchParams?.distanceNm ? parseInt((searchParams?.distanceNm || '').trim(), 10) : 0;
      detailsRequest.planStateCode = searchParams?.plan?.stateCd || '';
      detailsRequest.specialtyCategories = searchParams?.specialtySelectNm?.length ? searchParams?.specialtySelectNm.filter((x) => x !== '00') : undefined;
      detailsRequest.networks = searchParams.plan?.networkList;
      detailsRequest.alphaPrefix = CommonUtil.getAlphaPrefix(appSession);
      detailsRequest.latitude = searchParams?.coordinates?.latitude?.toString() || '';
      detailsRequest.longitude = searchParams?.coordinates?.longitude?.toString() || '';

      detailsRequest.postalCode = isNaN(Number((searchParams?.zip || '').trim())) ? undefined : (searchParams?.zip || '').trim();
      detailsRequest.city = searchParams.countyCode ? undefined : searchParams.city;
      detailsRequest.procedureCode = searchParams.eycSearchParam?.eycProcedureCriteria?.procedureCode;
      detailsRequest.outputFlags = outputFlags;
      detailsRequest.providerPlanType = selectedProvider.providerPlanType ?? undefined

      if (searchParams.plan && searchParams.plan.identifier) {
        const plan: IPlanList = {
          identifier: searchParams.plan?.identifier ? searchParams?.plan?.identifier.trim() : '',
          isNationalPlan: searchParams?.plan?.isNationalPlan
        };
        detailsRequest.plans = new Array(plan);
      }
      if (selectedProvider?.providerTypeCodeList) {
        detailsRequest.typeCodes = selectedProvider?.providerTypeCodeList;
      } else if (searchParams.providerTypeCodeList) {
        detailsRequest.typeCodes = searchParams?.providerTypeCodeList;
      }

      const memberCriteria = {} as IMemberCriteria;
      if (metaData) {
        detailsRequest.brandCode = metaData.brandCd;
        detailsRequest.locale = metaData.locale && metaData.locale.toString() || '';
        if (metaData.appContract?.mbrUid && !appSession.isPlanUnknown) {
          memberCriteria.mbrUid = metaData.appContract?.mbrUid;
        }
        if (metaData.appContract?.contractUid && !appSession.isPlanUnknown) {
          memberCriteria.contractUid = metaData.appContract?.contractUid;
        }
      }

      if (searchParams?.identificationNumber && !appSession.isPlanUnknown) {
        memberCriteria.identificationNumber = searchParams?.identificationNumber;
      }
      if (CommonUtil.isMemberSecure(appSession)) {
        memberCriteria.isFuturePlan = appSession.isFutureCoverage;
      }
      if (memberCriteria.mbrUid || memberCriteria.identificationNumber) {
        detailsRequest.memberCriteria = memberCriteria;
      }

      // Add aditional parameters for CVS Pharmacy API.
      if ((detailsRequest.typeCodes || []).includes(PROVIDER_TYPE.PHARMACY)) {
        detailsRequest.providerName = selectedProvider?.providerName;
        detailsRequest.pharmacyNumber = selectedProvider?.providerIdentifier;
        detailsRequest.coverageTypeCode = searchParams?.coverageTypeCode;
        detailsRequest.state = searchParams.stateCd?.code;

        const address: IAddress = selectedProvider?.location?.address;
        if (address) {
          if (address.state) {
            detailsRequest.state = address.state;
          }

          if (address.postalCode) {
            detailsRequest.postalCode = address.postalCode;
          }

          if (address.city) {
            detailsRequest.city = address.city;
          }
        }
      }
      detailsRequest.pdtKey = (defaultAddressId === detailsRequest.addressId) ? selectedProvider.pdtKey : undefined;
      detailsRequest.isShipDirectToDoor = appSession?.selectedProvider?.isShipDirectToDoor;
      detailsRequest.coverageType = SearchRequestUtil.isOutOfNetwork(appSession) ? OUT_OF_NETWORK : undefined;
    }
    return detailsRequest;
  }

  static buildNetworkStatus(networkStatus: INetworkStatus): IProviderNetworkStatus {
    const providerNetworkStatus = {} as IProviderNetworkStatus;
    if (networkStatus) {
      providerNetworkStatus.date = networkStatus.date;
      providerNetworkStatus.typeCode = networkStatus.coverages;
      providerNetworkStatus.statusCode = networkStatus.status;
    }
    return providerNetworkStatus;
  }

  /**
   * Checks if the value of a given key (or a subkey) in the args object has changed.
   *
   * @param {unknown} args - The object that contains the values to check.
   * @param {string} key - The key in the args object to check.
   * @param {string} [subKey] - Optional. The subkey in the args object to check.
   * @returns {boolean} - Returns true if the value has changed, false otherwise.
   */
  static hasValueChanged(args: unknown, key: string, subKey?: string): boolean {
    return (
      args?.[key] &&
      args[key].previousValue &&
      args[key].currentValue &&
      args[key].currentValue !== (subKey ? args[key].previousValue[subKey] : args[key].previousValue)
    );
  }
}
