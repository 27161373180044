import { TitleCasePipe } from '@angular/common';
import { isEmpty, merge } from 'lodash';
import { AppConstants, AppErrorMsg, Locale } from '../../common/constants/app-constants';
import { IOfficeFeatureCodeList, IProviderNetworkStatus } from '../provider-details/models/iDetailsResponse';
import { BoardCertificationCode } from '../provider-details/values/boardCertificationCodeConstants';
import {
  CLINICAL_DISCLAIMERS,
  GREEN_MEDICAL_BH,
  INFOLIST_DISCLAIMERS,
  INITIAL_VISIT_COST_MESSAGE,
  MORE_THAN_ONE_VISIT_COST_MESSAGE,
  PROVIDER_TYPE,
  WLP_MEDICAL_BH
} from '../search-providers/values/providerSearchConstants';
import { NORATING } from '../search-results/values/providerSearchConstants';
import { IPrograms } from './../../../app/common/interfaces/iAppMetadata';
import { AppSession } from './../../../app/common/values/appSession';
import { CARE_TYPE, HIGH_RANGE_AGE, LOW_RANGE_AGE } from './../../common/constants/app-constants';
import { IBaseCode } from './../../common/interfaces/iBaseCode';
import { IAlertMessage, IVirtualVisitSSO } from './../../common/interfaces/iMessage';
import { CommonUtil } from './commonUtil';

export class WcsUtil {
  private _content: any;
  private _appSession: AppSession;
  static linkAriaLabel: string = '';
  isMemberSecure: any;

  constructor(content: any, appSession: AppSession) {
    this._content = content;
    this._appSession = appSession;
    WcsUtil.linkAriaLabel = this._content?.searchCriteriaComponent?.labels?.linkAriaLabel;
    if (this._appSession) {
      this.isMemberSecure = CommonUtil.isMemberSecure(this._appSession);
    }
  }

  getAcceptsNewPatientsMsg(code: string) {
    if (!code) {
      return this._content.messages.acceptsPatientsDefaultMsg;
    }

    switch (code.toUpperCase()) {
      case '01':
      case '11':
      case '09':
        return this._content.messages.acceptsPatientsDefaultMsg;
      case '08':
        return this._content.messages.acceptsPatientsFamilyMsg;
      case '12':
        return this._content.messages.notAcceptsPatientsMsg;
      case '13':
        return this._content.messages.acceptsPatientsByReferralMsg;
      case 'INV':
        return this._content.alerts.infoNA;
      case 'YES':
        return this._content.messages.acceptsPatientsYesMsg;
      default:
        return this._content.messages.notAcceptsPatientsMsg;
    }
  }

  getAcceptsMedicaidMsg(code: string) {
    if (!code) {
      return this._content.messages.acceptsMedicaidMsg;
    }
    switch (code) {
      case 'MDCFLG':
        return this._content.messages.acceptsMedicaidYesMsg;
      case 'NMDCFLG':
        return this._content.messages.acceptsMedicaidNoMsg;
      default:
        return this._content.messages.acceptsMedicaidMsg;
    }
  }
  getHelpfulHintscontent(helpfulHintscontent: any, providertype: string) {
    switch (providertype?.toLocaleUpperCase()) {
      case 'P':
        return helpfulHintscontent.P;
      case 'H':
        return helpfulHintscontent.H;
      case 'D':
        return helpfulHintscontent.D;
      case 'V':
        return helpfulHintscontent.V;
      case 'B':
        return helpfulHintscontent.B;
      case 'M':
        return helpfulHintscontent.M;
      case 'G':
        return helpfulHintscontent.G;
      case 'L':
        return helpfulHintscontent.L;
      case 'R':
        return helpfulHintscontent.R;
      case 'U':
        return helpfulHintscontent.U;
      case 'O':
        return helpfulHintscontent.O;
      default:
        return helpfulHintscontent.P;
    }
  }
  getSpanishPlanCategoryName(catCode: string, catName: string) {
    switch (catCode.toLocaleUpperCase()) {
      case 'NET':
        return 'Red médica';
      case 'NON':
        return 'Redes Médicas en Intercambio';
      case 'IND':
        return 'Plan médico (individual y familiar)';
      case 'EMP':
        return 'Plan médico (patrocinado por el empleador)';
      case 'MCR':
        return 'Medicare';
      case 'MCD':
        return 'Medicaid';
      case 'FEP':
        return 'Programa para empleados federales';
      case 'INT':
        return 'Internacional';
      case 'STU':
        return 'Médico (salud estudiantil)';
      case 'DEN':
        return 'Cuidado dental';
      case 'VIS':
        return 'Cuidado de la visión';
      default:
        return catName;
    }
  }
  getSpanishProviderType(code: string, name: string) {
    switch (code.toLocaleUpperCase()) {
      case 'P':
        return 'Médico/Profesional de la salud';
      case 'H':
        return 'Hospitales y centros médicos';
      case 'D':
        return 'Profesional de salud dental';
      case 'V':
        return 'Profesional de la visión';
      case 'R':
        return 'Farmacia';
      case 'B':
        return 'Profesional en salud del comportamiento';
      case 'L':
        return 'Laboratorio/Patología/Radiología';
      case 'M':
        return 'Equipo médico';
      case 'G':
        return 'Grupo médico/Especialidades múltiples';
      case 'C':
        return 'Clínica de salud en un local comercial';
      case 'U':
        return 'Atención urgente';
      case 'O':
        return 'Otros servicios médicos';
      case 'A':
        return 'Profesional de la audición';
      default:
        return name;
    }
  }
  getDefaultText(code: string) {
    switch (code) {
      case 'S':
        return this._content.searchCriteriaComponent.labels.allSpecialtyTxt;
      case 'SS':
        return this._content.searchCriteriaComponent.labels.allSubSpecialtyTxt;
      case 'LOC':
        return this._content.searchCriteriaComponent.labels.allLevelOfCareTxt;
      case 'NP':
        return this._content.searchCriteriaComponent.labels.noPreference;
      default:
        return;
    }
  }
  getSpecialtyMsg(specialty: any) {
    let value = this._content.alerts.infoNA;
    if (specialty && specialty.specialtyCodeName) {
      value = specialty.specialtyCodeName;
      if (specialty.boardCertificationCode) {
        switch (specialty.boardCertificationCode.toUpperCase()) {
          case BoardCertificationCode.BOARD_CERTIFIED:
            return (value += this._content.messages.boardCertifiedMsg);
          case BoardCertificationCode.NOT_BOARD_CERTIFIED:
            return (value += this._content.messages.notBoardCertifiedMsg);
          case BoardCertificationCode.BOARD_CERTIFICATION_NOT_APPLICABLE:
            return (value += this._content.messages.naBoardCertifiedMsg);
          default:
            return (value += specialty.boardCertificationCode);
        }
      }
    }
    return value;
  }

  getOfficeFeature(featureList: Array<IOfficeFeatureCodeList>, featureName: string): string {
    let labelText = this._content.alerts.infoNA;
    if (featureList && featureList.length > 0) {
      const filterdFeature = featureList.filter((x) => x.name === featureName);
      if (filterdFeature && filterdFeature.length > 0 && filterdFeature[0].value !== '') {
        labelText = filterdFeature[0].value;
      }
    }
    return labelText;
  }

  getAgePreference(lowRange: string, highRange: string): string {
    let agePreference = '';
    const lowRangeAge = lowRange !== undefined && lowRange !== '' ? Number(lowRange) : LOW_RANGE_AGE;
    const highRangeAge = highRange !== undefined && highRange !== '' ? Number(highRange) : LOW_RANGE_AGE;
    if (lowRangeAge > highRangeAge) {
      return agePreference;
    }
    if (lowRangeAge > LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
      agePreference = lowRangeAge.toString() + '-' + highRangeAge.toString() + this._content.messages.yearsText;
    } else if (lowRangeAge > LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
      agePreference = lowRangeAge.toString() + ' ' + this._content.messages.yearOlderText;
    } else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
      agePreference = this._content.messages.birthToText + ' ' + highRangeAge.toString() + ' ' + this._content.messages.yearsText;
    } else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
      agePreference = this._content.messages.birthOlderText;
    }
    return agePreference;
  }

  getGenderPreference(genderPreferences: string): string {
    let genderPreference = '';
    if (typeof genderPreferences !== 'undefined' && genderPreferences !== null && genderPreferences.trim() !== '') {
      switch (genderPreferences.toUpperCase()) {
        case 'M':
        case 'MALE':
          genderPreference = this._content.messages.maleOnlyText;
          break;
        case 'F':
        case 'FEMALE':
          genderPreference = this._content.messages.femaleOnlyText;
          break;
        case 'B':
        case 'BOTH':
          genderPreference = this._content.messages.bothText;
          break;
        case 'A':
        case 'ALL':
          genderPreference = this._content.messages.allText;
          break;
      }
    }
    return genderPreference;
  }

  getAffiliationType(affiliationType: string) {
    if (affiliationType && affiliationType !== '') {
      return this._content.compareComponent[affiliationType];
    }

    return affiliationType;
  }
  getGenderText(genderCode: string): string {
    let genderText = '';
    if (genderCode !== undefined && genderCode.trim() !== '') {
      switch (genderCode.toUpperCase()) {
        case 'M':
        case 'MALE':
          genderText = this._content.summaryComponent.genderList.M;
          break;
        case 'F':
        case 'FEMALE':
          genderText = this._content.summaryComponent.genderList.F;
          break;
      }
      return genderText !== '' ? genderText : genderCode;
    }
  }
  getFormattedVisionServices(visionServiceList: any) {
    let availableService = '';
    for (let index = 0; index < visionServiceList.length; index++) {
      availableService = index === 0 ? availableService + visionServiceList[index].name : availableService + ', ' + visionServiceList[index].name;
    }
    return availableService;
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  getNetworkStatusText(providerNetworkStatus: IProviderNetworkStatus, summary: boolean = false): string {
    let networktext = '';
    let typeCode = '';
    let networkDate = '';
    if (providerNetworkStatus?.statusCode) {
      networktext = this._content.summaryComponent.networkStatus[providerNetworkStatus.statusCode];
      networkDate = this.getFormatedDate(providerNetworkStatus.date);
      if (this.isMemberSecure) {
        // get the secured state network status message
        networktext = this._content.summaryComponent.networkStatusSecured[providerNetworkStatus.statusCode];
      }
      if (summary) {
        // if status text is for provider listing page
        networkDate = providerNetworkStatus.date;
        networktext = this._content.summaryComponent.networkStatusSummary[providerNetworkStatus.statusCode];
      }
      if (networktext) {
        if (providerNetworkStatus.typeCode?.length) {
          providerNetworkStatus.typeCode.forEach((typeCd) => {
            // get the corresponding provider type code for each care type
            const providerType = CARE_TYPE.options.find((x) => x.value.toLocaleLowerCase() === typeCd.toLocaleLowerCase());
            // get the corresponding locale string for the providerType.type from wcs
            let typeText = this._content.searchCriteriaComponent.providerTypes[providerType.type];
            if (this._appSession.metaData.locale === Locale.ENGLISH && summary) {
              typeText = this.titleCase(typeText);
            }
            typeCode += providerType?.type ? typeText + ', ' : '';
          });
          // remove the ',' from end of the string
          typeCode = typeCode?.length ? typeCode.substr(0, typeCode.length - 2) : '';
        }
        // replace {PLANTYPE} and {DATE} with the values in Network Status Text
        networktext = networktext.replace(/{PLANTYPE}/gi, typeCode ? typeCode : '');
        networktext = networktext.replace('{DATE}', networkDate);
      }
    }
    return networktext ? networktext : '';
  }

  getFormatedDate(value: string): string {
    // convert date format to "Month, dd, yyyy", eg: 06/22/2022 to June, 22, 2022
    if (typeof value !== 'string' || new Date(value).toString().toLowerCase() === 'invalid date') {
      return '';
    }
    const networkDate = new Date(value);
    const monthNames = this._content.searchCriteriaComponent.monthNames;
    const month = monthNames[networkDate.getMonth()];
    const day = networkDate.getDate();
    const year = networkDate.getFullYear();
    const formattedDate = month + ' ' + day + ', ' + year;
    return formattedDate;
  }

  getUnknownNetworkMsg(providerType: PROVIDER_TYPE): string {
    switch (providerType) {
      case PROVIDER_TYPE.DOCTOR_PROFESSIONAL:
        return this._content.searchCriteriaComponent.alerts.networkUnknown.replace(/{TYPEOFCARE}/g, this._content.searchCriteriaComponent.providerTypes[PROVIDER_TYPE.DOCTOR_PROFESSIONAL]);
      case PROVIDER_TYPE.DENTAL:
        return this._content.searchCriteriaComponent.alerts.networkUnknown.replace(/{TYPEOFCARE}/g, this._content.searchCriteriaComponent.providerTypes[PROVIDER_TYPE.DENTAL]);
      case PROVIDER_TYPE.VISION:
        return this._content.searchCriteriaComponent.alerts.networkUnknown.replace(/{TYPEOFCARE}/g, this._content.searchCriteriaComponent.providerTypes[PROVIDER_TYPE.VISION]);
    }
  }

  decodeHTML(html) {
    const txt = document.createElement('textarea');
    txt.value = html;
    return txt.value;
  }
  isAdministrativeOffice(activeFlagList: Array<string>) {
    if (activeFlagList !== undefined && activeFlagList.length > 0 && activeFlagList.indexOf('Vivity') >= 0) {
      return true;
    }
    return false;
  }

  getPCPDisclaimerText(pcpCount: any): string {
    let disclaimer = '';
    if (this._content.compareComponent.pcpAffDisclaimer) {
      disclaimer = this._content.compareComponent.pcpAffDisclaimer.replace('{COUNT}', pcpCount);
    }
    return disclaimer;
  }

  getStripedHtml(htmlString) {
    return (htmlString || '').replace(/<[^>]+>/g, '');
  }

  getVitalsSsoConfig(professional: any, showAddReviewLabel: boolean = false, useClassForAddReview: boolean = false) {
    const vitalsSsoConfig: any = {
      errorMessage:
        "Sorry, looks like something isn't working. Please refresh the page and try again. \
        If the problem continues, contact  <a href='contact-us' data-analytics='contactUsTasks' target='' \
        id='link1439353740480' style=''>Technical Support</a> .",
      iconAfterClasses: 'fa fa-external-link gray',
      iconBeforeClasses: '',
      linkClasses: '',
      linkText: 'Be First to Review',
      ssoErrorClass: '',
      ssoErrorPageHeader: '',
      ssoErrorRedirectUrl: '',
      ssoErrorType: '2',
      ssoSubType: 'vitalsSSO',
      ssoType: 'sso'
    };

    if (professional && professional.reviewRatings.length > 0) {
      vitalsSsoConfig.linkText = professional.reviewRatings[0].starRating !== NORATING ? this._content.summaryComponent.reviewThisDoctor : this._content.summaryComponent.beFirstReview;
      vitalsSsoConfig.analyticsText =
        professional.reviewRatings[0].starRating !== NORATING ? this._content.summaryComponent.reviewThisDoctorDataAnalytics : this._content.summaryComponent.beFirstReviewDataAnalytics;
    }
    if (showAddReviewLabel) {
      vitalsSsoConfig.linkText = this._content.summaryComponent.addReview;
      vitalsSsoConfig.iconAfterClasses = '';
    }
    if (useClassForAddReview) {
      vitalsSsoConfig.linkClasses = 'link-font go-to-chat';
    }
    return vitalsSsoConfig;
  }

  // Khealth SSO vendor navigation configuration from virtual care hub page
  getVirtualVisitSsoConfig(careType: string): IVirtualVisitSSO {
    const contentContext = this._content.kHealthPopUpCard;
    const virtualVisitSsoConfig: IVirtualVisitSSO = {
      errorMessage: AppErrorMsg.SSO_ERROR_MSG,
      iconAfterClasses: '',
      iconBeforeClasses: '',
      linkClasses: '',
      linkText: contentContext.continueButton,
      ssoErrorClass: '',
      ssoErrorPageHeader: '',
      ssoErrorRedirectUrl: '',
      ssoErrorType: '2',
      ssoSubType: 'kHealthRedirectURL',
      ssoType: 'sso',
      analyticsText: contentContext.analyticTag,
      careType
    };
    return virtualVisitSsoConfig;
  }

  // Find care result & details page vendor sso navigation configuration
  getVirtualCareUrlSsoConfig(ssoKey: string, isDetailsPage?: boolean): IVirtualVisitSSO {
    const contentContext = this._content.summaryComponent;
    const virtualCareURLSsoConfig: IVirtualVisitSSO = {
      errorMessage: AppErrorMsg.SSO_ERROR_MSG,
      iconAfterClasses: '',
      iconBeforeClasses: '',
      linkClasses: '',
      linkText: contentContext.connectNow,
      ssoErrorClass: '',
      ssoErrorPageHeader: '',
      ssoErrorRedirectUrl: '',
      ssoErrorType: '2',
      ssoSubType: 'virtualCareSSOURL',
      ssoType: 'sso',
      analyticsText: isDetailsPage ? 'connectNowBtnDetailFindCare' : 'connectNowBtnResultsFindCare',
      imageSource: isDetailsPage ? 'externalIconInverted.svg' : 'externalIcon.svg',
      ssoKey
    };
    return virtualCareURLSsoConfig;
  }

  // Function to do spanish traslation based on office feature code
  getOfficeService(service): string {
    let labelText = service.name;
    if (service && service.code && this._content.officeService) {
      labelText = this._content.officeService[service.code];
      labelText = labelText && labelText !== '' ? labelText : service.name;
    }
    return labelText;
  }

  getVisionService(service: IBaseCode): string {
    let labelText = service.name;
    if (service && service.code && this._content.visionService) {
      labelText = this._content.visionService[service.code];
      labelText = labelText && labelText !== '' ? labelText : service.name;
    }
    return labelText;
  }

  getOnsiteClinicsText(): string {
    return this._content.summaryComponent.onsiteClinicText;
  }

  /**
   * this method is responsible to add any custom text to info list WCS content
   * @param contentId
   * @param content
   * @param ariaMessage
   */
  getInfoListContentText(contentId: string, content: string, ariaMessage: boolean = false, val?: string | undefined): string {
    if (!contentId) {
      return content;
    }
    if (contentId in CLINICAL_DISCLAIMERS) {
      const clinicalProgram = this._content.searchCriteriaComponent.alerts.clinicalPrograms[contentId];
      const program: IPrograms = ((this._appSession && this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.programs) || []).find(
        (program: IPrograms) => contentId.indexOf(program.programId) > -1
      );

      let clinicalPgmContent: string;

      if (clinicalProgram && program) {
        const programName: string = clinicalProgram.programName || program.programNm;

        if (ariaMessage) {
          clinicalPgmContent = (clinicalProgram.msgText || '').replace(/{PROGRAM_NAME}/gi, programName);
        } else {
          const programLink: string =
            `<a href='javascript:void(0);' id='${clinicalProgram.id}' class='${contentId}' data-analytics='${clinicalProgram.dataAnalytics}' aria-label='${clinicalProgram.ariaLabel}'>${programName}</a>.`.replace(
              /{PROGRAM_NAME}/gi,
              programName
            );
          clinicalPgmContent = (clinicalProgram.msgText || '').replace(/{PROGRAM_NAME}/gi, programLink);
        }
      }

      return clinicalPgmContent || '';
    } else if (contentId === GREEN_MEDICAL_BH || contentId === WLP_MEDICAL_BH) {
      const carelonMedicalBH = this._content.searchCriteriaComponent.labels[contentId];
      const brand = this._appSession.metaData.brandCd;
      let carelonMedicalBHContent = `${carelonMedicalBH.msgText} <a target='_blank' href='{0}'>{1}</a>`
        .replace('{0}', carelonMedicalBH.brandUrls[(brand || '').toLowerCase()])
        .replace('{1}', carelonMedicalBH.linkText);
      return carelonMedicalBHContent;
    } else if (contentId === INITIAL_VISIT_COST_MESSAGE) {
      const { msgText, linkText, linkUrl, ariaLabel } = this._content?.searchCriteriaComponent?.alerts?.costForInitialVisit;
      const benefitLink: string = `<a target='_blank' href='${linkUrl}' id='lnk-find-benefits' aria-label='${ariaLabel}'>${linkText}</a>`;
      return (msgText || '').replace(/{BENEFITS_LINK}/gi, benefitLink);
    } else if (contentId === MORE_THAN_ONE_VISIT_COST_MESSAGE) {
      const { msgText, linkText, linkUrl, ariaLabel } = this._content?.searchCriteriaComponent?.alerts?.costForMoreThanOneVisit;
      const benefitLink: string = `<a target='_blank' href='${linkUrl}' id='lnk-find-benefits' aria-label='${ariaLabel}'>${linkText}</a>`;
      return (msgText || '').replace(/{VISITS_VALUE}/gi, val).replace(/{BENEFITS_LINK}/gi, benefitLink);
    } else if (contentId in INFOLIST_DISCLAIMERS) {
      // common info list disclaimer logic
      const INFOLIST_DISCLAIMER_CONTENT: IAlertMessage[] = this._content.searchCriteriaComponent.alerts.infoListDisclaimers[contentId];
      return INFOLIST_DISCLAIMER_CONTENT ? WcsUtil.getInfoListContent(INFOLIST_DISCLAIMER_CONTENT) : content;
    } else {
      return content;
    }
  }

  /**
   * return alert message text for each brands
   * @param IAlertMessage
   * @returns string
   */
  static getInfoListContent(infoListContent: Array<IAlertMessage>): string {
    let finalInfoMessage = '';
    if (infoListContent && infoListContent.length > 0) {
      infoListContent.sort((contentObj1, contentObj2) => {
        return contentObj1.orderSequence > contentObj2.orderSequence ? 1 : -1;
      });

      let alertInfoMessage = '';
      infoListContent.forEach((alertMessage: IAlertMessage) => {
        const anchorLink = document.createElement('a');
        const textContent = document.createElement('span');
        // settign the anchorLink for banner message
        if (!alertMessage.inAppNavigation && alertMessage.isAnchorText) {
          anchorLink.setAttribute('target', '_blank');
          anchorLink.setAttribute('aria-label', `${WcsUtil.linkAriaLabel} ${alertMessage.linkLabel}`);
        } else if (alertMessage.inAppNavigation && alertMessage.isAnchorText) {
          anchorLink.setAttribute('target', alertMessage.targetAttribute);
          anchorLink.setAttribute('aria-label', alertMessage.linkLabel);
        }
        anchorLink.setAttribute('id', `${alertMessage.id}-anchor`);
        anchorLink.setAttribute('href', alertMessage.navigationUrl);
        anchorLink.innerHTML = alertMessage.linkLabel;
        if (alertMessage.isAnchorText && alertMessage.linkDataAnalyticsTag) {
          anchorLink.setAttribute('data-analytics', alertMessage.linkDataAnalyticsTag);
        }
        if (infoListContent[0].externalIcon) {
          const icon = document.createElement('span');
          icon.setAttribute('class', 'motif-icon motif-external-link info-icon');
          anchorLink.appendChild(icon);
        }
        //setting textContent for benner message
        alertMessage.displayText = alertMessage.displayText.replace(/{linkLabel}/gi, anchorLink.outerHTML);
        textContent.setAttribute('id', alertMessage.id);
        if (alertMessage.ariaLabel) {
          textContent.setAttribute('aria-label', alertMessage.ariaLabel);
        }
        textContent.innerHTML = alertMessage.displayText;
        alertInfoMessage += textContent.outerHTML;
      });
      finalInfoMessage = `<span id="${infoListContent[0].id}" class="pf-pre-wrap pf-inline-span">${alertInfoMessage}</span>`;
    }
    return finalInfoMessage;
  }

  getIhspPreference(ihspPref: boolean): string {
    let ihspPrefs = '';
    if (typeof ihspPref !== 'undefined' && ihspPref !== null) {
      if (ihspPref) {
        ihspPrefs = this._content.messages.acceptsMedicaidYesMsg;
      } else {
        ihspPrefs = this._content.messages.acceptsMedicaidNoMsg;
      }
    } else {
      ihspPrefs = this._content.alerts.infoNA;
    }
    return ihspPrefs;
  }

  /**
   * Retrieves the supporpted brand label for a given brand.
   *
   * @param brand - The brand to retrieve the supported brand label for.
   * @param content - The content object to check for the supported brand label.
   * @returns The supported brand label if found, otherwise an empty string.
   */
  getSupportedBrandLabel(brand: string, content: any): string {
    const supportedBrandLabel: string = AppConstants.BRAND.find((b) => b.brand === brand)?.supportedBrandLabel;
    if (supportedBrandLabel) {
      if (content && content[supportedBrandLabel]) {
        return supportedBrandLabel;
      }
    }
    return '';
  }

  /**
   * Overrides the content of an object with updated content.
   * If both the original content and updated content are not empty, the updated content will be merged into the original content.
   * If either the original content or updated content is empty, the original content will be returned as is.
   *
   * @param originalContent - The original content object.
   * @param updatedContent - The updated content object.
   * @returns The overridden content object.
   */
  overrideTheContent(originalContent: any, updatedContent: any): any {
    if (!isEmpty(originalContent) && !isEmpty(updatedContent)) {
      return merge(originalContent, updatedContent);
    }
    return originalContent;
  }
}
