import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '../../../../../libs/uxd-shared/uxdSharedModule';
import { OnlinePharmacyLabelComponent } from './onlinePharmacyLabelCmp';

@NgModule({
  declarations: [OnlinePharmacyLabelComponent],
  exports: [OnlinePharmacyLabelComponent],
  imports: [UxSharedModule, CommonModule],
  providers: []
})
export class OnlinePharmacyLabelCmpModule {}
