import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DOS } from '../../../fad/search-providers/values/providerSearchConstants';
import { AppEvents } from '../../enums/appEvents';
import { EventHandler } from '../../services/eventHandler';
import { AppSession } from '../../values/appSession';
import { ContentHelper } from '../../values/contentHelper';
import { BaseComponent } from '../base-component/baseCmp';

@Component({
  selector: 'app-denial-of-service-cmp',
  templateUrl: './denialOfServiceCmp.html'
})
export class DenialOfServiceComponent extends BaseComponent implements OnInit {
  private readonly _headerEvent: EventEmitter<any> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  dosEnum: typeof DOS = DOS;
  isEyc: boolean = false;
  parentContainer: HTMLElement;
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _eventHandler: EventHandler,
    @Inject(DOCUMENT) private readonly _document: Document
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'DenialOfServiceComponent');
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.onPreLoad();
    }
    this.isEyc = this._appSession?.isEyc ?? false;
    this.parentContainer = this._document.getElementById('mbr-page-body');
    if (this.parentContainer?.classList.contains('mbr-page-body')) {
      this.parentContainer.classList.remove('mbr-page-body');
    }
  }

  ngAfterViewInit() {
    this.setPageHeader();
  }

  ngOnDestroy() {
    //resetting the flag on component destroy, the flag is used to fetch right maintenance message from WCS
    if (this._appSession?.isEyc) {
      this._appSession.isEyc = false;
    }
    this.parentContainer?.classList.add('mbr-page-body');
  }

  /***
   * set the Page Header and Title dynamically based on pages
   */
  setPageHeader() {
    const pageFadObj = {
      header: this.content?.pageHeader,
      title: this.content?.pageTitle
    };
    this._headerEvent.emit(pageFadObj);
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  get denialOfService(): string {
    return this._appSession.dos;
  }

  decodeHTML(html) {
    return this.WcsUtility.decodeHTML(html);
  }
}
