import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { ICardItem } from '../../../common/interfaces/iProgramCard';
import { CommonUtility } from '../../../common/utilities/commonUtil';

@Component({
  moduleId: module.id,
  selector: 'app-fc-program-card-cmp',
  templateUrl: './programCardCmp.html'
})
export class ProgramCardComponent implements OnInit {
  cardData: ICardItem;
  showTitle: boolean;
  showMsg: boolean;
  showClinicLink: boolean;
  showPhoneLink: boolean;
  showActionLink: boolean;
  isSliderApplicable: boolean;
  phoneNumber: any;

  @Input()
  horizontalView: boolean = false;

  @Output()
  viewMoreEvent: EventEmitter<void> = new EventEmitter();

  @Input()
  set data(val: ICardItem) {
    if (val !== this.cardData) {
      this.cardData = val;
      this.showTitle = val.programName && val.programName != '';
      this.showMsg = val.description && val.description != '';
      this.showClinicLink = val.details.webURL ? true : false;
      this.showPhoneLink = val.details.phone ? true : false;
      this.phoneNumber = this._phonePatternPipe.transform(val?.details?.phone);
    }
  }

  get data(): ICardItem {
    return this.cardData;
  }

  constructor(private _phonePatternPipe: PhonePatternPipe) {}

  ngOnInit() {}

  openCardDetails(card) {
    this.viewMoreEvent.emit(card);
  }

  trimText(value: string, limit: number): string {
    return CommonUtility.getLimitedMessage(value, limit);
  }
}
