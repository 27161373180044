export const CARE_CIRCLE_CONTENT_EN = {
  careTeamHeader:
    "This is where you can find the doctors or other medical professionals you visit the most. We'll add someone to your Care Team after we process a medical, dental, or vision claim. You can add or remove someone from your Care Team at any time.",
  primaryCarePhysician: 'Primary Care Physician',
  getCareNow: 'Get Care Now',
  careCircleOptions: {
    print: 'Print',
    email: 'Email',
    addToCareTeam: 'Add to Care Team'
  },
  careProvidersComponent: {
    labels: {
      mapEndpoint: 'https://www.google.com/maps/dir//',
      onlinePharmcyTooltip: 'Save a trip to the store and enjoy the convenience of home delivery. We will deliver your prescriptions with free standard shipping.',
      ariaLabelForTooltipInfo: 'Select enter for more information about',
      myProviders: 'My Providers',
      onlinePharmacyLabel: 'ONLINE PHARMACY',
      virtualCareOnlyLabel: 'VIRTUAL ONLY',
      sortBy: 'Sort By:',
      sortByFirstName: 'By provider first name',
      sortByLastName: 'By provider last name',
      sortByDateService: 'By most recent date of service',
      sortByNickname: 'By nickname',
      noProviders: 'You currently don’t have any providers on your Care Team.',
      ascDesc: 'Sort Ascending or Descending',
      removeCareTeam: 'Remove From Care Team',
      removeCareTeamAriaLabel: 'Remove {PROVIDER} from care team',
      viewClaims: 'View Claims',
      nickname: 'Nickname',
      saveNickname: 'Save Nickname',
      addNickname: 'Add Nickname',
      addNicknameAriaLabel: 'Add Nickname to {PROVIDER}',
      editNickname: 'Edit Nickname',
      cancelEditNickname: 'Cancel Edit Nickname',
      call: 'Call',
      assignedMembers: 'Assigned Members',
      lastVisit: 'Last visit',
      validNoSpecialChar: "Don't use |;@$%()^-+,<>'\"&*#'",
      address: 'Address',
      selectedPlanMsg: 'Viewing Care Team in <span class="ant-font-b">{PLAN}</span>',
      selectedPlan: 'Viewing Care Team in <span>{PLAN}</span>',
      selectedMemberPlanMsg: 'Viewing Care Team for <span class="ant-font-b">{NAME}</span> in <span class="ant-font-b">{PLAN}</span>',
      selectedMemberPlan: 'Viewing Care Team for <span>{NAME}</span> in <span>{PLAN}</span>',
      lnkAssignPcp: 'Assign as PCP',
      lnkAssignPcpAriaLabel: 'Assign {PROVIDER} as PCP',
      lnkRemovePcp: 'Remove PCP',
      lnkRemovePcpAriaLabel: 'Remove {PROVIDER} as PCP',
      lnkLabelPcp: 'Label as PCP',
      lnkRemoveLabelPcp: 'Remove PCP Label',
      lblPCP: 'PCP',
      lblPCPId: 'PCP ID',
      recentClaim: 'Recent Claim',
      getDirection: 'Get Directions',
      getDirectionAriaLabel: 'Get directions for {PROVIDER}',
      providerName: 'Provider Name',
      navigationInfo: 'Click here to navigate to claims page',
      back: 'Back to Find Care',
      backAriaLabel: 'Back button, Select here to go back to search criteria page',
      careTeamFor: 'Care Team for {NAME}',
      removeProvider: 'Remove Care Team provider',
      removeProviderConfirm: 'Are you ready to remove <strong>{NAME}</strong> provider?',
      yes: 'Yes',
      no: 'No',
      addCareTeam: 'Add to Care Team',
      addCareAriaLabel: 'Add to Care Team button, Select here to go back to search criteria page',
      emailModalHeader: 'Email Care Team',
      removeCareProvider: 'Remove From Care Team?',
      removeCareProviderConfirm: 'Are you sure you want to remove <strong>{NAME}</strong> from the Care Team of <strong>{MEMBER}</strong>? (You always can add again if you change your mind.)',
      cancel: 'Cancel',
      remove: 'Remove',
      pcpStartDateLabel: 'PCP starting',
      pcpProviderToolTip: 'We are in the process of assigning {DOCTOR NAME} as Primary Care Physician (PCP) for {MEMBER NAME}. Please note, this change won’t take effect until {EFFECTIVE DATE}.',
      selectedPCP: 'Selected PCP',
      subscriber: 'subscriber',
      dependent: 'dependent',
      spouse: 'spouse',
      getCareFrom: 'Get care from',
      healthSystemName: 'Health System Name: ',
      callForNearestLocation: 'Call For Nearest Location',
      getCareTeam: 'Obtener atención de',
      pcp: 'Assigned as PCP',
      careTeamHeader:
        "This is where you can find the doctors or other medical professionals you visit the most. We'll add someone to your Care Team after we process a medical, dental, or vision claim. You can add or remove someone from your Care Team at any time.",
      networkNotInYourPlan: "Not in your plan's network.",
      yourPrimaryCarePhysician: 'Your Primary Care Physician:',
      noPCPAssigned: 'No PCP Assigned',
      pcpDescription: 'Your PCP is a key member of your Care Team and can act as a care coordinator and provide regular care and routine screenings. You can change your PCP at any time.',
      primaryCarePhysician: 'Primary Care Physician',
      planAssignsText: 'Your plan assigns a',
      planRequiresText: 'Your plan requires that you choose a',
      optionText: 'You have the option to assign a',
      coverageText: 'to you when your coverage begins.',
      pcpSearchBtnLabel: 'Search for a New PCP',
      closeModal: 'button, select to close the modal',
      whyImportantPCP: 'Why is it important to have a PCP',
      whyImportantPCPContentFirstLine: 'You’re in charge of your health. But you need a go-to expert — so choose a PCP.',
      whyImportantPCPContent:
        'Choosing a primary care physician (PCP) is an important personal decision. You don’t want to just choose a random name from a quick web search. Finding someone you can relate to, feel comfortable with and trust with private matters is a big deal. And when you find the right PCP, that doctor can help make a difference in your health',
      pcpImpLabel: '<b>Why a PCP is important</b>',
      pcpBulletPoint: [
        '<b>Your PCP gets to know the “overall you,” not just bits and pieces.</b> Your doctor will have a fuller picture, with your health history and habits, and can more easily see changes and recommend action to avoid more serious problems later.',
        '<b>You have a starting point.</b> If you’re not sure who to see for a certain problem, if you have a question about different prescriptions, or if you’re worried about what a specialist has suggested, talk to your PCP.',
        '<b>You may be able to save time and money.</b> A PCP helps you stay healthy and can help you take care of ongoing health problems. This can mean lower health care costs, fewer sick days and better access to specialized care when you do need it.'
      ],
      searchForPCPNow: 'Search for a PCP now'
    }
  },
  founderPanel: {
    closeModal: 'Close',
    founderName: 'Get care from ',
    patientPortal: 'Patient Portal',
    memberServices: 'Member services : ',
    faq: 'FAQ',
    contactUs: 'Contact Us',
    virtualCare: 'Virtual Care',
    symptomCheckerLabel: 'Symptom Checker',
    symptomCheckerContent: 'Use our system checker to help guide to the right level of care.',
    nurselineLabel: '24/7 Nurseline',
    nurselineContent: 'Registered nurses are available by phone for support and answers to health questions.',
    virtualHealthVisitLabel: 'Virtual Health Visit',
    virtualHealthVisitContent: 'Get advice and treatment options from the comfort of your home.',
    inPersonSpecialtyCare: 'In-Person Specialty Care',
    behaviroalHealthLabel: 'Behavioral Health',
    behaviroalHealthDesc: 'Get care from a therapist or psychiatrist in the Anthem Network. They may provide online or in-person care. No referral or preapproval is needed.',
    urgentCare: 'Urgent Care',
    featuredUrgentCareLabel: 'Featured Urgent Care',
    featuredUrgentCareContent: 'Clinics that are connected to your medical group.',
    additionalVivityUrgentCare: 'Additional Vivity Urgent Care',
    alertText: 'Call 911 or go to the Emergency Room (ER) if you think you are having a real emergency that could cause serious health risk by delaying.',
    founderLogoAlt: 'Founder Logo Image'
  },
  advocatesList: {
    familyAdvocate: 'FAMILY ADVOCATE',
    requestCallBack: 'Request a Callback',
    chatWithAdv: 'Chat With Advocate',
    familyClinicalAdv: 'FAMILY CLINICAL ADVOCATE',
    advDetails: 'Your Family Advocate will help simplify getting the best care possible.',
    mgrDetails: 'Your Clinical Family Advocate will work with your doctors on a treatment plan.',
    learnMore: 'Learn more',
    extension: 'ext',
    phone: 'Phone',
    idCardDetails: 'To connect with your advocate, call the Member Services number on ',
    serviceNoDetails: 'To connect with your advocate, contact Member Services at: ',
    yourIdCard: 'your ID card.'
  },
  advocateDetails: {
    familyAdv: 'FAMILY ADVOCATE',
    familyClinicalAdv: 'FAMILY CLINICAL ADVOCATE',
    detail: 'Details',
    about: 'About',
    phone: 'Phone(main)',
    language: 'Language',
    hours: 'Hours',
    specialties: 'Specialties',
    whatFamilyAdvocateDoes: 'What is a Family Advocate?',
    whatFamilyClinicianDoes: 'What is a Clinical Family Advocate?',
    familyAdvRespties:
      '<p>A Family Advocate was assigned to help with your unique health needs.<br/> Your Advocate will be the single point of contact for your health insurance <br/>and health care needs. </p>',
    familyClAdvRespb: '<p>A Clinical Family Advocate was assigned to help with your unique health needs.</p>',
    familyAdvList:
      '<p>The Family Advocate can: <ul><li>Find Doctors who are a great fit</li><li>Schedule appointments</li><li>Find health programs offered by your benefits</li><li>Work with your care team to manage conditions</li><li>Simplify access to preventive care</li><li>Help resolve transportation and financial barriers to care</li></ul></p>',
    familyClAdvList:
      "<p>A Clinical Family Advocate can:<ul><li>Help you manage conditions with one-on-one education and support</li><li>Work with your doctors on treatment plans & health goals</li><li>Help you when you're in the hospital or suddenly sick</li><li>Enlist family and friends to help you get and stay healthy</li><li>Help remove mental, physical, financial, and emotional barriers to care</li></ul></p>",
    advConcl: '<p>Your Family Advocate will be there for you if you have a new health issue <br/> or have to spend time in the hospital.</p>',
    familyAdvoutOfPkt: '<p>There are no out-of-pocket costs with a Family Advocate. </p>',
    familyClAdvoutOfPkt: '<p>There are no out-of-pocket costs with a Clinical Family Advocate. </p>',
    close: 'Close',
    extension: 'ext'
  },
  removePcp: {
    labels: {
      printPdf: 'Print page details',
      emailpdf: 'Email page details',
      emaillbl: "Enter Recipient's e-mail Address:",
      defaultEmailText: 'Enter email address',
      closeModal: ' button, select to close the modal',
      sendEmailModal: ' button, select to send the email',
      lblToAssignPcp: 'To whom would you like to assign this PCP?',
      lblToAssignPcp_v1: 'Assign as Primary Care Physician (PCP)',
      lblToSelectPcp: 'Select a Primary care physician for change',
      lblNotAcceptingNewPatients: 'Not Accepting New Patients',
      lblToSelectPcp_v1: 'PCP ID',
      lblToRemovePcp: 'Remove Primary Care Physician (PCP)',
      lblPcpMemberHeader: 'For this member',
      lblCurrentPCP: 'Current PCP:',
      lblPcpID: 'ID: ',
      lblNotAcceptingNewPatientsDesc:
        "Our system is telling us that {providerName} is not accepting new patients. If you are an existing patient, or if you think this information is wrong, please contact <a target='_blank' href='/member/contact-us' style='text-decoration:none'> Member Services</a>.",
      lblNotAcceptingNewPatientsDesc2: 'You can filter your search results to ',
      lblNotAcceptingNewPatientsDesc3: 'only show care providers who are accepting new patients.',
      lblToRemovePcpDesc: 'Are you ready to remove this care provider as your PCP? (You always can add again if you change your mind.)',
      lblToSelectMultiplePcp: "This Primary Care Physician (PCP) has more than one PCP ID. Please contact the doctor's office if you need help choosing which PCP ID to select.",
      lblSelectReasonPcp: 'Select a reason',
      lblSelectAny: 'Select any',
      lblAssignPCPSuccessMsg: 'You have successfully assigned the <b>{providerName}</b> as <b>{memberName}</b> PCP.',
      lblEmpPcpHeading: 'Assign as Primary Care Physician (PCP)',
      lblEmpPcpContent:
        "<strong>PCP ID</strong></br>If this Primary Care Physician (PCP) has more than one PCP ID, you may need to contact the doctor's office for help choosing which PCP ID to select.",
      btnAssignPcp: 'Assign as PCP',
      btnRemovePcp: 'Remove PCP',
      btnCompletePcp: 'Complete',
      btnCancel: 'Cancel',
      btnClose: 'Close',
      btnSent: 'Send',
      btnOk: 'OK',
      changePlanPublic: 'Change Plan',
      changePlanSecure: 'Change Plan or Member',
      changePlanPublicMsg: 'Change Plan Networks',
      changePlanSecureMsg: 'Change Member and Plan Networks',
      print: 'Print',
      email: 'Email',
      showFilter: 'See Filters',
      hideFilter: 'Hide Filters',
      showHints: 'Show FAQs',
      hideHints: 'Hide FAQs',
      careLabel: 'What type of care are you searching for?',
      planLabel: 'Select Plan',
      planLabelMsg: 'Which plan Network?',
      ariaCare: 'you can arrow down to make select or type in type to resort the type of Care',
      ariaPlan: 'you can arrow down to make select or type in type to resort the plan',
      ariaMember: 'you can arrow down to make select or type in type to resort the member',
      memberLabel: 'Who is this care for?',
      btnSave: 'Save',
      saveContactInfo: 'Save Contact Info',
      addCareTeam: 'Add to Care Team',
      addCareTeamHeader: 'Whose care team should we add this to?',
      addWhoseCareTeamHeader: 'Add to whose Care Team?',
      addCareProviderHeader: 'Add to Care Team',
      ariaLabelForAddCareTeam: 'Add to Care Team button add {PROVIDER} to {MEMBER} Care Team',
      addCareProviderContent:
        "Are you sure you want to add <strong class='text-uppercase'>{PROVIDER}</strong> to the Care Team of <strong>{MEMBER}</strong>? (You always can remove if you change your mind.)",
      TP_AddCareTeamSuccess:
        "You have successfully added <strong class='text-uppercase'>{PROVIDER}</strong> to <strong>{MEMBER}</strong> Care Team. <a type='button' id='lnk-careteam' class='addcareteam pf-button' aria-label='click here to navigate to Care team page'>Click here</a> to see \"My Care Team\" now.",
      TP_AddCareTeamSuccessMsg:
        "You have successfully added <strong class='text-uppercase'>{PROVIDER}</strong> to the <a type='button' id='lnk-careteam' class='addcareteam pf-button' aria-label='click here to navigate to Care team page'>Care Team of <strong class='addcareteam pf-button'>{MEMBER}</strong></a>.",
      TP_AddCareTeamFailureMsg:
        "There was a problem and we weren't able to add <strong class='text-uppercase'>{PROVIDER}</strong> to the Care Team of <strong>{MEMBER}</strong>. Please try again later.",
      TP_RemoveCareTeamSuccessMsg: "You have successfully removed <strong class='text-uppercase'>{PROVIDER}</strong> from the Care Team of <strong>{MEMBER}</strong>.",
      TP_RemoveCareTeamFailureMsg:
        "There was a problem and we weren't able to remove <strong class='text-uppercase'>{PROVIDER}</strong> from the Care Team of <strong>{MEMBER}</strong>. Please try again later.",
      planStart: 'Starting',
      ariaLabelForEmail: 'Send results to email',
      dismiss: 'Dismiss alert',
      moreOptionsLabel: 'More Options',
      changeMember: 'Change Member',
      careTeamPlansMsg: 'Viewing Care Team for <span class="ant-font-b">{NAME}</span> in',
      careTeamPlans: 'Viewing Care Team for <span>{NAME}</span> in',
      selectedMemberPlansMsg: 'Finding Care for <span class="ant-font-b">{NAME}</span> in',
      selectedMemberPlans: "Finding Care in All of <span>{NAME}</span>'s",
      planNetworks: 'Plan Networks',
      planNetwork: 'Plan Network',
      planNetworksToutHeader: 'The following plan networks are displayed in the search results.',
      AddPcpLabel: 'Label as Primary Care Physician (PCP)',
      labelAsPcpDesc: 'Although your plan does not require you to choose a Primary Care Physician, you can add this label to help keep track of your primary doctor.',
      CurrentLabeledPCP: 'Current Labeled PCP',
      labelAsPCP: 'Label as PCP',
      removeLabelPCPDesc: 'Are you ready to remove this care provider as your labeled PCP? (You always can add again if you change your mind.)',
      removePCPLabel: 'Remove PCP Label',
      allYour: 'All Your',
      backLabel: 'Back',
      backAriaLabel: 'Back button, Select here to go back to previous page'
    }
  }
};
export type CareCircleContent = typeof CARE_CIRCLE_CONTENT_EN;
