import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../../environments/api-name-list';
import { HttpClientService } from '../../../../common/services/httpClientService';
import { AppUtility } from '../../../../common/utilities/appUtil';
import { AppSession } from '../../../../common/values/appSession';
import { FindCareWebIntegrationClient } from '../../../constants/app-constants';
import { HttpMethod } from '../../../enums/httpMethodEnum';
import { IAppContract } from '../../../interfaces/iAppMetadata';
import { BaseService } from '../../../services/baseService';
import { IMemberSummaryRequest } from '../interfaces/iMemberSummary';
import { IMetadata } from '../models/iMetadata';

@Injectable()
export class MemberSummaryService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async fetchMetadata(userNm: string): Promise<IMetadata> {
    return this.httpClientSvc.request({
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.getAuthMetaData,
      urlParams: [
        {
          name: 'userName',
          value: userNm,
          isQueryParam: true
        }
      ]
    });
  }

  getMemberDetails(reqObj: IMetadata, pfEmMode: boolean, client?: string, isHcidSearch?: boolean): Promise<any> {
    const header = this.getHeaders();

    if (pfEmMode === true) {
      header.push({
        name: 'pf-em-user',
        value: 'emulate'
      });
    }

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.getURLBasedOnClient(client, isHcidSearch),
      data: reqObj,
      headers: header
    });
  }

  getPublicSummary(request: IMemberSummaryRequest, pfEmMode: boolean): Promise<IAppContract> {
    const headers = this.getHeaders();

    if (pfEmMode === true) {
      headers.push({
        name: 'pf-em-user',
        value: 'emulate'
      });
    }

    return this.httpClientSvc.request({
      cancellable: true,
      data: request,
      headers,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.publicSummary
    });
  }

  getMultiMemberDetails(reqObj: any, pfEmMode: boolean): Promise<any> {
    const header = this.getHeaders();

    if (pfEmMode === true) {
      header.push({
        name: 'pf-em-user',
        value: 'emulate'
      });
    }

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.multiMemberContract,
      data: reqObj,
      headers: header
    });
  }

  private getURLBasedOnClient(client: string, isHcidSearch?: boolean): string {
    let url: string;
    if (client === FindCareWebIntegrationClient.SYDNEYMEDICAID) {
      url = isHcidSearch ? apiNameList.restApi.medicaidPublicSummary : apiNameList.restApi.medicaidSecureSummary;
    } else {
      url = apiNameList.restApi.secureSummary;
    }
    return this.baseURL + url;
  }
}
