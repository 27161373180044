import { CareNowContent } from './careNowContent';

export const CARE_NOW_CONTENT_ES: CareNowContent = {
  careNowCards: [
    {
      id: 'talkToNurse247',
      title: 'Hable con un enfermero 24/7',
      descriptionOne:
        'Su plan incluye la línea de enfermería 24 horas sin gastos de bolsillo para usted. El personal de enfermería están allí las 24 horas del día. Pueden ayudarlo a determinar qué hacer o adónde ir cuando necesite cuidado.',
      descriptionTwo: '',
      goodChoiceFor: '',
      costLabel: '',
      dollarSign: '',
      averageWaitLabel: '',
      time: '',
      buttonLabel: 'Línea de enfermería 24 horas',
      analyticTag: '24HrsNurseLineGcnFindCare',
      expandedLabel: 'Detalles de la línea de enfermería ampliados',
      collapsedLabel: 'Detalles de Línea de Enfermería ampliados',
      buttonAriaLabel: 'Ir a Encontrar cuidado médico para encontrar una línea de enfermería'
    },
    {
      id: 'virtualCare',
      title: 'Cuidado médico virtual',
      descriptionOne:
        'Consulte a un médico, enfermero o terapeuta sin salir de su casa. El cuidado médico virtual también es una excelente manera de visitar a un proveedor de salud mental. Los proveedores de cuidado médico virtual suelen estar disponibles las 24 horas, los 7 días de la semana, sin necesidad de cita previa.',
      descriptionTwo:
        'síntomas similares a los de la gripe, alergias, ansiedad, depresión, fiebre, sinusitis, diarrea, infección de los ojos, infección de la piel, sarpullido, infección del tracto urinario (ITU).',
      goodChoiceFor: 'Una buena opción para: ',
      costLabel: 'costo<sup>6</sup>',
      dollarSign: '$',
      averageWaitLabel: 'tiempo de espera promedio<sup>2</sup>',
      time: '10 minutos',
      buttonLabel: 'Encuentre un proveedor de cuidado médico virtual',
      analyticTag: 'findVirtualCareProvGcnFindCare',
      expandedLabel: 'Detalles de cuidado médico virtual ampliados',
      collapsedLabel: 'Detalles de cuidado médico virtual contraídos',
      buttonAriaLabel: 'Ir a Encontrar cuidado médico para encontrar un proveedor de cuidado médico virtual'
    },
    {
      id: 'retailHealthClinic',
      title: 'Clínica de salud minorista ',
      descriptionOne:
        'Visite su clínica minorista local para recibir vacunas contra la gripe o ayuda con sarpullidos leves, fiebre o resfriados. Las clínicas de atención sin cita previa se encuentran en ciertas farmacias y en las principales tiendas minoristas.',
      descriptionTwo: 'dolor de garganta, dolor de oído, golpes, cortes y raspones menores, infección del tracto urinario.',
      goodChoiceFor: 'Una buena opción para: ',
      costLabel: 'costo',
      dollarSign: '$$',
      averageWaitLabel: 'tiempo de espera promedio<sup>3</sup>',
      time: '30 minutos',
      buttonLabel: 'Encuentre una clínica de salud minorista',
      analyticTag: 'findRetailHealthClinicGcnFindCare',
      expandedLabel: 'Detalles de la clínica de salud minorista ampliados',
      collapsedLabel: 'Detalles de la clínica de salud minorista contraídos',
      buttonAriaLabel: 'Ir a Encontrar cuidado médico para encontrar una clínica de salud minorista'
    },
    {
      id: 'urgentCareCenter',
      title: 'Centro de atención de urgencias ',
      descriptionOne:
        'Hay centros de atención de urgencias disponibles en muchas áreas a todas horas del día y de la noche. Los médicos y las enfermeras pueden ayudar con atención que no pone en peligro la vida, pero que se necesita con urgencia rápidamente. Por lo general, son centros independientes con horarios extendidos.',
      descriptionTwo:
        'esguinces y distensiones, náuseas o diarrea, dolor de oído o sinusitis, reacciones alérgicas menores, tos, dolor de garganta, dolor de cabeza leve, infección del tracto urinario.',
      goodChoiceFor: 'Una buena opción para: ',
      costLabel: 'costo',
      dollarSign: '$$$',
      averageWaitLabel: 'tiempo de espera promedio<sup>4</sup>',
      time: '30 minutos',
      buttonLabel: 'Encuentre un centro de atención de urgencia',
      analyticTag: 'findUrgentCareCenterGcnFindCare',
      expandedLabel: 'Detalles del centro de atención de urgencias ampliados',
      collapsedLabel: 'Detalles del centro de atención de urgencias contraídos',
      buttonAriaLabel: 'Ir a Encontrar cuidado médico para encontrar un centro de atención de urgencia'
    },
    {
      id: 'emergencyRoom',
      title: 'Sala de emergencias ',
      descriptionOne: 'Siempre llame al 911 o vaya a la sala de emergencias si cree que está teniendo una emergencia o si cree que podría poner en riesgo grave su salud al retrasar la atención.',
      descriptionTwo:
        'signos de un ataque al corazón (dolor en el pecho), accidente cerebrovascular (debilidad repentina, entumecimiento y dificultad para hablar), dificultad para respirar, sangrado, quemaduras graves o cualquier otro síntoma que pueda requerir atención de emergencia.',
      goodChoiceFor: 'Una buena opción para: ',
      costLabel: 'costo',
      dollarSign: '$$$$',
      averageWaitLabel: 'tiempo de espera promedio<sup>5</sup>',
      time: '90 minutos',
      buttonLabel: 'Encuentre una sala de emergencias',
      analyticTag: 'findEmergencyRoomGcnFindCare',
      expandedLabel: 'Detalles de la sala de emergencias ampliados',
      collapsedLabel: 'Detalles de la sala de emergencias contraídos',
      buttonAriaLabel: 'Ir a Encontrar cuidado médico para encontrar una sala de emergencias'
    }
  ],
  points: [
    'Las opciones de cuidado médico y la lista de síntomas no son exhaustivas. Si es posible, consulte a su médico de familia o médico de cabecera para obtener más orientación.',
    'LiveHealth Online, datos internos de 2020.',
    'Finanzas de salud: Los tiempos de espera de los pacientes muestran un impacto notable en las puntuaciones de satisfacción, según muestra el estudio de Vitals (consultado en julio de 2021): <a href="https://www.healthcarefinancenews.com" rel="noopener noreferrer" target="_blank" aria-label="Healthcare Finance News. abre en una ventana nueva">healthcarefinancenews.com</a>.',
    'Asociación de Atención Urgente: Informe comparativo de UCA 2019 (consultado en julio de 2021): <a href="https://www.ucaoa.org" rel="noopener noreferrer" target="_blank" aria-label="Urgent Care Association. abre en una ventana nueva">ucaoa.org</a>.',
    'Harvard Business Review: Para reducir los tiempos de espera en las salas de emergencia, vincúlelos con los pagos (consultado en julio de 2021): <a href="https://www.hbr.org" rel="noopener noreferrer" target="_blank" aria-label="Harvard Business Review. abre en una ventana nueva">hbr.org</a>.',
    'Los costos se clasifican según los gastos de bolsillo estimados del miembro y los copagos promedio del plan de salud. Cada plan puede tener costos diferentes. La atención que no sea de emergencia fuera de su red puede tener gastos de bolsillo más altos o puede que no esté cubierta en absoluto. $ = menor costo y $$$ = mayor costo.'
  ],
  bannerTitle: 'Tiene opciones cuando necesite cuidado médico rápidamente',
  bannerDescription:
    'Cuando se trata de su salud, obtener ayuda rápidamente puede ser muy importante. Por eso, le facilitamos recibir el cuidado médico que necesita, cuando lo necesita. Su plan incluye acceso a estos médicos y profesionales de la salud que están listos para ayudarlo.<sup>1</sup>',
  getCareNow: 'Reciba cuidado médico ahora'
};
