import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonDirectivesModule } from '../../../../../common/directives/commonDirectivesModule';
import { AnpSliderAlertComponent } from './components/anp-slider-alert/anpSliderAlertCmp';
import { CaHmoSliderAlertComponent } from './components/ca-hmo-slider-alert/caHmoSliderAlertCmp';
import { SliderAlertMessageComponent } from './components/slider-alert-message/sliderAlertMessageCmp';

@NgModule({
  declarations: [SliderAlertMessageComponent, AnpSliderAlertComponent, CaHmoSliderAlertComponent],
  exports: [SliderAlertMessageComponent, AnpSliderAlertComponent, CaHmoSliderAlertComponent],
  imports: [CommonModule, CommonDirectivesModule]
})
export class CommonSliderAlertModule {}
