import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { ICardRequest, ICardResponse } from '../interfaces/iProgramCard';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';

@Injectable({
  providedIn: 'root'
})
export class ProgramCardService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  /**
   * Method to get the program card
   * @param request Program card request.
   * @returns Program card items.
   */
  async getProgramCards(request: ICardRequest): Promise<ICardResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.programCardsV1,
      data: request,
      headers: this.getHeaders()
    });
  }
}
