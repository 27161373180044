import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { HttpMethod } from '../enums/httpMethodEnum';
import { IIntentLookupRequest } from '../interfaces/iIntentLookupRequest';
import { IIntentLookupResponse } from '../interfaces/iIntentLookupResponse';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';

@Injectable({
  providedIn: 'root'
})
export class IntentService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  /**
   * Sends a getIntentLookup  request  to the server and returns the response.
   * @param request The getIntentLookup request object.
   * @returns A promise that resolves to the getIntentLookup response.
   */
  getIntentLookup(request: IIntentLookupRequest): Promise<IIntentLookupResponse> {
    const url = `${this.baseURL}${apiNameList.restApi.secureIntentLookup}`;
    const headers = this.getHeaders();

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: url,
      data: request,
      headers: headers
    });
  }
}
