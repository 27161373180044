import { CurrencyPipe } from '@angular/common';
import { isEmpty } from 'lodash';
import { IScenarioAlertRequest } from '../../common/interfaces/iScenarioAlert';
import { IWindow } from '../../common/interfaces/iWindow';
import { FindCareFlag } from '../../findcare/common/enums/findCareFlag';
import { FeatureManagerUtility } from '../../findcare/common/utilities/featureManagerUtil';
import { IDetailsParameters, IOutputFlags } from '../provider-details/models/iDetailsParameters';
import { IPlanList } from '../provider-details/models/iPlanList';
import { ISelectedProvider } from '../provider-details/models/iSelectedProvider';
import { IProviderSearchDdlValues } from '../search-providers/interfaces/iProviderSearchDdlValues';
import { IActionReasonCodes } from '../search-providers/interfaces/iQuickLinks';
import { ISearchParameters } from '../search-providers/interfaces/iSearchParameters';
import { VirtualCareURLSSORequest } from '../search-providers/interfaces/iVirtualCareUrlSSO';
import { MagicLinkRequest, RedirectUrlResponse, VirtualCareEnums } from '../search-providers/interfaces/magicLinkVpcItemTypes';
import { VirtualCareSSOUrlSvc } from '../search-providers/services/virtualCareSSOURLSvc';
import { VpcMagicLinkSvc } from '../search-providers/services/vpcMagicLinkSvc';
import { IMapRequest } from '../search-results/interfaces/iMapRequest';
import { EycProcedureCriteria } from '../search-results/interfaces/iSearchRequest';
import { ICostInfo, ISpecialty, ReviewResponse } from '../search-results/interfaces/iSummaryResp';
import { ACCOUNT_TYPE, BRAND_CODE, EMPTY_STRING, FindCareWebIntegrationClient, Locale, TILDE_SYMBOL } from './../../common/constants/app-constants';
import { SEARCH_SCENARIOS } from './../../common/constants/strategy-search-constant';
import { IAdobe } from './../../common/interfaces/iAdobe';
import { IBaseCode } from './../../common/interfaces/iBaseCode';
import { AppSession } from './../../common/values/appSession';
import { IMemberRequest, IPlanRuleParameters, IRuleParameters } from './../rules/interfaces/iRulesParameters';
import { ISpecialtyCategory } from './../search-providers/interfaces/iProvTypeSpecialtyUtility';
import { ICodeType, IProviderType } from './../search-providers/interfaces/iProviderType';
import {
  BCBSASOURCE,
  DOS,
  EYC_PROVIDER_CATEGORY_CODE,
  EYC_RETURN_PROVIDER_TYPE,
  MEDICAL_PHARMACY,
  NONBCBSASOURCE,
  PROVIDER_CATEGORY_CODE,
  PROVIDER_TYPE,
  RETAIL_PHARMACY
} from './../search-providers/values/providerSearchConstants';
import { ILocation, IProvider } from './../search-results/interfaces/iSummaryResp';
import {
  AMPERSAND,
  CITY,
  CULTURE,
  CULTURE_VALUE,
  DEEP_LINK_CMP,
  DEEP_LINK_ID,
  DOT_OPERAND,
  EQUAL_OPERAND,
  PROCCODE,
  STATE,
  ZIPCODE,
  providerOutputFlags
} from './../search-results/values/providerSearchConstants';

declare let window: any;

export class CommonUtil {
  private static NOREVIEW = 'no review available';

  static setMapRequest(providers: any, needClickEvent: boolean = false, showSPFI: boolean = false): IMapRequest[] {
    let _mapIndex: number = 0;
    const mapData: IMapRequest[] = [];
    if (providers && providers.length > 0) {
      providers.forEach((provider) => {
        const location = this.swapOfficeLocation(showSPFI, provider);
        if (provider && location && location.address && location.address.latitude && location.address.longitude) {
          _mapIndex = _mapIndex + 1;
          mapData.push(this.buildMapRequest(provider, _mapIndex, needClickEvent, showSPFI));
        }
        //Remote provider prod issue and regional providers
        if (location.address.homeHlthCareIndc || (!location.address.homeHlthCareIndc && (location.address.latitude === null || +location.address.latitude <= 0))) {
          _mapIndex = _mapIndex + 1;
          mapData.push(this.buildMapRequest(provider, _mapIndex, needClickEvent, showSPFI));
        }
      });
    }
    return mapData;
  }

  static buildMapRequest(provider: any, _mapIndex: any, needClickEvent: boolean = false, showSPFI: boolean = false): IMapRequest {
    let _address = '';
    let _latitude = '';
    let _longitude = '';
    let _addressId = '';
    const location = this.swapOfficeLocation(showSPFI, provider);
    if (provider && location && location.address) {
      const _provAddress = location.address;
      _address += _provAddress.addressOne ? _provAddress.addressOne + ',' : '';
      _address += _provAddress.addressTwo;
      _latitude = _provAddress.latitude ? _provAddress.latitude.trim() : '';
      _longitude = _provAddress.longitude ? _provAddress.longitude.trim() : '';
      _addressId = needClickEvent ? _provAddress.addressId : '';
    }
    const mapRequest: IMapRequest = {
      text: _mapIndex + '',
      title: _address,
      latitude: _latitude,
      longitude: _longitude,
      providerSearch: true,
      provNm: provider.providerName,
      addressId: _addressId,
      providerDetails: provider
    };
    return mapRequest;
  }

  static buildMapDataFromLocation(providerName: string, location: any): any {
    const providerArray = [];
    location.forEach((loc) => {
      const provider = {
        providerName: '',
        location: {
          address: null
        }
      };
      provider.providerName = providerName;
      provider.location.address = loc;
      providerArray.push(provider);
    });
    return providerArray;
  }

  static getSpecialty(specialtyCategoryList: any, allSpecialtiesTxt: string = '', includeDefault: boolean) {
    if (specialtyCategoryList) {
      const _index = specialtyCategoryList.findIndex((x) => x.code === '00');
      if (_index > -1) {
        specialtyCategoryList[_index].name = allSpecialtiesTxt;
      }
      if (!includeDefault) {
        specialtyCategoryList = specialtyCategoryList.filter((x) => x.code !== '00');
      }
    }
    return this.buildDropDownValues(specialtyCategoryList, 'cb_result_filter_specialty_');
  }

  static getSubSpecialty(specialtyCategoryList: Array<ISpecialtyCategory>, specialties: string[]) {
    let subSpecialtyList: Array<IBaseCode> = [];
    if (specialtyCategoryList?.length && specialties?.length) {
      for (const specialty of specialties) {
        const category = specialtyCategoryList.find((x) => x.code === specialty);
        if (category) {
          subSpecialtyList = [...subSpecialtyList, ...category.taxonomies];
        }
      }

      if (subSpecialtyList.length) {
        subSpecialtyList = subSpecialtyList.filter((x, i, a) => x.code !== '00' && a.findIndex((t) => t.code === x.code) === i);
      }
    }
    return this.buildDropDownValues(subSpecialtyList, 'cb_result_filter_subspecialty_');
  }

  static getLevelofCare(specialtyCategoryList: any, specialties: string[], allLevelOfCareTxt: string) {
    let levelOfCareList = [];
    if (specialtyCategoryList && specialtyCategoryList.length > 0 && specialties && specialties.length > 0) {
      for (const specility of specialties) {
        const index = specialtyCategoryList.findIndex((x) => x.code === specility);
        if (index >= 0 && specialtyCategoryList[index] && specialtyCategoryList[index].levelOfCareList && specialtyCategoryList[index].levelOfCareList.length > 0) {
          levelOfCareList = levelOfCareList.concat(specialtyCategoryList[index].levelOfCareList);
        }
      }
    }
    if (levelOfCareList && levelOfCareList.length > 0) {
      const _index = levelOfCareList.findIndex((x) => x.code === '00');
      if (_index > -1) {
        levelOfCareList[_index].name = allLevelOfCareTxt;
      }
    }
    return this.buildDropDownValues(levelOfCareList, 'cb_result_filter_levelofcare_');
  }

  static buildDropDownValues(data: any, identifier: any, defaultname?: string, defaultValue?: string, type?: string, multiSelect: boolean = true) {
    const ddlValues: IProviderSearchDdlValues = {
      title: '',
      options: [],
      controlListData: []
    };
    let name = '';
    let code = '';
    let controlListItem = {};
    if (defaultname && defaultname !== '') {
      ddlValues.options.push({
        label: defaultname,
        value: defaultValue,
        id: identifier + 0,
        name: multiSelect ? identifier + 0 : identifier,
        trueValue: defaultValue,
        falseValue: '',
        isChecked: false
      });
      ddlValues.controlListData.push('');
    }

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (type === 'provider') {
          name = data[i].providerName;
          code = data[i].providerIdentifier;
          controlListItem = data[i];
        } else {
          name = data[i].name ? data[i].name.trim() : data[i].name;
          code = data[i].code;
        }
        ddlValues.options.push({
          label: name,
          value: code,
          id: identifier + (i + 1),
          name: multiSelect ? identifier + (i + 1) : identifier,
          trueValue: code,
          falseValue: '',
          isChecked: false
        });
        ddlValues.controlListData.push(controlListItem);
      }
    }
    return ddlValues;
  }

  static getDropDownSelectedText(ddlValues: IProviderSearchDdlValues, selectedValue: string) {
    let defaultText = '';
    if (typeof ddlValues === 'undefined') {
      return defaultText;
    }
    const ddlValue = ddlValues.options.filter((x) => x.value === selectedValue);
    if (ddlValue && ddlValue.length > 0) {
      defaultText = ddlValue[0].label;
    }
    return defaultText;
  }

  static getSubSpecialtyList(ddlValues: IProviderSearchDdlValues, selectedValue: string[]) {
    const subSpecialtyList = [];
    if (ddlValues) {
      for (const item of ddlValues.options || []) {
        subSpecialtyList.push(item.value);
      }
    }
    return subSpecialtyList;
  }

  static buildRuleRequest(searchParams: ISearchParameters, appSession: AppSession) {
    const _planCategory = searchParams.plan && searchParams.plan.category && searchParams.plan.category.catCode ? searchParams.plan.category.catCode : '';
    const _prefix = this.getPrefix(searchParams, appSession);
    let _productType = '';

    let memberDetails: IMemberRequest;

    if (appSession.metaData && appSession.metaData.appContract) {
      if (appSession.metaData.appContract.mbrUid && !appSession.isPlanUnknown) {
        memberDetails = {} as IMemberRequest;
        memberDetails.mbrUid = appSession.metaData.appContract.mbrUid;
        memberDetails.coverageUid = appSession.coverageUid;
      }
      if (appSession.metaData.appContract.coverageDetails && appSession.metaData.appContract.coverageDetails.productType && !appSession.isPlanUnknown) {
        _productType = appSession.metaData.appContract.coverageDetails.productType;
      }
    }

    const ruleRequest: IRuleParameters = {
      providerType: searchParams.typeSelectNm,
      speciality: searchParams.specialtySelectNm && searchParams.specialtySelectNm.length > 0 ? searchParams.specialtySelectNm.filter((x) => x !== '00') : null,
      taxonomy: searchParams.taxonomySelectNm && searchParams.taxonomySelectNm.length > 0 ? searchParams.taxonomySelectNm : null,
      brand: appSession.metaData.brandCd,
      state: searchParams.plan ? searchParams.plan.stateCd : '',
      searchState: appSession.searchParams && appSession.searchParams.stateCd && appSession.searchParams.stateCd.code ? appSession.searchParams.stateCd.code : '',
      county: appSession.metaData && appSession.metaData.appContract && !appSession.isPlanUnknown ? appSession.metaData.appContract.memberCounty : '',
      coverageType: searchParams.plan ? searchParams.plan.careType : '',
      filter: true,
      integratedFilter: appSession.integratedSearch,
      isEyc: appSession.isEyc,
      planCategory: _planCategory,
      productType: _productType,
      identification: searchParams.identificationNumber ? searchParams.identificationNumber : '',
      locale: Locale.ENGLISH, // Send always english as we do not have Spanish rules
      network: searchParams.plan && searchParams.plan.networkList && searchParams.plan.networkList.length > 0 ? searchParams.plan.networkList : appSession?.networkList ? appSession.networkList : [],
      prefix: _prefix,
      coverage: searchParams.coverageTypeCode ? searchParams.coverageTypeCode : '',
      isSecureState: appSession.isSecureState && !appSession.isPlanUnknown,
      contractUid: appSession.metaData && appSession.metaData.appContract && !appSession.isPlanUnknown ? appSession.metaData.appContract.contractUid : undefined,
      member: memberDetails,
      isPCP: appSession.isChangePCP ? 'Y' : undefined,
      careType: appSession.isSecureState
        ? appSession.filterChangePlan && appSession.filterChangePlan.careType
          ? appSession.filterChangePlan.careType
          : ''
        : searchParams.plan && searchParams.plan.careType
          ? searchParams.plan.careType
          : '',
      standAloneDental: searchParams.standAloneDental ? true : false,
      standAloneVision: searchParams.standAloneVision ? true : false,
      useTaxonomy: true,
      isDeepLink: appSession.isDeepLink,
      deeplinkAction: appSession.deeplinkParams?.action,
      ableToServePcp: searchParams.ableToServePcp,
      mbrResidentialZip: appSession?.addressObj?.homeAddress?.zipCode,
      mbrWorkZip: appSession?.addressObj?.workAddress?.zipCode,
      isCaHmoDmhc: appSession?.isCaHmoDmhc,
      hasStandaloneDentalContract: appSession.hasStandaloneDentalContract,
      hasStandaloneVisionContract: appSession.hasStandaloneVisionContract,
      isPrimeGroup: appSession.metaData.appContract?.mbrUid && !appSession.isPlanUnknown && appSession.searchParams.includePrimeGroupSearch,
      isCpt: appSession.isMedicalCodeSearch
    };

    if (appSession.integratedSearch) {
      ruleRequest.integratedSearch = true;
      const _provType = ruleRequest.providerType;
      delete ruleRequest.providerType;
      ruleRequest.providerTypeList = searchParams.providerTypeCodeList?.length ? searchParams.providerTypeCodeList : _provType ? [_provType] : [];
    }

    if (searchParams.eycSearchParam) {
      const procedure: EycProcedureCriteria = {
        procedureCode:
          searchParams.eycSearchParam.eycProcedureCriteria && searchParams.eycSearchParam.eycProcedureCriteria.procedureCode ? searchParams.eycSearchParam.eycProcedureCriteria.procedureCode : '',
        providerCategoryCode:
          searchParams.eycSearchParam.eycProcedureCriteria && searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode
            ? searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode
            : ''
      };
      ruleRequest.eycSearchParam = {
        eycProcedureCriteria: procedure
      };
    }

    return ruleRequest;
  }

  // Build plan rule request.
  static buildPlanRuleRequest(searchParams: ISearchParameters, appSession: AppSession) {
    let _plan = {} as IPlanRuleParameters;

    if (searchParams.plan) {
      _plan = {
        state: searchParams.plan.stateCd ? searchParams.plan.stateCd : '',
        brand: appSession.metaData.brandCd,
        prefix: searchParams.plan.alphaPrefix ? searchParams.plan.alphaPrefix : '',
        planCategory: searchParams.plan.category ? searchParams.plan.category.catCode : '',
        productType:
          appSession.isSecureState === true &&
          appSession.metaData &&
          appSession.metaData.appContract &&
          appSession.metaData.appContract.coverageDetails &&
          appSession.metaData.appContract.coverageDetails.productType
            ? appSession.metaData.appContract.coverageDetails.productType
            : '',
        //networkList: !appSession.isSecureState ? appSession.searchParams?.plan?.networkList: appSession.isHcidSearch ? appSession.searchParams?.plan?.networkList: [],
        networkList: !appSession.isSecureState ? appSession.networkList : appSession.isHcidSearch ? appSession.networkList : [],
        locale: appSession.metaData.locale ? appSession.metaData.locale.toString() : Locale.ENGLISH,
        mbu: appSession?.mbu,
        underwritingStateCode: appSession?.underwritingStateCode,
        isHcidSearch: appSession.isHcidSearch
      };

      if (appSession?.isSecureState === true && !appSession?.metaData?.pfEmMode && !appSession?.isHcidSearch && appSession?.encryptedUserName) {
        if (appSession?.metaData?.appContract?.mbrUid) {
          _plan.mbrUid = appSession?.metaData?.appContract?.mbrUid;
        }
        _plan.contractUid = appSession?.metaData?.appContract?.contractUid ? appSession?.metaData?.appContract?.contractUid : '';
        _plan.userName = appSession?.encryptedUserName;
        _plan.coverageUid = appSession?.coverageUid;
      }
    }

    return _plan;
  }

  // Provider Type - method handle   stand alone.
  static buildStandAlone(searchParams: ISearchParameters, appSession: AppSession) {
    const _searchParams: ISearchParameters = searchParams;
    _searchParams.standAloneDental = null;
    _searchParams.standAloneVision = null;
    _searchParams.coverageTypeCode = '';

    if (appSession) {
      if (_searchParams.typeSelectNm === PROVIDER_TYPE.DENTAL && appSession.isDentalStandAlone) {
        //_searchParams.coverageTypeCode = DENTAL_ROUTINE_COVERAGETYPE;
        _searchParams.standAloneDental = true;
      } else if (_searchParams.typeSelectNm === PROVIDER_TYPE.VISION && appSession.isVisionStandAlone) {
        //_searchParams.coverageTypeCode = VISION_ROUTINE_COVERAGETYPE;
        _searchParams.standAloneVision = true;
      } else if (_searchParams.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
        _searchParams.coverageTypeCode = this.getPharmacyCoverageType(_searchParams.typeSelectNm, appSession);
      }
    }

    return _searchParams;
  }

  /**
   * Determines the pharmacy coverage type based on the provider type and the application session.
   * @param {string} provType - The provider type.
   * @param {AppSession} appSession - The application session which includes information about the member's plans and indicators.
   * @returns {string} - Returns 'RETAIL_PHARMACY' if the provider type is 'PHARMACY' and the member is not secure or has a pharmacy plan or has either an RxCvsIndicator or an RxEsiIndicator. Otherwise, returns 'MEDICAL_PHARMACY'.
   */
  static getPharmacyCoverageType(provType: string, appSession: AppSession): string {
    if (provType === PROVIDER_TYPE.PHARMACY && (!this.isMemberSecure(appSession) || appSession.hasPharmacyPlan || appSession.hasRxCvsIndicator || appSession.hasRxEsiIndicator)) {
      return RETAIL_PHARMACY;
    }
    return MEDICAL_PHARMACY;
  }

  /**
   * Builds a request for the Specialty rule.
   * @param searchParams - The search parameters to be used in the request.
   * @param appSession - The application session data, including metadata and user-specific data.
   * @returns An object representing the Specialty rule request.
   */
  static buildSpecialtiesRequest(searchParams: ISearchParameters, appSession: AppSession) {
    // Get prefix using searchParams and appSession
    const _prefix = this.getPrefix(searchParams, appSession);

    // Define memberDetails based on appSession.metaData and appSession.isPlanUnknown
    const memberDetails: IMemberRequest =
      appSession.metaData?.appContract?.mbrUid && !appSession.isPlanUnknown ? { mbrUid: appSession.metaData.appContract.mbrUid, coverageUid: appSession.coverageUid } : undefined;

    // Define ruleRequest object using various properties from appSession and memberDetails
    const ruleRequest: IRuleParameters = {
      brand: appSession.metaData.brandCd,
      prefix: _prefix,
      locale: appSession.metaData.locale === Locale.SPANISH ? Locale.SPANISH : Locale.ENGLISH,
      network: appSession.searchParams?.plan?.networkList || [],
      identification: appSession.searchParams?.identificationNumber && !appSession.isPlanUnknown ? appSession.searchParams.identificationNumber : undefined,
      contractUid: appSession.metaData?.appContract && !appSession.isPlanUnknown ? appSession.metaData.appContract.contractUid : undefined,
      member: memberDetails,
      isDeepLink: appSession.isDeepLink,
      deeplinkAction: appSession.deeplinkParams?.action,
      useTaxonomy: true
    };

    return ruleRequest;
  }

  static buildPreConfigSearchRequest(searchParams: ISearchParameters, appSession: AppSession) {
    let memberDetails: IMemberRequest;
    if (appSession.metaData && appSession.metaData.appContract) {
      if (appSession.metaData.appContract.mbrUid) {
        memberDetails = {} as IMemberRequest;
        memberDetails.mbrUid = appSession.metaData.appContract.mbrUid;
        memberDetails.coverageUid = appSession.coverageUid;
      }
    }

    const ruleRequest: IRuleParameters = {
      identification: searchParams.identificationNumber ? searchParams.identificationNumber : '',
      contractUid: appSession.metaData && appSession.metaData.appContract ? appSession.metaData.appContract.contractUid : undefined,
      member: memberDetails,
      isDeepLink: appSession.isDeepLink,
      deeplinkAction: appSession.deeplinkParams?.action,
      ableToServePcp: searchParams.ableToServePcp
    };
    return ruleRequest;
  }

  static getPrefix(searchParams: ISearchParameters, appSession: AppSession): string | undefined {
    if (searchParams?.plan?.alphaPrefix) {
      return searchParams.plan.alphaPrefix;
    } else if (appSession?.searchParams?.plan?.alphaPrefix) {
      return appSession?.searchParams.plan.alphaPrefix;
    } else if (appSession?.deeplinkParams?.alphaprefix) {
      return appSession.deeplinkParams.alphaprefix;
    }
    return undefined;
  }

  static getAlphaPrefix(appSession: AppSession): string {
    if (appSession?.searchParams?.plan?.alphaPrefix && appSession?.searchParams?.plan?.alphaPrefix !== '') {
      return appSession?.searchParams?.plan?.alphaPrefix;
    }
    if (appSession?.deeplinkParams?.alphaprefix && appSession?.deeplinkParams?.alphaprefix !== '') {
      return appSession?.deeplinkParams?.alphaprefix;
    }
  }

  static getPrefixPlanCategoryValue(searchParams: ISearchParameters): string {
    const selectedPlanCatCode = searchParams?.plan?.category?.catCode;
    if (searchParams?.planCategoryDdlValues && searchParams?.planCategoryDdlValues?.length > 0 && selectedPlanCatCode) {
      return searchParams.planCategoryDdlValues
        .filter((planCategory: any) => {
          if (planCategory?.value === selectedPlanCatCode) {
            return planCategory.value;
          }
        })
        .map((planCatValue: any) => planCatValue.value)[0];
    } else {
      return selectedPlanCatCode;
    }
  }

  static isMemberSecure(appSession: AppSession) {
    let isSecureState = appSession.isSecureState;
    // Hcid search scenario
    if (appSession && appSession.searchParams && appSession.searchParams.identificationNumber && appSession.searchParams.identificationNumber !== '') {
      isSecureState = false;
    }
    return isSecureState;
  }

  static showReviewLink(totalReviews: any) {
    if (totalReviews && totalReviews.toString().toLocaleLowerCase() === this.NOREVIEW) {
      return false;
    }
    return true;
  }

  static checkIndividualProvWithProvTypeP(provCategoryCode: string, providerTypeCodeList: Array<string>): boolean {
    const _categoryCode = provCategoryCode ? provCategoryCode.trim() : '';
    const _provTypeCode = providerTypeCodeList ? providerTypeCodeList : [];
    if (_categoryCode && _categoryCode !== '' && PROVIDER_CATEGORY_CODE.Individual === _categoryCode && _provTypeCode.length > 0 && _provTypeCode.indexOf(PROVIDER_TYPE.DOCTOR_PROFESSIONAL) > -1) {
      return true;
    }
    return false;
  }

  static isReviewRating(rating: ReviewResponse, provCategoryCode: string, providerTypeCodeList: Array<string>): boolean {
    return (
      rating &&
      rating.starRating &&
      rating.totalReviews &&
      CommonUtil.showReviewLink(rating.totalReviews) &&
      (rating.source === NONBCBSASOURCE || (rating.source === BCBSASOURCE && CommonUtil.checkIndividualProvWithProvTypeP(provCategoryCode, providerTypeCodeList)))
    );
  }

  //Adobe Analytics Search Queries
  private static concateSymbol = '|';
  //Populates the Analytics query for Plan by search
  static getPlanDetailsAnalyticsQuery(data: IAdobe) {
    let _planDetails = '';
    if (data && data.planDetail && data.planDetail.careType !== '' && data.planDetail.state !== '' && data.planDetail.category !== '' && data.planDetail.planName !== '') {
      _planDetails =
        data.planDetail.careType + CommonUtil.concateSymbol + data.planDetail.state + CommonUtil.concateSymbol + data.planDetail.category + CommonUtil.concateSymbol + data.planDetail.planName;
    }
    return _planDetails;
  }
  //Populates the Analytics query for provider search criteria
  static getDoctorDetailsAnalyticsQuery(data: IAdobe) {
    let _searchDetails = '';
    if (
      data &&
      data.searchDetail &&
      data.searchDetail.provType !== '' &&
      data.searchDetail.speciality &&
      data.searchDetail.speciality.length > 0 &&
      data.searchDetail.zip !== '' &&
      data.searchDetail.distance !== '' &&
      data.searchDetail.provName !== ''
    ) {
      _searchDetails =
        data.searchDetail.provType +
        CommonUtil.concateSymbol +
        data.searchDetail.speciality +
        CommonUtil.concateSymbol +
        data.searchDetail.zip +
        CommonUtil.concateSymbol +
        data.searchDetail.distance +
        ' miles' +
        CommonUtil.concateSymbol +
        data.searchDetail.provName;
    }
    return _searchDetails;
  }

  //Populates the Analytics query for provider search criteria
  static getAnalyticsQuery(data: IAdobe) {
    let _searchDetails = '';
    if (data && data.searchDetail) {
      if (data.searchDetail.procedureName) {
        _searchDetails = data.searchDetail.procedureName + CommonUtil.concateSymbol + data.searchDetail.zip;
      } else if (data.searchDetail.specialityName) {
        _searchDetails = data.searchDetail.specialityName + CommonUtil.concateSymbol + data.searchDetail.zip;
      } else if (data.searchDetail.provType) {
        _searchDetails = data.searchDetail.provType + CommonUtil.concateSymbol + data.searchDetail.zip;
      } else if (data.searchDetail.provName) {
        _searchDetails = data.searchDetail.provName + CommonUtil.concateSymbol + data.searchDetail.zip;
      }
    }
    return _searchDetails;
  }

  //Populates the Analytics query for Pill Filter
  static getPillFilterAnalyticsQuery(data: IAdobe) {
    let _pillFilterDetails = '';
    if (data && data.pillFilterDetail) {
      if (data.pillFilterDetail.filterType) {
        _pillFilterDetails = data.pillFilterDetail.filterType + CommonUtil.concateSymbol + data.pillFilterDetail.filterValue;
      }
    }
    return _pillFilterDetails;
  }

  static checkPcpIndicator(val: string): boolean {
    const _pcpInd = val.split(';').filter((x) => x === 'U');
    if (_pcpInd.length === 1) {
      return true;
    }
    return false;
  }

  static getProcedureProviderReturnType(providerCategoryCode: string): string {
    let providerReturnType: string = EYC_RETURN_PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
    if (providerCategoryCode) {
      switch (providerCategoryCode.toUpperCase()) {
        case EYC_PROVIDER_CATEGORY_CODE.BOTH:
          providerReturnType = EYC_RETURN_PROVIDER_TYPE.BOTH;
          break;
        case EYC_PROVIDER_CATEGORY_CODE.FACILITY:
          providerReturnType = EYC_RETURN_PROVIDER_TYPE.HOSPITAL;
          break;
      }
    }
    return providerReturnType;
  }

  static getSecureProcedureScenario(code: PROVIDER_TYPE) {
    let provType: SEARCH_SCENARIOS;
    if (code === PROVIDER_TYPE.DENTAL) {
      provType = SEARCH_SCENARIOS.SECURE_V1_TCC_DENTAL;
    } else {
      provType = SEARCH_SCENARIOS.SECURE_V1_TCC_MEDICAL;
    }
    return provType;
  }

  static getSecureSpecialtyScenario(providerTypes: PROVIDER_TYPE[]): SEARCH_SCENARIOS {
    return this.isPharmacyType(providerTypes) ? SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY : SEARCH_SCENARIOS.SECURE_V1_SPECIALTY;
  }

  static isPharmacyType(providerTypes: PROVIDER_TYPE[]): boolean {
    const types = providerTypes?.filter(Boolean) || [];
    return types.length === 1 && types[0].toUpperCase().trim() === PROVIDER_TYPE.PHARMACY;
  }

  static getAccountExpansion(accntType) {
    let expansion = '';
    switch (accntType) {
      case 'HRA':
        expansion = ACCOUNT_TYPE.HRA;
        break;
      case 'HIA':
        expansion = ACCOUNT_TYPE.HIA;
        break;
      case 'HIAPLUS':
        expansion = ACCOUNT_TYPE.HIAPLUS;
        break;
      case 'HSA':
        expansion = ACCOUNT_TYPE.HSA;
        break;
      case 'QHE':
        expansion = ACCOUNT_TYPE.QHE;
        break;
      default:
        expansion = ACCOUNT_TYPE.HRA;
        break;
    }
    return expansion;
  }

  static hasMultipleUnknownPrefix(appSession: AppSession) {
    if (
      typeof appSession.metaData !== 'undefined' &&
      appSession.metaData !== null &&
      typeof appSession.metaData.appContract !== 'undefined' &&
      appSession.metaData.appContract !== null &&
      typeof appSession.metaData.appContract.memberList !== 'undefined' &&
      appSession.metaData.appContract.memberList !== null
    ) {
      const member = appSession.metaData.appContract.memberList.filter((m) => m.mbrUid === appSession.metaData.appContract.mbrUid);

      if (typeof member !== 'undefined' && member !== null && member.length > 0) {
        return member[0].hasMultipleUnknownPrefix;
      } else {
        // No contract scenario.
        return true;
      }
    }

    return false;
  }

  static isMultiContractAllowedClient(client: string): boolean {
    if (client !== FindCareWebIntegrationClient.SYDNEYMEDICAID) {
      return true;
    }
    return false;
  }

  static getLimitedMessage(msg: string, charLimit: number): string {
    const limitedMsg = (msg || '').slice(0, charLimit) + ((msg || '').length > charLimit ? '...' : '');
    return limitedMsg;
  }

  static buildPrefixRuleRequest(searchParams: ISearchParameters, appSession: AppSession) {
    const _prefix = CommonUtil.getPrefix(searchParams, appSession);

    let memberDetails: IMemberRequest;

    if (appSession.metaData && appSession.metaData.appContract && appSession.metaData.appContract.mbrUid && !appSession.isPlanUnknown) {
      memberDetails = {} as IMemberRequest;

      memberDetails.mbrUid = appSession.metaData.appContract.mbrUid;
      memberDetails.coverageUid = appSession.coverageUid;
    }

    const ruleRequest: IRuleParameters = {
      brand: appSession.metaData.brandCd,
      prefix: _prefix,
      providerType: searchParams.typeSelectNm,
      isLevelofCare: '',
      locale: appSession.metaData && appSession.metaData.locale ? appSession.metaData.locale.toString() : Locale.ENGLISH,
      network: searchParams.plan && searchParams.plan.networkList && searchParams.plan.networkList.length > 0 ? searchParams.plan.networkList : [],
      identification: appSession.searchParams && appSession.searchParams.identificationNumber ? appSession.searchParams.identificationNumber : undefined,
      isPCP: appSession.isChangePCP ? 'Y' : undefined,
      contractUid: appSession.metaData && appSession.metaData.appContract && !appSession.isPlanUnknown ? appSession.metaData.appContract.contractUid : undefined,
      member: memberDetails,
      isDeepLink: appSession.isDeepLink,
      deeplinkAction: appSession.deeplinkParams?.action,
      ableToServePcp: searchParams.ableToServePcp
    };

    // Rule request set plan request obj.
    if (searchParams.plan) {
      ruleRequest.state = searchParams.plan.stateCd ? searchParams.plan.stateCd : '';
      ruleRequest.planCategory = searchParams.plan.category ? searchParams.plan.category.catCode : '';
      (ruleRequest.productType =
        appSession.isSecureState === true &&
        !appSession.isPlanUnknown &&
        appSession.metaData &&
        appSession.metaData.appContract &&
        appSession.metaData.appContract.coverageDetails &&
        appSession.metaData.appContract.coverageDetails.productType
          ? appSession.metaData.appContract.coverageDetails.productType
          : ''),
        (ruleRequest.careType = searchParams.plan.careType ? searchParams.plan.careType : '');
    }

    return ruleRequest;
  }

  static populateProviderTypes(data: any, appSession: AppSession, useQuickLink: boolean = false) {
    let code: any;
    const _providerTypes: IProviderType[] = [];

    code = useQuickLink ? data : data.codeTypeList;
    let isPhrmcyStndAlneNonMdclPhrmcy: boolean =
      appSession.metaData.appContract.coverageDetails && !appSession.metaData.appContract.coverageDetails.hasPharmacyPlan && appSession.metaData.appContract.coverageDetails.isPharmacyStandAlone
        ? true
        : false;

    for (let i = 0; i < code.length; i++) {
      if (
        appSession.isSecureState &&
        code[i].code.toUpperCase().trim() === PROVIDER_TYPE.PHARMACY &&
        appSession.metaData.appContract.coverageDetails &&
        !(appSession.metaData.appContract.coverageDetails.hasPharmacyPlan || isPhrmcyStndAlneNonMdclPhrmcy)
      ) {
        continue;
      }

      const _codeName: IProviderType = {
        code: code[i].code,
        name: code[i].name,
        licenseList: code[i].licenseCodeList && code[i].licenseCodeList.length > 0 ? code[i].licenseCodeList : [],
        isChecked: code[i].isChecked ? code[i].isChecked : false,
        id: code[i].code,
        label: code[i].name,
        trueValue: true,
        falseValue: false
      };

      _providerTypes.push(_codeName);
    }

    return _providerTypes;
  }

  static getProviderTypes(providerTypes: IProviderType[], providerTypeNames: any) {
    const providerTypeList: IProviderType[] = [];

    if (providerTypes && providerTypes.length > 0) {
      for (const provType of providerTypeNames) {
        const _code = provType.code.toUpperCase().trim();
        const _name = provType.name;
        const _adobeTag = provType.tag;
        const _index = providerTypes.findIndex((x) => x.code.toUpperCase().trim() === _code);

        if (_index > -1) {
          const _provType: IProviderType = {
            code: _code,
            name: _name,
            id: _code,
            label: _name,
            trueValue: true,
            falseValue: false,
            adobeTag: _adobeTag
          };

          providerTypeList.push(_provType);
        }
      }
    }

    return providerTypeList;
  }

  static mapQuickLinkProviderTypes(providerTypes: ICodeType[]): IProviderType[] {
    const providerTypeList: IProviderType[] = [];

    if (providerTypes && providerTypes.length > 0) {
      for (const provType of providerTypes) {
        const _code = provType.code.toUpperCase().trim();
        const _name = provType.name;
        const _provType: IProviderType = {
          code: _code,
          name: _name,
          licenseList: provType.licenseCodeList ? provType.licenseCodeList : [],
          id: _code,
          label: _name,
          trueValue: true,
          falseValue: false,
          adobeTag: provType.adobeTag,
          specialtyCategoryList: provType.specialtyCategoryList ? provType.specialtyCategoryList : [],
          imageContentId: provType.imageContentId,
          imageName: provType.imageName,
          vendorUrl: provType.vendorUrl,
          taxonomyList: provType.taxonomyList ? provType.taxonomyList : [],
          ableToServePcp: provType.ableToServePcp,
          rankPercentile: provType.rankPercentile,
          modelId: provType.modelId,
          actionReasonCodes: provType.actionReasonCodes,
          hasFeedbackOptions: CommonUtil.toShowFeedbackOptionForTile(provType)
        };
        providerTypeList.push(_provType);
      }
    }

    return providerTypeList;
  }

  static swapOfficeLocation(showSPFI: boolean, provider: IProvider): ILocation {
    let location = provider && provider.location ? provider.location : provider && provider.locations && provider.locations.length > 0 ? provider.locations[0] : ({} as ILocation);
    location.isFacilityLoc = false;
    if (showSPFI && provider && (provider.location || provider.locations) && provider.eycAffliation && provider.eycAffliation.affiliationList && provider.eycAffliation.affiliationList.length > 0) {
      const affFacility = { ...provider.eycAffliation.affiliationList[0] };
      location = affFacility.location ? affFacility.location : provider.location ? { ...provider.location } : { ...provider.locations[0] };
      location.isFacilityLoc = true;
    }
    return location;
  }

  /**
   *
   * @param imageName
   * @param appSession
   * @returns brand image url
   */
  static getBrandImageURL(imageName: string, appSession: AppSession): string {
    if (imageName) {
      const wcsBaseUrl = appSession.state ? appSession.appConfig?.baseUrl[appSession?.state] : appSession.appConfig?.baseUrl['public'];
      return wcsBaseUrl + appSession.appConfig?.images?.brandBaseUrl + imageName;
    } else {
      return '';
    }
  }

  static isCptSearch(appSession: AppSession): boolean {
    return appSession?.isMedicalCodeSearch;
  }

  static supressCheckbox(providerList, appSession?: AppSession): boolean {
    if (this.isCptSearch(appSession)) {
      return false;
    } else if (providerList?.length > 1) {
      return true;
    }
    return false;
  }

  static getMemberDOB(dob: string) {
    let date = new Date(dob).getDate().toString();
    let month = new Date(dob).getMonth().toString();
    const year = new Date(dob).getFullYear().toString();
    if (date.length > 1 && month.length > 1) {
      return new Date(dob).toLocaleDateString();
    } else {
      if (date.length === 1) {
        date = '0' + date;
      }
      month = month.length === 1 ? (month === '9' ? '10' : '0' + (Number(month) + 1)) : (Number(month) + 1).toString();
    }
    return month + '/' + date + '/' + year;
  }

  static isCobrand(appSession: AppSession): boolean {
    return appSession && appSession.planRule && appSession.planRule.cobrandRule && appSession.planRule.cobrandRule.isCobrand ? true : false;
  }

  static getCobrandByDefaultBrand(cobrand: string): string {
    if (cobrand === BRAND_CODE.ABCBSKROGER) {
      return BRAND_CODE.ABCBS;
    } else if (cobrand === BRAND_CODE.NYEMBLUAM) {
      return BRAND_CODE.EBCBS;
    } else {
      return BRAND_CODE.ABCBS;
    }
  }

  static isNotBlank(str: string): boolean {
    let value = false;
    if (!this.isEmpty(str) && str.trim() !== '') {
      value = true;
    }
    return value;
  }

  static isBlank(str: string): boolean {
    if (str && str.trim() !== '') {
      return false;
    }
    return true;
  }

  static isEmpty(temp: string): boolean {
    return temp && temp.length ? false : true;
  }

  static isNonEmptyArrayOfStrings(value: unknown): boolean {
    return Array.isArray(value) && value.length && !value.some((item) => typeof item !== 'string');
  }

  /**
   * This method will return request object for calling provider details API
   * @param appSession
   * @param selectedProvider
   * @param selectedAddressId
   * @returns
   */
  public static buildProviderDetailsRequest(appSession: AppSession, selectedProvider: ISelectedProvider, selectedAddressId: string): IDetailsParameters {
    const plan: IPlanList = {
      identifier: appSession?.searchParams?.plan?.identifier ? appSession?.searchParams?.plan?.identifier.trim() : '',
      isNationalPlan: appSession?.searchParams?.plan?.isNationalPlan
    };

    const outputFlags: IOutputFlags = {
      includeTaxonomy: true,
      includeVisibilityRules: providerOutputFlags?.visibilityRulesFlag,
      includeReviewRatings: providerOutputFlags?.reviewRatingsFlag,
      includePrimeGroupSearch: providerOutputFlags?.includePrimeGroupSearch,
      imposeAddressConsolidation: providerOutputFlags?.imposeAddressConsolidation,
      improveSearchCriteria: providerOutputFlags?.improveSearchCriteria,
      includeSpfInfo: providerOutputFlags?.includeSpfInfo,
      includeProviderNetworks: providerOutputFlags?.includeProviderNetworks
    };

    if (!appSession.isEyc) {
      outputFlags.includeAllNetworks = (appSession.hasStandaloneDentalContract || appSession.hasStandaloneVisionContract) && appSession.hasMedicalContracts;
    }

    const netwrkList: Array<IBaseCode> = [];

    (appSession?.searchParams?.plan?.networkList || []).forEach((network) => {
      netwrkList.push({
        code: network,
        name: undefined
      });
    });

    const detailsRequest: IDetailsParameters = {
      providerIdentifier: selectedProvider?.providerIdentifier,
      addressIdentifier: !selectedAddressId ? selectedProvider?.location?.address?.addressId : selectedAddressId,
      brandCode: appSession?.metaData?.brandCd,
      state: undefined,
      distance: appSession?.searchParams?.distanceNm ? parseInt((appSession?.searchParams?.distanceNm || '').trim(), 10) : 0,
      planStateCode: appSession?.searchParams?.plan?.stateCd || '',
      rxChoiceNtwkIndicator: '',
      specialtyCategoryList: appSession?.searchParams?.specialtySelectNm?.length ? appSession?.searchParams?.specialtySelectNm.filter((x) => x !== '00') : undefined,
      networkList: netwrkList,
      alphaPrefix: CommonUtil.getAlphaPrefix(appSession),
      location: {
        latitude: appSession?.searchParams?.coordinates?.latitude || '',
        longitude: appSession?.searchParams?.coordinates?.longitude || ''
      },
      planList: new Array(plan),
      locale: (appSession?.metaData?.locale && appSession?.metaData?.locale.toString()) || '',
      zipCode: isNaN(Number((appSession?.searchParams?.zip || '').trim())) ? undefined : (appSession?.searchParams?.zip || '').trim(),
      city: appSession?.searchParams?.countyCode ? undefined : appSession?.searchParams?.city,
      procedureCode: appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode,
      outputFlags: outputFlags
    };

    if (selectedProvider?.providerTypeCodeList) {
      detailsRequest.providerTypeCodeList = selectedProvider?.providerTypeCodeList;
    } else if (appSession?.searchParams?.providerTypeCodeList) {
      detailsRequest.providerTypeCodeList = appSession?.searchParams?.providerTypeCodeList;
    }

    if (appSession?.metaData?.appContract?.mbrUid && !appSession?.isPlanUnknown) {
      detailsRequest.mbrUid = appSession?.metaData?.appContract?.mbrUid;
      detailsRequest.coverageUid = appSession.coverageUid;
    }

    if (appSession?.searchParams?.identificationNumber && !appSession?.isPlanUnknown) {
      detailsRequest.identificationNumber = appSession?.searchParams?.identificationNumber;
    }

    if (appSession?.metaData?.appContract?.contractUid && !appSession?.isPlanUnknown) {
      detailsRequest.contractUid = appSession?.metaData?.appContract?.contractUid;
    }

    // Add aditional parameters for CVS Pharmacy API.
    if ((detailsRequest?.providerTypeCodeList || []).includes(PROVIDER_TYPE.PHARMACY) && appSession?.searchParams?.coverageTypeCode === RETAIL_PHARMACY) {
      detailsRequest.pharmacyName = selectedProvider?.providerName;
      detailsRequest.pharmacyNumber = selectedProvider?.providerIdentifier;
      detailsRequest.coverageTypeCode = appSession?.searchParams?.coverageTypeCode;

      let location = selectedProvider?.location;

      if (location?.address?.state) {
        detailsRequest.state = location?.address?.state;
      } else if (appSession?.searchParams?.stateCd?.code) {
        detailsRequest.state = appSession?.searchParams?.stateCd?.code;
      }

      if (location?.address?.postalCode) {
        detailsRequest.zipCode = location?.address?.postalCode;
      } else if (appSession?.searchParams?.zip) {
        detailsRequest.zipCode = isNaN(Number((appSession?.searchParams?.zip || '').trim())) ? undefined : (appSession?.searchParams?.zip || '').trim();
      }

      if (location?.address?.city) {
        detailsRequest.city = location?.address?.city;
      } else if (appSession?.searchParams?.city) {
        detailsRequest.city = appSession?.searchParams?.countyCode ? undefined : appSession?.searchParams?.city;
      }
    }

    // Assigning isFutureCoverage for details request when a future plan is selected
    if ((CommonUtil.isMemberSecure(appSession) || appSession?.searchParams?.identificationNumber) && appSession?.isFutureCoverage) {
      detailsRequest.isFutureCoverage = appSession?.isFutureCoverage;
    }

    return detailsRequest;
  }

  /**
   *
   * @param contentLabel
   * @param appSession
   * @returns Procedure criteria deeplink url and append the constructed deeplink url to the content login url
   */
  public static getProcedureDeeplinkUrl(contentLabel: string, appSession: AppSession): string {
    const postalCode = Number(appSession?.searchParams?.zip?.trim());
    if (contentLabel) {
      let deeplinkUrl: string = '';
      if (postalCode) {
        deeplinkUrl = ZIPCODE + EQUAL_OPERAND + postalCode;
      } else if (appSession?.searchParams?.city && appSession?.searchParams?.stateCd?.code) {
        deeplinkUrl = CITY + EQUAL_OPERAND + appSession.searchParams.city + DOT_OPERAND + STATE + EQUAL_OPERAND + appSession.searchParams.stateCd.code;
      }
      const procedureCode = appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
      if (procedureCode) {
        deeplinkUrl += DOT_OPERAND + PROCCODE + EQUAL_OPERAND + procedureCode;
      }

      if (appSession?.metaData?.locale === Locale.SPANISH) {
        deeplinkUrl += DOT_OPERAND + CULTURE + EQUAL_OPERAND + CULTURE_VALUE;
      }

      if (deeplinkUrl) {
        const deeplinkId = DEEP_LINK_ID + DOT_OPERAND + deeplinkUrl + AMPERSAND + DEEP_LINK_CMP;
        contentLabel = contentLabel.replace(/{deeplinkid}/gi, deeplinkId);
      } else {
        contentLabel = contentLabel.replace(/{deeplinkid}/gi, '');
      }
    }
    return contentLabel;
  }

  public static isValidString(key: unknown): boolean {
    let validRequest = false;
    if (key && typeof key === 'string' && key !== '' && key.trim().length) {
      validRequest = true;
    }
    return validRequest;
  }

  static swapEycCostInfo(showSPFI: boolean, provider: IProvider): ICostInfo {
    let eycCostInfo = {} as ICostInfo;
    if (showSPFI && provider?.eycAffliation?.affiliationList?.length > 0) {
      const affFacility = { ...provider.eycAffliation.affiliationList[0] };
      if (affFacility?.eycCostInfo) {
        eycCostInfo = affFacility.eycCostInfo;
      }
    } else if (provider?.eycCostInfo) {
      eycCostInfo = provider.eycCostInfo;
    }
    return eycCostInfo;
  }

  /**
   *
   * @param careType
   * @param appSession
   * @param vpcMagicLinkService
   * @param locale
   * @param windowObj
   * @param chatID
   * @returns SSO navigation to K-Health screen from virtual care option
   */
  public static redirectKHealth(careType: string, appSession: AppSession, vpcMagicLinkService: VpcMagicLinkSvc, windowObj: IWindow, chatID?: string): Promise<void | RedirectUrlResponse> {
    let magicLinkRequest: MagicLinkRequest;
    const digitalDataCustomer = window?.digitalData?.customer;
    const digitalDataCustomerRequest = {
      brand: digitalDataCustomer?.brand,
      relationship: digitalDataCustomer?.relationship,
      userRole: digitalDataCustomer?.userRole,
      underwritingState: digitalDataCustomer?.underwritingState,
      mbu: digitalDataCustomer?.mbu,
      paperlessBilling: digitalDataCustomer?.paperlessBilling,
      pcpStatus: digitalDataCustomer?.pcpStatus,
      productType: digitalDataCustomer?.productType,
      population: digitalDataCustomer?.population,
      sourceSystemId: digitalDataCustomer?.sourceSystemId,
      ageRange: digitalDataCustomer?.ageRange,
      subGroupId: digitalDataCustomer?.subGroupId,
      fundingType: digitalDataCustomer?.fundingType,
      spendingAccount: digitalDataCustomer?.spendingAccount,
      pharmacy: digitalDataCustomer?.pharmacy,
      snapPreferred: digitalDataCustomer?.snapPreferred,
      employerGroupId: digitalDataCustomer?.employerGroupId,
      zipDrugEligible: digitalDataCustomer?.zipDrugEligible,
      virtualCare: digitalDataCustomer?.virtualCare
    };
    magicLinkRequest = {
      sentMemberInfoToKHealth: appSession.hasSentMemberInfoToKHealth ?? false,
      digitalData: { customer: digitalDataCustomerRequest },
      isNewKHealthSSO: true
    };

    if (careType === VirtualCareEnums.CHAT) {
      magicLinkRequest.destination = {
        type: VirtualCareEnums.CHAT,
        data: chatID
      };
    }
    if (appSession.metaData.idToken) {
      magicLinkRequest.idToken = appSession.metaData.idToken;
    }

    let windowContext;
    return vpcMagicLinkService
      .getRedirectUrl(magicLinkRequest)
      .then((data: RedirectUrlResponse) => {
        if (data.vendorUrl) {
          if (windowContext) {
            windowContext.close();
          }
          data.additionalParams = {
            RelayState: data?.additionalParams.RelayState
          };
          return data;
        }
        appSession.hasSentMemberInfoToKHealth = data.sentMemberInfoToKHealth ?? false;
        windowContext.location.href = data.magicLink;
        windowContext.focus();
      })
      .catch(() => {
        if (windowContext) {
          windowContext.close();
        }
        throw new Error('API failed');
      });
  }

  /**
   *
   * @param appSession
   * @param virtualCareSSOUrlService
   * @param ssoKey
   * @returns SSO navigation to provider portal from search provider list & details page
   */
  public static virtualCareSSOURL(appSession: AppSession, virtualCareSSOUrlService: VirtualCareSSOUrlSvc, ssoKey: string): Promise<void | RedirectUrlResponse> {
    let virtualCareSSOURLReq: VirtualCareURLSSORequest;
    virtualCareSSOURLReq = {
      ssoKey: ssoKey
    };

    if (appSession.metaData.idToken) {
      virtualCareSSOURLReq.idToken = appSession.metaData.idToken;
    }
    return virtualCareSSOUrlService
      .getVirtualCareSSOUrl(virtualCareSSOURLReq)
      .then((data: RedirectUrlResponse) => {
        if (data.vendorUrl) {
          data.additionalParams = {
            RelayState: data?.additionalParams.RelayState
          };
          return data;
        }
      })
      .catch(() => {
        throw new Error('API failed');
      });
  }

  static isProdEmulation(appsession: AppSession): boolean {
    return appsession.appConfig?.production && appsession.isEmulation;
  }

  public static getSemImageUrl(appSession: AppSession): string {
    return appSession.appConfig?.images?.semBaseUrl || '';
  }

  static buildScenarioAlertRequest(appSession: AppSession, searchParams: ISearchParameters = {} as ISearchParameters) {
    const request: IScenarioAlertRequest = {
      planCategory: appSession?.searchParams?.plan?.category?.catCode || '',
      prefix: this.getPrefix(searchParams, appSession)?.toLocaleUpperCase() || '',
      mbu: appSession?.mbu || '',
      subGroupId: appSession?.subGroupId || '',
      brand: appSession?.metaData?.brandCd || '',
      prefixState: appSession?.alphaPrefixStateCode || '',
      geoState: appSession?.searchParams?.stateCd?.code || '',
      networkList: appSession?.searchParams?.plan?.networkList?.length ? appSession.searchParams.plan.networkList : [],
      locale: appSession?.metaData?.locale ? appSession.metaData.locale.toString() : Locale.ENGLISH,
      isSecureState: appSession?.isSecureState || false
    };
    if (searchParams) {
      request.specialities = searchParams.specialtySelectNm || [];
      request.taxonomies = searchParams.taxonomySelectNm || [];
      request.providerTypeCodeList = searchParams.providerTypeCodeList || [];
    }
    if (CommonUtil.isMemberSecure(appSession)) {
      request.mbrUid = appSession?.metaData?.appContract?.mbrUid;
      request.careType = appSession?.filterChangePlan?.careType ?? '';
      request.contractUid = appSession?.metaData?.appContract?.contractUid;
      request.coverageUid = appSession?.coverageUid;
    }
    if (appSession?.encryptedGroupId) {
      request.groupId = appSession.encryptedGroupId;
    }
    return request;
  }

  static toShowFeedbackOptionForTile(tile: { actionReasonCodes?: IActionReasonCodes }): boolean {
    return (
      !!(Array.isArray(tile?.actionReasonCodes?.positive) && tile.actionReasonCodes.positive.length) || !!(Array.isArray(tile?.actionReasonCodes?.negative) && tile.actionReasonCodes.negative.length)
    );
  }

  static getSpecialtyOrders(specialties: ISpecialty[], appSession: AppSession): ISpecialty[] {
    if (specialties?.length > 1 && appSession?.searchParams?.taxonomySelectNm?.length) {
      appSession.searchParams.taxonomySelectNm.forEach((code) => {
        const index = specialties.findIndex((spec) => spec.specialtyCode === code);
        if (index !== -1) {
          const specialty = specialties[index];
          specialties.splice(index, 1);
          specialties.unshift(specialty);
        }
      });
    }
    return specialties;
  }

  static getEycProcProviderType(eycProcProviderType: string): string {
    let provType = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
    switch (eycProcProviderType?.toLocaleUpperCase()) {
      case EYC_PROVIDER_CATEGORY_CODE.FACILITY:
      case PROVIDER_TYPE.BEHAVIORAL_HEALTH:
        provType = PROVIDER_TYPE.HOSPITAL;
        break;
      default:
        provType = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
        break;
    }
    return provType;
  }

  static isDentalSearch(hasDentalEYC: boolean, providerCategoryCode: string): boolean {
    return !!(hasDentalEYC && providerCategoryCode === PROVIDER_TYPE.DENTAL);
  }

  static getCopayWithPeriod(eycCostInfo: ICostInfo): string {
    if ((eycCostInfo?.copay && typeof eycCostInfo?.copay === 'number') || eycCostInfo?.copay === 0) {
      const _currencyPipe = new CurrencyPipe('en-US');
      let copay = _currencyPipe.transform(eycCostInfo.copay, 'USD', 'symbol', '1.0');
      if (eycCostInfo?.copayPeriodDesc) {
        copay = `${copay} ${eycCostInfo?.copayPeriodDesc}`;
      }
      return copay;
    } else {
      return '';
    }
  }

  static personalizedMatchPdfLink(appSession: AppSession, baseUrl: string): string {
    if (!baseUrl) {
      return;
    }
    return `${appSession.appConfig?.baseUrl[appSession?.state]}${appSession.appConfig?.wcsApi.baseDocumentURL}${baseUrl}`;
  }

  static getPdtStrKey(selectedProvider: IProvider): string {
    if (CommonUtil.isValidString(selectedProvider?.pdtStrKey)) {
      return selectedProvider.pdtStrKey;
    } else if (!isEmpty(selectedProvider?.pdtKey)) {
      return (
        (selectedProvider.pdtKey.planCode ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.productId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerNumber ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerMasterId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.locationMasterId ?? EMPTY_STRING)
      );
    }
    return EMPTY_STRING;
  }

  static getProviderTitles(titleList: Array<string>, networkRegisteredName: string, providerName: string): string {
    const result = titleList?.slice(0, 4).join(', ');
    const remainingCount = titleList?.length - 4;
    return networkRegisteredName ? networkRegisteredName : providerName + (result ? `, ${result}${remainingCount > 0 ? `, +${remainingCount}` : ''}` : '');
  }

  static isCoupeCoverageOnly(_appSession: AppSession) {
    if (Array.isArray(_appSession.bootstrap.contracts) && _appSession.bootstrap.contracts.length === 0 && FeatureManagerUtility.isFlagLive(FindCareFlag.COUPE_HEALTH_ONLY_PRODUCT, _appSession)) {
      _appSession.dos = DOS.COUPE_HEALTH;
      return true;
    }
    return false;
  }
}
