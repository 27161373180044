import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ProviderFlag } from '../../../../common/constants/common';
import { BaseComponent } from './../../../../common/components/core/baseCmp';
import { IProvider } from './../../../../result/interfaces/iProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-address-cmp',
  templateUrl: './providerAddressCmp.html'
})
export class ProviderAddressComponent extends BaseComponent {
  @Input() provider: IProvider;
  @Input() index: number;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  setDistance(distance: string): string {
    return parseFloat(distance).toFixed(2) + ' mi';
  }

  checkForCountyProvider(): boolean {
    return !this.provider?.providerFlags?.includes(ProviderFlag.HOME_HEALTH_CARE);
  }
}
