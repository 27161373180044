import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { SearchExecutionMode } from '../../../../common/constants/common';

@Component({
  moduleId: module.id,
  selector: 'app-fc-incorrect-data-cmp',
  templateUrl: './incorrectDataCmp.html'
})
export class IncorrectDataComponent extends BaseComponent implements OnInit {
  userForm: FormGroup;
  submitted = false;
  @Output()
  closeModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() submitEmail: EventEmitter<any> = new EventEmitter<any>();
  incorrectDetailContent = this.content?.result?.incorrectDataComponent;
  @Input() providerIncorrectInfo: any;
  @Input() providerDetails: any;
  contactUsURL: string;
  @Input() showErrorMsg: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private fb: FormBuilder
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.maxLength(100)]],
      lastName: ['', [Validators.required, Validators.maxLength(100)]],
      phoneNumber: ['', [Validators.required, Validators.pattern('^[0-9._%+-\\s]+$'), Validators.maxLength(15), Validators.minLength(10)]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')]],
      description: ['', [Validators.required]]
    });
    this.getContactUsUrl();
  }

  onSubmit(): void {
    if (this.userForm.valid) {
      this.submitEmail.emit(this.userForm.value);
    } else {
      this.userForm.markAllAsTouched();
    }
  }

  onCancel(): void {
    this.submitted = false;
    this.userForm.reset();
    this.closeModal.emit();
  }

  onClose() {
    this.closeModal.emit();
  }

  getContactUsUrl() {
    const contactUsPath = this._appSession.appState?.executionMode === SearchExecutionMode.SECURE ? '/member/contact-us' : 'https://www.anthem.com/contact-us';
    this.contactUsURL = this.content?.result?.incorrectDataComponent?.labels?.contactInfo.replace(/{contactUs}/gi, contactUsPath);
  }

  getMailToLink(email: string[]): string {
    if (!email || email.length === 0) {
      return '';
    }

    const htmlBody = `
    Thank you for reporting incorrect information about this care provider.

    Name: ${this.providerDetails?.providerName}
    Location: ${this.providerDetails?.address?.addressOne}, ${this.providerDetails?.address?.addressTwo}
    National Provider Identifier (NPI): ${this.providerDetails?.nationalProviderIdentifier}

    Please include your information. We'll only use this information if we need to contact you for more details.

    Your First Name:
    Your Last Name:
    Your Phone Number:
    Preferred Email:

    Tell us what information is incorrect. (Feel free to add the correct information if you know it.) Please keep in mind that we'll not be able to answer any questions submitted with this email. If you have questions about your health plan, claims, or benefits, please call the Member Services number on your Member ID card.

    Add your comments here:`;

    const subject1 = this._appSession.appConfig?.production ? 'Provider Inaccuracy' : 'Elevance Health Test only';
    let cc: string[];
    if (email?.length > 1) {
      cc = email?.slice(1);
    }
    return `mailto:${email[0]}?subject=${encodeURIComponent(subject1)}&body=${encodeURIComponent(htmlBody)}&cc=${cc ?? ''}`;
  }
}
