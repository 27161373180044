import { EventEmitter, Injectable } from '@angular/core';
import { REVIEW_SELECTIONS, SELECT_MEMBER, SELECT_PCP_ID, SELECT_PLAN, SELECT_REASON } from '../constants/steps';
import { StepChangeEvent } from '../events/stepChangeEvent';
import { StepAutoSkipEvent } from './../events/stepChangeEvent';
import { IStep } from './../models/iStep';

@Injectable()
export class Stepper {
  showPrevBtn: boolean = false;
  showNextBtn: boolean = false;

  curStep: number = 0;
  curStepCd: string;
  step: number = 0;
  total: number = 0;

  private s1SelectPlan: IStep = { code: SELECT_PLAN, skip: false };
  private s2SelectMbr: IStep = { code: SELECT_MEMBER, skip: false };
  private s3SelectPcp: IStep = { code: SELECT_PCP_ID, skip: false };
  private s4SelectReason: IStep = { code: SELECT_REASON, skip: false };
  private s5Review: IStep = { code: REVIEW_SELECTIONS, skip: false };

  readonly onChange = new EventEmitter<StepChangeEvent>();
  readonly onAutoSkip = new EventEmitter<StepAutoSkipEvent>();

  private _steps: IStep[];
  private _availableSteps: IStep[];
  private _curStepIndex: number = -1;
  private _stepsMap: { [key: string]: IStep } = {};

  constructor() {
    this.init();
  }

  init() {
    //initialize the steps
    this._steps = [this.s1SelectPlan, this.s2SelectMbr, this.s3SelectPcp, this.s4SelectReason, this.s5Review];
    this._availableSteps = [...this._steps];
    //initialize the steps map
    this._steps.forEach((s) => {
      s.skip = false;
      this._stepsMap[s.code] = s;
    });

    this._curStepIndex = -1;
    this.curStepCd = '';

    this.step = 1;
    this.total = this._availableSteps.length;
  }

  /**
   * Call this function to start the stepper from the beginning.
   * Note: first 'n' steps are marked for skipping, then they will be skipped.
   */
  start() {
    this._curStepIndex = -1;
    this.next();
  }

  next() {
    if (this._curStepIndex < this._steps.length - 1) {
      this._curStepIndex++;
      const step = this._steps[this._curStepIndex];
      if (step.skip) {
        const evt = new StepAutoSkipEvent();
        evt.step = this._curStepIndex + 1;
        evt.stepName = step.code;
        evt.goingForward = true;
        this.onAutoSkip.emit(evt);
        this.next();
        return;
      }
      this.update();
    }
  }

  previous() {
    if (this._curStepIndex > 0) {
      this._curStepIndex--;
      const step = this._steps[this._curStepIndex];
      if (step.skip) {
        //auto skip
        const evt = new StepAutoSkipEvent();
        evt.step = this._curStepIndex + 1;
        evt.stepName = step.code;
        evt.goingBackward = true;
        this.onAutoSkip.emit(evt);
        this.previous();
        return;
      }
      this.update();
    }
  }

  skipStep(stepCd: string) {
    const step = this._stepsMap[stepCd];
    if (!step) {
      return;
    }
    step.skip = true;
    this._availableSteps = this._steps.filter((s) => {
      return !s.skip;
    });
    this.updateStepNum();
  }

  includeStep(stepCd: string) {
    const step = this._stepsMap[stepCd];
    if (!step) {
      return;
    }
    step.skip = false;
    this._availableSteps = this._steps.filter((s) => {
      return !s.skip;
    });
    this.updateStepNum();
  }

  isSkipped(stepCd: string): boolean {
    const step = this._stepsMap[stepCd];
    return step && step.skip;
  }

  private update() {
    this.curStep = this._curStepIndex + 1;
    this.curStepCd = this._steps[this._curStepIndex].code;

    this.showPrevBtn = true;
    this.showNextBtn = true;

    if (this._curStepIndex === 0) {
      this.showPrevBtn = false;
      this.showNextBtn = true;
    }

    if (this._curStepIndex === this._steps.length - 1) {
      this.showPrevBtn = true;
      this.showNextBtn = false;
    }

    if (this.curStepCd === SELECT_MEMBER && this.s1SelectPlan.skip) {
      this.showPrevBtn = false;
    }

    if (this.curStepCd === SELECT_PCP_ID && this.s2SelectMbr.skip && this.s1SelectPlan.skip) {
      this.showPrevBtn = false;
    }

    if (this.curStepCd === SELECT_REASON && this.s3SelectPcp.skip && this.s2SelectMbr.skip && this.s1SelectPlan.skip) {
      this.showPrevBtn = false;
    }

    if (this.curStepCd === REVIEW_SELECTIONS && this.s4SelectReason.skip && this.s3SelectPcp.skip && this.s2SelectMbr.skip && this.s1SelectPlan.skip) {
      this.showPrevBtn = false;
    }

    this.updateStepNum();
    // Emit the page change event
    const stepEvt = new StepChangeEvent();
    stepEvt.step = this.step;
    stepEvt.total = this.total;
    stepEvt.stepName = this.curStepCd;
    this.onChange.emit(stepEvt);
  }

  private updateStepNum() {
    const stepIndex = this._availableSteps.findIndex((s) => {
      return s.code === this.curStepCd;
    });
    this.step = stepIndex + 1;
    this.total = this._availableSteps.length;
  }
}
