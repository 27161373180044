import { DatePipe, TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IAlert, IRadioInput } from '@anthem/uxd/util';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { IMemberPcpDetailsRequest, IMemberPcpDetailsResponse } from '../models/iMemberPcpDetails';
import { IRadioGroupModel } from './../../../common/components/radio-group/iRadioItem';
import { INFORMATION } from './../../../common/constants/alertTypes';
import { ASSIGN_PCP_SUPPRESSED_ERROR_CODE, DATE, DATE_FORMAT, ERR_INVALID_PCP_DETAILS_API_RESP, ERR_MBR_PCP_EFF_DT_PAST_END_DT, PCP_NOTREQUIRED, PCP_OPTIONAL, PCP_REQUIRED, PLAN_NAME } from './../../../common/constants/app-constants';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { IAffliliationsModel } from './../../../common/interfaces/iPCPInfo';
import { ACTIVE_COVERAGE, BOTH_COVERAGES, FUTURE_COVERAGE } from './../../../common/interfaces/iPcpRequest';
import { ApiHelper } from './../../../common/services/apiHelper';
import { DataHelper } from './../../../common/services/dataHelper';
import { ContentHelper } from './../../../common/values/contentHelper';
import { ICoverageRadioInput, IMembersModel, IPcpEligibleMemberContract } from './../models/iMemberPcpDetails';
import { MemberPcpDetailsService } from './memberPcpDetailsSvc';

@Injectable()
export class MemberPcpDetailsApiHelper extends ApiHelper<IMemberPcpDetailsRequest, IMemberPcpDetailsResponse> {
  apiResp: IMemberPcpDetailsResponse;

  private _localCacheMap: { [key: string]: IMemberPcpDetailsResponse };
  private _mbrsModelMap: { [key: string]: IMembersModel } = {};
  private _activeContract: IPcpEligibleMemberContract;
  private _futureContract: IPcpEligibleMemberContract;
  private _defaultContract: IPcpEligibleMemberContract;
  private _selectedContractUid: string = '';
  private _disRBMapByPCPNotRequired = { disable_A: false, disable_F: false, disable_B: false };
  private _disRBMapByPcpAvailability = { disable_A: false, disable_F: false, disable_B: false };

  plansRadioGroupModel: IRadioGroupModel<ICoverageRadioInput> = {
    id: 'pf-plans-radio-group',
    label: '',
    name: 'pf-plans-radio-group',
    ariaLabel: '',
    isOptional: false,
    alignVertical: true,
    options: [],
    legendSrOnly: true,
    showLegendAsLbl: false
  };

  hasBothContracts = false;
  hasActiveContract = false;
  hasFutureContract = false;
  hasPcpRequiredContract = false;
  hasPcpOptionalContract = false;
  hasPcpOptionalOnBothContract = false;
  isSingleContract = false;

  hasPcpNotRequiredContract = false;
  hasPcpIdNotAvailableContract = false;

  pcpNotReqAlert: IAlert = { alertType: INFORMATION };
  pcpIdNotAvailableAlert: IAlert = { alertType: INFORMATION };

  constructor(
    private svc: MemberPcpDetailsService,
    private _dataHlpr: DataHelper,
    private _contentHlpr: ContentHelper,
    private _datePipe: DatePipe,
    private _titleCasePipe: TitleCasePipe,
    private _router: Router
  ) {
    super();
    this._localCacheMap = {};
  }

  invoke(request: IMemberPcpDetailsRequest): Promise<IMemberPcpDetailsResponse> {
    this.reset();
    const detailApiKey = request.defaultContractUid + '-' + request.mbrUid;
    const existingResp = this._localCacheMap[detailApiKey];
    //Call Member PCP Detail API only once
    if (existingResp) {
      this.isProgress = false;
      this.isSuccess = true;
      this.isFailure = false;
      return Promise.resolve(existingResp);
    }
    return super.invoke(request);
  }

  execute(request: IMemberPcpDetailsRequest): Promise<IMemberPcpDetailsResponse> {
    this._selectedContractUid = request.defaultContractUid;
    return this.svc.getMemberPcpDetails(request);
  }

  onSuccess(resp: IMemberPcpDetailsResponse, request: IMemberPcpDetailsRequest): void {
    this.validateResp(resp);
    this.apiResp = resp;

    this._activeContract = resp.contracts.find((contract) => {
      return this._dataHlpr.areEqualStrings(contract.contractStatus, ACTIVE_COVERAGE);
    });

    this._futureContract = resp.contracts.find((contract) => {
      return this._dataHlpr.areEqualStrings(contract.contractStatus, FUTURE_COVERAGE);
    });

    this._defaultContract = resp.contracts.find((contract) => {
      return contract.defaultContract;
    });

    const pcpRequiredContract = resp.contracts.find((contract) => {
      return this._dataHlpr.areEqualStrings(contract.pcpIndicator, PCP_REQUIRED);
    });

    const pcpOptionalContract = resp.contracts.find((contract) => {
      return this._dataHlpr.areEqualStrings(contract.pcpIndicator, PCP_OPTIONAL);
    });

    this.hasActiveContract = this._activeContract !== undefined;
    this.hasFutureContract = this._futureContract !== undefined;
    this.hasPcpRequiredContract = pcpRequiredContract !== undefined;
    this.hasPcpOptionalContract = pcpOptionalContract !== undefined;
    this.hasBothContracts = this.hasActiveContract && this.hasFutureContract;
    this.hasPcpOptionalOnBothContract = this.hasBothContracts && this._dataHlpr.areEqualStrings(this._activeContract.pcpIndicator, PCP_OPTIONAL) && this._dataHlpr.areEqualStrings(this._futureContract.pcpIndicator, PCP_OPTIONAL);
    this.isSingleContract = this.apiResp.contracts.length === 1;

    this.segregateMbrs(resp);

    const detailApiKey = request.defaultContractUid + '-' + request.mbrUid;
    this._localCacheMap[detailApiKey] = resp;
  }

  onFailure(errResp): void {
    this.plansRadioGroupModel.options = [];
  }

  validateResp(resp: IMemberPcpDetailsResponse) {
    const invalidDataExcpn: IException = { code: ERR_INVALID_PCP_DETAILS_API_RESP, message: 'Invalid data received form API.' };
    if (resp.suppressAssignPcp !== false) {
      const excptn: IException = { code: ASSIGN_PCP_SUPPRESSED_ERROR_CODE, message: 'Assigning PCP is suppressed' };
      throw excptn;
    }

    if (!Array.isArray(resp.contracts) || resp.contracts.length === 0) {
      throw invalidDataExcpn;
    }

    const hasInvalidContract = resp.contracts.some((c) => {
      if (!c) {
        return true;
      }
      if (this._dataHlpr.isEmptyString(c.contractUid)) {
        return true;
      }
      const isValidStatus = [ACTIVE_COVERAGE, FUTURE_COVERAGE].some((s) => {
        return this._dataHlpr.areEqualStrings(s, c.contractStatus);
      });
      return !isValidStatus;
    });

    if (hasInvalidContract) {
      throw invalidDataExcpn;
    }
  }

  getMembersModel(covStatusCode: string): IMembersModel {
    return this._mbrsModelMap[covStatusCode];
  }

  /**
   * Get contract by given contractUid
   * @param contractUid
   * @returns
   */
  getContract(contractUid: string): IPcpEligibleMemberContract {
    const contract = this.apiResp?.contracts?.find((c) => {
      return this._dataHlpr.areEqualStrings(c.contractUid, contractUid);
    });
    if (!contract) {
      throw new Error(`There is no contract found in Member Pcp Details api response macthing the contractUid '${contractUid}'`);
    }
    return contract;
  }

  /**
 * Get active or future contract by given contractStatus
 * @param contractStatus
 * @returns
 */
  getContractByStatus(contractStatus: string): IPcpEligibleMemberContract {
    if (this._dataHlpr.areEqualStrings(contractStatus, ACTIVE_COVERAGE)) {
      return this._activeContract;
    }
    if (this._dataHlpr.areEqualStrings(contractStatus, FUTURE_COVERAGE)) {
      return this._futureContract;
    }
    if (this._dataHlpr.areEqualStrings(contractStatus, BOTH_COVERAGES)) {
      return this._defaultContract;
    }
  }

  /**
   * This method throws an exception when the mbrPcpEffectiveDt is past the contract effectiveDt
   * Otherwise this method will not throw any errors.
   * Caller should catch and handle this exception.
   */
  validateMbrPcpEffectiveDate() {
    const excptn = { code: ERR_MBR_PCP_EFF_DT_PAST_END_DT, message: '', terminationDt: '' };
    if (this.hasBothContracts) {
      if (this._activeContract.pcpMbrEffDtPastContractEndDt &&
        this._futureContract.pcpMbrEffDtPastContractEndDt) {
        //use the defautl contract's terminationDt
        excptn.terminationDt = this._defaultContract.terminationDt;
        throw excptn;
      }
      return;
    }

    if (this.hasActiveContract && this._activeContract.pcpMbrEffDtPastContractEndDt) {
      excptn.terminationDt = this._activeContract.terminationDt;
      throw excptn;
    }

    if (this.hasFutureContract && this._futureContract.pcpMbrEffDtPastContractEndDt) {
      excptn.terminationDt = this._futureContract.terminationDt;
      throw excptn;
    }
  }

  /**
   * This method throws an exception when the mbrPcpEffectiveDt is past the contract effectiveDt
   * Otherwise this method will not throw any errors.
   * Caller should catch and handle this exception.
   */
  validateMbrPcpEffectiveDateByStatus(contractStatusCd: string) {
    const excptn = { code: ERR_MBR_PCP_EFF_DT_PAST_END_DT, message: '', terminationDt: '' };
    if (this._dataHlpr.areEqualStrings(contractStatusCd, BOTH_COVERAGES) && this.hasBothContracts) {
      if (this._activeContract.pcpMbrEffDtPastContractEndDt && this._futureContract.pcpMbrEffDtPastContractEndDt) {
        //use the defautl contract's terminationDt
        excptn.terminationDt = this._defaultContract.terminationDt;
        throw excptn;
      }
      if (this._activeContract.pcpMbrEffDtPastContractEndDt) {
        excptn.terminationDt = this._activeContract.terminationDt;
        throw excptn;
      }
      if (this._futureContract.pcpMbrEffDtPastContractEndDt) {
        excptn.terminationDt = this._futureContract.terminationDt;
        throw excptn;
      }
      return;
    }
    if (this._dataHlpr.areEqualStrings(contractStatusCd, ACTIVE_COVERAGE) &&
      this.hasActiveContract && this._activeContract.pcpMbrEffDtPastContractEndDt) {
      excptn.terminationDt = this._activeContract.terminationDt;
      throw excptn;
    }
    if (this._dataHlpr.areEqualStrings(contractStatusCd, FUTURE_COVERAGE) &&
      this.hasFutureContract && this._futureContract.pcpMbrEffDtPastContractEndDt) {
      excptn.terminationDt = this._futureContract.terminationDt;
      throw excptn;
    }
  }

  createPlanRadioInputs(afflnsModel: IAffliliationsModel) {
    this.createDisabledPlansMap1();
    this.createDisabledPlansMap2(afflnsModel);

    const content = this.getContent();
    this.plansRadioGroupModel.options = [];

    this.apiResp.contracts.forEach((contract, i) => {
      const label = this.getFormatedPlanName(contract);
      const value = contract.contractStatus;
      const isPcpNotRequired = this._dataHlpr.getValueByKey<boolean>('disable_' + contract.contractStatus, this._disRBMapByPCPNotRequired, false);
      const isPcpIdNotAvailable = this._dataHlpr.getValueByKey<boolean>('disable_' + contract.contractStatus, this._disRBMapByPcpAvailability, false);
      const disabled = isPcpNotRequired || isPcpIdNotAvailable;
      const analytics = this._dataHlpr.getValueByKey<string>('planRadioBtn_' + contract.contractStatus, content.btnDataAnalytics, '');
      const ro: ICoverageRadioInput = {
        label, value, disabled, isPcpNotRequired, isPcpIdNotAvailable,
        ariaLabel: this.getPlanAriaLabel(contract), isSelected: false, analytics, analyticsText: ''
      };
      this.plansRadioGroupModel.options.push(ro);
    });

    if (this.hasBothContracts) {
      const label = this.getFormatedPlanName({ contractStatus: BOTH_COVERAGES, plans: [{ planNm: content.both }] } as IPcpEligibleMemberContract);
      const isPcpNotRequired = this._dataHlpr.getValueByKey<boolean>('disable_B', this._disRBMapByPCPNotRequired, false);
      const isPcpIdNotAvailable = this._dataHlpr.getValueByKey<boolean>('disable_B', this._disRBMapByPcpAvailability, false);
      const disabled = isPcpNotRequired || isPcpIdNotAvailable;
      const analytics = this._dataHlpr.getValueByKey<string>('planRadioBtn_B', content.btnDataAnalytics, '');

      this.plansRadioGroupModel.options.push({
        label,
        value: BOTH_COVERAGES,
        isPcpNotRequired,
        isPcpIdNotAvailable,
        disabled,
        hidden: this.apiResp.isNssPlan,
        ariaLabel: '',
        isSelected: false,
        analytics,
        analyticsText: ''
      });
    }
  }

  /**
   * Check if the current route is FCR route
   * @returns true if the current route is FCR route
   */
  private isFCRRoute(): boolean {
    const routesWithNewDesign = [AppNavigations.FCR_HOME_PATH, AppNavigations.FCR_LANDING_PATH, AppNavigations.FCR_RESULT_PATH,  AppNavigations.FCR_CARE_NOW];
    return routesWithNewDesign.includes(this._router.url);
  }

  private getContent() {
    if (this.isFCRRoute()) {
      return this._contentHlpr.getAllContent()?.common?.assignPcpCmpContent;
    } else {
      return this._contentHlpr.getContent('PFAssignPcpContainerComponent')['assignPcpCmpContent'];
    }
  }

  private segregateMbrs(resp: IMemberPcpDetailsResponse) {
    this._mbrsModelMap = {};

    resp.contracts.forEach((contract) => {
      const statusCd = contract.contractStatus;
      let mbrsModel = this._mbrsModelMap[statusCd];
      if (!mbrsModel) {
        mbrsModel = {
          statusCd,
          selectableMbrs: [],
          nonSelectableMbrs: []
        }
        this._mbrsModelMap[statusCd] = mbrsModel;
      }

      contract.members?.forEach((mbr) => {
        mbrsModel.selectableMbrs.push(mbr);
      });
    });

    if (this.hasBothContracts) {
      this.createBothCovMbrsModel();
    }
    this.createMbrRadioInputs();
  }

  private createBothCovMbrsModel() {
    const bothCovMbrsModel: IMembersModel = {
      statusCd: BOTH_COVERAGES,
      selectableMbrs: [],
      nonSelectableMbrs: []
    };
    this._mbrsModelMap[BOTH_COVERAGES] = bothCovMbrsModel;
    const activeCovMbrsModel = this._mbrsModelMap[ACTIVE_COVERAGE];
    const futureCovMbrsModel = this._mbrsModelMap[FUTURE_COVERAGE];
    const hasAlreadyPicked: { [key: string]: boolean } = {};

    //First, iterate through members of active plan
    activeCovMbrsModel.selectableMbrs.forEach((mbr1) => {
      //mbr1 is from Active contract
      //mbr2 is from Future contract
      const mbrFoundInBoth = futureCovMbrsModel.selectableMbrs.some(mbr2 => {
        return mbr1.mbrUid === mbr2.mbrUid;
      });
      if (mbrFoundInBoth) {
        bothCovMbrsModel.selectableMbrs.push(mbr1);
      } else {
        bothCovMbrsModel.nonSelectableMbrs.push(mbr1);
        futureCovMbrsModel.nonSelectableMbrs.push(mbr1);
      }
      hasAlreadyPicked[mbr1.mbrUid] = true;
    });

    //Second, iterate through members of future plan
    futureCovMbrsModel.selectableMbrs.forEach((mbr2) => {
      if (hasAlreadyPicked[mbr2.mbrUid]) {
        return;
      }
      bothCovMbrsModel.nonSelectableMbrs.push(mbr2);
      activeCovMbrsModel.nonSelectableMbrs.push(mbr2);
    });
  }

  private createMbrRadioInputs() {
    const content = this.getContent();

    for (const statusCd in this._mbrsModelMap) {
      const mbrsModel = this._mbrsModelMap[statusCd];
      [...mbrsModel.selectableMbrs, ...mbrsModel.nonSelectableMbrs].forEach((mbr) => {
        const defaultRlnshpCd = mbr.relationshipCode ? '(' + this._titleCasePipe.transform(mbr.relationshipCode) + ')' : '';
        mbr.relationshipDesc = this._dataHlpr.getValueByKey<string>(mbr.relationshipCode, content.relationship, defaultRlnshpCd);
        const memRadioInput: IRadioInput = {
          id: `pf-assign-pcp-mbr-${mbr.mbrUid}`,
          value: mbr.mbrUid,
          label: `${mbr.firstName} ${mbr.lastName}`.toUpperCase(),
          name: `pf-assign-pcp-mbr-${mbr.mbrUid}-name`
        };
        mbr.ro = memRadioInput;
      });

      mbrsModel.hasSelectableMbrs = mbrsModel.selectableMbrs.length > 0;
      mbrsModel.hasNonSelectableMbrs = mbrsModel.nonSelectableMbrs.length > 0;

      let alertContent = content.memWarning_plural;
      if (mbrsModel.nonSelectableMbrs.length === 1) {
        alertContent = content.memWarning_singular;
      }
      mbrsModel.alert = {
        alertType: 'information',
        alertContent
      };
    }
  }

  private createDisabledPlansMap1() {
    const content = this.getContent();
    this.hasPcpNotRequiredContract = false;
    if (this.isSingleContract) {
      return;
    }

    this._disRBMapByPCPNotRequired.disable_A = false;
    this._disRBMapByPCPNotRequired.disable_F = false;
    this._disRBMapByPCPNotRequired.disable_B = false;

    //User is searching provider on Active plan in FAD & the future plan is PCP_NOT_REQUIRED
    if (this._selectedContractUid === this._activeContract.contractUid) {
      if (this._futureContract.pcpIndicator === PCP_NOTREQUIRED) {
        this._disRBMapByPCPNotRequired.disable_F = true;
        this._disRBMapByPCPNotRequired.disable_B = true;
        this.hasPcpNotRequiredContract = true;

        let alertContent = this._dataHlpr.getValueByKey<string>('pcpNotReqAlertMsg_A', content);
        const planName = this.getFormatedPlanName(this._futureContract);
        alertContent = alertContent.replace(PLAN_NAME, planName);
        this.pcpNotReqAlert.alertContent = alertContent;
      }
    }

    //User is searching provider on Future plan in FAD & the active plan is PCP_NOT_REQUIRED
    if (this._selectedContractUid === this._futureContract.contractUid) {
      if (this._activeContract.pcpIndicator === PCP_NOTREQUIRED) {
        this._disRBMapByPCPNotRequired.disable_A = true;
        this._disRBMapByPCPNotRequired.disable_B = true;
        this.hasPcpNotRequiredContract = true;

        let alertContent = this._dataHlpr.getValueByKey<string>('pcpNotReqAlertMsg_F', content);
        const planName = this.getFormatedPlanName(this._activeContract);
        alertContent = alertContent.replace(PLAN_NAME, planName);
        this.pcpNotReqAlert.alertContent = alertContent;
      }
    }
  }

  private createDisabledPlansMap2(afflnsModel: IAffliliationsModel) {
    if (!afflnsModel) {
      return;
    }
    const content = this.getContent();
    this.hasPcpIdNotAvailableContract = false;
    if (this.isSingleContract) {
      return;
    }
    this._disRBMapByPcpAvailability.disable_A = false;
    this._disRBMapByPcpAvailability.disable_F = false;
    this._disRBMapByPcpAvailability.disable_B = false;

    //Check Active contract
    const n1 = afflnsModel.activeAffiliations.reduce((accumulator, curValue) => {
      return accumulator + curValue.activePcpIds.length;
    }, 0);

    if (n1 === 0) {
      this._disRBMapByPcpAvailability.disable_A = true;
      this._disRBMapByPcpAvailability.disable_B = true;
      this.hasPcpIdNotAvailableContract = true;

      let alertContent = this._dataHlpr.getValueByKey<string>('pcpNotReqAlertMsg_F', content);
      const planName = this.getFormatedPlanName(this._activeContract);
      alertContent = alertContent.replace(PLAN_NAME, planName);
      this.pcpIdNotAvailableAlert.alertContent = alertContent;
    }

    //Check Future contract
    const n2 = afflnsModel.futureAffiliations.reduce((accumulator, curValue) => {
      return accumulator + curValue.futurePcpIds.length;
    }, 0);
    if (n2 === 0) {
      this._disRBMapByPcpAvailability.disable_F = true;
      this._disRBMapByPcpAvailability.disable_B = true;
      this.hasPcpIdNotAvailableContract = true;

      let alertContent = this._dataHlpr.getValueByKey<string>('pcpNotReqAlertMsg_A', content);
      const planName = this.getFormatedPlanName(this._futureContract);
      alertContent = alertContent.replace(PLAN_NAME, planName);
      this.pcpIdNotAvailableAlert.alertContent = alertContent;
    }
  }

  private getFormatedPlanName(contract: IPcpEligibleMemberContract): string {
    const content = this.getContent();
    const plan = contract.plans[0];
    const planNmCssCls = 'pcp-plan-name';
    const planNmSuffixCssCls = 'pcp-plan-name-suffix';

    const elmPlanNm = `<span class='${planNmCssCls}'>${plan.planNm}</span>`;
    let elmPlanNmSuffix = '';

    if (contract.contractStatus === ACTIVE_COVERAGE) {
      elmPlanNmSuffix = `<span class='${planNmSuffixCssCls}'>${content.activeStatus}</span>`;
    }
    if (contract.contractStatus === FUTURE_COVERAGE) {
      const futureStatus = content.futureStatus.replace(DATE, this._datePipe.transform(plan.planStartDate, DATE_FORMAT));
      elmPlanNmSuffix = `<span class='${planNmSuffixCssCls}'>${futureStatus}</span>`
    }
    return `${elmPlanNm} ${elmPlanNmSuffix}`;
  }

  private getPlanAriaLabel(contract: IPcpEligibleMemberContract): string {
    const plan = contract?.plans?.[0];
    if (!plan) {
      return '';
    }

    const content = this.getContent();
    let planNmSuffix = '';
    if (this._dataHlpr.areEqualStrings(contract.contractStatus, ACTIVE_COVERAGE)) {
      planNmSuffix = content.activeStatus;
    }
    if (this._dataHlpr.areEqualStrings(contract.contractStatus, FUTURE_COVERAGE)) {
      const futureStatus = content.futureStatus.replace(DATE, this._datePipe.transform(plan.planStartDate, DATE_FORMAT));
      planNmSuffix = futureStatus;
    }
    return `${plan.planNm} ${planNmSuffix}`;
  }
}
