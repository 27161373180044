import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { AppExternalCommands, SEARCH_TYPE } from '../../../common/constants/app-constants';
import { AppEvents } from '../../../common/enums/appEvents';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { ITranslation } from '../../search-providers/interfaces/iAdaListResp';
import { IFtsSuggestion } from '../../search-providers/interfaces/iFtsClientInteraction';
import { IProcedureLookup } from '../../search-providers/interfaces/iProcedureLookup';
import { AvailableSuggestionService } from './../services/availableSuggestionSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fad-fts-available-suggestion-cmp',
  templateUrl: '../views/pfFtsAvailableSuggestionCmp.html',
  providers: []
})
export class PFFtsAvailableSuggestionComponent extends BaseComponent implements OnInit, OnDestroy {
  searchTerm: string = '';
  searchCriteriaToDisplay: string = '';
  specialtyIes: string = '';
  otherSuggestions: IFtsSuggestion<ITranslation>[];
  otherSuggestionsDescriptions: string[];
  alternateSearchOptions: string[];
  isBehavioralSearch: boolean = false;
  descriptionMaxLength: number;

  @Output()
  onSelect: EventEmitter<IFtsSuggestion<ITranslation>> = new EventEmitter<IFtsSuggestion<ITranslation>>();
  @Output()
  procedureSelect: EventEmitter<IProcedureLookup> = new EventEmitter<IProcedureLookup>();
  @Input()
  suggestions: IFtsSuggestion<ITranslation>[];
  procedures: IProcedureLookup[];
  dismissFtsResultSummary: boolean;

  private suggestionSubscription: Subscription;
  private procedureSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _availableSuggestionSvc: AvailableSuggestionService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchResultsContainerComponent');

    this.suggestionSubscription = this._availableSuggestionSvc.getSuggestions.subscribe((data: IFtsSuggestion<ITranslation>[]) => {
      if (data) {
        this.bindSuggestions(data);
      }
    });

    this.procedureSubscription = this._availableSuggestionSvc.getProcedureList.subscribe((data: string[]) => {
      if (data) {
        this.bindProcedures(data);
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.descriptionMaxLength = this._appSession.appConfig?.features.freeTextSearch.descriptionMaxLength;
  }

  ngOnDestroy(): void {
    if (this.suggestionSubscription) {
      this._availableSuggestionSvc.setSuggestion(undefined);
      this.suggestionSubscription.unsubscribe();
    }
    if (this.procedureSubscription) {
      this._availableSuggestionSvc.setProcedureList(undefined);
      this.procedureSubscription.unsubscribe();
    }
  }

  getProcedureDescription(procedure: string): string {
    return procedure.length > this.descriptionMaxLength ? `${procedure.slice(0, this.descriptionMaxLength)}...` : procedure;
  }

  getSuggestionDescription(index): string {
    const description = this.otherSuggestionsDescriptions[index];
    return description.length > this.descriptionMaxLength ? `${description.slice(0, this.descriptionMaxLength)}...` : description;
  }

  hasSpecialty(suggestion: IFtsSuggestion<ITranslation>): boolean {
    const criteria = suggestion?.criteria;
    return criteria?.specialtyCategoryList?.length > 0 || criteria?.taxonomyList?.length > 0 || criteria?.providerName !== '' || criteria?.npi !== '' || false;
  }

  hasProcedure(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.procedureCode !== '' && suggestion?.metaData?.providerCategoryCode !== '' && suggestion?.criteria?.procedure?.name !== '') || false;
  }

  hasMedicalCode(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.medicalCode !== '' && suggestion?.text !== '' && suggestion?.metaData?.medicalTypeCode !== '') || false;
  }

  bindSuggestions(suggestion: IFtsSuggestion<ITranslation>[]) {
    this.dismissFtsResultSummary = false;
    this.searchTerm = this._appSession.searchTerm;
    this.searchCriteriaToDisplay = '';
    this.otherSuggestions = [];
    if (suggestion?.length && (this.hasSpecialty(suggestion[0]) || this.hasProcedure(suggestion[0]) || this.hasMedicalCode(suggestion[0]))) {
      this.suggestions = suggestion;
      this.searchCriteriaToDisplay = this.displaySearchCriteria(suggestion[0]);
      this.displayOtherSuggestions();
    }
  }

  bindProcedures(procedure: string[]) {
    this.alternateSearchOptions = [];
    if (procedure.length) {
      this.alternateSearchOptions.push(...procedure);
    }
  }

  onSuggestionSelect(suggestion: IFtsSuggestion<ITranslation>) {
    this.onSelect.emit(suggestion);
  }

  onAlternateProcedureSelect(procedure: IProcedureLookup) {
    this._availableSuggestionSvc.setSuggestion([]);
    this._availableSuggestionSvc.setProcedureList([]);
    this.procedureSelect.emit(procedure);
  }

  handleSpecialtySearch(suggestion: IFtsSuggestion<ITranslation>): string {
    const criteriaDisplaySet = new Set<string>();
    (suggestion.criteria.specialtyCategoryList || []).forEach((category: ITranslation) => {
      criteriaDisplaySet.add(category.name);
    });
    (suggestion.criteria.taxonomyList || []).forEach((taxonomy: ITranslation) => {
      criteriaDisplaySet.add(taxonomy.name);
    });
    let searchCriteria = Array.from(criteriaDisplaySet);
    //Add NPI if any
    if (suggestion.criteria?.npi) {
      searchCriteria.push(this.content.freeTextSearch?.npi + ' ' + suggestion.criteria?.npi);
    }
    //Add provider name if any
    if (suggestion.criteria?.providerName) {
      searchCriteria.push(this.content.freeTextSearch?.name + suggestion.criteria?.providerName);
    }
    //Add gender if any
    if (suggestion.criteria?.genderList?.length === 1) {
      if (suggestion.criteria?.genderList[0] === 'F') {
        searchCriteria.push(this.content.freeTextSearch?.femaleDoctor);
      } else {
        searchCriteria.push(this.content.freeTextSearch?.maleDoctor);
      }
    }
    if (suggestion.criteria?.ableToServeAsPcp) {
      searchCriteria.push(this.content.freeTextSearch?.serveAsPcp);
    }
    if (suggestion.criteria?.acceptsNewPatient) {
      searchCriteria.push(this.content.freeTextSearch?.acceptsNewPatients);
    }
    if (suggestion.criteria?.languageList?.length > 0) {
      const criteriaLanguageSet = new Set<string>();
      (suggestion.criteria.languageList || []).forEach((language) => {
        criteriaLanguageSet.add(language.name);
      });
      searchCriteria.push(this.content.freeTextSearch?.whoSpeaks + Array.from(criteriaLanguageSet).join(', '));
    }
    return searchCriteria.join(', ');
  }

  displaySearchCriteria(suggestion: IFtsSuggestion<ITranslation>): string {
    switch (suggestion.type) {
      case SEARCH_TYPE.SPECIALTY:
        return this.handleSpecialtySearch(suggestion);
      case SEARCH_TYPE.PROCEDURE:
        return suggestion.criteria.procedure?.name;
      case SEARCH_TYPE.MEDICAL_CODE:
        return suggestion.text;
      default:
        break;
    }
  }

  displayOtherSuggestions(): void {
    this.otherSuggestions = [];
    this.otherSuggestionsDescriptions = [];
    if (this.suggestions.length > 1) {
      const otherSuggestionsList = this.suggestions.slice(1, 8);
      for (let suggestion of otherSuggestionsList) {
        if (this.hasSpecialty(suggestion) || this.hasProcedure(suggestion) || this.hasMedicalCode(suggestion)) {
          this.otherSuggestions.push(suggestion);
          this.otherSuggestionsDescriptions.push(this.displaySearchCriteria(suggestion));
        }
      }
    }
    this.handleBehavioralSearch(this.otherSuggestionsDescriptions);
  }

  handleBehavioralSearch(suggestions: string[]) {
    if (!this._appSession.appConfig?.production && suggestions.length && typeof suggestions[0] === 'string') {
      this.isBehavioralSearch = suggestions.some((item) => item.toLowerCase().includes('psych') || item.toLowerCase().includes('behavior'));
    }
  }

  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  onNavigateToChatBotClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message = AppExternalCommands.RCP_CHAT_BOT.MSG;
    eventDetail.target = AppExternalCommands.RCP_CHAT_BOT.CMD;
    this._pfAppCommandEvent.emit(eventDetail);
  }

  get searchResultMsg() {
    return this.content?.freeTextSearch?.searchResultsSummary + '  ' + `<strong>${this.searchCriteriaToDisplay}</strong>`;
  }
  dismissResultSummary() {
    this.dismissFtsResultSummary = true;
  }
}
