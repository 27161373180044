import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { EventHandler } from '../../../../common/services/eventHandler';
import { NavigationService } from '../../../../common/services/navigationService';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { EmailComponent } from '../../../common/components/shareable/email/emailCmp';
import { SliderDirection } from '../../../common/constants/common';
import { ICareTeamEmailRequest, careCircleDetails } from '../../interfaces/iCareProvider';
import { CareCircleService } from '../../services/careCircleSvc';
import { CareCircleUtil } from '../../utilities/careCircleUtil';
import { PcpImportanceComponent } from '../pcp-importance/pcpImportanceCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-care-circle-info-cmp',
  templateUrl: './careCircleInfoCmp.html',
  providers: []
})
export class CareCircleInfoComponent extends BaseComponent implements OnInit {
  sidePanelRef: ModalRef<HTMLElement>;
  sliderDirection = SliderDirection;
  careCircleContent = this.content?.careCircle;
  careCircleInfo = this.careCircleContent?.careProvidersComponent?.labels;
  careCircleOptionsContent = this.careCircleContent?.careCircleOptions;
  @ViewChild('pcpImportanceModal') pcpImportanceModal: PcpImportanceComponent;
  @Input() careCircleDetails: careCircleDetails;
  @Output() printPCPInfo: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('emailModal') emailModal: EmailComponent;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _navigationService: NavigationService,
    private _sidePanel: SidePanel,
    private _careCircleSvc: CareCircleService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.onPreLoad();
  }

  openPCPInfoSlider() {
    this.pcpImportanceModal.openSidePanel();
  }

  onViewAllContinue() {
    this.sidePanelRef.close();
  }

  onViewAllCancel() {
    this.sidePanelRef.close();
  }

  onSearchPcp() {
    this._appSession.searchParams.ableToServePcp = true;
    this._navigationService.navigateByUrl(AppNavigations.FCR_RESULT_PATH);
  }

  onAddCareTeam() {
    this._navigationService.navigateByUrl(AppNavigations.FCR_LANDING_PATH);
  }

  onPrintPage() {
    this.printPCPInfo.emit(true);
  }

  onEmailClick() {
    this.emailModal.show();
  }

  onSendEmail(emailId: string) {
    const emailRequest: ICareTeamEmailRequest = CareCircleUtil.buildCareTeamEmailRequest(this._appSession, emailId);
    this._careCircleSvc.sendCareTeamEmail(emailRequest).then(
      () => this.onEmailSuccess(),
      () => this.onEmailFailure()
    );
  }

  onEmailSuccess() {
    this.emailModal.emailMessageSent = this.content.common.alerts.emailSent;
  }

  onEmailFailure() {
    this.emailModal.emailMessageSent = this.content.common.alerts.emailFailed;
  }

  getEmailPopupHeader() {
    return this.careCircleInfo?.emailModalHeader;
  }
}
