import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertService, SnackbarAlertContainerComponent } from '@anthem/uxd/alert';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { SNACKBAR } from '../../../../common/constants/app-constants';
import { INetworkStatus } from '../../../../common/interfaces/iCommonSearchResponse';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { IProviderNetworkStatus } from '../../../common/interfaces/iProviderDetail';
import { CommonUtility } from '../../../common/utilities/commonUtil';
import { ProviderCard, ProviderCardNavigation } from '../../constants/result';
import { IProvider } from '../../interfaces/iProvider';
import { IDetail } from '../../interfaces/iProviderDetail';
import { IProviderLocationsResponse } from '../../interfaces/iProviderLocation';
import { IReportInvalidRequest } from '../../interfaces/iReportInvaliData';
import { ReportInvalidDataService } from '../../services/reportInvalidDataSvc';
import { IncorrectDataComponent } from '../providerOverview/incorrectData/incorrectDataCmp';
import { BaseComponent } from './../../../common/components/core/baseCmp';
@Component({
  moduleId: module.id,
  selector: 'app-fc-pharmacy-card-cmp',
  templateUrl: './pharmacyCardCmp.html',
  providers: [SidePanel]
})
export class PharmacyCardComponent extends BaseComponent implements OnChanges {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  @Input() providerDetails: IDetail;
  @Input() provider: IProvider;
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Input() showSearchResultsFilter: boolean;
  overviewContent = this.content?.result?.providerOverview;
  pharmacyContent = this.content?.result?.pharmacy;
  summaryContent = this.content?.result?.summaryComponent;
  locationContent = this.content?.result?.locationsComponent;
  moreDetailsContent = this.content?.result?.providerMoreDetails;
  showProviderLoader: boolean = true;
  @Input() locations: IProviderLocationsResponse;
  currentDayNumber: number = ((new Date().getDay() + 6) % 7) + 1;
  @Input() providerIncorrectInfo: any;
  sidePanelRef: ModalRef<any, any>;
  showErrorMsg: boolean;
  @ViewChild('switchReportIncorrectData') openAddressSelection: TemplateRef<IncorrectDataComponent>;
  @ViewChild('snackbarModal') snackbarModal: SnackbarAlertContainerComponent;
  @ViewChild('contractChangeAlertTemplate', { static: false }) contractChangeAlertTemplate: TemplateRef<HTMLElement>;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _phonePatternPipe: PhonePatternPipe,
    private _sidePanel: SidePanel,
    private _reportInvaliDataService: ReportInvalidDataService,
    private _alertService: AlertService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['providerDetails']) {
      if (changes['providerDetails'].previousValue !== changes['providerDetails'].currentValue) {
        this.showProviderLoader = false;
      }
    }
  }

  /**
   * Method to Close Speciality Card.
   * Hiding Speciality Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  /**
   * Method to show Previous Card State in Provider Tab.
   * Hiding Affiliations List and Showing Provider Card.
   */

  onShowPreviousState(): void {
    this.cardSelected.emit(ProviderCard.OVERVIEW);
  }

  getMapEndpoint(): string {
    const latitude = this.providerDetails?.address?.latitude?.trim();
    const longitude = this.providerDetails?.address?.longitude?.trim();
    return `${this.pharmacyContent?.mapEndpoint}${latitude}+${longitude}`;
  }

  getNetworkStatusText(networkStatus: INetworkStatus): string {
    const providerNetworkStatus: IProviderNetworkStatus = {
      statusCode: networkStatus.status ?? '',
      date: networkStatus.date ?? '',
      typeCode: networkStatus.coverages ?? []
    };
    const networktext = CommonUtility.getProviderNetworkStatus(providerNetworkStatus, this._appSession);
    return networktext || '';
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  /**
   * View Recognitions Card Show Event.
   * Emits boolean value true for opening recognitions.
   */
  onShowRecognitions() {
    this.cardSelected.emit(ProviderCard.RECOGNITION);
  }

  /** Function to print content other than table */
  onPrintCard(): void {
    let screenPrintContainer = document.createElement('div');
    screenPrintContainer.classList.add('provider-card-print-content-container');
    const contentToPrint = document.querySelector('.fcr-pharmacy-card-container');
    if (contentToPrint) {
      screenPrintContainer.appendChild(contentToPrint.cloneNode(true));
      document.body.appendChild(screenPrintContainer);
      window.print();
      document.body.removeChild(screenPrintContainer);
    }
  }

  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  /**
   * Method to show PharmacyFeatures Card in Provider Tab.
   */
  onViewAllFeatures() {
    this.cardSelected.emit(ProviderCard.PHARMACY_FEATURES);
  }

  getMessage() {
    if (this.providerIncorrectInfo?.emailList?.length > 0) {
      return this.content.result.incorrectDataComponent.labels.formOption;
    } else {
      return `${this.content.result.incorrectDataComponent.labels.phoneLabel} <a class="anchor-icon">${this.providerIncorrectInfo?.phoneNumber}</a>.`;
    }
  }

  openSwitchLocationModal() {
    this.sidePanelRef = this._sidePanel.open('right', this.openAddressSelection);
  }

  onClose() {
    this.sidePanelRef.close();
  }

  async onSubmit(form: any) {
    const request = this.buildIncorrectData(form);
    const response = await this._reportInvaliDataService.reportInvalid(request);
    if (response) {
      this.sidePanelRef.close();
      this._alertService.success(this.contractChangeAlertTemplate, {
        regionName: SNACKBAR,
        isDissmisable: true
      });
    } else {
      this.showErrorMsg = true;
    }
  }

  buildIncorrectData(form: any): IReportInvalidRequest {
    const request: IReportInvalidRequest = {
      firstNm: form?.firstName,
      lastNm: form?.lastName,
      emailAddress: form?.email,
      phoneNumber: form?.phoneNumber,
      emailList: this.providerIncorrectInfo?.emailList,
      correctedInfo: form?.description,
      brandCode: this._appSession?.metaData?.brandCd,
      providerInfo: {
        providerName: this.provider?.providerName,
        nationalProviderIdentifier: this.provider['nationalProviderIdentifier'],
        addressOne: this.provider?.addressSummary?.addressOne,
        addressTwo: this.provider?.addressSummary?.addressTwo
      }
    };
    return request;
  }
}
