import { IProvider } from '../../../care-circle/interfaces/iCareProvider';
import { ICareTeamRequest } from '../interfaces/iCareTeam';

export class CareteamUtility {
  static buildAddCareTeamRequest(_selectedMemberContract: string, provider: IProvider, mbrUid: string) {
    const _addRequest: ICareTeamRequest = {};
    _addRequest.careTeam = provider;
    _addRequest.contractUid = _selectedMemberContract;
    _addRequest.mbrUid = mbrUid;
    return _addRequest;
  }
}
