import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SEARCH_SCENARIOS } from '../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../common/enums/appEvents';
import { CareNowCardContent, CareNowCardCriteria } from '../../../common/interfaces/iCareNow';
import { IPageEvent } from '../../../common/interfaces/iEvent';
import { ClinicalProgramNavigator } from '../../../common/services/clinicalProgramNav';
import { EventHandler } from '../../../common/services/eventHandler';
import { SearchSvc } from '../../../common/services/searchSvc';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { RulesService } from '../../../fad/rules/services/RulesSvc';
import { ISearchParameters } from '../../../fad/search-providers/interfaces/iSearchParameters';
import { SAVNavigationService } from '../../../fad/search-providers/services/savNavigationSvc';
import { PROVIDER_SEARCH_DISTANCE } from '../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { GenericModalService } from '../../common/components/shareable/modal/services/genericModalSvc';
import { UtilityService } from '../../common/services/utilitySvc';
import { CARE_NOW_CARDS_CRITERIA } from '../constants/careNow';

@Component({
  moduleId: module.id,
  selector: 'app-fc-care-now-cmp',
  templateUrl: './careNowCmp.html',
  providers: []
})
export class CareNowComponent extends BaseComponent implements OnInit, OnDestroy {
  private pageHeaderEvent: EventEmitter<IPageEvent> = this._eventHandler.get(AppEvents[AppEvents.APP_HEADER]);
  private navigationSubscription: Subscription;
  careNowCards: CareNowCardContent[] = [];
  has247NRSLNProgramCard = false;
  showLoader = true;

  constructor(
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    @Inject(AppSession) private _appSession: AppSession,
    private clinicalProgramNav: ClinicalProgramNavigator,
    private searchService: SearchSvc,
    private savNavigationService: SAVNavigationService,
    private router: Router,
    private appUtility: AppUtility,
    private rulesService: RulesService,
    private genericModalService: GenericModalService,
    private utilityService: UtilityService
  ) {
    super(_route, _eventHandler, _appSession);
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.setPageHeader();
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.appUtility.scrollToTop();

    if (this._appSession.isSecureState) {
      this.utilityService
        .getProgram({ codes: ['247NRSLN'] })
        .then((response) => {
          if (response?.data?.length) {
            this.has247NRSLNProgramCard = response.data.some((program) => program.id === '247NRSLN');
          }
        })
        .catch(() => {
          this.has247NRSLNProgramCard = false;
        })
        .finally(() => {
          this.careNowCards = this.filterDisplayCards();
          this.showLoader = false;
        });
    } else {
      this.careNowCards = this.filterDisplayCards();
      this.showLoader = false;
    }
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  setPageHeader() {
    if (!this.content) {
      return;
    }
    if (this.content && this.content?.careNow && this.content?.careNow?.getCareNow) {
      this.pageHeaderEvent.emit({ header: this.content.careNow.getCareNow, title: this.content.careNow.getCareNow });
    }
  }

  search(cardId: string) {
    if (cardId === 'talkToNurse247') {
      this.clinicalProgramNav.navigateToClinicalProgram('247NRSLN');
    } else {
      const selectedCard = CARE_NOW_CARDS_CRITERIA.find((card) => card.id === cardId);
      this.searchByCriteria(selectedCard?.searchCriteria);
    }
  }

  searchByCriteria(searchCriteria: CareNowCardCriteria) {
    const searchParams: ISearchParameters = {
      provNm: '',
      coverageTypeCode: '',
      specialtySelectNm: searchCriteria?.specialtyCategoryCodes || [],
      taxonomySelectNm: searchCriteria?.taxonomyCodes || [],
      providerTypeCodeList: searchCriteria?.providerTypeCodeList || [],
      standAloneVision: this._appSession.planRule?.isMedicareGov,
      virtualOnly: searchCriteria?.virtualOnly,
      selectedSortCriteria: searchCriteria?.sortBy,
      typeSelectNm: ''
    };
    this.searchService.currentScenario = CommonUtil.isMemberSecure(this._appSession) ? SEARCH_SCENARIOS.SECURE_V1_SPECIALTY : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchParams = { ...this._appSession.searchParams, ...searchParams };
    this.resetAppSessionParams();

    this.rulesService.getRules(CommonUtil.buildRuleRequest(this._appSession.searchParams, this._appSession)).then(
      (result) => {
        if (result) {
          if (!this.genericModalService.isActionBlockedByModal(result)) {
            this._appSession.feature = result;
            if (result.showBCP && result.showPreFilterBCP && this._appSession.searchParams) {
              this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
            }
            this.savNavigationService.navigate();
          }
        }
      },
      (error) => {
        throw error;
      }
    );
  }

  resetAppSessionParams() {
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
    this._appSession.isMedicalCodeSearch = false;
    this._appSession.billingType = undefined;
    this._appSession.medicalCode = undefined;
    this._appSession.medicalModifierOptionCode = undefined;
    this._appSession.medicalPosCode = undefined;
  }

  filterDisplayCards() {
    if (!this.has247NRSLNProgramCard) {
      return this.content?.careNow?.careNowCards?.filter((card: CareNowCardContent) => card.id !== 'talkToNurse247');
    }
    return this.content?.careNow?.careNowCards || [];
  }
}
