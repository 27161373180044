import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Locale } from '../../../common/constants/app-constants';
import { FcLabel, labels } from '../../common/contents/labels';
import { Content } from '../../common/enums/content';
import { ContentService } from '../../common/services/contentSvc';
import { routeContentKeys } from '../constants/routeConstant';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';

@Injectable({
  providedIn: 'root'
})
export class ContentResolver implements Resolve<any> {
  constructor(
    private appSession: AppSession,
    private _contentHelper: ContentHelper,
    private _contentService: ContentService
  ) {}

  /**
   * Resolves the content based on the route data and locale.
   * @param route - The activated route snapshot.
   * @returns An observable containing the resolved content.
   */
  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    // Determine the locale from the app session, defaulting to English if not available
    const locale = this.appSession?.metaData?.locale || Locale.ENGLISH;

    let templatePath: string = this._contentHelper.getResolvedUrl(route);
    // Trim the leading slash from the template path
    templatePath = templatePath.replace(/^\//, '').toUpperCase();

    // Get the content keys from the route data
    const contentKeys = routeContentKeys[templatePath];
    let contents = {};

    // Populate the contents object with the corresponding labels based on the locale
    (contentKeys || []).forEach((contentKey) => {
      contents = { ...contents, [contentKey]: labels[locale.toString()][contentKey] };
    });

    return this.getContent().then((result: FcLabel) => {
      if (result) {
        contents = this.mergeObjects(contents, result);
      }

      // Register the content to access it in non-FCR components using the content helper
      this._contentHelper.registerContent(templatePath, contents);

      return contents;
    });
  }

  /**
   * Retrieves content from the app session if available, otherwise fetches it
   * from the content service. The fetched content is then stored in the app session.
   * @returns A promise that resolves to an FcLabel object or undefined if an error occurs.
   */
  private async getContent(): Promise<FcLabel | undefined> {
    if (this.appSession?.appState?.opsState?.overriddenContent) {
      return this.appSession.appState.opsState.overriddenContent;
    } else {
      try {
        const result: FcLabel = await this._contentService.getContent(Content.FC_WEB_CONTENT_MANAGER);
        const strapiContent = result?.[Content.FC_WEB_CONTENT_MANAGER] || {};
        this.appSession.appState.opsState.overriddenContent = strapiContent;
        return strapiContent;
      } catch (error) {
        return undefined;
      }
    }
  }

  /**
   * Merges two objects, with properties from the overriddenContent
   * object overriding those in the mainContent object. This function handles
   * nested objects recursively.
   * @param mainContent - The primary object.
   * @param overriddenContent - The object with properties to override.
   * @returns A new object with merged properties.
   */
  private mergeObjects(mainContent: any, overriddenContent: any): any {
    const mergedObject = { ...mainContent };

    for (const key in overriddenContent) {
      if (overriddenContent.hasOwnProperty(key)) {
        if (typeof overriddenContent[key] === 'object' && !Array.isArray(overriddenContent[key])) {
          mergedObject[key] = this.mergeObjects(mainContent[key] || {}, overriddenContent[key]);
        } else {
          mergedObject[key] = overriddenContent[key];
        }
      }
    }

    return mergedObject;
  }
}
