import { TitleCasePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IDropdownItem } from '@anthem/uxd/util';
import { DEEPLINK_ACTION } from '../../../../common/constants/app-constants';
import { IPCPRadioSideOptions, IPCPResponse } from '../../../../common/interfaces/iPcpDetails';
import { IPCPRadioOption, IPcpReasonCodeContent } from '../../../../common/interfaces/iPCPInfo';
import { IPcpRequest } from '../../../../common/interfaces/iPcpRequest';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { DataHelper } from '../../../../common/services/dataHelper';
import { EventHandler } from '../../../../common/services/eventHandler';
import { PCPService } from '../../../../common/services/pcpSvc';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IMember } from '../../../common/interfaces/iMember';
import { ICareProvider } from '../../interfaces/iCareProvider';

declare let window: any;
declare let _satellite: any;

@Component({
  moduleId: module.id,
  selector: 'app-fc-remove-pcp-cmp',
  templateUrl: './removePcpCmp.html'
})
export class RemovePcpComponent extends BaseComponent implements OnInit {
  removePcpContent = this.content?.careCircle?.removePcp;
  removePcpPanelRef: ModalRef<HTMLElement>;
  @ViewChild('removePcpModal') removeProviderModal: TemplateRef<HTMLElement>;
  @Output() onRemoveSuccess = new EventEmitter<boolean>();
  pcpPageProgress = false;
  showRemoveLoader = false;
  selectedMbr: IMember;
  pcpRadioSideOptions: Array<IPCPRadioSideOptions>;
  labelPCPRadioSideOptions: Array<IPCPRadioSideOptions>;
  showCompletePCP = false;
  isRemovePCP = false;
  professional: ICareProvider;
  changePcpDropDown: IDropdownItem[] = [];
  pcpList: IPCPRadioOption[] = [];
  errorText = '';
  _assignPCPSuccessText = '';
  selectedMbrError = false;
  selectedPcpIdError = false;
  changeReasonPcp = '';
  selectedReasonPcpError = false;
  assignPcpErrorMsg = false;
  showSucessAssignPCPMsg = false;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _sidePanel: SidePanel,
    private _pcpSvc: PCPService,
    private _titlecasePipe: TitleCasePipe,
    private _phonePatternPipe: PhonePatternPipe,
    private _dataHelper: DataHelper
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    this.getPcpMemberInfo(true);
  }

  /**
   * Method to open side panel
   * @param careProvider - Care Provider to be removed
   */
  openSidePanel(careProvider: ICareProvider) {
    this.initialize(careProvider);
    this.removePcpPanelRef = this._sidePanel.open('right', this.removeProviderModal);
  }

  /**
   * Method to initialize the properties for Remove PCP Modal
   * @param careProvider - PCP to be removed
   */
  initialize(careProvider: ICareProvider) {
    this.showCompletePCP = false;
    this.isRemovePCP = true;
    this.professional = careProvider;
    this.setReasonCodes();
  }

  /**
   * Method to set the reason codes for removing the PCP
   */
  setReasonCodes() {
    const providerCategoryCd = this.professional?.providerCategoryCode?.code ?? '';
    const reasonCodes: IDropdownItem[] = [];
    const reasons = this._dataHelper.getValueByKey<IPcpReasonCodeContent[]>(
      this._appSession.sourceSystem,
      this.content.common.assignPcpCmpContent.pcpReasonsList,
      this.content.common.assignPcpCmpContent.pcpReasonsList.ALL
    );

    reasons.forEach((reason) => {
      const isExcluded = reason?.excludeCategoryCodes?.some((provCatCode) => {
        return this._dataHelper.areEqualStrings(provCatCode, providerCategoryCd);
      });
      if (!isExcluded) {
        reasonCodes.push({ label: reason?.reason, value: reason.value });
      }
    });

    this.changePcpDropDown = reasonCodes;
  }

  get isPCPDeepLink() {
    return this._appSession?.isDeepLink && this._appSession?.deeplinkParams?.action === DEEPLINK_ACTION.POST_PCP_INFO;
  }

  /**
   * Method to get the PCP Member Information for the selected contract
   * @param showProgress - Flag to show the loader while fetching the PCP Member Information
   */
  getPcpMemberInfo(showProgress: boolean) {
    this.pcpPageProgress = showProgress;
    const activeContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract;
    const pcpReq: IPcpRequest = {
      contractUid: activeContract?.contractUid,
      selMbrUid: activeContract?.mbrUid,
      pcpInfo: null
    };

    this._pcpSvc.getPcpMemberInfo(pcpReq).then(
      (result: IPCPResponse) => {
        this.processPcpMemberInfo(result, activeContract?.mbrUid);
        this.pcpPageProgress = false;
      },
      (error: any) => {
        this.pcpPageProgress = false;
        this.processPcpMemberInfo(null, activeContract?.mbrUid);
        if (error instanceof HttpErrorResponse) {
          throw error;
        }
      }
    );
  }

  /**
   * Method to process the PCP Member Information and update the member list
   * @param pcpResponse - PCP Response from the API
   * @param mbrUid - Member UID of the selected contract
   */
  processPcpMemberInfo(pcpResponse: IPCPResponse, mbrUid: string) {
    const activeContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract;
    const memberList = this._appSession?.appState?.selectedEligibilityProduct?.members;

    if (activeContract && memberList?.length > 0 && pcpResponse?.member?.length > 0) {
      memberList.forEach((member) => {
        member.pcp = null;
        const pcpMember = pcpResponse.member.find((x) => x.mbrUid === member.mbrUid);
        if (pcpMember?.pcp) {
          member.pcp = pcpMember.pcp.find((x) => x.contractUid === activeContract.contractUid && x.providerName !== '');
        }
      });
    }

    if (memberList?.length > 0) {
      this.selectedMbr = this.getSelectedMember(mbrUid);
      this.pcpRadioSideOptions = this.buildPcpMemberOptions(memberList);
      this.labelPCPRadioSideOptions = this.pcpRadioSideOptions?.filter((options: IPCPRadioSideOptions) => options.value === this.selectedMbr?.mbrUid);
    }
  }

  /**
   * Method to build the PCP Member Options for the radio side panel
   * @param members - List of members for the selected contract
   * @returns - List of PCP Member Options
   */
  buildPcpMemberOptions(members: IMember[]) {
    const pcpMemberOptions: IPCPRadioSideOptions[] = [];
    members?.forEach((member) => {
      const fullName = `${this.titleCase(member.firstNm)} ${this.titleCase(member.lastNm)}`;
      const label = member?.relationshipCd ? `${fullName} (${this.titleCase(member.relationshipCd)})` : fullName;

      pcpMemberOptions.push({
        label,
        ariaLabel: fullName,
        value: member.mbrUid,
        id: `rdb-mbr-id-${member.mbrUid}`,
        name: `rdb-mbr-name-${member.mbrUid}`,
        pcp: member.pcp
      });
    });
    return pcpMemberOptions;
  }

  /**
   * Method to get the selected member from the member list
   * @param mbrUid - Member UID of the selected member
   * @returns - Selected Member
   */
  getSelectedMember(mbrUid: string): IMember {
    const memberList = this._appSession?.appState?.selectedEligibilityProduct?.members;
    return memberList?.find((member) => member.mbrUid === mbrUid);
  }

  /**
   * Method to handle the radio button change event for PCP Member
   * @param option - Selected PCP Member Option
   */
  onRadioChangePCP(option: IPCPRadioSideOptions) {
    this.selectedMbr = this.getSelectedMember(option.value);
    this.errorText = this._assignPCPSuccessText = '';
    this.selectedMbrError = this.selectedReasonPcpError = this.selectedPcpIdError = this.showSucessAssignPCPMsg = this.assignPcpErrorMsg = false;
  }

  /**
   * Method to handle the radio button change event for PCP Provider
   * @param option - Selected PCP Provider Option
   */
  onChangePcpReason(option: IDropdownItem) {
    if (option?.value !== '') {
      this.changeReasonPcp = option.value;
      this.selectedReasonPcpError = false;
    }
  }

  /**
   * Method to handle the Remove PCP button click event
   */
  onRemovePCP() {
    const isRemovePCPValid = this.validateRemovePCP();
    if (isRemovePCPValid) {
      this.selectedMbrError = this.selectedReasonPcpError = this.showSucessAssignPCPMsg = this.assignPcpErrorMsg = this.selectedPcpIdError = false;
      this.errorText = this._assignPCPSuccessText = '';

      if (window?.digitalData?.page?.pageInfo) {
        window.digitalData.page.pageInfo.changePcpCode = this.changeReasonPcp;
      }

      const pcpReq: IPcpRequest = {
        contractUid: this._appSession.appState.selectedEligibilityProduct.selectedContract.contractUid,
        pcpInfo: {
          pcpId: '',
          pcpTrmntnRsnCd: this.changeReasonPcp
        },
        selMbrUid: this.selectedMbr.mbrUid
      };

      this.showRemoveLoader = true;
      this._pcpSvc.removePCP(pcpReq).then(
        (result: IPCPResponse) => {
          this.handleRemovePCPSuccess();
        },
        (error: any) => {
          this.handleRemovePCPFailure();
        }
      );
    }
  }

  /**
   * Method to validate the Remove PCP form
   * @returns - Flag to indicate if the form is valid
   */
  validateRemovePCP(): boolean {
    if (this.selectedMbr === null) {
      this.selectedMbrError = true;
      this.errorText = this.content.common.assignPcpCmpContent.handle.alerts.lblErrorMbrMsg;
      return false;
    } else if (this.changeReasonPcp === '') {
      this.selectedReasonPcpError = true;
      this.selectedMbrError = this.selectedPcpIdError = false;
      this.errorText = this.content.common.assignPcpCmpContent.handle.alerts.lblErrorChangeReasonPcpMsg;
      return false;
    } else if (!(this.selectedMbr?.pcp?.providerName !== '')) {
      this.selectedMbrError = this.selectedReasonPcpError = false;
      this.assignPcpErrorMsg = true;
      this.errorText = this.content.common.assignPcpCmpContent.handle.alerts.lblErrorNoPcpAssignedMsg;
      return false;
    }
    return true;
  }

  /**
   * Method to handle the Remove PCP Success
   */
  handleRemovePCPSuccess() {
    this.showSucessAssignPCPMsg = true;
    const _removeSucess = this.content.common.assignPcpCmpContent.handle.pcpErrorList.removeSuccess;
    const _providerName = this.selectedMbr?.pcp?.providerName?.toUpperCase() ?? '';
    this._assignPCPSuccessText = _removeSucess
      .replace(/{DOCTOR NAME}/gi, _providerName)
      .replace(/{MEMBER NAME}/gi, this.titleCase(this.selectedMbr.firstNm) + ' ' + this.titleCase(this.selectedMbr.lastNm));
    this.getPcpMemberInfo(false);
    this.showCompletePCP = true;
    this.isRemovePCP = false;
    this.showRemoveLoader = false;
    if (typeof _satellite !== 'undefined') {
      _satellite.track('removePCPSuccess');
    }
  }

  /**
   * Method to handle the Remove PCP Failure
   */
  handleRemovePCPFailure() {
    this.assignPcpErrorMsg = true;
    this.showRemoveLoader = false;
    const _removeFail = this.content.common.assignPcpCmpContent.handle.pcpErrorList.removeFail;
    const _providerName = this.selectedMbr?.pcp?.providerName?.toUpperCase() ?? '';
    this.errorText = _removeFail.replace(/{DOCTOR NAME}/gi, _providerName).replace(/{MEMBER NAME}/gi, this.titleCase(this.selectedMbr.firstNm) + ' ' + this.titleCase(this.selectedMbr.lastNm));
    if (typeof _satellite !== 'undefined') {
      _satellite.track('removePCPFailure');
    }
  }

  /**
   * Method to notify the parent component about the completion of Remove PCP
   */
  onCompletePCP() {
    this.onRemoveSuccess.emit(true);
    this.onClose();
  }

  /**
   * Close the Remove Provider Modal
   */
  onClose() {
    this.removePcpPanelRef.close();
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }
}
