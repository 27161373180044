import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { ProviderCard } from '../../constants/result';

@Component({
  moduleId: module.id,
  selector: 'app-fc-title-card-cmp',
  templateUrl: './titleListCardCmp.html'
})
export class TitleListCardComponent extends BaseComponent {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Input() selectedIndex: number;
  @Input() providerName: string;
  @Input() providerTitles: string[];
  titlesContent = this.content?.result?.titleCard;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to Close Speciality Card.
   * Hiding Speciality Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  onShowPreviousState() {
    if (this.selectedIndex == 1) {
      this.cardSelected.emit(ProviderCard.MORE_DETAILS);
    } else {
      this.cardSelected.emit(ProviderCard.OVERVIEW);
    }
  }
}
