import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CustomPipesModule } from '../../common/pipes/customPipesModule';
import { AssignPcpCtaModule } from '../common/components/shareable/assign-pcp-cta/assignPcpCtaModule';
import { EmailModule } from '../common/components/shareable/email/emailModule';
import { LoaderModule } from '../common/components/shareable/loader/loaderModule';
import { SearchHeaderModule } from '../common/components/shareable/search-header/SearchHeaderModule';
import { OnlinePharmacyLabelCmpModule } from '../result/components/onlinePharmacy/onlinePharmacyLabelCmpModule';
import { AdvocateDetailsComponent } from './components/advocateDetails/advocateDetailsCmp';
import { CareCircleComponent } from './components/careCircleCmp';
import { CareCircleListComponent } from './components/careCircleList/careCircleListCmp';
import { CareCircleInfoComponent } from './components/careCricleInfo/careCircleInfoCmp';
import { PcpImportanceComponent } from './components/pcp-importance/pcpImportanceCmp';
import { RemoveCareComponent } from './components/remove-care/removeCareCmp';
import { RemovePcpComponent } from './components/remove-pcp/removePcpCmp';
import { CareCircleService } from './services/careCircleSvc';
import { MemberPCPHelper } from './services/memberPCPHelper';

@NgModule({
  declarations: [CareCircleComponent, CareCircleListComponent, CareCircleInfoComponent, RemoveCareComponent, PcpImportanceComponent, AdvocateDetailsComponent, RemovePcpComponent],
  exports: [CareCircleComponent, RemoveCareComponent, PcpImportanceComponent],
  imports: [CommonModule, UxSharedModule.forRoot(), FormsModule, SearchHeaderModule, LoaderModule, EmailModule, CustomPipesModule, AssignPcpCtaModule, OnlinePharmacyLabelCmpModule],
  providers: [MemberPCPHelper, CareCircleService]
})
export class CareCircleModule {}
