import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClinicalProgramNavigator } from '../../../../common/services/clinicalProgramNav';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { ProgramCategory, SliderDirection } from '../../../common/constants/common';
import { CLINICAL_DISCLAIMERS, PREFIX_TP } from '../../../common/constants/providerSearchConstants';
import { ICardItem } from '../../../common/interfaces/iProgramCard';
import { ProgramCardsModalComponent } from './programCardsModal/programCardsModalCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-program-card-list-cmp',
  templateUrl: './programCardListCmp.html'
})
export class ProgramCardListComponent extends BaseComponent {
  @Input()
  maxVisibleCards: number = 2; // default number of program cards to show

  @ViewChild('programCardsModal')
  programCardsModal: ProgramCardsModalComponent;

  @Output()
  onError: EventEmitter<boolean> = new EventEmitter<boolean>();

  showViewAll: boolean = false;
  showList: boolean = false;

  private _cardsData: ICardItem[];
  showDetails: boolean;
  selectedCardData: ICardItem;

  disclaimerUrl: string;

  constructor(
    route: ActivatedRoute,
    eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    private _clinicalProgramNav: ClinicalProgramNavigator
  ) {
    super(route, eventHandler, _appSession);
  }

  ngOnInit() {}

  @Input()
  set cardsData(val: ICardItem[]) {
    if (!val) {
      val = [];
    }

    if (val !== this._cardsData) {
      this._cardsData = val;
      this.showList = val.length > 0;
      this.showViewAll = val.length > this.maxVisibleCards;
    }
  }
  get cardsData(): ICardItem[] {
    return this._cardsData;
  }

  viewAllCards() {
    this.showDetails = false;
    this.openSidePanel();
  }
  openSidePanel() {
    this.programCardsModal.openSidePanel(SliderDirection.RIGHT, this.showList, this.showDetails);
  }

  openProgramDetails(card: ICardItem) {
    this.showDetails = true;
    this.selectedCardData = card;
    if (card.category === ProgramCategory.ONSITE_CLINICS) {
      this.openSidePanel();
    } else {
      if ((card?.id && PREFIX_TP + card?.id in CLINICAL_DISCLAIMERS) || card.category === ProgramCategory.THIRD_PARTY) {
        this._clinicalProgramNav.navigateToClinicalProgram(card.id)?.then((status: boolean) => {
          if (!status) {
            this.onError.emit(true);
          }
        });
      }
    }
  }
}
