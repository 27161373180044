import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppExternalCommands } from '../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { SEARCH_SCENARIOS } from '../../../../common/constants/strategy-search-constant';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../../common/services/eventHandler';
import { NavigationService } from '../../../../common/services/navigationService';
import { SearchSvc } from '../../../../common/services/searchSvc';
import { AppSession } from '../../../../common/values/appSession';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { PROVIDER_TYPE } from '../../../common/constants/common';
import { IAdvocateDetails } from '../../interfaces/iCareCircle';
import { ICareProvider } from '../../interfaces/iCareProvider';
import { AdvocateDetailsComponent } from '../advocateDetails/advocateDetailsCmp';
import { RemoveCareComponent } from '../remove-care/removeCareCmp';
import { RemovePcpComponent } from '../remove-pcp/removePcpCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-care-circle-list-cmp',
  templateUrl: './careCircleListCmp.html'
})
export class CareCircleListComponent extends BaseComponent {
  careCircleInfo = this.content?.careCircle;
  @Input() providerList: Array<ICareProvider> | Array<IAdvocateDetails> = [];
  @Output() onReloadCareTeam: EventEmitter<boolean> = new EventEmitter(false);
  @ViewChild('removePCPSlider') removePCPSlider: RemovePcpComponent;
  @ViewChild('removeCareTeamSlider') removeCareTeamSlider: RemoveCareComponent;
  @ViewChild('advocateDetailsSlider') advocateDetailsSlider: AdvocateDetailsComponent;
  private _fcrAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _navigationService: NavigationService,
    private _searchService: SearchSvc,
    private _titleCasePipe: TitleCasePipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Get provider titles and full name from care provider
   * @param careProvider Care provider object
   * @returns Provider name
   */
  getProviderName(careProvider: ICareProvider): string {
    return CommonUtil.getProviderTitles(careProvider.titleList, '', this._titleCasePipe.transform(careProvider.fullNm));
  }

  /**
   * Method navigates to results page with selected care team provider card open
   * @param careProvider - Care Provider
   */
  onProviderClick(careProvider: ICareProvider) {
    let searchCriteria = this._appSession.searchParams;
    searchCriteria.provNm = careProvider?.fullNm;
    searchCriteria.providerIdentifier = careProvider?.id || careProvider?.providerId;
    searchCriteria.addressIdentifier = careProvider?.address?.addressId;
    searchCriteria.typeSelectNm = careProvider?.providerTypes?.[0];
    searchCriteria.providerTypeCodeList = careProvider.providerTypes;

    this._appSession.searchTerm = careProvider?.fullNm;
    this._appSession.openDetailPopup = true;
    this._searchService.currentScenario = this.isMemberSecure ? CommonUtil.getSecureSpecialtyScenario(careProvider.providerTypes as PROVIDER_TYPE[]) : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
    this._navigationService.navigateByUrl(AppNavigations.FCR_RESULT_PATH);
  }

  /**
   * Open the remove PCP slider
   * @param careProvider PCP Care Provider
   */
  onRemovePCP(careProvider: ICareProvider) {
    this.removePCPSlider.openSidePanel(careProvider);
  }

  /**
   * On Remove PCP Success - Reload the Care Team
   */
  onRemovePCPSuccess() {
    this.reloadCareTeam();
  }

  /**
   * Open the remove care team member slider
   * @param careProvider - Care Provider to be removed
   */
  onRemoveCareTeam(careProvider: ICareProvider) {
    this.removeCareTeamSlider.openSidePanel(careProvider);
  }

  /**
   * On Remove Care Team Member Success - Reload the Care Team
   */
  onRemoveCareTeamSuccess() {
    this.reloadCareTeam();
  }

  /**
   * Redirect to claims page
   */
  viewClaims() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message = AppExternalCommands.RCP_CLAIMS_LANDING.MSG;
    eventDetail.target = AppExternalCommands.RCP_CLAIMS_LANDING.CMD;
    this._fcrAppCommandEvent.emit(eventDetail);
  }

  /**
   * Method to update label with provider name in the text
   * @param labelText Label text to update
   * @param providerNm Provider name to replace
   * @returns Updated label text with provider name
   */
  updateLabelWithProviderName(labelText: string, providerNm: string) {
    return labelText?.replace(/{PROVIDER}/gi, providerNm);
  }

  /**
   * Method to reload care team
   */
  reloadCareTeam() {
    this.onReloadCareTeam.emit(true);
  }

  /**
   * Method to navigate to chat bot
   */
  onNavigateToChatBotClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.details = eventDetail.message = AppExternalCommands.RCP_CHAT_BOT.MSG;
    eventDetail.target = AppExternalCommands.RCP_CHAT_BOT.CMD;
    this._fcrAppCommandEvent.emit(eventDetail);
  }

  /**
   * Method to navigate to callback request
   */
  onCallbackRequestClick() {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    eventDetail.message = AppExternalCommands.RCP_CONTACT_US_CALL_MAIL_SECTION.MSG;
    eventDetail.target = AppExternalCommands.RCP_CONTACT_US_CALL_MAIL_SECTION.CMD;
    this._fcrAppCommandEvent.emit(eventDetail);
  }

  /**
   * Method to navigate to secure message
   */
  getAdvocateName(advocate: IAdvocateDetails) {
    return this._titleCasePipe.transform(advocate.firstName + ', ' + advocate.lastName);
  }

  /**
   * Method to show advocate details
   * @param advocate Advocate details
   */
  showAdvocateDetails(advocate: IAdvocateDetails) {
    this.advocateDetailsSlider.openSidePanel(advocate);
  }

  /**
   * Method to get map endpoint for care team member
   * @param careTeam Care team member
   * @returns Map endpoint URL
   */
  getMapEndpoint(careTeam: ICareProvider) {
    const careTeamMemberAddress = careTeam?.address;
    const latitude = careTeamMemberAddress?.latitude?.trim();
    const longitude = careTeamMemberAddress?.longitude?.trim();
    if (latitude && longitude) {
      return `${this.careCircleInfo?.careProvidersComponent?.labels?.mapEndpoint}${latitude}+${longitude}`;
    }
    return `${this.careCircleInfo?.careProvidersComponent?.labels?.mapEndpoint}${careTeamMemberAddress?.addressLineOne}, ${careTeamMemberAddress?.city}, ${careTeamMemberAddress?.stateCd.code} ${careTeamMemberAddress?.zipCd}`;
  }
}
