import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISearchFilterResponse } from '../../common/interfaces/iSearchFilter';
import { SearchFilterService } from '../../common/services/searchFilterSvc';
import { ISecureSpecialtySearchRequest } from './../../../common/interfaces/iSecureSpecialtySearchRequest';

@Injectable({ providedIn: 'root' })
export class SearchFilter {
  private searchFilters = new BehaviorSubject<ISearchFilterResponse>(undefined);
  private filterOptions: ISearchFilterResponse;

  filters = this.searchFilters.asObservable();
  appSession = this._searchFilterSvc.appSession;

  constructor(private _searchFilterSvc: SearchFilterService) {}

  async initialize(filterRequest: ISecureSpecialtySearchRequest, searchKey?: string): Promise<void> {
    if (searchKey?.length) {
      filterRequest.searchKey = searchKey;
    }
    const searchFilterResponse = await this._searchFilterSvc.execute(filterRequest);
    this.setSearchFilter(searchFilterResponse);
  }

  public isKeyRecognitionFilterOption(filterValue: string) {
    return this.filterOptions?.keyRecognitions?.some((filter) => filter.code === filterValue);
  }

  private setSearchFilter(filterResponse: ISearchFilterResponse | null): void {
    if (filterResponse) {
      this.filterOptions = filterResponse;
      this.searchFilters.next(filterResponse);
    }
  }
}
