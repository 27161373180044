import { TitleCasePipe } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { CommonUtility } from '../../../../common/utilities/commonUtil';
import { IProvider } from '../../../interfaces/iProvider';
import { IAffiliationProvider } from '../../../interfaces/iProviderAffiliation';
import { IDetail } from '../../../interfaces/iProviderDetail';
import { IProviderPlan } from '../../../interfaces/iProviderPlan';
import { ISpecialityResponse } from '../../../interfaces/iProviderSpeciality';
import { ProviderUtility } from '../../../utilities/providerUtil';

@Component({
  moduleId: module.id,
  selector: 'app-fc-print-provider-card-cmp',
  templateUrl: './printProviderCardCmp.html'
})
export class PrintProviderCardComponent extends BaseComponent {
  @Input() providerDetails: IDetail;
  @Input() provider: IProvider;
  @Input() licenses?: Array<string>;
  @Input() affiliations: Map<string, IAffiliationProvider[]>;
  @Input() specialityDetails: ISpecialityResponse;
  @Input() insurancePlans: IProviderPlan[];
  @Input() disclaimers: string[] = [];

  overviewContent = this.content?.result?.providerOverview;
  moreDetailsContent = this.content?.result?.providerMoreDetails;
  pharmacyContent = this.content?.result?.pharmacy;
  summaryContent = this.content?.result?.summaryComponent;
  licenseContent = this.content?.result?.licenseCard;
  affiliationContent = this.content?.result?.affiliationCard;
  serviceCardContent = this.content?.result?.serviceCard;
  specialityContent = this.content?.result?.specialityCard;
  insuranceContent = this.content?.result?.insuranceCard;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  getNetworkStatus(isResultView?: boolean): string {
    const networktext = CommonUtility.getProviderNetworkStatus(this.provider?.providerNetworkStatus, this._appSession, isResultView);
    return networktext ? networktext : '';
  }

  getGenderAndEthnicity(): string {
    const gender = this.providerDetails?.gender;
    const ethnicity = this.providerDetails?.ethnicityCodes || [];
    const ethnicityString = ethnicity?.length > 0 ? ethnicity.join(', ') : this.overviewContent.labels.noEthnicity;

    if (gender && ethnicity?.length > 0) {
      return `${this.titleCase(gender)}, ${this.titleCase(ethnicityString)}`;
    }
    if (gender && ethnicity?.length === 0) {
      return `${this.titleCase(gender)}, ${ethnicityString}`;
    }
    if (!gender && ethnicity?.length > 0) {
      return `${this.titleCase(ethnicityString)}, ${this.overviewContent.labels.noGender}`;
    }
    return this.overviewContent.labels.noGenderOrEthnicity;
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  /** Function to print content other than table */
  onPrintCard(): void {
    let screenPrintContainer = document.createElement('div');
    screenPrintContainer.classList.add('provider-card-print-content-container');
    const contentToPrint = document.querySelector('.fcr-providerprint-card-container');
    if (contentToPrint) {
      screenPrintContainer.appendChild(contentToPrint.cloneNode(true));
      document.body.appendChild(screenPrintContainer);
      window.print();
      document.body.removeChild(screenPrintContainer);
    }
  }

  decodeHTML(html) {
    const txt = document.createElement('textarea');
    txt.value = html;
    return txt.value;
  }

  getOfficeHours(): string[] {
    const officeHours = this.providerDetails?.officeHours || [];
    const daysPattern = /^(M|Tu|W|Th|F|Sa|Su)\s+/;
    const allDays = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];
    const officeHoursMap = new Map<string, string>();

    officeHours.forEach((hour) => {
      const day = hour.match(daysPattern)?.[1];
      if (day) {
        const hours = hour.replace(daysPattern, '').replace(' TO ', ' - ');
        if (hours === '00:00 AM - 00:00 AM') {
          officeHoursMap.set(day, 'Not Open');
        } else {
          officeHoursMap.set(day, hours);
        }
      }
    });

    return allDays.map((day) => officeHoursMap.get(day) || 'Not Open');
  }

  checkNum(review: string): boolean {
    return !isNaN(Number(review));
  }

  isDiamondProvider(): boolean {
    return this.providerDetails?.performance?.recognition?.some((pgm) => ProviderUtility.isDiamondProvider(pgm?.code)) || false;
  }
}
