import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhonePatternPipe } from '../../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { IProvider } from '../../../interfaces/iProvider';
import { IProviderLocationAddress } from '../../../interfaces/iProviderLocation';
import { ProviderDetail } from '../../../services/providerDetail';
import { BaseComponent } from './../../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-view-locations-cmp',
  templateUrl: './viewLocationsCmp.html'
})
export class ViewLocationsComponent extends BaseComponent {
  @Input() locations: IProviderLocationAddress[];
  @Input() provider: IProvider;
  locationsContent = this.content?.result?.locationsCard;
  activeIndex: number = 0;
  selectedIndex: number = 0;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerDetail: ProviderDetail,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  /**
   * Method to show provider Card specific to selected location.
   */
  onShowLocationProviderCard(location: IProviderLocationAddress): void {
    this._providerDetail.initialize(this.provider.providerIdentifier, location.addressId, this.provider);
  }

  onMouseEnter(index: number): void {
    this.activeIndex = index;
  }

  onMouseLeave(): void {
    this.activeIndex = this.selectedIndex;
  }

  onClick(index: number): void {
    this.selectedIndex = index;
    this.activeIndex = index;
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }
}
