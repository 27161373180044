import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../common/components/base-component/baseCmp';
import { BILLING_TYPE, Locale, OFFICE_VISIT_MEDICALCODE } from '../../../common/constants/app-constants';
import { IProvider } from '../../../common/interfaces/iSearchStrategyResponse';
import { PhonePatternPipe } from '../../../common/pipes/phonePatternPipe';
import { DataService } from '../../../common/services/dataService';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { ContentHelper } from '../../../common/values/contentHelper';
import { IAddress } from '../../provider-details/models/iAddress';
import { ProviderDetailsNavigationService } from '../../provider-details/services/providerDetailsNavigationSvc';
import { CommonUtil } from '../../utilities/commonUtil';

@Component({
  selector: 'app-fad-search-result-provider-info',
  templateUrl: '../views/pfSearchResultProviderInfoCmp.html'
})
export class PFSearchResultProviderInfoCmp extends BaseComponent {
  @Input()
  professional: IProvider;
  @Input()
  idSuffix: number;
  @Output()
  openDomain: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  openMapModal: EventEmitter<IProvider> = new EventEmitter<IProvider>();

  constructor(
    private _dataService: DataService,
    private _providerDetailsNavigationService: ProviderDetailsNavigationService,
    private _phonePatternPipe: PhonePatternPipe,
    private _appSession: AppSession,
    route: ActivatedRoute,
    eventHandler: EventHandler,
    contentHlpr: ContentHelper
  ) {
    super(route, eventHandler, _appSession, contentHlpr, 'PFSearchResultsContainerComponent');
  }

  getCommonImagePath(imageName: string) {
    return this.getCommonImageURL(imageName);
  }

  showProviderDetails(professional: IProvider) {
    let address = {} as IAddress;
    const pageFadObj = {
      header: this.content.globalHeaderComponent.pageHeader.fadPageTitle,
      title: this.content.globalHeaderComponent.pageTitle.details
    };
    if (professional.location.address) {
      address = {
        addressId: professional.location.address.addressId,
        city: professional.location.address.city,
        state: professional.location.address.state ?? '',
        postalCode: professional.location.address.postalCode,
        latitude: professional.location.address.latitude,
        longitude: professional.location.address.longitude
      };
    }
    const selectedProvider = {
      providerIdentifier: professional.providerIdentifier,
      providerTypeCodeList: professional.providerTypeCodeList,
      providerName: professional.providerName,
      pdtStrKey: professional.pdtStrKey || '',
      location: {
        address: address
      },
      providerPlanType: professional.providerPlanType ?? undefined
    };
    if (professional?.costInfo?.isOfficeVisitCost && CommonUtil.isValidString(professional.costInfo?.planPays)) {
      this._appSession.medicalCode = OFFICE_VISIT_MEDICALCODE.MEDICAL_CODE;
      this._appSession.billingType = BILLING_TYPE.CPT;
      this._appSession.selectedPosCode = this._appSession.metaData?.locale === Locale.ENGLISH ? OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_EN : OFFICE_VISIT_MEDICALCODE.SELECTED_POS_CODE_ES;
      this._appSession.searchTerm = OFFICE_VISIT_MEDICALCODE.SEARCH_TERM;
      this._appSession.isSpecialtyOfficeVisitCost = true;
    }
    this._dataService.setPageTitle(pageFadObj);
    this._providerDetailsNavigationService.navigateToProviderDetails(selectedProvider);
  }

  openDomainDisclaimerModal(url: string) {
    this.openDomain.emit(url);
  }

  openLocationMapModal(professional: IProvider) {
    this.openMapModal.emit(professional);
  }

  getAddress(professional: IProvider) {
    return CommonUtil.swapOfficeLocation(this.showSPFI, professional);
  }

  administrativeOffice(activeFlagList: Array<string>): boolean {
    return this.WcsUtility.isAdministrativeOffice(activeFlagList);
  }

  formatDistance(distance: string) {
    return distance ? parseFloat(distance).toFixed(2) : '';
  }

  formatPhone(phone: string) {
    return this._phonePatternPipe.transform(phone);
  }

  get showProviderServiceLocationName(): boolean {
    return this._appSession?.feature?.showServiceLocationName || false;
  }

  getProviderName(provider: IProvider): string {
    return CommonUtil.getProviderTitles(provider.titleList, provider.networkRegisteredName, provider.providerName);
  }
}
