import { Locale } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { FindCareFeature } from '../enums/findCareFeature';
import { FindCareFlag } from '../enums/findCareFlag';
import { IOpsIndicator } from '../interfaces/iBootstrap';

export class FeatureManagerUtility {
  /**
   * Evaluates whether a given feature is available under appSession.bootstrap.features.
   * @returns true if feature available for the currently selected contractUid-MbrUid, otherwise false. @default false.
   */
  static isFeatureLive(value: FindCareFeature | Array<FindCareFeature>, appSession: AppSession, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    const _features = appSession?.appState?.features || [];
    if (_features.length === 0 || !value) {
      return false;
    }
    if (Array.isArray(value)) {
      if (logicBetween === 'AND') {
        return value.every((v) => _features.includes(v));
      } else {
        return value.some((v) => _features.includes(v));
      }
    } else if (typeof value === 'string') {
      return _features.includes(value);
    } else {
      return false;
    }
  }

  /**
   * Evaluates whether a given flag is available under appSession.appFlags.
   * @returns true if flag available for the currently selected contractUid-MbrUid, otherwise false. This also evaluates productFlags. @default false.
   */
  static isFlagLive(value: FindCareFlag | Array<FindCareFlag>, appSession: AppSession, logicBetween: 'AND' | 'OR' = 'OR'): boolean {
    const _flags = appSession?.appState?.flags || [];
    if (_flags.length === 0 || !value) {
      return false;
    }
    if (Array.isArray(value)) {
      if (logicBetween === 'AND') {
        return value.every((v) => _flags.includes(v));
      } else {
        return value.some((v) => _flags.includes(v));
      }
    } else if (typeof value === 'string') {
      return _flags.includes(value);
    } else {
      return false;
    }
  }

  static getOpsIndicator(appSession: AppSession): IOpsIndicator {
    const _opsIndicator = {} as IOpsIndicator;
    _opsIndicator.canRemovePcp = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.PCP_REMOVE, appSession);
    _opsIndicator.hasCastlightTransparency = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_CASTLIGHT, appSession);
    _opsIndicator.hasCoupeHealth = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_COUPE_HEALTH, appSession);
    _opsIndicator.hasDentalCoverage = FeatureManagerUtility.isFlagLive(FindCareFlag.DENTAL_COVERAGE, appSession);
    _opsIndicator.hasEyc =
      FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_EYC, appSession) || FeatureManagerUtility.isFlagLive([FindCareFlag.TCC_SEARCH_ENABLED, FindCareFlag.MEDICAL_COVERAGE], appSession, 'AND');
    _opsIndicator.hasFindCareFts = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_FTS, appSession);
    _opsIndicator.hasHealthAdvocacy = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.HEALTH_ADVOCACY, appSession);
    _opsIndicator.hasHealthSystem = FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_HEALTHSYSTEM, appSession);
    _opsIndicator.hasHealthWellness = FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_HEALTH_WELLNESS_LEGACY_EXPERIENCE, appSession);
    _opsIndicator.hasEngagement = FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_MEMBER_ENGAGE, appSession) || FeatureManagerUtility.isFeatureLive(FindCareFeature.ENGAGEMENT, appSession);
    _opsIndicator.hasLHO = FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_LHO, appSession) && !FeatureManagerUtility.isFeatureLive(FindCareFeature.SUPPRESS_LHO, appSession);
    _opsIndicator.hasMedicalCoverage = FeatureManagerUtility.isFlagLive(FindCareFlag.MEDICAL_COVERAGE, appSession);
    _opsIndicator.hasOnsiteClinics = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.ONSITE_CLINICS, appSession);
    _opsIndicator.hasRxCoverage = FeatureManagerUtility.isFlagLive(FindCareFlag.RX_COVERAGE, appSession);
    _opsIndicator.hasRxCVS = appSession.isSecureState && FeatureManagerUtility.isFlagLive(FindCareFlag.RX_CVS, appSession);
    _opsIndicator.hasTalkSpaceSSO = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.TALKSPACE_SSO, appSession);
    _opsIndicator.hasTelehealth = FeatureManagerUtility.isFeatureLive(FindCareFeature.TELEHEALTH, appSession);
    _opsIndicator.hasVirtualUrgentCare = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.VIRTUALCARE_TEXT_WITH_DOCTOR, appSession);
    _opsIndicator.hasVirtualCareHeadway = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.HEADWAY_VIRTUAL_CARE, appSession);
    _opsIndicator.hasVisionCoverage = FeatureManagerUtility.isFlagLive(FindCareFlag.VISION_COVERAGE, appSession);
    _opsIndicator.hasVpc =
      appSession.isSecureState &&
      FeatureManagerUtility.isFeatureLive(
        [FindCareFeature.VIRTUALCARE_TEXT_WITH_DOCTOR, FindCareFeature.VPC, FindCareFeature.VIRTUAL_URGENT_CARE, FindCareFeature.HEADWAY_VIRTUAL_CARE],
        appSession,
        'OR'
      ) &&
      !FeatureManagerUtility.isFeatureLive(FindCareFeature.SUPPRESS_VPC, appSession);
    _opsIndicator.isAnpEnabled = FeatureManagerUtility.isFlagLive(FindCareFlag.ANP_ELIGIBLE_SRC, appSession);
    _opsIndicator.isCoeEligible = FeatureManagerUtility.isFlagLive(FindCareFlag.SOCT_COE, appSession);
    _opsIndicator.isDentalStandAlone = FeatureManagerUtility.isFlagLive(FindCareFlag.DENTAL_STANDALONE_CONTRACT, appSession);
    _opsIndicator.isDetailV2Enabled = FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_PROV_DTL_SOURCE_MGN, appSession);
    _opsIndicator.isFcrEnabled = FeatureManagerUtility.isFlagLive(FindCareFlag.FCR, appSession) && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_V5, appSession);
    _opsIndicator.isFtsEnabled =
      FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_FTS, appSession) && appSession.metaData?.locale === Locale.ENGLISH && appSession.appState?.opsState?.hasMemberContract;
    _opsIndicator.isLiveChatEnabled = FeatureManagerUtility.isFeatureLive([FindCareFeature.FC_LIVE_CHAT_SUPPORT, FindCareFeature.CHAT], appSession, 'AND');
    _opsIndicator.isMedicalCodeSearchEnabled = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_SEARCH_CPT, appSession);
    _opsIndicator.isMedicalStandAlone = FeatureManagerUtility.isFlagLive(FindCareFlag.MEDICAL_STANDALONE_CONTRACT, appSession);
    _opsIndicator.isPcpEligible = appSession.isSecureState && FeatureManagerUtility.isFlagLive(FindCareFlag.PCP_CAPABLE, appSession);
    _opsIndicator.isRxStandAlone = FeatureManagerUtility.isFlagLive(FindCareFlag.RX_STANDALONE_CONTRACT, appSession);
    _opsIndicator.isTccColdStateMedicalEnabled = FeatureManagerUtility.isFlagLive(FindCareFlag.COLD_TCC_ENABLED, appSession);
    _opsIndicator.isTccDentalEnabled = appSession.isSecureState && FeatureManagerUtility.isFlagLive(FindCareFlag.DENTAL_TCC_ENABLED, appSession);
    _opsIndicator.isTccMedicalEnabled = appSession.isSecureState && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_SEARCH_TCC, appSession);
    _opsIndicator.isVisionStandAlone = FeatureManagerUtility.isFlagLive(FindCareFlag.VISION_STANDALONE_CONTRACT, appSession);
    _opsIndicator.isVirtualOnlySearchFilterEnabled = FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_SEARCH_FILTER_VIRTUALPROV, appSession);
    _opsIndicator.isIntentEnabled =
      appSession.isSecureState &&
      FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_INTENT_RESOLUTION, appSession) &&
      FeatureManagerUtility.isFlagLive(FindCareFlag.BH_INTENT_RESOLUTION, appSession) &&
      appSession.metaData?.locale === Locale.ENGLISH &&
      appSession.isEmulation;
    return _opsIndicator;
  }

  /** Check whether FCR is available for the current system state. */
  static isFcrEnabled(appSession: AppSession): boolean {
    return FeatureManagerUtility.isFlagLive(FindCareFlag.FCR, appSession) && FeatureManagerUtility.isFeatureLive(FindCareFeature.FC_V5, appSession);
  }
}
