// TODO: others message need to be updated
export const PAGE_ALERTS_EN = {
  PA_COVID_FINDER_ALERT: "Find a testing center near you with our <a target='_blank' href='https://www.anthem.com/microsites/covid19-assessment/covid-finder.html'>COVID-19 Test Site Finder</a>",

  PA_LABS_ALERT: "Whether a lab is 'in-network' also depends on the location of the doctor who ordered the test. Please update the search location with the doctor's ZIP code.",

  PA_WLP_BH_ALERT:
    "Behavioral health services for the Wellpoint State Indemnity Plan are provided by Carelon Behavioral Health. To find a behavioral health professional, go to the <a target='_blank' href='{brandUrl}'>Carelon Behavioral Health website",
  PA_BEHAVIORAL_ALERT: "Your plan does not include coverage for behavioral health. Please contact <a target='_blank' href='{brandUrl}'>member services</a> if you have any questions about your plan.",

  PA_MSSP_ALERT:
    "To learn more about the <a target='_blank' href='https://www.aging.ca.gov/Providers_and_Partners/Multipurpose_Senior_Services_Program/'>Multipurpose Senior Services Program (MSSP)</a>, and help with the application process, please contact your Care Coordinator.",

  PA_UNIMASS_SECURE_EYC_ALERT:
    '<span id="lnk-member-service" class="pf-pre-wrap pf-inline-span"><span id="lnk-member-service" aria-label="click here to navigate to Member Service">We\'re not able to show procedure cost estimates for care providers located outside of Massachusetts. If you have any questions, please contact <a target="_blank" aria-label=" Member Service" id="lnk-member-service-anchor" href="/contact-us">Member Service.</a> </span></span>',

  PA_MEMBER_32BJ_ALERT: 'As of 01/01/2025, joint replacement, spine, and bariatric surgery must be provided by Lantern. Call Lantern at 855-413-7197.',

  PA_GA_TERMINATING_PROVIDERS_ALERT:
    '<span id="termination-Providers-link-text" class="pf-pre-wrap pf-inline-span"><span id="termination-Providers-link-text" aria-label="click here to navigate to care providers who may leave your plan network">Please review this list of <a target="_blank" aria-label=" care providers who may leave your plan network" id="termination-Providers-link-text-anchor" href="/assets/documents/gaterminatingproviders.pdf">care providers who may leave your plan network</a> during the upcoming plan year.</span></span>',

  PA_EMORY_ALERT:
    'Emory has joined the Pathway network effective 01/01/21. This means that Emory providers will be “in-network” for our 2021 Individual benefit plans. We are working to update our provider directory to reflect this update.',

  PA_MSK_WINFERTILITY:
    "Your fertility benefits are managed by <a target='_blank' href='https://managed.winfertility.com/memorial-sloan-kettering/'>WINFertility</a>.  To access your benefits, you must enroll with a WINFertility nurse case manager by calling 844-343-0656.",

  PA_PROMOTED_PROCEDURES_ALERT:
    'Some members with similar health histories as you have needed certain procedures.<br><a target="_self" href="javascript:void(0);" class="promotedProcedures" data-analytics="compareProvFindCare" aria-label="Procedures You May Be Considering.link">Procedures You May Be Considering</a>',

  PA_DENTAQUEST:
    '<span id="dentaquest-label-text" class="pf-pre-wrap pf-inline-span"><span id="dentaquest-label-text" aria-label="DentaQuest manages some parts of your dental benefit.">DentaQuest manages some parts of your dental benefit.</span><span id="dentaquest-link-text" aria-label="Go to the DentaQuest website to find a dental professional in your network.">Go to the <a target="_blank" aria-label=" DentaQuest website" id="dentaquest-link-text-anchor" href="http://www.dentaquest.com/members/">DentaQuest website</a> to find a dental professional in your network.</span></span>',

  PA_TN_DENTAQUEST:
    '<span id="tndentaquest-label-text" class="pf-pre-wrap pf-inline-span"><span id="tndentaquest-label-text" aria-label="Some of your 2022 dental benefits are managed by DentaQuest.">Some of your 2022 dental benefits are managed by DentaQuest. </span><span id="tndentaquest-link-text" aria-label="To find a dental professional in your 2022 dental plan, go to the DentaQuest website">To find a dental professional in your 2022 dental plan, go to the <a target="_blank" aria-label=" DentaQuest website" id="tndentaquest-link-text-anchor" href="http://www.dentaquest.com/members/">DentaQuest website</a></span></span>',
  PA_MAGELLAN_PHARMACY:
    '<span id="pharmacy-label-text" class="pf-pre-wrap pf-inline-span"><span id="pharmacy-label-text" aria-label="Magellan manages some parts of your Pharmacy benefit.">Magellan manages some parts of your Pharmacy benefit.</span><span id="pharmacy-link-text" aria-label="Go to the pharmcy website to find a pharmacy in your network.">Go to the <a target="_blank" aria-label=" Magellan website" id="pharmacy-link-text-anchor" href="https://www.lamcopbmpharmacy.com">Magellan website</a> to find a pharmacy in your network.</span></span>',

  PA_EMPTY_REULTS_RESULT_PAGE:
    '<span class="ant-font-b pf-inline-span">No Results.</span> We’ve automatically expanded your search but didn’t find any matches within <span class="ant-font-b pf-inline-span">{RDS} miles</span> of <span class="ant-font-b pf-inline-span">{LOC}</span>. Try changing the ZIP code or <a target="_blank" href="/contact-us">Contact Us</a> if you have questions about your benefits.',
  PA_INCREASED_RADIUS_ALERT:
    'We couldn’t find any results, so we expanded the search distance to <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span> and found <span class="ant-font-b">{CNT} results</span>{MATCH}.',
  PA_TENNCARE_PHARMACY:
    'TennCare Pharmacy program: If you have prescription drug coverage through TennCare, a health plan called Magellan Health Services takes care of your prescription medicines. Call about your pharmacy services at 1-888-816-1680.',
  PA_NPN_PDF:
    '<span id="lnk-navituspdf" class="pf-pre-wrap pf-inline-span"><span id="lnk-navituspdf" aria-label="click here to open Navitus Pharmacy Network PDF">Find a pharmacy in your plan by viewing the <a target="_blank" aria-label=" Navitus Pharmacies Network Listing (PDF)" id="lnk-navituspdf-anchor" href="https://www.navitus.com/getmedia/25f6cef8-46f9-4d0b-9bd5-7ae8a4a13150/Navitus-Pharmacy-Network-Listing-National-and-Chains.pdf">Navitus Pharmacies Network Listing (PDF)</a></span></span>',
  PA_KYPORTAL:
    '<span id="lnk-kyportal" class="pf-pre-wrap pf-inline-span"><span id="lnk-kyportal" aria-label="click here to open Pharmacy locator tool">To find a pharmacy near you, use our <a target="_blank" aria-label=" pharmacy locator tool." id="lnk-kyportal-anchor" href="http://kyportal.medimpact.com/">pharmacy locator tool.</a></span></span>',
  PA_BH_SIMPLYMPL: 'For help finding a behavioral health professional, please call <b>844-375-7215.</b>',
  PA_HOME_HEALTH_SIMPLYMPL: 'For help finding home health care, please call the member services number on your ID card.',
  PA_DME_SIMPLYMPL: 'For help finding a DME/medical/surgical professional, please call the member services number on your ID card.',
  PA_NO_OO_RESULT_PAGE:
    '<strong>This care provider isn\'t in your plan\'s network.</strong> We have automatically increased the search distance but no results were found for <span class="ant-font-b">{PVD}</span> in the <span class="ant-font-b">{PLAN}</span> within <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>{MATCH}.',
  PA_OON_SEARCH_NO_RESULT:
    'No Results. We’ve automatically expanded your search but didn’t find any matches within <span class="ant-font-b">{RDS}</span> miles of <span class="ant-font-b">{LOC}</span>. Try changing the ZIP code or <a href="https://www.anthem.com/contact-us">Contact Us</a> if you have questions about your benefits.',
  PA_NO_RESULT_DEFAULT_MSG: 'No results were found for {PVD} in the {PLAN} within {RDS} miles of {LOC}{MATCH}.',
  PA_DME_ALERT: 'If searching for Durable Medical Equipment, search in the area where the equipment will be shipped to or purchased at a retail store.',
  PA_HCS:
    '<span id="hcs-label-text" class="pf-pre-wrap pf-inline-span"><span id="hcs-label-text" aria-label="Hearing Care Solutions handles some parts of your hearing benefit.">Hearing Care Solutions handles some parts of your hearing benefit.</span><span id="hcs-link-text" aria-label="Visit the Hearing Care Solutions website to find a hearing professional in your network.">Visit the <a target="_blank" aria-label=" Hearing Care website Solutions" id="hcs-link-text-anchor" href="https://hearingcaresolutions.com/anthem/">Hearing Care Solutions website</a> to find a hearing professional on your network.</span></span>',
  PA_LIBERTY:
    '<span id="liberty-label-text" class="pf-pre-wrap pf-inline-span"><span id="liberty-label-text" aria-label="Liberty Dental manages some parts of your dental benefit.">Liberty Dental manages some parts of your dental benefit.</span><span id="liberty-link-text" aria-label="Go to the Liberty Dental website to find a dental professional in your network.">Go to the <a target="_blank" aria-label=" Liberty Dental website" id="liberty-link-text-anchor" href="https://client.libertydentalplan.com/anthem/FindADentist">Liberty Dental website</a> to find a dental professional in your network.</span></span>',
  PA_EYEQUEST:
    '<span id="eyequest-label-text" class="pf-pre-wrap pf-inline-span"><span id="eyequest-label-text" aria-label="EyeQuest manages some parts of your vision benefit.">EyeQuest manages some parts of your vision benefit.</span><span id="eyequest-link-text" aria-label="Go to the EyeQuest website to find a vision professional in your network.">Go to the <a target="_blank" aria-label=" EyeQuest website" id="eyequest-link-text-anchor" href="https://govservices10.dentaquest.com/Router.jsp?component=Main&amp;source=Logon&amp;action=ProviderDirectory">EyeQuest website</a> to find a vision professional in your network.</span></span>',
  PA_GREEN_MEDICAL_BH:
    'Behavioral health services for the UniCare State Indemnity Plan are provided by Carelon Behavioral Health. To find a behavioral health professional, go to the <a target="_blank" href="https://plan.carelonbehavioralhealth.com/find-a-provider/unicare_gic">Carelon Behavioral Health website.</a>',
  PA_MARCH:
    '<span id="lnk-march" class="pf-pre-wrap pf-inline-span"><span id="lnk-march" aria-label="click here to navigate to March Vision Care website">March Vision Care manages some parts of your vision benefit. Go to the <a target="_blank" aria-label=" March Vision Care website" id="lnk-march-anchor" href="https://www.marchvisioncare.com/">March Vision Care website</a> to find a vision professional in your network.</span></span>',
  PA_DNTAQST_FWDHLTH:
    '<span id="lnk-dentaquest" class="pf-pre-wrap pf-inline-span"><span id="lnk-dentaquest" aria-label="click here to navigate to DentaQuest website">If you live in Kenosha, Milwaukee, Ozaukee, Racine, Washington and Waukesha Counties, DentaQuest manages some parts of your dental benefit. Go to the <a target="_blank" aria-label=" DentaQuest website" id="lnk-dentaquest-anchor" href="http://www.dentaquest.com/members/">DentaQuest website</a> to find a dental professional in your network. For members who line in other Wisconsin counties, call ForwardHealth Member Services at 1-800-362-3002 to learn about your dental benefits.</span></span>',
  PA_LCS:
    'Counseling services for breast feeding (lactation) may be provided or supported by an in-network (participating) provider such as a pediatrician, ob-gyn, or hospital. Contact your provider to determine if lactation counseling services are available. If your search did not return any results, search under Office Services for Lactation Services.',
  PA_DENTAL_PROC_MSG:
    'This procedure may not be covered by your dental plan. Please refer to <a href="/member/benefits?covtype=den" tabindex=\'0\' aria-label=\'your dental plan\' target="_blank"> your dental plan </a> or <a href="/member/contact-us" tabindex=\'0\' aria-label=\'contact us\' target="_blank">contact us</a> if you have questions about your dental benefits.',
  PA_EycAlert: "The ability to search by medical procedure won't be available for 12-18 hours due to maintenance.",
  PA_SUPERIOR:
    'Superior manages some parts of your vision benefit. Go to the <a target="_blank" aria-label="click here to navigate to Superior website" href="https://superiorvision.com">Superior website </a> to find a vision professional in your network.',
  PA_HEALTHY_BLUE_EYEMED:
    'EyeMed manages some parts of your vision benefit. Go to the <a target="_blank" aria-label="click here to navigate to EyeMed website" href="https://eyedoclocator.eyemedvisioncare.com/healthyblueks/en"> EyeMed website </a> to find a vision professional in your network.</a>',
  PA_SKYGEN:
    'Skygen manages some parts of your dental benefit. Go to the <a target="_blank" aria-label="click here to navigate to Skygen website" href="https://healthybluemwp.sciondental.com/">Skygen website </a> to find a dental professional in your network.',
  PA_TEXCHIP: 'CHIP Dental (Texas) provides dental benefits for members. Please call 1-877-KIDS-NOW (1-877-543-7669) for assistance',
  PA_TEXSTAR: 'Texas Health Steps provider’s dental benefits for members under the age of 21. Please call 1-877-847-8377 for assistance.',
  PA_TEXCHIP_PERINATAL: 'To find a dental provider, please call DentaQuest at 1-855-418-1621.',
  PA_DELTA: 'To find a dental provider, please call Delta Dental at 1-800-774-9049 (TTY 711).',
  PA_MD_DENTAL:
    '<span id="lnk-maryland" class="pf-pre-wrap pf-inline-span"><span id="lnk-maryland" aria-label="click here to navigate to Maryland Health Smiles website">For children under age 21 and pregnant women, Maryland Health Smiles manages some parts of your dental benefit. Go to the <a target="_blank" aria-label=" Maryland Health Smiles website" id="lnk-kyportal-anchor" href="https://mdmwp.sciondental.com/MWP/Landing">Maryland Health Smiles website</a> to find a dental professional in your network.</span><span id="lnk-maryland" aria-label="click here to navigate to DentaQuest website">\n\nFor adults age 21 and older, DentaQuest manages some parts of your dental benefit. Go to the <a target="_blank" aria-label=" DentaQuest website" id="lnk-kyportal-anchor" href="http://www.dentaquest.com/members/">DentaQuest website</a> to find a dental professional in your network.</span></span>',
  PA_OHDENTAQUEST:
    '<span id="lbl-info-PA_OHLIBERTYDENTAL" class="pf-pre-wrap pf-inline-span"><span id="lbl-info-PA_OHLIBERTYDENTAL" aria-label="click here to navigate to Liberty Dental website">Liberty Dental manages some parts of your dental benefit. Go to the <a target="_blank" aria-label=" Liberty Dental website" id="lbl-info-PA_OHLIBERTYDENTAL-anchor" href="https://client.libertydentalplan.com/anthemoh">Liberty Dental website</a> to find a dental professional in your network.</span></span>',
  PA_AVESIS:
    '<span id="lnk-avesis" class="pf-pre-wrap pf-inline-span"><span id="lnk-avesis" aria-label="click here to navigate to Avesis website">Avesis manages some parts of your vision benefit. Go to the <a target="_blank" aria-label=" Avesis website" id="lnk-avesis-anchor" href="https://www.fap.avesis.com/amerigroupga/provider/search">Avesis website</a> to find a vision professional in your network.</span></span>',
  PA_OHEYEMED:
    '<span id="lnk-oheyemed" class="pf-pre-wrap pf-inline-span"><span id="lnk-oheyemed" aria-label="click here to navigate to OHEyeMed website">EyeMed manages some parts of your vision benefit. Go to the <a target="_blank" aria-label=" EyeMed website" id="lnk-oheyemed-anchor" href="https://eyedoclocator.eyemedvisioncare.com/anthemohmd">EyeMed website</a> to find a vision professional in your network.</span></span>',
  PA_EYEMED:
    '<span id="lnk-eyemed" class="pf-pre-wrap pf-inline-span"><span id="lnk-eyemed" aria-label="click here to navigate to EyeMed website">EyeMed manages some parts of your vision benefit. Go to the <a target="_blank" aria-label=" EyeMed website" id="lnk-eyemed-anchor" href="https://eyedoclocator.eyemedvisioncare.com/ahkplus/?networkDDDisabled=true">EyeMed website</a> to find a vision professional in your network.</span></span>',
  PA_VSP:
    'VSP manages some parts of your vision benefit. Go to the <a target="_blank" aria-label="VSP manages some parts of your vision benefit." href="https://www.vsp.com/find-eye-doctors.html?id=medicaid">VSP website</a> to find a vision professional in your network.',
  PA_D136:
    "<div>Not all Pathologists at this hospital are participating providers with Anthem. Those who are not participating may bill you for the difference between Anthem's reimbursement and their billed charge, which may result in significant financial responsibility to you. In the event you are treated for an emergency condition and you are an HMO member, you are only responsible for any in-network co-pays or coinsurance. Please refer to your member benefit booklet or certificate to determine your level of financial responsibility, when you receive services by a non-participating Pathologist. If you have any questions, please contact us.</div>",
  PA_D254: '<div>Updates received from our network providers are generally added to this tool once a week.</div>',
  PA_D262:
    '<div><strong>IMPORTANT</strong><BR>While we make efforts to ensure that our lists of doctors and hospitals are up to date and accurate, providers do leave our networks from time to time, and these listings do change. There are hospitals, doctors or other providers who are not included in every plan network. Please make sure you are searching the right network. There may be higher costs to you if you visit a provider who is not in your plan network. We recommend you contact the provider to confirm that they are in your plan network and that the desired service is covered. </div>',
  PA_D284:
    "<div>Not all Pathology at this hospital are participating with Anthem. Those who are not may bill you for the difference between Anthem's reimbursement and their billed charge, which may result in significant financial responsibility to you. However, in the event that you are treated by a non-participating pathologist for an emergency condition or you are an HMO member, please note that you are only responsible for any in-network co-pays or coinsurance. Please refer to your member benefit booklet or certificate to determine your level of financial responsibility, if any, when you receive services by a non-participating pathologist. If you have any questions, please contact us.</div>",
  PA_D37:
    "<div>Specialty: Certified Clinical Nurse Specialist<br />This provider is not credentialed. The fact that a provider has not been credentialed by Anthem Blue Cross and Blue Shield is not an indicator of quality. It only means that Anthem Blue Cross and Blue Shield has not taken any steps to determine the qualifications of that particular provider. All of the providers have represented that they are licensed and carry levels of malpractice insurance that meet Anthem Blue Cross and Blue Shield's minimum requirements. Anthem Blue Cross and Blue Shield encourages you to discuss with your providers information about their qualifications.</div>",
  PA_D268:
    '<div><strong>IMPORTANT</strong><br />While we make efforts to ensure that our lists of doctors and hospitals are up to date and accurate, providers do leave our networks from time to time, and these listings do change. There are hospitals, doctors or other providers who are not included in every plan network. <strong>Please make sure you are searching the right network.</strong> There may be higher costs to you if you visit a provider who is not in your plan network. We recommend you contact the provider to confirm that they are in your plan network and that the desired service is covered.</div>',
  PA_D264:
    '<div><strong>IMPORTANT</strong><BR>While we make efforts to ensure that our lists of doctors and hospitals are up to date and accurate, providers do leave our networks from time to time, and these listings do change. There are hospitals, doctors or other providers who are not included in every plan network. Please make sure you are searching the right network. There may be higher costs to you if you visit a provider who is not in your plan network. We recommend you contact the provider to confirm that they are in your plan network and that the desired service is covered.</div>',
  PA_CPT: 'Before scheduling an appointment, please check with this care provider to confirm they perform this procedure or service.',
  PA_CPT_PD_BACK_PROVIDER:
    '<span><span [innerText]="We don’t have a cost estimate for this care provider performing this service."> </span><span><a class="pf-back-link" href="javascript:void(0);" (click)="goToProviderList()" tabindex="0"><span class="pf-back-provider-link" [innerText]="find care providers who perform this service."></span></a></span></span>',
  PA_NO_COST: 'We don’t have a cost estimate for this care provider performing this service. Search for another medical code or find care providers who perform this service.',
  PA_PLAN_NOT_COVER: 'Your plan doesn\'t cover this procedure. <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your plan\'s benefits.',
  PA_BENEFIT_DENIED: 'Unable to show some cost data',
  PA_SUPPRESSED_MEDSUPP:
    "To find a medical care provider that accepts Medicare Assignment (one that accepts the Medicare-approved amount as full payment) go to <a target='_blank' href='https://www.medicare.gov/care-compare/'>es.medicare.gov/care-compare</a>.",
  PA_MORE_THAN_ONE_VISIT_COST_MESSAGE:
    "The estimated costs we're showing are for visits 1-{VISITS_VALUE}.  To view coverage information for this procedure, including service limits, go to <a target='_blank' href='/member/benefits?covtype=med'>Find Benefits</a>.",
  PA_INITIAL_VISIT_COST_MESSAGE:
    "The estimated costs we're showing are for an initial visit. To view detailed benefit information for this procedure, including any service limits, go to <a target='_blank' href='/member/benefits?covtype=med'>Find Benefits</a>.",
  PA_SPCLTY_PHMCY_ALERT:
    'Whether a medical specialty pharmacy is "in-network" also depends on the location of the doctor who ordered the prescription. Please update the search location with the doctor\'s ZIP code.',
  PA_TN_LIBERTY:
    '<span id="tnliberty-label-text" class="pf-pre-wrap pf-inline-span"><span id="tnliberty-label-text" aria-label="Some of your 2023 dental benefits will be managed by Liberty Dental.">Some of your 2023 dental benefits will be managed by Liberty Dental. </span><span id="tnliberty-link-text" aria-label="To find a dental professional in your 2023 dental plan, go to the Liberty Dental website.">To find a dental professional in your 2023 dental plan, go to the <a target="_blank" aria-label=" Liberty Dental website" id="tnliberty-link-text-anchor" href="https://client.libertydentalplan.com/anthem/FindADentist">Liberty Dental website</a>.</span></span>',
  PA_GA_TERMINATION_PROVIDERS:
    '<span id="termination-Providers-link-text" class="pf-pre-wrap pf-inline-span"><span id="termination-Providers-link-text" aria-label="Please review this list of care providers who may leave your plan network during the upcoming plan year.">Please review this list of <a target="_blank" aria-label=" care providers who may leave your plan network" id="termination-Providers-link-text-anchor" href="/assets/documents/gaterminatingproviders.pdf" data-analytics="providersLeftNetworkAlertFindCare">care providers who may leave your plan network</a> during the upcoming plan year.</span></span>',
  PA_MPLVISION: 'For help finding Vision Professional, please call the member services number on your ID card.',
  PA_NON_EMG_SVC: "You'll need a referral from your Primary Care Physician (PCP) before receiving non-emergency services from a retail health clinic, lab, pathology, or radiology care provider.",
  PA_PUBLICPHARMACY:
    '<span id="lnk-pharmacy-login" class="pf-pre-wrap pf-inline-span"><span id="lnk-pharmacy-login" aria-label="click here to log in to find a pharmacy">To find a pharmacy in your plan: Members can either <a target="_blank" aria-label=" log in to find a pharmacy" id="lnk-pharmacy-login-anchor" href="/login">log in to find a pharmacy</a></span><span id="lnk-pharmacy-without-login" aria-label="click here to view our pharmacy networks without logging in">or  <a target="_blank" aria-label=" view our pharmacy networks without logging in." id="lnk-pharmacy-without-login-anchor" href="{brandUrl}pharmacyinformation/rxnetworks.html">view our pharmacy networks without logging in.</a> </span><span id="lnk-download-pharmacy-directory" aria-label="click here to download their pharmacy directory">Members who have a retiree plan through their employer can <a target="_blank" aria-label=" download their pharmacy directory" id="lnk-download-pharmacy-directory-anchor" href="{brandUrl}provider/f0/s0/t0/pw_g344160.pdf">download their pharmacy directory</a> or call the First Impressions Welcome Team number found in their enrollment guide. (Hint: After downloading the directory, try searching for your ZIP code to find a pharmacy in your area.)</span></span>',
  PA_WLP_MEDICAL_BH:
    '<span id="lnk-wlp-medical-BH" class="pf-pre-wrap pf-inline-span"><span id="lnk-wlp-medical-BH" aria-label="Click here to navigate to wlp">Behavioral health services for the Wellpoint Massachusetts plan are provided by Carelon Behavioral Health. To find a behavioral health professional, go to the <a target="_blank" aria-label=" Carelon Behavioral Health website" id="lnk-wlp-medical-BH-anchor" href="https://www.carelonbh.com/wellpoint/">Carelon Behavioral Health website</a></span></span>',
  PA_COUPE_ALERT:
    '<span id="coupe-health-text" class="pf-pre-wrap pf-inline-span"><span id="coupe-health-text" aria-label="To find care using your Coupe Health medical benefits, go to coupehealth.com">To find care using your Coupe Health medical benefits, go to <a target="_blank" aria-label=" coupehealth.com" id="coupe-health-text-anchor" href="https://www.coupehealth.com" data-analytics="linkCoupeAlertHomeFindCare">coupehealth.com</a><span class="motif-icon motif-external-link info-icon"></span></span></span>',
  PA_PRIME_GROUP_SEARCH:
    'These search results show care providers in your medical group. If you want to search outside your medical group, select "No Preference" in the Your Medical Group filter. You\'ll need a referral from your Primary Care Physician before receiving non-emergency services.',
  PA_HEALTH_SYSTEM_MSG:
    'These search results show care providers in your medical group. To search outside your medical group, choose another option in the "Your Health System" filter. However, if you seek non-emergency care outside your medical group, you will need a referral from your PCP.',
  PA_NO_RESULT_SNY_MEMBER:
    '<span id="results-info" class="pf-pre-wrap pf-inline-span"><span id="results-info" aria-label="click here to navigate to other provider">Please note that only General/Acute Care hospitals, Hospice facilities, and Skilled Nursing Facilities can be searched on this site. If you are looking for any other provider, please visit <a target="_blank" aria-label=" www.cs.ny.gov/employee-benefits/nyship/shared/providers" id="results-info-anchor" href="https://www.cs.ny.gov/employee-benefits/nyship/shared/providers/index.cfm">www.cs.ny.gov/employee-benefits/nyship/shared/providers</a></span></span>',
  PA_NO_RESULT:
    '<span class="ant-font-b"> No Results.</span> Try a different ZIP code or use &quot;Back to Find Care&quot; to start over. <a target="_blank" href="/member/contact-us">Contact Us</a> if you have questions about your plan\'s benefits.',
  PA_CPT_ALERT: 'If the purpose of this procedure or service is preventive care, and you use an in-network care provider, your share of the costs may be as low as $0.',
  PA_CA_HMO_UC: 'You have the benefit of having all of your care coordinated by your primary care physician. If you need to be seen urgently, please consult with your medical group on where to go.',
  PA_NON_GA_COLD_STATE_PROC_SEARCH_MSG:
    "To find care providers for this procedure outside of Georgia, <a tabindex='0' aria-label='please login' target='_blank' href='https://www.anthem.com/login/?dplid={deeplinkid}'>please log in</a>.",
  PA_PLAN_SELECTION: '<a class="pa-navigate-plan-selection"  role="button" type="button" tabindex="0">search Find Care by selecting a plan.</a>',
  PA_BH_SEARCH_ASSISTANT:
    "<div class='pf2-mt-5 pf2-pl-10 width-100 pf2-mb-5' id='page-alert-row'><span>Need help finding behavioral health services?</span><img class='bh-non-crisis-padding bh-non-crisis-image-padding bh-non-crisis-image'/><a role='button' type='button' tabindex='0'><strong class='navigateToChatBtn'>Use Search Assistant</strong></a></div>",
  PA_COST_NOT_AVAILABLE_ALERT: 'Cost estimates might not be available for all care providers',
  PA_BARIATRIC_REPLACEMENT_ALERT:
    '<span id="lnk-home-depot-bariatric-carve-out" class="pf-pre-wrap pf-inline-span"><span id="lnk-home-depot-bariatric-carve-out" aria-label="click here to navigate to livetheorangelife.com/lanternreq">Coverage for bariatric, hip, and knee surgery is only available through Lantern. For more information, call 877-923-2311 or visit <a target="_blank" aria-label=" livetheorangelife.com/lanternreq" id="lnk-home-depot-bariatric-carve-out-anchor" href="http://www.livetheorangelife.com/lanternreq" data-analytics="linkLanternAlertHomeFindCare">livetheorangelife.com/lanternreq</a>.<span class="motif-icon motif-external-link info-icon"></span></span></span>',
  PA_BARIATRIC_ALERT:
    '<span id="lnk-home-depot-bariatric-only" class="pf-pre-wrap pf-inline-span"><span id="lnk-home-depot-bariatric-only" aria-label="click here to navigate to livetheorangelife.com/lanternnr">Coverage for bariatric surgery is only available through Lantern. For more information, call 877-923-2311 or visit <a target="_blank" aria-label=" livetheorangelife.com/lanternnr" id="lnk-home-depot-bariatric-only-anchor" href="http://www.livetheorangelife.com/lanternnr" data-analytics="linkLanternAlertHomeFindCare">livetheorangelife.com/lanternnr</a>.<span class="motif-icon motif-external-link info-icon"></span></span></span>',
  PA_REFINE_SEARCH:
    'We couldn’t find any results for that specialty, so we’ve expanded the search to include related specialties within <span class="ant-font-b">{RDS} miles</span> of <span class="ant-font-b">{LOC}</span>. We found <span class="ant-font-b">{CNT}</span> results within that distance.'
};
