import { IProviderPDTKey } from '../../../common/interfaces/iProviderPDTKey';
import { EycProcedureCriteria } from '../../search-results/interfaces/iSearchRequest';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';

export interface IProcedureCostEst {
  providerIdentifier: string;
  addressIdentifier: string;
  locale: string;
  mbrUid?: string;
  identificationNumber?: string;
  contractUid?: string;
  professionalSequenceNumber?: string;
  eycProcedureCriteria?: EycProcedureCriteria;
  providerCostInfo?: ProviderCostInfo[];
  pdtKey?: IProviderPDTKey;
  providerSituations?: any;
  providerTier?: string;
  providerProcedureInfo?: ProviderProcedureInfo;
  isDentalEyc?: boolean;
  isFuturePlan: boolean;
  provCategoryCode: IBaseCode;
  oopEligible?: boolean;
}

export class ProviderProcedureInfo {
  procedureCode: string;
  procedureName?: string;
  procedureServicesList?: Array<ProcedureService>;
  professionalSequenceNumber?: string;
  costInfo?: CostInfo;
  costInfoArr?: Array<CostInfo>;
  providerCosts?: Array<CostInfo>;
  procedureDescription?: string;
  procedureInclude?: string;
}

export class ProcedureService {
  code: string;
  name: string;
  type: string;
}

export class CostInfo {
  costIHC?: string;
  costILC?: string;
  costTAC?: string;
  costTHC?: string;
  costTLC?: string;
}

export class ProviderCostInfo {
  facilitySequenceNumber?: string;
  inpatientLowCost: string;
  inpatientHighCost: string;
  typicalLowCost: string;
  typicalHighCost: string;
  avgAllowedCost: string;
}
