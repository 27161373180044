import { Component, EventEmitter, Inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { PhonePatternPipe } from '../../../../../common/pipes/phonePatternPipe';
import { ClinicalProgramNavigator } from '../../../../../common/services/clinicalProgramNav';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProgramCategory } from '../../../../common/constants/common';
import { CLINICAL_DISCLAIMERS, PREFIX_TP } from '../../../../common/constants/providerSearchConstants';
import { ICardItem, IOperationHour } from '../../../../common/interfaces/iProgramCard';

@Component({
  moduleId: module.id,
  selector: 'app-fc-program-cards-modal-cmp',
  templateUrl: './programCardsModalCmp.html'
})
export class ProgramCardsModalComponent extends BaseComponent {
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;

  @ViewChild('programCardsModal')
  programCardsModal: TemplateRef<HTMLElement>;

  @Input()
  orientation: string = 'row';

  private _cardsData: ICardItem[];

  showList: boolean;

  @Input()
  showDetails: boolean = false;

  @Input()
  selectedCardData: ICardItem;

  showSidePanel: boolean;
  disclaimerUrl: string;

  showAddress: boolean = true;

  @Output()
  onError: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    route: ActivatedRoute,
    eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    public _sidePanel: SidePanel,
    private _phonePatternPipe: PhonePatternPipe,
    private _clinicalProgramNav: ClinicalProgramNavigator
  ) {
    super(route, eventHandler, _appSession);
  }

  @Input()
  set cardsData(val: ICardItem[]) {
    if (!val) {
      val = [];
    }

    if (val !== this._cardsData) {
      this._cardsData = val;
      this.showList = val.length > 0;
    }
  }
  get cardsData(): ICardItem[] {
    return this._cardsData;
  }

  /**
   * Opens the side panel in the specified direction.
   * @param direction The direction to open the side panel.
   * @param list Whether to show the list view.
   * @param details Whether to show the details view.
   */
  openSidePanel(direction: any, list: boolean, details: boolean): void {
    this.showDetails = details;
    this.showList = list;
    this.sidePanelRef = this._sidePanel.open(direction, this.programCardsModal);
  }

  /**
   * Event handler for the cancel button click.
   * Closes the side panel.
   */
  onCancel(): void {
    this.sidePanelRef?.close();
  }

  /**
   * Event handler for the view more button click.
   * Opens the details view.
   * @param card The card item to view more details.
   */
  openProgramDetails(card: ICardItem) {
    if (card.category === ProgramCategory.ONSITE_CLINICS) {
      this.showDetails = true;
      this.showList = false;
      this.selectedCardData = card;
      this.showAddress = this.selectedCardData?.details?.address1?.length > 0 || this.selectedCardData?.details?.address2?.length > 0 || this.selectedCardData?.details?.latitude?.length > 0;
    } else {
      this.navigateToProgramDetails(card);
    }
  }

  /**
   * Navigates to the program details clinical / third party.
   * @param card The card item to navigate to.
   */
  navigateToProgramDetails(card: ICardItem) {
    if ((card?.id && PREFIX_TP + card?.id in CLINICAL_DISCLAIMERS) || card.category === ProgramCategory.THIRD_PARTY) {
      this._clinicalProgramNav.navigateToClinicalProgram(card.id)?.then((status: boolean) => {
        if (!status) {
          this.onError.emit(true);
        }
        this.onCancel();
      });
    }
  }

  /**
   * Event handler for the view all button click.
   * Opens the list view.
   */
  closeCardDetails() {
    this.showDetails = false;
    this.showList = true;
  }

  /**
   * Gets the map endpoint for the specified details.
   * @param details The details to get the map endpoint for.
   * @returns The map endpoint.
   */
  getMapEndpoint(details): string {
    const latitude = details?.latitude?.toString();
    const longitude = details?.longitude?.toString();
    return `${this.content?.result?.providerOverview?.mapEndpoint}${latitude}+${longitude}`;
  }

  formatPhone(phone: string): string {
    return this._phonePatternPipe?.transform(phone.toString());
  }

  preventNavigation(latitude: string, event: Event) {
    if (!latitude) {
      event.preventDefault();
    }
  }

  getWorkingHour(hoop: IOperationHour): string {
    return hoop.days + ': ' + hoop.opens + ' to ' + hoop.closes + ' ' + hoop.timezone;
  }
}
