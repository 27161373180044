import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ITranslation } from '../../../../fad/search-providers/interfaces/iAdaListResp';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IFtsSuggestion } from '../../../common/interfaces/iFtsClientInteraction';
import { FtsAvailableSuggestionService } from '../../../common/services/ftsAvailableSuggestionSvc';
import { FtsUtility } from '../../../common/utilities/ftsUtil';
import { FtsSuggestionSelectService } from '../../services/ftsSuggestionSelectSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-fts-similarSearches-cmp',
  templateUrl: './ftsSimilarSearches.html',
  providers: []
})
export class FtsSimilarSearchesComponent extends BaseComponent implements OnInit, OnDestroy {
  otherSuggestions: IFtsSuggestion<ITranslation>[];
  otherSuggestionsDescriptions: string[];
  sidePanelRef: ModalRef<HTMLElement>;
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Input()
  suggestions: IFtsSuggestion<ITranslation>[];
  @Output()
  onSuggestionClick: EventEmitter<IFtsSuggestion<ITranslation>> = new EventEmitter<IFtsSuggestion<ITranslation>>();

  private suggestionSubscription: Subscription;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _availableSuggestionSvc: FtsAvailableSuggestionService,
    private _suggestionSelectService: FtsSuggestionSelectService
  ) {
    super(_route, _eventHandler, _appSession);
    this.suggestionSubscription = this._availableSuggestionSvc.getSuggestions.subscribe((data: IFtsSuggestion<ITranslation>[]) => {
      if (data) {
        this.suggestions = data;
        this.otherSuggestions = [];
        this.otherSuggestionsDescriptions = [];
        if (this.suggestions.length > 1) {
          const otherSuggestionsList = this.suggestions.slice(1, 6);
          for (let suggestion of otherSuggestionsList) {
            if (FtsUtility.hasSpecialty(suggestion) || FtsUtility.hasProcedure(suggestion) || FtsUtility.hasMedicalCode(suggestion)) {
              this.otherSuggestions.push(suggestion);
              this.otherSuggestionsDescriptions.push(FtsUtility.displaySearchCriteria(suggestion, this.content.common.freeTextSearchResults));
            }
          }
        }
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy(): void {
    this.suggestionSubscription?.unsubscribe();
  }

  onClose() {
    this.closeModal.emit();
    this.suggestionSubscription?.unsubscribe();
  }

  getSuggestionDescription(index): string {
    const description = this.otherSuggestionsDescriptions[index];
    const descriptionMaxLength = this._appSession.appConfig?.features.freeTextSearch.descriptionMaxLength;
    return description.length > descriptionMaxLength ? `${description.slice(0, descriptionMaxLength)}...` : description;
  }

  onSuggestionSelect(suggestion: IFtsSuggestion<ITranslation>) {
    this.onClose();
    this._suggestionSelectService.setSelectedSuggestion(suggestion);
  }
}
