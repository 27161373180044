import { Injectable } from '@angular/core';
import { SOURCE_SYSTEM } from '../../../../app/common/constants/app-constants';
import { REASON_CODES } from '../constants/otherReasonCodes';
import { InvalidSourceSystemError } from '../errors/pcpError';
import { IReason, IReasonCodesRequest, IReasonCodesResponse } from '../models/iReasonCodeList';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { ACTIVE_COVERAGE, BOTH_COVERAGES, FUTURE_COVERAGE } from './../../../common/interfaces/iPcpRequest';
import { ApiHelper } from './../../../common/services/apiHelper';
import { DataHelper } from './../../../common/services/dataHelper';
import { PCPService } from './../../../common/services/pcpSvc';

@Injectable()
export class PcpReasonCodesApiHelper extends ApiHelper<IReasonCodesRequest, IReasonCodesResponse> {
  private _reasonCodesResponse: IReasonCodesResponse;
  constructor(
    private _pcpSvc: PCPService,
    private _dataHlpr: DataHelper
  ) {
    super();
  }

  execute(request: IReasonCodesRequest): Promise<IReasonCodesResponse> {
    return this._pcpSvc.getPcpReasonCode(request);
  }

  onSuccess(resp: IReasonCodesResponse): void {
    this.validateResp(resp);
    this._reasonCodesResponse = resp;
  }

  /**
   * This method throws error when the ReasonCodeAPI response is not a valid one
   * @param resp
   */
  validateResp(resp: IReasonCodesResponse): void {
    let excpn: IException = { code: 'invalidData', message: 'Invalid reasonCodes response' };
    if (!resp || !Array.isArray(resp.contracts) || resp.contracts.length === 0) {
      throw excpn;
    }

    resp.contracts.forEach((contract) => {
      if (!contract || !Array.isArray(contract.reasons) || contract.reasons.length === 0) {
        throw excpn;
      }
    });
  }

  onFailure(errResp): void {
    this._reasonCodesResponse = null;
  }

  getReasonsByStatus(contractStatus: string): IReason[] {
    const contract = this._reasonCodesResponse?.contracts?.find((c) => {
      return this._dataHlpr.areEqualStrings(c.contractStatus, contractStatus);
    });

    const reasons = contract?.reasons ?? [];
    return reasons;
  }

  getReasonsByContractUid(contractUid: string): IReason[] {
    const contract = this._reasonCodesResponse?.contracts?.find((c) => {
      return this._dataHlpr.areEqualStrings(c.contractUid, contractUid);
    });

    const reasons = contract?.reasons ?? [];
    return reasons;
  }

  /**
   * Get default reason to display in UI based on contract's status code.
   * For source systems other than WGS, STAR/ISG, MEDISYS - throw error
   * @param statusCd
   * @returns IReason
   */
  getDefaultReason(statusCd: string): IReason {
    let reason: IReason;
    if (this._dataHlpr.areEqualStrings(statusCd, BOTH_COVERAGES)) {
      const activeReason = this._getDefaultReason(ACTIVE_COVERAGE);
      const futureReason = this._getDefaultReason(FUTURE_COVERAGE);
      reason = {
        value: activeReason.value,
        translatedCode: futureReason.value,
        label: activeReason.label
      };
    } else {
      reason = this._getDefaultReason(statusCd);
    }
    return reason;
  }

  /**
   * Return a reason object for the given contract's status code.
   * This method will throw error if you try to get a reason for contract 'B'
   * @param statusCd string. Supported values are 'A and 'F'. 'B' is not supported.
   * @returns IReason
   */
  private _getDefaultReason(statusCd: string): IReason {
    let message = `Can not decide source system for contract status code '${statusCd}'.`;
    if (this._dataHlpr.areEqualStrings(statusCd, ACTIVE_COVERAGE) || this._dataHlpr.areEqualStrings(statusCd, FUTURE_COVERAGE)) {
      let defaultReasonCd = '';
      const contract = this._reasonCodesResponse?.contracts?.find((c) => {
        return this._dataHlpr.areEqualStrings(c.contractStatus, statusCd);
      });
      if (!contract) {
        throw new InvalidSourceSystemError(`No reason codes found for the contract-'${statusCd}' in api response.`);
      }
      const sourceSystem = contract.sourceSystem;
      //When Source system is WGS or STAR or ISG
      const isWgs = [SOURCE_SYSTEM.WGS, SOURCE_SYSTEM.STAR].some((srcSys) => {
        return this._dataHlpr.areEqualStrings(srcSys, sourceSystem);
      });
      if (isWgs) {
        defaultReasonCd = REASON_CODES.RC_30;
      }
      //When Source system is MEDISYS
      const isMedisys = [SOURCE_SYSTEM.MEDISYS, SOURCE_SYSTEM.MWMEDSYS].some((srcSys) => {
        return this._dataHlpr.areEqualStrings(srcSys, sourceSystem);
      });
      if (isMedisys) {
        defaultReasonCd = REASON_CODES.RC_MBRQ;
      }

      if (isWgs || isMedisys) {
        const reason = contract.reasons?.find((c) => {
          return this._dataHlpr.areEqualStrings(c.value, defaultReasonCd);
        });
        if (reason) {
          return reason;
        }
        message = `Can not find default reason '${defaultReasonCd}' for contract '${contract.contractStatus}'.`;
      } else {
        message = `Contract-'${contract.contractStatus}' source system '${contract.sourceSystem}' is not supported.`;
      }
    }
    //In all other cases
    throw new InvalidSourceSystemError(message);
  }
}
