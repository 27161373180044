import { TitleCasePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { InfoListService } from '../../../common/components/info-list/services/infoListSvc';
import { AppNavigations } from '../../../common/constants/app-navigations';
import { EventHandler } from '../../../common/services/eventHandler';
import { NavigationService } from '../../../common/services/navigationService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { RulesService } from '../../../fad/rules/services/RulesSvc';
import { ProviderUtilityService } from '../../../fad/search-providers/services/providerUtilitySvc';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { IAppContract } from '../../common/interfaces/iAppContract';
import { IContract } from '../../common/interfaces/iContract';
import { IMember } from '../../common/interfaces/iMember';
import { BootstrapService } from '../../common/store/bootstrapSvc';
import { CARE_TEAM_TYPE } from '../constants/careCircleConstants';
import { IAdvocateDetails, ICareCircle } from '../interfaces/iCareCircle';
import { ICareProvider, careCircleDetails } from '../interfaces/iCareProvider';
import { CareCircleService } from '../services/careCircleSvc';
import { MemberPCPHelper } from '../services/memberPCPHelper';
import { CareCircleUtil } from '../utilities/careCircleUtil';

@Component({
  moduleId: module.id,
  selector: 'app-fc-care-circle-cmp',
  templateUrl: './careCircleCmp.html',
  providers: []
})
export class CareCircleComponent extends BaseComponent implements OnInit, OnDestroy {
  careCircleContent = this.content?.careCircle;
  careCircleInfo = this.careCircleContent?.careProvidersComponent?.labels;
  searchCriteriaComponent = this.content?.result?.summaryComponent?.searchCriteriaComponent;
  commonContent = this.content?.common?.pageHeader?.searchCriteria;
  searchHeaderContent = this.content?.common?.searchHeader;
  private _contractSubscription: Subscription;
  careProviders: Array<ICareProvider> | Array<IAdvocateDetails> = [];
  selectedMbr: IMember;
  isEmulation: boolean = false;
  showLoader: boolean = true;
  noResults: boolean = false;
  showBackToLink: boolean = true;
  noPrint: boolean = false;
  careCircleInfoObj: careCircleDetails = {
    hasPCPFeature: false,
    isPcpEligible: false,
    hasPCP: false,
    pcpName: '',
    memberName: ''
  };

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _bootstrapService: BootstrapService,
    private _infoListSvc: InfoListService,
    private _appUtility: AppUtility,
    private _careCircleSvc: CareCircleService,
    private _memberPCPHelper: MemberPCPHelper,
    private _providerUtilityService: ProviderUtilityService,
    private _titlecasePipe: TitleCasePipe,
    private _ruleService: RulesService,
    private _navigationService: NavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    } else {
      this.showBackToLink =
        !this._appSession.isFirstTimeLoad &&
        (this._navigationService.previousRoute === AppNavigations.SEARCH_PROVIDERS_PATH ||
          this._navigationService.previousRoute === AppNavigations.SEARCH_RESULTS_PATH ||
          this._navigationService.previousRoute === AppNavigations.PROVIDER_DETAILS_PATH ||
          this._navigationService.previousRoute === AppNavigations.ASSIGN_PCP_PATH ||
          this._navigationService.previousRoute === AppNavigations.FCR_LANDING_PATH);
      this.onPreLoad();
    }
    if (this._appSession?.appState?.opsIndicator?.hasCastlightTransparency) {
      this.showBackToLink = false;
    }
    this._contractSubscription = this._bootstrapService.contract.subscribe((contract: IAppContract) => {
      this.initCalls();
    });
  }

  initCalls() {
    this._infoListSvc.reset();
    this.careProviders = [];
    this.careCircleInfoObj.memberName = this.careCircleInfo?.myProviders;
    this.selectedMbr = null;
    this.isEmulation = this._appSession.isEmulation;
    const appState = this._appSession.appState;
    const selectedEligibilityProduct = appState?.selectedEligibilityProduct;
    const members = selectedEligibilityProduct?.members;
    const selectedContract = selectedEligibilityProduct?.selectedContract;

    this.bindMemberLocation(selectedContract);

    if (members && selectedContract) {
      const _member = members.find((x: IMember) => x.mbrUid === selectedContract.mbrUid);

      if (_member) {
        this.selectedMbr = _member;
        const _memberName = this.titleCase(_member.firstNm);
        this.careCircleInfoObj.memberName = this.careCircleInfo?.careTeamFor?.replace(/{NAME}/gi, _memberName.trim());
      }
    }

    if (this.isSecureState) {
      this.getCareTeam();
    }
  }

  getCareTeam() {
    this.careProviders = [];
    this.showLoader = true;
    this._careCircleSvc.getCareCircle(CareCircleUtil.buildCareCircleRequest(this._appSession, CARE_TEAM_TYPE.SELECTED)).then(
      (result: ICareCircle) => {
        this.careCircleInfoObj.pcpName = '';
        this.careCircleInfoObj = {
          hasPCPFeature: false,
          hasPCP: false,
          isPcpEligible: this._appSession?.appState?.opsIndicator?.isPcpEligible
        };
        if (result && result.pcp && result.pcp.data && result.pcp.data.length > 0) {
          const pcpList = CareCircleUtil.bindCareProviders(result.pcp);
          this.careProviders = [...this.careProviders, ...pcpList];
          this.bindFutureEffectiveInfoLabel();
          this.careCircleInfoObj.pcpName = result.pcp.data[0].provider?.fullNm;
          this.careCircleInfoObj.hasPCP = true;
        }
        if (result?.pcp?.hasPCPFeature) {
          this.careCircleInfoObj.hasPCPFeature = true;
        }
        if (result && result.advocates && result.advocates.data && result.advocates.data.length > 0) {
          const advocateList = CareCircleUtil.bindAdvocates(result.advocates.data);
          this.careProviders = [...this.careProviders, ...advocateList];
        }
        if (result && result.managers && result.managers.data && result.managers.data.length > 0) {
          const managerList = CareCircleUtil.bindManagers(result.managers.data);
          this.careProviders = [...this.careProviders, ...managerList];
        }
        if (result && result.careTeam && result.careTeam.data && result.careTeam.data.length > 0) {
          result.careTeam.hasPCPFeature = result.pcp?.hasPCPFeature;
          result.careTeam.hasPCPRemoveFeature = result.pcp?.hasPCPRemoveFeature;
          const careTeamList = CareCircleUtil.bindCareProviders(result.careTeam);
          this.careProviders = [...this.careProviders, ...careTeamList];
        }
        if (this.careProviders.length > 0) {
          this.noResults = false;
        } else {
          this.noResults = true;
        }
        CareCircleUtil.showOnlinePharmacy(this.careProviders);
        this._memberPCPHelper.updatePcpProviderSession(result);
        this.showLoader = false;
      },
      (error: any) => {
        this.showLoader = false;
        this.noResults = true;
        this.onCategoryError('CARETEAM', error);
      }
    );
  }

  onCategoryError(type: string, error: any) {
    throw error;
  }

  bindMemberLocation(contract: IContract) {
    const memberAddress = contract?.addresses?.length > 0 ? contract.addresses[0] : null;
    if (!memberAddress) {
      return;
    }

    this._providerUtilityService.getGeoLocation(memberAddress.postalCd).then((response) => {
      const location = response?.length > 0 ? response[0] : null;
      if (location) {
        this._appSession.searchParams.coordinates = {
          latitude: location['latitude'],
          longitude: location['longitude']
        };
      }
    });
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  bindFutureEffectiveInfoLabel() {
    const _memberName = `${this.titleCase(this.selectedMbr?.firstNm)} ${this.titleCase(this.selectedMbr?.lastNm)}`;

    this.careProviders = (this.careProviders as any[]).map((provider) => {
      if (provider?.isPCP && provider?.isFuturePCP) {
        let futureEffectiveDateTooltip = this.careCircleInfo?.pcpProviderToolTip || '';

        futureEffectiveDateTooltip = futureEffectiveDateTooltip.replace(/{MEMBER NAME}/gi, _memberName || '');
        futureEffectiveDateTooltip = futureEffectiveDateTooltip.replace(/{DOCTOR NAME}/gi, provider.fullNm || '');
        futureEffectiveDateTooltip = futureEffectiveDateTooltip.replace(/{EFFECTIVE DATE}/gi, provider.mbrPcpEffectiveDt || '');

        provider.futureEffectiveDateTooltip = futureEffectiveDateTooltip;
      }
      return provider;
    });
  }

  refreshCareteam() {
    this.getCareTeam();
  }

  onPrintPage(printStatus: boolean) {
    if (printStatus) {
      window.print();
    }
  }

  ngOnDestroy(): void {
    this._contractSubscription?.unsubscribe();
  }
}
