import { Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { FindCareWebIntegrationClient, Locale } from '../../../../common/constants/app-constants';
import { SEARCH_SCENARIOS } from '../../../../common/constants/strategy-search-constant';
import { ICostInfo } from '../../../../common/interfaces/iSearchStrategyResponse';
import { DataHelper } from '../../../../common/services/dataHelper';
import { EventHandler } from '../../../../common/services/eventHandler';
import { SearchSvc } from '../../../../common/services/searchSvc';
import { AppSession } from '../../../../common/values/appSession';
import { PHARMACY_TYPE, PROVIDER_CATEGORY_CODE } from '../../../../fad/search-providers/values/providerSearchConstants';
import { IAffliation } from '../../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { PROV_NETWORK_STATUS, PROVIDER_TYPE, ProviderFlag } from '../../../common/constants/common';
import { RESULT_CONTENT_EN } from '../../../common/contents/result/resultContent';
import { RESULT_CONTENT_ES } from '../../../common/contents/result/resultContent.es';
import { CommonUtility } from '../../../common/utilities/commonUtil';
import { IProvider } from '../../interfaces/iProvider';
import { CompareProvider } from '../../services/compareProvider';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { NETWORK_STATUS } from './../../../common/constants/common';
import { IOption } from './../../../common/interfaces/iOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-card-cmp',
  templateUrl: './providerCardCmp.html'
})
export class ProviderCardComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() provider: IProvider;
  @Input() index: number;
  @Input() isActive!: boolean;
  @Input() mapVisible = true;
  @Output() cardSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  hasCostInfo = false;
  comapreProvider: IOption;
  comapreSelectedProviders: IProvider[] = [];
  summaryContent: any;
  provNetworkStatus: string;
  isProviderAssignedAsFuturePCP = false;

  private compareProviderSubscription: Subscription;
  providerMap = new Map<number, IProvider>();
  @Input() providerRemoved: IProvider = null;
  @Input() mapvisible: boolean = false;
  @Input() showProviderCard: boolean = false;
  @Input() clearAll: boolean = false;
  @Output() showProviderCompare: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedPairedProvider = new EventEmitter<IAffliation>();
  resultContent: any;
  hoursToday: string;
  isSecuredPharmacy: boolean;
  spfiDisclaimerHoverText: string = '';
  topChoiceTooltip: string;
  moreOptionsTooltip: string;
  statusOverlayIconClass: any = {};

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _compareProvider: CompareProvider,
    private _dataHlpr: DataHelper,
    private searchService: SearchSvc
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Initializes the component and subscribes to necessary observables.
   */
  ngOnInit(): void {
    this.resultContent = this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES : RESULT_CONTENT_EN;
    this.summaryContent = this._appSession.metaData.locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent : RESULT_CONTENT_EN.summaryComponent;
    if (this.provider?.costInfo) {
      this.hasCostInfo = true;
    }
    this.getPersonalizedMatchPdfLink();
    this.comapreProvider = this.getOptionObject(this.provider, this.index);
    this.compareProviderSubscription = this._compareProvider.compareProvider.subscribe((data) => {
      this.comapreSelectedProviders = data || [];
    });
    this.provNetworkStatus = this.provider?.providerNetworkStatus?.statusCode;
    this.isProviderAssignedAsFuturePCP = this.isFuturePCP();
    this.hoursToday = this.provider?.location?.address?.open24hours
      ? this.summaryContent.open24Hours
      : this.provider?.location?.address?.officeHours?.length
        ? this.provider?.location?.address?.officeHours.toLocaleString()
        : '';
    this.isSecuredPharmacy = this.provider?.providerTypeCodeList?.includes(PROVIDER_TYPE.PHARMACY) && this.isSecureState;
  }

  getEycCostInfo(provider: IProvider): ICostInfo {
    let eycCostInfo = {} as ICostInfo;
    if (provider?.eycAffliation?.affiliationList?.length) {
      const affFacility = { ...provider.eycAffliation.affiliationList[0] };
      if (affFacility?.eycCostInfo) {
        eycCostInfo = affFacility.eycCostInfo;
      }
    } else if (provider?.eycCostInfo) {
      eycCostInfo = provider.eycCostInfo;
    }
    return eycCostInfo;
  }

  providerCardContainerClasses(): object {
    return {
      'provider-card-container fcr-container-spacing-left': true,
      'is-active': this.isActive,
      'fcr-container-spacing-right': !this.mapVisible && !this.showProviderCard,
      [this.getProviderClass()]: true
    };
  }

  getProviderClass(): string {
    if (
      ([PROV_NETWORK_STATUS.INNETWORK, NETWORK_STATUS.INNETWORK].includes(this.provNetworkStatus) && !this.showVirtualOption() && !this.onScreenResize()) ||
      this.searchService.currentScenario === SEARCH_SCENARIOS.SECURE_V1_RX_SPECIALTY
    ) {
      return 'in-network';
    } else if ([PROV_NETWORK_STATUS.INN_ASOF, NETWORK_STATUS.ASOF].includes(this.provNetworkStatus) && !this.showVirtualOption() && !this.onScreenResize()) {
      return 'in-network-termed';
    } else if ([PROV_NETWORK_STATUS.INN_UNTIL, NETWORK_STATUS.UNTIL].includes(this.provNetworkStatus)) {
      return 'in-network-until';
    } else if ([PROV_NETWORK_STATUS.OUTNETWORK, PROV_NETWORK_STATUS.NOT_INNTWK_ASOF, NETWORK_STATUS.OUTNETWORK, NETWORK_STATUS.NOT_INNTWK_ASOF].includes(this.provNetworkStatus)) {
      return 'out-of-network';
    } else if (this.showVirtualOption() && this.provNetworkStatus !== (PROV_NETWORK_STATUS.OUTNETWORK || NETWORK_STATUS.OUTNETWORK)) {
      return 'virtual-provider';
    }
    return '';
  }

  ngOnDestroy(): void {
    this.compareProviderSubscription?.unsubscribe();
  }

  getNetworkStatus(resultView?: boolean): string {
    const status = this.provider?.providerNetworkStatus?.statusCode;
    this.statusOverlayIconClass = {
      'in-network': [PROV_NETWORK_STATUS.INNETWORK, NETWORK_STATUS.INNETWORK].includes(status),
      'not-in-network': [PROV_NETWORK_STATUS.OUTNETWORK, PROV_NETWORK_STATUS.NOT_INNTWK_ASOF, NETWORK_STATUS.OUTNETWORK, NETWORK_STATUS.NOT_INNTWK_ASOF].includes(status),
      'in-network-termed': [PROV_NETWORK_STATUS.INN_ASOF, NETWORK_STATUS.ASOF].includes(status),
      'in-network-until': [PROV_NETWORK_STATUS.INN_UNTIL, NETWORK_STATUS.UNTIL].includes(status)
    };
    const networktext = CommonUtility.getProviderNetworkStatus(this.provider?.providerNetworkStatus, this._appSession, resultView);
    return networktext ? networktext : '';
  }

  onSendCardStatus(status: boolean): void {
    this.cardSelected.emit(status);
  }

  onCompare(providerObj: IProvider, index: number): void {
    if (this.providerMap.has(index)) {
      this.providerMap.delete(index);
      this._compareProvider.remove(providerObj);
      this.showProviderCompare.emit(false);
    } else {
      this.providerMap.set(index, providerObj);
      this._compareProvider.add(providerObj);
      this.showProviderCompare.emit(true);
    }
  }

  setCompareSelection(providerObj: IProvider, index: number): string {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}-${index}`;
      if (this.providerMap.has(index)) {
        return value;
      } else {
        return '';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['providerRemoved'] && this.providerRemoved) {
      const providerToRemove = this.providerRemoved;
      this.providerMap.forEach((provider, index) => {
        if (provider.providerIdentifier === providerToRemove.providerIdentifier) {
          this.providerMap.delete(index);
        }
      });
    }
    if (changes['clearAll']) {
      this.providerMap.clear();
    }
  }

  disableSelection(providerIdentifier: string): boolean {
    const provider = this.comapreSelectedProviders.find((option) => option.providerIdentifier === providerIdentifier);
    const isMobile = this.onScreenResize();
    const maxProviders = isMobile ? 2 : 3;
    return (this.comapreSelectedProviders.length >= maxProviders && !provider) || this.providerGreyedOut;
  }

  /***
   *  get the FutureEffectiveProvider value from feature flag config
   * if true, we will enable the details page for future effective provider
   */
  get isFutureEffectiveProviderEnabled(): boolean {
    // TODO: Obsolete feature implementation. Evaluate and remove if unnecessary.
    return true;
  }

  get providerGreyedOut(): boolean {
    return this.isGreyedOut(this.provider, true);
  }

  private isGreyedOut(provider: IProvider, enableFutureProvider: boolean = true): boolean {
    // Feature flag to disable future effective provider navigation to details view.
    if (provider?.pharmacyType === PHARMACY_TYPE.AHD && provider?.isShipDirectToDoor) {
      return true;
    }

    if (!this.isFutureEffectiveProviderEnabled) {
      enableFutureProvider = false;
    }
    return !!(provider?.eycCostInfo?.isGreyedout || (!enableFutureProvider && provider?.isFutureEffective));
  }

  private getOptionObject(providerObj: IProvider, index: number): IOption {
    if (providerObj) {
      const value = `${providerObj.providerIdentifier}-${index}`;
      return {
        value: value,
        id: `input-${value}`,
        name: providerObj.providerName,
        trueValue: value,
        falseValue: ''
      } as IOption;
    }
  }

  // check for virtual provider
  showVirtualOption() {
    return this.provider?.providerFlags?.includes(ProviderFlag.VIRTUAL_PROVIDER);
  }

  showOnlinePharmacy() {
    return this.provider?.providerTypeCodeList?.includes(PROVIDER_TYPE.PHARMACY) && this.provider?.isShipDirectToDoor && !this.provider?.location?.address?.addressOne; // && !this.mapVisible;
  }

  showOnlineProviders() {
    return this.provider?.providerFlags?.includes(ProviderFlag.ONLINE_PROVIDER);
  }

  showOutOfNetwork() {
    return [PROV_NETWORK_STATUS.OUTNETWORK, PROV_NETWORK_STATUS.NOT_INNTWK_ASOF, NETWORK_STATUS.OUTNETWORK, NETWORK_STATUS.NOT_INNTWK_ASOF].includes(this.provNetworkStatus);
  }

  showAssignedAsPcp() {
    if (CommonUtil.isMemberSecure(this._appSession) && this._appSession?.pcpData && this._appSession?.metaData?.appContract?.mbrUid) {
      const provAndAddressId = this.provider?.providerIdentifier + this.provider?.location?.address?.addressId;
      return provAndAddressId === this._appSession?.pcpData[this._appSession?.metaData?.appContract?.mbrUid]?.currentPCP;
    }
  }

  /**
   * Method to check if the provider is assigned as future PCP
   * @returns boolean
   */
  isFuturePCP() {
    if (CommonUtil.isMemberSecure(this._appSession) && this._appSession?.pcpData && this._appSession?.appState?.selectedEligibilityProduct?.selectedContract) {
      const activeContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract;
      const provAndAddressId = this.provider?.providerIdentifier + this.provider?.location?.address?.addressId;
      return this._appSession.pcpData && this._appSession.pcpData[activeContract?.mbrUid]?.futurePCP === provAndAddressId;
    } else {
      return false;
    }
  }

  getPersonalizedMatchPdfLink() {
    let pdfUrl: string;
    let baseUrl: string;
    if (this.client === FindCareWebIntegrationClient.SYDNEYMEDICAID) {
      const sydMedicaidState = `explanation_of_personalization_medicaid_${this._appSession?.searchParams?.stateCd?.code}`;
      baseUrl = this._dataHlpr.getValueByKey<string>(sydMedicaidState, this.content?.result?.summaryComponent?.personalizedMatch?.personalizedMatchPdf?.medicaid);
      pdfUrl = CommonUtil.personalizedMatchPdfLink(this._appSession, baseUrl);
      if (pdfUrl) {
        this.topChoiceTooltip = this.content?.result?.summaryComponent?.topChoiceTooltipSydMedicaidLearnMore.replace(/{pdfUrl}/gi, pdfUrl);
        this.moreOptionsTooltip = this.content?.result?.summaryComponent?.moreOptionsTooltipSydMedicaidLearnMore.replace(/{pdfUrl}/gi, pdfUrl);
      } else {
        this.topChoiceTooltip = this.content?.result.summaryComponent?.topChoiceTooltipSydMedicaid.replace(/{pdfUrl}/gi, pdfUrl);
        this.moreOptionsTooltip = this.content?.result.summaryComponent?.moreOptionsTooltipSydMedicaid.replace(/{pdfUrl}/gi, pdfUrl);
      }
    } else {
      baseUrl = this._dataHlpr.getValueByKey<string>(
        this._appSession.mbu,
        this.content?.result?.summaryComponent?.personalizedMatch?.personalizedMatchPdf,
        this.content?.result?.summaryComponent?.personalizedMatch?.personalizedMatchPdf?.commercial
      );
      pdfUrl = CommonUtil.personalizedMatchPdfLink(this._appSession, baseUrl);

      this.topChoiceTooltip = this.content?.result.summaryComponent?.topChoiceTooltip.replace(/{pdfUrl}/gi, pdfUrl);
      this.moreOptionsTooltip = this.content?.result.summaryComponent?.moreOptionsTooltip.replace(/{pdfUrl}/gi, pdfUrl);
    }
    this.spfiDisclaimerHoverText = this.content?.result?.summaryComponent?.spfiDisclaimerHoverText.replace(/{pdfUrl}/gi, pdfUrl);
  }

  showAffiliation(provider: IProvider) {
    return (
      this.isEYC &&
      provider &&
      provider.eycAffliation &&
      Array.isArray(provider.eycAffliation.affiliationList) &&
      provider.eycAffliation.affiliationList.length > 0 &&
      provider.eycAffliation.isVisibleAffiliation
    );
  }

  get isEYC(): boolean {
    return this._appSession.isEyc;
  }

  getPairedProviderLabelAndSwapAddress(code: string): string {
    if (this.provider?.addressSummary) {
      const affliationAddress = this.provider?.eycAffliation?.affiliationList?.[0]?.location?.address;
      if (affliationAddress) {
        this.provider.addressSummary.addressOne = affliationAddress.addressOne;
        this.provider.addressSummary.addressTwo = affliationAddress.addressTwo;
        this.provider.addressSummary.distance = affliationAddress.distance;

        if (affliationAddress.phone) {
          this.provider.addressSummary.phoneNumberList = [affliationAddress.phone];
        }
      }
    }
    if (code) {
      return code === PROVIDER_CATEGORY_CODE.Individual
        ? this._appSession.metaData.locale === Locale.SPANISH
          ? RESULT_CONTENT_ES.summaryComponent.providingCareAt
          : RESULT_CONTENT_EN.summaryComponent.providingCareAt
        : this._appSession.metaData.locale === Locale.SPANISH
          ? RESULT_CONTENT_ES.summaryComponent.performedBy
          : RESULT_CONTENT_EN.summaryComponent.performedBy;
    }
    return '';
  }

  showPairedProviderDetails(event: Event) {
    event.stopPropagation();
    this.selectedPairedProvider.emit(this.provider.eycAffliation.affiliationList[0]);
  }

  showSpfiDisclaimer(data): boolean {
    return data && Object.keys(data).length > 0 && !!data.code;
  }

  getTitleList(titleList: string[]): string {
    return titleList.length > 2 ? titleList[0] + ', ' + titleList[1] + ', +' + (titleList.length - 2) : titleList.join(', ');
  }
}
