import { HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { DeeplinkUrl } from '../enums/deepLinkUrls';
import { AppEvents } from './../../common/enums/appEvents';
import { EventHandler } from './../../common/services/eventHandler';
import { IMetadata } from './../components/app-secure/models/iMetadata';
import { SecureStateHandler } from './../components/app-secure/services/secureStateHandler';
import { ANTHEM, AppConstants, BRAND_CODE, Locale } from './../constants/app-constants';
import { AppNavigations } from './../constants/app-navigations';
import { AppUtility } from './../utilities/appUtil';
import { RouteUtil } from './../utilities/routeUtil';
import { AppSession } from './../values/appSession';
import { DataHelper } from './dataHelper';
import { DeeplinkHandler } from './deeplinkHandler';
import { NavigationService } from './navigationService';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  private _headerEvent: EventEmitter<string> = this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]);

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _routeUtil: RouteUtil,
    private _navigationService: NavigationService,
    private _deeplinkHandler: DeeplinkHandler,
    private _appUtility: AppUtility,
    private _secureStateHandler: SecureStateHandler,
    private _dataHelper: DataHelper
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const templatePath: string = this._routeUtil.getResolvedUrl(route);
    this.setDeeplinkParams();
    this.setBrand(route.queryParams);
    this._appSession.isDeepLink = this.isDeepLink;
    this._deeplinkHandler.setFocus(this._appSession.deeplinkParams);

    if (!this._appSession.isIntegratedMode && !this._appSession.hasAppInitialized && !this._dataHelper.areEqualStrings(templatePath, AppNavigations.EMULATE_SECURE_STATE_PATH)) {
      // route standalone navigations through state handler for the first time
      const metadata = this.getDefaultMetadata();
      const deeplinkUrl = this.getDeeplinkUrl();
      if (this._dataHelper.areEqualStrings(templatePath, AppNavigations.PROVIDER_DETAILS_PATH)) {
        metadata.dplUrl = deeplinkUrl ? `${DeeplinkUrl.PROVIDER_DETAILS}?${deeplinkUrl}` : DeeplinkUrl.PROVIDER_DETAILS;
      } else {
        metadata.dplUrl = deeplinkUrl ? `${DeeplinkUrl.SEARCH_PROVIDERS}?${deeplinkUrl}` : DeeplinkUrl.SEARCH_PROVIDERS;
      }
      this._secureStateHandler.initializeSecureState(JSON.stringify(metadata));
      return false;
    }

    if (this.isAuthorized(templatePath)) {
      return true;
    }

    this._navigationService.navigateByUrl(AppNavigations.SEARCH_PROVIDERS_PATH, route.queryParams);

    return true;
  }

  isAuthorized(path: string): boolean {
    switch (path) {
      case AppNavigations.PROMOTED_PROCEDURES_PATH:
      case AppNavigations.CARE_TEAM_PATH:
      case AppNavigations.CARE_CIRCLE_PATH:
      case AppNavigations.FCR_CARE_CIRCLE_PATH:
      case AppNavigations.SEARCH_PROVIDERS_PATH:
      case AppNavigations.VIRTUAL_CARE_PATH:
      case AppNavigations.FCR_HOME_PATH:
      case AppNavigations.FCR_LANDING_PATH:
      case AppNavigations.SERVICE_AREA_VALIDATION_PATH:
      case AppNavigations.FCR_SAV_PATH:
      case AppNavigations.FCR_CARE_NOW:
      case AppNavigations.CARE_NOW: {
        //Skipped Care-circle path from the below condition as we need to reset the EYC flag for care-circle path because on search of eyc with BBB navigating to care circle we are loosing the eyc search parms due to that results page is not loading
        if (path !== AppNavigations.CARE_CIRCLE_PATH) {
          this._appSession.isEyc = false;
          if (this._appSession?.searchParams?.eycSearchParam) {
            this._appSession.searchParams.eycSearchParam = undefined;
          }
        }
        return true;
      }
      case AppNavigations.SEARCH_RESULTS_PRINT_PATH:
      case AppNavigations.SEARCH_RESULTS_PATH:
      case AppNavigations.SEARCH_RESULTS_NEXT:
      case AppNavigations.MAINTENANCE_PAGE_PATH:
      case AppNavigations.PROVIDER_DETAILS_PATH:
      case AppNavigations.FCR_RESULT_PATH: {
        if (this._appSession.searchParams) {
          return true;
        }
        break;
      }
      case AppNavigations.DENIAL_OF_SERVICE_PATH:
        if (this._appSession.dos) {
          return true;
        }
        break;
      case AppNavigations.ERROR_PATH: {
        return true;
      }
      case AppNavigations.COST_SEARCH_PATH: {
        this._appSession.isEyc = true;
        return true;
      }
      case AppNavigations.EMULATE_SECURE_STATE_PATH:
      case AppNavigations.EMULATE_COST_PATH: {
        if (this.isProduction === false) {
          this.emulation = true;
          return true;
        }
        break;
      }
      case AppNavigations.ASSIGN_PCP_PATH: {
        if (this._appSession.selectedProvider) {
          return true;
        }
        break;
      }
      default:
        break;
    }
    return false;
  }

  setDeeplinkParams() {
    let url = window.location.href;
    if (url.includes('?')) {
      url = decodeURIComponent(url.replace(/\+/g, '%20'));
      this._appSession.deeplinkParams = this._deeplinkHandler.findDeeplinkParams(url);
    }
  }

  get isProduction(): boolean {
    return this._appSession.appConfig?.production;
  }

  set emulation(value) {
    this._appSession.isEmulation = value;
  }

  get isDeepLink(): boolean {
    if (this._appSession.deeplinkParams.culture && this._appSession.deeplinkParams.culture.toLocaleLowerCase() === 'mx') {
      this.setCulture();
      this._appSession.deeplinkParams.culture = undefined;
    }

    return this._deeplinkHandler.isDeepLink();
  }

  setBrand(queryParams) {
    // Brand value setting from domain
    let _brandCd = this._appUtility.getBrandFromUrl();
    if (this.getValueFromQueryString('brand', queryParams) && this.getValueFromQueryString('brand', queryParams).trim() !== '') {
      _brandCd = this.getValueFromQueryString('brand', queryParams).trim().toLocaleUpperCase();
    } else if (this._appSession.isIntegratedMode || this._appSession.isSecureState || this._appSession.hasAppInitialized) {
      _brandCd = this._appSession.metaData.brandCd.toLocaleUpperCase();
    }

    if (AppConstants.BRAND && AppConstants.BRAND.length > 0) {
      const index = AppConstants.BRAND.findIndex((x) => x.brand === _brandCd);
      if (index >= 0) {
        this._appSession.metaData.brandCd = AppConstants.BRAND[index].brand;
        this._appSession.metaData.brandName = AppConstants.BRAND[index].domainName;
      } else {
        this._appSession.metaData.brandCd = BRAND_CODE.ABCBS;
        this._appSession.metaData.brandName = ANTHEM;
      }
    }
    //below changes are for ABCNY brand for public search, only logo should come as ABCNY and rest of the things should be ABCBS
    if (_brandCd === BRAND_CODE.ABCNY && !this._appSession.isSecureState) {
      this._appSession.metaData.brandCd = BRAND_CODE.ABCBS;
      this._appSession.metaData.brandName = ANTHEM;
      this._appSession.metaData.integratedBrandCode = _brandCd;
    }

    const brand = this._appSession.metaData.integratedBrandCode || this._appSession.metaData.brandCd;
    this._headerEvent.emit(brand);
  }

  setCulture() {
    if (this._appSession.deeplinkParams.culture && this._appSession.deeplinkParams.culture.toLocaleLowerCase() === 'mx') {
      this._appSession.metaData.locale = Locale.SPANISH;
    } else {
      this._appSession.metaData.locale = Locale.ENGLISH;
    }
  }

  getValueFromQueryString(paramName, queryParams: any = null): string {
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }

    if (paramValue && paramValue !== '') {
      return paramValue;
    } else {
      return this.getValueFromRoute(paramName, queryParams);
    }
  }

  // Funtion to get query parameters from route
  getValueFromRoute(paramName, queryParams): string {
    let paramValue;
    if (queryParams && typeof queryParams !== 'undefined' && queryParams !== null) {
      paramValue = queryParams[paramName];
    }

    return paramValue;
  }

  private getDeeplinkUrl(): string {
    let deepLinkUrl: string;
    let url = window.location.href;
    if (url.includes('?')) {
      url = decodeURIComponent(url.replace(/\+/g, '%20'));
      deepLinkUrl = url.substring(url.indexOf('?') + 1, url.length);
    }

    return deepLinkUrl;
  }

  private getDefaultMetadata(): IMetadata {
    const metadata = {} as IMetadata;

    metadata.brand = this._appSession.metaData.brandCd;
    metadata.isCastlightTransparency = false;
    metadata.isEmulateFlag = false;
    metadata.isSecureState = false;
    metadata.isWebView = false;
    metadata.isChangePCP = false;

    return metadata;
  }
}
