import { Injectable } from '@angular/core';
import { ANP_ERROR_CODE } from '../../../common/constants/app-constants';
import { NOT_ACCEPTING_NEW_PATIENTS_CODES } from '../../common/constants/providerSearchConstants';
import { CA_HMO_DISCLAIMER, DEEPLINK_ACTION } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { ISelectedPcp } from './../../../common/interfaces/iSelectedPcp';
import { DataHelper } from './../../../common/services/dataHelper';
import { NavigationService } from './../../../common/services/navigationService';
import { AppSession } from './../../../common/values/appSession';
import { IPcpNavResponse } from './../models/iMemberPcpDetails';
import { MemberPcpDetailsApiHelper } from './memberPcpDetailsApiHlpr';
import { ProviderPcpInfoApiHelper } from './providerPcpInfoApiHlpr';

@Injectable({ providedIn: 'root' })
export class AssignPcpNavigationService {
  constructor(
    private _appSession: AppSession,
    private _navSvc: NavigationService,
    private _mbrPcpDtlsApi: MemberPcpDetailsApiHelper,
    private _provPcpInfoApiHlpr: ProviderPcpInfoApiHelper,
    private _dataHlpr: DataHelper
  ) {}

  /**
   * This method decides whether to navigate to new 'assign-pcp' page or to show the 'Assign PCP' side panel.
   * Thus decision is made based on the following
   *  1. FeatureFlag 'changePcpOnFutureCoverage' AND
   *  2. suppressAssignPcp flag received from MemberPcpDetail API
   * @param professional
   * @returns
   */
  async navigateToAssignPcp(professional: ISelectedPcp, skipCaHmoDisclaimer: boolean = false): Promise<IPcpNavResponse> {
    if (!professional) {
      return Promise.reject('Given Provider is not valid');
    }

    this._appSession.selectedProvider = professional;

    //Handle deeplink routes coming from Employer Portal
    const isPostPCPInfoDeepLink = this._appSession.isDeepLink && this._dataHlpr.areEqualStrings(this._appSession.deeplinkParams.action, DEEPLINK_ACTION.POST_PCP_INFO);

    if (!isPostPCPInfoDeepLink) {
      const dtlApiPromise = new Promise<IPcpNavResponse>((resovle, reject) => {
        (async () => {
          try {
            if (skipCaHmoDisclaimer) {
              // Continue to new Assign as PCP page
              this._navSvc.navigateByUrl(AppNavigations.ASSIGN_PCP_PATH);
              resovle({ navigatedToAssignPcpPage: true });
              return;
            }
            const pcpDtlsApiReq = {
              defaultContractUid: this._appSession.metaData?.appContract?.contractUid,
              mbrUid: this._appSession.metaData?.appContract?.mbrUid
            };
            // Priority 1: When suppressAssignPcp is true from mbrPcpDtlsApi response - Stop Assign PCP flow. (Ex: Two active contract at a time(active or future))
            // Invoke MemberPCP Detail API
            await this._mbrPcpDtlsApi.invoke(pcpDtlsApiReq);

            // Priority 2: When 'Not Accepting New Patients' - Stop Assign PCP flow.
            this.validateANP(professional);

            //Priority 3: Check for pcpMbrEffDtPastContractEndDt related errors
            this._mbrPcpDtlsApi.validateMbrPcpEffectiveDate();

            //Priority 4: Check for Member Age Range
            await this._provPcpInfoApiHlpr.invokeByProvider(professional);
            this._provPcpInfoApiHlpr.verifyMbrAge();

            // Priority 5: When CA HMO or POS disclaimer is true - Stop Assign PCP flow.
            if (this._appSession.feature?.showCACommercialPCPDisclaimer && !skipCaHmoDisclaimer) {
              const excptn: IException = {
                code: CA_HMO_DISCLAIMER,
                message: 'Display CA HMO Disclaimer panel'
              };
              throw excptn;
            }

            // Navigate to new Assign as PCP page
            this._navSvc.navigateByUrl(AppNavigations.ASSIGN_PCP_PATH);
            resovle({ navigatedToAssignPcpPage: true });
          } catch (error) {
            reject(error);
          }
        })();
      });
      return dtlApiPromise;
    }

    //Legacy Flow of Assign PCP
    try {
      // Stop Assign PCP flow : When 'Not Accepting New Patients'
      this.validateANP(professional);
      // Show existing Assign PCP side panel flow
      return Promise.resolve({ navigatedToAssignPcpPage: false });
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * This method throws an exception when the doctor is not accepting new patients.
   * Otherwise this method will not throw any errors.
   * Caller should catch and handle this exception.
   * @param professional
   */
  private validateANP(professional: ISelectedPcp) {
    const anpCd: string = professional.anp?.code ?? '';
    const hasAnpCd = NOT_ACCEPTING_NEW_PATIENTS_CODES.find((cd) => {
      return this._dataHlpr.areEqualStrings(cd, anpCd);
    });

    if (hasAnpCd && this._appSession.hasANPEligibleSourceSystem) {
      const excptn: IException = {
        code: ANP_ERROR_CODE,
        message: 'Not Accepting New Patitents'
      };
      throw excptn;
    }
  }
}
