import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { DataService } from '../common/services/dataService';
import { DeeplinkHandler } from '../common/services/deeplinkHandler';
import { EventHandler } from '../common/services/eventHandler';
import { AppSession } from '../common/values/appSession';
import { FindCareRoutingModule } from './app-routing/app-routing.module';
import { AssignPcpModule } from './assign-pcp/assignPcpModule';
import { CareCircleModule } from './care-circle/careCircleModule';
import { CareNowModule } from './careNow/careModule';
import { GlobalHeaderModule } from './common/components/shareable/global-header/globalHeaderModule';
import { PageAlertsModule } from './common/components/shareable/page-alert/pageAlertsModules';
import { UtilityService } from './common/services/utilitySvc';
import { HomeModule } from './home/homeModule';
import { LandingModule } from './landing/landingModule';
import { DisclaimersModule } from './result/components/disclaimers/disclaimersModule';
import { ResultModule } from './result/resultModule';

@NgModule({
  declarations: [],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    GlobalHeaderModule,
    UxSharedModule.forRoot(),
    FindCareRoutingModule,
    LandingModule,
    HomeModule,
    ResultModule,
    AssignPcpModule,
    DisclaimersModule,
    CareNowModule,
    CareCircleModule
  ],
  providers: [DataService, AppSession, EventHandler, UtilityService, DeeplinkHandler],
  exports: [HomeModule, LandingModule, ResultModule, AssignPcpModule, PageAlertsModule, CareNowModule, CareCircleModule]
})
export class FindCareModule {
  static forRoot(): ModuleWithProviders<FindCareModule> {
    return {
      ngModule: FindCareModule,
      providers: []
    };
  }
}
