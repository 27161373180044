import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSession } from '../../../common/values/appSession';
import { IGroupAffiliations } from '../../../fad/search-results/interfaces/iGroupAffiliations';
import { ISearchRequest } from '../../common/interfaces/iSearchRequest';
import { AffiliationService } from './affiliationSvc';

@Injectable({ providedIn: 'root' })
export class PrimeGroupFilter {
  private primeGroupFilter = new BehaviorSubject<IGroupAffiliations>(undefined);

  primeGroup: Observable<any> = this.primeGroupFilter.asObservable();

  constructor(
    public appSession: AppSession,
    private _affiliationSvc: AffiliationService
  ) {}

  async initialize(request: ISearchRequest): Promise<void> {
    this._affiliationSvc.getAffiliations(request).then((result: IGroupAffiliations) => {
      if (result?.affiliationList?.length > 0) {
        this.appSession.primeGroupAffiliations = result;
        this.primeGroupFilter.next(result);
      }
    });
  }
}
