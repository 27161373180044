import { DOCUMENT } from '@angular/common';
import { Directive, EventEmitter, HostListener, Inject, Input } from '@angular/core';
import { EventHandler } from '../../../services/eventHandler';
import { SSOErrorType } from '../enums/ssoErrorType';
import { AppEvents } from './../../../../common/enums/appEvents';
import { AppExternalCommands } from './../../../constants/app-constants';
import { IEventDetail } from './../../../interfaces/iEventDetail';
import { IWindow } from './../../../interfaces/iWindow';
import { AppSession } from './../../../values/appSession';
import { ISSOEError } from './../interfaces/iSsoError';
import { ISSOEvent } from './../interfaces/iSsoEvent';

/**
 *  Adds support to handle sso links on click event of used element
 *  data-sso-type attribute should be used to specify the type parameter in sso
 *  data-sso-sub-type attribute should be used to specify the sub type parameter in sso
 */

// tslint:disable-next-line:directive-selector
@Directive({ selector: '[data-fad-sso-link-dir], [data-fad-sso-link-v2-dir]' })
export class SsoLinkDirective {
  @Input()
  linkTarget: string;
  @Input()
  ssoType: string;
  @Input()
  ssoSubType: string;
  @Input()
  ssoErrorType: string;
  @Input()
  ssoErrorClass: string;
  @Input()
  ssoErrorRedirectUrl: string;
  @Input()
  ssoErrorPageHeader: string;
  @Input()
  errorMessage: string;
  @Input()
  uniqueId: string;
  @Input()
  contractUid: string;
  @Input()
  newWindow: boolean = false;
  @Input()
  disclaimerEnabled: boolean = false;
  @Input()
  disclaimerContent: string;
  @Input()
  acctKey: string;
  @Input()
  flexAcctKey: string;
  @Input()
  acctOptions: string;
  @Input()
  acctTargetId: string;
  @Input()
  pdtStrKey: string;
  @Input()
  baseURL: string;
  @Input()
  careType: string;
  @Input()
  ssoKey: string;

  private _ssoEvent: EventEmitter<ISSOEvent> = this._eventHandler.get(AppEvents[AppEvents.SSO]);
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    private _eventHandler: EventHandler,
    @Inject(DOCUMENT) private _doc: any,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (this._appSession.isEmulation === true) {
      this._window.open(this.baseURL + '/ssoEmulated.html', '_blank');
      event.preventDefault();
      event.stopPropagation(); // Instead of returning false we can stop the dom propagation using stopPropagation invocation
    } else if (this._appSession.isSecureState === true) {
      this._ssoEvent.emit({
        target: this.linkTarget || '_blank',
        type: this.ssoType,
        subType: this.ssoSubType,
        acctKey: this.acctKey || '',
        errorDefinition: this.getErrorDefinition(),
        contractUid: this.contractUid || '',
        newWindow: this.newWindow,
        disclaimerEnabled: this.disclaimerEnabled,
        disclaimerContent: this.disclaimerContent,
        acctOptions: this.acctOptions,
        acctTargetId: this.acctTargetId,
        flexAcctKey: this.flexAcctKey,
        pdtStrKey: this.pdtStrKey || '',
        careType: this.careType,
        ssoKey: this.ssoKey
      });
    } else {
      const eventDetail = {} as IEventDetail;

      eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
      eventDetail.message = AppExternalCommands.RCP_LOGIN.MSG;
      eventDetail.target = AppExternalCommands.RCP_LOGIN.CMD;

      this._pfAppCommandEvent.emit(eventDetail);
    }

    event.preventDefault();
    event.stopPropagation(); // Instead of returning false we can stop the dom propagation using stopPropagation invocation
  }

  private getErrorDefinition(): ISSOEError {
    const errDef: ISSOEError = {
      type: parseInt(this.ssoErrorType || '0', 2),
      errorMessage: this.errorMessage
    };

    if (errDef.type === SSOErrorType.OUTSIDE && this.ssoErrorClass) {
      errDef.errorElement = this._doc.querySelector('.' + this.ssoErrorClass + ' .media-body p');
    }

    if (errDef.type === SSOErrorType.INLINE && this.uniqueId) {
      errDef.errorElement = this._doc.querySelector('#errorId' + this.uniqueId);
    }

    if (errDef.type === SSOErrorType.REDIRECT && this.ssoErrorRedirectUrl) {
      errDef.redirectUrl = this.ssoErrorRedirectUrl;
      errDef.redirectPageHeader = this.ssoErrorPageHeader;
    }

    return errDef;
  }
}
