import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isEmpty } from 'lodash';

import { fromEvent, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppNavigations, PfNavigationEvent } from '../../common/constants/app-navigations';
import { NavigationService, OutletNavigationService, PathNavigationService } from '../../common/services/navigationService';
import { AppSession } from '../../common/values/appSession';

@Injectable({
  providedIn: 'root'
})
export class NavigationTrackingService implements OnDestroy {
  currentNavigationPath: string;
  pfRouterOutletBackSub: Subscription;
  popstateSub: Subscription;

  constructor(
    private router: Router,
    private navigationService: NavigationService,
    private _appSession: AppSession
  ) {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.currentNavigationPath = !event.urlAfterRedirects || event.urlAfterRedirects === '' ? this.navigationService.currentRoute?.toString() : event.urlAfterRedirects;
      this.clearNavigationStack(event);
    });
    /*
    This is to prvenet the back listener when alreday parent portal is enabled to do that browser navigation.
    This logic only has to apply for standalone and integrated portals that are not migrated to browser navigation feature.
    */
    if (!this._appSession.metaData?.isBrowserNavigationEnabled) {
      this.setUpEventListener();
    }
  }

  setUpEventListener() {
    // Create observables from the events
    this.pfRouterOutletBackSub = fromEvent<CustomEvent>(window, PfNavigationEvent.PF_ROUTER_OUTLET_BACK).subscribe((event) => {
      event.stopPropagation();
      event.preventDefault();
      (this.navigationService instanceof OutletNavigationService) ?  this.backNavigationListener(event) : window.history.back();
    });
    this.popstateSub = fromEvent<CustomEvent>(window, 'popstate').subscribe((event) => {
      event.stopPropagation();
      event.preventDefault();
      this.backNavigationListener(event);
    });
  }

  backNavigationListener(event: CustomEvent) {
    const overlayContainer = document.getElementsByClassName('overlay-container');
    const scrollClass = document.getElementsByClassName('ant-scroll-block');
    if (overlayContainer && overlayContainer.length && scrollClass && scrollClass.length) {
      scrollClass[0].classList.remove('ant-scroll-block');
      overlayContainer[0].innerHTML = '';
    }
    this._appSession.navigationStackCount = this._appSession.navigationStackCount - 1;
    this._appSession.navigationStack.pop();
    if (this._appSession.navigationStack.length > 0) {
      let urlToBeNavigated = this._appSession.navigationStack[this._appSession.navigationStack.length - 1];
      if (this.navigationService instanceof OutletNavigationService) {
        this.router.navigateByUrl(urlToBeNavigated.commands[0], urlToBeNavigated.extras);
      } else if (this.navigationService instanceof PathNavigationService) {
        this.router.navigate(urlToBeNavigated.commands, urlToBeNavigated.extras);
      }
    }
  }

  clearNavigationStack(event: NavigationEnd) {
    if (AppNavigations.SEARCH_PROVIDERS_PATH.includes(this.currentNavigationPath) || AppNavigations.SEARCH_PROVIDERS_OUTLET.includes(this.currentNavigationPath)) {
      if (this.navigationService.navigationStack?.length > 0) {
        this.navigationService.navigationStack.splice(1, this.navigationService.navigationStack.length - 1);
      }
      this._appSession.navigationStackCount = 1;
      this._appSession.navigationStack.splice(1, this._appSession.navigationStack.length - 1);
      if (this.navigationService instanceof PathNavigationService || !this._appSession.isIntegratedMode) {
        window.history.pushState(null, '', window.location.href);
      }
    }
    this._appSession.isGlobalBackBtnHidden = false;
  }

  ngOnDestroy() {
    this._appSession.navigationStackCount = 0;
    this._appSession.navigationStack = [];
    this.pfRouterOutletBackSub?.unsubscribe();
    this.popstateSub?.unsubscribe();
  }

  getNavigationHistory() {
    const SEARCH_PROVIDERS_PATH = 'search-providers';
    const isSearchProvidersPath = window.location?.href?.includes(SEARCH_PROVIDERS_PATH);
    const isBrowserUrlSearchProviders =
      AppNavigations.SEARCH_PROVIDERS_PATH.includes(this.currentNavigationPath) || AppNavigations.SEARCH_PROVIDERS_OUTLET.includes(this.currentNavigationPath) || isSearchProvidersPath;
    return !isEmpty(this.navigationService.previousRoute) && !isBrowserUrlSearchProviders;
  }
}
