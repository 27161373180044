import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IReportInvalidRequest } from '../interfaces/iReportInvaliData';

@Injectable({ providedIn: 'root' })
export class ReportInvalidDataService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  private _emailSent = new Subject<string>();

  get onEmailSent(): Observable<string> {
    return this._emailSent.asObservable();
  }

  public emailSentSuccessfully(message: string): void {
    this._emailSent.next(message);
  }

  public async reportInvalid(reportInvalidRequest: IReportInvalidRequest): Promise<boolean> {
    const apiUrl = apiNameList.restApi.reportIncorrectdataSubmit;
    const response = await this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: reportInvalidRequest,
      headers: [
        {
          name: 'Content-Type',
          value: 'application/json'
        }
      ]
    });

    return response?.status || false;
  }
}
