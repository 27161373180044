import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, Input, Output, Renderer2, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AlertService, SnackbarAlertContainerComponent } from '@anthem/uxd/alert';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { isNaN } from 'lodash';
import { SNACKBAR } from '../../../../common/constants/app-constants';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { IProviderNetworkStatus, ISpecialty } from '../../../../fad/provider-details/models/iDetailsResponse';
import { BoardCertificationCode } from '../../../../fad/provider-details/values/boardCertificationCodeConstants';
import { PROV_NETWORK_STATUS, SliderDirection } from '../../../common/constants/common';
import { FindCarePageType } from '../../../common/enums/findCarePages';
import { CommonUtility } from '../../../common/utilities/commonUtil';
import { ProviderCard, ProviderCardNavigation } from '../../constants/result';
import { IReportIncorrectDataResponse } from '../../interfaces/iIncorrectData';
import { IProvider, IReviewResponse } from '../../interfaces/iProvider';
import { IAffiliationProvider } from '../../interfaces/iProviderAffiliation';
import { IDetail } from '../../interfaces/iProviderDetail';
import { IReportInvalidRequest } from '../../interfaces/iReportInvaliData';
import { ReportInvalidDataService } from '../../services/reportInvalidDataSvc';
import { ProviderUtility } from '../../utilities/providerUtil';
import { BaseComponent } from './../../../common/components/core/baseCmp';
import { NETWORK_STATUS } from './../../../common/constants/common';
import { IncorrectDataComponent } from './incorrectData/incorrectDataCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-overview-cmp',
  templateUrl: './providerOverviewCmp.html'
})
export class ProviderOverviewComponent extends BaseComponent {
  page = FindCarePageType.DetailOverview;
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() specialtySelected: EventEmitter<ISpecialty> = new EventEmitter<ISpecialty>();
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  @Input() providerDetails: IDetail;
  @Input() provider: IProvider;
  overviewContent = this.content?.result?.providerOverview;
  summaryContent = this.content?.result?.summaryComponent;
  resultLabels = this.content?.result?.result?.labels;
  compareProvidersContent = this.content?.result?.compareProvidersComponent;
  showProviderLoader = true;
  @Input() affiliations: Map<string, IAffiliationProvider[]>;
  @Input() providerIncorrectInfo: IReportIncorrectDataResponse;
  isMapView: boolean;
  showGender: boolean = false;
  showEducation: boolean = false;
  showReadMore = false;
  isVirtualCareProvider: boolean = false;
  diamondTooltip: string;
  @ViewChild('viewRatingsSlider')
  ratingsSlider: TemplateRef<HTMLElement>;
  ratingsSliderRef: ModalRef<HTMLElement>;
  sliderDirection = SliderDirection;
  ratingsUrl: SafeResourceUrl;
  @Output() isMoreDetailsChange = new EventEmitter<boolean>();
  @Output() handleDisclaimersChange = new EventEmitter<string[]>();
  sidePanelRef: ModalRef<any, any>;
  @ViewChild('switchReportIncorrectData') openAddressSelection: TemplateRef<IncorrectDataComponent>;
  @ViewChild('snackbarModal') snackbarModal: SnackbarAlertContainerComponent;
  @ViewChild('contractChangeAlertTemplate', { static: false }) contractChangeAlertTemplate: TemplateRef<HTMLElement>;
  showErrorMsg = false;
  @ViewChild('disclaimerContainer', { static: false }) disclaimerContainer: ElementRef;
  reviewRatings: IReviewResponse;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _sanitizer: DomSanitizer,
    private _sidePanel: SidePanel,
    private _phonePatternPipe: PhonePatternPipe,
    private _reportInvaliDataService: ReportInvalidDataService,
    private _alertService: AlertService,
    private renderer: Renderer2
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['providerDetails']) {
      this.isVirtualCareProvider = ProviderUtility.isVirtualProvider(this.providerDetails?.providerFlags);
      this.showGender = this.providerDetails?.visibilityRule?.showGender;
      this.showEducation = this.providerDetails?.visibilityRule?.showEducation;
      this.reviewRatings = this.providerDetails?.reviewRatings?.[0];
    }
  }

  onDisclaimersChange(disclaimers: string[]) {
    this.handleDisclaimersChange.emit(disclaimers);
    if (disclaimers?.length > 0) {
      const container = document.getElementById('discalimer-container-div');
      const lineHeight = parseFloat(window.getComputedStyle(container).lineHeight);
      const containerHeight = parseFloat(window.getComputedStyle(container).height);
      const lineCount = Math.ceil(containerHeight / lineHeight);
      this.showReadMore = lineCount > 5;
    } else {
      this.showReadMore = false;
    }
  }

  isDetailPageAlert(): boolean {
    return this._appSession.pageAlerts[FindCarePageType.DetailOverview].length > 0;
  }

  /**
   * View PCP List Card Show Event.
   * Emits boolean value true for opening pcpList.
   */
  onViewPCPList() {
    this.cardSelected.emit(ProviderCard.PCP);
  }

  /**
   * View Speciality Card Show Event.
   * Emits boolean value true for opening speciality.
   */
  onShowSpeciality(speciality: ISpecialty) {
    const isMoreDetails = false;
    this.isMoreDetailsChange.emit(isMoreDetails);
    this.cardSelected.emit(ProviderCard.SPECIALITY);
    this.specialtySelected.emit(speciality);
  }

  /**
   * View Recognitions Card Show Event.
   * Emits boolean value true for opening recognitions.
   */
  onShowRecognitions() {
    this.cardSelected.emit(ProviderCard.RECOGNITION);
  }

  /**
   * View Affiliations Card Show Event.
   * Emits boolean value true for opening affiliations.
   */
  onShowAffiliations() {
    this.cardSelected.emit(ProviderCard.AFFILIATION);
  }

  onReadMoreDisclaimer() {
    this.cardSelected.emit(ProviderCard.DISCLAIMER);
  }

  /**
   * Open the side panel
   * @param direction - side panel direction
   */
  openSlider(direction: SliderDirection) {
    this.ratingsUrl = this.getReviewURL();
    this.ratingsSliderRef = this._sidePanel.open(direction, this.ratingsSlider);
  }

  /**
   * Close the side panel
   */
  closeSlider() {
    this.ratingsSliderRef.close();
  }

  getProviderStatusIconClass(networkStatusCode: string): string {
    if ([PROV_NETWORK_STATUS.INNETWORK, PROV_NETWORK_STATUS.INN_UNTIL, NETWORK_STATUS.INNETWORK, NETWORK_STATUS.UNTIL].includes(networkStatusCode)) {
      return 'motif-checked';
    } else if ([PROV_NETWORK_STATUS.INN_ASOF, NETWORK_STATUS.ASOF].includes(networkStatusCode)) {
      return 'motif-event-accepted';
    } else {
      return 'motif-cancel';
    }
  }

  getGenderAndEthnicity(): string {
    const gender = this.providerDetails?.gender?.toUpperCase();
    const ethnicity = this.providerDetails?.ethnicityCodes || [];
    const ethnicityString = ethnicity.length > 0 ? ethnicity.join(', ') : this.overviewContent.labels.noEthnicity;

    const genderText = CommonUtility.getGenderText(gender, this.content);

    if (this.providerDetails?.visibilityRule?.showGender && this.providerDetails?.visibilityRule?.showEthnicity && gender && ethnicity.length > 0) {
      return `${this.titleCase(genderText)}, ${ethnicityString}`;
    }
    if (this.providerDetails?.visibilityRule?.showGender && gender) {
      return this.providerDetails?.isOnlineProviderIndicator && !this.providerDetails?.visibilityRule?.showEthnicity
        ? `${this.titleCase(genderText)}`
        : `${this.titleCase(genderText)}, ${ethnicityString}`;
    }
    if (this.providerDetails?.visibilityRule?.showEthnicity && ethnicity.length > 0) {
      return this.providerDetails?.isOnlineProviderIndicator && !this.providerDetails?.visibilityRule?.showGender
        ? `${ethnicityString}`
        : `${ethnicityString}, ${this.overviewContent.labels.noGender}`;
    }
    return this.overviewContent.labels.noGenderOrEthnicity;
  }

  getNetworkStatusText(providerNetworkStatus: IProviderNetworkStatus): string {
    // Todo: changes expected for Details V2 api
    const networktext = CommonUtility.getProviderNetworkStatus(providerNetworkStatus, this._appSession);
    return networktext || '';
  }

  private getPlanName(plan: string): string {
    const planType = this.titleCase(plan);
    const labels = this.content.result.compareProvidersComponent?.labels;

    const planLabels: { [key: string]: string } = {
      Medical: labels?.medical,
      Dental: labels?.dental,
      Vision: labels?.vision
    };

    return this.titleCase(planLabels[planType] || planType);
  }

  checkNum(review: string): boolean {
    return !isNaN(Number(review));
  }

  titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  isDiamondProvider(): boolean {
    const admRecognition = this.providerDetails?.performance?.recognition?.find((pgm) => ProviderUtility.isDiamondProvider(pgm?.code));
    if (admRecognition) {
      this.diamondTooltip = admRecognition.hoverText;
      return true;
    }

    return false;
  }

  get tooltipContent(): string {
    if (this.providerDetails?.reviewRatings?.[0]?.toolTip) {
      return this.providerDetails.reviewRatings[0].toolTip.replace(/{DATE}/gi, this.providerDetails.reviewRatings[0].lastReviewTime);
    }
    return '';
  }

  getReviewURL(): string {
    let reviewUrl;
    const pdtStrKey = this.providerDetails?.reviewRatings?.[0]?.pdtStrKey;
    if (pdtStrKey) {
      reviewUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + pdtStrKey + '/en');
    }
    return reviewUrl;
  }

  getMapEndpoint(): string {
    const latitude = this.providerDetails?.address?.latitude?.trim();
    const longitude = this.providerDetails?.address?.longitude?.trim();
    return `${this.overviewContent?.mapEndpoint}${latitude}+${longitude}`;
  }

  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }

  preventNavigation(event: Event) {
    if (!this.providerDetails?.address?.latitude) {
      event.preventDefault();
    }
  }

  isReviewRating(rating: IReviewResponse, provCategoryCode: string) {
    return CommonUtility.isReviewRating(rating, provCategoryCode);
  }

  checkIndividualProvWithProvTypeP(provCategoryCode: string) {
    return CommonUtility.checkIndividualProvWithProvTypeP(provCategoryCode);
  }

  /** Functiton to get speciality text */
  getSpecialtyText(boardCertificationCode): string {
    let value = '';
    if (boardCertificationCode) {
      switch (boardCertificationCode.toUpperCase()) {
        case BoardCertificationCode.BOARD_CERTIFIED:
          value = this.compareProvidersContent?.labels?.boardCertifiedMsg;
          break;
        case BoardCertificationCode.NOT_BOARD_CERTIFIED:
          value = this.compareProvidersContent?.labels?.notBoardCertifiedMsg;
          break;
        case BoardCertificationCode.BOARD_CERTIFICATION_NOT_APPLICABLE:
          value = this.compareProvidersContent?.labels?.naBoardCertifiedMsg;
          break;
      }
    }
    return value;
  }

  getMessage() {
    if (this.providerIncorrectInfo?.emailList?.length > 0) {
      return this.content.result.incorrectDataComponent.labels.formOption;
    } else {
      return `${this.content.result.incorrectDataComponent.labels.phoneLabel} <a class="anchor-icon">${this.providerIncorrectInfo?.phoneNumber}</a>.`;
    }
  }

  openSwitchLocationModal() {
    this.sidePanelRef = this._sidePanel.open('right', this.openAddressSelection);
  }

  onClose() {
    this.sidePanelRef.close();
  }

  async onSubmit(form: any) {
    const request = this.buildIncorrectData(form);
    const response = await this._reportInvaliDataService.reportInvalid(request);
    if (response) {
      this.sidePanelRef.close();
      this._alertService.success(this.contractChangeAlertTemplate, {
        regionName: SNACKBAR,
        isDissmisable: true
      });
    } else {
      this.showErrorMsg = true;
    }
  }

  buildIncorrectData(form: any): IReportInvalidRequest {
    const request: IReportInvalidRequest = {
      firstNm: form?.firstName,
      lastNm: form?.lastName,
      emailAddress: form?.email,
      phoneNumber: form?.phoneNumber,
      emailList: this.providerIncorrectInfo?.emailList,
      correctedInfo: form?.description,
      brandCode: this._appSession.metaData.brandCd,
      providerInfo: {
        providerName: this.provider?.providerName,
        nationalProviderIdentifier: this.provider['nationalProviderIdentifier'],
        addressOne: this.provider?.addressSummary?.addressOne,
        addressTwo: this.provider?.addressSummary?.addressTwo
      }
    };
    return request;
  }

  hasReviews(): boolean {
    return ProviderUtility.hasReviews(this.reviewRatings);
  }

  showAddReview(): boolean {
    const isProviderCategoryCodeValid = this.providerDetails?.providerCategoryCode?.code ? this.checkIndividualProvWithProvTypeP(this.providerDetails.providerCategoryCode.code) : false;
    const isPdtStrKeyValid = !!this.reviewRatings?.pdtStrKey;

    return this.isMemberSecure && isProviderCategoryCodeValid && isPdtStrKeyValid;
  }

  showReviewRating(): boolean {
    return this.showAddReview() || this.hasReviews();
  }
}
