import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITranslation } from '../../../fad/search-providers/interfaces/iAdaListResp';
import { IFtsSuggestion } from '../../common/interfaces/iFtsClientInteraction';

@Injectable({ providedIn: 'root' })
export class FtsSuggestionSelectService {
  public suggestions: IFtsSuggestion<ITranslation>[] = undefined;
  public searchTerm: string = '';
  private suggestionSubject = new BehaviorSubject<any>(this.suggestions);

  setSelectedSuggestion(suggestion: IFtsSuggestion<ITranslation>) {
    this.suggestionSubject.next(suggestion);
  }

  getSelectedSuggestion() {
    return this.suggestionSubject.asObservable();
  }
}
