import { Inject, Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppSession } from '../../../common/values/appSession';
import { IReportIncorrectDataRequest, IReportIncorrectDataResponse } from '../../result/interfaces/iIncorrectData';
import { IProviderDetailRequest, IProviderDetailResponse } from '../interfaces/iProviderDetail';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetailService extends BaseService {
  constructor(
    _httpClientSvc: HttpClientService,
    @Inject(AppSession)
    _appSession: AppSession,
    _appUtility: AppUtility
  ) {
    super(_appSession, _appUtility, _httpClientSvc);
  }

  getProviderDetail(request: IProviderDetailRequest): Promise<IProviderDetailResponse> {
    let apiUrl = apiNameList.restApi.detailsV1;

    if (this.appSession?.appState?.opsIndicator.isDetailV2Enabled) {
      apiUrl = this.appSession?.isSecureState ? apiNameList.restApi.detailSecureV2 : apiNameList.restApi.detailPublicV2;
    }

    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: request,
      headers: [
        {
          name: 'Content-Type',
          value: 'application/json'
        }
      ]
    });
  }

  getIncorrectEmailData(request: IReportIncorrectDataRequest): Promise<IReportIncorrectDataResponse> {
    const apiUrl = request?.memberCriteria?.contractUid && request?.memberCriteria?.mbrUid ? apiNameList.restApi.securereportDataConfig : apiNameList.restApi.publicreportDataConfig;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: request,
      headers: [
        {
          name: 'Content-Type',
          value: 'application/json'
        }
      ]
    });
  }
}
