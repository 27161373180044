import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseType } from '../enums/responseTypeEnum';
import { IHttpRequest } from '../interfaces/iHttpRequest';
import { IUrlParam } from '../interfaces/iUrlParam';
import { RequestHederUtil } from '../utilities/requestHeaderUtil';
import { AppSession } from '../values/appSession';
import { DataService } from './dataService';

@Injectable()
export class HttpClientService {
  private readonly API_PATH_STRING = ['/fad/api/', '/precare/api'];
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private http: HttpClient,
    private dataService: DataService
  ) {}

  request(requestOptions: IHttpRequest): Promise<any> {
    const finalRequestUrl = this.getUrl(requestOptions.url, requestOptions.urlParams);
    const options2: any = {
      params: this.getHttpParams(requestOptions.urlParams),
      headers: this.getHttpHeaders(requestOptions),
      responseType: requestOptions.responseType || ResponseType.Json,
      withCredentials: requestOptions.withCredentials || false
    };

    const req2 = new HttpRequest(this.getHttpMethod(requestOptions), finalRequestUrl, this.getHttpBody(requestOptions), options2);

    if (requestOptions.method === 'GET' || this._appSession.appConfig?.environment.toLowerCase() === 'mock') {
      return new Promise((resolve, reject) => {
        this.get(req2.urlWithParams, req2.headers, req2.responseType).subscribe(
          (result: any) => {
            resolve(result);
          },
          (error: any) => {
            reject(error);
          }
        );
      });
    } else if (requestOptions.method === 'POST') {
      return new Promise((resolve, reject) => {
        this.post(req2.urlWithParams, req2.body, req2.headers, req2.responseType).subscribe(
          (result: any) => {
            resolve(result);
          },
          (error: any) => {
            reject(error);
          }
        );
      });
    }
  }

  private createCustomHeaders(headerParam?: HttpHeaders) {
    // Add custom headers
    if (headerParam) {
      return headerParam;
    } else {
      const headers = new HttpHeaders();
      return headers.set('Content-Type', 'application/json');
    }
  }

  private get(url, headerParam?: HttpHeaders, responseTypeParam?: any) {
    // Reset NG UI JavaScript Idle timeout for every HTTP GET API call
    this.dataService.resetIdleTimeoutClick(true);

    if (headerParam === undefined || headerParam == null) {
      if (responseTypeParam !== undefined && responseTypeParam != null) {
        return this.http.get(url, { responseType: responseTypeParam });
      } else {
        return this.http.get(url);
      }
    } else {
      if (responseTypeParam !== undefined && responseTypeParam != null) {
        return this.http.get(url, { responseType: responseTypeParam, headers: headerParam });
      } else {
        return this.http.get(url, { headers: headerParam });
      }
    }
  }

  private post(url, data, headerParam?: HttpHeaders, responseTypeParam?: any) {
    // Reset NG UI JavaScript Idle timeout for every HTTP GET API call
    this.dataService.resetIdleTimeoutClick(true);

    let header;
    if (headerParam === undefined || headerParam == null || (headerParam !== undefined && headerParam != null && headerParam instanceof HttpHeaders)) {
      header = this.createCustomHeaders(headerParam);
    }

    if (typeof data !== 'string') {
      data = JSON.stringify(data);
    }

    if (responseTypeParam !== undefined && responseTypeParam != null) {
      return this.http.post(url, data, { responseType: responseTypeParam, headers: header });
    } else {
      return this.http.post(url, data, { headers: header });
    }
  }

  private getUrl(url: string, urlParams: Array<IUrlParam> = []): string {
    for (const param of urlParams) {
      if (!param.isQueryParam && !new RegExp('^\\d+$').test(param.name) && param.value && new RegExp('(^|[^\\\\]):' + param.name + '(\\W|$)').test(url)) {
        const encVal = this.encodeUriSegment(param.value);
        url = url.replace(new RegExp(':' + param.name + '(\\W|$)', 'g'), (match, p1) => {
          return encVal + p1;
        });
      }
    }

    return url;
  }

  /**
   * Allow encoding chars on url.
   * @param val - url string
   */
  private encodeUriSegment(val: string) {
    return this.encodeUriQuery(val, true).replace(/%26/gi, '&').replace(/%3D/gi, '=').replace(/%2B/gi, '+');
  }

  /**
   *  Allow encoding parameters in url
   *  @param val - url stirng
   *  @param pctEncodeSpaces - should encode spaces or not
   */
  private encodeUriQuery(val: string, pctEncodeSpaces: boolean) {
    return encodeURIComponent(val)
      .replace(/%40/gi, '@')
      .replace(/%3A/gi, ':')
      .replace(/%24/g, '$')
      .replace(/%2C/gi, ',')
      .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
  }

  private getHttpParams(urlParams: Array<IUrlParam> = []): HttpParams {
    let params = new HttpParams();
    for (const param of urlParams) {
      if (param.isQueryParam && typeof param.value !== 'undefined') {
        params = params.set(param.name, param.value);
      }
    }

    return params;
  }

  private getHttpHeaders(requestOptions: IHttpRequest): HttpHeaders {
    return RequestHederUtil.setFor(requestOptions.url, this._appSession, requestOptions?.headers);
  }

  private getHttpMethod(requestOptions: IHttpRequest): string {
    if (typeof requestOptions.method === 'string') {
      return requestOptions.method;
    } else {
      switch (requestOptions.method) {
        case 1:
          return 'POST';
        case 2:
          return 'PUT';
        case 3:
          return 'DELETE';
        case 4:
          return 'OPTIONS';
        case 5:
          return 'HEAD';
        case 6:
          return 'PATCH';

        default:
          return 'GET';
      }
    }
  }

  private getHttpBody(requestOptions: IHttpRequest): any {
    if (requestOptions.data) {
      return requestOptions.isMultiPartRequest ? requestOptions.data : JSON.stringify(requestOptions.data);
    }

    return null;
  }
}
