import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MemberPCPHelper } from '../../../../../care-circle/services/memberPCPHelper';
import { AnpSidePanelComponent } from '../../../../../common/components/common-alert/anpSidePanelCmp';
import { CaHmoPanelComponent } from '../../../../../common/components/pcp/caHmoPanelCmp';
import { ANP_ERROR_CODE, CA_HMO_DISCLAIMER, DOCTOR_NAME, MEMBER_NAME, TERMINATION_DATE } from '../../../../../common/constants/app-constants';
import { IFinalProvider } from '../../../../../common/interfaces/iCommonSearchResponse';
import { ISpecialty } from '../../../../../common/interfaces/iSearchStrategyResponse';
import { ISelectedPcp } from '../../../../../common/interfaces/iSelectedPcp';
import { ISlideoutMessage } from '../../../../../common/interfaces/iSlideoutMsg';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { OUTNETWORK } from '../../../../../fad/search-results/values/providerSearchConstants';
import { IPcpNavResponse } from '../../../../assign-pcp/models/iMemberPcpDetails';
import { AssignPcpNavigationService } from '../../../../assign-pcp/services/assignPcpNavigationSvc';
import { ICareProvider, ICareProviderDetails } from '../../../../care-circle/interfaces/iCareProvider';
import { IAddress } from '../../../../result/interfaces/iProvider';
import { BaseComponent } from '../../core/baseCmp';
import { SliderAlertMessageComponent } from '../common-slider-alert/components/slider-alert-message/sliderAlertMessageCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-assign-pcp-cta-cmp',
  templateUrl: './assignPcpCtaCmp.html'
})
export class AssignPcpCtaComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() provider: IFinalProvider;
  @Input() careProvider: ICareProvider;
  @ViewChild('slideoutMsgCmp') slideoutMsgCmp: SliderAlertMessageComponent;
  @ViewChild('anpSidePanelCmp') anpSidePanelCmp: AnpSidePanelComponent;
  @ViewChild('caHmoPanelCmp') caHmoPanelCmp: CaHmoPanelComponent;

  @Input() isAssignPcpEnabled = false;
  @Output() isAssignPcpEnabledChange = new EventEmitter();

  assignPcpCmpContent = this.content?.common?.assignPcpCmpContent;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _assignPcpNavSvc: AssignPcpNavigationService,
    private _dataHelper: DataHelper,
    private _memberPCPHelper: MemberPCPHelper
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['provider']) {
      this.isAssignPcpEnabled = this.provider && this.showAssignPCPBtn(this.provider);
      this.isAssignPcpEnabledChange.emit(this.isAssignPcpEnabled);
    }
    if (changes['careProvider']) {
      this.isAssignPcpEnabled = this.careProvider && this.validateCareProvider(this.careProvider);
      this.isAssignPcpEnabledChange.emit(this.isAssignPcpEnabled);
    }
  }

  /**
   * Method to transform provider details to selected PCP object
   * @param provider - Provider details
   * @returns selected PCP object
   */
  transfromProviderDetails(provider: IFinalProvider & { address?: IAddress }): ISelectedPcp {
    return {
      ...provider,
      location: {
        address: {
          addressId: provider?.address?.addressId,
          addressOne: provider?.address?.addressOne,
          addressTwo: provider?.address?.addressTwo,
          phone: provider?.address?.phone
        }
      }
    };
  }

  /**
   * Method to handle assign PCP click event
   * @param provider - Provider details
   */
  onAssignPcpClick(provider: IFinalProvider & { address?: IAddress }) {
    const selectedProviderForPCP = this.transfromProviderDetails(provider);

    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(selectedProviderForPCP);
    this.handleAssignPcpNavPromise(navPromise, provider, 'right');
  }

  /**
   * Method to continue for assign PCP and navigate to assign-PCP page in CA-HMO case
   */
  onContinueAssignPcp() {
    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(this._appSession.selectedProvider, true);
    this.handleAssignPcpNavPromise(navPromise, this._appSession.selectedProvider, 'right');
  }

  /**
   * Method to handle assign PCP navigation promise or open different slide panel based on scenario
   * @param navPromise promise to navigate to assign PCP page
   * @param provider provider details
   * @param direction direction to open side panel
   */
  async handleAssignPcpNavPromise(navPromise: Promise<IPcpNavResponse>, provider: IFinalProvider | ICareProviderDetails, direction: string) {
    try {
      await navPromise;
    } catch (error) {
      // Handle Errors using the error.code value
      if (error.code === ANP_ERROR_CODE) {
        this.anpSidePanelCmp.openSidePanel();
      } else if (error.code === CA_HMO_DISCLAIMER) {
        this.caHmoPanelCmp.openSidePanel();
      } else {
        const slideOutMessage = this.formatSlideoutMessage(error, provider);
        this.slideoutMsgCmp.showMessage(slideOutMessage);
      }
      throw error;
    }
  }

  /**
   * Method to format slideout message based on error code and provider details
   * @param error error object with code and termination date
   * @param provider provider details
   * @returns slideout message object
   */
  formatSlideoutMessage(error: any, provider: IFinalProvider | ICareProviderDetails): ISlideoutMessage {
    const slideoutMsgContent = this.assignPcpCmpContent.slideoutMsgCmpContent;
    let message = this._dataHelper.getValueByKey<string>(error.code, slideoutMsgContent);
    let title: string = slideoutMsgContent['title_' + error.code] ?? slideoutMsgContent.title;

    const selectedContract = this._appSession.appState.selectedEligibilityProduct.selectedContract;
    const mbrName = selectedContract.firstNm + ' ' + selectedContract.lastNm;

    message = message.replace(DOCTOR_NAME, provider?.providerName);
    message = message.replace(TERMINATION_DATE, error.terminationDt ?? '');
    message = message.replace(MEMBER_NAME, mbrName);

    const slideOutMessage: ISlideoutMessage = {
      title,
      close: slideoutMsgContent.close,
      message
    };
    return slideOutMessage;
  }

  /**
   * Method to check if assign PCP button should be shown
   * @param provider
   * @returns
   */
  showAssignPCPBtn(provider: IFinalProvider): boolean {
    const selectedProviderForPCP = this.transfromProviderDetails(provider);
    return (
      provider?.visibilityRule?.showAssignPCP &&
      this._appSession.appState?.opsIndicator?.isPcpEligible &&
      this._memberPCPHelper.isNotAMemberPCPProvider(selectedProviderForPCP) &&
      this._memberPCPHelper.isNotAMemberFuturePCPProvider(selectedProviderForPCP) &&
      !this.isOutOfNetwork(provider.providerNetworkStatus.statusCode)
    );
  }

  /**
   * Method to validate assign PCP
   * @param careProvider - Care provider details
   * @returns boolean based on validation
   */
  validateCareProvider(careProvider: ICareProvider): boolean {
    const selectedProviderForPCP = this.mapCareProviderDetails(careProvider);

    return (
      careProvider?.showAssignPCP &&
      this._appSession?.appState?.opsIndicator?.isPcpEligible &&
      this._memberPCPHelper.isNotAMemberPCPProvider(selectedProviderForPCP) &&
      this._memberPCPHelper.isNotAMemberFuturePCPProvider(selectedProviderForPCP)
    );
  }

  /**
   * Method to handle care provider click event
   * @param careProvider - Care provider details
   */
  onCareProviderClick(careProvider: ICareProvider) {
    const selectedProviderForPCP = this.mapCareProviderDetails(careProvider);

    const navPromise = this._assignPcpNavSvc.navigateToAssignPcp(selectedProviderForPCP);
    this.handleAssignPcpNavPromise(navPromise, selectedProviderForPCP, 'right');
  }

  /**
   * Method to transform care provider details to selected PCP object
   * @param careProvider  - Care provider details
   * @returns selected PCP object
   */
  mapCareProviderDetails(careProvider: ICareProvider): ICareProviderDetails {
    const specialties = careProvider?.specialties?.map<ISpecialty>((spl) => {
      return { specialtyCode: spl.code, specialtyCodeName: spl.name };
    });

    const professional = {
      providerIdentifier: careProvider?.providerId,
      providerName: careProvider?.fullNm,
      titleList: careProvider?.titleList,
      providerTypeCodeList: careProvider?.providerTypes,
      pcpList: careProvider?.pcpList,
      pcpInfoList: careProvider?.pcpInfoList,
      nickName: careProvider?.nickName,
      labels: careProvider?.labels,
      id: careProvider?.id,
      anp: careProvider?.anp,
      location: {
        address: {
          addressId: careProvider?.address?.addressId ?? '',
          addressOne: careProvider?.address?.addressLineOne ?? '',
          addressTwo:
            (careProvider?.address?.city ? careProvider.address.city + ', ' : '') +
            (careProvider?.address?.stateCd ? careProvider.address.stateCd.code : '') +
            (careProvider?.address?.zipCd ? ' ' + careProvider.address.zipCd : ''),
          phone: careProvider?.phone
        }
      },
      providerCategoryCode: careProvider?.providerCategoryCode,
      specialties,
      providerNetworkStatus: { typeCode: careProvider?.networkTypeCodes }
    } as ICareProviderDetails;
    return professional;
  }

  /**
   * Method to check if provider is out of network
   * @param statusCode - Provider network status code
   * @returns boolean based on network status
   */
  isOutOfNetwork(statusCode: string): boolean {
    return statusCode === OUTNETWORK;
  }
}
