import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Output, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProviderCard } from '../../../constants/result';
import { IDisclaimerRequest, IDisclaimerResponse, IRule } from '../../../interfaces/iDisclaimer';
import { IProviderOverview } from '../../../interfaces/iProviderDetail';
import { DisclaimerSvc } from '../../../services/disclaimerSvc';
import { ProviderDetail } from '../../../services/providerDetail';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-disclaimer-cmp',
  templateUrl: './providerDisclaimerCmp.html'
})
export class ProviderDisclaimerComponent extends BaseComponent {
  disclaimers$ = new BehaviorSubject<string[]>([]);
  showDisclaimerLoader$ = new BehaviorSubject<boolean>(false);
  disclaimerContent = this.content?.result?.disclaimerCard;
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Output() onDisclaimersChange = new EventEmitter<string[]>();
  showProviderLoader = true;
  showDisclaimerError = false;
  @ViewChildren('disclaimerContainer') disclaimerContainer: QueryList<ElementRef>;
  providerDetailSubscription: Subscription;

  constructor(
    private _disclaimerSvc: DisclaimerSvc,
    @Inject(DataHelper) private _dataHelper: DataHelper,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession) private _appSession: AppSession,
    private _providerDetailHandler: ProviderDetail,
    private cdr: ChangeDetectorRef
  ) {
    super(_route, _eventHandler, _appSession);
    this.disclaimers$.next([]);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.providerDetailSubscription = this._providerDetailHandler.details.subscribe((providerDetail: IProviderOverview) => {
      this.getProviderDisclaimers(providerDetail?.ruleList || []);
    });
  }

  getProviderDisclaimers(ruleList: IRule[]) {
    // Resetting the disclaimer arrays to handle refreshing of disclaimers
    this.disclaimers$.next([]);
    this.showDisclaimerLoader$.next(true);

    // Creation of Disclaimer Request
    const reqObj: IDisclaimerRequest = {
      isEyc: this._appSession?.isEyc,
      isCpt: this._appSession?.isMedicalCodeSearch,
      isTopRequired: true,
      isVitalRequired: false,
      rules: ruleList
    };

    this._disclaimerSvc.loadDisclaimerIDs(reqObj).then(
      (disclaimers: IDisclaimerResponse) => {
        this.showDisclaimerLoader$.next(false);
        let genericDisclaimers = [];

        if (disclaimers?.messages.length > 0) {
          // Generic Messages
          disclaimers.messages.forEach((disclaimerId: string) => {
            const disclaimerMsg = this.content?.common?.disclaimer?.[disclaimerId] ?? null;
            if (disclaimerMsg !== null) {
              const genericMessage = this._dataHelper.isObject(disclaimerMsg) ? disclaimerMsg.content : disclaimerMsg;
              genericDisclaimers.push(genericMessage);
            }
          });
        }

        this.disclaimers$.next(genericDisclaimers);
        this.cdr.detectChanges();
        this.onDisclaimersChange.emit(genericDisclaimers);
      },
      (error: any) => {
        this.onError(error);
      }
    );
  }

  onError(error: any) {
    this.showDisclaimerLoader$.next(false);
    this.showDisclaimerError = true;
    this.disclaimers$.next([]);
  }

  ngOnDestroy() {
    if (this.providerDetailSubscription) {
      this.providerDetailSubscription.unsubscribe();
    }
    this.disclaimers$.next([]);
    this.showDisclaimerLoader$.next(false);
    this.showDisclaimerError = false;
  }
}
