import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { IProviderCostDetails } from '../../../../../eyc/cost-details/models/iProviderCostDetails';
import { ProviderProcedureInfo } from '../../../../../fad/provider-details/models/iProcedureCostEst';
import { ICostInfo, IHealthwiseAdobeAnalyticsObj, IProvider } from '../../../../../fad/search-results/interfaces/iSummaryResp';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProviderCard } from '../../../constants/result';

@Component({
  moduleId: module.id,
  selector: 'app-fc-procedure-cost-info-cmp',
  templateUrl: './providerProcedureCostInfoCmp.html'
})
export class ProviderProcedureCostInfoCmp extends BaseComponent implements OnInit {
  @Input()
  costInfo: ICostInfo = {} as ICostInfo;
  @Input()
  costEst: ICostInfo = {} as ICostInfo;
  @Input()
  costDetails: IProviderCostDetails = {} as IProviderCostDetails;
  @Input()
  selectedProcedureObj: ProviderProcedureInfo = {} as ProviderProcedureInfo;
  @Input()
  hraNocostText: string;
  @Input()
  coinsuranceDesc: string;
  @Input()
  showCostError: boolean;
  @Input()
  showCostLoader: boolean;
  @Input()
  selectedProvider: IProvider = {} as IProvider;
  @Output() procedureCardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  isExpanded: boolean = false;
  isProcedureDetailsVisible: boolean = false;

  private _adobeAnalyticsObj: IHealthwiseAdobeAnalyticsObj = {
    isSummary: false
  };
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  onShowCostDetails() {
    this.costDetails.costEstimate = this.costEst;
    this.costDetails.costInfo = this.costInfo;
    this.costDetails.coinsuranceDesc = this.coinsuranceDesc;
  }

  showProcedureDetails() {
    this.procedureCardSelected.emit(ProviderCard.PROCEDURE);
  }
}
