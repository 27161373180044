import { AppSession } from '../../../common/values/appSession';
import { ResultsUtil } from '../../../fad/utilities/resultsUtil';
import { ISearchRequest } from '../../common/interfaces/iSearchRequest';

export class AffiliationUtility {
  static getAffiliationRequest(appSession: AppSession, typeCode: string): ISearchRequest | undefined {
    if (!appSession || !typeCode) {
      return undefined;
    }

    let searchRequest = ResultsUtil.buildProviderRequest(appSession, 1, appSession.appConfig?.paginationSize?.groupAffiliationPage, typeCode);
    return this.removeSpecialty(searchRequest);
  }

  private static removeSpecialty(searchRequest: ISearchRequest): ISearchRequest {
    if (searchRequest?.searchCriteria) {
      delete searchRequest.searchCriteria.specialtyCategoryList;
      delete searchRequest.searchCriteria.subSpecialtyList;
      delete searchRequest.searchCriteria.taxonomyList;
      delete searchRequest.searchCriteria.ableToServeAsPCP;
    }
    return searchRequest;
  }
}
