import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';

@Component({
  moduleId: module.id,
  selector: 'app-fc-data-loader',
  templateUrl: './loaderCmp.html'
})
export class LoaderComponent {
  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute
  ) {}
}
