import { Component, ElementRef, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IRadioInput } from '@anthem/uxd/util';
import { Locale } from '../../../../../common/constants/app-constants';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { FEEDBACK_INTENDED_TASK_OPTIONS } from '../../../constants/result';
import { IFeedbackRequest } from '../../../interfaces/iFeedback';
import { FeedbackService } from '../../../services/feedbackSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fcr-feedback-cmp',
  templateUrl: './feedbackCmp.html',
  providers: []
})
export class FeedbackComponent extends BaseComponent implements OnInit {
  maxFeedbackLength: number = 2000;
  feedbackImprovement = '';
  feedbackExperience = '';
  sidePanelRef: ModalRef<any, any>;
  feedbackMessage: IFeedbackRequest;
  @ViewChild('feedbackInput')
  feedbackInput: ElementRef;
  @ViewChild('openFeedbackSlidePanel')
  feedbackSlidePanel: TemplateRef<HTMLElement>;
  selectedIntendedTaskOption: string = 'Y';
  intendedTaskOptions: Array<IRadioInput> = FEEDBACK_INTENDED_TASK_OPTIONS.options;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _sidePanel: SidePanel,
    private _feedbackSvc: FeedbackService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this._appSession.metaData.locale === Locale.SPANISH) {
      this.intendedTaskOptions = this.intendedTaskOptions.map((option) => {
        return {
          ...option,
          label: option.label.replace('Yes', this.content.common.freeTextSearchResults.feedback.yes).replace('No', this.content.common.freeTextSearchResults.feedback.no)
        };
      });
    }
  }

  onSendFeedback() {
    const feedbackMessage = {
      cT: this.selectedIntendedTaskOption.toUpperCase() === 'Y',
      wDL: encodeURIComponent(this.feedbackExperience),
      wTI: encodeURIComponent(this.feedbackImprovement)
    };
    this._feedbackSvc.submitFeedback(feedbackMessage);
    this.closeFeedback();
  }

  closeFeedback() {
    this.sidePanelRef.close();
  }

  onFeedbackSlideOut() {
    this.feedbackExperience = '';
    this.feedbackImprovement = '';
    this.sidePanelRef = this._sidePanel.open('right', this.feedbackSlidePanel);
  }

  getRemainingCharacterCountMsg(feedback: string): string {
    const count = this.maxFeedbackLength - feedback.length;
    return this.content.common.freeTextSearchResults.feedback.remainingCharacters.replace(/{charLength}/gi, () => count.toString());
  }
}
