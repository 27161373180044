import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ISmartSearchLookupRequest } from '../interfaces/iSmartSearchLookupRequest';
import { ISmartSearchLookupResponse } from '../interfaces/iSmartSearchLookupResponse';
import { BaseService } from './../../../common/services/baseService';

@Injectable()
export class SmartSearchService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  getSmartSearchLookup(reqObj: ISmartSearchLookupRequest): Promise<ISmartSearchLookupResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.smartSearchLookup,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
