import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { IOption } from '../../common/interfaces/iOption';
import { FilterCategory } from '../constants/result';
import { IAppliedSearchFilter } from '../models/iAppliedSearchFilter';
import { SearchFilter } from './searchFilter';

@Injectable({ providedIn: 'root' })
export class AppliedSearchFilter {
  public appliedFilterItems: IAppliedSearchFilter[] = [];
  public initialAppliedFilterItems: IAppliedSearchFilter[] = [];
  private appliedFilters = new BehaviorSubject<IAppliedSearchFilter[]>(this.appliedFilterItems);
  private remove = new BehaviorSubject<IAppliedSearchFilter>(undefined);

  constructor(private _searchFilter: SearchFilter) {}

  get getAppliedFilters() {
    return this.appliedFilters.asObservable().pipe(
      map((filters) => {
        const uniqueNames = new Set();
        return filters.filter((filter) => {
          if (!uniqueNames.has(filter.name)) {
            uniqueNames.add(filter.name);
            return true;
          }
          return false;
        });
      })
    );
  }

  get appliedFilterRemove() {
    return this.remove.asObservable();
  }

  /**
   * Adds a filter item to the applied filter items list.
   * @param category The category of the filter.
   * @param filterOption The filter option to add.
   */
  addFilterOption(category: string, filterOption: IOption, initial: boolean = false): void {
    const filter: IAppliedSearchFilter = {
      category: category,
      value: filterOption.value,
      name: filterOption.label
    };
    if (!this.appliedFilterItems.some((item) => item.category === category && item.name === filterOption.label && item.value === filterOption.value)) {
      this.appliedFilterItems.push(filter);
      if (this.isSpecialKeyFilterOption(category, filterOption.value)) {
        this.appliedFilterItems.push({
          category: category === FilterCategory.KEYFILTERS ? FilterCategory.RECOGNITION : FilterCategory.KEYFILTERS,
          value: filterOption.value,
          name: filterOption.label
        } as IAppliedSearchFilter);
      }
      this.appliedFilters.next(this.appliedFilterItems);
    }

    if (initial) {
      this.addInitialAppliedFilterItem(filter);
    }
  }

  addInitialAppliedFilterItem(filterItem: IAppliedSearchFilter): void {
    if (!this.initialAppliedFilterItems.some((item) => item.category === filterItem.category && item.name === filterItem.name && item.value === filterItem.value)) {
      const filter: IAppliedSearchFilter = {
        category: filterItem.category,
        value: filterItem.value,
        name: filterItem.name
      };
      this.initialAppliedFilterItems.push(filter);
    }
  }

  removeInitialAppliedFilterItem(filterItem: IAppliedSearchFilter): void {
    const removeItemIndex = this.initialAppliedFilterItems.findIndex(
      (item) => filterItem && item && item.category === filterItem.category && item.name === filterItem.name && item.value === filterItem.value
    );
    if (removeItemIndex > -1) {
      this.initialAppliedFilterItems.splice(removeItemIndex, 1);
    }
  }

  /**
   * Removes a filter item from the applied filter items list.
   * @param category The category of the filter.
   * @param filterOption The filter option to remove.
   * @param initial Remove from initial applied filters.
   */
  removeFilterOption(category: string, filterOption: IOption, initial: boolean = false): void {
    const index = this.appliedFilterItems.findIndex((option) => option.category === category && option.name === filterOption.label);
    if (index !== -1) {
      if (initial) {
        this.removeInitialAppliedFilterItem(this.appliedFilterItems[index]);
      }

      if (this.isSpecialKeyFilterOption(category, filterOption.value)) {
        this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.name !== filterOption.label);
      } else {
        this.appliedFilterItems.splice(index, 1);
      }
      this.appliedFilters.next(this.appliedFilterItems);
    }
  }

  /**
   * Adds filters for a specific category, replacing any existing filters for that category.
   * @param category The category of the filters.
   * @param filterOptions The filter options to add.
   */
  addCategoryFilters(category: string, filterOptions: IAppliedSearchFilter[]): void {
    // Remove existing filters for the specified category
    this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.category !== category);

    // Add the new filter options for the specified category
    this.appliedFilterItems.push(...filterOptions);

    // Notify subscribers of the updated filter items
    this.appliedFilters.next(this.appliedFilterItems);
  }

  /**
   * Removes a specific filter from the applied filters.
   * @param filter The filter to remove.
   */
  removeFilter(filter: IAppliedSearchFilter): void {
    if (!filter) {
      return;
    }

    const index = this.appliedFilterItems.findIndex((option) => option.category === filter.category && option.name === filter.name);

    if (index !== -1) {
      const filterOption = this.appliedFilterItems[index];
      if (this.isSpecialKeyFilterOption(filterOption.category, filterOption.value)) {
        this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.name !== filterOption.name);
      } else {
        this.appliedFilterItems.splice(index, 1);
      }
      this.appliedFilters.next(this.appliedFilterItems);
      this.remove.next(filter);
    }
  }

  /**
   * Clears all filters or filters of a specific category.
   * @param category The category of filters to clear. If not provided, all filters are cleared.
   */
  clearFilters(category?: string): void {
    if (category) {
      this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.category !== category);
    } else {
      this.appliedFilterItems = [];
      this.clearInitialFilters();
    }
    this.appliedFilters.next(this.appliedFilterItems);
  }

  clearInitialFilters(): void {
    this.initialAppliedFilterItems = [];
  }

  resetFilters(filterItems: IAppliedSearchFilter[]): void {
    this.appliedFilterItems = [...filterItems];
    this.appliedFilters.next(this.appliedFilterItems);
  }

  undoFilter(): void {
    const lastFilter = this.appliedFilterItems[this.appliedFilterItems.length - 1];
    if (!this.initialAppliedFilterItems.some((item) => item.category === lastFilter.category && item.name === lastFilter.name && item.value === lastFilter.value)) {
      const poppedFilter = this.appliedFilterItems.pop();
      if (poppedFilter && this.isSpecialKeyFilterOption(poppedFilter.category, poppedFilter.value)) {
        this.appliedFilterItems = this.appliedFilterItems.filter((item) => item.name !== poppedFilter.name);
      }
      this.remove.next(poppedFilter);
      this.appliedFilters.next(this.appliedFilterItems);
    }
  }

  /**
   * Maps the applied filter items based on the category property.
   * @param category The category to filter by. If not provided, all categories are returned.
   * @returns An object with categories as keys and arrays of corresponding filter items as values.
   */
  mapAppliedFilterItemsByCategory(category?: string): { [key: string]: IAppliedSearchFilter[] } {
    if (category) {
      return this.appliedFilterItems
        .filter((item) => item.category === category)
        .reduce(
          (acc, item) => {
            if (!acc[item.category]) {
              acc[item.category] = [];
            }
            acc[item.category].push(item);
            return acc;
          },
          {} as { [key: string]: IAppliedSearchFilter[] }
        );
    } else {
      return this.appliedFilterItems.reduce(
        (acc, item) => {
          if (!acc[item.category]) {
            acc[item.category] = [];
          }
          acc[item.category].push(item);
          return acc;
        },
        {} as { [key: string]: IAppliedSearchFilter[] }
      );
    }
  }

  /**
   * Checks if the provided value is a special key filter option.
   * @param value - The value to check.
   * @returns {boolean} - True if the value is a special key filter option, otherwise false.
   */
  private isSpecialKeyFilterOption(category: string, value: string): boolean {
    if (!value) {
      return false;
    }
    return [FilterCategory.KEYFILTERS, FilterCategory.RECOGNITION].includes(category as FilterCategory) && this._searchFilter.isKeyRecognitionFilterOption(value);
  }
}
