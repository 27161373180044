import { AppSession } from '../../../common/values/appSession';
import { ContractCoverageType } from '../constants/common';
import { IContract } from '../interfaces/iContract';

export class ContractUtility {
  /**
   * Retrieves unique coverage types from the contract.
   * @param contract The bootstrap contract.
   * @returns An array of unique coverage types.
   */
  static getCoverages(contract: IContract): string[] {
    if (!contract?.coverages) {
      return [];
    }

    const coverages = contract.coverages.flatMap((coverage) => coverage.coverageTypes);
    const uniqueCoverages = [...new Set(coverages)];

    return uniqueCoverages.map((type) => ContractCoverageType[type] ?? undefined).filter(Boolean) || [];
  }

  /**
   * Retrieves unique coverage plans from the contract.
   * @param contract The bootstrap contract.
   * @returns An array of unique coverage plans.
   */
  static getCoveragePlans(contract: IContract): string[] {
    if (!contract?.coverages) {
      return [];
    }

    const plans = contract.coverages.map((coverage) => coverage.planNm);
    return Array.from(new Set(plans));
  }

  /**
   * Indicates whether the contract is a standalone contract.
   * @param appSession The application session.
   * @returns True if the contract is a standalone contract, otherwise false.
   */
  static isStandAloneContract(appSession: AppSession): boolean {
    const opsIndicator = appSession?.appState?.opsIndicator;

    if (!opsIndicator) {
      return false;
    }

    const { isMedicalStandAlone, isDentalStandAlone, isVisionStandAlone, isRxStandAlone } = opsIndicator;

    return isMedicalStandAlone || isDentalStandAlone || isVisionStandAlone || isRxStandAlone || false;
  }
}
