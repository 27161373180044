import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserImpressionUtil } from '../../../../app/common/utilities/userImpressionUtil';
import { CareTeamResponse } from '../../../care-circle/interfaces/iCareProvider';
import { MemberPCPHelper } from '../../../care-circle/services/memberPCPHelper';
import { IAppContract } from '../../../common/interfaces/iAppMetadata';
import { IWindow } from '../../../common/interfaces/iWindow';
import { RulesService } from '../../rules/services/RulesSvc';
import { ICobrandRule, IPlanRule } from '../../search-providers/interfaces/iPlanRule';
import { ProviderSummaryService } from '../../search-results/services/providerSummarySvc';
import { ResultsUtil } from '../../utilities/resultsUtil';
import { ProcedureCategoryService } from '.././../../eyc/cost-search/services/procedureCategorySvc';
import { ICareActionItemsResponse } from '../interfaces/ICareActionItemsResponse';
import { IConfiguredSearchResponse } from '../interfaces/iPreconfigSearch';
import { ICodeType, IProviderType } from '../interfaces/iProviderType';
import { ILinkProcedure, IQuickLinks } from '../interfaces/iQuickLinks';
import { CareActionMessageSvc } from '../services/careActionMessageSvc';
import { ProviderUtilityService } from '../services/providerUtilitySvc';
import { QuickLinksService } from '../services/quickLinksSvc';
import {
  ADDCARETEAM_MESSAGE,
  ALERT_TYPE,
  ARIALABEL_MESSAGE,
  COUPE_HEALTH_ALERT,
  MESSAGE_CATEGORIES,
  PROVIDER_SEARCH_DISTANCE,
  PROVIDER_TYPE,
  TP_SUPPRESSED_MEDSUPP,
  VEP,
  VEP_BEH_HEALTH,
  VIEW_MORE_PROCEDURE_COUNT
} from '../values/providerSearchConstants';
import { CareCircleService } from './../../../care-circle/services/careCircleSvc';
import { IDeeplink } from './../../../common/components/app-secure/models/iDeeplink';
import { BaseComponent } from './../../../common/components/base-component/baseCmp';
import { IFilterChangePlan } from './../../../common/components/change-plan/models/iChangePlanParam';
import { ChangePlanService } from './../../../common/components/change-plan/services/changePlanSvc';
import { InfoListService } from './../../../common/components/info-list/services/infoListSvc';
import { AppConstants, BRAND_CODE, Locale, MODAL_TYPE } from './../../../common/constants/app-constants';
import { AppNavigations } from './../../../common/constants/app-navigations';
import { AppEvents } from './../../../common/enums/appEvents';
import { IAdobeSearchDetail } from './../../../common/interfaces/iAdobe';
import { IOptions } from './../../../common/interfaces/iAppMetadata';
import { IEventDetail } from './../../../common/interfaces/iEventDetail';
import { IMessage } from './../../../common/interfaces/iMessage';
import { DataHelper } from './../../../common/services/dataHelper';
import { EventHandler } from './../../../common/services/eventHandler';
import { NavigationService } from './../../../common/services/navigationService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';
import { ContentHelper } from './../../../common/values/contentHelper';
import { CommonUtil } from './../../utilities/commonUtil';
import { ISearchParameters } from './../interfaces/iSearchParameters';

@Component({
  moduleId: module.id,
  selector: 'app-fad-pf-dynamic-search-links-cmp',
  templateUrl: '../views/pfDynamicSearchLinksCmp.html',
  providers: []
})
export class PFDynamicSearchLinksComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  gotoPlanSelection: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  planError: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  modal: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();

  selectedPlanText: string = '';
  showViewMoreLink: boolean = false;
  careTeamResponse: CareTeamResponse;
  providerTypeList: IProviderType[] = [];
  procedureList: ILinkProcedure[] = [];
  locale: string;
  isSecureState: boolean = false;
  showPlanUnknownError: boolean = false;
  planUnknownErrorMsg: string = '';
  planRule: IPlanRule;
  showCareTout: boolean;
  showProviderProcedureLoader: boolean = false;
  showCareTeamLoader: boolean;
  careTeamHeading: string = '';
  hasEYC: boolean = false;
  count: number = 0;
  private changePlanSaveClickSubscription: Subscription;
  hasPersonalization: boolean = false;
  personalizedIndicator: number;

  constructor(
    private _providerSummarySvc: ProviderSummaryService,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _providerUtilityService: ProviderUtilityService,
    private _procedureSvc: ProcedureCategoryService,
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    private _ruleService: RulesService,
    private _careActionMsgService: CareActionMessageSvc,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _eventHandler: EventHandler,
    private _appUtility: AppUtility,
    private _changePlanService: ChangePlanService,
    private _titleCasePipe: TitleCasePipe,
    private _infoListSvc: InfoListService,
    private _userImpressionUtil: UserImpressionUtil,
    private _careCricleSvc: CareCircleService,
    private _memberPCPHelper: MemberPCPHelper,
    private _quickLinksService: QuickLinksService
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');

    this.changePlanSaveClickSubscription = this._changePlanService.onChangePlanSaveClick.subscribe((path: AppNavigations) => {
      if (path === AppNavigations.SEARCH_PROVIDERS_PATH) {
        this.ngOnInit();
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._appUtility.scrollToTop();

    if (this._appSession) {
      this._appSession.pageNumber = null;
      this.locale = this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
      this.isSecureState = CommonUtil.isMemberSecure(this._appSession);
      this.hasEYC = this.isSecureState && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS));
      if (this._appSession.showPrefixPopup) {
        this.showSpecialPrefixPopup();
      }

      this.getProviderProcedureTypes();
      const _disclaimerCodes = this._infoListSvc.disclaimerCodes;
      this._infoListSvc.reset();
      this._infoListSvc.setInfoListDisclaimerValue(true);
      if (this.isSecureState) {
        // Handle Info box in search criteria.
        const disclaimerCodes: IMessage[] = [];
        if (this.isAddCareTeamEnabled) {
          const index = _disclaimerCodes.findIndex((x) => x.contentId === ADDCARETEAM_MESSAGE);
          if (index > -1) {
            const _message = {
              contentId: ADDCARETEAM_MESSAGE,
              type: ALERT_TYPE.INFORMATION
            } as IMessage;
            disclaimerCodes.push(_message);
          }
        }
        // Display TP_SUPPRESSED_MEDSUPP alert in landing page
        const hasSuppressedMedsup = this._appSession.feature?.disclaimer?.some((disclaimerId) => {
          return this._dataHelper.areEqualStrings(disclaimerId, TP_SUPPRESSED_MEDSUPP);
        });
        if (hasSuppressedMedsup) {
          disclaimerCodes.push({
            contentId: TP_SUPPRESSED_MEDSUPP,
            type: ALERT_TYPE.INFORMATION
          });
        }
        if (this._appSession.hasCoupeHealth) {
          disclaimerCodes.push({
            contentId: COUPE_HEALTH_ALERT,
            type: ALERT_TYPE.INFORMATION
          });
        }
        this._infoListSvc.setInfoList(disclaimerCodes);
      }
    }
    this.hasPersonalization = this._appSession.metaData.appContract?.hasPersonalization && this.showPznNBAWidgetFindcare;
  }

  ngOnDestroy() {
    if (this.changePlanSaveClickSubscription) {
      this.changePlanSaveClickSubscription.unsubscribe();
    }
    this._infoListSvc.setInfoListDisclaimerValue(false);
  }

  /// Write all ngOnInit call methods here
  initCalls() {
    // Handle Urgent care deep link Medsupp popup
    if (this._appSession.searchParams && this._appSession.searchParams.typeSelectNm === PROVIDER_TYPE.URGENT_CARE && this._appSession.planRule && this._appSession?.planRule?.isMedicareGov) {
      this.openModal(MODAL_TYPE.MEDICARE_SUPPLEMENT);
    }

    //Handle when CobrandRule present
    if (this._appSession && this._appSession.planRule && this._appSession.planRule.cobrandRule) {
      this._appSession.metaData.brandCd = this._appSession.planRule.cobrandRule.object.brand;
      const integratedBrand = AppConstants.BRAND.find((x) => x.brand === this._appSession.metaData.integratedBrandCode);
      if (integratedBrand && this._appSession.planRule.cobrandRule.object && integratedBrand.brand !== BRAND_CODE.ABCNY) {
        this._appSession.planRule.cobrandRule.object.imageName = integratedBrand.logo;
      }
      this.emitCobrandEvent();
      //Emit APP_BRAND event to notify the parent Standalone page to refresh the logo with cobrand
      const brand = this._appSession.metaData.integratedBrandCode || this._appSession.metaData.brandCd;
      this._eventHandler.get(AppEvents[AppEvents.APP_BRAND]).emit(brand);
    }

    this.careTeamHeading = '';
    this.showPlanUnknownError = false;
    let _planName = '';
    let _memberName = '';
    let _selectedPlanText = this.content.searchCriteriaComponent.labels.selectedPlan;

    if (this.searchParams.plan) {
      _planName = this.searchParams.plan.name;
      if (!_planName && this.searchParams.plan.securePlanLabelKey) {
        _planName = this.searchParams.plan.securePlanLabelKey;
      }

      if (_planName === this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown] || this.searchParams.plan === undefined) {
        _planName = this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
        if (this._appSession?.planRule && !this._appSession?.planRule?.isMedicareGov) {
          this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
          this.showPlanUnknownError = true;
        }
        this.goToPlanSelection();
      } else {
        if (this.isSecureState) {
          if (this._appSession?.planRule?.isMedicareGov) {
            this.goToPlanSelection();
          }
          const _plan = ResultsUtil.getPlanNetworkPrefixText(this._appSession, '', this.searchParams);
          if (_plan) {
            _planName = _plan;
          }
          _selectedPlanText = this.content.searchCriteriaComponent.labels.selectedMemberPlan;
          if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.mbrUid) {
            const _index = this._appSession.metaData.appContract.memberList.findIndex((x) => x.mbrUid === this._appSession.metaData.appContract.mbrUid);
            if (_index > -1) {
              const _member: IOptions = this._appSession.metaData.appContract.memberList[_index];
              _memberName = this.titleCase(_member.firstName + ' ' + _member.lastName);
              this.careTeamHeading = this.content.searchCriteriaComponent.labels.careTeamFor.replace(/{NAME}/gi, _memberName.trim());
            }
          }
        }
      }
    } else {
      _planName = this.content.searchCriteriaComponent.labels[AppConstants.PlanUnknown];
      this.planUnknownErrorMsg = this.getMedicalUnknownNetworkMsg();
      this.showPlanUnknownError = true;
      this.goToPlanSelection();
    }

    this.joinMemberNameWithPlanName(_selectedPlanText, _planName, _memberName);

    this.setPlanUnknown();
    if (this.showPlanUnknownError) {
      this.planError.emit(true);
    }
    if (this.isSecureState && !this.showPlanUnknownError && this.showPreConfiguredLink) {
      this.getPreConfigureSearchRule();
    }
  }

  getPreConfigureSearchRule() {
    this._ruleService.getPreconfiguredSearchRule(CommonUtil.buildPreConfigSearchRequest(this._appSession.searchParams, this._appSession)).then(
      (data: IConfiguredSearchResponse) => {
        if (data) {
          if (data.configuredSearchRules) {
            this._appSession.preConfigureSearchRule = data.configuredSearchRules;
          } else {
            this._appSession.preConfigureSearchRule = undefined;
          }

          const preConfigRules = this._infoListSvc.disclaimerCodes;
          for (const rule of data.configuredSearchRules || []) {
            const _infoMessage = {
              contentId: rule.contentId,
              contentAriaLabelId: rule.contentId + ARIALABEL_MESSAGE,
              type: ALERT_TYPE.INFORMATION
            } as IMessage;
            preConfigRules.push(_infoMessage);
          }

          this._infoListSvc.setInfoList(preConfigRules);
        }
      },
      (error: any) => {
        try {
          this.onError('PreConfigSearchRuleResponse', error);
        } catch (e) {}
      }
    );
  }

  getCareActionItemAlertMsg(data: ICareActionItemsResponse) {
    let message = '';
    const infoList = this._infoListSvc.disclaimerCodes;
    if (data.alertMessages && data.alertMessages.length > 0) {
      data.alertMessages.forEach((alertMsg) => {
        if (
          alertMsg.action &&
          alertMsg.message &&
          alertMsg.action.label &&
          alertMsg.action.target &&
          alertMsg.action.ariaLabel &&
          alertMsg.action.dataAnalytics &&
          alertMsg.action.control === 'link' &&
          alertMsg.category
        ) {
          switch (alertMsg.category) {
            case MESSAGE_CATEGORIES.PROMOTED_PROCEDURES:
              message = this.content.newLandingComponent.labels.TP_PROMOTED_PROCEDURE.replace(/{MESSAGE}/gi, alertMsg.message)
                .replace(/{LABEL}/gi, alertMsg.action.label)
                .replace(/{TARGET}/gi, alertMsg.action.target)
                .replace(/{ARIA-LABEL}/gi, alertMsg.action.ariaLabel)
                .replace(/{DATA-ANALYTICS}/gi, alertMsg.action.dataAnalytics)
                .replace(/{CATEGORY}/gi, alertMsg.category);
              break;
          }
          if (message) {
            const _alertMsg = {
              content: message,
              type: ALERT_TYPE.INFORMATION
            } as IMessage;
            infoList.push(_alertMsg);
            this._infoListSvc.setInfoList(infoList);
          }
        }
      });
    }
  }

  setPreconfiguredSearch($event) {
    if (
      this.isLocationValid() &&
      this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.MyPlan &&
      this._appSession.preConfigureSearchRule &&
      this._appSession.preConfigureSearchRule.length > 0 &&
      this._appSession.preConfigureSearchRule[0].deeplink.providerTypeCodeList.length > 0
    ) {
      this.searchParams.typeSelectNm = this._appSession.preConfigureSearchRule[0].deeplink.providerTypeCodeList[0];
      this.searchParams.providerTypeCodeList = this._appSession.preConfigureSearchRule[0].deeplink.providerTypeCodeList;
      this.searchParams.specialtySelectNm = this._appSession.preConfigureSearchRule[0].deeplink.specialtyCategoryList;
      if (this._appSession.preConfigureSearchRule[0].deeplink.sosOnly) {
        this.searchParams.sosOnly = this._appSession.preConfigureSearchRule[0].deeplink.sosOnly;
      }
      if (this._appSession.preConfigureSearchRule[0].deeplink.acceptingNewPatients) {
        this.searchParams.acceptingNewPatients = this._appSession.preConfigureSearchRule[0].deeplink.acceptingNewPatients;
      }
      //if (this._appSession.preConfigureSearchRule[0].deeplink.ableToServePcp) {
      //  this.searchParams.ableToServePcp = this._appSession.preConfigureSearchRule[0].deeplink.ableToServePcp;
      //}
      this.resetAppSessionParams();
      this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
    }
  }

  isLocationValid() {
    let _isValid = true;
    if (!(this.searchParams && this.searchParams.coordinates && this.searchParams.coordinates.latitude !== '' && this.searchParams.coordinates.longitude !== '')) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  resetAppSessionParams() {
    this.searchParams.provNm = '';
    this.searchParams.coverageTypeCode = '';
    this.searchParams.taxonomySelectNm = [];
    this.searchParams.standAloneDental = null;
    this.searchParams.standAloneVision = null;
    this._appSession.filterSearchParams = undefined;
    this._appSession.searchParams = this.searchParams;
    this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this._appSession.initialSearch = true;
    this._appSession.integratedSearch = true;
    this._appSession.isEyc = false;
  }

  onError(type: string, error: unknown) {
    throw error;
  }
  setPlanUnknown() {
    const isPlanUnknown =
      CommonUtil.isMemberSecure(this._appSession) &&
      this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown &&
      this._appSession?.planRule &&
      !this._appSession?.planRule?.isMedicareGov;

    if (CommonUtil.isMemberSecure(this._appSession)) {
      this._appSession.isPlanUnknown = isPlanUnknown;
    }
  }

  getMedicalUnknownNetworkMsg() {
    this.showPlanUnknownError = true;
    const planUnknownMsg = this.customizedFeatures.showChangePlan
      ? this.content.searchCriteriaComponent.alerts.networkUnknownMsg
      : this.WcsUtility.getUnknownNetworkMsg(PROVIDER_TYPE.DOCTOR_PROFESSIONAL);
    return planUnknownMsg;
  }

  getProviderProcedureTypes() {
    this.showProviderProcedureLoader = true;
    this.getQuickLinksResponse().then(
      (result: IQuickLinks) => {
        if (result && (this._appSession.hasEYC || (this._appSession.hasDentalEYC && this.showDentalDDS))) {
          this.procedureList = result.procedures && result.procedures.length > 0 ? result.procedures : [];
          if (result?.isMedicalProcedure && this.isHideQuickLinksShopForProcedureEnabled) {
            if (result?.personalizedIndicator === 0 && this.procedureList?.length === VIEW_MORE_PROCEDURE_COUNT) {
              this.showViewMoreLink = true;
            } else {
              this.showViewMoreLink = this.procedureList?.length > VIEW_MORE_PROCEDURE_COUNT ? true : false;
            }
          } else {
            this.showViewMoreLink = result?.isMedicalProcedure ?? false;
          }
        } else {
          this.procedureList = [];
          this.showViewMoreLink = false;
        }
        this.getQuickLinksProviderTypes(result);
        if (result?.personalizedIndicator !== undefined) {
          this.personalizedIndicator = result.personalizedIndicator;
        }
        this.showProviderProcedureLoader = false;
        this._userImpressionUtil.postLandingPageImpression(result);
      },
      (error: unknown) => {
        try {
          this.showProviderProcedureLoader = false;
          this.onCategoryError('PROVIDERS_PROCEDURES', error);
        } catch (e) {}
      }
    );
  }

  getQuickLinksProviderTypes(result: IQuickLinks) {
    if (this.isHideQuickLinksShopForProcedureEnabled && Array.isArray(this._appSession?.providerTypes) && this._appSession?.providerTypes?.length) {
      this.populateProviderTypes(result.providers);
    } else if (Array.isArray(result?.availableProviders) && result?.availableProviders?.length) {
      this._appSession.providerTypes = CommonUtil.populateProviderTypes(result.availableProviders, this._appSession, true);
      this.populateProviderTypes(result.providers);
    }
  }

  getQuickLinksResponse(): Promise<IQuickLinks> {
    return this.isHideQuickLinksShopForProcedureEnabled
      ? this._quickLinksService.getQuickLinksV3List(CommonUtil.buildRuleRequest(this.searchParams, this._appSession))
      : this._procedureSvc.getQuickLinksV2List(CommonUtil.buildRuleRequest(this.searchParams, this._appSession));
  }

  populateProviderTypes(quickLinkProviders: ICodeType[]) {
    this.providerTypeList = CommonUtil.mapQuickLinkProviderTypes(quickLinkProviders);
    if (this._appSession.providerTypes.length === 1) {
      this.searchParams.typeSelectNm = this._appSession.providerTypes[0].code;
    }

    this.initCalls();
  }

  onCategoryError(type: string, error: unknown) {
    throw error;
  }

  showSpecialPrefixPopup() {
    const prefix = CommonUtil.getPrefix(this.searchParams, this._appSession);
    if (prefix && prefix.toUpperCase() === VEP) {
      this._appSession.showPrefixPopup = false;
      this.leavingDisclaimer.emit(VEP_BEH_HEALTH);
    }
  }

  onChangePlanClick() {
    if (!CommonUtil.isMemberSecure(this._appSession)) {
      this.goToPlanSelection();
    } else {
      this._changePlanService.setPlanSelectionLinkClick(true);
    }
  }

  goToPlanSelection() {
    if (this._appSession?.deeplinkParams?.hcid) {
      this._appSession.deeplinkParams = {} as IDeeplink;
      this.searchParams.plan = null;
    }
    const isPlanUnknown =
      CommonUtil.isMemberSecure(this._appSession) &&
      this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown &&
      this._appSession?.planRule &&
      !this._appSession?.planRule?.isMedicareGov;

    if (CommonUtil.isMemberSecure(this._appSession)) {
      this._appSession.isPlanUnknown = isPlanUnknown;
    }
    if (!CommonUtil.isMemberSecure(this._appSession) && !this._appSession.isPlanUnknown) {
      this.resetSearchParams();
    }
    if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.plan && this._appSession.searchParams.plan.securePlanLabelKey) {
      this.searchParams.securePlanLabelKey = this._appSession.searchParams.plan.securePlanLabelKey;
    }

    this._appSession.searchParams = this.searchParams;
    this.gotoPlanSelection.emit();
  }

  resetSearchParams() {
    this.searchParams.stateCd = '';
    this.searchParams.provNm = '';
    this.searchParams.zip = '';
    this.searchParams.typeSelectNm = '';
    this.searchParams.providerTypeCodeList = [];
    this.searchParams.specialtySelectNm = [];
    this.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this.searchParams.city = '';
    this.searchParams.coordinates = {
      latitude: '',
      longitude: ''
    };
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchOption = '';
    this._appSession.isDentalStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.filterChangePlan = {} as IFilterChangePlan;
    this._appSession.filterChangePlan.isPreviouState = false;
    this._appSession.isCaHmoDmhc = false;
    //To Be Cleared only on Cold State Change Plan
    this._appSession.planRule = {} as IPlanRule;
    this._appSession.metaData.appContract = {} as IAppContract;
  }

  onLocationError() {
    this.locationError.emit(true);
  }

  showCareToutAndLoader(data) {
    this.showCareTout = data.showCareTout;
    this.showCareTeamLoader = data.showCareTeamLoader;
  }
  openModal(event: any) {
    this.modal.emit(event);
  }

  onAnalytics(event: IAdobeSearchDetail) {
    this.analytics.emit(event);
  }

  titleCase(name: string) {
    return this._titleCasePipe.transform(name);
  }

  emitCobrandEvent() {
    //Emit PF_APP_COMMAND event to notify the Consumer Public page to refresh the logo with cobrand
    const eventDetail = {} as IEventDetail;
    const cobrandRule: ICobrandRule = this._appSession.planRule.cobrandRule;
    if (this._appSession?.metaData?.integratedBrandCode === BRAND_CODE.ABCNY) {
      cobrandRule.object.brand = BRAND_CODE.ABCNY;
    }
    eventDetail.target = cobrandRule.target;
    eventDetail.type = cobrandRule.type;
    eventDetail.message = cobrandRule.message;
    eventDetail.object = cobrandRule.object;
    this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]).emit(eventDetail);
  }

  joinMemberNameWithPlanName(planTitleContent: string, planNm: string, memberNm: string) {
    this.selectedPlanText = planTitleContent.replace(/{PLAN}/gi, planNm).replace(/{NAME}/gi, memberNm?.trim());
  }
}
