import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { PROVIDER_NAME } from '../../../../../../../common/constants/app-constants';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ActionService } from '../../../../../services/actionSvc';
import { BaseComponent } from '../../../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-anp-slider-alert-cmp',
  templateUrl: './anpSliderAlertCmp.html'
})
export class AnpSliderAlertComponent extends BaseComponent {
  modalRef: ModalRef<HTMLElement>;
  @ViewChild('assignPcpAnpModal') alertTemplateRef: TemplateRef<HTMLElement>;

  anpSliderAlertContent = this.content?.common;

  constructor(
    private _sidePanel: SidePanel,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _actionService: ActionService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to open side panel
   */
  openSidePanel() {
    this.modalRef = this._sidePanel.open('right', this.alertTemplateRef);
  }

  /**
   * Method to close side panel
   */
  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  /**
   * Method to get modal content
   * @param content - Modal content
   */
  getModalContentANP(content: string) {
    return content.replace(PROVIDER_NAME, this._appSession.selectedProvider.providerName);
  }

  /**
   * Method to handle accepting new patients click event
   */
  onNewPatientsClick() {
    this._appSession.searchParams.acceptingNewPatients = true;
    this._actionService.setSearchClick(true);
    this.modalRef.close();
  }
}
