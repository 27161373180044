export const CARE_NOW_CONTENT_EN = {
  careNowCards: [
    {
      id: 'talkToNurse247',
      title: 'Talk to a Nurse 24/7',
      descriptionOne:
        'You plan includes the 24-hour NurseLine at no out-of-pocket cost for you. Nurses are there 24 hours a day. They can help you figure out what to do or where to go when you need care.',
      descriptionTwo: '',
      goodChoiceFor: '',
      costLabel: '',
      dollarSign: '',
      averageWaitLabel: '',
      time: '',
      buttonLabel: '24-hour NurseLine',
      analyticTag: '24HrsNurseLineGcnFindCare',
      expandedLabel: 'Nurseline Details Expanded',
      collapsedLabel: 'Nurseline Details Collapsed',
      buttonAriaLabel: 'Go to Find Care to Find a Nurseline'
    },
    {
      id: 'virtualCare',
      title: 'Virtual Care',
      descriptionOne:
        'Visit with a doctor, nurse or therapist without leaving your home. Virtual care is also a great way to visit with a mental health provider. Virtual care providers often have 24/7 availability with no appointment needed.',
      descriptionTwo: 'Flu-like symptoms, allergies, anxiety, depression, fever, sinus infection, diarrhea, eye infection, skin infection, rash, urinary tract infection (UTI).',
      goodChoiceFor: 'A good choice for: ',
      costLabel: 'cost<sup>6</sup>',
      dollarSign: '$',
      averageWaitLabel: 'average wait<sup>2</sup>',
      time: '10 min',
      buttonLabel: 'Find a Virtual Care Provider',
      analyticTag: 'findVirtualCareProvGcnFindCare',
      expandedLabel: 'Virtual Care Details Expanded',
      collapsedLabel: 'Virtual Care Details Collapsed',
      buttonAriaLabel: 'Go to Find Care to Find a Virtual Care Provider'
    },
    {
      id: 'retailHealthClinic',
      title: 'Retail Health Clinic',
      descriptionOne: 'Visit your local retail clinic for flu shots or help with mild rashes, fevers, or colds. Walk-in care clinics are located in certain drugstores and major retailers.',
      descriptionTwo: 'Sore throat, earache, bumps, minor cuts and scrapes, urinary tract infection.',
      goodChoiceFor: 'A good choice for: ',
      costLabel: 'cost',
      dollarSign: '$$',
      averageWaitLabel: 'average wait<sup>3</sup>',
      time: '30 min',
      buttonLabel: 'Find a Retail Health Clinic',
      analyticTag: 'findRetailHealthClinicGcnFindCare',
      expandedLabel: 'Retail Health Clinic Details Expanded',
      collapsedLabel: 'Retail Health Clinic Details Collapsed',
      buttonAriaLabel: 'Go to Find Care to Find a Retail Health Clinic'
    },
    {
      id: 'urgentCareCenter',
      title: 'Urgent Care Center',
      descriptionOne:
        'Urgent care locations are available in many areas at all hours of the day and night. Doctors and nurses can help with non-life-threatening but urgently needed care quickly. They are typically standalone facilities with extended hours.',
      descriptionTwo: 'Sprains and strains, nausea or diarrhea, ear or sinus pain, minor allergic reactions, cough, sore throat, minor headache, urinary tract infection.',
      goodChoiceFor: 'A good choice for: ',
      costLabel: 'cost',
      dollarSign: '$$$',
      averageWaitLabel: 'average wait<sup>4</sup>',
      time: '30 min',
      buttonLabel: 'Find an Urgent Care Center',
      analyticTag: 'findUrgentCareCenterGcnFindCare',
      expandedLabel: 'Urgent Care Center Details Expanded',
      collapsedLabel: 'Urgent Care Center Details Collapsed',
      buttonAriaLabel: 'Go to Find Care to Find a Urgent Care Center'
    },
    {
      id: 'emergencyRoom',
      title: 'Emergency Room',
      descriptionOne: 'Always call 911 or go to the ER if you think you are having an emergency or if you think you could put your health at serious risk by delaying care.',
      descriptionTwo:
        'Signs of a heart attack (chest pain), stroke (sudden weakness, numbness, and difficulty with speech), difficulty breathing, bleeding, severe burns or any other symptom which may require emergency care.',
      goodChoiceFor: 'A good choice for: ',
      costLabel: 'cost',
      dollarSign: '$$$$',
      averageWaitLabel: 'average wait<sup>5</sup>',
      time: '90 min',
      buttonLabel: 'Find an Emergency Room',
      analyticTag: 'findEmergencyRoomGcnFindCare',
      expandedLabel: 'Emergency Room Details Expanded',
      collapsedLabel: 'Emergency Room Details Collapsed',
      buttonAriaLabel: 'Go to Find Care to Find a Emergency Room'
    }
  ],
  points: [
    'The care options and list of symptoms are not all-inclusive. If possible, consult your PCP for more guidance.',
    'LiveHealth Online, internal data 2020.',
    'Healthcare Finance: Patient wait times show notable impact on satisfaction scores, Vitals study shows (accessed July 2021): <a href="https://www.healthcarefinancenews.com" rel="noopener noreferrer" target="_blank" aria-label="Healthcare Finance News. Opens in a new window">healthcarefinancenews.com</a>.',
    'Urgent Care Association: UCA 2019 Benchmarking Report (accessed July 2021): <a href="https://www.ucaoa.org" rel="noopener noreferrer" target="_blank" aria-label="Urgent Care Association. Opens in a new window">ucaoa.org</a>.',
    'Harvard Business Review: To Reduce Emergency Room Wait Times, Tie Them to Payments (accessed July 2021): <a href="https://www.hbr.org" rel="noopener noreferrer" target="_blank" aria-label="Harvard Business Review. Opens in a new window">hbr.org</a>.',
    'Costs are ranked according to the member’s estimated out-of-pocket costs and average health plan copays. Each plan may have different costs. Nonemergency care outside of your network may cost more out of pocket or may not be covered at all. $ = lower cost, and $$$ = higher cost.'
  ],
  bannerTitle: 'You have options when you need care quickly',
  bannerDescription:
    "When it comes to your health, getting help fast can be really important. That's why we make it easy for you to get the care you need, when you need it. Your plan includes access to these doctors and health professionals who are ready to help you.<sup>1</sup>",
  getCareNow: 'Get Care Now'
};
export type CareNowContent = typeof CARE_NOW_CONTENT_EN;
