import { RESULT_CONTENT_EN } from './resultContent';

//  Define a type based on the structure of the English content
export type ResultContent = typeof RESULT_CONTENT_EN;

// Define the Spanish content for the home page, ensuring each key in the English content has a corresponding key in the Spanish content
export const RESULT_CONTENT_ES: ResultContent = {
  title: 'Resultados',
  result: {
    labels: {
      viewMap: 'Ver mapa',
      viewList: 'Ver lista',
      hideMap: 'Ocultar mapa',
      results: 'Resultados de la búsqueda'
    }
  },
  provider: {
    callAriaLabel: 'Llamar',
    phone: 'Presiona Enter para llamar a este médico',
    specialtiesFor: 'Especialidades para ',
    costEstimate: 'Estimación de Costos: '
  },
  onSiteClinicModal: {
    close: 'Cerrar',
    back: 'Volver',
    officeLocation: 'Ubicación',
    getDirections: 'Cómo llegar',
    contactInfo: 'Datos de Contacto',
    hoursOfOperations: 'Horas de Operaciones',
    additionalInfo: 'Información Adicional',
    specialties: 'Especialidades',
    procedures: 'Procedimientos',
    providerType: 'Tipo de Proveedor'
  },
  searchEmailComponent: {
    emailSearchHeader: 'Enviar resultados de búsqueda por correo electrónico',
    emailSearchNote: 'Ten en cuenta: este PDF solo incluye hasta los primeros 100 proveedores de tus resultados de búsqueda. Esta no es una lista de todos los proveedores.',
    emailSearchMessage: 'Mensaje: Resultados de la búsqueda de proveedores del {provDate}'
  },
  providerMap: {
    searchThisArea: 'Buscar en esta área',
    recenterMapAltText: 'Recentrar mapa',
    zoomInAltText: 'Acercar',
    zoomOutAltText: 'Alejar',
    chatLogoAltText: 'Logo de chat',
    careProvidersAtThisLocation: 'Proveedores de cuidado médico en esta ubicación',
    noMapAvailableForvirtualCare: 'Mapa no disponible para proveedores de cuidado médico virtual.'
  },
  memberPlanProgress: {
    remaining: 'restante',
    available: '/ Disponible',
    spent: 'Gastado',
    limit: '/ Límite',
    errorDesc: 'Tenemos un problema para mostrar tus datos aquí.<br>Si tienes preguntas,<a class="ant-font-b" target="_blank" href=""> habla con un agente en vivo.</a>',
    spendingAccntInfo: 'La información de la cuenta de gastos no está disponible.',
    planProgressInfo: 'La información del progreso del plan no está disponible.',
    vendorAdminstratedAccountInfo:
      '<div> HealthEquity le ayuda a administrar su {account}.<br><a class="ant-font-b" target="_blank" href="{vendorUrl}" aria-label=\'manage my account\'> Manejar mi cuenta <img class=\'bh-non-crisis-image-padding arrowExternal-image\'/></a> <div>'
  },
  aria_label: {
    previousClick: 'Clic izquierdo',
    nextClick: 'Clic derecho',
    progressBar: 'Barra de progreso',
    dismissAlert: 'Descartar alerta'
  },
  dollarCptCostColumn: {
    estimatedBill: 'Permitido por el plan ',
    planPays: 'El plan paga',
    memberResponsibility: 'Lo que podrías pagar '
  },
  cptMedicalSearchComponent: {
    hraBalance: ' saldo de cuenta HRA de ',
    errorScenarios: {
      noResultsMessage:
        '<span class="ant-font-b">No hay resultados.</span> Pruebe con un código postal diferente o usa &quot;Volver a Encontrar cuidado médico &quot; para comenzar de nuevo. <a target="_blank" href="/member/contact-us">Contáctanos</a> si tienes preguntas sobre los beneficios de tu plan.',
      noResultsIncreasedRadiusMessage:
        '<span class="ant-font-b">No hay resultados.</span> Hemos ampliado tu distancia de búsqueda a <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span> pero no encontramos ningún resultado. <a target="_blank" href="/member/contact-us">Contáctanos </a> si tienes preguntas sobre los beneficios de tu plan.',
      increasedRadiusMsgNew:
        'Aumentamos la distancia de búsqueda a <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span> para <span class="ant-font-b">{USR} en </span><span class="ant-font-b">{PLN}</span> con un deducible restante de ',
      isBenefitDefinedScenario1: 'No se pueden mostrar algunos datos de costos',
      isBenefitDefinedScenario2: 'No se pueden mostrar los datos de costos',
      planNotCovered: 'Tu plan no cubre este procedimiento. <a target="_blank" href="/member/contact-us">Contáctanos</a> si tienes preguntas sobre los beneficios de tu plan.',
      planNotCoveredAriaLabel: 'Tu plan no cubre este procedimiento. Contáctanos si tienes preguntas sobre los beneficios de tu plan.',
      costInfoIsPlanNotCoveredScenario: [
        {
          id: 'cost-info-isplannotcovered-scenario',
          orderSequence: '1',
          displayText: 'Tu plan no cubre este procedimiento. {linkLabel} si tienes preguntas sobre los beneficios de tu plan.',
          ariaLabel: 'Tu plan no cubre este procedimiento. {linkLabel} si tienes preguntas sobre los beneficios de tu plan.',
          isAnchorText: true,
          inAppNavigation: true,
          mobileInAppNavigationUrl: 'CONTACT_US_OVERVIEW',
          navigationUrl: '',
          linkLabel: 'Contáctanos'
        }
      ]
    }
  },
  cptTooltips: {
    posCodes: 'Un procedimiento o servicio puede tener diferentes costos dependiendo del lugar donde se realice.',
    modifierCodes: 'Un procedimiento o servicio puede tener variaciones que afectan los costos.',
    allowedByPlan:
      'Lo máximo que tu plan pagaría por este servicio. Este monto es una estimado y puede cambiar según los beneficios de tu plan y el tipo de proveedor de cuidado médico que presenta la reclamación.'
  },
  percentageCptCostColumn: {
    allowedByPlan: 'Permitido por el plan',
    youPay: 'Lo que podrías pagar ',
    costDetail: 'Detalles de costos'
  },
  cptSlideModalContent: {
    missToothClauseInd: 'This service/procedure is subject to a Missing Tooth Clause. Check with the Provider on current eligibility.',
    close: 'Cerrar',
    closeCptSlideModalContent: 'close cpt slide out modal',
    aboutThisProcedureOrService: 'About This Procedure or Service',
    viewAll: {
      text: 'Important alerts about your search results.',
      link: 'View all'
    },
    tabNames: {
      summary: 'Resumen',
      outOfPricing: 'Precios fuera de la red',
      alerts: 'Alertas',
      notice: 'Avisos'
    },
    summaryTab: {
      description: {
        label: 'Descripción',
        content:
          'Ultrasound, limited, joint or other nonvascular extremity structure(s), (e.g., joint space, peri-articular tendon(s), muscles(s), nerve(s), other soft tissue structure, or soft tissue mass(es), real-time with image documentation.'
      },
      secondDescription: {
        label: 'Búsqueda por código médico versus por procedimiento',
        content:
          'cuando buscas por código médico, los estimados de los costos son solo para ese código. Pero cuando visitas a un médico o te sometes a un procedimiento médico, tu tratamiento puede incluir varios códigos y cargos tanto del médico como del centro médico. Buscar por procedimiento (por ejemplo, colonoscopia o reemplazo de rodilla) en lugar de por código médico probablemente te dará el mejor estimado de los costos generales. Antes de programar una cita, consulta con este proveedor de cuidado médico para confirmar que realiza este procedimiento o servicio.'
      },
      serviceLimits: {
        heading: 'Service Limits',
        content: "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost.",
        contentAdditionalLimits:
          "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost. This service has other limits. You can find more information about your plan's service limits in <a class=\"go-to-chat\" target='_blank' href=\"/member/benefits?covtype=med\" data-analytics= 'myPlansProcCodeModalFindCare'>My Plans</a>",
        showServiceLimitContent:
          'Tu plan limita el monto cubierto o la cantidad de veces al año que recibes este servicio. Si superas el límite, necesitarás cubrir el costo. Puede haber otros límites de servicio además de los que se muestran aquí. Revisa tus beneficios antes de programar una cita.',
        limit: '<strong>Limit</strong>',
        serviceLimitValue: '{Limits} {LimitUnitDescription} <strong>{LimitsRemaining}</strong>',
        visitsTotalAndUsed: {
          paragraph1: [
            {
              label: '{TotalVisits} visits (',
              type: 'normal'
            },
            {
              label: '{UsedVisits} used ',
              type: 'normal'
            },
            {
              label: ')',
              type: 'normal'
            }
          ]
        },
        viewMore: 'Ver más',
        viewLess: 'Ver menos'
      },
      preapproval: {
        heading: 'Se requiere aprobación previa',
        content:
          'La aprobación previa, a veces denominada "precertificación", "preautorización" o "autorización previa", es el proceso que se utiliza para confirmar si un procedimiento es médicamente necesario. Excepto en una emergencia, la aprobación previa generalmente debe ocurrir antes de recibir el tratamiento. El médico que programa una admisión u ordena el procedimiento generalmente es responsable de obtener la aprobación previa de la compañía de seguros. No obtener la aprobación previa cuando se requiere puede resultar en la denegación del pago de una reclamación.'
      }
    },
    alertTab: {
      alerts: 'Notices',
      preventiveCareAlertMessage: 'If the purpose of this procedure or service is preventive care, and you use an in-network care provider, your share of the costs may be as low as $0.'
    },
    outOfNetworkTab: {
      oonStaticContent:
        "<strong>Coverage Outside of Your Plan's Network</strong><br><br/><p>Healthcare professionals and facilities not contracted with your health plan are considered out-of-network care providers. They can charge you an amount generally higher than what in-network providers charge. And, since the provider is out of network, you typically have to file your own claims and request preapprovals, if needed.</p><p>An out-of-network provider may bill you for the difference between what your plan has agreed to pay and the full amount charged for a service. This is called <strong>balance billing</strong>.</p><p>For example, suppose the provider charges <strong>$10,000 for a service</strong>, and the allowed by plan amount is <strong>$6,000</strong>. The provider may also bill you for the remaining <strong>$4,000</strong>.</p>",
      estimatedCostSharesHeading: 'Estimated Cost Shares',
      noOonCostShareDataMessage:
        'We\'re not able to show the estimated cost shares for this Facturación code.  If you have questions about your plan\'s benefits, please <a target="_blank" href="/member/contact-us" data-analytics=\'contactUsOutNetworkModalFindCare\'>contact us.</a>',
      planNotCoveredMessage:
        "Your plan doesn't offer out-of-network coverage for this procedure. This means you'll be responsible for 100% of non-emergency costs if you use an out-of-network care provider.",
      costInfoRadioButton: {
        professional: 'Professional Cost',
        institutional: 'Facility Cost'
      },
      costInfoRadioButtonAnalytics: {
        professional: 'profProcCodeModalFindCare',
        institutional: 'facilityProcCodeModalFindCare'
      },
      billFrom: {
        label: 'Bill From: ',
        value: {
          professional: 'Doctor o profesional de la salud',
          institutional: 'Hospital o Instalación'
        }
      },
      OutOfNetworkCostEstimateContent: {
        introContent:
          "These amounts are estimates for a single procedure or service. The amounts could vary based on your plan's benefits and what the care provider decides to bill. The following estimated cost shares also take into account:",
        costContent: {
          estimatedBill: {
            name: 'Allowed by Plan',
            description: "The most your plan would pay for this service. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
          },
          appliedDeductible: {
            name: 'Applied to Out-of-Network Deductible',
            description:
              'The amount that would count toward your deductible, which is the amount you pay for health services before your plan starts sharing the costs. The deductible you owe may be less based on the allowed-by-plan amount. Keep in mind that your out-of-network deductible is higher than your in-network deductible.'
          },
          coinsurance: {
            name: 'Coinsurance',
            description:
              "The amount you'd pay for a covered service after you’ve met your deductible. Your coinsurance is a percentage of the allowed-by-plan amount. Usually, that percentage is higher for out-of-network care than for in-network care."
          },
          copay: {
            name: 'Copay',
            description: "The flat-dollar amount you'd pay for certain health care services. This amount is what you'd pay each time this service is delivered."
          }
        },
        costContentForPercentage: {
          estimatedBill: {
            name: 'Allowed by Plan',
            description:
              "The allowed-by-plan amount is a percentage of the billed charges, which could vary. This amount is an estimate and may change based on your plan's benefits and the type of care provider submitting the claim."
          }
        },
        professionals: 'Professionals: ',
        facilities: 'Facilities: ',
        selected: 'Selected',
        notSelected: 'Not Selected'
      },
      oonPricingStaticContent: {
        paragraph1: [
          {
            label: 'Cobertura fuera de la red de su plan',
            type: 'bold'
          }
        ],
        paragraph2: [
          {
            label:
              'Los profesionales de la salud y los centros médicos que no tienen contrato con tu plan de salud se consideran proveedores de cuidado médico fuera de la red. Pueden cobrarte una cantidad generalmente más alta que la que cobran los proveedores dentro de la red. Y, dado que el proveedor está fuera de la red, normalmente tiene que presentar sus propias reclamaciones y solicitar aprobaciones previas, si es necesario.',
            type: 'normal'
          }
        ],
        paragraph3: [
          {
            label: 'Un proveedor fuera de la red puede facturarte la diferencia entre lo que tu plan acordó pagar y el monto total cobrado por un servicio. Esto se llama ',
            type: 'normal'
          },
          {
            label: 'facturación de saldo.',
            type: 'bold'
          }
        ],
        paragraph4: [
          {
            label: 'Por ejemplo, supón que el proveedor cobra ',
            type: 'normal'
          },
          {
            label: '$10,000 por un servicio ',
            type: 'bold'
          },
          {
            label: 'y el monto permitido por el plan es de ',
            type: 'normal'
          },
          {
            label: '$6000.',
            type: 'bold'
          },
          {
            label: 'El proveedor también puede facturarte los ',
            type: 'normal'
          },
          {
            label: '$4,000 restantes',
            type: 'bold'
          }
        ]
      }
    }
  },
  cptCostEstimateSidePanelContent: {
    serviceLimits: {
      heading: 'Service Limits',
      content: "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost.",
      contentAdditionalLimits:
        "Your plan limits the number of times or the amount covered when you receive this service. If you go over the limit, you'll need to cover the cost. This service has other limits. You can find more information about your plan's service limits in <a class=\"go-to-chat\" target='_blank' href=\"/member/benefits?covtype=med\" data-analytics= 'myPlansProcCodeModalFindCare'>My Plans</a>",
      showServiceLimitContent:
        'Tu plan limita el monto cubierto o la cantidad de veces al año que recibes este servicio. Si superas el límite, necesitarás cubrir el costo. Puede haber otros límites de servicio además de los que se muestran aquí. Revisa tus beneficios antes de programar una cita.',
      limit: '<strong>Limit</strong>',
      serviceLimitValue: '{Limits} {LimitUnitDescription} <strong>{LimitsRemaining}</strong>',
      visitsTotalAndUsed: {
        paragraph1: [
          {
            label: '{TotalVisits} visits (',
            type: 'normal'
          },
          {
            label: '{UsedVisits} used ',
            type: 'normal'
          },
          {
            label: ')',
            type: 'normal'
          }
        ]
      }
    },
    orthProcIndTrue:
      ' <strong>Importante</strong> - esta es la información más actualizada según las reclamaciones procesadas. Los beneficios están sujetos a los términos, limitaciones y exclusiones de su plan dental. Su plan cubre los servicios de ortodoncia médicamente necesarios, con limitaciones contractuales específicas. La elegibilidad y los beneficios se evalúan mensualmente. Si el tratamiento continúa después de las fechas de elegibilidad, no habrá más pagos disponibles.',
    orthProcIndFalse:
      ' <strong>Importante</strong> - esta es la información más actualizada según las reclamaciones procesadas. Los beneficios están sujetos a los términos, limitaciones y exclusiones de su plan dental. Su plan cubre estos servicios dentales, con limitaciones contractuales específicas. Si se han excedido estas limitaciones contractuales, necesitará cubrir el costo.',
    heading: 'Costos compartidos estimados',
    introContent:
      "Estos montos son estimados para un solo procedimiento o servicio. También tomamos en cuenta los beneficios de tu plan y las tarifas de los proveedores de cuidado médico en tu área.\nTen en cuenta que tu tratamiento puede incluir muchos códigos. Cada código puede incluir cargos tanto de un médico como de un centro médico. Otros factores pueden afectar tu costo real, incluso si tu plan tiene una red 'por niveles' y si tu cuidado médico incluye atención primaria o atención especializada. Es posible que no podamos mostrar tu responsabilidad de costos compartidos por los cargos del centro médico para todos los procedimientos y servicios. Lo que pagas también se ve afectado por:",
    whatYouCouldPay: 'Estimación del costo de la visita al consultorio',
    memberResponsibilityDesc:
      'El monto que adeudarías después de que se hayan aplicado los descuentos, ahorros y pagos del plan. Este monto también tiene en cuenta cuánto te queda por pagar de <a target="_blank" href="">tu deducible y el máximo de gastos de bolsillo.</a>',
    officeVisitIntroContent:
      'Aquí está el desglose de costos para una <span class="ant-font-b">visita al consultorio para un paciente actual que dura entre 30 y 39 minutos.</span> Recuerde que estos montos son estimados basadas en los beneficios de tu plan. Tu costo real puede ser diferente. Los análisis de laboratorio, las radiografías y otras pruebas o procedimientos durante tu visita también podrían aumentar tus costos.',
    costContent: {
      estimatedBill: {
        name: 'Permitido por el plan',
        description:
          'Lo máximo que tu plan pagaría por este servicio. Este monto es una estimado y puede cambiar según los beneficios de tu plan y el tipo de proveedor de cuidado médico que presenta la reclamación.'
      },
      planPays: {
        name: 'El plan paga',
        description: 'El monto que estaría cubierto por tu plan para este servicio. Este monto se calcula aplicando cualquier copago, deducible y coseguro al monto permitido por el plan.'
      },
      appliedDeductible: {
        name: 'Aplicado al deducible dentro de la red',
        description:
          'El monto que contaría contra tu deducible, que es el monto que pagas por los servicios de salud antes de que tu plan comience a compartir los costos. El deducible que adeudas puede ser menor según el monto permitido por el plan.'
      },
      coinsurance: {
        name: 'Coseguro',
        description: 'El monto que pagarías por un servicio cubierto después de alcanzar tu deducible. Tu coseguro es un porcentaje del monto permitido por el plan.'
      },
      copay: {
        name: 'Copago',
        description: 'El monto fijo en dólares que pagarías por ciertos servicios médicos.'
      },
      memberResponsibility: {
        name: 'Lo que podrías pagar',
        description:
          'El monto que adeudarías después de que se hayan aplicado los descuentos, ahorros y pagos del plan. Este monto también depende de cuánto queda por pagar del máximo de tus gastos de bolsillo y el deducible. (Para ver el progreso de tus gastos de bolsillo y deducible, ve a Mis planes > Beneficios médicos > Progreso del plan).'
      }
    },
    costContentForPercentage: {
      estimatedBill: {
        name: 'Permitido por el Plan ',
        description:
          'El monto permitido por el plan es un porcentaje de los cargos facturados, que puede variar. Este monto es un estimado y puede cambiar según los beneficios de tu plan y el tipo de proveedor de cuidado médico que presenta la reclamación.'
      }
    },
    medicalCodeContent: {
      posCd: 'Dónde recibes cuidado médico: ',
      modifierCd: 'Opción de código Facturación: '
    }
  },
  costDetailSlideModalContent: {
    costEstimateProcedure: 'Estimación de costos para el procedimiento',
    whatYouCouldPay: 'Lo que podrías pagar',
    costEstimate: 'Estimación de Costos',
    costDetails: 'Detalle de costos',
    closeCostDetailsModal: 'Cerrar ventana de Detalle de costos',
    headerText1: 'Así es como llegamos al monto de Estimación de Costos. Ten en cuenta que estos montos son estimados basadas en los beneficios de tu plan. Tu costo real puede ser diferente.',
    headerText2: '<a class="go-to-chat" target="_blank" href="/member/benefits?covtype={{COVERAGE}}">Revisa los detalles de tu cobertura</a> para ayudar a evitar costos inesperados.',
    estimatedBillDescription: 'El monto promedio que este proveedor de cuidado acordó facturar a alguien con tu plan por este servicio.',
    averageBillDescription: 'El monto promedio que este proveedor de cuidado médico acordó facturar por este servicio en tu plan.',
    hraPaysDescription: 'El monto que sería pagado por tu Cuenta de Reembolso de Gastos Médicos.',
    hsaPaysDescription: 'El monto que sería pagado por tu Cuenta de Ahorros para la Salud.',
    planPaysDescription: 'El monto que estaría cubierto por tu plan para este servicio.',
    appliedDeductibleDescription: 'El monto que contaría para tu deducible, que es el monto que pagas por los servicios médicos antes de que tu plan comience a compartir los costos.',
    coinsuranceDescription: 'El monto que pagarías por un servicio cubierto después de alcanzar tu deducible. Tu coseguro es un porcentaje del monto permitido por el plan.',
    copayDescription: 'El monto fijo en dólares que pagarías por ciertos servicios médicos.',
    youPayDescription:
      'El monto que adeudarías después de que se hayan aplicado los descuentos, ahorros y pagos del plan. Este monto también depende de cuánto queda por pagar del máximo de tus gastos de bolsillo y el deducible. (Para ver el progreso de tus gastos de bolsillo y deducible, ve a Mis planes > Beneficios médicos > Progreso del plan).',
    close: 'Cerrar',
    notAvailable: 'No disponible',
    noCost: 'Costo estimado no disponible',
    noBenefit: 'Tu plan no cubre este procedimiento.',
    cdhpError: 'Los fondos de tu cuenta {{ACCOUNTTYPE}} podrían reducir el monto que pagas.'
  },
  eyc: {
    noData: 'No datos',
    cdhpErrorText: 'Los fondos de tu cuenta de gastos podrían reducir la cantidad que pagas.',
    cdhpError: 'Lo sentimos, pero no podemos obtener tu saldo {ACCOUNTTYPE} en este momento. {ACCOUNTTYPE} podrían reducir la cantidad que pagas.',
    youPay: 'Lo que pagas',
    estimatedCost: 'Factura estimada',
    estimatedFacilityTHCCost: 'Las estimaciones para esta instalación pueden ser tan altas como:',
    estimatedFacilityTLCCost: 'Las estimaciones para esta instalación pueden ser tan bajas como:',
    estimatedDoctorTHCCost: 'Las estimaciones para este médico pueden ser tan altas como:',
    estimatedDoctorTLCCost: 'Las estimaciones para este médico pueden ser tan bajas como:',
    noCostDataText: 'No podemos mostrar una estimado de costos para este procedimiento.',
    coPayText: 'Copago',
    coInsuranceText: 'Coseguro',
    overageChargeText: 'Exceso',
    deductibleText: 'Aplicado al deducible',
    planPaysText: 'El plan paga',
    oopNotAvailableText: 'Los montos de El plan paga y Lo que tú pagas no están disponibles.',
    isBenefitDeniedMsg: '¡Lo sentimos! Tu plan no cubre este procedimiento.',
    totalEstimateCostText: 'Factura estimada',
    totalText: 'Est Total',
    averageCostText: 'Precio promedio',
    dentalAverageHover:
      'El monto estimado que este proveedor de cuidado médico puede facturar si este servicio está cubierto por tu plan. Esto también se conoce como el Monto permitido. Tus costos de bolsillo dependerán de los beneficios, el coseguro y el deducible de tu plan (si corresponde).',
    rangeCountHover: 'La cantidad que paga puede diferir en cada hospital. Ver las estimaciones a continuación.',
    noCostHover: 'El costo total podría <br>diferir en cada hospital. <br> Ver las estimaciones a continuación.',
    costRange: 'Factura estimada para',
    costCDHPPaysText: 'La cuenta {ACCOUNTTYPE} paga',
    costBeforeCDHPBalance: 'Tus costos antes de usar los fondos de la cuenta {ACCOUNTTYPE}:',
    for: 'por',
    near: 'cerca',
    costRangeNew: 'Factura estimada para {PROCEDURENAME}: <span class="ant-font-b">{COSTRANGE}</span>',
    costRangeToolTipMsg:
      'Este es el rango de montos de facturas estimados para los que tenemos datos de costos. La factura estimada es el monto promedio que un proveedor de cuidado ha acordado facturar por este servicio en tu plan.',
    forNew: 'por <span class="ant-font-b">{MEMBER}</span> ',
    nearNew: 'cerca <span class="ant-font-b">{ZIP}</span>',
    averagePay: 'Factura promedio',
    hideAffiliation: 'Hide cost estimates at different hospitals',
    showAffiliation: 'View cost estimates at different hospitals',
    name: 'Name',
    spendingAccntInfo: 'Account Balance',
    youpaytooltip: 'Seleccione para obtener su información de pago detallada',
    showPrices: 'Mostrar más precios',
    hidePrices: 'Ocultar más precios',
    ariaLabel: {
      showPrices: 'Seleccione enter para mostrar más precios.',
      hidePrices: 'Seleccione enter para ocultar más precios.'
    },
    selectforMoreInfo: 'selecciona para mas informacion de',
    arialLblEsitmateBillHeader: 'Selecciona para mas informacion de Factura estimada para {PROCEDURENAME}',
    arialLblEsitmateCostHeader: 'Selecciona para mas informacion de costos Estimada para {PROCEDURENAME}',
    coldStateCostRangeTooltipMsg:
      'Esta estimado de costos está basado en reclamaciones para este procedimiento que nos envían los proveedores de cuidado médico en tu área. Los miembros deben registrarse e iniciar sesión para obtener una estimado más exacto, incluidos los montos de los gastos de bolsillo según sus planes.',
    costRangeColdState: 'costos Estimada para {PROCEDURENAME}: <span class="ant-font-b">{COSTRANGE}</span>'
  },
  healthwiseModal: {
    tabs: {
      summary: 'Resumen',
      notices: 'Notices'
    },
    close: 'Cerrar',
    closeHealthwiseModal: 'close healthwise modal',
    emptyRepsonseAlertMssg: 'Lo sentimos. Todavía no tenemos una explicación de este término.',
    failureAlertMssg: 'Lo sentimos. Normalmente, explicaríamos este término aquí, pero nuestro sistema no está funcionando correctamente en este momento. Intenta más tarde.'
  },
  summaryComponent: {
    aboutYourSearch: 'Acerca de su búsqueda',
    noResults: 'No hay resultados',
    notFindingFilterResults: '¿No encuentras lo que buscas?',
    tryChangeFilter: 'Intenta cambiando los filtros de búsqueda.',
    learnMoreLabel: 'Más información',
    moreDetails: 'Seleccione Enter para obtener detalles del proveedor',
    pageText: 'Página',
    firstPageText: 'Primera página',
    lastPageText: 'Última página',
    previousPageText: 'Página anterior',
    nextPageText: 'Siguiente página',
    callAriaLabel: 'Llamar',
    providingCareAt: 'Brindando cuidado médico en',
    performedBy: 'Realizado por',
    countyText: 'Condado: ',
    viewInfo: 'Ver información importante sobre esta herramienta',
    miles: 'millas',
    getDirections: 'Cómo llegar',
    beFirstReview: 'Se el primero en Calificar',
    reviewThisDoctor: 'Califica este Médico',
    addReview: 'Agregar una reseña',
    noRating: 'No hay calificaciones disponibles',
    noReview: 'No Calificar disponibles',
    beFirstReviewDataAnalytics: 'beTheFirstToReviewResults',
    reviewThisDoctorDataAnalytics: 'reviewThisDoctorResults',
    mapmarker: 'selecciona para acceder a las direcciones en un mapa',
    phone: 'Presiona Enter para llamar a este médico',
    phoneNumber: 'Numero telefonico:',
    drivingDirectionAddrPlaceholder: 'con dirección',
    drivingTime: 'Tiempo de conducción ',
    filterChange: ' o refina sus resultados de búsqueda usando cualquier combinación de los filtros a continuación, luego selecciona <strong>Aplicar</strong>.',
    seeAll: 'Ver todo',
    morePCP: 'Seleccione enter para ver los detalles de PCP',
    viewMoreAddress: 'Ver más direcciones',
    noDetails: 'Detalles no disponibles',
    viewMoreAddrAriaLbl: 'Seleccione enter para ver más detalles de la dirección',
    hoursToday: 'Horas hoy',
    open24Hours: 'Abierto las 24/7',
    servingCounty: 'Condado',
    servingText: 'Servicio',
    medicalNetwork: 'Plan médico dentro de la red',
    visionNetwork: 'Plan de visión dentro de la red',
    dentalNetwork: 'Plan dental dentro de la red',
    liveHealthText: 'Tus beneficios incluyen la opción de consultar a un médico 24/7 a través de LiveHealth Online.',
    liveHealthLink: 'Consulta a un médico ya o programa una cita.',
    compareHintMsg: 'Selecciona de 2 a 3 elementos para comparar ',
    compareLabel: 'Comparar',
    selectedProviderCountLabel: 'seleccionado de 3',
    addressNotAvailable: 'Dirección no disponible',
    recognitionAdobeTag: 'recognitionsLinkResultsFindCare',
    providerRecognitionTab: 'providerTabRecognitionsModalFindCare',
    facilityRecognitionTab: 'facilityTabRecognitionsModalFindCare',
    resultsLabel: 'resultados',
    sortByDrivingDistance: 'ordenados por distancia en auto',
    pinLabel: 'Haz clic en un pin para ver detalles de los proveedores',
    providerExperienceText: 'Realizado',
    providerExperienceToolTip: 'Con base en las reclamaciones de los miembros, sabemos que este médico ha realizado este procedimiento al menos {experience} veces durante el último año.',
    onsiteClinicText: 'Tus beneficios incluyen cuidado de esta clínica.',
    pgmCardAriaLabel: 'haga clic aquí para navegar a {PROGRAM_NAME}',
    backToPgmCardsList: 'Volver a Relacionados con tu búsqueda',
    onsiteClinicPhone: ' Llama al {phone}.',
    onsiteClinicWeb:
      " <a target=\"_blank\" href=\"{weburl}\" id='lnk-clinic' class='ONSITECLINICS' aria-label='Haz clic aquí para navegar al sitio web de la clínica en el lugar de trabajo'>Visita el sitio web.</a><span class='fa fa-external-link gray'></span>",
    topChoiceLabel: 'LAS MEJORES OPCIONES PARA TI',
    topChoiceTooltipSydMedicaidLearnMore:
      'Al usar la clasificación de la Fase 1 de la Coincidencia personalizada, estas son las tres mejores opciones para ti.<a target="_blank" href="{pdfUrl}" aria-label=\'haz clic aquí para navegar a Más información\' data-analytics=\'provDetailsTopChoiceResultsFindCare\'> Más información</a>',
    topChoiceTooltipSydMedicaidLearnMoreNJ:
      'Al usar la clasificación de la de la Coincidencia personalizada, estas son las tres mejores opciones para ti.<a target="_blank" href="{pdfUrl}" aria-label=\'haz clic aquí para navegar a Más información\' data-analytics=\'provDetailsTopChoiceResultsFindCare\'> Más información</a>',
    topChoiceTooltipSydMedicaid: 'Al usar la clasificación de la Fase 1 de la Coincidencia personalizada, estas son las tres mejores opciones para ti.>',
    topChoiceTooltipSydMedicaidNJ: 'Al usar la clasificación de la de la Coincidencia personalizada, estas son las tres mejores opciones para ti.>',
    topChoiceTooltip:
      'De acuerdo con nuestra clasificación de coincidencias personalizadas, estas son las tres mejores opciones para ti. La coincidencia personalizada tiene en cuenta tus condiciones de salud, medidas de calidad y costo. <a target="_blank" href="{pdfUrl}" aria-label=\'haz clic aquí para navegar a Más información\' data-analytics=\'provDetailsTopChoiceResultsFindCare\'> Más información</a>',
    moreOptionsLabel: 'MAS OPCIONES',
    moreOptionsAriaLabel: 'Selecciona para obtener más información sobre Más opciones',
    spfiDisclaimerHoverText:
      'Los profesionales de la salud dentro de la red pueden quedar excluidos de la coincidencia personalizada por razones como restricciones contractuales, falta de datos o una ubicaciÃ³n fuera de nuestra Ã¡rea de servicio. <a target="_blank" href="{pdfUrl}"> MÃ¡s informaciÃ³n sobre la coincidencia personalizada.</a>',
    topChoiceAriaLabel: 'Selecciona para obtener más información sobre las Mejores opciones para ti',
    moreOptionsTooltipSydMedicaidLearnMore:
      'Hemos ordenado estos resultados de búsqueda utilizando la Fase 1 de la Coincidencia personalizada para ayudarte a encontrar proveedores que sean adecuados para ti según las medidas de calidad y mejor valor. En el futuro, la Coincidencia personalizada tendrá en cuenta tus condiciones de salud. Puedes reordenar estos resultados de búsqueda por distancia o nombre. <a target="_blank" href="{pdfUrl}" aria-label=\'haz clic aquí para navegar a Más información\' data-analytics=\'provDetailsMoreOptionsResultsFindCare\'> Más información</a>',
    moreOptionsTooltipSydMedicaidLearnMoreNJ:
      'Hemos ordenado estos resultados de búsqueda utilizando la de la Coincidencia personalizada para ayudarte a encontrar proveedores que sean adecuados para ti según las medidas de calidad y mejor valor. En el futuro, la Coincidencia personalizada tendrá en cuenta tus condiciones de salud. Puedes reordenar estos resultados de búsqueda por distancia o nombre. <a target="_blank" href="{pdfUrl}" aria-label=\'haz clic aquí para navegar a Más información\' data-analytics=\'provDetailsMoreOptionsResultsFindCare\'> Más información</a>',
    moreOptionsTooltipSydMedicaid:
      'Hemos ordenado estos resultados de búsqueda utilizando la Fase 1 de la Coincidencia personalizada para ayudarte a encontrar proveedores que sean adecuados para ti según las medidas de calidad y mejor valor. En el futuro, la Coincidencia personalizada tendrá en cuenta tus condiciones de salud. Puedes reordenar estos resultados de búsqueda por distancia o nombre.',
    moreOptionsTooltipSydMedicaidNJ:
      'Hemos ordenado estos resultados de búsqueda utilizando la de la Coincidencia personalizada para ayudarte a encontrar proveedores que sean adecuados para ti según las medidas de calidad y mejor valor. En el futuro, la Coincidencia personalizada tendrá en cuenta tus condiciones de salud. Puedes reordenar estos resultados de búsqueda por distancia o nombre.',
    moreOptionsTooltip:
      'Hemos ordenado estos resultados en función de tus condiciones de salud, medidas de calidad y costo para ayudarte a encontrar un proveedor de cuidado médico que sea adecuado para ti. <a target="_blank" href="{pdfUrl}" aria-label=\'haz clic aquí para navegar a Más información\' data-analytics=\'provDetailsMoreOptionsResultsFindCare\'> Más información</a>',
    atLabel: 'en ',
    forLabel: 'por ',
    mapLabel: 'En el mapa',
    virtualOptions: 'Opciones virtuales',
    virtualOptionsInfo: 'Proveedores de cuidado que aparecen en tus resultados de búsqueda pero que solo se ofrecen en línea',
    officeLocation: 'Ubicación',
    adaAccessibleTitle: 'Accesible según la ADA:',
    personalizedMatch: {
      personalizedMatchTopChoice: 'Coincidencia personalizada: la mejor opción',
      whatIsPersonalizedMatch: '¿Qué es coincidencia personalizada?',
      whatIsPersonalizedMatchSydMedicaid: '¿Qué es coincidencia personalizada 1?',
      personalizedMatchMsg:
        'Hemos ordenado estos resultados de búsqueda utilizando la coincidencia personalizada para ayudarte a encontrar proveedores de cuidado médico que sean adecuados para ti. La coincidencia personalizada tiene en cuenta tus condiciones de salud, medidas de calidad y costo. Siempre puedes volver a ordenar estos resultados de búsqueda por distancia o nombre.',
      personalizedMatchMsgSydMedicaid:
        'Hemos ordenado estos resultados de búsqueda utilizando la Fase 1 de la Coincidencia personalizada para ayudarte a encontrar proveedores que sean adecuados para ti según las medidas de calidad y mejor valor. En el futuro, la Coincidencia personalizada tendrá en cuenta tus condiciones de salud. Puedes reordenar estos resultados de búsqueda por distancia o nombre. Nuestra herramienta de clasificación del desempeño de proveedores utiliza puntajes de calidad y eficiencia para ayudarte a tomar decisiones informadas sobre tu cuidado médico. Puedes reordenar estos resultados de búsqueda por distancia o nombre.',
      personalizedMatchMsgSydMedicaidNJ:
        'Hemos ordenado estos resultados de búsqueda utilizando la de la Coincidencia personalizada para ayudarte a encontrar proveedores que sean adecuados para ti según las medidas de calidad y mejor valor. En el futuro, la Coincidencia personalizada tendrá en cuenta tus condiciones de salud. Puedes reordenar estos resultados de búsqueda por distancia o nombre. Nuestra herramienta de clasificación del desempeño de proveedores utiliza puntajes de calidad y eficiencia para ayudarte a tomar decisiones informadas sobre tu cuidado médico. Puedes reordenar estos resultados de búsqueda por distancia o nombre.',
      personalizedMatchPdfLink: 'https://findcare.anthem.com/assets/documents/personalizedprovidersearch_es.pdf',
      learnMore: ' Más información',
      learnMoreAriaLabel: 'Más información sobre coincidencia personalizada.PDF',
      personalizedMatchPdf: {
        snr: 'explanation_of_personalization_commercial_medicare_snr_es_US.pdf',
        commercial: 'explanation_of_personalization_commercial_medicare_es_US.pdf',
        medicaid: {
          explanation_of_personalization_medicaid_DC: 'explanation_of_personalization_medicaid_DC_es_US.pdf',
          explanation_of_personalization_medicaid_GA: 'explanation_of_personalization_medicaid_GA_es_US.pdf',
          explanation_of_personalization_medicaid_TX: 'explanation_of_personalization_medicaid_TX_es_US.pdf',
          explanation_of_personalization_medicaid_CA: 'explanation_of_personalization_medicaid_CA_es_US.pdf',
          explanation_of_personalization_medicaid_LA: 'explanation_of_personalization_medicaid_LA_es_US.pdf',
          explanation_of_personalization_medicaid_TN: 'explanation_of_personalization_medicaid_TN_es_US.pdf',
          explanation_of_personalization_medicaid_VA: 'explanation_of_personalization_medicaid_VA_es_US.pdf',
          explanation_of_personalization_medicaid_AR: 'explanation_of_personalization_medicaid_AR_es_US.pdf',
          explanation_of_personalization_medicaid_NJ: 'explanation_of_personalization_medicaid_NJ_es_US.pdf',
          explanation_of_personalization_medicaid_IA: 'explanation_of_personalization_medicaid_IA_es_US.pdf',
          explanation_of_personalization_medicaid_NV: 'explanation_of_personalization_medicaid_NV_es_US.pdf',
          explanation_of_personalization_medicaid_WV: 'explanation_of_personalization_medicaid_WV_es_US.pdf',
          explanation_of_personalization_medicaid_FL: 'explanation_of_personalization_medicaid_FL_es_US.pdf',
          explanation_of_personalization_medicaid_IN: 'explanation_of_personalization_medicaid_IN_es_US.pdf',
          explanation_of_personalization_medicaid_MD: 'explanation_of_personalization_medicaid_MD_es_US.pdf',
          explanation_of_personalization_medicaid_NY: 'explanation_of_personalization_medicaid_NY_es_US.pdf',
          explanation_of_personalization_medicaid_OH: 'explanation_of_personalization_medicaid_OH_es_US.pdf',
          explanation_of_personalization_medicaid_WA: 'explanation_of_personalization_medicaid_WA_es_US.pdf',
          explanation_of_personalization_medicaid_WI: 'explanation_of_personalization_medicaid_WI_es_US.pdf',
          explanation_of_personalization_medicaid_MO: 'explanation_of_personalization_medicaid_MO_es_US.pdf'
        }
      }
    },
    filter: {
      cme: 'Centros de Excelencia Médica',
      showMoreFilters: 'Muestra {COUNT} más',
      showLess: 'Muestra menos',
      filter: 'Filtrar',
      filterRemove: 'Filtrar eliminar',
      level: 'Nivel',
      preferred: 'Preferida',
      features: 'Caracteristicas',
      type: 'Tipo',
      plan: 'Red del plan',
      pharmacyType: 'Tipo de farmacia',
      refineResultsHeading: 'Refinar Resultados',
      hospDoctText: 'Hospitales/Médicos',
      quality: 'Reconocimientos/Nivel',
      specialty: 'Especialidad',
      subspecialty: 'Subespecialidad',
      levelofcare: 'Nivel de cuidado',
      patientpreference: 'Población de pacientes',
      areaofexpertise: 'Área de experiencia',
      adaAccessible: 'Accesible según la ADA',
      officeService: 'Servicios en el consultorio',
      ethnicity: 'Etnicidad',
      languageSpoken: 'Otros Idiomas',
      distance: 'Distancia',
      general: 'General',
      gender: 'Género',
      boardcertification: 'Certificada por la Junta',
      boardcertification1: 'Certificado por la junta',
      tier: 'Nivel de proveedor',
      npi: 'Identificador de proveedor nacional',
      licensetype: 'Tipo de licencia',
      licensenumber: 'Número de licencia',
      phone: 'Teléfono del proveedor',
      email: 'Correo electrónico del proveedor',
      servicesAvailable: 'Servicios disponibles',
      hospitalAffiliation: 'Afiliación a hospitales',
      groupAffiliation: 'Grupo Médico o IPA',
      yourMedicalGroup: 'Tu grupo médico',
      yourHealthSystem: 'Tu sistema de salud',
      healthSystem: 'Sistema de Salud: ',
      medicalGroup: 'Grupo Médico: ',
      affiliation: 'Afiliación',
      pcp: 'Médico de atención primaria',
      pcp1: 'Médico de atención primaria',
      keyFilters: 'Filtros clave',
      moreFilters: 'Más filtros',
      viewMoreFilterOptions: 'Ver más opciones de filtros',
      selectOne: 'Selecciona uno',
      options: 'Otras opciones',
      profile: 'Perfil',
      ariaApply: 'Aplicar búsqueda de proveedores',
      apply: 'Hecho',
      applyAriaLabel: 'Aplicar',
      close: 'Cerrar',
      closeAriaLabel: 'Cerrar selecciones',
      clear: 'Borrar',
      filterLabel: 'filtro',
      clearall: 'Limpiar todo',
      providers: 'Providers',
      clearAllFilters: 'Borrar filtros',
      enterNumber: 'Ingrese número de',
      enterEmail: 'Introduzca correo electrónico',
      emailError: 'Enter a valid email address',
      phoneError: 'Enter a valid phone number',
      npiError: 'Enter a valid NPI number',
      noResultMsg: 'No se encontró nada. Revisa tu ortografía o prueba con otro término',
      search: 'Buscar',
      hospitalSearch: 'Ingresa el nombre de un hospital',
      blueDistictionHelpText: 'Reconocido por su experiencia en ofrecer cuidado especializado',
      cmeText: 'Centros de salud reconocidos por cumplir con los criterios de calidad para la atención especializada.',
      medicalGrpSearch: 'Ingresa un grupo médico o una Asociación de médicos independientes (IPA)',
      adaAccessibleFilter: 'La información de accesibilidad nos la proporciona el proveedor de cuidado médico y puede cambiar.',
      searchHospAriaLabel: 'Ingresa 3 o mas caracteres para actualizar los resultados por Afiliación a hospitales. El filtro de Afiliación a hospitales que coincidan apareceran abajo.',
      searchMedicalAriaLabel: 'Ingresa 3 o mas caracteres para actualizar los resultados por Grupo Médico o IPA. El filtro de Grupo Médico o IPA que coincidan apareceran abajo.',
      clearfilter: 'Filtro claro',
      bdc: 'Blue Distinction',
      bdcPlus: 'Centros Blue Distinction+',
      bdcDescription: 'Filtrar por experiencia en la entrega de:',
      groupAffiliationDescription: 'Filter by medical group or independent physician association (IPA):',
      filter508header: 'Al seleccionar cualquiera de los siguientes controles de filtro, se actualizará la cuadrícula de resultados de búsqueda con los nuevos resultados filtrados.',
      ariaLblForPillButton: 'Al seleccionar este filtro, se mostrará una lista de opciones. Navega a la lista para agregar o quitar opciones',
      clearAriaLabel: 'Eliminar selecciones',
      recognition: 'Reconocimientos',
      clearAll: 'Borrar todo',
      bdcValue: {
        BDBAR: 'Cirugía bariátrica',
        BDCANC: 'Cuidado del cáncer',
        BDCAR: 'Cuidado cardíaco',
        BDCIT: 'Inmunoterapia celular',
        BDFC: 'Cuidado de la fertilidad',
        BDGT: 'Terapia de genes',
        BDKAH: 'Reemplazo de rodilla y cadera',
        BDMAT: 'Atención de maternidad',
        BDSPS: 'Cirugía de la columna',
        BDTPT: 'Trasplantes',
        BDSTR: 'Tratamiento y recuperación del uso de sustancias'
      },
      labels: {
        medicalPlan: 'Red médica',
        dentalPlan: 'Red dental',
        visionPlan: 'Red de visión',
        allPlans: 'Todas tus redes',
        oonPlan: 'Fuera de la red',
        scheduleAppointment: 'Programar citas',
        cooperativeCare: 'Cuidado médico cooperativo',
        sosTier: 'Sitio de servicio (SOS)',
        sosTier1: 'Sitio de servicio',
        benefitTier1: 'Nivel 1',
        bluePrecision: 'Blue Precision',
        LGBTQAlly: 'LGBTQ+ Ally',
        greenRibbon: 'Green Ribbon',
        blueDistinctionTotalCare: 'Cuidado total',
        enhancedPHC: 'Cuidado Médico Personal Mejorado',
        centerOfExcellence: 'Centro de Excelencia',
        providerOfDistinction: 'Proveedor de Distinción',
        msp: 'Farmacia de especialidad médica designada',
        valueBasedProvider: 'Proveedores basados en el valor de',
        bvvProvider: 'Vision PLUS',
        hppProvider: 'HPP',
        ableToServePCP: 'Presta servicios como PCP (médico de atención primaria)',
        integratedPCP: 'Puede servir como PCP (médico de atención primaria)',
        integratedPCP2: 'Sirve como médico de atención primaria (PCP)',
        ableToServePCP_ariaLabel: 'médico de atención primaria',
        acceptsMedicaid: 'Acepta Medicaid',
        ecpprovider: 'ECP Proveedor',
        omh: 'Oficina de salud mental',
        oasas: 'Oficina de servicios de alcoholismo y abuso de sustancias',
        bcp: 'Especialistas preferidos',
        acceptNewPatient: 'Acepta nuevos pacientes',
        acceptNewPatient1: 'Acepta Pacientes nuevos',
        level1: 'Nivel 1',
        preferred1: 'Preferida',
        all: 'Todas las características de una farmacia',
        twentyfourhour: 'Servicios 24 horas',
        rxMaintenance: 'Mantenimiento RX 90',
        drivethrough: 'Servicio de conducción',
        onsiteMedical: 'Clínica médica en el lugar',
        bloodPressure: 'Examen de presión arterial',
        fluShots: 'Vacunas contra la gripe',
        homeDelivery: 'Entrega a domicilio',
        vaccinations: 'Vacunas',
        coumpoundMedications: 'Medicamentos Coumpound',
        durableMedical: 'Equipo médico duradero',
        allTypes: 'Todo tipo de farmacias',
        communityRetail: 'Comunidad / Minorista',
        longTerm: 'Cuidado a largo plazo',
        mailOrder: 'Pedido por correo',
        homeInfusion: 'Proveedor de terapia de infusión en el hogar',
        nonPharmacy: 'Sitio de dispensación no farmacéutico',
        indianHealth: 'Servicio de Salud Indígena / Tribal / Urban Indian Health (I / T / U)',
        deptOfVeterans: 'Departamento de Asuntos de Veteranos (VA)',
        institutional: 'Institucional',
        managedCare: 'Organización de atención administrada',
        dme: 'DME',
        clinic: 'Clínica',
        specialty: 'Especialidad',
        nuclear: 'Nuclear',
        military: 'Militar / EE. UU. guardacostas',
        compounding: 'Agravante',
        showMore: 'Muestra más',
        showLess: 'Muestra menos',
        showAll: 'Muestra todo',
        showMoreInfo: 'mostrar más enlace enumerará todas las coincidencias ',
        showLessInfo: 'mostrar menos enlace mostrará las 6 primeras ',
        viewAllLocationsLabel: 'Ver todas las ubicaciones',
        hospitalLocationsCountLabel: 'ubicaciones encontradas para este hospital',
        medicalLocationsCountLabel: 'ubicaciones encontradas para este Grupo médico o IPA',
        close: 'Cerrar',
        location: 'Ubicación',
        acceptTeleHealth: 'Aceptan telemedicina',
        acceptTeleHealth1: 'Telemedicina',
        upswing: 'Upswing',
        snyDental: 'Proveedores del programa de descuentos dentales',
        ccare: 'CCare',
        ccare_ariaLabel: 'CCare',
        ariaLabelForBDCTitle: 'Selecciona enter para mas informacion acerca de',
        whatIsA: '¿Qué es una',
        showFilters: 'Mostrar filtros',
        extendedHours: 'Horario extendido',
        lowerCopayPCP: 'PCP de copago más bajo'
      },
      vaccines: 'Vacunas',
      tooltips: {
        enhancedPHC:
          'Un enfoque según el cual los médicos pasan más tiempo con los pacientes, coordinan la atención con otros médicos y se concentran en la mejor manera de ayudarlo a lograr estar saludable y mantenerse así.',
        blueDistinctionTotalCare:
          'Los proveedores de servicios médicos son reconocidos por su compromiso en la coordinación del cuidado total del paciente, haciendo énfasis en la prevención y el bienestar y ayudando a los pacientes a manejar mejor las enfermedades crónicas para lograr mejores resultados de salud.',
        bluePrecision:
          'Las designaciones de Blue Precision se les otorgan a los médicos que ejercen las siguientes especialidades: Alergia/inmunología, enfermedades cardiovasculares, endocrinología, obstetricia y ginecología (OB/GYN), otorrinolaringología (Oído, nariz, garganta), reumatología y medicina pulmonar. Podrá encontrar las designaciones de Blue Precision para los proveedores que ejercen en Colorado, Connecticut, Georgia, Indiana, Kentucky, Maine, Missouri, New Hampshire, Nevada, New York, Ohio y Virginia. Para más información sobre Blue Precision, haga clic en el enlace de Blue Precision Designation Program (Programa de designaciones de Blue Precision) que se encuentra en la página de resultados de búsquedas del buscador de proveedores.',
        acceptNewPatient:
          'Esta información ha sido comunicada directamente por el médico y actualizada en cada plan de acuerdo con lo reportado. Para obtener mejores resultados, asegúrate de seleccionar un plan cuando utilices esta opción.',
        ableToServePCP:
          'En algunas áreas, profesionales de la medicina que no sean médicos (como un enfermero profesional avanzado) pueden cumplir funciones de atención primaria. Para garantizar resultados exactos, asegúrate de tener un plan seleccionado cuando utilices esta opción.',
        ecpprovider:
          'Un proveedor comunitario de servicios esenciales (ECP, por sus siglas en inglés) es un proveedor de servicios médicos que atiende a personas de alto riesgo, con necesidades especiales e insuficientemente atendidas.',
        acceptsMedicaid: 'El proveedor identificado también acepta Medicaid.',
        blueDistictionCenterPlus: 'Proveedores de servicios médicos reconocidos por su experiencia y su eficiencia en costos al brindar atención especializada.',
        blueDistictionCenter: 'Proveedores de servicios médicos reconocidos por su experiencia en brindar atención especializada.',
        aoe: 'El proveedor reportó ser competente y tener experiencia dentro de un campo.',
        loc: 'Servicios médicos brindados en una instalación física.',
        patientPreference: 'Datos demográficos de los pacientes que trata un proveedor.',
        cooperativeCare:
          'Los médicos de Cooperative Care se comprometen a brindar una mejor experiencia al paciente, incluyendo citas dentro de las 24 horas, más formas de ver a un médico o a personal de enfermería y ayuda para navegar el sistema, para asegurarse de que obtengas lo que necesitas de otros médicos y especialistas.',
        LGBTQAlly: 'Este proveedor de cuidado médico ha completado la capacitación y ha demostrado que entiende las necesidades de los miembros que se identifican como LGBTQ+',
        greenRibbon:
          'Los proveedores de Green Ribbon avanzan en la integración de redes de proveedores de salud física y conductual y facilitan la navegación a estos innovadores entornos de  cuidado.',
        sosTier:
          'Usar un proveedor de Sitio de servicio independiente puede reducir los costos de bolsillo por laboratorios, imágenes de diagnóstico y cirugías ambulatorias. No todos los planes ofrecen un beneficio de Sitio de servicio, así que comunícate con Servicios para miembros para confirmarlo.',
        integratedTier1: 'Nivel 1 Preferido, que puede resultar en costos más bajos para ti',
        omh: 'La integración de los servicios primarios de salud mental puede ayudar a mejorar la calidad general de la atención para las personas con múltiples condiciones de salud al tratar a la persona en su totalidad de una manera más integral.',
        oasas:
          'La integración de los servicios para el trastorno de uso de sustancias puede ayudar a mejorar la calidad general de la atención para las personas con múltiples condiciones s de salud al tratar a la persona en su totalidad de una manera más integral.',
        bcp: 'Estos especialistas cumplen con nuestros más altos estándares de cuidado médico de calidad y de referencia de costos. Están comprometidos a ayudarte a navegar por el sistema de salud al trabajar con tu médico de atención primaria (PCP, por sus siglas en inglés), ofreciéndote una mejor experiencia como paciente.',
        extendedHours: 'Abierto después de las 5 p. m. (entre semana) o Abierto durante los fines de semana (sábado y domingo).',
        servicesAvailable: {
          VD: 'Ofrece descuentos adicionales en servicios y suministros no cubiertos asociados con tu plan de visión. Habla con tu proveedor sobre posibles descuentos.'
        },
        tooltipInfo: 'presiona Enter para obtener más información sobre ',
        acceptTeleHealth: 'Este profesional de la salud o ubicación ofrece acceso virtual en tiempo real a algunos o todos los servicios médicos que normalmente se brindan en el consultorio.',
        upswing:
          'Upswing es un programa de manejo ortopédico. Upswing te ayuda a comprender qué está causando tu dolor y luego te conecta con un profesional médico en vivo sin salir de tu casa. Para comenzar, visita <a href="https://www.upswinghealth.com/CT/" target="_blank">www.upswinghealth.com/CT</a> (en inglés).',
        snyDental: 'Este proveedor dental puede cobrarte menos que su cargo normal por servicios no reembolsados por el Plan dental SEHP.',
        ccare:
          'Los miembros que participan en CCare pueden visitar cualquier proveedor de atención primaria de T.J. Samson por un copago reducido, independientemente de la condición o el diagnóstico.',
        msp: 'Trabajamos con los centros médicos para reducir el costo total de ciertos medicamentos especializados y reducir los gastos de bolsillo de nuestros miembros. Los centros médicos que aceptan participar como una Farmacia de especialidad médica designada se consideran ubicaciones preferidas para nuestros miembros que reciben uno de los medicamentos de esta lista en un entorno ambulatorio. <a href="https://www.anthem.com/ms/pharmacyinformation/Designated-Medical-Specialty-Pharmacy-Drug-List-eff-07012022-AnthemBCBS.pdf" target="_blank">Ver la lista de medicamentos especializados (PDF)</a>',
        valueBasedProvider: 'Los costos compartidos del miembro de Anthem pueden no aplicarse o reducirse cuando los servicios son prestados por un proveedor basado en el valor.',
        bvvProvider: 'Los proveedores de atención de Vision PLUS pueden maximizar tus beneficios con cobertura adicional para ayudarte a ahorrar aún más.',
        hppProvider:
          'Los Proveedores de Alto Rendimiento se enfocan en ofrecer cuidado médico de alta calidad, el cuidado médico adecuado en el momento adecuado y en seguir las mejores prácticas para la administración del cuidado',
        lowerCopayPCP: 'Usar un médico de atención primaria con un copago más bajo puede resultar en costos más bajos para ti.'
      },
      expand: {
        level: 'Press Enter to expand or collapse level filters',
        preferred: 'Press Enter to expand or collapse preferida filters',
        features: 'Press Enter to expand or collapse features filter',
        type: 'Press Enter to expand or collapse type filter',
        hospitals: 'Press Enter to expand or collapse hospitals or doctors filters',
        recoginition: 'Press Enter to expand or collapse recognitions filters',
        blueDistinctionCenters: 'Press Enter to expand or collapse Blue Distinction Centers filters',
        specialty: 'Press Enter to expand or collapse Specialty filters',
        subSpecialty: 'Press Enter to expand or collapse Sub Specialty filters',
        levelofcare: 'Press Enter to expand or collapse level of care filters',
        distance: 'Press Enter to expand or collapse Distance filters',
        gender: 'Press Enter to expand or collapse Gender filters',
        options: 'Press Enter to expand or collapse Options filters',
        servicesAvailable: 'Press Enter to expand or collapse Services Available filters',
        language: 'Press Enter to expand or collapse Language Spoken in addition to English filters',
        hospitalAffiliation: 'Press Enter to expand or collapse Hospital Affiliation filters',
        showAffiliation: 'Press Enter to expand or Medical group or IPA filters',
        ethnicity: 'Press Enter to expand or collapse ethnicity filters',
        areaofexpertise: 'Press Enter to expand or collapse Area of expertise filters',
        patientPopulation: 'Press Enter to expand or collapse  Patient Population filters',
        boardcertification: 'Press Enter to expand or collapse  board certification filters',
        tier: 'Press Enter to expand or collapse  tier filters',
        npi: 'Press Enter to expand or collapse  npi filters',
        licensetype: 'Press Enter to expand or collapse  license type filters',
        licensenumber: 'Press Enter to expand or collapse  license number filters',
        phone: 'Press Enter to expand or collapse  phone filters',
        email: 'Press Enter to expand or collapse  email filters',
        medicalGroup: 'Press Enter to expand or collapse Medical Group / IPA filters'
      }
    },
    genderList: {
      M: 'Masculino',
      F: 'Femenino'
    },
    adminOffice: 'Oficina administrativa solamente',
    sortBy: 'Ordenar por',
    sortByPlaceholder: 'Ordenar por:',
    sort: 'Ordenar',
    select: 'Seleccionar',
    physician: 'Proveedor',
    location: 'Ubicación(es)',
    appointmentSchedule: 'Reserva una cita',
    selectPhysician: 'Seleccionar Proveedor',
    inNetwork: 'Dentro de la red',
    oonNetworkLabel:
      'Nuestro sistema no puede determinar si este proveedor de cuidado médico está en la red de tu plan. Comunícate con este proveedor de cuidado médico para verificar su estatus dentro de la red.',
    inNetworkLabel: 'En la red de su plan',
    balance: 'Saldo de la cuenta:',
    remainingDeductible: 'Deducible restante: ',
    networkStatusSummary: {
      TP_INNETWORK: 'Dentro de la red',
      TP_OUTNETWORK: '',
      TP_ASOF: 'Dentro de la red',
      TP_UNTIL: 'Dentro de la red',
      INNETWORK: 'Dentro de la red',
      OUTNETWORK: '',
      INNETWORK_AS_OF: 'Dentro de la red',
      INNETWORK_UNTIL: 'Dentro de la red',
      NOT_INNTWK_ASOF: '',
      NOTINNETWORK_ASOF: '',
      OUTOFNETWORK: ''
    },
    networkStatus: {
      TP_INNETWORK: 'En la red de este plan {PLANTYPE}',
      TP_OUTNETWORK: 'No en la red de este plan {PLANTYPE}',
      TP_ASOF: 'En la red de este plan {PLANTYPE} a partir de {DATE}',
      TP_UNTIL: 'En la red {PLANTYPE} de este plan hasta {DATE}',
      INNETWORK: 'En la red de este plan {PLANTYPE}',
      OUTNETWORK: 'No en la red de este plan {PLANTYPE}',
      INNETWORK_AS_OF: 'En la red de este plan {PLANTYPE} a partir de {DATE}',
      INNETWORK_UNTIL: 'En la red {PLANTYPE} de este plan hasta {DATE}',
      NOT_INNTWK_ASOF: 'No está en la red de este plan {PLANTYPE} a partir del {DATE}',
      NOTINNETWORK_ASOF: 'No está en la red de este plan {PLANTYPE} a partir del {DATE}',
      OUTOFNETWORK: 'No en la red de este plan {PLANTYPE}'
    },
    networkStatusSecured: {
      TP_INNETWORK: 'En la red de tu plan {PLANTYPE}',
      TP_OUTNETWORK: 'No está en la red de tu plan',
      TP_ASOF: 'En la red de tu plan {PLANTYPE} a partir de {DATE}',
      TP_UNTIL: 'En la red de tu plan {PLANTYPE} hasta {DATE}',
      INNETWORK: 'En la red de tu plan {PLANTYPE}',
      OUTNETWORK: 'No está en la red de tu plan',
      INNETWORK_AS_OF: 'En la red de tu plan {PLANTYPE} a partir de {DATE}',
      INNETWORK_UNTIL: 'En la red de tu plan {PLANTYPE} hasta {DATE}',
      NOT_INNTWK_ASOF: 'No está en la red de su plan {PLANTYPE} a partir del {DATE}',
      NOTINNETWORK_ASOF: 'No está en la red de su plan {PLANTYPE} a partir del {DATE}',
      OUTOFNETWORK: 'No está en la red de tu plan'
    },
    reviewText: {
      bcbsa: 'BlueCross BlueShield',
      hcahps: 'CMS HCAHPS',
      reviews: 'reseñas',
      review: 'reseña',
      tooltipReview:
        "Las calificaciones de HCAHPS (Evaluación de consumidores de hospitales sobre proveedores y sistemas de cuidado de salud) se basan en datos de encuestas de pacientes de los Centros de servicios de Medicare y Medicaid. Encuentra más información en <a target='_blank' href='https://es.medicare.gov/care-compare/'>es.medicare.gov/care-compare.</a> Última actualización {DATE}."
    },
    awardsCerts: 'Reconocimientos/Certificaciones',
    accessibility: 'Accesibilidad:',
    emailResults: 'Enviar resultados por correo electrónico',
    email: 'Correo electrónico ',
    printResults: 'Imprimir resultados',
    print: 'Imprimir',
    downloadResults: 'Descargar resultados',
    download: 'Descargar',
    back: 'Volver',
    backCompareAriaLabel: 'Botón de Volver, haz clic aquí para volver a la página de resumen',
    compareAriaLabel: 'Botón de Comparar, haz clic aquí para ir a la página de comparar detalles del proveedor',
    ariaLabelForRecognitionModal: 'Botón cerrar, Seleccionar para cerrar la ventana de resumen de reconocimiento',
    ariaLabelForTelehealthModal: 'Botón cerrar, haz clic aquí para cerrar la ventana de resumen de telemedicina',
    ariaLabelForAwardModal: 'Botón cerrar, haz clic aquí para cerrar la ventana de resumen del premio',
    backAriaLabel: ' Botón de Volver, selecciona para volver  a la página de criterios de búsqueda',
    compare: 'Comparar ahora',
    emailModal: {
      success: 'El correo fue enviado con éxito',
      error: 'No se pudo enviar el correo electrónico. Intenta otra vez.',
      tooManyIds: 'Se han ingresado demasiadas identificaciones de correo electrónico',
      required: ' El campo del correo electrónico es obligatorio',
      validEmail: 'Correo electrónico no válido',
      emailAddress: 'Correo electrónico',
      details: 'Detalles a enviar por correo electrónico:',
      max100: 'Ten en cuenta: el PDF solo tiene hasta los primeros 100 proveedores de tus resultados de búsqueda. Esta no es una lista de todos los proveedores.',
      submit: 'Enviar',
      cancel: 'Cancelar',
      providerSearchResults: 'Resultados de búsqueda de proveedores',
      pdf: '.pdf'
    },
    close: 'Cerrar',
    showMap: 'Mostrar mapa',
    hideMap: 'Ocultar mapa ',
    specialty: 'Especialidad:',
    showDisclaimer: 'Mostrar descargos de responsabilidad',
    hideDisclaimer: 'Ocultar descargos de responsabilidad',
    helpfulHintsHeading: 'Consejos útiles',
    backTosearchResult: 'Resultados de Búsqueda',
    backToResults: 'Volver a Resultados',
    backToCareTeam: 'Volver a Equipo de cuidado médico',
    backToFindCare: 'Volver a Encontrar cuidado médico',
    newFindCareSearch: 'Nueva búsqueda de Encontrar cuidado',
    backToModifySearch: 'Modificar Búsqueda',
    compareProviderText: 'Compara Proveedores',
    availableServices: 'Servicios disponibles:',
    pcpid: 'PCP ID:',
    showMore: 'Mostrar más',
    showLess: 'Muestra menos',
    resultsHeader: {
      plan: 'plan',
      prefix: 'Prefijo',
      noResultDefaultMsgNew:
        'No se encontraron resultados para <span class="ant-font-b">{PVD}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>{MATCH}.',
      noResultDefaultMsgNewLine1: 'No se encontraron resultados dentro de <span class = "ant-font-b "> {RDS} </span> millas de <span class = "ant-font-b "> {LOC} </span> {MATCH}.',
      noResultSummaryMsgCold:
        '<span class="ant-font-b">No hay resultados.</span> Ampliamos automáticamente tu búsqueda, pero no encontramos ninguna coincidencia dentro de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>. Intenta cambiar el código postal o <a target="_blank" href="/contact-us">comunícate con nosotros</a> si tienes preguntas sobre tus beneficios.',
      noResultSummaryMsgHot:
        '<span class="ant-font-b">No hay resultados.</span> Ampliamos automáticamente tu búsqueda, pero no encontramos ninguna coincidencia dentro de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>. Intenta cambiar el código postal o <a target="_blank" href="/member/contact-us">comunícate con nosotros</a> si tienes preguntas sobre tus beneficios.',
      noResultSummaryMsgHotMedicaid:
        '<span class="ant-font-b">No hay resultados.</span> Ampliamos automáticamente tu búsqueda, pero no encontramos ninguna coincidencia dentro de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>. Intenta cambiar el código postal o comunícate con nosotros si tienes preguntas sobre tus beneficios.',
      noOONResultSummaryMsg:
        '<strong>Este proveedor de cuidado médico no está en la red de tu plan.</strong> Hemos aumentado automáticamente la distancia de búsqueda, pero no se encontraron resultados para <span class="ant-font-b">{PVD}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>{MATCH}.',
      noOONResultSummaryMsgLine1:
        '<strong> Este proveedor de cuidado médico no está dentro de la red de tu plan. </strong> Hemos aumentado automáticamente la distancia de búsqueda, pero no se encontraron resultados dentro de <span class = "ant-font-b "> {RDS} millas </span> de <span class = "ant-font-b "> {LOC} </span> {MATCH}.',
      OONsearchNoResultSummaryMsg:
        'No hay resultados. Hemos ampliado automáticamente tu búsqueda, pero no encontramos ninguna coincidencia dentro de <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>. Intenta cambiar el código postal o <a href="https://www.anthem.com/contact-us">Contáctanos</a> si tienes preguntas sobre tus beneficios.',
      summaryMsgNew:
        '<span class="ant-font-b">{CNT}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>{MATCH}. {REFINE}',
      selectedMemberPlanMsgNew: 'Encontrar cuidado médico para <b>{NAME}</b> en <span class="ant-font-b">{PLAN}</span>.',
      selectedMemberPlanNew: 'Encontrar cuidado médico para <span>{NAME}</span> dentro de la red del plan de<span>{PLAN)</span>',
      selectedPlanMsgNew: 'Encontrar cuidado médico en <span class="ant-font-b">{PLAN}</span>.',
      selectedPlanMsg: 'Encontrar cuidado médico en <span>{PLAN}</span>',
      cptSummaryMsgLine1:
        '<span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span> para <span class="ant-font-b">{USR} en </span><span class="ant-font-b">{PLN}</span> con un deducible restante de ',
      cptSummary: '{CNT} resultados en la red del plan médico de {USR}',
      summaryMsgLine1: '<span class="ant-font-b">{CNT}</span> resultados dentro de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>{MATCH}',
      summaryMsgSubSpecRemoved:
        'No pudimos encontrar ningún resultado para esa especialidad, por lo que ampliamos tu búsqueda para incluir especialidades relacionadas dentro de un radio de <span class="ant-font-b">{RDS} millas</span> de <span class="ant-font-b">{LOC}</span>. Encontramos <span class="ant-font-b">{CNT}</span> resultados dentro de esa distancia.',
      summaryMsgPrimeGroupRemoved:
        'No se encontraron resultados, por lo que eliminamos el filtro de Grupo médico y aumentamos el radio de búsqueda a <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>. Encontramos <span class="ant-font-b">{CNT}</span> resultados dentro de esa distancia {MATCH}. Necesitarás ser referido por tu  médico de atención primaria antes de recibir servicios que no sean de emergencia fuera de tu grupo médico.',
      increasedRadiusMsgNew:
        'No se encontraron resultados, así que aumentamos la distancia de búsqueda a <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>. Encontramos <span class="ant-font-b">{CNT}</span> <span class="ant-font-b">{PVD}</span> en el <span class="ant-font-b">{PLAN}</span> dentro de esa distancia {MATCH}. {REFINE}',
      increasedRadiusMsgNewLine1:
        'No se encontraron resultados, así que aumentamos la distancia de búsqueda a <span class="ant-font-b">{RDS}</span> millas de <span class="ant-font-b">{LOC}</span>. Encontramos <span class="ant-font-b">{CNT}</span> dentro de esa distancia {MATCH}.',
      matchingNew: ' coinciden o son similares a <span class="ant-font-b">{NAM}</span>',
      refineResultsNew: '<br/>Refina tus resultados de búsqueda usando cualquier combinación de filtros.',
      noResultEycSummaryMsg: 'Lo sentimos, no hay resultados para sus criterios de búsqueda. Utilice el botón ATRÁS para cambiar su ubicación o distancia de búsqueda e intente nuevamente.',
      noResultEycSummaryMsgForUnimass:
        "No hay resultados. Prueba con un código postal diferente o usa &quot;Volver a Encontrar cuidado médico&quot; para comenzar de nuevo. No podemos mostrar estimados de costos de procedimientos para proveedores de cuidado ubicados fuera de Massachusetts. Si tienes alguna pregunta, comunícate con <a target='_blank' href='{brandUrl}'>Servicios para miembros</a>.",
      nonGAColdStateProcSearchMsg:
        "Para encontrar proveedores de cuidado médico para este procedimiento fuera de Georgia, <a tabindex='0' aria-label='inicia sesión' target='_blank' href='https://www.anthem.com/login/?dplid={deeplinkid}'>inicia sesión</a>.",
      hpnariaNoResultsMsg: 'Tus beneficios pueden estar limitados a la atención de urgencias o a la atención de emergencia en esta ubicación. Contáctanos si tienes preguntas sobre tus benefcios.',
      hpnNoResultsMsg1: 'Tus beneficios pueden estar limitados a la atención de ',
      hpnNoResultsMsgUC: 'urgencias ',
      hpnNoResultsMsg2: 'o a la atención de ',
      hpnNoResultsMsgEC: 'emergencia ',
      hpnNoResultsMsg3: 'en esta ubicación. ',
      hpnNoResultsMsgCU: 'Contáctanos ',
      hpnNoResultsMsg4: 'si tienes preguntas sobre tus benefcios.'
    },
    searchCriteriaComponent: {
      providerTypes: { P: 'médico', D: 'dental', V: 'de visión', R: 'farmacia' },
      monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
      labels: {
        careProviders: 'Care Providers',
        onlinePharmacyLabel: 'FARMACIA EN LÍNEA',
        onlinePharmcyTooltip: 'Ahorra un viaje a la farmacia y disfruta de la comodidad de la entrega a domicilio. Te entregaremos tus recetas con envío estándar gratuito.',
        ariaLabelForTooltipInfo: 'Selecciona enter para mas informacion acerca de',
        onlineProviderLabel: 'EN LÍNEA'
      }
    },
    resultsProvType: {
      P: 'Médico/Profesional de la salud',
      H: 'Hospitales y centros médicos',
      B: 'Profesional en salud del comportamiento',
      D: 'Proveedores de Servicios Dentales',
      DM: 'Médicos Proveedores de Servicios Dentales',
      V: 'Profesional de Visión',
      VM: 'Profesional de Médicos Visión',
      L: 'Labs, patólogos, y / o radiólogos',
      C: 'Clínica de salud en un local comercial',
      A: 'Profesional de la audición',
      U: 'Proveedores de Atención de Urgencia',
      M: 'Proveedores de equipo médico',
      R: 'Farmacia',
      O: 'Otros servicios médicos',
      G: 'Grupo Médico'
    },
    pdfFileName: {
      medical_procedure: 'Procedimiento_médico',
      dental_procedure: 'Procedimiento_dental',
      medical_code: 'Código_médico',
      search_on: '_Buscar_en_'
    },
    bookAppointment: 'Programar cita',
    requestAppointment: 'Pedir cita',
    assignPCP: 'Asignar como médico primario',
    addCareTeam: 'Agregar al equipo médico',
    viewDetails: 'Ver detalles',
    summaryTabs: {
      listView: 'Ver como lista',
      tileView: 'Ver cuadro',
      mapView: 'Ver mapa'
    },
    detailsNotAvailable: 'Detalles del proveedor no disponibles',
    showMoreRating: 'Mostrar más enlace de calificaciones. Al seleccionar el enlace, se enumerarán todas las calificaciones disponibles para ',
    showLessRating: 'Mostrar menos enlaces de calificaciones. Al seleccionar el enlace, las calificaciones se plegarán. Solo aparecerá la primera calificación.',
    starAriaLabel: '{NAME} tiene una calificación de {RATING} de 5 para {SRC}',
    reviewInfo: '{review} disponible para el proveedor',
    telehealth: 'Telemedicina',
    telehealthNote: 'Este profesional de la salud o ubicación ofrece acceso virtual en tiempo real a algunos o todos los servicios médicos que normalmente se brindan en el consultorio.',
    selectProvider: 'Seleccionar Proveedor',
    doingBusinessAs: 'Haciendo negocios como:',
    costRangeLabel: 'El rango de costo estimado para este servicio es',
    costRangeToolTipInfo:
      'Este es el rango de montos promedio que los proveedores de cuidado médico acordaron facturar por este servicio en este plan. Inicia sesión para obtener estimados de costos más exactas.',
    personalizedMatchContent: {
      spfiDisclaimer: {
        spfiDisclaimerText: 'No incluido en la coincidencia personalizada ',
        spfiDisclaimerDescription:
          'Los profesionales de la salud dentro de la red pueden quedar excluidos de la coincidencia personalizada por razones como restricciones contractuales, falta de datos o una ubicación fuera de nuestra Área de servicio. <a target="_blank" href="{pdfUrl}"> Más información sobre la coincidencia personalizada.</a>'
      }
    }
  },
  providerCardDetails: {
    tabs: {
      overView: 'Descripción general',
      moreDetails: 'Más detalles',
      costEstimate: 'Estimación de costos'
    },
    actions: {
      assignPCP: 'Asignar como PCP',
      addCareTeam: 'Agregar al equipo de atención'
    },
    labels: {
      print: 'Imprimir',
      viewLocation: 'Ver ubicaciones',
      close: 'Cerca',
      county: 'Condado'
    },
    selectProvider: 'Seleccionar Proveedor',
    addToCareTeam: {
      outerBtnLabel: 'Agregar al equipo de cuidado',
      closeModal: 'button, select to close the modal',
      btnCancel: 'Cancelar',
      addCareTeam: 'Agregar al equipo de cuidado',
      ariaLabelForAddCareTeam: 'Add to Care Team button add {PROVIDER} to {MEMBER} Care Team',
      addCareProviderContent:
        "Are you sure you want to add <strong class='text-uppercase'>{PROVIDER}</strong> to the Care Team of <strong>{MEMBER}</strong>? (You always can remove if you change your mind.)",
      addWhoseCareTeamHeader: 'Add to whose Care Team?',
      addCareProviderHeader: 'Agregar al equipo de cuidado',
      addCareTeamSuccessMsg:
        "You have successfully added <strong class='text-uppercase'>{PROVIDER}</strong> to the <a type='button' id='lnk-careteam' class='addcareteam pf-button' aria-label='click here to navigate to Care team page'>Care Team of <strong class='addcareteam pf-button'>{MEMBER}</strong></a>.",
      addCareTeamFailureMsg: "There was a problem and we weren't able to add <strong class='text-uppercase'>{PROVIDER}</strong> to the Care Team of <strong>{MEMBER}</strong>. Please try again later."
    },
    assignToPcp: {
      slideoutMsgCmpContent: {
        close: 'Close',
        title: "We're Having a Problem Assigning This Primary Care Physician",
        default: "To assign {DOCTOR NAME} as a Primary Care Physician, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        errNoPcpsInAffiliation: "To assign {DOCTOR NAME} as a Primary Care Physician, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        errPcpMbrEffDtPastContractEndDt:
          "We won't be able to assign {DOCTOR NAME} as the PCP for {MEMBER NAME} because your plan ends on {TERMINATION DATE}. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        errMbrAgeNotInRange:
          "Our system is telling us that {DOCTOR NAME} doesn't treat patients in {MEMBER NAME}'s age group. If you think this information is wrong, please contact <a target='_self' href='/member/contact-us'>Member Services.</a>",
        title_errPcpMbrEffDtPastContractEndDt: "Can't assign PCP before plan ends"
      }
    }
  },
  filter: {
    allFilters: 'Todos Filtros',
    filterApplied: 'Filtro aplicado',
    clearFilter: 'Filtro claro',
    cancel: 'Cancelar',
    undoFilter: 'Undo Filter',
    apply: 'Aplicar',
    showMoreFilters: 'Muestra {COUNT} más',
    filterRemove: 'Filtrar eliminar',
    filter: 'Filtrar',
    showLess: 'Muestra menos',
    selected: 'seleccionados',
    serveAsPcp: 'Sirve como médico de atención primaria (PCP)',
    acceptsNewPatients: 'Acepta nuevos pacientes',
    noPreference: 'Sin preferencias',
    adaFilterDescription: 'La información de accesibilidad nos la proporciona el proveedor de cuidado médico y puede cambiar.',
    blueDistictionFilterDescription: 'Reconocido por su experiencia en ofrecer cuidado especializado.',
    cmeDescription: 'Centros de salud reconocidos por cumplir con los criterios de calidad para la atención especializada.',
    categories: {
      gender: 'Género',
      specialty: 'Especialidad',
      subSpecialty: 'Subespecialidad',
      providerType: 'Tipo',
      languages: 'Otros Idiomas',
      adaAccessible: 'Accesible según la ADA',
      areaofexpertise: 'Área de experiencia',
      billingCode: 'Código de facturación',
      ethnicities: 'Ethnicidad',
      levelOfCares: 'Nevel de Atencion',
      officeServices: 'Servicios de Oficina',
      officeFeatures: 'Caracteristicas de la Oficina',
      medicalGroup: 'Grupo Médico o IPA',
      hospitalAffiliation: 'Afiliaciones a hospitales',
      profile: 'Perfil',
      patientPopulation: 'Patient Population',
      servicesAvailable: 'Services Available',
      keyFilters: 'Filtros clave',
      distance: 'Distancia',
      features: 'Caracteristicas',
      pharmacyType: 'Tipo de farmacia',
      level: 'Nivel',
      recognition: 'Reconocimientos',
      blueDistinction: 'Distinción azul',
      planNetwork: 'Red del plan',
      cme: 'Centros de Excelencia Médica'
    },
    distanceOptions: [
      { label: '2 Millas', value: '2' },
      { label: '5 Millas', value: '5' },
      { label: '10 Millas', value: '10' },
      { label: '20 Millas', value: '20' },
      { label: '30 Millas', value: '30' },
      { label: '50 Millas', value: '50' },
      { label: '75 Millas', value: '75' },
      { label: '100 Millas', value: '100' }
    ],
    sortFilters: [
      { label: 'PersonalizedMatch', value: 'Coincidencia personalizada' },
      { label: 'distance', value: 'Distancia' },
      { label: 'atoz', value: 'De la A a la Z' },
      { label: 'ztoa', value: 'De la Z a la A' }
    ]
  },
  sortOptions: {
    distance: 'Distancia',
    atoz: 'De la A a la Z',
    ztoa: 'De la Z a la A',
    atoz_eyc: 'A - Z',
    ztoa_eyc: 'Z - A',
    quality: 'Reconocimientos',
    tier: 'Nivel de beneficio',
    ppc: 'PPC (Atención Primaria Preferida)',
    blue_distinction: 'Distinción azul',
    personalizedmatch: 'Coincidencia personalizada',
    personalizedmatch_eyc: 'Coincidencia personalizada',
    least_expensive: 'Tu costo (de menor a mayor)',
    most_expensive: 'Tu costo (de mayor a menor)',
    cooperative_care: 'Tu costo (de mayor a menor)',
    preferred: 'Preferida',
    dh: 'Dignity Health',
    cost_low_to_high: 'Tu costo (de menor a mayor)',
    cost_high_to_low: 'Your Cost (High to Low)'
  },
  cptFilters: {
    medicalCodePlaceHolder: 'Código Médico',
    posPlaceHolder: 'Dónde recibes cuidado médico',
    modifiersPlaceHolder: 'Opciones de código médico'
  },
  providerOverview: {
    actions: {
      appointment: 'Solicitar cita'
    },
    tooltips: {
      acceptPatients: 'Estamos mostrando esta información según lo informado por el médico. Comunícate con el médico para confirmar.',
      diamondTooltip: 'Un proveedor Diamante de Anthem cumple con ciertos estándares de cuidado médico de calidad, mejora de la salud y reducción de costos.',
      pcpInfo:
        'Confirme este número revisando la información en la sección de Afiliaciones. Los PCP en grupos pueden tener diferentes ID según la ubicación de la oficina. Elija el ID que coincida con la oficina que desea visitar.'
    },
    labels: {
      speak: 'Habla',
      viewPcP: 'Ver todas las identificaciones de PCP',
      acceptPatients: 'Aceptando nuevos pacientes',
      addReview: 'Añadir una reseña',
      pcpInfo: 'ID del PCP: ',
      pcp: 'Información del PCP',
      providerImage: 'Imagen del proveedor',
      planType: 'Tipo de plan',
      providerType: 'Tipo de proveedor',
      tierType: 'Tipo de nivel',
      review: 'Agregar reseña',
      basicInfo: {
        address: 'DIRECCIÓN',
        phone: 'Teléfono',
        email: 'Correo electrónico',
        website: 'Sitio web'
      },
      acceptNew: 'Aceptando nuevos pacientes',
      notAcceptNew: 'No acepta nuevos pacientes',
      recognition: 'Reconocimientos',
      affiliation: 'Afiliaciones',
      virtualService: 'Servicios virtuales/telesalud',
      noEthnicity: 'etnicidad no disponible',
      noGender: 'género no disponible',
      noGenderOrEthnicity: 'Género y etnia no disponibles',
      noLanguages: 'Idiomas hablados no disponibles',
      noEducation: 'Educación no disponibles',
      noSpeciality: 'Especialidades no disponibles',
      officeHours: 'Horario de oficina',
      open24Hours: 'Abierto las 24 horas',
      open24HoursDay: 'Abierto 24/7',
      notOpen: 'No abierto',
      hoursNotAvail: 'Horario de oficina no disponible',
      quickFacts: 'Datos breves',
      networkNotAvail: 'Red no disponsible',
      diamondProvider: 'Proveedor de diamantes Anthem',
      notAvail: 'No disponible',
      tty: 'TTY: '
    },
    weekdays: {
      0: 'Lunes',
      1: 'Martes',
      2: 'Miércoles',
      3: 'Jueves',
      4: 'Viernes',
      5: 'Sábado',
      6: 'Domingo'
    },
    noAccordian: {
      recognitions: 'No hay información disponible sobre reconocimientos para este proveedor de atención en esta ubicación.',
      affiliations: 'No hay información disponible sobre afiliaciones para este proveedor de atención en esta ubicación.'
    },
    ratingsSlider: {
      closeRatingModal: 'Close Review Rating Modal',
      close: 'Cerrar',
      bcbs: 'BlueCross BlueShield'
    },
    ssoContent: {
      analyticsText: 'reviewThisDoctorResults',
      errorMessage:
        "Sorry, looks like something isn't working. Please refresh the page and try again. \n" +
        "If the problem continues, contact  <a href='contact-us' data-analytics='contactUsTasks' target='' \n" +
        "id='link1439353740480'>Technical Support</a> .",
      empty: '',
      ssoErrorType: '2',
      ssoSubType: 'vitalsSSO',
      ssoType: 'sso',
      linkText: 'Add a Review'
    },
    notAvail: 'No disponible',
    diamondCode: 'Anthem Diamond Provider',
    disclaimer: { viewMoreLabel: 'Ver más' },
    mapEndpoint: 'https://www.google.com/maps/dir/'
  },
  providerMoreDetails: {
    labels: {
      accreditStatus: 'Estado de acreditación:',
      adaAccess: 'Accesible según la ADA:',
      affiliation: 'Afiliaciones',
      careLevel: 'Nivel de atención:',
      culturalTraining: 'Formación en competencia cultural:',
      expertise: 'Área de especialización:',
      countiesServiced: 'Condados atendidos:',
      view: 'Ver',
      ihsp: 'Proveedores de servicios de salud para indígenas:',
      insurance: 'Planes de seguro',
      licenseNum: 'Número de licencia:',
      licenseType: 'Tipo de licencia:',
      locationLanguages: 'Idiomas hablados en el lugar:',
      no: 'No',
      patientAgePref: 'Preferencia de edad del paciente:',
      patientGenderPref: 'Preferencia de género del paciente:',
      providerId: 'Identificador de proveedor nacional (NPI):',
      services: 'Servicios',
      speciality: 'Especialidades:',
      viewAll: 'Ver todo',
      yes: 'Sí'
    },
    accreditationCode: {
      tp_accredited: 'Acreditado',
      tp_notaccredited: 'No acreditado'
    },
    tooltips: {
      npi: 'El Identificador Nacional de Proveedores (NPI) es un número de identificación único de diez dígitos emitido por el gobierno federal para identificar a los proveedores de atención médica.',
      licenseNumber: 'Los proveedores de atención a quienes se les ha otorgado permiso para practicar la medicina en un estado reciben un número de licencia para ese estado.',
      areaOfExpertise:
        'La información sobre Áreas de Especialización es proporcionada de manera voluntaria por los profesionales. Por lo tanto, las búsquedas de profesionales basadas en estos elementos pueden no resultar en una lista completa de todos los profesionales que coinciden con el área especificada. Aunque esta lista se actualiza continuamente, la participación de los profesionales, así como los datos sobre Áreas de Especialización, no están garantizados y pueden estar sujetos a cambios. Antes de recibir atención, por favor pregunte a su profesional si todavía participa en la red, acepta nuevos pacientes y proporciona servicios que cumplan con los criterios que ha seleccionado.',
      competenceTraining: 'La capacitación en competencia cultural educa a los proveedores de atención sobre cómo satisfacer las necesidades de diversos pacientes.',
      indianHSP:
        'Un proveedor de servicios de salud por contrato operados por el Servicio de Salud Indígena, una tribu india, una organización tribal o una organización indígena urbana (conocida como I/T/Us). Los servicios de estos proveedores pueden limitarse únicamente a miembros tribales.',
      ada: 'El proveedor de atención nos informa la información de accesibilidad de la ADA y puede cambiar.'
    },
    noAccordian: {
      insurance: 'No hay información disponible sobre el seguro para este proveedor de atención en esta ubicación.',
      services: 'No hay información disponible sobre los servicios para este proveedor de atención en esta ubicación.'
    },
    preference: {
      birth: 'Nacimiento',
      years: 'años',
      older: ' y más',
      to: 'a',
      both: 'Both',
      all: 'All',
      maleFemale: 'Masculino y Femenino'
    },
    notAvail: 'No disponible'
  },
  incorrectDataComponent: {
    labels: {
      lastName: 'Su apellido:',
      firstName: 'Su nombre:',
      phoneNumber: 'Su número de teléfono:',
      email: 'Su correo electrónico:',
      correctInformation: '¿Qué información es incorrecta?',
      correctInfoPlaceholder:
        'Indique claramente qué información es incorrecta, como una dirección, un número de teléfono, una especialidad o un horario de atención incorrectos.Si es posible, incluya la información correcta.\n\nTenga en cuenta que no podremos responder ninguna pregunta enviada con este formulario.',
      submission: 'Enviar',
      cancel: 'Cancelar',
      modelTitle: 'Reportar información incorrecta',
      wrongInfo: 'Puede reportar información incorrecta sobre este proveedor de cuidado médico de tres maneras:',
      emailText: 'Enviar un correo electrónico a',
      callText: 'Llamar',
      formText: 'Completar y enviar el formulario a continuación',
      successLabel: '¡Entendido! Gracias por informarnos.',
      noticeInfo: '¿Ha notado algún error? ',
      formOption: ' Infórmenos si encuentra información incorrecta para que podamos corregirla.',
      phoneLabel: '<strong>¿Ha notado algún error? </strong> Para informar información incorrecta, llame al',
      contactInfo: 'Si tiene preguntas sobre su plan de salud, reclamaciones o beneficios, <a target="_blank" href="{contactUs}">comuníquese con Servicios para miembros.</a>',
      reportIncorrectBtn: 'Informar información incorrecta',
      successMessage: '¡Entendido! Gracias por informarnos.'
    },
    errorMessage: {
      firstNameRequiredMsg: 'Se requiere el nombre.',
      lastNameRequiredMsg: 'Se requiere el apellido.',
      phoneRequiredMsg: 'Se requiere el número de teléfono.',
      phoneInvalidMsg: 'Ingrese un número de teléfono válido. El número de teléfono debe ser numérico..',
      emailRequiredMsg: 'Se requiere el correo electrónico.',
      emailErrMsg: 'Ingrese una dirección de correo electrónico válida.',
      correctInfoRequiredMsg: 'Se requiere la información correcta.',
      failureMsg: 'Hubo un problema y no recibimos los datos del formulario. Intenta otra vez.',
      phoneMaxLengthmsg: 'Ingrese un número de teléfono válido.',
      lastNameMaxLengthMsg: 'El apellido debe tener exactamente 100 dígitos.',
      firstNameMaxLengthMsg: 'El nombre debe tener exactamente 100 dígitos.',
      minLengthMsg: 'Ingrese un número de teléfono válido.'
    }
  },
  pcpList: {
    pcpId: 'PCP IDs',
    pcpNotAvail: 'PCP IDs no disponible',
    back: 'Volver'
  },
  recognitionCard: {
    recognition: 'Reconocimientos',
    recognitionNotAvail: 'Reconocimientos no disponible',
    back: 'Volver',
    learnMoreLabel: 'Más información',
    close: 'Cerrar'
  },
  serviceCard: {
    services: 'Servicios',
    back: 'Volver'
  },
  insuranceCard: {
    insurance: 'Planes de seguro',
    back: 'Volver'
  },
  affiliationCard: {
    affiliation: 'Afiliaciones',
    back: 'Volver',
    go: 'Ir',
    close: 'Cerrar',
    list: 'Lista'
  },
  disclaimerCard: {
    back: 'Volver',
    close: 'Cerrar',
    disclaimer: 'Descargo'
  },
  licenseCard: {
    license: 'Números de licencia',
    back: 'Volver'
  },
  specialityCard: {
    speciality: 'Especialidades',
    back: 'Volver'
  },
  countiesCard: {
    counties: 'Condados atendidos',
    back: 'Volver'
  },
  titleCard: {
    description: 'Este proveedor tiene los siguientes títulos:',
    back: 'Back'
  },
  locationsCard: {
    locationsFor: 'Ubicaciones para ',
    selectAddress: 'Seleccione una dirección a continuación para ver información de ubicación',
    costEstimate: 'Estimación de costos',
    county: 'Condado'
  },
  pagination: {
    resultCountText: 'Showing <b>{providerCountFirst}-{providerCountLast}</b> of <b>{totalRecords} results</b><br/>within {searchMiles} miles of {searchLocation}',
    resultCountLessProviderText: '<b>{totalRecords}</b> resultados dentro de <b>{searchMiles} millas</b> de <b>{searchLocation}</b>'
  },
  disclaimerComponent: {
    labels: {
      important: 'IMPORTANTE',
      showMore: 'Ver más',
      showLess: 'Muestra menos',
      showMoreDisclaimer: 'Selecciona para obtener Más detalles sobre descargo de responsabilidades',
      showLessDisclaimer: 'Seleccione para obtener Menos detalles sobre descargo de responsabilidades'
    },
    ariaLabel: {
      showMore: 'Show More refine filters',
      showLess: 'Show Less refine filters'
    },
    error: 'No podemos completar tu solicitud en este momento. Vuelve a intentar más tarde.'
  },
  compareProvidersComponent: {
    actions: {
      print: 'Imprimir',
      addAnother: 'Agregar otro',
      addToCareTeam: 'Agregar al equipo de atención',
      remove: 'Eliminar',
      viewDetails: 'Ver detalles',
      reviews: 'reseñas',
      replace: 'Reemplazar',
      backToCompareResults: 'Volver a Comparar resultados'
    },
    labels: {
      selected: 'seleccionado',
      ratings: 'calificaciones',
      notAvailable: 'No disponible',
      compareResults: 'Comparar resultados',
      close: 'Cerrar',
      memberCostEstimate: 'Estimación de costo para miembros',
      providerBillEstimate: 'Estimación de factura del proveedor',
      gender: 'Género',
      specialities: 'Especialidades',
      locations: 'Ubicación',
      map: 'Mapa',
      providerEmail: 'Correo electrónico del proveedor',
      providerWebsite: 'Sitio web del proveedor',
      officeHours: 'Horario de consulta',
      impairmentService: 'Servicio de discapacidad',
      culturalCompetenceTraining: 'Capacitación en competencia cultural',
      aDAAccessible: 'Accesible según la ADA',
      recognition: 'Reconocimiento/Premios',
      rating: 'Calificaciones',
      pcpId: 'ID de PCP',
      servicesAvailable: 'Servicios disponibles',
      acceptsNewPatients: 'Acepta nuevos pacientes',
      languagesSpoken: 'Otros idiomas',
      languagesSpokenAtPractice: 'Idiomas hablados en la práctica',
      medicalSchoolEducation: 'Educación en la escuela de medicina',
      nationalProviderIdentifier: 'Identificación Nacional del Proveedor',
      familyAccreditation: 'Acreditación familiar',
      areaOfExpertise: 'Área de especialización',
      levelOfCare: 'Nivel de atención',
      ethnicity: 'Etnicidad',
      patienceAgePreference: 'Preferencia de edad del paciente',
      patienceGenderPreference: 'Preferencia de género del paciente',
      indianHealthServiceProvider: 'Proveedor de servicios de salud indio',
      licenseType: 'Tipo de licencia',
      providerLicensingInfo: 'Información de licencia del proveedor',
      networkType: 'Tipo de red',
      affiliations: 'Afiliación',
      insurancePlans: 'Planes de seguro aceptados',
      maleOnlyText: 'Solo Masculino',
      femaleOnlyText: 'Solo Femenino',
      allText: 'Todos',
      bothText: 'Masculino y Femenino',
      yesText: 'Sí',
      noText: 'No',
      yearsText: 'años',
      yearOlderText: 'años o más',
      birthToText: 'Nacimiento a',
      birthOlderText: 'Nacimiento y más',
      medicalNetwork: 'En la red médica de este plan',
      visionNetwork: 'En la red visión de este plan',
      dentalNetwork: 'En la red dental de este plan',
      selectedCompareMessage: 'Use las casillas de verificación para seleccionar 2',
      secure: {
        medicalNetwork: 'En la red de su plan médico',
        visionNetwork: 'En la red de su plan visión',
        dentalNetwork: 'En la red de su plan dental'
      },
      boardCertifiedMsg: ' - Certificado por la Junta',
      notBoardCertifiedMsg: ' - No Certificado por la Junta',
      naBoardCertifiedMsg: ' - Certificación de la Junta No Aplicable',
      miles: 'millas',
      male: 'M',
      maleTxt: 'MASCULINO',
      female: 'F',
      femaleTxt: 'FEMENINO',
      both: 'B',
      all: 'A',
      bothTxt: 'AMBOS',
      allTxt: ' TODOS',
      dental: 'DENTAL',
      vision: 'VISIÓN',
      medical: 'MÉDICO',
      boardCertified: 'CERTIFICADO POR LA JUNTA',
      notBoardCertified: 'NO CERTIFICADO POR LA JUNTA',
      naBoardCertified: 'CERTIFICACIÓN DE LA JUNTA NO APLICABLE',
      hospital_affiliations: 'Afiliaciones a hospitales',
      medical_group_affiliations: ' Afiliaciones a Grupos médicos',
      doctor_affiliations: 'Médicos de atención primaria afiliados',
      specialty_affiliations: 'Especialistas afiliados',
      urgentcare_affiliations: 'Centros de Atención de Urgencias afiliados',
      dental_affiliations: 'Proveedores afiliados',
      clinic_affiliations: 'Clínicas afiliadas',
      pcpEnrollmentId: 'PCP Id:',
      pcpEnrollmentIdPaper: 'PCP ID/id de inscripción (En papel/En línea): ',
      pcpEnrollmentIdPhone: 'PCP ID/id de inscripción (Teléfono): ',
      recognitions: 'Reconocimientos',
      open24Hours: 'Abierto las 24 horas',
      notOpen: 'No abierto',
      virtualCareOnlyLabel: 'SOLO VIRTUAL',
      onlinePharmacyLabel: 'FARMACIA EN LÍNEA',
      onlinePharmcyTooltip: 'Ahorra un viaje a la farmacia y disfruta de la comodidad de la entrega a domicilio. Te entregaremos tus recetas con envío estándar gratuito.',
      telehealth: 'Telemedicina',
      telehealthNote: 'Este profesional de la salud o ubicación ofrece acceso virtual en tiempo real a algunos o todos los servicios médicos que normalmente se brindan en el consultorio.',
      doingBusinessAs: 'Haciendo negocios como:',
      diamondProvider: 'Proveedor de diamantes Anthem',
      weekdays: {
        0: 'M',
        1: 'Tu',
        2: 'W',
        3: 'Th',
        4: 'F',
        5: 'Sa',
        6: 'Su'
      },
      viewMap: 'Ver mapa',
      hideMap: 'Ocultar mapa'
    }
  },
  pharmacylabels: {
    onlinePharmacy: 'Farmacia en línea'
  },
  pricingCmpContent: {
    pricingDropdown: [
      {
        label: 'procedimiento',
        value: 'procedure'
      },
      {
        label: 'Código Facturación',
        value: 'cptcode'
      }
    ],
    defaultPos: 'Selecciona',
    selectPricingDropdown: 'Selecciona un código Facturación',
    cptCode: 'Busca un código médico',
    procedureCode: 'Seleccione un procedimiento para ver el presupuesto para esta ubicación',
    headingProcedure: 'Precio estimado',
    defaultSelectedCode: 'Ingresa el código facturación o término relacionado',
    defaultModifierCode: 'No aplica',
    posList: 'Dónde recibes cuidado médico',
    modifierList: 'Opciones de código médico',
    modifierCodeDisclaimer: 'Un procedimiento o servicio puede tener variaciones que afecten los costos.',
    posDisclaimer: 'Un procedimiento o servicio puede tener diferentes costos dependiendo del lugar donde se realice.',
    noMedicalCodeSelected: 'Ningún código de Facturación seleccionado',
    noMedicalCodeDesc: 'Selecciona un código Facturación y te mostraremos los precios estimados para los servicios dentro de la red.',
    allowedByPlan: 'Permitido por el plan',
    allowedByPlanDesc:
      'Lo máximo que tu plan pagaría por este servicio. Este monto es un estimado y puede cambiar según los beneficios de tu plan y el tipo de proveedor de cuidado que presenta la reclamación.',
    planPay: 'El plan paga',
    planPayDesc: 'El monto que estaría cubierto por tu plan para este servicio. Este monto se calcula aplicando cualquier copago, deducible y coseguro al monto permitido por el plan.',
    whatYouPay: 'Estimación de Costos',
    whatYouPayDesc:
      'El monto que adeudarías después de que se hayan aplicado los descuentos, ahorros y pagos del plan. Este monto también depende de cuánto queda por pagar del máximo de tus gastos de bolsillo y el deducible. (Para ver el progreso de tus gastos de bolsillo y deducible, ve a Mis planes > Beneficios médicos > Progreso del plan).',
    costDetails: 'Cost Details',
    planNotCovered: 'Tu plan no cubre este procedimiento. <a target="_blank" href="/member/contact-us">Contáctanos</a> si tienes preguntas sobre los beneficios de tu plan.',
    planNotCoveredAriaLabel: 'Tu plan no cubre este procedimiento. Contáctanos si tienes preguntas sobre los beneficios de tu plan.',
    benefitDenied: 'No se pueden mostrar algunos datos de costos',
    cptAlertMsg: 'Antes de programar una cita, consulta con este proveedor de cuidado médico para confirmar que realiza este procedimiento o servicio.',
    aboutThisBillingCode: 'About this billing code',
    cptPdtAlertMsg: 'No tenemos un presupuesto para este proveedor de cuidado médico que realiza este servicio. Busca otro código médico o',
    backToProviderList: 'encuentra proveedores de cuidado médico que realicen este servicio.',
    dataAnalytics: {
      selectProcDropDetailFindCare: 'selectProcDropDetailFindCare',
      selectMedCodeDropDetailFindCare: 'selectMedCodeTypeDetailFindCare',
      selectProcMedDropDetailFindCare: 'selectProcMedDropDetailFindCare',
      selectPlaceServiceDropDetailFindCare: 'selectPlaceServiceDropDetailFindCare',
      selecteModifierDropDetailFindCare: 'selectMedCodeDropDetailFindCare',
      infoPlaceServiceDetailFindCare: 'infoPlaceServiceDetailFindCare',
      infoMedCodeDetailFindCare: 'infoMedCodeDetailFindCare',
      payCostDetailFindCare: 'payCostDetailFindCare'
    },
    noSuggestionsFound: 'No se encontraron sugerencias'
  },
  eycProcedure: {
    eycCostinfoMsg: '¿Qué se incluye en estos costos estimados?',
    totalEstimatedCostText: '<strong> Factura total. </strong> El monto que pagarías si no tuvieras seguro.',
    noBenefitsmsg: 'Debido a que la información de tu plan no está disponible, solo podemos mostrar un rango y un promedio estimados.',
    planPaysMsg: '<strong> Ahorros del plan. </strong> El monto que ahorrarías por ser miembro. Este ahorro es el resultado de nuestra capacidad de negociar mejores precios.',
    youPayMsg: '<strong> Tu responsabilidad.</strong> </strong> El monto que adeudarías después de que se hayan aplicado los descuentos, ahorros y pagos del plan.',
    deductibleMsg: '<strong>Aplicado al deducible.</strong> El monto que contaría para tu deducible, que es el monto que pagas por servicios médicos antes de que comencemos a compartir los costos.',
    coinsuranceMsg:
      '<strong>Coseguro.</strong> Un porcentaje fijo del costo del cuidado médico que se espera que cubras después de que se hayas alcanzado tu deducible total. Tu coseguro es del {COINSURANCE}%',
    coPayMsg: '<strong>Copago.</strong> El monto fijo en dólares que pagarías por ciertos servicios.',
    overChargeMsg:
      '<strong>Excedente.</strong> El monto de la factura no cubierto por tu plan porque excede los límites de tus beneficios para este procedimiento o tratamiento. Debes pagar el cargo excedente.',
    defaultCoinsuranceMsg: '',
    costBeforeHRA:
      '<Strong> Costo antes de que se apliquen los fondos de tu cuenta {ACCOUNT}. </Strong> El monto que tú cubrirías. Si están disponibles, se utilizarán los fondos de tu cuenta de gastos {ACCOUNT}. Si no, tendrías que pagar esta cantidad de tus gastos de bolsillo.',
    hraCostText: '<Strong> La cuenta {ACCOUNT} paga. </Strong> El monto en tu cuenta {ACCOUNT} que se aplicaría a tu factura.',
    hraNocostText: 'Lo sentimos, pero no podemos obtener el saldo de tu cuenta {ACCOUNT} en este momento. Los fondos de tu cuenta {ACCOUNT} podrían reducir la cantidad que pagas.',
    totalEstimatedCostRangeText: '<strong>Factura estimada.</strong> El monto que tendrías que cubrir de tu bolsillo. Este monto puede variar dependiendo de dónde elijas realizarte el procedimiento.',
    youPayRangeMsg: '<strong>Precio promedio.</strong> El monto que tendrías que cubrir de tu bolsillo. Este monto puede variar dependiendo de dónde elijas realizarte el procedimiento.',
    tooltiptitle: {
      selectforMoreInfo: 'Seleccione para obtener más información sobre'
    }
  },
  eycPricing: {
    headingProcedure: 'Precio estimado por procedimiento',
    selectProcedure: 'Selecciona un procedimiento',
    arialabelServices: 'servicios proporcionados por',
    arialabelIncluded: 'servicios incluidos cubiertos en',
    services: 'Servicios',
    included: 'Incluido',
    estimatedPrice: 'Precio estimado',
    estimatedBill: 'Factura del proveedor de atención',
    estimatedBillDesc: 'El monto promedio que este proveedor de cuidado ha acordado facturar por este servicio en tu plan. Esto también se conoce como el Monto permitido.',
    totalBill: 'Factura total',
    totalBillDesc: 'El monto que pagarías si no tuvieras seguro.',
    planSavings: 'El plan paga',
    planSavingsDesc: 'La cantidad que cubriría tu plan por este servicio.',
    isBenefitDeniedMsg: '¡Lo sentimos! Tu plan no cubre este procedimiento.',
    yourResponsibility: 'Estimación de costos',
    yourResponsibilityDesc:
      'El monto que adeudarías después de que se hayan aplicado los descuentos, ahorros y pagos del plan. Este monto también depende de cuánto queda por pagar del máximo de tus gastos de bolsillo y el deducible. (Para ver el progreso de tus gastos de bolsillo y deducible, ve a Mis planes > Beneficios médicos > Progreso del plan).',
    aboutThisProcedure: 'Acerca de este procedimiento',
    footerText: 'El monto de Lo que podrías pagar incluye el pago de tu deducible restante y cualquier coseguro o copago.',
    remainingDeductible: 'Aplicado al deducible',
    remainingDeductibleDesc: 'El monto que contaría para tu deducible, que es el monto que pagas por servicios médicos antes de que comencemos a compartir los costos.',
    coinsurance: 'Coseguro',
    coinsuranceDesc: 'Un porcentaje fijo del costo del cuidado médico que se espera que cubras después de que se hayas alcanzado tu deducible total. Tu coseguro es del {COINSURANCEPRECENT}%.',
    defaultCoinsuranceDesc: 'Un porcentaje fijo del costo del cuidado médico que se espera que cubras después de que se hayas alcanzado tu deducible total.',
    copay: 'Copago',
    copayDesc: 'El monto fijo en dólares que pagarías por ciertos servicios.',
    overage: 'Excedente',
    overChargeDesc: 'El monto de la factura no cubierto por tu plan porque excede los límites de tus beneficios para este procedimiento o tratamiento. Debes pagar el cargo excedente.',
    hraCost: 'La cuenta {ACCOUNT} paga',
    hraDesc: 'El monto en tu cuenta {ACCOUNT} que se aplicaría a tu factura.',
    averagePrice: 'Precio promedio',
    averageDesc: 'El monto que tendrías que cubrir de tu bolsillo. Esta cantidad puede variar según dónde elijas que se realice el procedimiento.',
    averageDentalDesc: 'El monto promedio que este proveedor de cuidado médico ha acordado facturar por este servicio en tu plan.',
    benefitInfoError: 'Debido a que la información de tu plan no está disponible, solo podemos mostrar un rango y un promedio estimados.',
    noCostDataError: 'No podemos mostrar una estimado de costos para este procedimiento.',
    airalblfooterInfoLink: 'presione enter para obtener más información sobre su responsabilidad',
    airalblfooterText: 'Esto también incluye pagar el deducible restante y cualquier coseguro o copago.',
    closeModal: 'botón, seleccione para cerrar el modal'
  },
  locationsComponent: {
    mapmarker: 'selecciona para acceder a las direcciones en un mapa',
    phone: 'Presiona Enter para llamar a este médico',
    drivingTime: 'Tiempo de conducción ',
    labels: {
      onlineLocation: 'Ubicación – En línea',
      accessibility: 'Accesibilidad',
      officeHours: 'Horario del consultorio',
      open24Hours: 'Abierto las 24 horas',
      miles: 'Millas',
      getDirections: 'Cómo llegar',
      drivingDirection: 'Distancia en auto & Indicaciones de cómo llegar',
      email: 'Correo electrónico:',
      provWebSiteUrl: 'Sitio web del proveedor/URL al sitio web:',
      locationCost: 'Estimados de precios son específicos de esta ubicación',
      location: 'Direccion',
      adminOffice: 'Oficina administrativa solamente',
      appointmentSchedule: 'Reserva una cita'
    },
    tooltips: {
      adaDisclaimer:
        'La información sobre la accesibilidad para personas con discapacidades es reportada por el proveedor de manera voluntaria y está sujeta a cambios. Comuníquese con el proveedor para confirmar el contenido y la precisión de esta información.',
      adaAccDisclaimer: 'La información de accesibilidad de la ADA es reportada por el proveedor médico.',
      cctDisclaimer: 'La capacitación en competencias culturales educa a los proveedores de cuidado médico sobre cómo apoyar las necesidades de los diversos pacientes.'
    }
  },
  virtualCareProvider: {
    virtualOptions: 'Opciones virtuales',
    closeVirtualOptions: 'Cerrar Opciones virtuales',
    virtualOnly: 'Virtual Only',
    connectNow: 'Connect Now',
    goToVirtualCare: 'Go To Virtual Care',
    ssoComponent: {
      linkTarget: '_blank',
      ssoType: 'sso',
      ssoSubType: 'locateVitalsSSO',
      ssoErrorType: '0',
      ssoErrorClass: 'outsideTestClass',
      ssoErrorRedirectUrl: '/custom-error',
      ssoErrorPageHeader: 'TEST ERROR PAGE HEADER',
      errorMessage: 'SSO Link Fails to Load - DISMISSABLE',
      linkClasses: 'cttTestLinkClass',
      iconBeforeClasses: '',
      iconAfterClasses: 'fa fa-external-link gray',
      linkText: ' TEST Link 1',
      uniqueId: '1'
    }
  },
  commonHealthCareContent: {
    providers: [
      { code: 'P', name: 'Médicos y profesionales médicos', tag: 'physMedCommonFindCare' },
      { code: 'V', name: 'Profesionales de la visión', tag: 'visionCommonFindCare' },
      { code: 'D', name: 'Profesionales dentales', tag: 'dentalCommonFindCare' },
      { code: 'B', name: 'Profesionales de salud conductual', tag: 'behavioralCommonFindCare' },
      { code: 'H', name: 'Hospitales y centros médicos', tag: 'hospFacCommonFindCare' },
      { code: 'R', name: 'Farmacias', tag: 'rxCommonFindCare' },
      { code: 'L', name: 'Laboratorios, patología y radiología', tag: 'labsPathRadCommonFindCare' },
      { code: 'G', name: 'Grupos médicos', tag: 'medGroupsCommonFindCare' },
      { code: 'U', name: 'Atención de urgencias', tag: 'urgCareCommonFindCare' },
      { code: 'C', name: 'Clínicas en locales comerciales', tag: 'retHealthCommonFindCare' },
      { code: 'M', name: 'Equipo médico', tag: 'medEquipCommonFindCare' },
      { code: 'O', name: 'Otros servicios médicos', tag: 'otherServCommonFindCare' },
      { code: 'A', name: 'Profesional de la audición', tag: 'hearingCommonFindCare' },
      { code: 'CA-OSP', name: 'Otros proveedores de servicios', tag: 'otherServicesProviders' }
    ],
    procedures: [
      { code: '22101', name: 'Vacuna contra la influenza', tag: 'fluImmunizationCommonFindCare' },
      { code: '19402', name: 'Visita al consultorio de atención primaria', tag: 'primCareCommonFindCare' },
      { code: '19501', name: 'Visita al consultorio de salud conductual', tag: 'behavHealthCommonFindCare' },
      { code: '03601', name: 'Detección de colonoscopia', tag: 'colonCommonFindCare' },
      { code: '00502', name: 'Parto vaginal', tag: 'vagDelivCommonFindCare' },
      { code: '13601', name: 'Vasectomía', tag: 'vasectCommonFindCare' },
      { code: '19101', name: 'Terapia física', tag: 'physTherCommonFindCare' },
      { code: '35401', name: 'Cuidado prenatal', tag: 'prenatalCommonFindCare' },
      { code: '19001', name: 'Tratamiento quiropráctico', tag: 'chiropracticCommonFindCare' },
      { code: '00501', name: 'Parto por cesárea', tag: 'csectionCommonFindCare' },
      { code: '51920', name: 'Tratamiento de dermatitis o erupción', tag: 'dermRashCommonFindCare' }
    ],
    dntlProcedures: [
      { code: 'D0120', name: 'Examen oral periódico', tag: 'periodicOralCommonFindCare' },
      { code: 'D1110', name: 'Profilaxis - adultos', tag: 'propAdultCommonFindCare' },
      { code: 'D0274', name: 'Radiografía interproximal (Bitewings) - cuatro placas', tag: 'bitewingsCommonFindCare' },
      { code: 'D0220', name: 'Radiografia periapical, primera placa', tag: 'firstPeriapicalCommonFindCare' },
      { code: 'D0230', name: 'Radiografia periapical, cada placa adicional', tag: 'additionalPeriapicalCommonFindCare' },
      { code: 'D1120', name: 'Profilaxis - niños', tag: 'propChildCommonFindCare' },
      { code: 'D1206', name: 'Barniz de fluoruro', tag: 'flourideVarnishCommonFindCare' },
      { code: 'D0150', name: 'Examen oral integral', tag: 'oralExamCommonFindCare' },
      { code: 'D2392', name: 'Resina compuesta - 2 superficies, muela', tag: 'resinTwoBackCommonFindCare' }
    ],
    medDntlProcedures: [
      { code: '22101', name: 'Vacuna contra la influenza', tag: 'fluImmunizationCommonFindCare' },
      { code: '19402', name: 'Visita al consultorio de atención primaria', tag: 'primCareCommonFindCare' },
      { code: '19501', name: 'Visita al consultorio de salud conductual', tag: 'behavHealthCommonFindCare' },
      { code: '03601', name: 'Detección de colonoscopia', tag: 'colonCommonFindCare' },
      { code: '00502', name: 'Parto vaginal', tag: 'vagDelivCommonFindCare' },
      { code: '13601', name: 'Vasectomía', tag: 'vasectCommonFindCare' },
      { code: '19101', name: 'Terapia física', tag: 'physTherCommonFindCare' },
      { code: '35401', name: 'Cuidado prenatal', tag: 'prenatalCommonFindCare' },
      { code: '19001', name: 'Tratamiento quiropráctico', tag: 'chiropracticCommonFindCare' },
      { code: '00501', name: 'Parto por cesárea', tag: 'csectionCommonFindCare' },
      { code: '51920', name: 'Tratamiento de dermatitis o erupción', tag: 'dermRashCommonFindCare' },
      { code: 'D0120', name: 'Examen oral periódico', tag: 'periodicOralCommonFindCare' },
      { code: 'D1110', name: 'Profilaxis - adultos', tag: 'propAdultCommonFindCare' },
      { code: 'D0274', name: 'Radiografía interproximal (Bitewings) - cuatro placas', tag: 'bitewingsCommonFindCare' }
    ]
  },
  pharmacy: {
    back: 'Volver',
    typeOfPharmacy: 'Tipo de farmacia',
    open24Hours: 'Abierto las 24 X 7',
    features: 'Caracteristicas',
    addReview: 'Agregar una reseña',
    workingHours: 'Horas hoy',
    npi: 'Identificador de proveedor nacional',
    license: 'State licencia',
    accessibllity: 'Accesibilidad',
    ada: 'Accesible según la ADA',
    ihsp: 'Indian Health Services Provider',
    tty: 'TTY Number',
    storeNumber: 'Store Number',
    pharmacyType: 'Tipo de farmacia',
    recognitions: 'Reconocimientos',
    print: 'Imprimir',
    close: 'Cerrar',
    avatar: 'farmacia',
    accessiblityContent: 'Esta tienda es accesible para clientes con discapacidades. Los animales de servicio también son bienvenidos',
    notAvail: 'No disponible',
    yes: 'Si',
    no: 'No',
    mapEndpoint: 'https://www.google.com/maps/dir//',
    tooltips: {
      aoeDisclaimer:
        'Los profesionales proporcionan información de las áreas de experiencia en forma voluntaria. Por lo tanto, de las búsquedas de profesionales basadas en estos elementos no se pueden obtener listados completos de todos los profesionales que coinciden con un área específica. Si bien este listado se actualiza permanentemente, la participación de los profesionales así como la información relacionada con las áreas de experiencia no están garantizadas y pueden estar sujetas a cambios. Antes de recibir atención, pregúntele al profesional si aún participa en la red, acepta nuevos pacientes y brinda servicios que cumplen con los criterios que usted seleccionó.',
      anpDisclaimer: 'Estamos mostrando esta información según lo informado por el médico. Comunícate con el médico para confirmar.',
      pcpDisclaimer:
        'Confirme este número mediante la revisión de la información en la sección de afiliaciones a continuación. PCP en los grupos pueden tener diferentes identificadores basados en ubicación de la oficina. Seleccione el ID que coincide con la oficina que desea visitar.',
      npiDisclaimer:
        'El Identificador de Proveedor Nacional (NPI, por sus siglas en inglés) es un número único de diez dígitos emitido por el gobierno federal para identificar a los proveedores de cuidado médico.',
      licenseNoDisclaimer: 'A los proveedores de cuidado médico a quienes se les ha otorgado permiso para ejercer la medicina en un estado se les asigna un número de licencia para ese estado. ',
      changeLocationDisclaimer: 'Es posible que algunas de las ubicaciones de este proveedor de cuidado médico no estén en la red de tu plan',
      abrDisclaimer:
        'Esta información ha sido comunicada por el médico de forma directa y actualizada según lo informado para cada plan de. Para asegurar resultados exactos, asegúrese de que tiene un plan seleccionado al utilizar esta opción.',
      specDisclaimer: 'Specialists in the examination, diagnosis, treatment and management of diseases and disorders of the eye and visual systems.',
      serviceAvailDisclaimer: 'Ofrece descuentos adicionales en suministros y servicios no cubiertos asociados con tu plan de visión. Habla con tu proveedor sobre posibles descuentos.',
      ihspDisclaimer:
        'Un proveedor de servicios de salud por contrato operados por el Servicio de Salud Indígena, una tribu indígena, una organización tribal o una organización indígena urbana (conocida como I/T/Us). Los servicios de estos proveedores pueden estar limitados únicamente a miembros de la tribu.',
      medicaid: {
        MDCFLG: 'El proveedor identificado también acepta Medicaid',
        NMDCFLG: 'El proveedor identificado no acepta Medicaid'
      }
    },
    pharmacyFeatures: {
      open24HoursADay: 'Abierto las 24 horas del día',
      ninetyDayRetail: 'Retail 90',
      driveThru: 'Conduzca a través del servicio',
      onsiteMedicalClinic: 'Clínica médica en el sitio',
      providesFluShots: 'Vacunas contra la gripe',
      vaccinationIndicator: 'Vacunas',
      bloodPressureScreening: 'Exámenes de presión arterial',
      preparesCompounds: 'Medicamentos compuestos',
      dme: 'Equipo médico duradero',
      preferred: 'Preferida',
      rxM90Pharmacy: 'Rx Maintenance 90',
      homeDelivery: 'Entrega a domicilio'
    },
    pharmacydispenseTypes: {
      '0': {
        name: 'Desconocido',
        description: 'Desconocido'
      },
      '1': {
        name: 'Farmacia comunitaria/minorista',
        description:
          'Una farmacia donde los farmacéuticos almacenan, preparan y dispensan preparaciones medicinales y/o recetas médicas para una población local de pacientes de acuerdo con las leyes federales y estatales.'
      },
      '4': {
        name: 'Farmacia de cuidado a largo plazo',
        description:
          'Una farmacia que dispensa preparaciones medicinales entregadas a pacientes que residen en un centro de enfermería especializada o intermedio que incluye hospicios, centros de vida asistida y hogares grupales.'
      },
      '5': {
        name: 'Farmacia de pedidos por correo',
        description:
          'Los farmacéuticos preparan o dispensan recetas y medicamentos utilizando transportistas comunes para entregar los medicamentos a los pacientes o cuidadores. Asesoran a pacientes o cuidadores por teléfono o correo electrónico.'
      },
      '6': {
        name: 'Proveedor de terapia de infusión en el hogar',
        description:
          'Brinda atención a pacientes con afecciones agudas o crónicas generalmente relacionadas con la administración parenteral de medicamentos administrados a través de catéteres y/o agujas en el hogar y sitios alternativos.'
      },
      '7': {
        name: 'Dispensario que no es farmacia',
        description:
          'Un sitio que no es una farmacia que dispensa preparaciones medicinales bajo la supervisión de un médico a los pacientes para su autoadministración (por ejemplo, consultorios médicos, salas de emergencias, centros de atención urgente, etc.).'
      },
      '8': {
        name: 'Farmacia de los Servicios de Salud Indígenas/Tribales/Salud Indígena Urbana (I/T/U)',
        description:
          'Una farmacia operada por el Servicio de Salud de los Indígenas (IHS, por sus siglas en inglés), una tribu india, tribal o una organización india urbana como se definen en la Sección 4 de la ley de Mejora de la Atención Médica a los Indios Americanos, 25 USC 1603.'
      },
      '9': {
        name: 'Farmacia del Departamento de Asuntos de Veteranos (VA)',
        description:
          'Un lugar bajo la jurisdicción del Departamento de Asuntos de Veteranos (VA, por sus siglas en inglés) donde se dispensan medicamentos y se brinda atención farmacéutica a los veteranos inscritos.'
      },
      '11': {
        name: 'Farmacia institucional',
        description:
          'Una farmacia en un hospital o institución (pacientes hospitalizados) utilizada por los farmacéuticos para la preparación y entrega de preparaciones medicinales para ser administradas al paciente por personal de enfermería o autorizado.'
      },
      '12': {
        name: 'Farmacia de la Organización de Atención Médica Administrada',
        description:
          'Una farmacia propiedad de una Organización de Atención Médica Administrada (MCO, por sus siglas en inglés) utilizada por los farmacéuticos para la composición y distribución de preparaciones medicinales únicamente a los miembros cubiertos de esa Organización.'
      },
      '13': {
        name: 'Equipo medico duradero (DME)',
        description:
          'Un proveedor de equipos médicos, como respiradores, sillas de ruedas, sistemas de diálisis en el hogar o sistemas de monitoreo que son prescritos por un médico para ser usados por un paciente en el hogar.'
      },
      '14': {
        name: 'Farmacia clínica',
        description:
          'Farmacia en una clínica, una sala de emergencias o un hospital (ambulatorio) que administra medicamentos a los pacientes para su autoadministración bajo la supervisión de un farmacéutico.'
      },
      '15': {
        name: 'Farmacia especializada',
        description:
          'Una farmacia que dispensa generalmente preparaciones medicinales de bajo volumen y alto costo a pacientes que se someten a terapias intensivas para el tratamiento de enfermedades crónicas, complejas y potencialmente mortales.'
      },
      '16': {
        name: 'Farmacia nuclear',
        description: 'Una farmacia dedicada a preparar y dispensar materiales radiactivos para usarse en imágenes nucleares y procedimientos médicos nucleares.'
      },
      '17': {
        name: 'Farmacia militar/de la Guardia Costera de los EE. UU.',
        description:
          'Una entidad del Departamento de Defensa o de la Guardia Costera de los EE. UU. Cuya función principal es almacenar, preparar y dispensar productos farmacéuticos y otros artículos asociados a los beneficiarios de los Servicios Uniformados.'
      },
      '18': {
        name: 'Farmacia magistral',
        description:
          'Se especializa en la preparación de componentes para la preparación de un medicamento como resultado de un pedido de un practicante médico o de una iniciativa basada en la relación practicante/paciente/farmacéutico.'
      },
      '19': {
        name: 'Equipo de oxígeno',
        description: 'Equipo de oxígeno'
      },
      '20': {
        name: 'Suministros para centros de enfermería',
        description: 'Suministros para centros de enfermería'
      },
      '21': {
        name: 'Equipos personalizados',
        description: 'Equipos personalizados'
      },
      '22': {
        name: 'Equipo de diálisis',
        description: 'Equipo de diálisis'
      },
      '23': {
        name: 'Nutrición parenteral y enteral',
        description: 'Nutrición parenteral y enteral'
      }
    }
  }
};
