export const PersonalizedPDFMapping = {
  personalizedMatchPdf: {
    snr_en_US: 'medicarePM_en.pdf',
    commercial_en_US: 'commercialPM_en.pdf',
    snr_es_US: 'medicarePM_es.pdf',
    commercial_es_US: 'commercialPM_es.pdf'
  },
  medicaidStateByPersonalizedMatchPdf: {
    medicaidDCAGP_en_US: 'medicaidDCAGP_en_US.pdf',
    medicaidFLCHA_en_US: 'medicaidFLCHA_en_US.pdf',
    medicaidGAAGP_en_US: 'medicaidGAAGP_en_US.pdf',
    medicaidINABS_en_US: 'medicaidINABC_en_US.pdf',
    medicaidKYABS_en_US: 'medicaidKYABC_en_US.pdf',
    medicaidMDWLP_en_US: 'medicaidMDWLP_en_US.pdf',
    medicaidNYABCBS_en_US: 'medicaidNYABCBS_en_US.pdf',
    medicaidWNYHWNY_en_US: 'medicaidWNYHWNY_en_US.pdf',
    medicaidTXWLP_en_US: 'medicaidTXWLP_en_US.pdf',
    medicaidWVUNC_en_US: 'medicaidWVUNC_en_US.pdf',
    medicaidFLSHC_en_US: 'medicaidFLSHC_en_US.pdf',
    medicaidLAHBL_en_US: 'medicaidLAHBL_en_US.pdf',
    medicaidCAABC_en_US: 'medicaidCAABC_en_US.pdf',
    medicaidOHABCBS_en_US: 'medicaidOHABCBS_en_US.pdf',
    medicaidWAWLP_en_US: 'medicaidWAWLP_en_US.pdf',
    medicaidWIABCBS_en_US: 'medicaidWIABCBS_en_US.pdf',
    medicaidTNWLP_en_US: 'medicaidTNWLP_en_US.pdf',
    medicaidVAABCBS_en_US: 'medicaidVAABCBS_en_US.pdf',
    medicaidARSCC_en_US: 'medicaidARSCC_en_US.pdf',
    medicaidDCAGP_es_US: 'medicaidDCAGP_es_US.pdf',
    medicaidFLCHA_es_US: 'medicaidFLCHA_es_US.pdf',
    medicaidFLSHC_es_US: 'medicaidFLSHC_es_US.pdf',
    medicaidGAAGP_es_US: 'medicaidGAAGP_es_US.pdf',
    medicaidINABS_es_US: 'medicaidINABS_es_US.pdf',
    medicaidKYABS_es_US: 'medicaidKYABS_es_US.pdf',
    medicaidMDWLP_es_US: 'medicaidMDWLP_es_US.pdf',
    medicaidNYABCBS_es_US: 'medicaidNYABCBS_es_US.pdf',
    medicaidWNYHWNY_es_US: 'medicaidWNYHWNY_es_US.pdf',
    medicaidTXWLP_es_US: 'medicaidTXWLP_es_US.pdf',
    medicaidWVUNC_es_US: 'medicaidWVUNC_es_US.pdf',
    medicaidLAHBL_es_US: 'medicaidLAHBL_es_US.pdf',
    medicaidCAABC_es_US: 'medicaidCAABC_es_US.pdf',
    medicaidOHABCBS_es_US: 'medicaidOHABCBS_es_US.pdf',
    medicaidWAWLP_es_US: 'medicaidWAWLP_es_US.pdf',
    medicaidWIABCBS_es_US: 'medicaidWIABCBS_es_US.pdf',
    medicaidTNWLP_es_US: 'medicaidTNWLP_es_US.pdf',
    medicaidVAABCBS_es_US: 'medicaidVAABCBS_es_US.pdf',
    medicaidARSCC_es_US: 'medicaidARSCC_es_US.pdf'
  }
};
