import { Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { ISlideoutMessage } from '../../../../../../../common/interfaces/iSlideoutMsg';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../../common/values/appSession';
import { BaseComponent } from '../../../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-slider-alert-message-cmp',
  templateUrl: './sliderAlertMessageCmp.html'
})
export class SliderAlertMessageComponent extends BaseComponent {
  title: string = '';
  close: string = '';
  message: string = '';
  modalRef: ModalRef<HTMLElement, any>;
  @ViewChild('AlertPanelTemplate') alertPanelTmpltRef: TemplateRef<HTMLElement>;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to show message in slideout panel
   * @param msg message to be shown in slideout panel
   */
  showMessage(msg: ISlideoutMessage) {
    this.title = msg.title;
    this.close = msg.close;
    this.message = msg.message;
    this.modalRef = this._sidePanel.open('right', this.alertPanelTmpltRef);
  }

  /**
   * Method to close side panel
   */
  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
