import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTagsHTML'
})
export class stripTagsHtmlPipe implements PipeTransform {
  transform(value: string): string {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;

    // Remove the title tag if it exists
    const titleTag = tempDiv.querySelector('title');
    if (titleTag) {
      titleTag.remove();
    }

    // Return the sanitized HTML content
    return tempDiv.innerHTML;
  }
}
