import { EventEmitter, Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { AppExternalCommands, Locale } from '../../../common/constants/app-constants';
import { AppEvents } from '../../../common/enums/appEvents';
import { IEventDetail } from '../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { PROVIDER_TYPE } from '../../../fad/search-providers/values/providerSearchConstants';
import { CARE_TEAM_TYPE } from '../constants/careCircleConstants';
import { IAdvocateDetails } from '../interfaces/iCareCircle';
import { CareTeamProvider, ICareProvider, ICareProviderRequest, ICareTeamEmailRequest, ICareTeamRequest, IProvider, IProviderRequest } from '../interfaces/iCareProvider';

@Injectable({ providedIn: 'root' })
export class CareCircleUtil {
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  constructor(private _eventHandler: EventHandler) {}

  navigateToIdCardPage() {
    const eventDetail: IEventDetail = {
      type: AppEvents[AppEvents.PF_APP_CMD_NAV],
      message: AppExternalCommands.RCP_ID_CARD.MSG,
      target: AppExternalCommands.RCP_ID_CARD.CMD,
      details: AppExternalCommands.RCP_ID_CARD.MSG,
      errorCode: null
    };
    this._pfAppCommandEvent.emit(eventDetail);
  }

  static buildCareCircleRequest(_appSession: AppSession, type: CARE_TEAM_TYPE): ICareProviderRequest {
    const _careTeamRequest: ICareProviderRequest = {} as ICareProviderRequest;
    _careTeamRequest.type = type;

    // mbrUid and contractUid is needed in request to pull loggedin or selected member info from cache.
    if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.mbrUid) {
      _careTeamRequest.mbrUid = _appSession.metaData.appContract.mbrUid;
      _careTeamRequest.contractUid = _appSession.metaData.appContract.contractUid;
      _careTeamRequest.locale = _appSession.metaData.locale ? _appSession.metaData.locale.toString() : Locale.ENGLISH;
      _careTeamRequest.brandCd = _appSession.metaData.brandCd;
      _careTeamRequest.isFuturePlan = _appSession.isFutureCoverage;
    }
    return _careTeamRequest;
  }

  static buildUpdateCareTeamRequest(_appSession: AppSession, providers: IProvider[]) {
    const _updateRequest: IProviderRequest = {};
    _updateRequest.careTeam = providers;

    // mbrUid and contractUid is needed in request to pull loggedin or selected member info from cache.
    if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.mbrUid) {
      _updateRequest.mbrUid = _appSession.metaData.appContract.mbrUid;
    }
    return _updateRequest;
  }

  static buildAddCareTeamRequest(_appSession: AppSession, provider: IProvider, mbrUid: string) {
    const _addRequest: ICareTeamRequest = {};
    _addRequest.careTeam = provider;

    // mbrUid and contractUid is needed in request to pull selected member info from cache.
    if (_appSession && _appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.memberList && _appSession.metaData.appContract.memberList.length > 0) {
      const _memberList = _appSession.metaData.appContract.memberList;
      const mbr = _memberList.filter((x: any) => x.mbrUid === mbrUid);
      if (mbr && mbr.length > 0) {
        _addRequest.mbrUid = mbr[0].mbrUid;
      }
      _addRequest.contractUid = _appSession.metaData.appContract.contractUid;
    }
    return _addRequest;
  }

  static bindCareProviders(careResponse: any) {
    const _careProviders: ICareProvider[] = [];
    if (careResponse) {
      for (const provider of careResponse?.data) {
        const _careProvider = {
          id: provider.provider.id,
          providerId: provider.provider.providerId,
          providerTypes: provider.providerTypes || [],
          firstNm: provider.provider.firstNm || '',
          lastNm: provider.provider.lastNm || '',
          fullNm: provider.provider.fullNm || '',
          nickName: provider.provider.nickName || '',
          enableDetailLink: provider.provider.enableDetailLink || '',
          address: provider.provider.address,
          phone: provider.provider.address ? provider.provider.address.phone : undefined,
          specialtyNames: this.getSpecialties(provider.specialties),
          assignedMemberList: provider.assignedMemberList,
          lastVisitDate: provider.lastVisitDate,
          modifiedNickname: provider.nickName || '',
          isRemoved: provider.isRemoved || false,
          npiList: provider.npiList || '',
          editNickname: false,
          isPCP: provider.isPCP,
          isFuturePCP: !!provider.isFuturePCP,
          showAssignPCP: !provider.isPCP && provider.showAssignPCP && careResponse.hasPCPFeature,
          showRemovePCP: provider.isPCP && provider.showAssignPCP && careResponse.hasPCPRemoveFeature,
          pcpList: provider.pcpList,
          pcpInfoList: provider.pcpInfoList,
          labels: provider.labels || [],
          healthSystem: provider.healthSystem,
          isVirtualProvider: provider.isVirtualProvider,
          callToAction: provider.callToAction,
          anp: provider.provider.anp,
          providerCategoryCode: provider.providerCategoryCode,
          specialties: provider.specialties,
          titleList: provider.provider.titleList,
          networkTypeCodes: provider.networkTypeCodes
        } as ICareProvider;

        const eVisitLink = _careProvider.healthSystem?.hseLinks?.externalLinks?.eVisitLink ?? '';
        const hseNurseLine = _careProvider.healthSystem?.hseLinks?.externalLinks?.hseNurseLine ?? '';
        const hseAnthemNurseLine = _careProvider.healthSystem?.hseLinks?.sydneyHealthLinks?.hseAnthemNurseLine ?? '';
        const hseSymptomChecker = _careProvider.healthSystem?.hseLinks?.hseSymptomChecker ?? '';

        if (_careProvider.healthSystem) {
          _careProvider.healthSystem.showVirtualCareSection = hseSymptomChecker !== '' || hseNurseLine !== '' || eVisitLink !== '' || hseAnthemNurseLine !== '';
          _careProvider.healthSystem.showNurseLineLink = hseNurseLine !== '' || hseAnthemNurseLine !== '';
        }
        if (provider.appointmentType) {
          _careProvider.appointmentType = provider.appointmentType;
        }
        if (provider.appointmentAvailable) {
          _careProvider.appointmentAvailable = provider.appointmentAvailable;
        }
        if (provider.npiList && provider.npiList.length > 0) {
          _careProvider.npiList = provider.npiList;
        }

        if (provider.isFuturePCP && provider.mbrPcpEffectiveDt !== 'undefined' && provider.mbrPcpEffectiveDt !== null && provider.mbrPcpEffectiveDt !== '') {
          _careProvider.mbrPcpEffectiveDt = provider.mbrPcpEffectiveDt;
        }

        _careProviders.push(_careProvider);
      }
    }
    return _careProviders;
  }

  static showOnlinePharmacy(careProviders: ICareProvider[] | CareTeamProvider[]) {
    if (careProviders) {
      careProviders.forEach((provider) => {
        if (provider?.providerTypes) {
          provider.isShipDirectToDoor = false;
          for (let type of provider.providerTypes) {
            const address = provider?.provider ? provider.provider?.address?.addressLineOne : provider?.address?.addressLineOne;
            if (type === PROVIDER_TYPE.PHARMACY && isEmpty(address)) {
              provider.isShipDirectToDoor = true;
              break;
            }
          }
        }
      });
    }
  }

  static bindAdvocates(careTeamResponse: any) {
    const advocateDetails: IAdvocateDetails[] = [];
    for (const careCircle of careTeamResponse) {
      const advocateDetail = {
        description: careCircle.description,
        email: careCircle.email,
        firstName: careCircle.firstName,
        lastName: careCircle.lastName,
        networkID: careCircle.networkID,
        phone: careCircle.serviceNumber,
        sme: careCircle.sme,
        schedule: careCircle.schedule,
        extension: careCircle.extension,
        isManager: false,
        isAdvocate: true
      };
      advocateDetails.push(advocateDetail);
    }
    return advocateDetails;
  }

  static bindManagers(careTeamResponse: any) {
    const advocateDetails: IAdvocateDetails[] = [];
    for (const careCircle of careTeamResponse) {
      const extension = careCircle.internalExt;
      const phone = careCircle.callbackNumber;
      const advocateDetail = {
        description: careCircle.description,
        email: careCircle.email,
        firstName: careCircle.firstName,
        lastName: careCircle.lastName,
        networkID: careCircle.networkID,
        phone,
        sme: careCircle.sme,
        schedule: careCircle.schedule,
        extension,
        isManager: true,
        isAdvocate: false
      };
      advocateDetails.push(advocateDetail);
    }
    return advocateDetails;
  }

  static Sort(input: any, config: string | string[]): any {
    if (!Array.isArray(input)) {
      return input;
    }

    if (!Array.isArray(config) || (Array.isArray(config) && config.length === 1)) {
      const propertyToCheck: string = !Array.isArray(config) ? config : config[0];
      const desc = propertyToCheck.startsWith('-');

      //Basic array
      if (!propertyToCheck || propertyToCheck === '-' || propertyToCheck === '+') {
        input.sort();
        if (desc) {
          input.reverse();
        }
        return input;
      } else {
        const property = propertyToCheck.startsWith('+') || propertyToCheck.startsWith('-') ? propertyToCheck.slice(1) : propertyToCheck;

        return input.sort((a: any, b: any) => {
          const comparison = this.orderByComparator(a[property], b[property]);
          return desc ? -comparison : comparison;
        });
      }
    } else {
      //Loop over property of the array in order and sort
      return input.sort((a: any, b: any) => {
        for (let i: number = 0; i < config.length; i++) {
          const desc = config[i].startsWith('-');
          const property = config[i].startsWith('+') || config[i].startsWith('-') ? config[i].slice(1) : config[i];

          const comparison = !desc ? this.orderByComparator(a[property], b[property]) : -this.orderByComparator(a[property], b[property]);

          //Don't return 0 yet in case of needing to sort by next property
          if (comparison !== 0) {
            return comparison;
          }
        }

        return 0; //equal each other
      });
    }
  }

  static buildCareTeamEmailRequest(_appSession: AppSession, emailId: string): ICareTeamEmailRequest {
    const emailRequest: ICareTeamEmailRequest = {};
    emailRequest.careTeamRequest = this.buildCareCircleRequest(_appSession, CARE_TEAM_TYPE.SELECTED);
    emailRequest.brandCode = _appSession.metaData.brandCd;
    emailRequest.emailId = emailId;
    return emailRequest;
  }

  private static orderByComparator(a: any, b: any): number {
    if (typeof a === 'boolean' && typeof b === 'boolean') {
      return a === b ? 0 : a ? 1 : -1;
    }

    const numA = parseFloat(a);
    const numB = parseFloat(b);

    if (!isNaN(numA) && isFinite(numA) && !isNaN(numB) && isFinite(numB)) {
      return numA < numB ? -1 : numA > numB ? 1 : 0;
    }

    const ta = (a || '').toString().toLowerCase();
    const tb = (b || '').toString().toLowerCase();

    if (ta === '' || ta === null) return 1;
    if (tb === '' || tb === null) return -1;
    if (ta === tb) return 0;

    return ta < tb ? -1 : 1;
  }

  private static getSpecialties(specialties: any): string {
    let _specialties = '';
    for (const specialty of specialties || []) {
      _specialties += specialty.name + ', ';
    }
    _specialties = _specialties.trim().replace(/(.+),$/, '$1');
    return _specialties;
  }
}
