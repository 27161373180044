import { Component, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { IPrograms } from '../../../../../app/common/interfaces/iAppMetadata';
import { ClinicalProgramNavigator } from '../../../../../app/common/services/clinicalProgramNav';
import { UserInteraction } from '../../../../../app/common/services/userInteraction';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { NavigationService } from '../../../../common/services/navigationService';
import { AppUtility } from '../../../../common/utilities/appUtil';
import { RouteUtil } from '../../../../common/utilities/routeUtil';
import {
  ALERT_TYPE,
  CLINICAL_DISCLAIMERS,
  DENTAL_QUEST_URL,
  DENTAL_VSP_URL,
  DISCLAIMERS,
  EYCMANITENANCE_ALERT_MESSAGE,
  GREEN_MEDICAL_BH,
  HCS_URL,
  LIBERTY_DENTAL_URL,
  PERSONALIZEDMATCH_MESSAGE,
  PREFIX_TP,
  WLP_MEDICAL_BH
} from '../../../../fad/search-providers/values/providerSearchConstants';
import { IWindow } from '../../../interfaces/iWindow';
import { EventHandler } from '../../../services/eventHandler';
import { AppSession } from '../../../values/appSession';
import { ContentHelper } from '../../../values/contentHelper';
import { BaseComponent } from '../../base-component/baseCmp';
import { IMessage } from './../../../interfaces/iMessage';
import { InfoListService } from './../services/infoListSvc';

@Component({
  moduleId: module.id,
  selector: 'app-info-list',
  templateUrl: './pfInfoListCmp.html',
  providers: []
})
export class PfInfoListComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output()
  leavingDisclaimer: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  preConfigSearch: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  onError: EventEmitter<boolean> = new EventEmitter<boolean>();
  infoList: Array<IMessage>;
  private infoListChangeSubscription: Subscription;
  @ViewChild('ProgramsCardsSidePanelTmpl') programsSidePanelTmplt: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  @ViewChild('viewAllSidePanel')
  viewAllSidePanel: TemplateRef<HTMLElement>;
  @Input() islandingPage: boolean;
  GREEN_MEDICAL_BH = GREEN_MEDICAL_BH;
  WLP_MEDICAL_BH = WLP_MEDICAL_BH;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    _eventHandler: EventHandler,
    private _elementRef: ElementRef,
    public _infoListSvc: InfoListService,
    private _routeUtil: RouteUtil,
    private _navigationService: NavigationService,
    private _appUtility: AppUtility,
    private _userInteraction: UserInteraction,
    private _clinicalProgramNav: ClinicalProgramNavigator,
    private _sidePanel: SidePanel,
    private sanitizer: DomSanitizer
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper, 'PFSearchContainerComponent');
    this.infoListChangeSubscription = this._infoListSvc.onInfoListChange.subscribe((list) => {
      this.onInfoListChange(list);
    });
  }

  onInfoListChange(list: IMessage[]) {
    if (this._infoListSvc.infos) {
      this.processInfoListForCustomAlert(this._infoListSvc.infos);
      this.setPersonalizeMatchtoTop();
      this.setFocus();
    }
  }

  processInfoListForCustomAlert(_infoList: IMessage[]) {
    const pathsForBanner: Array<string> = [AppNavigations.SEARCH_PROVIDERS_PATH];

    const disclaimerCodes: IMessage[] = _infoList ? _infoList : [];
    this.infoList = undefined;

    const path = this._routeUtil.getResolvedUrl(this._route.snapshot);
    //When: the page is '/search-providers' add  Covid and EYC banner
    if (pathsForBanner.indexOf(path) >= 0) {
      if (this._appSession.isEycbanner) {
        const _index: number = disclaimerCodes.findIndex((item) => item.contentId === EYCMANITENANCE_ALERT_MESSAGE);
        const _message = {
          contentId: EYCMANITENANCE_ALERT_MESSAGE,
          type: ALERT_TYPE.NEGATIVE
        } as IMessage;

        if (_index === -1) {
          disclaimerCodes.push(_message);
        }
      }
    }
    this.infoList = disclaimerCodes;
  }

  ngAfterViewInit() {
    this.processInfoListForCustomAlert(this.infoList);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this._infoListSvc.programCards = undefined;
  }

  ngOnDestroy() {
    if (typeof this.infoListChangeSubscription !== 'undefined' && this.infoListChangeSubscription !== null) {
      this.infoListChangeSubscription.unsubscribe();
    }
  }

  getMessage(info: IMessage) {
    return info.content ? info.content : this.WcsUtility.getInfoListContentText(info.contentId, this.content.searchCriteriaComponent.labels[info.contentId], false, info?.value);
  }

  getAriaMessage(info: IMessage) {
    return info.contentAriaLabelId
      ? this.content.searchCriteriaComponent.labels[info.contentAriaLabelId]
      : info.content
        ? info.content
        : this.WcsUtility.getInfoListContentText(info.contentId, this.content.searchCriteriaComponent.labels[info.contentId], true);
  }

  getCloseMessage(info: IMessage) {
    if (
      info &&
      info.contentId &&
      this.content &&
      this.content.searchCriteriaComponent &&
      this.content.searchCriteriaComponent.closealert &&
      this.content.searchCriteriaComponent.closealert[info.contentId]
    ) {
      return this.content.searchCriteriaComponent.closealert[info.contentId];
    } else {
      return this.content.commonContents.labels.dismiss;
    }
  }

  embedBaseUrl(content: string) {
    const message = this.getDocumentURL(content);
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  closeInfo(info: IMessage) {
    this.infoList = this.infoList.filter((item) => (item.contentId && item.contentId !== info.contentId) || (item.content && item.content !== info.content));
  }

  setFocus() {
    if (this.infoList && this.infoList.length > 0) {
      const _index = this.infoList.findIndex((item) => item.focus === true);
      if (_index > -1) {
        this.setCtrlFocus(this._elementRef, `#lbl-info-${_index}`);
      }
    }
  }

  // Set Ctrl Focus
  setCtrlFocus(element: ElementRef, querySelector: string, delay: number = 1500) {
    setTimeout(() => {
      let _ele;
      if (element && element.nativeElement && querySelector) {
        _ele = element.nativeElement.querySelector(querySelector);
      } else if (element && element.nativeElement) {
        _ele = element.nativeElement;
      }
      if (_ele) {
        this._appUtility.scrollToTop();
        _ele.focus();
      }
    }, delay);
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event) {
    if (typeof event !== 'undefined' && event !== null && typeof event.target !== 'undefined' && event.target !== null && event.target.className !== null) {
      let _url = null;
      const _item = event.target.className.toUpperCase().trim().split(' ')[0];
      const programCardId = event.target.id || '';

      switch (_item) {
        case DISCLAIMERS.DENTAQUEST: {
          _url = DENTAL_QUEST_URL;
          break;
        }
        case DISCLAIMERS.EYEQUEST: {
          _url = this.content.searchCriteriaComponent.labels.eyeQuestVisionUrl;
          break;
        }
        case DISCLAIMERS.HCS: {
          _url = HCS_URL;
          break;
        }
        case DISCLAIMERS.LIBERTY: {
          _url = LIBERTY_DENTAL_URL;
          break;
        }
        case DISCLAIMERS.SUPERIOR: {
          _url = this.content.searchCriteriaComponent.labels.superiorVisionUrl;
          break;
        }
        case DISCLAIMERS.EYEMED: {
          _url = this.content.searchCriteriaComponent.labels.eyeMedVisionUrl;
          break;
        }
        case DISCLAIMERS.MARCH: {
          _url = this.content.searchCriteriaComponent.labels.marchVisionUrl;
          break;
        }
        case DISCLAIMERS.AVESIS: {
          _url = this.content.searchCriteriaComponent.labels.avesisUrl;
          break;
        }
        case DISCLAIMERS.MARYLAND: {
          _url = this.content.searchCriteriaComponent.labels.marylandUrl;
          break;
        }
        case DISCLAIMERS.NAVITUSPDF: {
          const pdfUrl = this.content.searchCriteriaComponent.labels.navitusPdfUrl;
          this._window.open(pdfUrl, '_blank');
          break;
        }
        case DISCLAIMERS.KYPHARMACYPORTAL: {
          _url = this.content.searchCriteriaComponent.labels.kyPortalUrl;
          break;
        }
        case DISCLAIMERS.VSP: {
          _url = DENTAL_VSP_URL;
          break;
        }
        case DISCLAIMERS.ADDCARETEAM: {
          this._navigationService.navigateByUrl(AppNavigations.CARE_TEAM_PATH);
          break;
        }
        case DISCLAIMERS.PRECONFIGSEARCH: {
          this.preConfigSearch.emit('');
          break;
        }
        case DISCLAIMERS.PROMOTEDPROCEDURE: {
          this._navigationService.navigateByUrl(AppNavigations.PROMOTED_PROCEDURES_PATH);
          this._userInteraction.postInfoAlertInteractions(this.userInteractionUtil.getUserInteraction(event?.target?.innerText, this.userInteraction.landingPage.procedureYouMayBeConsideringLink));
          this.onClose();
          break;
        }
        case DISCLAIMERS.OHEYEMED: {
          _url = this.content.searchCriteriaComponent.labels.oheyeMedVisionUrl;
          break;
        }
      }

      if (_url) {
        let windowParams: string;
        windowParams = 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1';
        _url = _url.indexOf('://') <= 0 ? 'http://' + _url : _url;
        const winArgs = [_url, '_blank'];
        if (windowParams) {
          winArgs.push(windowParams);
        }
        this._window.open.apply(this._window, winArgs);
      } else if ((programCardId && programCardId in CLINICAL_DISCLAIMERS) || (programCardId && this.isThirdPartyProgram(programCardId))) {
        // extract program id from disclaimer code
        const programId: string = encodeURI(programCardId).replace(PREFIX_TP, '');
        this._clinicalProgramNav.navigateToClinicalProgram(programId).then((status: boolean) => {
          if (!status) {
            this.onError.emit(true);
          }
          this.closeSidePanel();
        });
      }
    }
  }

  /**
   * this method will set personalize match disclaimer on top of infolist array
   */
  private setPersonalizeMatchtoTop(): void {
    const index: number = (this.infoList || []).findIndex((code: IMessage) => code.contentId === PERSONALIZEDMATCH_MESSAGE);
    if (index > 0) {
      const item: IMessage = (this.infoList || []).splice(index, 1)[0];
      item && (this.infoList || []).splice(0, 0, item);
    }
  }

  private isThirdPartyProgram(item: string): IPrograms | undefined {
    return this._appSession?.metaData?.appContract?.thirdPartyPrograms?.find((pgm) => PREFIX_TP + pgm.programId === item);
  }

  onViewMoreClick() {
    this.sidePanelRef = this._sidePanel.open('right', this.programsSidePanelTmplt);
  }

  closeSidePanel() {
    if (this.sidePanelRef) {
      this.sidePanelRef.close();
    }
  }
  private openSidePanel(direction: 'right') {
    this.sidePanelRef = this._sidePanel.open(direction, this.viewAllSidePanel);
  }
  onClose() {
    this.sidePanelRef.close();
  }

  //Get Data Analytics for Close icon/ dismiss icon of the alert if present
  getDismissDataAnalytics(info: IMessage): string {
    let dataAnalytics: string = '';
    let infoListDisclaimer = this.content?.searchCriteriaComponent?.alerts?.infoListDisclaimers?.[info.contentId];

    if (infoListDisclaimer && Array.isArray(infoListDisclaimer)) {
      const hasCloseIconDataAnalyticsTag = infoListDisclaimer.some((disclaimer) => disclaimer.hasOwnProperty('closeIconDataAnalyticsTag'));
      if (hasCloseIconDataAnalyticsTag) {
        const infoListObj = infoListDisclaimer.find((disclaimer) => disclaimer.hasOwnProperty('closeIconDataAnalyticsTag'));
        return infoListObj['closeIconDataAnalyticsTag'];
      }
    }
    return dataAnalytics;
  }
}
