import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  selector: 'app-fc-look-up-container-cmp',
  templateUrl: './lookUpContainerCmp.html'
})
export class LookUpContainerCmpComponent extends BaseComponent implements OnInit {
  @Input() parentWidth: HTMLElement;
  @Input() customCSS = '';
  @Input() warningMessage = false;
  @Output() closeSortDropdown: EventEmitter<boolean> = new EventEmitter<boolean>();
  warningMessageData: string;
  boxWidth: number;
  mouseInFilter = false;

  constructor(
    @Inject(AppSession)
    _appSession: AppSession,
    _eventHandler: EventHandler,
    _route: ActivatedRoute,
    _contentHelper: ContentHelper,
    private renderer: Renderer2
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    this.warningMessageData = this.content.common.pageHeader.searchCriteria.alerts.zipcodeEmptyError;
    if (this.waitUntilAppReload) {
      return;
    }

    if (typeof this.parentWidth !== 'undefined' && this.parentWidth !== null) {
      this.boxWidth = this.parentWidth.clientWidth;
    }

    this.renderer.listen('document', 'click', (e: Event) => {
      if (!this.mouseInFilter) {
        this.closeSortDropdown.emit(true);
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (typeof this.parentWidth !== 'undefined' && this.parentWidth !== null) {
      this.boxWidth = this.parentWidth.clientWidth;
    }
  }

  onMouseOutFilter(e) {
    this.mouseInFilter = false;
  }

  onMouseEnterFilter(e) {
    this.mouseInFilter = true;
  }
}
