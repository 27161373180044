import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppNavigations } from './common/constants/app-navigations';
import { NavigationService } from './common/services/navigationService';
import { AppSession } from './common/values/appSession';
import { ContentHelper } from './common/values/contentHelper';

@Component({
  selector: 'app-root',
  templateUrl: './appCmp.html'
})
export class AppComponent implements OnInit, OnDestroy {
  isFCRRoute = false;
  isFCRGetCareNow = false;
  private navigationSubscription: Subscription;

  constructor(
    @Inject(AppSession)
    private appSession: AppSession,
    private contentHelper: ContentHelper,
    private navigationService: NavigationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscribeToRouterEvents();
  }

  ngOnDestroy(): void {
    this.unsubscribeFromRouterEvents();
  }

  private subscribeToRouterEvents(): void {
    this.navigationSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.checkFCRRoute();
        this.checkFCRGetCareNow();
      }
    });
  }

  private unsubscribeFromRouterEvents(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  private checkFCRRoute(): void {
    const routesWithNewDesign = [
      AppNavigations.FCR_HOME_PATH,
      AppNavigations.FCR_LANDING_PATH,
      AppNavigations.FCR_RESULT_PATH,
      AppNavigations.FCR_SAV_PATH,
      AppNavigations.FCR_CARE_NOW,
      AppNavigations.FCR_CARE_CIRCLE_PATH
    ];
    this.isFCRRoute = routesWithNewDesign.includes(this.router.url);
  }

  private checkFCRGetCareNow(): void {
    this.isFCRGetCareNow = this.router?.url?.includes(AppNavigations.FCR_CARE_NOW);
  }

  emulate(): void {
    this.navigationService.navigateByUrl(AppNavigations.EMULATE_SECURE_STATE_PATH);
  }

  get isProduction(): boolean {
    return this.appSession.appConfig?.production;
  }

  get backToTopText(): string {
    return this.content?.planSelectionComponent?.backToTop || 'Back to Top';
  }

  get content(): any {
    return this.contentHelper.getContent('PFSearchContainerComponent');
  }
}
