export const CARE_CIRCLE_CONTENT_ES = {
  careTeamHeader:
    'Aquí es donde puedes encontrar los médicos u otros profesionales médicos que visitas con más frecuencia. Agregaremos a alguien a tu equipo de cuidado médico después de que procesemos una reclamación médica, dental o de visión. Puedes agregar o eliminar a alguien de tu equipo de cuidado médico en cualquier momento.',
  primaryCarePhysician: 'médico de atención primaria',
  getCareNow: 'Obtén atención ahora',
  careCircleOptions: {
    print: 'Imprimir',
    email: 'Correo electrónico',
    addToCareTeam: 'Agregar al equipo de atención'
  },
  careProvidersComponent: {
    labels: {
      mapEndpoint: 'https://www.google.com/maps/dir//',
      myProviders: 'Mis proveedores',
      onlinePharmacyLabel: 'FARMACIA EN LÍNEA',
      virtualCareOnlyLabel: 'SOLO VIRTUAL',
      onlinePharmcyTooltip: 'Ahorra un viaje a la farmacia y disfruta de la comodidad de la entrega a domicilio. Te entregaremos tus recetas con envío estándar gratuito.',
      ariaLabelForTooltipInfo: 'Selecciona enter para mas informacion acerca de',
      sortBy: 'Ordenar por:',
      sortByFirstName: 'Por nombre del proveedor',
      sortByLastName: 'Por apellido del proveedor',
      sortByDateService: 'Por fecha de servicio más reciente',
      sortByNickname: 'Por apodo',
      noProviders: 'Actualmente no tienes ningún proveedor en tu equipo de cuidado médico',
      ascDesc: 'Ordenar de manera ascendente o descendente',
      removeCareTeam: 'Eliminar del equipo de cuidado médico',
      removeCareTeamAriaLabel: 'Eliminar a {PROVIDER} del equipo de cuidado médico',
      viewClaims: 'Ver reclamaciones',
      nickname: 'Apodo',
      saveNickname: 'Guardar apodo',
      addNickname: 'Añadir apodo',
      addNicknameAriaLabel: 'Añadir apodo a {PROVIDER}',
      editNickname: 'Editar apodo',
      cancelEditNickname: 'Cancelar Editar apodo',
      call: 'Llamar',
      assignedMembers: 'Miembros asignados',
      lastVisit: 'Última visita',
      validNoSpecialChar: 'No uses|;@$%()^-+,<>\'"&*#',
      address: 'Dirección:',
      selectedPlanMsg: 'Viendo el equipo de cuidado médico en <span class="ant-font-b">{PLAN}</span>',
      selectedPlan: 'Viendo el equipo de cuidado médico en <span class="ant-font-b">{PLAN}</span>',
      selectedMemberPlanMsg: 'Viendo el equipo de cuidado médico para <span class="ant-font-b">{NAME}</span> en <span class="ant-font-b">{PLAN}</span>',
      selectedMemberPlan: 'Viendo el equipo de cuidado médico para <span>{NAME}</span> en <span>{PLAN}</span>',
      lnkAssignPcp: 'Asignar como médico primario',
      lnkAssignPcpAriaLabel: 'Asignar a {PROVIDER} como tu médico de atención primaria',
      lnkRemovePcp: 'Eliminar PCP',
      lnkRemovePcpAriaLabel: 'Eliminar {PROVIDER} como tu médico de atención primaria',
      lnkLabelPcp: 'Etiquetar como PCP',
      lnkRemoveLabelPcp: 'Eliminar etiqueta de PCP',
      lblPCP: 'PCP',
      lblPCPId: 'PCP ID',
      recentClaim: 'Reclamación reciente',
      getDirection: 'Obtener indicaciones',
      getDirectionAriaLabel: 'Obtener indicaciones para {PROVIDER}',
      providerName: 'Nombre del proveedor',
      navigationInfo: 'Haz clic aquí para navegar a la página de reclamaciones',
      back: 'Volver a Encontrar cuidado médico',
      backAriaLabel: 'Botón de Volver, selecciona para volver a la página de criterios de búsqueda',
      careTeamFor: 'Equipo de cuidado médico para {NAME}',
      removeProvider: 'Eliminar proveedor del equipo de cuidado médico',
      removeProviderConfirm: '¿Estás listo para eliminar <strong>{NAME}</strong> proveedor?',
      yes: 'Sí',
      no: 'No',
      addCareTeam: 'Agregar al equipo de atención',
      addCareAriaLabel: 'Agregar al Equipo de cuidado médico. Selecciona para volver a la página de criterios de búsqueda',
      emailModalHeader: 'Enviar correo electrónico al equipo de cuidado médico',
      removeCareProvider: '¿Eliminar del equipo de cuidado médico?',
      removeCareProviderConfirm:
        '¿Estás seguro de que quieres eliminar a <strong>{NAME}</strong> del Equipo de cuidado médico de <strong>{MEMBER}</strong>? (Siempre puedes agregarlo de nuevo si cambias de opinión.)',
      cancel: 'Cancelar',
      remove: 'Eliminar',
      pcpStartDateLabel: 'Médico de atención primaria a partir del',
      pcpProviderToolTip:
        'Estamos en el proceso de asignar a {DOCTOR NAME} como el médico de atención primaria (PCP) para {MEMBER NAME}. Ten en cuenta que este cambio no entrará en vigencia sino hasta el {EFFECTIVE DATE}.',
      selectedPCP: 'seleccionado como médico primario',
      subscriber: 'suscriptor',
      dependent: 'dependiente',
      spouse: 'conyuge',
      getCareFrom: 'Obtén atención de',
      healthSystemName: 'Nombre del sistema de salud: ',
      callForNearestLocation: 'Call For Nearest Location',
      getCareTeam: 'Obtener atención de',
      pcp: 'Asignado como PCP',
      careTeamHeader:
        'Aquí es donde puedes encontrar los médicos u otros profesionales médicos que visitas con más frecuencia. Agregaremos a alguien a tu equipo de cuidado médico después de que procesemos una reclamación médica, dental o de visión. Puedes agregar o eliminar a alguien de tu equipo de cuidado médico en cualquier momento.',
      networkNotInYourPlan: 'No está en la red de tu plan.',
      yourPrimaryCarePhysician: 'Tu médico de atención primaria:',
      noPCPAssigned: 'Sin PCP asignado',
      pcpDescription:
        'Tu médico de atención primaria (PCP) es un miembro clave de tu equipo de cuidado médico y puede actuar como coordinador de cuidado y brindar cuidado regular y exámenes de rutina. Puedes cambiar tu médico de atención primaria (PCP) en cualquier momento.',
      primaryCarePhysician: 'médico de atención primaria',
      planAssignsText: 'Tu plan te asignó un',
      planRequiresText: 'Tu plan requiere que elijas un',
      optionText: 'Tienes la opción de asignar un',
      coverageText: 'cuando comenzó tu cobertura.',
      pcpSearchBtnLabel: 'Buscar un médico de atención primaria',
      closeModal: 'botón, seleccione para cerrar el modal',
      whyImportantPCP: '¿Por qué es importante tener un médico de atención primaria?',
      whyImportantPCPContentFirstLine: 'Tú estás a cargo de tu salud. Pero necesitas un experto a quien acudir, así que elige un médico de atención primaria.',
      whyImportantPCPContent:
        'La elección de un médico de atención primaria (PCP, por su siglas en inglés) es una decisión personal importante. No quieres simplemente elegir un nombre aleatorio en una búsqueda rápida en la web. Encontrar a alguien con quien puedas relacionarte, sentirte cómodo y a quien puedas confiarle tus asuntos privados es muy importante. Y cuando encuentres el médico de atención primaria adecuado, ese médico puede ayudarte a marcar una diferencia en tu salud.',
      pcpImpLabel: '<b>Por qué es importante un médico de atención primaria</b>',
      pcpBulletPoint: [
        '<b> Tu médico de atención primaria llega a conocer “tu persona completa”, no solo partes o fragmentos</b> Tu médico tendrá una imagen más completa, con tu historial médico y tus hábitos, y podrá ver más fácilmente los cambios y recomendar acciones para evitar problemas más graves en el futuro.',
        '<b>Tienes un punto de partida.</b> Si no estás seguro de a quién acudir para un determinado problema, si tienes alguna pregunta sobre diferentes recetas médicas o si te preocupa lo que te sugirió un especialista, habla con tu médico de atención primaria.',
        '<b>Puede que ahorres tiempo y dinero. </b> Un médico de atención primaria te ayuda a mantenerte saludable y puede ayudarte a atender los problemas de salud en curso. Esto puede significar costos de atención médica más bajos, menos días de enfermedad y un mejor acceso al cuidado especializado cuando la necesites'
      ],
      searchForPCPNow: 'Buscar un médico de atención primaria ahora'
    }
  },
  founderPanel: {
    closeModal: 'Cerrar',
    founderName: 'Obtén cuidado de ',
    patientPortal: 'Portal del paciente',
    memberServices: 'Servicios para miembros  : ',
    faq: 'Preguntas más frecuentes',
    contactUs: 'Contáctanos',
    virtualCare: 'Cuidado médico virtual',
    symptomCheckerLabel: 'Herramienta de Evaluación de síntomas',
    symptomCheckerContent: 'Usa nuestra herramienta de evaluación del sistema para ayudarte a guiar hacia el nivel correcto de cuidado',
    nurselineLabel: 'Línea de enfermería 24/7 ',
    nurselineContent: 'Personal de enfermería registrado está disponible por teléfono para apoyar y responder preguntas de salud.',
    virtualHealthVisitLabel: 'Consulta médica virtual',
    virtualHealthVisitContent: 'Obtén consejos y opciones de tratamiento desde la comodidad de tu hogar',
    inPersonSpecialtyCare: 'Cuidado especializado personal',
    behaviroalHealthLabel: 'Salud conductual',
    behaviroalHealthDesc:
      'Recibe cuidado de un terapeuta o psiquiatra dentro de la Red de Anthem. Pueden brindar atención en línea o en persona. No se necesita ser referido ni tener aprobación previa.',
    urgentCare: 'Atención de urgencias',
    featuredUrgentCareLabel: 'Atención de urgencias destacada',
    featuredUrgentCareContent: 'Clínicas que están conectadas a tu grupo médico.',
    additionalVivityUrgentCare: 'Atención de urgencias adicional de Vivity',
    alertText: 'Llama al 911 o acude a la sala de emergencia (ER) si crees que tienes una emergencia real que pudiera poner en grave riesgo tu salud si te demoras',
    founderLogoAlt: 'Imagen del logotipo del fundador'
  },
  advocatesList: {
    familyAdvocate: 'DEFENSOR DE FAMILIA',
    requestCallBack: 'Solicitar que te regresen la llamada',
    chatWithAdv: 'Chatear con el defensor',
    familyClinicalAdv: 'DEFENSOR CLÍNICO DE FAMILIA',
    advDetails: 'Tu defensor de familia te ayudará a hacer fácil que obtengas el mejor cuidado posible.',
    mgrDetails: 'Tu defensor clínico de familia trabajará con tus médicos en un plan de tratamiento.',
    learnMore: 'Más información',
    extension: 'ext',
    phone: 'Teléfono',
    idCardDetails: 'Para conectarte con tu defensor, llama al número de Servicios para miembros en ',
    serviceNoDetails: 'Para conectarte con tu defensor, comunícate con Servicios para Miembros al: ',
    yourIdCard: 'tu tarjeta de identificación.'
  },
  advocateDetails: {
    familyAdv: 'DEFENSOR DE FAMILIA',
    familyClinicalAdv: 'DEFENSOR CLÍNICO DE FAMILIA',
    detail: 'Detalles',
    about: 'Acerca de',
    phone: 'Teléfono (principal)',
    language: 'Idioma',
    hours: 'Horas',
    specialties: 'Especialidades',
    whatFamilyAdvocateDoes: '¿Qué es un Defensor de Familia?',
    whatFamilyClinicianDoes: '¿Qué es un Defensor Clínico de Familia?',
    familyAdvRespties:
      '<p>Se asignó un Defensor de familia para ayudarte con tus necesidades de salud únicas. <br/> Tu defensor será el único punto de contacto para tus necesidades médicas y de seguro de salud. </p>',
    familyClAdvRespb: '<p>Se asignó un Defensor Clínico de Familia para ayudarte con tus necesidades de salud únicas.</p>',
    familyAdvList:
      '<p>El Defensor de Familia puede: <ul><li>Encontrar médicos que se ajusten perfectamente</li><li>Programar citas</li><li>Encontrar el programa de salud ofrecido por tus beneficios</li><li>Trabajar con tu equipo de cuidado médico para manejar tus condiciones y enfermedades.</li><li>Simplificar el acceso al cuidado preventivo</li><li>Ayudar a resolver las barreras financieras y de transporte para el cuidado médico</li></ul></p>',
    familyClAdvList:
      '<p>Un Defensor Clínico de Familia puede:<ul><li>Ayudarte a manejar las condiciones y enfermedades. con educación y apoyo uno a uno</li><li>Trabajar con tus médicos en planes de tratamiento y objetivos de salud</li><li>Ayudarte cuando estés en el hospital o te enfermes repentinamente</li><li>Reclutar a familiares y amigos para que te ayuden a recuperar y mantener la salud</li><li>Ayudar a eliminar las barreras mentales, físicas, financieras y emocionales para el cuidado médico</li></ul></p>',
    advConcl: '<p>Tu Defensor de familia estará allí para ti si tienes un nuevo problema de salud o si tienes que pasar tiempo en el hospital.</p>',
    familyAdvoutOfPkt: '<p>No hay gastos de bolsillo con un Defensor de Familia.</p>',
    familyClAdvoutOfPkt: '<p>No hay gastos de bolsillo con un Defensor Clínico de Familia</P>',
    close: 'Cerrar',
    extension: 'ext'
  },
  removePcp: {
    labels: {
      printPdf: 'Imprimir detalles de la página',
      emailpdf: 'Detalles de la página de correo electrónico',
      emaillbl: 'Ingresa la dirección de correo electrónico del destinatario:',
      defaultEmailText: 'Ingresa la dirección de correo electrónico',
      closeModal: 'botón, seleccione para cerrar el modal',
      sendEmailModal: ' botón, seleccione para enviar el correo electrónico',
      lblToAssignPcp: '¿A quién quieres asignar este médico de atención primaria?',
      lblToAssignPcp_v1: '¿A quién quieres asignar este médico de atención primaria?',
      lblToSelectPcp: 'Selecciona un médico de atención primaria para el cambio',
      lblNotAcceptingNewPatients: 'No acepta nuevos pacientes',
      lblToSelectPcp_v1: 'PCP ID',
      lblToRemovePcp: '¿Eliminar como médico de atención primaria (PCP)',
      lblPcpMemberHeader: 'Para este miembro',
      lblCurrentPCP: 'PCP actual:',
      lblPcpID: 'ID: ',
      lblNotAcceptingNewPatientsDesc:
        "Nuestro sistema nos dice que {providerName} no acepta nuevos pacientes. Si eres un paciente existente o si crees que esta información es incorrecta, comunícate con <a target='_blank' href='/member/contact-us' style='text-decoration:none'>Servicios para Miembros.</a>",
      lblNotAcceptingNewPatientsDesc2: 'Puedes filtrar los resultados de tu búsqueda para mostrar ',
      lblNotAcceptingNewPatientsDesc3: 'solo los proveedores de cuidado médico que aceptan nuevos pacientes.',
      lblToRemovePcpDesc: '¿Estás listo para eliminar a este proveedor de cuidado médico como tu médico de atención primaria? (Siempre puedes agregarlo nuevamente si cambias de opinión).',
      lblToSelectMultiplePcp:
        'Este médico de atención primaria (PCP, por sus siglas en inglés) tiene más de una identificación de PCP. Comunícate con el consultorio del médico si necesitas ayuda para elegir cuál identificación seleccionar.',
      lblSelectReasonPcp: 'Selecciona un motivo',
      lblAssignPCPSuccessMsg: 'Has asignado con éxito el <b>{providerName}</b> como <b>{memberName}</b> médico de atención primaria.',
      lblEmpPcpHeading: 'Asignar como Médico de atención primaria (PCP)',
      lblEmpPcpContent:
        '<strong>Identificación del médico de atención primaria</strong></br>Si este médico de atención primaria (PCP, por sus siglas en inglés) tiene más de una identificación de PCP, puede que tengas que comunicarte con el consultorio del médico para que te ayuden a elegir cual identificación seleccionar.',
      lblSelectAny: 'Seleccione cualquiera',
      btnAssignPcp: 'Asignar como PCP',
      btnRemovePcp: 'Eliminar PCP',
      btnCompletePcp: 'Completar',
      btnCancel: 'Cancelar',
      btnClose: 'Cerrar',
      btnOk: 'Ok',
      btnSent: 'Enviar',
      changePlanPublic: 'Cambiar plan',
      changePlanSecure: 'Cambiar plan o miembro',
      changePlanPublicMsg: 'Cambiar redes de planes',
      changePlanSecureMsg: 'Cambiar redes de Miembros y Planes',
      print: 'Imprimir',
      email: 'Correo electrónico',
      showFilter: 'Ver filtros',
      hideFilter: 'Ocultar filtros',
      showHints: 'Mostrar preguntas frecuentes',
      hideHints: 'Ocultar preguntas frecuentes',
      careLabel: '¿Qué tipo de cuidado médico estás buscando?',
      planLabel: 'Seleccionar plan',
      planLabelMsg: '¿Cuál red de plan?',
      memberLabel: '¿Para quién es este cuidado médico?',
      ariaCare: 'you can arrow down to make select or type in type to resort the type of Care',
      ariaPlan: 'you can arrow down to make select or type in type to resort the plan',
      ariaMember: 'you can arrow down to make select or type in type to resort the member',
      btnSave: 'Guardar',
      saveContactInfo: 'Guardar información de contacto',
      addCareTeam: 'Agregar al equipo médico',
      addCareTeamHeader: '¿Al equipo de cuidado médico de quién debemos agregar esto?',
      addWhoseCareTeamHeader: '¿Agregar a cuál equipo de cuidado médico?',
      addCareProviderHeader: '¿Agregar al equipo de cuidado médico?',
      ariaLabelForAddCareTeam: 'Agregar al equipo de atencion botón. Agregar a {PROVIDER} al Equipo de cuidado de {MEMBER}',
      addCareProviderContent:
        "¿Estás seguro de que quieres agregar a <strong class='text-uppercase'>{PROVIDER}</strong> al Equipo de cuidado médico de <strong>{MEMBER}</strong>? (Siempre puedes eliminarlo si cambias de opinión.)",
      TP_AddCareTeamSuccess:
        "Has agregado correctamente a <strong class='text-uppercase'>{PROVIDER}</strong> al equipo de cuidado médico de <strong>{MEMBER}</strong>. <a href='javascript:void(0);' type='button' id='lnk-careteam' class='addcareteam pf-button' aria-label='click here to navigate to Care team page'>Haz clic aquí</a> para ver \"Mi equipo de cuidado médico\" ahora.",
      TP_AddCareTeamSuccessMsg:
        "Has agregado con éxito <strong class='text-uppercase'>{PROVIDER}</strong> al <a href='javascript:void(0);' id='lnk-careteam' type='button' class='addcareteam pf-button' aria-label='click here to navigate to Care team page'>Equipo de cuidado médico de <strong class='addcareteam pf-button'>{MEMBER}</strong></a>.",
      TP_AddCareTeamFailureMsg:
        "Hubo un problema y no pudimos agregar a <strong class='text-uppercase'>{PROVIDER}</strong> al Equipo de cuidado médico de <strong>{MEMBER}</strong>. Vuelve a intentar más tarde.",
      TP_RemoveCareTeamSuccessMsg: "Has eliminado con éxito a <strong class='text-uppercase'>{PROVIDER}</strong> del Equipo de cuidado médico de <strong>{MEMBER}</strong>.",
      TP_RemoveCareTeamFailureMsg:
        "Hubo un problema y no pudimos eliminar a <strong class='text-uppercase'>{PROVIDER}</strong> del Equipo de cuidado médico de <strong>{MEMBER}</strong>. Vuelve a intentar más tarde.",
      planStart: 'A partir del',
      ariaLabelForEmail: 'enviar detalles de la página vía correo electrónico',
      dismiss: 'Descartar alerta',
      moreOptionsLabel: 'Mas Opciones',
      changeMember: 'Cambiar miembro',
      careTeamPlansMsg: 'Viendo el equipo de cuidado médico para <span class="ant-font-b">{NAME}</span> en',
      careTeamPlans: 'Viendo el equipo de cuidado médico para <span class="ant-font-b">{NAME}</span> en',
      selectedMemberPlansMsg: 'Encontrar cuidado médico para <span class="ant-font-b">{NAME}</span> en',
      selectedMemberPlans: 'Encontrar cuidado en TODAS las redes de planes de <span>{NAME}</span>',
      planNetworks: 'redes del plan',
      planNetwork: 'Red de plan',
      planNetworksToutHeader: 'Las siguientes redes de planes se muestran en los resultados de búsqueda.',
      AddPcpLabel: 'Agregar etiqueta de médico de atención primaria',
      labelAsPcpDesc:
        'Si bien tu plan no requiere que elijas un médico de atención primaria (PCP, por sus siglas en inglés), puedes agregar esta etiqueta para ayudar a realizar un seguimiento de tu médico primario.',
      CurrentLabeledPCP: 'Médico de atención primaria etiquetado actualmente',
      labelAsPCP: 'Etiquetar como PCP',
      removeLabelPCPDesc: '¿Estás listo para eliminar a este proveedor de cuidado médico como tu médico de atención primaria etiquetado? (Siempre puedes volver a agregar si cambias de opinión).',
      removePCPLabel: 'Eliminar etiqueta de PCP',
      allYour: 'Todas tus ',
      backLabel: 'Volver',
      backAriaLabel: 'Botón de volver, selecciona aquí para volver a la página anterior'
    }
  }
};
export type CareCircleContent = typeof CARE_CIRCLE_CONTENT_ES;
