import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { ICareProvider } from '../../interfaces/iCareProvider';
import { CareCircleService } from '../../services/careCircleSvc';
import { CareCircleUtil } from '../../utilities/careCircleUtil';

@Component({
  moduleId: module.id,
  selector: 'app-fc-remove-care-cmp',
  templateUrl: './removeCareCmp.html'
})
export class RemoveCareComponent extends BaseComponent {
  careTeamContent = this.content?.common?.careTeams;
  careProvider: ICareProvider;
  isLoading = false;
  isRemoveEnabled = true;
  description = '';
  removeProviderPanelRef: ModalRef<HTMLElement>;
  @ViewChild('removeProviderModal') removeProviderModal: TemplateRef<HTMLElement>;
  @Output() onRemoveSuccess = new EventEmitter<boolean>();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _titlecasePipe: TitleCasePipe,
    private _careTeamSvc: CareCircleService,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Initialize the Remove Provider Modal with Care Provider Details
   * @param careProvider - Care Provider to be removed
   */
  initialize(careProvider: ICareProvider) {
    this.careProvider = careProvider;
    this.description = this.careTeamContent?.removeCareProviderConfirm.replace(/{NAME}/gi, this.careProvider?.fullNm).replace(/{MEMBER}/gi, this.getActiveMemberName()?.trim());
    this.isLoading = false;
    this.isRemoveEnabled = true;
  }

  /**
   * Method to open side panel
   * @param careProvider - Care Provider to be removed
   */
  openSidePanel(careProvider: ICareProvider) {
    this.initialize(careProvider);
    this.removeProviderPanelRef = this._sidePanel.open('right', this.removeProviderModal);
  }

  /**
   * Close the Remove Provider Modal
   */
  onClose() {
    this.removeProviderPanelRef.close();
  }

  /**
   * Remove the Provider from Care Team
   */
  onRemove() {
    const careTeamMemberRequest: ICareProvider = {
      isRemoved: true,
      providerId: this.careProvider.id
    };
    this.isLoading = true;
    this._careTeamSvc.updateCareTeam(CareCircleUtil.buildUpdateCareTeamRequest(this._appSession, [careTeamMemberRequest])).then(
      (result) => this.handleUpdateCareTeamResponse(true),
      (error) => this.handleUpdateCareTeamResponse(false)
    );
  }

  /**
   * Handle Update Care Team Response - Handle Provider Removal Response
   * @param isSuccess - Is Success : boolean
   * @returns void
   */
  handleUpdateCareTeamResponse(isSuccess: boolean) {
    const messageTemplate = isSuccess ? this.careTeamContent?.removeCareTeamSuccessMsg : this.careTeamContent?.removeCareTeamFailureMsg;
    this.description = messageTemplate?.replace(/{MEMBER}/gi, this.getActiveMemberName())?.replace(/{PROVIDER}/gi, this.careProvider.fullNm);
    this.isLoading = false;

    if (isSuccess) {
      this.isRemoveEnabled = false;
      this.onRemoveSuccess.emit(true);
    }
  }

  /**
   * Get Active Member Name
   * @returns string
   */
  getActiveMemberName() {
    const activeContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract;
    return `${this.titleCase(activeContract?.firstNm)} ${this.titleCase(activeContract?.lastNm)}`.trim();
  }

  /**
   * Title Case - Convert the Name to Title Case
   * @param name - Name
   * @returns string
   */
  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }
}
