import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TIER1 } from '../../../../common/constants/app-constants';
import { DataHelper } from '../../../../common/services/dataHelper';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { PersonalizedPDFMapping } from '../../../common/constants/personalizedDownloadPdfMapping';
import { IOption } from '../../../common/interfaces/iOption';
import { ISearchFilterResponse } from '../../../common/interfaces/iSearchFilter';
import { CommonUtility } from '../../../common/utilities/commonUtil';
import { SortOptionHandler } from '../../classes/sortOptionHandler';
import { FilterCategory, FilterValue, PERSONALIZED_MATCH } from '../../constants/result';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';
import { FilterSidePanelComponent } from '../filterSidePanel/filterSidePanelCmp';
import { SliderDirection } from './../../../common/constants/common';
import { SearchFilter } from './../../services/searchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-filter-header-cmp',
  templateUrl: './filterHeaderCmp.html'
})
export class FilterHeaderComponent extends BaseComponent implements OnInit, OnDestroy {
  filterContent = this.content?.result?.filter;
  sortFilter = this.content?.result?.summaryComponent;
  sortOptions: IOption[] = [];
  isSortContainerVisible: boolean = false;
  sortOptionSelected: IOption = undefined;
  selectedSort = '';
  selectedSortLabel = '';
  personalizedMatch = PERSONALIZED_MATCH;
  @ViewChild('searchResultsFilterModal')
  searchResultsFilterModal: FilterSidePanelComponent;
  @Input() disableFilter: boolean;
  @Output()
  selectedSortOption: EventEmitter<any> = new EventEmitter<any>();
  @Output() keyFiltersClicked = new EventEmitter();
  selectedFilterCount: number = 0;
  filtersLoaded: boolean = false;
  searchCriteria: ISearchParameters;
  appliedFilters: IAppliedSearchFilter[] = [];
  keyFilters: IOption[] = [];
  private appliedFilterSubscription: Subscription;
  private searchFilterSubscription: Subscription;
  private sortSubscription: Subscription;
  private selectedSortSubscription: Subscription;
  @Output() resetProviderCard: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appliedFilter: AppliedSearchFilter,
    private _dataHlpr: DataHelper,
    private _searchFilter: SearchFilter,
    private _sortOption: SortOptionHandler
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.appliedFilterSubscription = this._appliedFilter.getAppliedFilters.subscribe((data) => {
      this.appliedFilters = data;
      this.selectedFilterCount = data?.length;
    });
    this.searchFilterSubscription = this._searchFilter.filters.subscribe((filters: ISearchFilterResponse) => {
      if (filters) {
        this.filtersLoaded = true;
        const initialFilterCount = this.getInitialFilterCount(filters);
        if (initialFilterCount) {
          if (!this.selectedFilterCount || initialFilterCount > this.selectedFilterCount) {
            this.selectedFilterCount = initialFilterCount;
          }
        }
        this.bindKeyFilters(filters);
      }
    });
    this.sortSubscription = this._sortOption.getSortOptions.subscribe((options: IOption[]) => {
      if (options?.length) {
        this.sortOptions = options;
      }
    });
    this.selectedSortSubscription = this._sortOption.selectedOptionChanged.subscribe((option: IOption) => {
      if (option) {
        this.sortOptionSelected = option;
        this.selectedSortLabel = option.label;
        this.selectedSort = option.value;
      }
    });
  }

  ngOnDestroy(): void {
    this.appliedFilterSubscription?.unsubscribe();
    this.searchFilterSubscription?.unsubscribe();
    this.sortSubscription?.unsubscribe();
    this.selectedSortSubscription?.unsubscribe();
  }

  openFilterPanel() {
    this.searchResultsFilterModal.openSidePanel(SliderDirection.RIGHT);
  }

  showSortContainer() {
    setTimeout(() => {
      this.isSortContainerVisible = true;
    });
  }

  selectSortingOption(option: IOption) {
    this.sortOptionSelected = option;
    this.selectedSort = option.value;
    this.selectedSortLabel = option.label;
  }

  onApplyClick() {
    this.isSortContainerVisible = false;
    this._sortOption.setSelectedOption(this.sortOptionSelected.value);
    this.selectedSortOption.emit(this.selectedSort);
  }

  onCancelClick() {
    if (this.isSortContainerVisible) {
      this.isSortContainerVisible = false;
    }
  }

  getSelectedFilterCount(): string {
    const filterCount = this.selectedFilterCount || 0;
    if (filterCount > 0) {
      return ' (' + filterCount + ')';
    } else {
      return '';
    }
  }

  /**
   * Calculates the initial count of applied filters based on the search parameters and available filters.
   * @param filters The available filters.
   * @returns {number} The count of initially applied filters.
   */
  private getInitialFilterCount(filters: ISearchFilterResponse): number {
    if (!filters || !this._appSession?.searchParams) {
      return 0;
    }

    let filterCount = 0;
    const { specialtySelectNm, taxonomySelectNm, providerTypeCodeList, ableToServePcp, acceptingNewPatients, aoeSelectNm, ofcSrvcSelectNm, providerTier, recognitionCodes } =
      this._appSession.searchParams;
    const { specialtyCategories, taxonomies, providerTypes, areaOfExpertise, officeServices, recognition } = filters;

    const countMatchingFilters = (selectedCodes: string[], availableFilters: { code: string }[]): number => {
      const uniqueSelectedCodes = Array.from(new Set(selectedCodes));
      return uniqueSelectedCodes.reduce((count, code) => {
        return count + (availableFilters?.some((filter) => filter.code === code) ? 1 : 0);
      }, 0);
    };

    const specialtyCount = countMatchingFilters(specialtySelectNm, specialtyCategories);
    const taxonomyCount = countMatchingFilters(taxonomySelectNm, taxonomies);
    const typeCount = countMatchingFilters(providerTypeCodeList, providerTypes);
    const aoeCount = countMatchingFilters(aoeSelectNm, areaOfExpertise);
    const ofcSrvcCount = countMatchingFilters(ofcSrvcSelectNm, officeServices);
    const recognitionCount = countMatchingFilters(recognitionCodes, recognition);

    filterCount = specialtyCount + taxonomyCount + typeCount + aoeCount + ofcSrvcCount + recognitionCount;
    if (ableToServePcp) {
      filterCount++;
    }
    if (acceptingNewPatients) {
      filterCount++;
    }
    if (this._appSession.isPrimeGroupSearch) {
      filterCount++;
    }
    if (providerTier?.length) {
      filterCount++;
    }

    return filterCount;
  }

  getPersonalizedMatchContent(): string {
    return this.sortFilter?.moreOptionsTooltip?.replace(/{pdfUrl}/gi, this.getPersonalizedMatchPdfLink());
  }

  getPersonalizedMatchPdfLink(): string {
    let personalizedMatchLink = '';
    let appendUrl = '';
    if (!this.sydneyMedicaid) {
      const snr = 'snr_' + this._appSession?.metaData?.locale;
      const commercial = 'commercial_' + this._appSession?.metaData?.locale;
      const link = this._dataHlpr.getValueByKey<string>(this._appSession.mbu, snr, commercial);
      appendUrl = this._dataHlpr.getValueByKey<string>(link, PersonalizedPDFMapping.personalizedMatchPdf);
    } else {
      const sydMedicaidState = `medicaid${this._appSession?.searchParams?.stateCd?.code}${this._appSession?.searchParams?.brandCode}_${this._appSession?.metaData?.locale}`;
      appendUrl = this._dataHlpr.getValueByKey<string>(sydMedicaidState, PersonalizedPDFMapping.medicaidStateByPersonalizedMatchPdf);
    }
    if (appendUrl) {
      personalizedMatchLink = CommonUtil.personalizedMatchPdfLink(this._appSession, appendUrl);
    }
    return personalizedMatchLink;
  }

  applyKeyFilter(filterItem: IOption): void {
    if (!this._appSession?.searchParams || !filterItem) {
      return;
    }

    const updateFilterOption = (filterOption: IOption, searchParamKey: string, filterCategory: FilterCategory) => {
      this._appSession.searchParams[searchParamKey] = !filterItem.isChecked;
      if (this._appSession.searchParams[searchParamKey]) {
        this.selectedFilterCount++;
      } else {
        this.selectedFilterCount--;
        this._appliedFilter.removeFilterOption(filterCategory, filterOption, true);
      }
    };

    const keyfilterOption = { label: filterItem.label, value: filterItem.value } as IOption;

    if (filterItem.value === FilterValue.ABLE_TO_SERVE_AS_PCP) {
      updateFilterOption(keyfilterOption, 'ableToServePcp', FilterCategory.KEYFILTERS);
    }

    if (filterItem.value === FilterValue.ACCEPT_PATIENTS) {
      updateFilterOption(keyfilterOption, 'acceptingNewPatients', FilterCategory.KEYFILTERS);
    }

    if (this._searchFilter.isKeyRecognitionFilterOption(filterItem.value)) {
      this._appSession.searchParams.recognitionCodes = this._appSession.searchParams.recognitionCodes || [];
      if (CommonUtility.isKeyTierFilterOption(filterItem.value)) {
        this._appSession.searchParams.providerTier = !filterItem.isChecked ? TIER1 : undefined;
      } else {
        if (!filterItem.isChecked) {
          this._appSession.searchParams.recognitionCodes.push(filterItem.value);
        }
      }
      if (!filterItem.isChecked) {
        this.selectedFilterCount++;
      } else {
        this.selectedFilterCount--;
        this._appSession.searchParams.recognitionCodes = this._appSession.searchParams.recognitionCodes?.filter((code) => code != filterItem.value);
        this._appliedFilter.removeFilterOption(FilterCategory.KEYFILTERS, keyfilterOption, true);
      }
    }

    this.keyFiltersClicked.emit();
  }

  private bindKeyFilters(filters: ISearchFilterResponse): void {
    this.keyFilters = [];
    if (!filters) {
      return;
    }

    const { ableToServePcp, acceptingNewPatients, providerTier, recognitionCodes } = this._appSession.searchParams;
    const { ableToServeAsPCP, acceptsPatients, keyRecognitions } = filters;

    if (ableToServeAsPCP) {
      this.keyFilters.push({
        label: ableToServeAsPCP.name,
        value: ableToServeAsPCP.code,
        isChecked: ableToServePcp ? true : false
      } as IOption);
    }
    if (acceptsPatients) {
      this.keyFilters.push({
        label: acceptsPatients.name,
        value: acceptsPatients.code,
        isChecked: acceptingNewPatients ? true : false
      } as IOption);
    }

    if (Array.isArray(keyRecognitions) && keyRecognitions?.length > 0) {
      keyRecognitions.forEach((option) => {
        this.keyFilters.push({
          label: option.name,
          value: option.code,
          isChecked: CommonUtility.isKeyTierFilterOption(option.code) ? providerTier?.length > 0 : recognitionCodes?.includes(option.code)
        } as IOption);
      });
    }
  }

  onResetProviderCard(status: boolean) {
    this.resetProviderCard.emit(status);
  }
}
