import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { CardLinkType, ICardData } from '../../cards-list/models/iCardData';
import { DataHelper } from './../../../../../app/common/services/dataHelper';
import { AppConfig } from './../../../../../app/common/values/appConfig';
import {
  ALERT_TYPE,
  BCP_MESSAGE,
  CARDS_CHARLIMIT,
  CLINICAL_DISCLAIMERS,
  DENTALPROCEDURE_MESSAGE,
  INITIAL_VISIT_COST_MESSAGE,
  MORE_THAN_ONE_VISIT_COST_MESSAGE,
  NCCT_FAILOVER_MESSAGE,
  PERSONALIZEDMATCH_MESSAGE,
  PREFIX_TP,
  PRIME_GROUP_SEARCH_MESSAGE
} from './../../../../fad/search-providers/values/providerSearchConstants';
import { IClinic } from './../../../../fad/search-results/interfaces/iSummaryResp';
import { CommonUtil } from './../../../../fad/utilities/commonUtil';
import { IPrograms } from './../../../interfaces/iAppMetadata';
import { IMessage, MsgCategory } from './../../../interfaces/iMessage';
import { AppSession } from './../../../values/appSession';
import { ContentHelper } from './../../../values/contentHelper';

@Injectable({ providedIn: 'root' })
export class InfoListService {
  programCards: ICardData[];
  onsiteClincCards: ICardData[];

  infos: IMessage[];

  private _infoList = new Subject<IMessage[]>();
  private _disclaimerCodes: IMessage[] = [];
  private _doNotShowInfoListDisclaimer: boolean = false;

  private _appConfig: AppConfig;

  constructor(
    private _dataHelper: DataHelper,
    @Inject(AppSession) private _appSession: AppSession,
    private _contentHlpr: ContentHelper
  ) {
    this._appConfig = this._appSession.appConfig;
  }

  //TODO Deprecate this method
  setInfoList(disclaimerCodes: IMessage[]) {
    if (Array.isArray(disclaimerCodes)) {
      this._disclaimerCodes = disclaimerCodes;
      this.segregateMessages();
      this._infoList.next(this._disclaimerCodes);
    }
  }

  segregateMessages(): void {
    if (!this._disclaimerCodes) {
      return;
    }
    const prgCards: ICardData[] = [];
    const titleLimit = CARDS_CHARLIMIT.TITLE;
    const descLimit = CARDS_CHARLIMIT.DESC;
    let infos = [];
    this._disclaimerCodes.forEach((disclmrCode) => {
      if (!disclmrCode) {
        return;
      }
      let title: string;
      let imgSrc: string;
      let message: string;
      let dataAnalytics: string;
      const content = this._contentHlpr.getAllContent();
      let thirdPartyProgram: IPrograms;
      let baseURL = this._appConfig?.baseUrl[this._appSession.state] + this._appSession.appConfig?.images.commonBaseUrl;

      if (disclmrCode?.contentId && this._appSession?.metaData?.appContract?.thirdPartyPrograms?.length) {
        thirdPartyProgram = this._appSession.metaData.appContract.thirdPartyPrograms.find((pgm) => PREFIX_TP + pgm.programId === disclmrCode.contentId);
      }

      if (disclmrCode.contentId in CLINICAL_DISCLAIMERS) {
        const clinicalProgram = content?.searchCriteriaComponent?.alerts?.clinicalPrograms[disclmrCode.contentId];
        const metadataProgram: IPrograms = (this._appSession.metaData.appContract?.programs || []).find((p: IPrograms) => disclmrCode.contentId.indexOf(p.programId) > -1);

        if (clinicalProgram && metadataProgram) {
          title = metadataProgram.programNm || clinicalProgram.programName;
          dataAnalytics = clinicalProgram.dataAnalytics || '';
        }
        message = (clinicalProgram?.msgText || '').replace(/{PROGRAM_NAME}/gi, title);
        message = CommonUtil.getLimitedMessage(message, descLimit);

        imgSrc = baseURL + disclmrCode.contentId + '.png';
        title = CommonUtil.getLimitedMessage(title, titleLimit);
        //Create a CardData with 'Learn more' link
        let cardData: ICardData = {
          sortOrder: 0,
          imgSrc,
          title,
          message,
          contentId: disclmrCode.contentId,
          actionLink: {
            type: CardLinkType.ACTION,
            text: content?.searchCriteriaComponent?.labels?.learnMore,
            href: '', //href is not needed
            ariaLabel: (content?.searchCriteriaComponent?.labels?.learnMoreAriaLabel || '').replace(/{TITLE}/gi, title),
            analytics: dataAnalytics,
            id: 'pf-sum-prog-learnmore-link-' + disclmrCode.contentId
          }
        };
        prgCards.push(cardData);
      } else if (thirdPartyProgram) {
        let cardData: ICardData = {
          sortOrder: 0,
          imgSrc: CommonUtil.getSemImageUrl(this._appSession) + thirdPartyProgram.image,
          title: CommonUtil.getLimitedMessage(thirdPartyProgram.programNm, titleLimit),
          message: CommonUtil.getLimitedMessage(thirdPartyProgram.description, descLimit),
          contentId: disclmrCode.contentId,
          actionLink: {
            type: CardLinkType.ACTION,
            text: content?.searchCriteriaComponent?.labels?.learnMore,
            href: '', //href is not needed
            ariaLabel: (content?.searchCriteriaComponent?.labels?.learnMoreAriaLabel || '').replace(/{TITLE}/gi, thirdPartyProgram.programNm),
            analytics: thirdPartyProgram.dataAnalytics || '',
            id: 'pf-sum-prog-learnmore-link-' + disclmrCode.contentId
          }
        };
        prgCards.push(cardData);
      } else if (disclmrCode.category === MsgCategory.ONSITE_CLINICS && disclmrCode.clinic) {
        title = disclmrCode.clinicName;
        title = CommonUtil.getLimitedMessage(title, titleLimit);
        message = disclmrCode.content;

        imgSrc = disclmrCode.clinic?.webURL ? baseURL + 'onsite_clinic_web.png' : disclmrCode.clinic?.phone ? baseURL + 'onsite_clinic_phone.png' : baseURL + 'onsite_clinic_web.png';

        //Create a CardData with Clinic Link (visit website) / Phone num link / Get Direction link
        let cardData: ICardData = {
          sortOrder: 1,
          imgSrc,
          title,
          message,
          contentId: disclmrCode.category
        };

        //Create clinic obj only when there is a phone num for the clinic
        if (disclmrCode.clinic.webURL) {
          cardData.clinicLink = {
            type: CardLinkType.CLINIC,
            href: disclmrCode.clinic.webURL,
            text: content?.cardsComponent?.onsiteClinicWebLink?.text,
            ariaLabel: (content?.cardsComponent?.onsiteClinicWebLink?.ariaLabel || '').replace(/{TITLE}/gi, cardData.title),
            analytics: content?.cardsComponent?.onsiteClinicWebLink?.analytics,
            id: 'pf-sum-clinic-web-link-' + disclmrCode.clinic.id
          };
        }

        //Create phonelink obj only when there is a phone num for the clinic
        if (disclmrCode.clinic.phone) {
          cardData.phoneLink = {
            type: CardLinkType.PHONE,
            href: 'tel:' + disclmrCode.clinic.phone,
            text: disclmrCode.clinic.phone + '',
            ariaLabel: (content?.cardsComponent?.onsiteClinicPhoneLink?.ariaLabel || '').replace(/{PHONENUMBER}/gi, disclmrCode.clinic.phone),
            analytics: content?.cardsComponent?.onsiteClinicPhoneLink?.analytics,
            id: 'pf-sum-clinic-phone-link-' + disclmrCode.clinic.id
          };
        }

        //Create getDirections / actionLink obj only when there is latitude and longitude for the clinic
        if (disclmrCode.clinic.longitude && disclmrCode.clinic.latitude) {
          cardData.actionLink = {
            type: CardLinkType.ACTION,
            href: 'todo-calculate-the-getdir-url',
            text: content?.cardsComponent?.onsiteClinicGetDirLink?.text,
            ariaLabel: content?.cardsComponent?.onsiteClinicGetDirLink?.ariaLabel,
            analytics: content?.cardsComponent?.onsiteClinicGetDirLink?.analytics,
            id: 'pf-sum-clinic-getdir-link-' + disclmrCode.clinic.id
          };
          cardData.actionLink = null; //ToDo - Remove this line for enabling Get Directions in future
        }
        cardData.clinic = disclmrCode.clinic;
        prgCards.push(cardData);
      } else {
        infos.push(disclmrCode);
      }
    });

    //sort program cards by category
    prgCards.sort((card1, card2) => {
      return card2.sortOrder - card1.sortOrder;
    });

    this.programCards = prgCards;
    this.infos = infos;
  }

  get doNotShowInfoListDisclaimer(): boolean {
    return this._doNotShowInfoListDisclaimer;
  }

  setInfoListDisclaimerValue(value: boolean) {
    this._doNotShowInfoListDisclaimer = value;
  }

  get onInfoListChange() {
    return this._infoList.asObservable();
  }

  reset() {
    this._disclaimerCodes = [];
  }

  get disclaimerCodes() {
    return this._disclaimerCodes;
  }

  /**
   * Add new alert message to infolist.
   * Default alert type will be information.
   * @param message
   * @returns
   */
  addMessage(message: IMessage): boolean {
    if (!message.contentId || !message.content) {
      return false;
    }

    if ((this._disclaimerCodes || []).findIndex((msg: IMessage) => this._dataHelper.areEqualStrings(msg.contentId, message.contentId)) === -1) {
      message.type = message.type || ALERT_TYPE.INFORMATION;

      this._disclaimerCodes.push(message);
    }
    this.update();
    return true;
  }

  addMsgById(contentId: string, msgType: ALERT_TYPE = ALERT_TYPE.INFORMATION, value?: string | undefined): IMessage {
    //ContentId is mandatory
    if (typeof contentId != 'string' || contentId.trim() === '') {
      return;
    }

    let msg: IMessage = this._disclaimerCodes.find((m) => {
      return m.contentId === contentId;
    });
    if (!msg) {
      msg = {
        contentId: contentId,
        type: msgType,
        value: value
      };
      this._disclaimerCodes.push(msg);
    }
    return msg;
  }

  addMsgByContent(content: string, category: MsgCategory, msgType: ALERT_TYPE = ALERT_TYPE.INFORMATION, clinic: IClinic): IMessage {
    //content is mandatory
    if (typeof clinic?.name != 'string' || clinic?.name.trim() === '') {
      return;
    }

    let msg: IMessage = this._disclaimerCodes.find((m) => {
      return m.clinicName === clinic?.name;
    });
    if (!msg) {
      msg = {
        content: content,
        type: msgType,
        category: category,
        clinicName: clinic?.name,
        clinic
      };
      this._disclaimerCodes.push(msg);
    }
    return msg;
  }

  addMsg(content: string, contentId: string, msgType: ALERT_TYPE = ALERT_TYPE.INFORMATION): IMessage {
    //content and contentId are mandatory
    if (typeof content != 'string' || content.trim() === '') {
      return;
    }
    if (typeof contentId != 'string' || contentId.trim() === '') {
      return;
    }

    let msg: IMessage = this.addMsgById(contentId, msgType);
    msg.content = content;
    return msg;
  }

  update() {
    this.segregateMessages();
    this._infoList.next(this._disclaimerCodes);
  }

  removeMsgById(contentId: string): void {
    let index: number = this._disclaimerCodes.findIndex((m, i) => {
      return m.contentId === contentId;
    });
    if (index > -1) {
      this._disclaimerCodes.splice(index, 1);
    }
  }
  /**
   * Remove all the messages from UI but PersonalizedMatch, Onsie Clinic and BCP
   */
  abolishMessages() {
    //Retain personalize match if already added from results page.
    //Retain NCCT Failover msg if already added from results page.
    //Retain Onsite clinics
    //Retain BCP from results page
    //Retain Prime group search msg in results page
    //Abolish other messages
    let filteredMsgs: IMessage[] = this._disclaimerCodes.filter((msg) => {
      if (msg.contentId === PERSONALIZEDMATCH_MESSAGE) {
        return true;
      }
      if (msg.contentId === NCCT_FAILOVER_MESSAGE) {
        return true;
      }
      if (msg.content !== '' && msg.category === MsgCategory.ONSITE_CLINICS) {
        return true;
      }
      if (msg.contentId == BCP_MESSAGE) {
        return true;
      }
      if (msg.contentId === DENTALPROCEDURE_MESSAGE) {
        return true;
      }
      if (msg.contentId === PRIME_GROUP_SEARCH_MESSAGE) {
        return true;
      }
      if (msg.contentId === INITIAL_VISIT_COST_MESSAGE) {
        return true;
      }
      if (msg.contentId === MORE_THAN_ONE_VISIT_COST_MESSAGE) {
        return true;
      }
      return false;
    });
    this._disclaimerCodes = filteredMsgs;
    this.update();
  }

  removeMsgByCategory(category: string) {
    if (this._disclaimerCodes) {
      this._disclaimerCodes = this._disclaimerCodes.filter((disclaimer) => {
        return disclaimer?.category !== category;
      });
    }
  }
}
