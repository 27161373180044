import { TitleCasePipe } from '@angular/common';
import { forOwn, isEmpty } from 'lodash';
import { CARE_TYPE, EMPTY_STRING, Locale, TIER1, TILDE_SYMBOL } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { MEDICAL_COVERAGETYPE, STATE_GA } from '../../../fad/search-providers/values/providerSearchConstants';
import { CITY, CULTURE, CULTURE_VALUE, DOT_OPERAND, EQUAL_OPERAND, PROCCODE, STATE, ZIPCODE } from '../../../fad/search-results/values/providerSearchConstants';
import { IProviderType } from '../../landing/interfaces/iProviderType';
import { IProvider, IReviewResponse } from '../../result/interfaces/iProvider';
import { PageAlertHandler } from '../components/shareable/page-alert/service/pageAlertHandler';
import { PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, SearchExecutionMode } from '../constants/common';
import { PROVIDER_CATEGORY_CODE } from '../constants/providerSearchConstants';
import { RESULT_PAGE_SEARCH_BLOCK_ALERT_CONFIG } from '../contents/common/alertTypeConfig';
import { RESULT_CONTENT_EN } from '../contents/result/resultContent';
import { RESULT_CONTENT_ES } from '../contents/result/resultContent.es';
import { FindCareFlag } from '../enums/findCareFlag';
import { FindCarePageType } from '../enums/findCarePages';
import { PageAlertID } from '../enums/pageAlertId';
import { IModalContent } from '../interfaces/iModalContent';
import { IAlertMessage } from '../interfaces/iPageAlert';
import { IProviderNetworkStatus, IProviderSearchDdlValues } from '../interfaces/iProviderDetail';
import { IMemberRequest, IRuleParameters } from '../interfaces/iRulesParameters';
import { ISearchParameters } from '../interfaces/iSearchParameters';
import { FeatureManagerUtility } from './featureManagerUtil';

export class CommonUtility {
  private static NOREVIEW = RESULT_CONTENT_EN.summaryComponent.noReview;
  /**
   * Determines if the member is in a secure state based on the app session.
   * @param appSession The application session.
   * @returns {boolean} True if the member is in a secure state, otherwise false.
   */
  static isMemberSecure(appSession: AppSession): boolean {
    // Check if the identification number is present and not empty
    const hasIdentificationNumber = appSession?.searchParams?.identificationNumber && appSession.searchParams.identificationNumber.trim() !== '';

    // If the identification number is present, the state is not secure
    if (hasIdentificationNumber) {
      return false;
    }

    // Otherwise, return the secure state from the app session
    return appSession.isSecureState;
  }

  static isCptSearch(appSession: AppSession): boolean {
    return appSession?.isMedicalCodeSearch;
  }

  static getPdtStrKey(selectedProvider: IProvider): string {
    if (CommonUtility.isValidString(selectedProvider?.pdtStrKey)) {
      return selectedProvider.pdtStrKey;
    } else if (!isEmpty(selectedProvider?.pdtKey)) {
      return (
        (selectedProvider.pdtKey.planCode ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.productId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerNumber ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.providerMasterId ?? EMPTY_STRING) +
        TILDE_SYMBOL +
        (selectedProvider.pdtKey.locationMasterId ?? EMPTY_STRING)
      );
    }
    return EMPTY_STRING;
  }

  static isValidString(key: unknown): boolean {
    if (key && typeof key === 'string' && key !== '' && key.trim().length) {
      return true;
    }
    return false;
  }

  static replaceContentWithLiterals(content: string = '', pattern: string, value: string): string {
    const regExPattern = new RegExp(`{${pattern}}`, 'gi');
    return content.replace(regExPattern, value);
  }

  static processModalContent(modalContent: IModalContent, brand: string): IModalContent {
    modalContent.title = CommonUtility.replaceContentWithLiterals(modalContent?.title, 'brand', brand);
    modalContent.body = CommonUtility.replaceContentWithLiterals(modalContent?.body, 'brand', brand);
    forOwn(modalContent.content, (value, key) => {
      modalContent.content[key] = CommonUtility.replaceContentWithLiterals(value, 'brand', brand);
    });
    return modalContent;
  }

  static populateProviderTypes(data: any, appSession: AppSession, useQuickLink: boolean = false) {
    let code: any;
    const _providerTypes: IProviderType[] = [];

    code = useQuickLink ? data : data.codeTypeList;
    let isPhrmcyStndAlneNonMdclPhrmcy: boolean =
      appSession.metaData.appContract.coverageDetails && !appSession.metaData.appContract.coverageDetails.hasPharmacyPlan && appSession.metaData.appContract.coverageDetails.isPharmacyStandAlone
        ? true
        : false;

    for (let i = 0; i < code.length; i++) {
      if (
        appSession.isSecureState &&
        code[i].code.toUpperCase().trim() === PROVIDER_TYPE.PHARMACY &&
        appSession.metaData.appContract.coverageDetails &&
        !(appSession.metaData.appContract.coverageDetails.hasPharmacyPlan || isPhrmcyStndAlneNonMdclPhrmcy)
      ) {
        continue;
      }

      const _codeName: IProviderType = {
        code: code[i].code,
        name: code[i].name,
        licenseList: code[i].licenseCodeList && code[i].licenseCodeList.length > 0 ? code[i].licenseCodeList : [],
        isChecked: code[i].isChecked ? code[i].isChecked : false,
        id: code[i].code,
        label: code[i].name,
        trueValue: true,
        falseValue: false
      };

      _providerTypes.push(_codeName);
    }

    return _providerTypes;
  }

  static getProviderTypes(appSession: AppSession): Array<string> {
    let allProviderTypes = appSession?.appState?.executionMode === SearchExecutionMode.SECURE ? appSession.providerTypes?.filter((x) => x.code !== PROVIDER_TYPE.PHARMACY) : appSession.providerTypes;
    const providerTypes = [];
    const opsIndicator = appSession?.appState?.opsIndicator;
    if (opsIndicator) {
      if (opsIndicator.isDentalStandAlone) {
        providerTypes.push(PROVIDER_TYPE.DENTAL);
      } else if (opsIndicator.isVisionStandAlone) {
        providerTypes.push(PROVIDER_TYPE.VISION);
      } else if (opsIndicator.isRxStandAlone) {
        providerTypes.push(PROVIDER_TYPE.PHARMACY);
      }
    }
    return providerTypes.length ? providerTypes : allProviderTypes?.map((x) => x.code) || [];
  }

  static getPrefix(searchParams: ISearchParameters, appSession: AppSession): string | undefined {
    if (searchParams?.plan?.alphaPrefix) {
      return searchParams.plan.alphaPrefix;
    } else if (appSession?.searchParams?.plan?.alphaPrefix) {
      return appSession?.searchParams.plan.alphaPrefix;
    } else if (appSession?.deeplinkParams?.alphaprefix) {
      return appSession.deeplinkParams.alphaprefix;
    }
    return undefined;
  }

  static buildPrefixRuleRequest(searchParams: ISearchParameters, appSession: AppSession) {
    const _prefix = CommonUtility.getPrefix(searchParams, appSession);

    let memberDetails: IMemberRequest;

    if (appSession.metaData && appSession.metaData.appContract && appSession.metaData.appContract.mbrUid && !appSession.isPlanUnknown) {
      memberDetails = {} as IMemberRequest;

      memberDetails.mbrUid = appSession.metaData.appContract.mbrUid;
      memberDetails.coverageUid = appSession.coverageUid;
    }

    const ruleRequest: IRuleParameters = {
      brand: appSession.metaData.brandCd,
      prefix: _prefix,
      providerType: searchParams.typeSelectNm,
      isLevelofCare: '',
      locale: appSession.metaData && appSession.metaData.locale ? appSession.metaData.locale.toString() : Locale.ENGLISH,
      network: searchParams.plan && searchParams.plan.networkList && searchParams.plan.networkList.length > 0 ? searchParams.plan.networkList : [],
      identification: appSession.searchParams && appSession.searchParams.identificationNumber ? appSession.searchParams.identificationNumber : undefined,
      isPCP: appSession.isChangePCP ? 'Y' : undefined,
      contractUid: appSession.metaData && appSession.metaData.appContract && !appSession.isPlanUnknown ? appSession.metaData.appContract.contractUid : undefined,
      member: memberDetails,
      isDeepLink: appSession.isDeepLink,
      deeplinkAction: appSession.deeplinkParams?.action,
      ableToServePcp: searchParams.ableToServePcp
    };

    // Rule request set plan request obj.
    if (searchParams.plan) {
      ruleRequest.state = searchParams.plan.stateCd ? searchParams.plan.stateCd : '';
      ruleRequest.planCategory = searchParams.plan.category ? searchParams.plan.category.catCode : '';
      (ruleRequest.productType =
        appSession.isSecureState === true &&
        !appSession.isPlanUnknown &&
        appSession.metaData &&
        appSession.metaData.appContract &&
        appSession.metaData.appContract.coverageDetails &&
        appSession.metaData.appContract.coverageDetails.productType
          ? appSession.metaData.appContract.coverageDetails.productType
          : ''),
        (ruleRequest.careType = searchParams.plan.careType ? searchParams.plan.careType : '');
    }

    return ruleRequest;
  }

  /** build the dropdown values for the provider search
   * @param data - The data to be used to build the dropdown values.
   * @param identifier - The identifier for the dropdown values.
   * @param defaultname - The default name for the dropdown values.
   * @param defaultValue - The default value for the dropdown values.
   * @param type - The type of the dropdown values.
   * @param multiSelect - The flag to check if the dropdown values are multi-select.
   * @returns {IProviderSearchDdlValues} The dropdown values for the provider search.
   * */
  static buildDropDownValues(data: any[], identifier: string, defaultname?: string, defaultValue?: string, type?: string, multiSelect: boolean = true): IProviderSearchDdlValues {
    const ddlValues: IProviderSearchDdlValues = {
      title: '',
      options: [],
      controlListData: []
    };

    if (defaultname) {
      ddlValues.options.push({
        label: defaultname,
        value: defaultValue,
        id: identifier + 0,
        name: multiSelect ? identifier + 0 : identifier,
        trueValue: defaultValue,
        falseValue: '',
        isChecked: false
      });
      ddlValues.controlListData.push('');
    }

    if (Array.isArray(data) && data.length > 0) {
      data.forEach((item, index) => {
        let name = '';
        let code = '';
        let controlListItem = {};

        if (type === 'provider') {
          name = item.providerName;
          code = item.providerIdentifier;
          controlListItem = item;
        } else {
          name = item.name ? item.name.trim() : item.name;
          code = item.code;
        }

        ddlValues.options.push({
          label: name,
          value: code,
          id: identifier + (index + 1),
          name: multiSelect ? identifier + (index + 1) : identifier,
          trueValue: code,
          falseValue: '',
          isChecked: false
        });
        ddlValues.controlListData.push(controlListItem);
      });
    }

    return ddlValues;
  }

  /** get the title case of the input text
   * @param inputTxt - The text to be converted to title case.
   * @returns { string } The title case text.
   * */
  static titleCase(inputTxt: string) {
    return new TitleCasePipe().transform(inputTxt);
  }

  /**
   * Get the formatted zip code based on the length of the zip code.
   * @param zipCode - The zip code to be formatted.
   * @returns {string} The formatted zip code.
   */
  static getFormattedZipCode(zipCode: string): string {
    return zipCode.length > 5 ? zipCode.substring(0, 5) : zipCode;
  }

  /** get the formatted date based on the locale
   * @param value - The date value to be formatted.
   * @param locale - The locale from appSession. Default is English.
   * @returns {string} The formatted date.
   * */
  static getFormatedDate(value: string, locale: string = Locale.ENGLISH): string {
    // convert date format to "Month, dd, yyyy", eg: 06/22/2022 to June, 22, 2022
    if (typeof value !== 'string' || new Date(value).toString().toLowerCase() === 'invalid date') {
      return '';
    }
    const networkDate = new Date(value);
    const monthNames = this.getSummaryContent(locale).searchCriteriaComponent.monthNames;
    const month = monthNames[networkDate.getMonth()];
    const day = networkDate.getDate();
    const year = networkDate.getFullYear();
    const formattedDate = month + ' ' + day + ', ' + year;
    return formattedDate;
  }

  /** get the summaryComponent data from resultContent files based on the locale
   * @param locale - The locale from appSession. Default is English.
   * @returns {any} The summaryComponent data from the resultContent files.
   * */
  static getSummaryContent(locale: string = Locale.ENGLISH): any {
    return locale === Locale.SPANISH ? RESULT_CONTENT_ES.summaryComponent : RESULT_CONTENT_EN.summaryComponent;
  }

  /**
   * Get the network status message based on the providerNetworkStatus object
   * @param providerNetworkStatus - The providerNetworkStatus object.
   * @param locale - The locale from appSession. Default is English.
   * @param isResultView - The flag to check if the status text is for provider list view.
   * @returns {string} The network status message.
   */
  static getProviderNetworkStatus(providerNetworkStatus: IProviderNetworkStatus, appSession: AppSession, isResultView?: boolean): string {
    let networktext = '';
    let typeCode = '';
    let networkDate = '';
    const locale = appSession?.metaData?.locale || Locale.ENGLISH;
    const isSecureState = appSession.isSecureState;
    const summaryContent = this.getSummaryContent(locale);
    const statusCode = providerNetworkStatus?.statusCode;
    if (statusCode) {
      networktext = summaryContent.networkStatus[statusCode];
      networkDate = providerNetworkStatus.date;
      if (isSecureState) {
        // get the secured state network status message
        networktext = summaryContent.networkStatusSecured[providerNetworkStatus.statusCode];
      }
      if (isResultView) {
        // if status text is for provider list view
        networkDate = providerNetworkStatus.date;
        networktext = summaryContent.networkStatusSummary[providerNetworkStatus.statusCode];
      }
      if (networktext) {
        if (providerNetworkStatus.typeCode?.length) {
          providerNetworkStatus.typeCode.forEach((typeCd) => {
            // get the corresponding provider type code for each care type
            const providerType = CARE_TYPE.options.find((x) => x.value.toLocaleLowerCase() === typeCd.toLocaleLowerCase() || x.type.toLocaleLowerCase() === typeCd.toLocaleLowerCase());
            // get the corresponding locale string for the providerType.type from wcs
            let typeText = summaryContent.searchCriteriaComponent.providerTypes[providerType?.type];
            if (locale === Locale.ENGLISH && isResultView) {
              typeText = this.titleCase(typeText);
            }
            typeCode += providerType?.type ? typeText + ', ' : '';
          });
          // remove the ',' from end of the string
          typeCode = typeCode?.length ? typeCode.substring(0, typeCode.length - 2) : '';
        }
        // replace {PLANTYPE} and {DATE} with the values in Network Status Text
        networktext = networktext.replace(/{PLANTYPE}/gi, typeCode ? typeCode : '');
        networktext = networktext.replace('{DATE}', networkDate);
      }
    }
    return networktext ? networktext : '';
  }

  /** get the provider type text based on the providerTypeCodeList
   * @param appSession - The application session.
   * @param content - The content from resultContent files.
   * @returns {string} The provider type text.
   * */
  static getProviderTypeText(_appSession: AppSession, date: string) {
    const content = _appSession?.metaData?.locale === Locale.SPANISH ? RESULT_CONTENT_ES : RESULT_CONTENT_EN;
    const suffixName = content?.summaryComponent?.pdfFileName?.search_on + date;

    const defaultName = content?.summaryComponent?.searchCriteriaComponent?.labels?.careProviders;

    if (!_appSession?.searchParams) {
      return defaultName + suffixName;
    }

    const { coverageTypeCode, providerTypeCodeList } = _appSession?.searchParams;
    const typeSelected = providerTypeCodeList?.[0];

    if (!typeSelected) {
      return defaultName + suffixName;
    }

    const providerTypes = [PROVIDER_TYPE.DENTAL.toString(), PROVIDER_TYPE.VISION.toString()];
    const resultType = content?.summaryComponent?.resultsProvType[typeSelected + coverageTypeCode];

    if (providerTypes.includes(typeSelected) && coverageTypeCode === MEDICAL_COVERAGETYPE && resultType) {
      return resultType + suffixName;
    }

    const providers = content?.commonHealthCareContent?.providers;
    const type = providers.find((x) => x.code === typeSelected);

    if (type?.name && !this.isDentalSearch(_appSession)) {
      return type.name + suffixName;
    } else if (this.isDentalSearch(_appSession)) {
      return content?.summaryComponent?.resultsProvType[PROVIDER_TYPE.DENTAL] + suffixName;
    } else {
      return content?.summaryComponent?.resultsProvType[typeSelected] + suffixName || defaultName + suffixName;
    }
  }
  /**
   * Check if the search is for dental providers
   * @param appSession  The appSession.
   * @returns {boolean} True if the search is for dental providers, otherwise false.
   */
  static isDentalSearch(appSession: AppSession): boolean {
    return (
      appSession.hasDentalEYC &&
      appSession.searchParams &&
      appSession.searchParams.eycSearchParam &&
      appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
    );
  }

  static isResultSearchBlocked(appSession: AppSession): boolean {
    let isResultSearchBlocked = false;
    const pageAlerts = appSession.pageAlerts[FindCarePageType.Results].map((alert: IAlertMessage) => alert.id) || [];
    if (!isEmpty(pageAlerts)) {
      isResultSearchBlocked = pageAlerts.some((alertId: PageAlertID) => RESULT_PAGE_SEARCH_BLOCK_ALERT_CONFIG.indexOf(alertId) > -1);
    }
    return isResultSearchBlocked;
  }

  /**
   * Compares two string arrays for equality.
   * @param array1 The first string array.
   * @param array2 The second string array.
   * @returns {boolean} True if the arrays are equal, otherwise false.
   */
  static areStringArraysEqual(array1: string[] | null | undefined, array2: string[] | null | undefined): boolean {
    if (!array1 || !array2) {
      return false;
    }

    if (array1.length !== array2.length) {
      return false;
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    for (let i = 0; i < sortedArray1.length; i++) {
      if (sortedArray1[i] !== sortedArray2[i]) {
        return false;
      }
    }

    return true;
  }

  static displayStaticAlerts(_appSession: AppSession, pageType: FindCarePageType) {
    if (pageType === FindCarePageType.Landing) {
      // COUPE APERT MESSAGE
      if (_appSession.bootstrap.contracts?.length > 0 && FeatureManagerUtility.isFlagLive(FindCareFlag.MSG_COUPE_HEALTH_PRODUCT, _appSession)) {
        PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_COUPE_ALERT], _appSession, pageType);
      }
      // GA_TERMINATION ALERT
      const stateCode = _appSession.searchParams.stateCd?.code ? _appSession.searchParams.stateCd.code : '';
      if (STATE_GA === stateCode.toLocaleUpperCase() && _appSession.skippedPageAlerts && _appSession.skippedPageAlerts.some((alertId) => alertId === PageAlertID.PA_GA_TERMINATING_PROVIDERS_ALERT)) {
        PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_GA_TERMINATING_PROVIDERS_ALERT], _appSession, pageType);
      }
    }
    if (pageType === FindCarePageType.Results) {
      // BH_SEARCH_ASSISTANT ALERT
      if (_appSession.isEmulation && _appSession.skippedPageAlerts && _appSession.skippedPageAlerts.some((alertId) => alertId === PageAlertID.PA_BH_SEARCH_ASSISTANT)) {
        PageAlertHandler.buildStaticPageAlerts([PageAlertID.PA_BH_SEARCH_ASSISTANT], _appSession, pageType);
      }
    }
  }

  static getPlanNetworkPrefixText(_appSession: AppSession, content?: any) {
    let _prefixLbl = '';
    if (content?.summaryComponent) {
      _prefixLbl = content.summaryComponent.resultsHeader?.prefix;
    }
    if (_appSession.searchParams.plan?.identifier && _appSession.searchParams.plan?.name) {
      return _appSession.searchParams.plan.name;
    } else if (_appSession.deeplinkParams.alphaprefix && _appSession.searchParams.plan?.name.toLocaleLowerCase() === _appSession.deeplinkParams.alphaprefix?.toLocaleLowerCase()) {
      return _appSession.deeplinkParams.alphaprefix + ' ' + _prefixLbl;
    } else if (_appSession.deeplinkParams.alphaprefix && _appSession.searchParams.plan?.name && _appSession.searchParams.plan.name !== '') {
      return _appSession.searchParams.plan.name;
    } else if (_appSession.appState.selectedEligibilityProduct?.selectedContract?.coverages[0]?.planNm) {
      return _appSession.appState.selectedEligibilityProduct.selectedContract.coverages[0]?.planNm;
    }
  }

  /**
   *
   * @param contentLabel
   * @param appSession
   * @returns Procedure criteria deeplink url and append the constructed deeplink url to the content login url
   */
  public static getProcedureDeeplinkUrl(contentLabel: string, appSession: AppSession): string {
    const postalCode = Number(appSession?.searchParams?.zip?.trim());
    let deeplinkUrl: string = '';
    if (contentLabel) {
      if (postalCode) {
        deeplinkUrl = ZIPCODE + EQUAL_OPERAND + postalCode;
      } else if (appSession?.searchParams?.city && appSession?.searchParams?.stateCd?.code) {
        deeplinkUrl = CITY + EQUAL_OPERAND + appSession.searchParams.city + DOT_OPERAND + STATE + EQUAL_OPERAND + appSession.searchParams.stateCd.code;
      }
      const procedureCode = appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode;
      if (procedureCode) {
        deeplinkUrl += DOT_OPERAND + PROCCODE + EQUAL_OPERAND + procedureCode;
      }

      if (appSession?.metaData?.locale === Locale.SPANISH) {
        deeplinkUrl += DOT_OPERAND + CULTURE + EQUAL_OPERAND + CULTURE_VALUE;
      }
    }
    return deeplinkUrl;
  }
  /*
   * Clears the search criteria in the app session and resets it to default values.
   * @param appSession - The current application session containing search parameters.
   */
  static clearSearchCriteria(appSession: AppSession): void {
    if (!appSession) return;

    const searchParams = appSession.searchParams || {};
    const _searchCriteria: ISearchParameters = {
      plan: searchParams.plan,
      zip: searchParams.zip,
      stateCd: searchParams.stateCd,
      city: searchParams.city,
      countyCode: searchParams.countyCode,
      distanceNm: PROVIDER_SEARCH_DISTANCE,
      coordinates: searchParams.coordinates,
      coverageTypeCode: searchParams.coverageTypeCode,
      planCategoryDdlValues: searchParams.planCategoryDdlValues,
      planDdlValues: searchParams.planDdlValues,
      stateDdlValues: searchParams.stateDdlValues,
      typeOfCareNm: searchParams.typeOfCareNm,
      identificationNumber: searchParams.identificationNumber,
      aoeSelectNm: [],
      blueDistinctionCenter: [],
      nationalProviderIdentifier: '',
      ofcSrvcSelectNm: [],
      providerTypeCodeList: [],
      provNm: '',
      specialtySelectNm: [],
      taxonomySelectNm: [],
      typeSelectNm: '',
      visionSrvcAvailableList: [],
      ableToServePcp: appSession.deeplinkParams?.pcponly?.toLocaleLowerCase() === 'y' ? searchParams.ableToServePcp : undefined,
      includePrimeGroupSearch: true,
      eycPrefix: searchParams.eycPrefix,
      isPrefixStateEnabledForProcSearch: searchParams.isPrefixStateEnabledForProcSearch
    };

    appSession.searchParams = _searchCriteria;
    appSession.isAoeSearch = false;
    appSession.isEyc = false;
    appSession.isMedicalCodeSearch = false;
    appSession.isOfcSrvcSearch = false;
    appSession.isSpecialitySearch = false;
    appSession.isPrimeGroupSearch = false;
    appSession.pageNumber = '';
  }

  static isKeyTierFilterOption(value: string) {
    return value.toUpperCase() === TIER1.toUpperCase();
  }

  static getGenderText(genderCode: string, content: any): string {
    let genderText = '';
    if (genderCode !== undefined && genderCode.trim() !== '') {
      switch (genderCode.toUpperCase()) {
        case 'M':
        case 'MALE':
          genderText = content.result?.summaryComponent?.genderList?.M;
          break;
        case 'F':
        case 'FEMALE':
          genderText = content.result?.summaryComponent?.genderList?.F;
          break;
      }
      return genderText !== '' ? genderText : genderCode;
    }
  }

  static getGenderCode(genderCode: string): string {
    let _genderCode = '';
    if (typeof genderCode == 'string' && genderCode.trim() !== '') {
      switch (genderCode.toUpperCase()) {
        case 'MALE':
          _genderCode = 'M';
          break;
        case 'FEMALE':
          _genderCode = 'F';
          break;
      }
      return _genderCode;
    }
  }

  public static getSemImageUrl(appSession: AppSession): string {
    return appSession.appConfig?.images?.semBaseUrl || '';
  }

  static getLimitedMessage(msg: string, charLimit: number): string {
    const limitedMsg = (msg || '').slice(0, charLimit) + ((msg || '').length > charLimit ? '...' : '');
    return limitedMsg;
  }

  static checkIndividualProvWithProvTypeP(provCategoryCode: string): boolean {
    const _categoryCode = provCategoryCode?.trim() || '';
    return _categoryCode === PROVIDER_CATEGORY_CODE.Individual;
  }

  static isReviewRating(rating: IReviewResponse, provCategoryCode: string): boolean {
    if (!rating || !rating.starRating || !rating.totalReviews || !CommonUtility.showReviewLink(rating.totalReviews)) {
      return false;
    }
    return CommonUtility.checkIndividualProvWithProvTypeP(provCategoryCode);
  }

  static showReviewLink(totalReviews: string) {
    if (totalReviews?.toString().toLocaleLowerCase() === this.NOREVIEW) {
      return false;
    }
    return true;
  }
}
