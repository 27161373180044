import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UxSharedModule } from '@anthem/uxd-shared';
import { SSOFormComponent } from './ssoFormCmp';
import { SSOLinkDirective } from './ssoLinkDir';

@NgModule({
  declarations: [SSOFormComponent, SSOLinkDirective],
  exports: [SSOFormComponent, SSOLinkDirective],
  imports: [CommonModule, UxSharedModule]
})
export class SSOModule {}
