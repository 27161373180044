import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonSliderAlertModule } from '../common-slider-alert/commonSliderAlertModule';
import { AssignPcpCtaComponent } from './assignPcpCtaCmp';

@NgModule({
  declarations: [AssignPcpCtaComponent],
  exports: [AssignPcpCtaComponent],
  imports: [CommonModule, CommonSliderAlertModule]
})
export class AssignPcpCtaModule {}
