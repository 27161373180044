import { Injectable } from '@angular/core';
import { apiNameList } from '../../../environments/api-name-list';
import { HttpMethod } from '../enums/httpMethodEnum';
import { ISecureTccMedicalSearchRequest } from '../interfaces/iSecureTccMedicalSearchRequest';
import { ISecureTccMedicalSearchResponse } from '../interfaces/iSecureTccMedicalSearchResponse';
import { AppUtility } from '../utilities/appUtil';
import { AppSession } from '../values/appSession';
import { BaseService } from './baseService';
import { HttpClientService } from './httpClientService';
@Injectable({
  providedIn: 'root'
})
export class SecureV1TccMedical extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(searchRequest: ISecureTccMedicalSearchRequest): Promise<ISecureTccMedicalSearchResponse> {
    const apiUrl = apiNameList.restApi.secureV1TccMedical;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchRequest,
      headers: this.getHeaders()
    });
  }

  executeV2(searchRequest: ISecureTccMedicalSearchRequest): Promise<ISecureTccMedicalSearchResponse> {
    const apiUrl = apiNameList.restApi.secureV2TccMedical;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchRequest,
      headers: this.getHeaders()
    });
  }
}
