import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NAVIGATION_ALERT_IDS } from '../../../../contents/common/alertTypeConfig';

@Injectable({ providedIn: 'root' })
export class GenericAlertNavigationService implements OnDestroy {
  alertNavigation = new Subject<string>();


  ngOnDestroy() {
    this.alertNavigation.unsubscribe();
  }

  pageAlertNavigation(pageAlertID: string) {
    this.alertNavigation.next(pageAlertID);
  }


  alertNavigationSubjectListener() {
    return this.alertNavigation;
  }

  onClickOnPageAlert(pageAlertID : string) {
    if (pageAlertID === NAVIGATION_ALERT_IDS.PA_NAVIGATE_PLAN_SELECTION) {
      this.pageAlertNavigation(pageAlertID);
   }
  }
}
