import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IGroupAffiliations } from '../../../fad/search-results/interfaces/iGroupAffiliations';
import { ISearchRequest } from '../../common/interfaces/iSearchRequest';

@Injectable({
  providedIn: 'root'
})
export class AffiliationService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async getAffiliations(request: ISearchRequest): Promise<IGroupAffiliations> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.affiliationOptions,
      data: request,
      headers: this.getHeaders()
    });
  }
}
