import { Inject, Injectable } from '@angular/core';
import { Locale } from '../../../common/constants/app-constants';
import { AppSession } from '../../../common/values/appSession';
import { ILocationRequest } from '../../../fad/provider-details/models/iLocations';
import { OUT_OF_NETWORK } from '../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { PROVIDER_TYPE } from '../../common/constants/common';
import { IMemberCriteria } from '../../common/interfaces/iMemberCriteria';
import { ProviderLocationService } from '../../common/services/providerLocationSvc';
import { IDetail } from '../interfaces/iProviderDetail';
import { IProviderLocationRequest, IProviderLocationsResponse } from '../interfaces/iProviderLocation';

@Injectable({
  providedIn: 'root'
})
export class ProviderLocationHandler {
  constructor(
    private _providerLocationSvc: ProviderLocationService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderLocations(provider: IDetail): Promise<IProviderLocationsResponse> {
    try {
      const locationsRequest = this.buildRequest(provider);
      const apiResponse = await this._providerLocationSvc.getProviderLocations(locationsRequest);
      return apiResponse;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildRequest(provider: IDetail): IProviderLocationRequest {
    const selectedProvider = this._appSession.selectedProvider;
    const address = provider?.address;
    const searchParams = this._appSession?.searchParams;
    const metaData = this._appSession?.metaData;
    const locale = metaData?.locale?.toString() || Locale.ENGLISH.toString();
    let onlineFlag: boolean;
    const identifier = searchParams?.plan?.identifier ? searchParams.plan.identifier.trim() : '';
    const providerTypeList = [PROVIDER_TYPE.VISION, PROVIDER_TYPE.DENTAL, PROVIDER_TYPE.HOSPITAL, PROVIDER_TYPE.BEHAVIORAL_HEALTH, PROVIDER_TYPE.PHARMACY];

    if (
      provider?.isRegionalProviderIndicator ||
      provider?.isRemoteProviderIndicator ||
      (provider?.isOnlineProviderIndicator && providerTypeList.some((x) => provider?.providerTypeCodeList?.includes(x)))
    ) {
      onlineFlag = true;
    }

    const locationsRequest: ILocationRequest = {
      addressId: address?.addressId || selectedProvider.providerIdentifier,
      alphaPrefix: CommonUtil.getAlphaPrefix(this._appSession),
      brandCode: metaData?.brandCd,
      countyCode: searchParams?.countyCode,
      distance: searchParams?.distanceNm ? parseInt(searchParams.distanceNm.trim(), 10) : 0,
      id: provider?.providerIdentifier || selectedProvider.providerIdentifier,
      isOnlineProvider: onlineFlag || false,
      isShipDirectToDoor: provider?.isShipDirectToDoor || false,
      latitude: address?.latitude ? address?.latitude : searchParams?.coordinates?.latitude?.toString() || '',
      locale: locale,
      longitude: address?.longitude ? address?.longitude : searchParams?.coordinates?.longitude?.toString() || '',
      networks: searchParams?.plan?.networkList,
      plans: identifier ? [identifier] : undefined,
      planStateCode: searchParams?.plan?.stateCd,
      specialtyCategories: searchParams?.specialtySelectNm?.filter((x) => x !== '00') || undefined,
      state: searchParams?.stateCd?.code || selectedProvider.state,
      typeCodes: provider?.providerTypeCodeList,
      pharmacyNumber: provider?.providerIdentifier || selectedProvider.providerIdentifier
    };

    const memberCritria: IMemberCriteria = {};
    if (metaData?.appContract?.mbrUid) {
      memberCritria.mbrUid = metaData.appContract.mbrUid;
    }

    if (metaData?.appContract?.contractUid) {
      memberCritria.contractUid = metaData.appContract.contractUid;
    }

    // Add aditional parameters for CVS Pharmacy API.
    if (provider?.providerTypeCodeList?.length === 1 && provider?.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
      locationsRequest.typeCodes = [PROVIDER_TYPE.PHARMACY];
    }

    if (locationsRequest?.typeCodes?.includes(PROVIDER_TYPE.PHARMACY)) {
      locationsRequest.providerName = provider.providerName || selectedProvider.providerName;
      locationsRequest.pharmacyNumber = provider.providerIdentifier || selectedProvider.providerIdentifier;
      locationsRequest.coverageTypeCode = searchParams?.coverageTypeCode;

      locationsRequest.state = address?.state || searchParams?.stateCd?.code;
      locationsRequest.postalCode = address?.postalCode || (isNaN(Number(searchParams?.zip?.trim())) ? undefined : searchParams.zip.trim());
      locationsRequest.city = address?.city || selectedProvider?.addressSummary?.cityName || (searchParams.countyCode ? undefined : searchParams.city);
    }

    locationsRequest.memberCriteria = memberCritria;
    locationsRequest.coverageType = searchParams?.planTypeList?.includes(OUT_OF_NETWORK) ? OUT_OF_NETWORK : undefined;

    return locationsRequest;
  }
}
