import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProviderCard } from '../../../constants/result';
import { IDetail } from '../../../interfaces/iProviderDetail';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-disclaimer-overview-cmp',
  templateUrl: './providerDisclaimerOverviewCmp.html'
})
export class ProviderDisclaimerOverviewComponent extends BaseComponent {
  @Input() ruleList = [];
  isCollapsed: boolean = true;
  isReadMoreVisible: boolean = false;
  disclaimerContent = this.content?.result?.disclaimerCard;
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Input() providerDetails: IDetail;
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  showProviderLoader = true;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to Close Disclaimer Card.
   * Hiding Disclaimer Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  /**
   * Method to show Previous Card State in Provider Tab.
   * Hiding Disclaimer and Showing Provider Card.
   */
  onShowPreviousState(): void {
    this.cardSelected.emit(ProviderCard.OVERVIEW);
  }
}
