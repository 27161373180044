import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppSession } from '../../../common/values/appSession';
import { PageAlertHandler } from '../../common/components/shareable/page-alert/service/pageAlertHandler';
import { PageAlertSvc } from '../../common/components/shareable/page-alert/service/pageAlertSvc';
import { FindCarePageType } from '../../common/enums/findCarePages';
import { ProviderDetailHandler } from '../classes/providerDetailHandler';
import { IReportIncorrectDataResponse } from '../interfaces/iIncorrectData';
import { IProvider } from '../interfaces/iProvider';
import { IProviderOverview } from '../interfaces/iProviderDetail';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetail {
  private providerDetailSubject = new BehaviorSubject<IProviderOverview | undefined>(undefined);
  private readonly incorrectDetailSubject = new BehaviorSubject<IReportIncorrectDataResponse | undefined>(undefined);

  incorrectDetails = this.incorrectDetailSubject.asObservable();
  details = this.providerDetailSubject.asObservable();

  private detailHistory: IProviderOverview[] = [];

  constructor(
    private detailHandler: ProviderDetailHandler,
    private _appSession: AppSession,
    private _pageAlertSvc: PageAlertSvc
  ) {}

  async initialize(providerIdentifier: string, asddressIdentifier: string, provider: IProvider): Promise<void> {
    const currentProviderDetail = this.providerDetailSubject.getValue()?.providerDetail;
    if (currentProviderDetail?.providerIdentifier !== providerIdentifier || currentProviderDetail?.address.addressId !== asddressIdentifier) {
      // call page alert with detail page for provider ddtail.
      this._appSession.providerTypeCodes = provider?.providerTypeCodeList ? provider.providerTypeCodeList : this._appSession.searchParams?.providerTypeCodeList;
      this._pageAlertSvc.getPageAlerts(PageAlertHandler.buildPageAlertRequest(FindCarePageType.DetailOverview, this._appSession));
      const providerDetail = await this.detailHandler.getProviderDetail(providerIdentifier, asddressIdentifier, provider);
      this.setDetail(providerDetail);
    }
  }

  reset(): void {
    this.providerDetailSubject.next(undefined);
    this._appSession.ruleList = [];
    this.incorrectDetailSubject.next(undefined);
  }

  private setDetail(providerDetail: IProviderOverview): void {
    if (providerDetail) {
      this.providerDetailSubject.next(providerDetail);
      const providerIndex = this.detailHistory.findIndex((provider) => provider.providerDetail.providerIdentifier === providerDetail.providerDetail.providerIdentifier);
      providerIndex === -1 && this.detailHistory.push(providerDetail);
    }
  }

  getParentDetail() {
    if (this.detailHistory.length > 1) {
      const previousDetail = this.detailHistory[this.detailHistory.length - 2];
      this.providerDetailSubject.next(previousDetail);
    }
  }

  getSelectedProviderDetail(selectedProvider: IProvider) {
    if (this.detailHistory.length > 0) {
      const previousDetail = this.detailHistory?.find((provider) => {
        return provider.providerDetail.providerIdentifier === selectedProvider.providerIdentifier;
      });
      this.providerDetailSubject.next(previousDetail);
    }
  }
}
