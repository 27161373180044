import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { PhonePatternPipe } from '../../../../common/pipes/phonePatternPipe';
import { IAdvocateDetails } from '../../interfaces/iCareCircle';
import { CareCircleUtil } from '../../utilities/careCircleUtil';
import { EventHandler } from './../../../../common/services/eventHandler';
import { AppSession } from './../../../../common/values/appSession';
import { BaseComponent } from './../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-advocate-details-cmp',
  templateUrl: './advocateDetailsCmp.html'
})
export class AdvocateDetailsComponent extends BaseComponent {
  selectedAdvocate: IAdvocateDetails;
  careCircleContent = this.content?.careCircle;
  careCircleInfo = this.careCircleContent?.careProvidersComponent?.labels;
  careCircleOptionsContent = this.careCircleContent?.careCircleOptions;
  advocateDetailsPanelRef: ModalRef<HTMLElement>;
  @ViewChild('advocateDetailsModal') advocateDetailsModal: TemplateRef<HTMLElement>;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _sidePanel: SidePanel,
    private _careCircleUtil: CareCircleUtil,
    private _phonePatternPipe: PhonePatternPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  get advocateName(): string {
    return this.selectedAdvocate?.firstName + ', ' + this.selectedAdvocate?.lastName;
  }

  /**
   * Method to open side panel
   */
  openSidePanel(advocate: IAdvocateDetails) {
    this.selectedAdvocate = advocate;
    this.advocateDetailsPanelRef = this._sidePanel.open('right', this.advocateDetailsModal);
  }

  /**
   * Close the Advocate Details side panel
   */
  onClose() {
    this.advocateDetailsPanelRef.close();
    this.selectedAdvocate = null;
  }

  navigateToIdCardPage() {
    this.onClose();
    this._careCircleUtil.navigateToIdCardPage();
  }

  formatPhoneNumber(phone: string): string {
    return this._phonePatternPipe.transform(phone);
  }
}
