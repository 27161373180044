import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { IFtsClientAssistanceResponse, IFtsClientRequest, IFtsSuggestionResponse } from '../interfaces/iFtsClientInteraction';

@Injectable({
  providedIn: 'root'
})
export class FreeTextSearchSvc extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async getAssistance(request: IFtsClientRequest): Promise<IFtsClientAssistanceResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.assistanceV1,
      data: request,
      headers: this.getHeaders()
    });
  }

  async getSuggestion(request: IFtsClientRequest): Promise<IFtsSuggestionResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.suggestionsV1,
      data: request,
      headers: this.getHeaders()
    });
  }
}
