import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProviderCard } from '../../../constants/result';

@Component({
  moduleId: module.id,
  selector: 'app-fc-cpt-card-cmp',
  templateUrl: './cptCardCmp.html'
})
export class CptCardComponent extends BaseComponent implements OnInit {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  specialityContent = this.content?.result?.specialityCard;
  @Input()
  isProcedureDetailsVisible: boolean = false;
  billingTypeCpt: string | undefined;
  medicalCodeCpt: string | undefined;
  medicalCodeDescriptionCpt: string | undefined;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    const selectedCptInfo = this._appSession.selectedCptInfo;
    if (selectedCptInfo) {
      this.billingTypeCpt = selectedCptInfo.billingType;
      this.medicalCodeCpt = selectedCptInfo.medicalCode.code;
      this.medicalCodeDescriptionCpt = selectedCptInfo.medicalCode.description;
    }
  }

  /**
   * Method to Close Cpt Card.
   * Hiding Cpt Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  onShowPreviousState() {
    this.cardSelected.emit(ProviderCard.COST_DETAILS);
  }
}
