import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { BaseComponent } from '../../../../common/components/core/baseCmp';
import { ProviderCard } from '../../../constants/result';
import { IHealthWiseResponse } from '../../../interfaces/iProviderSpeciality';

@Component({
  moduleId: module.id,
  selector: 'app-fc-speciality-card-cmp',
  templateUrl: './specialityCardCmp.html'
})
export class SpecialityCardComponent extends BaseComponent {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Input() specialityDetails: IHealthWiseResponse;
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  specialityContent = this.content?.result?.specialityCard;
  @Input() isMoreDetails: boolean;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {
    super(_route, _eventHandler, _appSession);
  }

  get specialtyDescription() {
    const specialtyData = this.specialityDetails?.data?.[0];
    return (specialtyData?.isHealthwiseContent ? specialtyData?.hwContent?.html : specialtyData?.description) || '';
  }

  /**
   * Method to Close Speciality Card.
   * Hiding Speciality Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  onShowPreviousState() {
    if (this.isMoreDetails) {
      this.cardSelected.emit(ProviderCard.MORE_DETAILS);
    } else {
      this.cardSelected.emit(ProviderCard.OVERVIEW);
    }
  }
}
