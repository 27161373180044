import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ProviderFlag } from '../../../../common/constants/common';
import { CommonUtility } from '../../../../common/utilities/commonUtil';
import { IDetail } from '../../../interfaces/iProviderDetail';
import { BaseComponent } from './../../../../common/components/core/baseCmp';
@Component({
  moduleId: module.id,
  selector: 'app-fc-select-provider',
  templateUrl: './selectProviderCmp.html'
})
export class SelectProviderComponent extends BaseComponent implements OnInit {
  selectProviderBtnText = this.content?.result?.providerCardDetails?.selectProvider;
  showSelectProvider: boolean = false;
  @Input()
  provider: IDetail;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.showSelectProvider = this._appSession?.feature?.customizedFeatures?.showSelectProvider;
  }

  onSelectProvider() {
    this.selectProvider(this.transform(this.provider as any));
  }

  private transform(provider) {
    if (!provider) {
      return null;
    }

    const _homeHlthCareIndc = provider.address?.homeHlthCareIndc || provider.providerFlags?.includes(ProviderFlag.HOME_HEALTH_CARE) || false;

    return {
      providerIdentifier: provider.providerIdentifier,
      providerTypeCodeList: provider.providerTypeCodeList,
      networkRegisteredName: provider.networkRegisteredName,
      providerName: provider.name?.providerName,
      firstName: provider.name?.firstName,
      middleName: provider.name?.middleName,
      lastName: provider.name?.lastName,
      gender: CommonUtility.getGenderCode(provider.gender),
      acceptsNewPatients: provider.anp?.name?.toUpperCase() == 'NO' ? 'N' : 'Y',
      providerCategoryCode: provider.providerCategoryCode,
      specialties: provider.specialities,
      titleList: provider.titleList,
      providerNetworkStatus: {
        date: provider.providerNetworkStatus?.date,
        typeCode: provider.providerNetworkStatus?.coverages,
        statusCode: provider.providerNetworkStatus?.status
      },
      location: {
        address: { ...provider.address, homeHlthCareIndc: _homeHlthCareIndc }
      },
      pcpList: provider.pcpInfoList?.map((pcp) => pcp.id),
      sourceSystemName: provider.sourceSystemName,
      pcpInfoList: provider.pcpInfoList,
      providerLanguages: provider.languages,
      nationalProviderIdentifier: provider.nationalProviderIdentifier,
      pdtKey: provider.pdtKey
    };
  }
}
