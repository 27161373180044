import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'providerOfficeHours'
})
export class providerHoursPipe implements PipeTransform {
  transform(officeHours: string[], notOpen: string): string[] {
    const daysMap = {
      M: 'Mon',
      Tu: 'Tue',
      W: 'Wed',
      Th: 'Thu',
      F: 'Fri',
      Mo: 'Mon',
      We: 'Wed',
      Fr: 'Fri',
      Sa: 'Sat',
      Su: 'Sun',
      Thur: 'Thu',
      Tues: 'Tue',
      Mond: 'Mon',
      Wedn: 'Wed',
      Frid: 'Fri',
      Satu: 'Sat',
      Sund: 'Sun'
    };

    const allDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const officeHoursMap = new Map<string, string>();

    officeHours.forEach((hour) => {
      const entries = hour.split(', ');
      entries.forEach((entry) => {
        const day = entry.split(' ')[0];
        const formattedDay = daysMap[day] || day;
        let hoursData = entry.replace(day, '').trim().replace('-', ' TO ');

        if (!hoursData.includes('AM') && !hoursData.includes('PM')) {
          hoursData = hoursData
            .split(' TO ')
            .map((time) => {
              const [hours, minute] = time.split(':');
              const hourInt = parseInt(hours, 10);
              const period = hourInt >= 12 ? 'PM' : 'AM';
              const formattedHour = hourInt % 12 || 12;
              return `${formattedHour}:${minute} ${period}`;
            })
            .join(' TO ');
        }

        officeHoursMap.set(formattedDay, hoursData);
      });
    });

    return allDays.map((day) => officeHoursMap.get(day) || notOpen);
  }
}
