import { Component, Inject, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { Subscription } from 'rxjs';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { ITranslation } from '../../../../fad/search-providers/interfaces/iAdaListResp';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IFtsSuggestion } from '../../../common/interfaces/iFtsClientInteraction';
import { FtsAvailableSuggestionService } from '../../../common/services/ftsAvailableSuggestionSvc';
import { FtsUtility } from '../../../common/utilities/ftsUtil';

@Component({
  moduleId: module.id,
  selector: 'app-fc-fts-available-suggestions-cmp',
  templateUrl: './ftsAvailableSuggestions.html',
  providers: []
})
export class FtsAvailableSuggestionsComponent extends BaseComponent implements OnInit {
  searchTerm: string = '';
  searchCriteriaToDisplay: string = '';
  showViewSimilarSearches: boolean = false;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;

  @Input()
  suggestions: IFtsSuggestion<ITranslation>[];
  @ViewChild('ftsSimilarSearches')
  ftsSimilarSearches: TemplateRef<HTMLElement>;
  showAvailableSuggestions: boolean = false;

  private suggestionSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _availableSuggestionSvc: FtsAvailableSuggestionService,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
    this.suggestionSubscription = this._availableSuggestionSvc.getSuggestions.subscribe((data: IFtsSuggestion<ITranslation>[]) => {
      if (data) {
        this.bindSuggestions(data);
      }
    });
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnDestroy(): void {
    if (this.suggestionSubscription) {
      this._availableSuggestionSvc.setSuggestion(undefined);
      this.suggestionSubscription.unsubscribe();
    }
  }

  bindSuggestions(suggestion: IFtsSuggestion<ITranslation>[]) {
    this.searchTerm = this._appSession.searchTerm;
    this.searchCriteriaToDisplay = '';
    if (suggestion?.length && (FtsUtility.hasSpecialty(suggestion[0]) || FtsUtility.hasProcedure(suggestion[0]) || FtsUtility.hasMedicalCode(suggestion[0]))) {
      this.suggestions = suggestion;
      this.searchCriteriaToDisplay = FtsUtility.displaySearchCriteria(suggestion[0], this.content.common.freeTextSearchResults);
      this.showAvailableSuggestions = this.isFtsFeatureActive && this.searchCriteriaToDisplay.length > 0;
      if (this.suggestions.length > 1) {
        this.showViewSimilarSearches = this.suggestions.some(
          (suggestionItem) => FtsUtility.hasSpecialty(suggestionItem) || FtsUtility.hasProcedure(suggestionItem) || FtsUtility.hasMedicalCode(suggestionItem)
        );
      }
    }
  }

  get searchResultMsg() {
    return this.content?.common.freeTextSearchResults?.yourSearch + '  ' + `<strong>${this.searchCriteriaToDisplay}</strong>`;
  }
  dismissResultSummary() {
    this.showAvailableSuggestions = false;
  }

  onViewSimilarSearchClick() {
    this.sidePanelRef = this._sidePanel.open('right', this.ftsSimilarSearches);
  }

  onClose() {
    this.sidePanelRef.close();
  }
}
