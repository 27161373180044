import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { AlertModule, SnackbarAlertContainerComponent } from '@anthem/uxd/alert';
import { FtsAvailableSuggestionsComponent } from '../../../../result/components/freeTextSearchResultSummary/ftsAvailableSuggestions';
import { FeedbackComponent } from '../../../../result/components/freeTextSearchResultSummary/ftsFeedback/feedbackCmp';
import { FtsSimilarSearchesComponent } from '../../../../result/components/freeTextSearchResultSummary/ftsSimilarSearches';
import { GlobalHeaderModule } from '../global-header/globalHeaderModule';
import { LookUpContainerModule } from '../look-up-container/lookUpContainerModule';
import { MemeberChipsModule } from '../member-chips/memberChipsModule';
import { ActionableHeaderModule } from './../../../../../common/components/actionable-header/actionableHeaderModule';
import { GeoLocationModule } from './../../../../../common/components/geoLocation/geoLocationModule';
import { LocationModule } from './../../../../../common/components/location/locationModule';
import { CustomPipesModule } from './../../../../../common/pipes/customPipesModule';
import { AddressFormComponent } from './components/address-form/addressFormCmp';
import { ChangePlanComponent } from './components/change-plan/changePlanCmp';
import { FreeTextSearchComponent } from './components/free-text-search/freeTextSearchCmp';
import { FtsMoreExamplesComponent } from './components/free-text-search/ftsMoreExamplesCmp';
import { IntegratedSearchComponent } from './components/integrated-search/integratedSearchCmp';
import { LocationWrapperComponent } from './components/location-wrapper/locationWrapperCmp';
import { CarePlanWrapperComponent } from './components/plan-wrapper/carePlanWrapperCmp';
import { SearchWrapperComponent } from './components/search-wrapper/searchWrapperCmp';
import { SwitchLocationComponent } from './components/switch-location/switchLocationCmp';
import { SearchHeaderComponent } from './searchHeaderCmp';

@NgModule({
  declarations: [
    SearchHeaderComponent,
    CarePlanWrapperComponent,
    ChangePlanComponent,
    SearchWrapperComponent,
    IntegratedSearchComponent,
    FreeTextSearchComponent,
    FtsAvailableSuggestionsComponent,
    FeedbackComponent,
    FtsSimilarSearchesComponent,
    LocationWrapperComponent,
    AddressFormComponent,
    SwitchLocationComponent,
    FtsMoreExamplesComponent
  ],
  exports: [
    SearchHeaderComponent,
    CarePlanWrapperComponent,
    ChangePlanComponent,
    SearchWrapperComponent,
    IntegratedSearchComponent,
    FreeTextSearchComponent,
    FtsAvailableSuggestionsComponent,
    FeedbackComponent,
    FtsSimilarSearchesComponent,
    LocationWrapperComponent,
    AddressFormComponent,
    SwitchLocationComponent,
    FtsMoreExamplesComponent,
    SnackbarAlertContainerComponent,
    AlertModule
  ],
  imports: [
    GlobalHeaderModule,
    MemeberChipsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    UxSharedModule.forRoot(),
    FormsModule,
    LookUpContainerModule,
    ActionableHeaderModule,
    GeoLocationModule,
    LocationModule,
    AlertModule.forRoot(),
    CustomPipesModule
  ],
  providers: []
})
export class SearchHeaderModule {}
