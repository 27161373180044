import { isEmpty } from 'lodash';
import { TIER1 } from '../../../common/constants/app-constants';
import { IProviderMiscFilterInRequest, IRecognitionRequest } from '../../../common/interfaces/iCommonSearchRequest';
import { ISearchParameters } from '../../../fad/search-providers/interfaces/iSearchParameters';
import { IRxFilter } from '../../result/interfaces/iRxFilter';

export class SearchRequestUtility {
  static buildRxFilterRequest(searchParams: ISearchParameters): IRxFilter {
    const rxFilter: IRxFilter = {};
    if ((searchParams?.dispenseTypeCode ?? []).length > 0) {
      rxFilter.dispenseTypeCode = searchParams?.dispenseTypeCode;
    }
    if ((searchParams?.languages ?? []).length > 0) {
      rxFilter.languageCodes = searchParams?.languages;
    }
    if (!isEmpty(searchParams?.pharmacyLevel)) {
      rxFilter.pharmacyLevel = searchParams?.pharmacyLevel;
    }
    if ((searchParams?.pharmacyFeatures ?? []).length > 0) {
      rxFilter.features = searchParams?.pharmacyFeatures;
    }
    return rxFilter;
  }

  static buildMiscFilterRequest(searchParams: ISearchParameters): IProviderMiscFilterInRequest {
    const miscFilter: IProviderMiscFilterInRequest = {};

    if (searchParams) {
      miscFilter.patientPopulationList = searchParams.patientPreferenceSelectNm ?? [];
      miscFilter.extendedHoursOnly = searchParams.extendedHoursOnly ?? false;
      miscFilter.medicaid = searchParams.medicaidInd ?? false;
      miscFilter.smartSearchRequestId = searchParams.smartSearchRequestId ?? '';

      let hasTier = false;
      if (Array.isArray(searchParams.recognitionCodes) && searchParams.recognitionCodes.length > 0) {
        hasTier = searchParams.recognitionCodes.some((code) => code === TIER1);
        miscFilter.recognitionCodes = searchParams.recognitionCodes.filter((code) => code !== TIER1);
      }

      if (Array.isArray(searchParams.blueDistinctions) && searchParams.blueDistinctions.length > 0) {
        miscFilter.blueDistinctions = searchParams.blueDistinctions;
      }

      const recognition: IRecognitionRequest = {
        providerTier: searchParams.providerTier?.length ? searchParams.providerTier : hasTier ? TIER1 : undefined,
        cmeDesignationList: searchParams?.cmeValue
      };
      miscFilter.recognition = recognition;
    }

    return miscFilter;
  }
}
