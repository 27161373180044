import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IntentScenario } from '../../../../../common/enums/intent';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { NavigationService } from '../../../../../common/services/navigationService';
import { AppSession } from '../../../../../common/values/appSession';
import { SydneyChatbotEventChannelUtil } from '../../../../../fad/utilities/sydneyChatbotEventChannelUtil';
import { IProviderList } from '../../../../result/interfaces/iProvider';
import { ALERT_CLOSE_ANALYTICS_CONFIG, NAVIGATION_ALERT_IDS } from '../../../contents/common/alertTypeConfig';
import { FindCarePageType } from '../../../enums/findCarePages';
import { IAlertMessage } from '../../../interfaces/iPageAlert';
import { BaseComponent } from '../../core/baseCmp';
import { GenericAlertNavigationService } from '../modal/services/genericAlertNavigationSvc';
import { SearchSlideOutComponent } from '../search-slide-out/searchSlideOutCmp';
import { CptSearchSlideOutTabs } from '../search-slide-out/searchSlideOutConstants';
import { PageAlertsContainerComponent } from './pageAlertContainerCmp';
import { PageAlertHandler } from './service/pageAlertHandler';

@Component({
  moduleId: module.id,
  selector: 'app-fc-page-alert-cmp',
  templateUrl: './pageAlertCmp.html'
})
export class PageAlertComponent extends BaseComponent implements OnInit {
  @Input() public pageName: string;
  @Input() providersInfo: IProviderList;
  @ViewChild('pageAlertSidePannelModal') showAlertsSidePannel: TemplateRef<PageAlertsContainerComponent>;
  @ViewChild('searchSlideOutContentModal')
  searchSlideOutContentModal: SearchSlideOutComponent;
  path: string = '';
  pageAlertsId: string[] = [];
  pageAlertToShow: IAlertMessage = null;
  viewMoreAlert: string = undefined;
  sidePanelRef: ModalRef<any, any>;
  isAlertHidden: boolean = false;
  sanitizedHtml: SafeHtml;
  closeIconDataAnalyticsTag: string;

  @Output() noticesEvent = new EventEmitter();

  constructor(
    public _route: ActivatedRoute,
    public _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    @Inject(AppSession)
    private _appSession: AppSession,
    private sanitizer: DomSanitizer,
    private _sidePanel?: SidePanel,
    @Inject('Window')
    private _window?: IWindow,
    private genericAlertNavigationService?: GenericAlertNavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  isPageAlert(): boolean {
    const alerts = this._appSession.pageAlerts[this.pageName] ? this._appSession.pageAlerts[this.pageName] : [];
    return alerts.length > 0;
  }

  isSingleAlert(): boolean {
    const currentPageAlertSize = this._appSession.pageAlerts[this.pageName]?.length;
    this.pageAlertToShow = currentPageAlertSize === 1 ? this._appSession.pageAlerts[this.pageName][0] : ({} as IAlertMessage);
    this.viewMoreAlert =
      currentPageAlertSize > 1 ? `${this.content.common.pageAlertComponentLabels?.view} ${currentPageAlertSize} ${this.content.common.pageAlertComponentLabels?.importantNotices}` : undefined;
    return currentPageAlertSize === 1 ? true : false;
  }

  getMessage(alertMessage: IAlertMessage) {
    const message = PageAlertHandler.replaceLiteralwithContent(alertMessage.id, this.providersInfo, this._appSession, this.content);
    if (ALERT_CLOSE_ANALYTICS_CONFIG.includes(alertMessage.id) && this.content.common.pageAlertCloseTag[alertMessage.id]) {
      this.closeIconDataAnalyticsTag = this.content.common.pageAlertCloseTag[alertMessage.id];
    }
    return this.sanitizer.bypassSecurityTrustHtml(message);
  }

  onViewMoreClick(pageName: string) {
    // cehck location first
    if (!(this._appSession.searchParams.coordinates && this._appSession.searchParams.coordinates.latitude !== '' && this._appSession.searchParams.coordinates.longitude !== '')) {
      return;
    }
    this.onClose();
    if (pageName === FindCarePageType.Results) {
      this.searchSlideOutContentModal.openSidePanel('right', 'procedureCode', CptSearchSlideOutTabs.ALERTS);
    } else {
      this.sidePanelRef = this._sidePanel.open('right', this.showAlertsSidePannel);
    }
  }

  openNoticesModel(pageName: string) {
    this.noticesEvent.emit(pageName);
  }

  onClose() {
    this.sidePanelRef?.close();
  }

  dismissAlert() {
    this.isAlertHidden = true;
  }

  @HostListener('click', ['$event']) onSearchClick(event) {
    if (event?.target?.className === 'navigateToChatBtn') {
      SydneyChatbotEventChannelUtil.publishEventForIntent(IntentScenario.BH_NON_CRISIS, this._appSession.bhNonCrisisIntentQuery, this._appSession, this._eventHandler);
    }
    if (
      event?.target?.className?.includes(NAVIGATION_ALERT_IDS.PA_NAVIGATE_PLAN_SELECTION)
    ) {
      this.genericAlertNavigationService.onClickOnPageAlert(NAVIGATION_ALERT_IDS.PA_NAVIGATE_PLAN_SELECTION);
    }
  }
}
