import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITranslation } from '../../../fad/search-providers/interfaces/iAdaListResp';
import { IFtsSuggestion } from '../interfaces/iFtsClientInteraction';

@Injectable({ providedIn: 'root' })
export class FtsAvailableSuggestionService {
  public suggestions: IFtsSuggestion<ITranslation>[] = undefined;
  private availableSuggestions = new BehaviorSubject<any>(this.suggestions);

  get getSuggestions() {
    return this.availableSuggestions.asObservable();
  }

  setSuggestion(suggestions: IFtsSuggestion<ITranslation>[]) {
    this.availableSuggestions.next(suggestions);
  }
}
