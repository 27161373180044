import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { chain, flatMap, isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { GEO_LOCATION_SEARCH_TYPE, IGeoLocation, IGeoLocationInfo, IGeoLocationInfoWithCounties } from '../../../../../../../common/components/geoLocation/models/geoLocation';
import { GeoLocationService } from '../../../../../../../common/components/geoLocation/services/geoLocationService';
import { LocationAdapterSvc } from '../../../../../../../common/components/geoLocation/services/locationAdapterSvc';
import { InfoListService } from '../../../../../../../common/components/info-list/services/infoListSvc';
import { LocationService } from '../../../../../../../common/components/location/services/locationService';
import { ScenarioAlertSvc } from '../../../../../../../common/components/scenarioAlert/services/pfScenarioAlertSvc';
import { Locale } from '../../../../../../../common/constants/app-constants';
import { AppNavigations } from '../../../../../../../common/constants/app-navigations';
import { DataHelper } from '../../../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { NavigationService } from '../../../../../../../common/services/navigationService';
import { RouteUtil } from '../../../../../../../common/utilities/routeUtil';
import { AppSession } from '../../../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../../../common/values/contentHelper';
import { IPrefixMetadataRequest, IPrefixMetadataResponse } from '../../../../../../../fad/search-providers/interfaces/iPrefixMetadata';
import { ISearchParameters } from '../../../../../../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from '../../../../../../../fad/search-providers/services/providerUtilitySvc';
import { SAVNavigationService } from '../../../../../../../fad/search-providers/services/savNavigationSvc';
import { STATE_GA } from '../../../../../../../fad/search-providers/values/providerSearchConstants';
import { CommonUtil } from '../../../../../../../fad/utilities/commonUtil';
import { MbrAddressType } from '../../../../../constants/common';
import { FindCarePageType } from '../../../../../enums/findCarePages';
import { PageAlertID } from '../../../../../enums/pageAlertId';
import { IMemberAddress } from '../../../../../interfaces/iContract';
import { BaseComponent } from '../../../../core/baseCmp';
import { PageAlertHandler } from '../../../page-alert/service/pageAlertHandler';
import { PageAlertSvc } from '../../../page-alert/service/pageAlertSvc';
import { SearchHeaderService } from '../../services/searchHeaderSvc';
import { SwitchLocationComponent } from '../switch-location/switchLocationCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-location-wrapper-cmp',
  templateUrl: './locationWrapperCmp.html'
})
export class LocationWrapperComponent extends BaseComponent implements OnInit {
  @Input()
  searchParams: ISearchParameters;
  @Output()
  onCtrlFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
  sidePanelRef: ModalRef<any, any>;
  @ViewChild('switchLocationModal') openAddressSelection: TemplateRef<SwitchLocationComponent>;
  locale: string;
  location = '';
  locationError = false;
  private _appLoader = false;
  private geoLocationSubscription: Subscription;
  private closeModelSubscription: Subscription;
  private memberChangeEventSubscription: Subscription;
  private currentLocationEventSubscription: Subscription;
  private _locationTxt;
  get locationTxt() {
    return this._locationTxt ? this._locationTxt : '';
  }
  set locationTxt(val) {
    this._locationTxt = val;
  }
  defaultLocationParam: IGeoLocation = {
    city: '',
    stateCd: '',
    stateName: '',
    county: '',
    zipcode: '',
    addressLine1: '',
    addressLine2: '',
    countyCode: '',
    isHomeAddressSearch: false,
    isGeoCodeSearch: false,
    isOtherAddressSearch: false,
    isCountySearch: false,
    isZipStateCountySearch: false,
    searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY
  };
  private _searchLocationSubscription: Subscription;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    private _geoLocationService: GeoLocationService,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _route: ActivatedRoute,
    private _contentHelper: ContentHelper,
    private _searchHeaderService: SearchHeaderService,
    private _savNavigationService?: SAVNavigationService,
    private _navigationService?: NavigationService,
    private _sidePanel?: SidePanel,
    private _locationAdapterSvc?: LocationAdapterSvc,
    private _routeUtil?: RouteUtil,
    private _locationService?: LocationService,
    private _infoListSvc?: InfoListService,
    private _providerUtilityService?: ProviderUtilityService,
    private _scenarioAlertSvc?: ScenarioAlertSvc,
    private _pageAlertSvc?: PageAlertSvc
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    if (isEmpty(this.searchParams) || !this.searchParams) {
      this.searchParams = this._appSession.searchParams;
    }
    this.locale = this._appSession && this._appSession.metaData && this._appSession.metaData.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH;
    this.locationTxt = this.searchParams && this.searchParams.zip ? this.searchParams.zip : '';
    //set the default search type
    if (!isEmpty(this._appSession.geolocationInfo)) {
      this.defaultLocationParam = this._appSession.geolocationInfo;
      this.setCoordinates(true);
    } else if (this.isDeepLinkSearchWithLocation()) {
      this.defaultLocationValidator(true, true);
      this.setDeeplinkParameters();
    } else {
      this.defaultLocationValidator(true);
    }
    this.geoLocationSubscription = this._geoLocationService.getGeoLocation().subscribe((data) => {
      if (!isEmpty(data)) {
        this.defaultLocationParam = { ...this.defaultLocationParam, ...data };
        this.setAppSessionCordinates(this.defaultLocationParam);
        //refresh the page if location params change
        const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
        if (this._contentHelper.isSearchResultRoute(_routeUrl)) {
          this.setCoordinates();
        } else {
          this.setCoordinates(true);
          this.showRedColorBorder(data);
        }
      }
    });
    this.closeModelSubscription = this._geoLocationService.getCloseModelObservable().subscribe((data) => {
      this.onClose();
    });

    this.currentLocationEventSubscription = this._geoLocationService.getCurrentLocationChangeEvent().subscribe(() => {
      this.getCurrentLocationGeoInfo(false, !this._dataHelper.isEmptyString(this._locationTxt));
    });
    if (this.isMemberSecure && this._dataHelper.isEmptyObject(this._appSession.addressesByMember)) {
      const groupedAddresses = chain(this._appSession.metaData?.appContract?.memberList)
        .groupBy('mbrUid')
        .mapValues((members) => flatMap(members, 'addresses'))
        .value();

      this._appSession.addressesByMember = groupedAddresses;
    }
    this._searchLocationSubscription = this._searchHeaderService.searchLocation.subscribe((data: IGeoLocation) => {
      if (!data) {
        return;
      }
      this.defaultLocationParam = { ...this.defaultLocationParam, ...data };
      this.setAppSessionCordinates(this.defaultLocationParam);
      this.setCoordinates(true);
    });
  }

  get showLoader(): boolean {
    return this._appLoader;
  }

  //Method to set the default location search based on user location or default location
  private setDefaultLocationSearch(init: boolean): void {
    if (this.defaultLocationParam.isUserLocationValid) {
      const memberAddressDetails: IGeoLocationInfo = this._appSession.memberAddressDetails;
      if (!this._dataHelper.isEmptyObject(memberAddressDetails)) {
        this._locationTxt = memberAddressDetails.zipcode?.toString();
        this.setHomeAddressDetails(memberAddressDetails);
        this.setCoordinates(init);
      }
    } else if (this.defaultLocationParam.isGeoCodeAllowed) {
      const defaultGeotLocation = this._appSession.defaultLocation;
      if (!this._dataHelper.isEmptyObject(defaultGeotLocation) && defaultGeotLocation.latitude && defaultGeotLocation.longitude) {
        this._locationTxt = defaultGeotLocation.zip?.toString();
        this.setGeoCodeDetails(defaultGeotLocation);
        this.setCoordinates(init);
      }
    }
  }

  //Method to open the switch location modal
  openSwitchLocationModal() {
    this.sidePanelRef = this._sidePanel.open('right', this.openAddressSelection);
  }

  onClose() {
    if (this.sidePanelRef) {
      this.sidePanelRef.close();
    }
  }

  /**
   * Retrieves the member's home address from the selected contract.
   * @returns {IMemberAddress} The member's home address or the first address if home address is not found.
   */
  private getMemberAddress(): IMemberAddress {
    const memberAddresses = this._appSession.appState?.selectedEligibilityProduct?.selectedContract?.addresses || [];

    if (memberAddresses.length === 0) {
      return undefined;
    }

    // Find the home address
    const homeAddress = memberAddresses.find((address) => this._dataHelper.areEqualStrings(address?.addressType, MbrAddressType.HOME));

    // Return the home address if found, otherwise return the first address
    return homeAddress || memberAddresses[0];
  }

  //Method to validate the default location
  public defaultLocationValidator(init: boolean = false, isDeepLinkSearch?: boolean): void {
    if (this.isSecureState && !this._dataHelper.isEmptyArray(this._appSession.metaData?.appContract?.memberList) && this._dataHelper.isEmptyObject(this._appSession.memberAddressDetails)) {
      const homeAddress: IMemberAddress = this.getMemberAddress();
      if (!isEmpty(homeAddress)) {
        this._locationAdapterSvc
          .getLocationLookup(homeAddress?.postalCd)
          .then((result: IGeoLocationInfoWithCounties) => {
            const geoLocationInfo: IGeoLocationInfoWithCounties = result;
            if (!isEmpty(geoLocationInfo.cities)) {
              this.defaultLocationParam.isUserLocationValid = true;
              this.setAppSessionAddressDetails(geoLocationInfo.cities[0], homeAddress);
            } else {
              this.defaultLocationParam.isUserLocationValid = false;
            }
            if (!isDeepLinkSearch) {
              this.setDefaultLocationSearch(init);
            } else {
              this._appSession.geolocationInfo.isUserLocationValid = true;
            }
          })
          .catch((error) => {
            this.defaultLocationParam.isUserLocationValid = false;
            this._appSession.geolocationInfo.isUserLocationValid = false;
          });
      }
    }
    if (this._dataHelper.isEmptyString(this._appSession.defaultLocation?.longitude) || this._dataHelper.isEmptyString(this._appSession.defaultLocation?.latitude)) {
      this.getCurrentLocationGeoInfo(init, isDeepLinkSearch);
    } else {
      this._appSession.geolocationInfo.isGeoCodeAllowed = true;
      this._appSession.geolocationInfo.isGeoCodeValid = true;
      this.defaultLocationParam.isGeoCodeAllowed = true;
      this.defaultLocationParam.isGeoCodeValid = true;
    }
  }

  //Method to set the member address details
  setAppSessionAddressDetails(memberAddress: IGeoLocationInfo, homeAddress: IMemberAddress): IGeoLocation {
    const _geoLocation: IGeoLocationInfo = {};
    if (!this._dataHelper.isEmptyObject(memberAddress)) {
      _geoLocation.city = homeAddress.city ?? memberAddress.city ?? '';
      _geoLocation.stateCd = memberAddress.stateCd ?? '';
      _geoLocation.zipcode = homeAddress.postalCd ?? memberAddress.zipcode ?? '';
      _geoLocation.addressLine1 = homeAddress.addressLine1 ?? memberAddress.addressLine1 ?? '';
      _geoLocation.latitude = memberAddress.latitude ?? '';
      _geoLocation.longitude = memberAddress.longitude ?? '';
    }
    this._appSession.memberAddressDetails = { ..._geoLocation };
    return _geoLocation;
  }

  //Method to set the home address details
  setHomeAddressDetails(memberAddress: IGeoLocationInfo): IGeoLocation {
    const _geoLocation: IGeoLocation = {};
    if (!this._dataHelper.isEmptyObject(memberAddress)) {
      _geoLocation.city = memberAddress.city ?? '';
      _geoLocation.stateCd = memberAddress.stateCd ?? '';
      _geoLocation.zipcode = memberAddress.zipcode ?? '';
      _geoLocation.addressLine1 = memberAddress.addressLine1 ?? '';
      _geoLocation.addressLine2 = memberAddress.addressLine2 ?? '';
      _geoLocation.latitude = memberAddress.latitude ?? '';
      _geoLocation.longitude = memberAddress.longitude ?? '';
      _geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.HOME_ADDRESS;
      _geoLocation.isHomeAddressSearch = true;
      _geoLocation.isUserLocationValid = true;
      _geoLocation.isGeoCodeSearch = false;
      _geoLocation.isOtherAddressSearch = false;
      _geoLocation.isZipStateCountySearch = false;
    }
    this.defaultLocationParam = { ...this.defaultLocationParam, ..._geoLocation };
    this._appSession.geolocationInfo = { ...this._appSession.geolocationInfo, ...this.defaultLocationParam };
    return _geoLocation;
  }

  //Method to set the geo code details
  private setGeoCodeDetails(defaultLocation: any): IGeoLocation {
    const _geoLocation: IGeoLocation = {};
    if (!this._dataHelper.isEmptyObject(defaultLocation)) {
      _geoLocation.city = defaultLocation.city ?? '';
      _geoLocation.stateCd = defaultLocation.state ?? '';
      _geoLocation.zipcode = defaultLocation.zip ?? '';

      _geoLocation.latitude = defaultLocation.latitude ?? '';
      _geoLocation.longitude = defaultLocation.longitude ?? '';
      _geoLocation.searchType = GEO_LOCATION_SEARCH_TYPE.CURRENT_LOCATION;
      _geoLocation.isGeoCodeSearch = true;
      _geoLocation.isGeoCodeValid = true;
      _geoLocation.isGeoCodeAllowed = true;
      _geoLocation.isOtherAddressSearch = false;
      _geoLocation.isHomeAddressSearch = false;
      _geoLocation.isZipStateCountySearch = false;
    }
    this.defaultLocationParam = { ...this.defaultLocationParam, ..._geoLocation };
    this._appSession.geolocationInfo = { ...this._appSession.geolocationInfo, ...this.defaultLocationParam };
    return _geoLocation;
  }

  //Method to handle summary page provider results reload on location change.
  reloadProviderSummary() {
    const _routeUrl: string = this._contentHelper.getResolvedUrl(this._route.snapshot);
    this._appSession.serviceAreaValidated = false;
    if (this._appSession.isCaHmoDmhc && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      if ((!this._appSession.searchParams.countyCode && !this._appSession.searchParams.city) || this._appSession.customAddressSearch) {
        this._savNavigationService.getZipcodeBasedCounty().then((result) => {
          if (result && isEmpty(result)) {
            this._geoLocationService.reloadSearch(true);
          } else {
            this._navigationService.navigateByUrl(AppNavigations.SERVICE_AREA_VALIDATION_PATH);
          }
        });
      } else {
        this._savNavigationService.navigate();
      }
      this._appSession.pageNumber = undefined;
      this._appSession.searchParams.selectedSortCriteria = undefined;
      this._appSession.filterSearchParams = undefined;
      this._appSession.initialSearch = true;
    } else if (this._appSession.isCaHmoDmhc && _routeUrl === AppNavigations.SERVICE_AREA_VALIDATION_PATH) {
      this._geoLocationService.reloadSAVPage(true);
    } else if (this.searchParams.zip && this._contentHelper.isSearchResultRoute(_routeUrl)) {
      this._geoLocationService.reloadSearch(true);
    } else if (this.searchParams.zip && _routeUrl === AppNavigations.SEARCH_PROVIDERS_PATH) {
      this._geoLocationService.loadResult(true);
    }
  }

  //Method to set the coordinates based on the location search type
  setCoordinates(isInit: boolean = false) {
    const coordinates = this.defaultLocationParam;
    this.searchParams.countyCode = null;
    this.searchParams.stateCd = null;
    this.searchParams.city = '';
    this.searchParams.coordinates = {
      latitude: '',
      longitude: ''
    };
    if (coordinates.isCountySearch) {
      const _countyName = coordinates.county;
      this.searchParams.countyCode = this.setSourceOrOverrideTargetValue(this.searchParams.countyCode, coordinates.countyCode);
      this.searchParams.city = this.setSourceOrOverrideTargetValue(this.searchParams.city, _countyName);
      this.searchParams.stateCd = {
        code: this.setSourceOrOverrideTargetValue(this.searchParams.stateCd?.code, coordinates.stateCd)
      };
      this.searchParams.coordinates.latitude = '0';
      this.searchParams.coordinates.longitude = '0';
      // Regional, Remote providers - Zoom map for county search.
      this.searchParams.coordinates.countyLatitude = this.setSourceOrOverrideTargetValue(this.searchParams.coordinates?.countyLatitude, coordinates.latitude);
      this.searchParams.coordinates.countyLongitude = this.setSourceOrOverrideTargetValue(this.searchParams.coordinates?.countyLongitude, coordinates.longitude);
      this.locationTxt = this.searchParams.zip = _countyName + ', ' + coordinates.stateCd;
    } else if (coordinates.isCitySearch) {
      this.searchParams.coordinates.latitude = this.setSourceOrOverrideTargetValue(this.searchParams.coordinates?.latitude, coordinates.latitude);
      this.searchParams.coordinates.longitude = this.setSourceOrOverrideTargetValue(this.searchParams.coordinates?.longitude, coordinates.longitude);
      this.searchParams.coordinates.countyLatitude = '0';
      this.searchParams.coordinates.countyLongitude = '0';
      this.searchParams.city = this.setSourceOrOverrideTargetValue(this.searchParams.city, coordinates.city);
      this.searchParams.stateCd = {
        code: this.setSourceOrOverrideTargetValue(this.searchParams.stateCd?.code, coordinates.stateCd)
      };
      this.locationTxt = this.searchParams.zip = coordinates.isCitySearch ? `${coordinates?.city}, ${coordinates.stateCd}` : '';
      this.searchParams.countyCode = null;
    } else {
      this.searchParams.coordinates.latitude = this.setSourceOrOverrideTargetValue(this.searchParams.coordinates?.latitude, coordinates.latitude);
      this.searchParams.coordinates.longitude = this.setSourceOrOverrideTargetValue(this.searchParams.coordinates?.longitude, coordinates.longitude);
      this.searchParams.coordinates.countyLatitude = '0';
      this.searchParams.coordinates.countyLongitude = '0';
      this.searchParams.stateCd = {
        code: this.setSourceOrOverrideTargetValue(this.searchParams.stateCd?.code, coordinates.stateCd)
      };
      this.searchParams.zip = this.setSourceOrOverrideTargetValue(this.searchParams.zip, coordinates.zipcode);
      this.locationTxt = this.searchParams.zip;
    }

    this._appSession.searchParams = {
      ...this._appSession.searchParams,
      ...this.searchParams
    };

    this.getPrefixMetadata(isInit);
  }

  //Method to set the source or override target value
  private setSourceOrOverrideTargetValue(source: string, target: string) {
    let finalValue = source;
    if (!this._dataHelper.isEmptyString(target)) {
      finalValue = target;
    }
    return finalValue;
  }

  private getPrefixMetadata(isInit: boolean) {
    const _routeUrl = this._routeUtil.getResolvedUrl(this._route.snapshot);
    const prefix = CommonUtil.getPrefix(this.searchParams, this._appSession);
    const stateCode = this.defaultLocationParam.stateCd ? this.defaultLocationParam.stateCd : '';
    if (
      //TODO showGACareGapNotificationMsg not updating b/c app-init-api not getting called
      // this._appSession.planRule?.showGACareGapNotificationMsg &&
      !this._dataHelper.isEmptyString(stateCode) &&
      this._dataHelper.areEqualStrings(stateCode.toLocaleUpperCase(), STATE_GA) &&
      this._dataHelper.areEqualStrings(_routeUrl, AppNavigations.FCR_LANDING_PATH)
    ) {
      this._locationService.setpinCode(true);
    } else {
      this._locationService.setpinCode(false);
      PageAlertHandler.removePageAlert([PageAlertID.PA_GA_TERMINATING_PROVIDERS_ALERT], this._appSession, FindCarePageType.Landing);
      this._infoListSvc.update();
    }

    if (!this.isSecureState && !this._dataHelper.isEmptyString(prefix) && !this._dataHelper.isEmptyString(stateCode)) {
      const _prefixMetaDataReqObj: IPrefixMetadataRequest = {} as IPrefixMetadataRequest;
      _prefixMetaDataReqObj.prefix = prefix;
      _prefixMetaDataReqObj.planStateCode = this.searchParams.plan?.stateCd ? this.searchParams.plan.stateCd : '';
      _prefixMetaDataReqObj.typeOfCareNm = this.searchParams.typeOfCareNm ? this.searchParams.typeOfCareNm : '';
      _prefixMetaDataReqObj.planCategory = CommonUtil.getPrefixPlanCategoryValue(this.searchParams) ? CommonUtil.getPrefixPlanCategoryValue(this.searchParams) : '';
      _prefixMetaDataReqObj.stateCode = stateCode;
      _prefixMetaDataReqObj.countyCode = this.searchParams.countyCode ? this.searchParams.countyCode : '';

      this._providerUtilityService.getPrefixMetadata(_prefixMetaDataReqObj).then(
        (result: IPrefixMetadataResponse) => {
          this._appSession.alphaPrefixStateCode = result?.alphaPrefixStateCode ? result?.alphaPrefixStateCode : '';
          if (this.searchParams) {
            this.searchParams.eycPrefix = result?.eycPrefix ? result?.eycPrefix : '';
            this.searchParams.isPrefixStateEnabledForProcSearch = result?.isPrefixStateEnabledForProcSearch;
            this._appSession.hasColdStateEYC = this.searchParams.isPrefixStateEnabledForProcSearch;
            this._appSession.searchParams = this.searchParams;
          }
          if (!isInit) {
            this.reloadProviderSummary();
          }
        },
        (error: any) => {
          this._appSession.alphaPrefixStateCode = '';
          if (this.searchParams) {
            this.resetPrefixMetaDataParams();
          }
          if (!isInit) {
            this.reloadProviderSummary();
          }
        }
      );
    } else {
      this._appSession.alphaPrefixStateCode = '';
      if (this.searchParams) {
        this.resetPrefixMetaDataParams();
      }
      if (!isInit) {
        this.reloadProviderSummary();
      }
    }
  }

  private resetPrefixMetaDataParams(): void {
    this.searchParams.eycPrefix = '';
    this.searchParams.isPrefixStateEnabledForProcSearch = false;
    this._appSession.hasColdStateEYC = false;
    this._appSession.searchParams = this.searchParams;
  }

  //Method to clear all the location params
  clearAll() {
    this.locationError = false;
    this.defaultLocationParam = {
      searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY,
      isGeoCodeSearch: false,
      isGeoCodeAllowed: false,
      isHomeAddressSearch: false,
      isOtherAddressSearch: false,
      isZipStateCountySearch: false,
      latitude: '',
      longitude: '',
      city: '',
      stateCd: '',
      zipcode: '',
      addressLine1: '',
      addressLine2: ''
    };
  }

  //Method to set the app session coordinates
  private setAppSessionCordinates(geoLocation: IGeoLocation) {
    if (!this._dataHelper.isEmptyObject(geoLocation)) {
      this._appSession.geolocationInfo = {
        ...this._appSession.geolocationInfo,
        ...{
          //current address details related param
          city: geoLocation.city ?? '',
          stateCd: geoLocation.stateCd ?? '',
          zipcode: geoLocation.zipcode ?? '',
          latitude: geoLocation.latitude ?? '',
          longitude: geoLocation.longitude ?? '',
          addressLine1: geoLocation.addressLine1 ?? '',
          addressLine2: geoLocation.addressLine2 ?? '',
          countyCode: geoLocation.countyCode ?? '',
          county: geoLocation.county ?? '',
          searchText: geoLocation.searchText ?? '',
          //Param for city,zip or county search
          isCountySearch: geoLocation.isCountySearch ?? false,
          isZipcodeSearch: geoLocation.isZipcodeSearch ?? false,
          isCitySearch: geoLocation.isCitySearch ?? false,
          //Param for Geo Code search check
          isGeoCodeValid: geoLocation.isGeoCodeValid ?? false,
          isGeoCodeAllowed: geoLocation.isGeoCodeAllowed ?? false,
          //Valid user details params
          isUserLocationValid: geoLocation.isUserLocationValid ?? false,
          //Location search type related Params
          isHomeAddressSearch: geoLocation.isHomeAddressSearch ?? false,
          isOtherAddressSearch: geoLocation.isOtherAddressSearch ?? false,
          isZipStateCountySearch: geoLocation.isZipStateCountySearch ?? false,
          isGeoCodeSearch: geoLocation.isGeoCodeSearch ?? false,
          searchType: geoLocation.searchType ?? GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY
        }
      };
    }
  }

  ngOnDestroy() {
    if (this.closeModelSubscription) {
      this.closeModelSubscription.unsubscribe();
    }
    if (this.geoLocationSubscription) {
      this.geoLocationSubscription.unsubscribe();
    }
    if (this.memberChangeEventSubscription) {
      this.memberChangeEventSubscription.unsubscribe();
    }
    if (this.currentLocationEventSubscription) {
      this.currentLocationEventSubscription.unsubscribe();
    }
    if (this._searchLocationSubscription) {
      this._searchLocationSubscription.unsubscribe();
    }
  }

  //Method to set red color border on error
  showRedColorBorder(data) {
    if ((!this.locationTxt || this._dataHelper.isEmptyString(this.locationTxt)) && data) {
      this.locationError = true;
    } else {
      this.locationError = false;
    }
  }

  //Method to set the location error
  setLocationError() {
    this.locationError = false;
    if (
      !(
        this.searchParams.coordinates &&
        this.searchParams.coordinates.latitude &&
        this.searchParams.coordinates.longitude &&
        this.searchParams.coordinates.latitude !== '' &&
        this.searchParams.coordinates.longitude !== ''
      )
    ) {
      this.locationError = true;
    }
  }

  private isDeepLinkSearchWithLocation() {
    return this._appSession.isLocationDeepLinkFlow && !this._dataHelper.isEmptyString(this._appSession.searchParams?.zip) && this._appSession.searchParams?.stateCd?.code;
  }

  //Method to set the deeplink parameters
  private setDeeplinkParameters(): void {
    const _deeplinkParams = this._appSession.deeplinkParams;
    this.defaultLocationParam = {
      ...this.defaultLocationParam,
      searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY,
      isHomeAddressSearch: false,
      isGeoCodeSearch: false,
      isOtherAddressSearch: false,
      isZipStateCountySearch: true,
      latitude: this.searchParams?.coordinates?.latitude?.toString() ?? '',
      longitude: this.searchParams?.coordinates?.longitude?.toString() ?? '',
      stateCd: this.searchParams?.stateCd?.code ?? ''
    };
    this.resetCoordinateType();
    if (_deeplinkParams.county && _deeplinkParams.state) {
      this.defaultLocationParam.isCountySearch = true;
      this.defaultLocationParam.countyCode = this.searchParams.countyCode;
      this.defaultLocationParam.searchText = `${_deeplinkParams.county}, ${_deeplinkParams.state}`;
      this.defaultLocationParam.county = _deeplinkParams.county;
    } else if (_deeplinkParams.city && _deeplinkParams.state) {
      this.defaultLocationParam.isCitySearch = true;
      this.defaultLocationParam.city = _deeplinkParams.city;
      this.defaultLocationParam.searchText = `${_deeplinkParams.city}, ${_deeplinkParams.state}`;
    } else if (_deeplinkParams.zipcode) {
      this.defaultLocationParam.isZipcodeSearch = true;
      this.defaultLocationParam.zipcode = this.searchParams.zip;
      this.defaultLocationParam.searchText = this.searchParams.zip;
    }
    this._appSession.selectLocationForm = {
      searchType: GEO_LOCATION_SEARCH_TYPE.ZIP_CITY_COUNTY,
      zipOrCityOrCounty: {
        zipCityCounty: this.defaultLocationParam.searchText
      }
    };
    this._appSession.geolocationInfo = { ...this._appSession.geolocationInfo, ...this.defaultLocationParam };
    this.setCoordinates(true);
  }

  //Method to reset the coordinate type
  resetCoordinateType() {
    this.defaultLocationParam.isCountySearch = false;
    this.defaultLocationParam.isCitySearch = false;
    this.defaultLocationParam.isZipcodeSearch = false;
  }

  //Method to get the current geo position
  async getCurrentLocationGeoInfo(init: boolean = false, isDeepLinkSearch?: boolean): Promise<void> {
    try {
      const position: GeolocationPosition = await this.getCurrentGeoPosition();
      this._appLoader = true;
      this.defaultLocationParam.isGeoCodeAllowed = true;
      this._appSession.geolocationInfo.isGeoCodeAllowed = true;
      const geoLocationInfo: IGeoLocationInfo = await this._locationAdapterSvc.getLocationByGeoCode(position?.coords?.latitude?.toString(), position?.coords?.longitude?.toString());

      if (geoLocationInfo && Object.keys(geoLocationInfo).length > 0 && !this._dataHelper.isEmptyString(geoLocationInfo.zipcode)) {
        this._appSession.defaultLocation = {
          ...this._appSession.defaultLocation,
          zip: geoLocationInfo.zipcode ?? '',
          state: geoLocationInfo.stateCd ?? '',
          city: geoLocationInfo.city ?? '',
          latitude: geoLocationInfo.latitude ?? '',
          longitude: geoLocationInfo.longitude ?? ''
        };
        this.defaultLocationParam.isGeoCodeValid = true;
        this._appSession.geolocationInfo.isGeoCodeValid = true;
        if (!isDeepLinkSearch) {
          this.setDefaultLocationSearch(init);
        }
      } else {
        this.defaultLocationParam.isGeoCodeValid = false;
        this._appSession.geolocationInfo.isGeoCodeValid = false;
        this._appSession.geolocationInfo.isGeoCodeAllowed = true;
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        this._appSession.geolocationInfo.isGeoCodeAllowed = true;
        this.defaultLocationParam.isGeoCodeAllowed = true;
      }
      this.defaultLocationParam.isGeoCodeValid = false;
      this._appSession.geolocationInfo.isGeoCodeValid = false;
    } finally {
      this._appLoader = false;
    }
  }

  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      this.openSwitchLocationModal();
    }
  }
}
