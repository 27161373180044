import { FindCareFeature } from '../../findcare/common/enums/findCareFeature';

export const KILL_SWITCH_TO_FEATURE_MAP = {
  addCareTeam: FindCareFeature.FC_CARE_TEAM_ADD,
  apiInterceptionEvent: FindCareFeature.FC_EVENT_INTERCEPTION,
  apptDisabledProvs: FindCareFeature.FC_PROVIDER_APPT_VALIDATE,
  basicLookup: FindCareFeature.FC_LOOKUP_BASIC,
  benefitVisits: FindCareFeature.FC_CS90_BENEFIT_VISIT,
  chineseLink: FindCareFeature.FC_CHN_LOCALE,
  costTransparency: FindCareFeature.FC_SEARCH_CPT,
  costTransparencyOutOfNetworkRN: FindCareFeature.FC_SEARCH_CPT_OON_COST,
  emailPDF: FindCareFeature.FC_EXPORT_EMAIL,
  enableAssignedAsPCPBadge: FindCareFeature.FC_CARE_CIRCLE_PCP_BADGE,
  enableBingLocationFlow: FindCareFeature.FC_LOCATION_BING_VALIDATE,
  enableLiveChatSupport: FindCareFeature.FC_LIVE_CHAT_SUPPORT,
  enableMbrApiForContracts: FindCareFeature.FC_MBR_ELIG_RULE_MGN,
  enableMultiCoverageSelection: FindCareFeature.FC_MBR_MULTICONTRACT,
  enablePfBBBNavigationFlow: FindCareFeature.FC_BROWSER_BACK_BUTTON,
  enableSearchThisArea: FindCareFeature.FC_SEARCH_FROM_MAP,
  findCareFts: FindCareFeature.FC_FTS,
  findCareMaintenance: FindCareFeature.FC_LIVE,
  ftsProcedureLookup: FindCareFeature.FC_FTS_DS_PROC_LKUP,
  googleReCaptcha: FindCareFeature.FC_GOOGLE_RECAPTCHA,
  impressionEvent: FindCareFeature.FC_EVENT_IMPRESSION,
  improveSearchCriteria: FindCareFeature.FC_SEARCH_IMPROVE,
  kafkaMessaging: FindCareFeature.FC_EVENT_PUBLISH,
  lookupHasOonProviders: FindCareFeature.FC_LOOKUP_OON_PROVIDER,
  memberPreCaching: FindCareFeature.FC_PRECACHE,
  molsFadToPrecareLocationApi: FindCareFeature.FC_MOLS_LOCATION_MGN,
  pcpForAll: FindCareFeature.FC_CARE_CIRCLE_PCP_ALL,
  printPDF: FindCareFeature.FC_EXPORT_PDF,
  procedureSearch: FindCareFeature.FC_SEARCH_TCC,
  providerRatings: FindCareFeature.FC_PROVIDER_RATING,
  showBDCEYCFilter: FindCareFeature.FC_FILTER_BDC_EYC,
  showOnlyOONProvidersLink: FindCareFeature.FC_SEARCH_OON,
  specialtiesAtProviderAddressNetworkLevel: FindCareFeature.FC_SEARCH_NTWK_SPCLTY,
  travelDuration: FindCareFeature.FC_TRAVEL_DURATION,
  userInteractionEvent: FindCareFeature.FC_EVENT_INTERACTION,
  vcard: FindCareFeature.FC_PROVIDER_VCARD
};
