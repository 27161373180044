import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ProviderCard, ProviderCardNavigation } from '../../../constants/result';
import { IProvider } from '../../../interfaces/iProvider';
import { IAffiliationProvider } from '../../../interfaces/iProviderAffiliation';
import { IDetail } from '../../../interfaces/iProviderDetail';
import { ProviderDetail } from '../../../services/providerDetail';
import { BaseComponent } from './../../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-affiliation-card-cmp',
  templateUrl: './affiliationCardCmp.html'
})
export class AffiliationCardComponent extends BaseComponent {
  @Output() cardSelected: EventEmitter<ProviderCard> = new EventEmitter<ProviderCard>();
  @Input() affiliations: Map<string, IAffiliationProvider[]>;
  affiliationContent = this.content?.result?.affiliationCard;
  @Output() cardNavigation: EventEmitter<ProviderCardNavigation> = new EventEmitter<ProviderCardNavigation>();
  @Input() providerDetails: IDetail;
  @Output() closeProviderCard: EventEmitter<boolean> = new EventEmitter();
  @Input() showSearchResultsFilter: boolean;
  cardProvider: IProvider;
  @Output() showLoader: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _providerDetail: ProviderDetail
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to Close Speciality Card.
   * Hiding Speciality Card and Provider Details Card.
   */
  onCloseCard() {
    this.closeProviderCard.emit(true);
  }

  /**
   * Method to show Previous Card State in Provider Tab.
   * Hiding Affiliations List and Showing Provider Card.
   */
  onShowPreviousState(): void {
    this.cardSelected.emit(ProviderCard.OVERVIEW);
  }

  /**
   * Method to show Affiliate Card  in Provider Tab.
   * Showing Provider Card based on provider type.
   */
  onShowAffiliatedProviderCard(provider: IAffiliationProvider): void {
    this.cardSelected.emit(ProviderCard.OVERVIEW);
    this.showLoader.emit(true);
    this.cardNavigation.emit(ProviderCardNavigation.AFFILIATION);
    this._providerDetail.initialize(provider.providerIdentifier, provider.addressSummary.addressIdentifier, this.cardProvider);
  }
}
