import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { GlobalHeaderModule } from '../common/components/shareable/global-header/globalHeaderModule';
import { LoaderModule } from '../common/components/shareable/loader/loaderModule';
import { GenericModalModule } from '../common/components/shareable/modal/genericModalModule';
import { GeoLocationModule } from './../../common/components/geoLocation/geoLocationModule';
import { CommonDirectivesModule } from './../../common/directives/commonDirectivesModule';
import { CareNowComponent } from './components/careNowCmp';

@NgModule({
  declarations: [CareNowComponent],
  exports: [CareNowComponent],
  imports: [CommonModule, UxSharedModule.forRoot(), FormsModule, CommonDirectivesModule, GeoLocationModule, GlobalHeaderModule, GenericModalModule, LoaderModule],
  providers: []
})
export class CareNowModule {}
