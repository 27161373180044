import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { IUrlParam } from '../../../common/interfaces/iUrlParam';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ICareCircle } from '../interfaces/iCareCircle';
import { ICareProviderRequest, ICareTeamEmailRequest } from '../interfaces/iCareProvider';

/**
 * Care Circle service will return Care Circle providers.
 */
@Injectable({ providedIn: 'root' })
export class CareCircleService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  updateCareTeam(reqObj): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.careTeamUpdate,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  addCareTeam(reqObj): Promise<any> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.careTeamAdd,
      data: reqObj,
      headers: this.getHeaders()
    });
  }

  getCareCircle(urlParams: ICareProviderRequest): Promise<ICareCircle> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.careCircle,
      urlParams: [
        {
          name: 'mbrUid',
          value: urlParams.mbrUid,
          isQueryParam: true
        },
        {
          name: 'contractUid',
          value: urlParams.contractUid,
          isQueryParam: true
        },
        {
          name: 'locale',
          value: urlParams.locale,
          isQueryParam: true
        },
        {
          name: 'brandCd',
          value: urlParams.brandCd,
          isQueryParam: true
        },
        {
          name: 'isFuturePlan',
          value: Boolean(urlParams.isFuturePlan).toString(),
          isQueryParam: true
        },
        {
          name: 'type',
          value: urlParams.type,
          isQueryParam: true
        }
      ]
    });
  }

  /**
   * To get the timezone from the client
   * example return as "America/New_York"
   */
  private getTimeZone(): string {
    return moment.tz.guess();
  }

  sendCareTeamEmail(careteamReq: ICareTeamEmailRequest): Promise<any> {
    const header = this.getHeaders();
    const urlParams: IUrlParam[] = [{ name: 'tz', value: this.getTimeZone(), isQueryParam: true }];
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: `${this.baseURL + apiNameList.restApi.careTeamEmail}`,
      data: careteamReq,
      headers: header,
      urlParams
    });
  }
}
