// Define the English content for the landing page
export const LANDING_CONTENT_EN = {
  title: 'Landing',
  quickLinks: {
    providerLabel: 'Search by Care Provider',
    procedureLabel: 'Search by Procedure',
    secureToolTip: 'Members with similar health histories have shown interest in some of these care providers.',
    coldToolTip: 'Our data tells us that members with similar health histories as you have sought care from some of these providers.',
    procedureTooltip: 'Members with similar health histories have shown interest in some of these procedures.',
    selectforMoreInfo: 'Select for more information about ',
    ariaLabelForprovSearch: 'select for {provider_name} based provider search',
    ariaLabelForprocedSearch: 'select for {provider_name} based procedure search',
    viewMoreLabel: 'View More',
    viewMoreAriaLabel: 'viewMoreProceduresLinkFindCare'
  },
  moreOptions: {
    moreOptionsLabel: 'More Options',
    labels: {
      ltss: 'Long-Term Services and Supports (LTSS)',
      ltssCard: 'Find care providers who offer long-term services and support to assist with routine daily activities.',
      ksMedDentalCard: 'Go to this website to find a Healthy Blue Kansas Medicaid dentist.',
      ksMedVisionCard: 'Go to EyeMed to search for a Healthy Blue Kansas Medicaid eye doctor.',
      ksMedDental: 'Find a Dentist',
      ksMedVision: 'Find an Eye Doctor',
      urgentCareCardLabel: 'Urgent Care',
      urgentCareCardText: 'Consider using these options when you need health care quickly, but can’t visit your usual doctor.',
      urgentCareCardAriaLabel: 'Consider using these options when you need health care quickly, but can’t visit your usual doctor.',
      virtualCareLabel: 'Virtual Care',
      virtualCareCardText: 'Visit with a doctor 24/7 for hundreds of health conditions.',
      visionPlus: 'Vision PLUS',
      getCareNow: 'Get Care Now',
      getCareNowCard: 'View your options when you need care quickly.',
      visionPlusCard: 'Vision PLUS providers may maximize your benefits with extra coverage to help you save even more.',
      otherServicesProviderCardLabel: 'Other Services Providers',
      otherServicesProviderCardText: 'These health care professionals and facilities provide housing supports and other services.',
      dentaQuestCardLabel: 'Find a Dentist',
      dentaQuestCardText: 'Go to DentaQuest to find a dentist in the Anthem BCBS Ohio Medicaid Network.',
      libertyDentalCardLabel: 'Find a Dentist',
      libertyDentalCardText: 'Go to Liberty Dental to find a dentist in the Anthem BCBS Ohio Medicaid Network.',
      eyeMedCardLabel: 'Find an Eye Doctor',
      eyeMedCardText: 'Go to EyeMed to find an eye doctor in the Anthem BCBS Ohio Medicaid Network.',
      dentalDiscount: 'Dental Discount Card Program Providers',
      dentalDiscountCard: 'These dental providers may charge you less than their normal fee for services not reimbursed by the SEHP Dental Plan.'
    }
  },
  lastUpdated: {
    medicareContent: {
      lastUpdated: 'Last Updated',
      lastUpdatedText: 'Updates received from our network providers are added weekly.',
      lastUpdatedDate: '04/10/2019',
      medicareCompliance: 'The dates below are used for Medicare compliance and do not apply to provider data updates.',
      cmsApproved: 'Y0114_20_122379_U CMS Accepted 05/08/2020',
      lastUpdatedDateMedicare: ' 05/08/2020'
    }
  },
  machineReadable: {
    linkText: 'Machine-Readable Provider Directory',
    ariaLabel: 'Click here to navigate to machine-readable provider directory page',
    brand: {
      amv: { url: 'https://www.amerigroup.com/microsites/cms/index.html' },
      hbncm: { url: 'https://www.healthybluenc.com/microsites/cms/index.html' },
      healthblue: { url: 'https://www.healthybluemo.com/microsites/cms/index.html' },
      txdcm: { url: 'https://findcare.mydellchildrens.com/microsites/cms/index.html' },
      abcbs: { url: 'https://www22.anthem.com/cms-data-index.json/index.html' },
      ahkp: { url: 'https://www22.anthem.com/cms-data-index.json/index.html' },
      kymbs: { url: 'https://www22.anthem.com/cms-data-index.json/index.html' },
      bcbswn: { url: 'https://www.mybcbswny.com/microsites/cms/index.html' },
      shc: { url: 'https://www.simplyhealthcareplans.com/microsites/cms/index.html' },
      scc: { url: 'https://www.summitcommunitycare.com/microsites/cms/index.html' },
      cha: { url: 'https://www.clearhealthalliance.com/microsites/cms/index.html' },
      wlp: { url: 'https://www.wellpoint.com/microsites/cms/index.html' },
      abc: { url: 'https://www.anthem.com/ca/machine-readable-file/provider.html' }
    }
  },
  memberSelectionModal: {
    memberDesc1: 'Coverage and network options may vary by family member. ',
    memberDesc2: 'Choose one to personalize your search results based on their specific plan.',
    memberHeading: 'Search by Family Member'
  },
  careTeams: {
    closeProviderModal: 'select button to close the remove provider modal',
    clickToCloseModal: 'click here to close the modal',
    careTeam: 'Care Team',
    providerName: 'Provider Name',
    goToCareTeam: 'Go to Care Team',
    pcp: 'ASSIGNED AS PCP',
    call: 'Call',
    virtualCareOnlyLabel: 'VIRTUAL ONLY',
    addPrimaryCarePhysician: 'Add a Primary Care Physician to Your Care Team',
    importantPCP: "Why it's important to have a PCP?",
    searchForPCP: 'Search for a PCP',
    addSomeOneToCareTeam: 'Add Someone to Your Care Team',
    TP_AddCareTeams: "It's easy to add someone to your care team. First, use the search box to find your care provider by name. Then, select that care provider and use the Add to Care Team button.",
    closeModal: ' button, select to close the modal',
    whyImportantPCP: 'Why is it important to have a PCP?',
    whyImportantPCPContentFirstLine: 'You’re in charge of your health. But you need a go-to expert — so choose a PCP.',
    whyImportantPCPContent:
      'Choosing a primary care physician (PCP) is an important personal decision. You don’t want to just choose a random name from a quick web search. Finding someone you can relate to, feel comfortable with and trust with private matters is a big deal. And when you find the right PCP, that doctor can help make a difference in your health',
    pcpImpLabel: '<b>Why a PCP is important</b>',
    pcpBulletPoint: [
      '<b>Your PCP gets to know the “overall you,” not just bits and pieces.</b> Your doctor will have a fuller picture, with your health history and habits, and can more easily see changes and recommend action to avoid more serious problems later.',
      '<b>You have a starting point.</b> If you’re not sure who to see for a certain problem, if you have a question about different prescriptions, or if you’re worried about what a specialist has suggested, talk to your PCP.',
      '<b>You may be able to save time and money.</b> A PCP helps you stay healthy and can help you take care of ongoing health problems. This can mean lower health care costs, fewer sick days and better access to specialized care when you do need it.'
    ],
    pcpStartDateLabel: 'PCP starting',
    whyImporatantPCPLink: 'Search for a PCP now',
    removeCareProvider: 'Remove From Care Team?',
    cancel: 'Cancel',
    remove: 'Remove',
    actionItemsLabel: { removeFromCareTeam: 'Remove From Care Team', changePCP: 'Change PCP', memberSvcChangePCP: 'Change PCP (Member Services)', bookAppointment: 'Book Onsite Appointment' },
    removeCareProviderConfirm: 'Are you sure you want to remove <strong>{NAME}</strong> from the Care Team of <strong>{MEMBER}</strong>? (You always can add again if you change your mind.)',
    removeCareTeamSuccessMsg: "You have successfully removed <strong class='text-uppercase'>{PROVIDER}</strong> from the Care Team of <strong>{MEMBER}</strong>.",
    removeCareTeamFailureMsg:
      "There was a problem and we weren't able to remove <strong class='text-uppercase'>{PROVIDER}</strong> from the Care Team of <strong>{MEMBER}</strong>. Please try again later.",
    apiError: "Sorry, looks like something isn't working. Please give us some time to fix it. Then, try again.",
    close: 'Close'
  }
};
//  Define a type based on the structure of the English content
export type LadingContent = typeof LANDING_CONTENT_EN;
