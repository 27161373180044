import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, EventEmitter, Inject, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertHelper } from '@anthem/uxd/alert';
import { ISAMLParam } from '../../../../../common/components/sso/interfaces/iSamlParam';
import { ISSOEvent } from '../../../../../common/components/sso/interfaces/iSsoEvent';
import { SSOApi } from '../../../../../common/components/sso/services/ssoApi';
import { SSO_SUBTYPE } from '../../../../../common/constants/app-constants';
import { AppEvents } from '../../../../../common/enums/appEvents';
import { SSOErrorType } from '../../../../../common/enums/ssoErrorType';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ContentHelper } from '../../../../../common/values/contentHelper';
import { RedirectUrlResponse } from '../../../../../fad/search-providers/interfaces/magicLinkVpcItemTypes';
import { VirtualCareSSOUrlSvc } from '../../../../../fad/search-providers/services/virtualCareSSOURLSvc';
import { VpcMagicLinkSvc } from '../../../../../fad/search-providers/services/vpcMagicLinkSvc';
import { CommonUtil } from '../../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: '[app-fc-sso-form-cmp]',
  templateUrl: './ssoFormCmp.html'
})
export class SSOFormComponent extends BaseComponent implements OnDestroy {
  ssoBrand: string;
  ssoBrandSite: string;
  actionUrl: string;
  samlAssertion: string;
  samlParams: Array<ISAMLParam> = [];
  formTarget: string = '_blank';
  ssoWindow: any = null;
  disclaimerEnabled: boolean = false;
  disclaimerContent: string;
  ssoEvent: ISSOEvent;
  isInstamed: boolean = false;
  externalPageId: string = '';
  externalPageUrl: string = '';
  showExternalModal: boolean = false;

  private _ssoEvent: EventEmitter<ISSOEvent> = this._eventHandler.get(AppEvents[AppEvents.SSO]);
  private _callSaml: EventEmitter<void> = this._eventHandler.get(AppEvents[AppEvents.CALL_SAML]);
  @ViewChild('ssoSubmitBtn')
  private submitBtn: ElementRef;
  private _subscription1: any;
  private _subscriptionSaml: any;

  constructor(
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _ssoApi: SSOApi,
    @Inject('Window')
    private _window: IWindow,
    private _alertHlpr: AlertHelper,
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _doc: any,
    private _route: ActivatedRoute,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _vpcMagicLinkService: VpcMagicLinkSvc,
    private _virtualCareUrlSSOService: VirtualCareSSOUrlSvc,
    private _dataHlpr: DataHelper
  ) {
    super(_route, _eventHandler, _appSession);

    this._subscription1 = this._ssoEvent.subscribe((event: ISSOEvent) => {
      this.initializeSSOEvent(event);
    });
    this._subscriptionSaml = this._callSaml.subscribe(() => {
      this.getSAMLData(this.ssoEvent);
    });
  }

  ngOnDestroy() {
    this._subscription1?.unsubscribe();
    this._subscriptionSaml?.unsubscribe();
  }

  private initializeSSOEvent(event: ISSOEvent) {
    this.ssoEvent = event;
    this.actionUrl = '';
    this.samlAssertion = '';
    this.samlParams = [];
    this.formTarget = '_blank';
    this.ssoWindow = null;
    this.ssoBrandSite = '';
    this.ssoBrand = '';
    this.showExternalModal = false;

    if (this._appSession.metaData.brandCd) {
      this.ssoBrandSite = this.getSSOBrand();
      this.ssoBrand = this.ssoBrandSite.split('.')[0];
    }

    if (event.isDirectLink) {
      this.actionUrl = event.directLink;
      this.handleSsoSuccess(event, {
        vendorUrl: event.directLink,
        samlassertion: '',
        additionalParams: event.additionalParams
      });
    } else {
      this.getSAMLData(event);
    }
  }

  getSSOBrand() {
    const brandMap: { [key: string]: string } = {
      abc: 'Anthem.com/ca',
      bcbsga: 'bcbsga.com',
      ebc: 'Empireblue.com',
      ebcbs: 'Empireblue.com'
    };
    return brandMap[this._appSession.metaData.brandCd.toLowerCase()] || 'Anthem.com';
  }

  private getSAMLData(event: ISSOEvent) {
    if (!event.type && !event.subType) {
      return;
    }

    this.formTarget = event.target === '_self' ? 'iframeself' : event.isOCPBridge ? '_self' : event.type + event.subType;

    const windowParams = event.isPcp || event.newWindow ? 'scrollbars=1,width=1140,height=800,toolbar=0,resizable=1' : '';
    const winArgs = [this.baseSSOURL + '/ssoLoader.html', this.formTarget, windowParams].filter(Boolean);

    if (!event.isOCPBridge) {
      this.ssoWindow = this._window.open.apply(this._window, winArgs);
    }

    if (this._dataHlpr.areEqualStrings(event.subType, SSO_SUBTYPE.VIRTUAL_VISIT)) {
      this.handleVirtualVisit(event);
    } else if (this._dataHlpr.areEqualStrings(event.subType, SSO_SUBTYPE.VIRTUAL_CARE_SSO_URL)) {
      this.handleVirtualCareSSOURL(event);
    } else {
      this.handleOtherSSO(event);
    }
  }

  private handleVirtualVisit(event: ISSOEvent) {
    this._appSession.isKHealthModalOpen = true;
    CommonUtil.redirectKHealth(event.careType, this._appSession, this._vpcMagicLinkService, this._window).then(
      (result: RedirectUrlResponse) => this.handleSsoSuccess(event, result),
      (error: any) => this.handleError(event, error)
    );
  }

  private handleVirtualCareSSOURL(event: ISSOEvent) {
    CommonUtil.virtualCareSSOURL(this._appSession, this._virtualCareUrlSSOService, event.ssoKey).then(
      (result: RedirectUrlResponse) => this.handleSsoSuccess(event, result),
      (error: any) => this.handleError(event, error)
    );
  }

  private handleOtherSSO(event: ISSOEvent) {
    this._ssoApi.getSSOData(event.pdtStrKey).then(
      (result: unknown) => this.handleSsoSuccess(event, result),
      (error: any) => this.handleError(event, error)
    );
  }

  private handleSsoSuccess(event: ISSOEvent, data: any) {
    this.handleNormalSsoLink(event, data);
    this.processFormSubmit();
  }

  private handleNormalSsoLink(event: ISSOEvent, data: any) {
    this.actionUrl = data.vendorUrl;
    this.samlAssertion = data.samlAssertion;
    this.samlParams = this.getSAMLParams(data.additionalParams);
  }

  private processFormSubmit() {
    setTimeout(
      () => {
        if (this.ssoWindow) {
          const form = (this._window.document.forms as any)['pfssoForm'];
          const samlHTML = form.outerHTML || (form.length > 0 && form.item(0).outerHTML) || '';
          this.ssoWindow.document.write(samlHTML + '<script nonce="MemberPortal">window.document.forms[0].submit();</script>');
        } else {
          (this._window.document.forms as any)['pfssoForm']?.submit();
        }
      },
      this._window.isIE ? 2000 : 100
    );
  }

  private getSAMLParams(params: any = {}): Array<ISAMLParam> {
    return Object.keys(params).map((p) => ({
      name: p,
      value: this.isInstamed && params[p].toString().includes('&#47;') ? params[p].toString().replace(/&#47;/g, '/') : params[p]
    }));
  }

  private showError(event: ISSOEvent) {
    if (event.errorDefinition.type === SSOErrorType.OUTSIDE && event.errorDefinition.errorElement) {
      event.errorDefinition.errorElement.innerHTML = this.getErrorMessage(event);
      const errorIcon = event.errorDefinition.errorElement.parentElement.parentElement.childNodes[0].childNodes[0];
      this._renderer.removeClass(errorIcon, 'fa-info');
      this._renderer.addClass(errorIcon, 'fa-exclamation');
      this._renderer.addClass(event.errorDefinition.errorElement.parentElement.parentElement.parentElement, 'ant-negative');
      this._renderer.removeClass(event.errorDefinition.errorElement.parentElement.parentElement.parentElement.parentElement, 'hidden');
    } else {
      this._alertHlpr.openAlert(this.getErrorMessage(event), 'negative', 10000);
    }
  }

  private getErrorMessage(event: ISSOEvent): string {
    return event.errorDefinition.errorMessage || 'We\'re having trouble redirecting you to external vendor, Please try again or <a href="contact-us">contact us</a>.';
  }

  private handleError(event: ISSOEvent, error: any) {
    if (this.ssoWindow) {
      this.ssoWindow.close();
    }
    this.showError(event);
    throw error;
  }
}
