import { IProductName } from '../../common/interfaces/iAppMetadata';
import { AppSession } from '../../common/values/appSession';
import { IOutputFlags } from '../provider-details/models/iDetailsParameters';
import { ISearchParameters } from '../search-providers/interfaces/iSearchParameters';
import {
  BLUEPRECISION_TYPE_BOTH,
  COVERAGE_TYPE,
  DENTAL_ROUTINE_COVERAGETYPE,
  MEDICAL_COVERAGETYPE,
  PHARMACY_ROUTINE_COVERAGETYPE,
  PROVIDER_TYPE,
  VISION_ROUTINE_COVERAGETYPE
} from '../search-providers/values/providerSearchConstants';
import { EycProcedureCriteria, IBlueDistinctCode, IPlan, IRecognition, ISearchRequest, SearchCriteria } from '../search-results/interfaces/iSearchRequest';
import { providerOutputFlags } from '../search-results/values/providerSearchConstants';
import { IBaseCode } from './../../common/interfaces/iBaseCode';
import { CommonUtil } from './commonUtil';

export class ResultsUtil {
  private static adobeTags = {
    discResults: 'showDisclaimerProviderResults',
    discDetails: 'showDisclaimerProviderDetails',
    discEycResults: 'showDisclaimerEycProviderResults',
    discEycDetails: 'showDisclaimerEycProviderDetails'
  };
  static buildEycRequest(_appSession: AppSession, pageNumber: any, pageSize: any) {
    let criteria = new SearchCriteria();
    let searchRequest: ISearchRequest;
    let sortOption = '';
    let eycProcedureCriteria = new EycProcedureCriteria();
    if (_appSession && _appSession.searchParams) {
      const eycProcProviderType = _appSession.searchParams.typeSelectNm;
      criteria = this.setCommonSearchCriteria(_appSession);
      criteria.isInitialSearch = _appSession.initialSearch;
      criteria.blueDistinction = criteria.blueDistinction ? criteria.blueDistinction : 'false';
      //when hasEYC is true and default sort is null or sort option has personalized match
      if (_appSession.hasEYC && (_appSession.initialSearch === true || _appSession.searchParams.selectedSortCriteria === null || _appSession.searchParams.hasPersonalizedMatch)) {
        criteria.applyPersonalizedMatch = true;
      } else {
        criteria.applyPersonalizedMatch = false;
      }
      sortOption = _appSession.searchParams.selectedSortCriteria;
      eycProcedureCriteria = Object.assign({}, _appSession?.searchParams?.eycSearchParam?.eycProcedureCriteria);
      eycProcedureCriteria.procedureName = '';
      criteria.returnTypeofProvider = CommonUtil.getProcedureProviderReturnType(_appSession.searchParams.eycSearchParam?.eycProcedureCriteria?.providerCategoryCode);

      if (_appSession.searchParams.blueDistinctionType && _appSession.searchParams.blueDistinctionTypePlus) {
        criteria.blueDistinctionType = BLUEPRECISION_TYPE_BOTH;
      } else if (_appSession.searchParams.blueDistinctionType) {
        criteria.blueDistinctionType = _appSession.searchParams.blueDistinctionType;
      } else if (_appSession.searchParams.blueDistinctionTypePlus) {
        criteria.blueDistinctionType = _appSession.searchParams.blueDistinctionTypePlus;
      }
      if (_appSession.searchParams.blueDistinctionType || _appSession.searchParams.blueDistinctionTypePlus) {
        criteria.blueDistinctionList = [];
      }
      if (_appSession.searchParams.ethnicityList && !_appSession.searchParams.ethnicityList.includes('00')) {
        criteria.ethnicityList = _appSession.searchParams.ethnicityList;
      }
      if (_appSession.searchParams.acceptingNewPatients) {
        criteria.acceptingNewPatients = _appSession.searchParams.acceptingNewPatients.toString();
      }
      if (_appSession.searchParams.medicaidInd) {
        criteria.medicaid = _appSession.searchParams.medicaidInd.toString();
      }
      if (_appSession.searchParams.boardCertification) {
        criteria.boardCertification = _appSession.searchParams.boardCertification.toString();
      }
      criteria.memberSeqNum = _appSession.searchParams.memberSeqNum;

      // mbrUid and contractUid is needed in request to pull loggedin or selected member info from cache.
      if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.mbrUid) {
        criteria.mbrUid = _appSession.metaData.appContract.mbrUid;
      }

      if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.contractUid) {
        criteria.contractUid = _appSession.metaData.appContract.contractUid;
      }
      criteria.taxonomyList = _appSession.searchParams.taxonomySelectNm;
      if (_appSession.searchParams.languages && !_appSession.searchParams.languages.includes('00')) {
        criteria.languages = _appSession.searchParams.languages;
      }
      if (_appSession.hasColdStateEYC) {
        if (_appSession.searchParams.eycPrefix) {
          criteria.eycPrefix = _appSession.searchParams.eycPrefix;
        }
        if (_appSession.searchParams.isPrefixStateEnabledForProcSearch) {
          criteria.isPrefixStateEnabledForProcSearch = _appSession.searchParams.isPrefixStateEnabledForProcSearch;
        }
        criteria.alphaPrefix = CommonUtil.getAlphaPrefix(_appSession);
      }
    }
    searchRequest = {
      orchestrationRequest: true,
      pageNumber,
      pageSize,
      searchCriteria: criteria,
      sortOption,
      eycProcedureCriteria,
      outputFlags: this.checkOutputFlags(_appSession)
    };
    return searchRequest;
  }

  static getSpecialSpecialties(_appSession: AppSession, specialtyList: string[]) {
    let _specialtyList = specialtyList;

    if (specialtyList && specialtyList.length === 0) {
      const _provType = _appSession.searchParams.providerTypeCodeList && _appSession.searchParams.providerTypeCodeList.length === 1 ? _appSession.searchParams.providerTypeCodeList[0] : '';
      if (_provType === PROVIDER_TYPE.HEARING) {
        _specialtyList = this.getSpecialtyCodeList(_appSession, specialtyList);
      }
    }

    return _specialtyList;
  }

  static getSpecialtyCodeList(_appSession: AppSession, specialtyList: string[]) {
    if (_appSession.searchParams.speicalityDdlValues && _appSession.searchParams.speicalityDdlValues.options) {
      specialtyList = _appSession.searchParams.speicalityDdlValues.options.map((x) => x.value);
      specialtyList = specialtyList && specialtyList.length > 0 ? specialtyList.filter((x) => x !== '00') : [];
    }
    return specialtyList;
  }

  static buildProviderRequest(_appSession: AppSession, pageNumber: any, pageSize: any, affiliationOption?: any) {
    let criteria = new SearchCriteria();
    let searchRequest: ISearchRequest;
    let sortOption = '';
    if (_appSession && _appSession.searchParams) {
      criteria = this.setCommonSearchCriteria(_appSession);
      const searchParams = _appSession.searchParams;
      if (searchParams.provNm || _appSession.isMedicalAffSearch || _appSession.isHospAffSearch) {
        criteria.providerName = _appSession.isMedicalAffSearch ? _appSession.medicalGrpSearchTerm : _appSession.isHospAffSearch ? _appSession.hospSearchTerm : searchParams.provNm;
      } else {
        if (
          !_appSession.isSpecialitySearch &&
          !searchParams.nationalProviderIdentifier &&
          !_appSession.isEyc &&
          !_appSession.isAoeSearch &&
          !_appSession.isOfcSrvcSearch &&
          !_appSession.isSearchTerm
        ) {
          criteria.providerName = _appSession.searchTerm;
        }
      }
      if (_appSession.isChangePCP) {
        criteria.isChangePCP = _appSession.isChangePCP;
      }

      criteria.specialtyCategoryList = searchParams.specialtySelectNm && searchParams.specialtySelectNm.length > 0 ? searchParams.specialtySelectNm.filter((x) => x !== '00') : [];
      criteria.specialtyCategoryList = this.getSpecialSpecialties(_appSession, criteria.specialtyCategoryList);
      if (_appSession.searchParams.typeSelectNm === PROVIDER_TYPE.HEARING) {
        criteria.taxonomyList = CommonUtil.getSubSpecialtyList(_appSession.searchParams.subSpeicalityDdlValues, _appSession.searchParams.specialtySelectNm);
      } else if (searchParams.taxonomySelectNm) {
        criteria.taxonomyList = searchParams.taxonomySelectNm;
      }

      if (Array.isArray(searchParams.levelOfCareList)) {
        criteria.levelOfCareList = [];
        criteria.levelOfCareList = searchParams.levelOfCareList;
      }
      if (Array.isArray(searchParams.languages)) {
        criteria.languages = [];
        criteria.languages = searchParams.languages;
      }

      criteria.areaOfExpertiseList = searchParams.aoeSelectNm && searchParams.aoeSelectNm.length > 0 ? searchParams.aoeSelectNm.filter((x) => x !== '00') : null;

      criteria.ofcSrvcList = searchParams.ofcSrvcSelectNm && searchParams.ofcSrvcSelectNm.length > 0 ? searchParams.ofcSrvcSelectNm.filter((x) => x !== '00') : null;

      if (searchParams.ethnicityList && !searchParams.ethnicityList.includes('00')) {
        criteria.ethnicityList = searchParams.ethnicityList;
      }

      criteria.patientPopulationList =
        searchParams.patientPreferenceSelectNm && searchParams.patientPreferenceSelectNm.length > 0 ? searchParams.patientPreferenceSelectNm.filter((x) => x !== '00') : null;

      if (searchParams.ableToServePcp) {
        criteria.ableToServeAsPCP = searchParams.ableToServePcp.toString();
        if (CommonUtil.isMemberSecure(_appSession)) {
          searchParams.hasPersonalizedMatch = true;
        }
      }

      if (searchParams.scheduleAptmntAvailable) {
        criteria.scheduleAptmntAvailable = searchParams.scheduleAptmntAvailable.toString();
      }
      if (searchParams.acceptingNewPatients) {
        criteria.acceptingNewPatients = searchParams.acceptingNewPatients.toString();
      }
      if (searchParams.medicaidInd) {
        criteria.medicaid = searchParams.medicaidInd.toString();
      }
      if (searchParams.teleHealthInd) {
        criteria.telehealth = searchParams.teleHealthInd;
      }
      if (searchParams.ecpprovider) {
        criteria.ecpprovider = searchParams.ecpprovider.toString();
      }
      if (searchParams.msp) {
        criteria.recognitions.mspOnly = searchParams.msp;
      }
      if (searchParams.valueBasedProvider) {
        criteria.recognitions.valueBasedProviderOnly = searchParams.valueBasedProvider;
      }
      if (searchParams.centerOfExcellence) {
        criteria.recognitions.centerOfExcellenceOnly = searchParams.centerOfExcellence;
      }
      if (searchParams.providerOfDistinction) {
        criteria.recognitions.providerOfDistinctionOnly = searchParams.providerOfDistinction;
      }
      if (searchParams.extendedHoursOnly) {
        criteria.extendedHoursOnly = searchParams.extendedHoursOnly;
      }
      if (searchParams.blueDistinctionCenter) {
        criteria.blueDistinctionList = criteria.blueDistinctionList ? criteria.blueDistinctionList : [];
        searchParams.blueDistinctionCenter.forEach((x) => {
          const bdc: IBlueDistinctCode = {
            code: x.code
          };
          if (x.bd && !x.bdcPlus) {
            bdc.name = x.bd;
          } else if (!x.bd && x.bdcPlus) {
            bdc.name = x.bdcPlus;
          }
          criteria.blueDistinctionList.push(bdc);
        });
      }

      if (searchParams.blueDistinctionType && !searchParams.blueDistinctionTypePlus) {
        criteria.blueDistinctionType = searchParams.blueDistinctionType;
      } else if (!searchParams.blueDistinctionType && searchParams.blueDistinctionTypePlus) {
        criteria.blueDistinctionType = searchParams.blueDistinctionTypePlus;
      }
      if (searchParams.boardCertification) {
        criteria.boardCertification = searchParams.boardCertification.toString();
      }

      if (searchParams.providerTier) {
        criteria.providerTier = searchParams.providerTier;
      }
      if (searchParams.lgbtqProviderOnly) {
        criteria.recognitions.lgbtqProviderOnly = searchParams.lgbtqProviderOnly;
      }
      if (searchParams.greenRibbonOnly) {
        criteria.recognitions.greenRibbonOnly = searchParams.greenRibbonOnly;
      }
      if (searchParams.upswingOnly) {
        criteria.upswingOnly = searchParams.upswingOnly;
      }
      if (searchParams.snyDentalOnly) {
        criteria.recognitions.snyDentalOnly = searchParams.snyDentalOnly;
      }

      criteria.cooperativeCareOnly = searchParams.cooperativeCareOnly;
      criteria.sosOnly = searchParams.sosOnly;

      if (searchParams.nyOMHOnly) {
        criteria.nyOMHOnly = searchParams.nyOMHOnly;
      }

      if (searchParams.nyOASASOnly) {
        criteria.nyOASASOnly = searchParams.nyOASASOnly;
      }

      if (searchParams.blueCarePrimePreferredSpecialistOnly) {
        criteria.blueCarePrimePreferredSpecialistOnly = searchParams.blueCarePrimePreferredSpecialistOnly;
      }

      if (searchParams.nationalProviderIdentifier) {
        criteria.nationalProviderIdentifier = searchParams.nationalProviderIdentifier;
      }

      if (searchParams.providerLicenseType && searchParams.providerLicenseType !== '00') {
        criteria.providerLicenseType = searchParams.providerLicenseType;
      }

      if (searchParams.providerLicenseNumber) {
        criteria.providerLicenseNumber = searchParams.providerLicenseNumber;
      }

      if (searchParams.providerPhone) {
        criteria.providerPhone = searchParams.providerPhone;
      }

      if (searchParams.providerEmail) {
        criteria.providerEmail = searchParams.providerEmail;
      }

      if (searchParams.visionSrvcAvailableList) {
        criteria.visionSrvcAvailableList = searchParams.visionSrvcAvailableList;
      }

      if (searchParams.hospitalAffiliationSelectNm && searchParams.hospitalAffiliationSelectNm !== '00') {
        criteria.hospitalAffiliation = searchParams.hospitalAffiliationSelectNm;
      }

      if (searchParams.groupAffiliationSelectNm && searchParams.groupAffiliationSelectNm !== '00' && searchParams.groupAffiliationHasChanged) {
        criteria.physicianAssociationAffiliation = searchParams.groupAffiliationSelectNm;
      }

      if (searchParams.typeSelectNm === PROVIDER_TYPE.DENTAL || searchParams.typeSelectNm === PROVIDER_TYPE.VISION || searchParams.typeSelectNm === PROVIDER_TYPE.PHARMACY) {
        criteria.coverageTypeCode = searchParams.coverageTypeCode;

        if (searchParams.typeSelectNm === PROVIDER_TYPE.VISION && searchParams.visionBVV && searchParams.visionBVV === 'N') {
          criteria.coverageTypeCode = MEDICAL_COVERAGETYPE;
        }
      }

      if (searchParams.hasCcare) {
        criteria.hasCcare = searchParams.hasCcare;
      }

      const planIdentifierList = [];
      const planNetworkList = [];
      let planStateCd = '';
      if (searchParams.plan) {
        planStateCd = searchParams.plan.stateCd ? searchParams.plan.stateCd.trim() : '';

        if (searchParams.plan.identifier) {
          const planIdentifier: IPlan = {
            identifier: searchParams.plan.identifier.trim()
          };
          //isNationalPlan: searchParams.plan.isNationalPlan.toString()
          planIdentifierList.push(planIdentifier);
        }

        if (searchParams.plan.networkList && searchParams.plan.networkList.length > 0) {
          for (const network of searchParams.plan.networkList || []) {
            const planNetwork: IBaseCode = {
              code: network,
              name: network,
              description: network
            };
            planNetworkList.push(planNetwork);
          }
        }

        // below code is to handle alphaprefix scenario
        if (!searchParams.plan.identifier && searchParams.plan.networkList && searchParams.plan.networkList.length <= 0) {
          criteria.alphaPrefix = searchParams.plan.alphaPrefix;
        }

        criteria.planCategory = searchParams.plan && searchParams.plan.category && searchParams.plan.category.catCode ? searchParams.plan.category.catCode : '';
      }
      if (planIdentifierList && planIdentifierList.length > 0) {
        criteria.planList = planIdentifierList;
      }
      if (planNetworkList && planNetworkList.length > 0) {
        criteria.networkList = planNetworkList;
      }
      if (planStateCd) {
        criteria.planStateCode = planStateCd;
      }
      if (_appSession.deeplinkParams?.alphaprefix) {
        criteria.alphaPrefix = _appSession.deeplinkParams?.alphaprefix;
      }
      if (_appSession?.searchParams?.plan) {
        criteria.alphaPrefix = _appSession.searchParams.plan.alphaPrefix;
      }
      criteria.includePlans = 'true';
      if (affiliationOption) {
        criteria.affiliationOption = affiliationOption;
        criteria.providerTypeCode = affiliationOption;
      }
      if (searchParams.countyCode) {
        criteria.countyCode = searchParams.countyCode;
      }
      // Identification number will be assigned from UI only for HCID search.
      // In secure state Node API will take care of HCID.
      if (searchParams.identificationNumber) {
        criteria.identificationNumber = searchParams.identificationNumber;
        criteria.applyPersonalizedMatch = false;
      } else {
        // Set personalized match only on initial search scenario and in secure state.
        if (
          _appSession.initialSearch === true &&
          CommonUtil.isMemberSecure(_appSession) &&
          !_appSession.isPlanUnknown &&
          (typeof searchParams.selectedSortCriteria === 'undefined' || searchParams.selectedSortCriteria === null || searchParams.selectedSortCriteria === '')
        ) {
          criteria.applyPersonalizedMatch = true;
        } else {
          criteria.applyPersonalizedMatch = searchParams.hasPersonalizedMatch;
        }
      }
      sortOption = searchParams.selectedSortCriteria;

      if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.mbrUid && !_appSession.isPlanUnknown) {
        criteria.mbrUid = _appSession.metaData.appContract.mbrUid;
      }

      if (_appSession.metaData && _appSession.metaData.appContract && _appSession.metaData.appContract.contractUid && !_appSession.isPlanUnknown) {
        criteria.contractUid = _appSession.metaData.appContract.contractUid;
      }

      // Pharmacy criteria starts here
      criteria.dispenseTypeCode = searchParams.dispenseTypeCode;
      criteria.pharmacyLevel = searchParams.pharmacyLevel;
      criteria.allPharmacyFeatures = searchParams.allPharmacyFeatures;
      criteria.ninetyDayRetail = searchParams.ninetyDayRetail;
      criteria.open24HoursADay = searchParams.open24HoursADay;
      criteria.rxM90Pharmacy = searchParams.rxM90Pharmacy;
      criteria.driveThru = searchParams.driveThru;
      criteria.onsiteMedicalClinic = searchParams.onsiteMedicalClinic;
      criteria.bloodPressureScreening = searchParams.bloodPressureScreening;
      criteria.providesFluShots = searchParams.providesFluShots;
      criteria.homeDelivery = searchParams.homeDelivery;
      criteria.vaccinationIndicator = searchParams.vaccinationIndicator;
      criteria.preparesCompounds = searchParams.preparesCompounds;
      criteria.dme = searchParams.dme;
      criteria.open7DaysAWeek = searchParams.open7DaysAWeek;
      criteria.prescriptionDeliveryService = searchParams.prescriptionDeliveryService;
      criteria.preferredPharmacy = searchParams.preferredPharmacy;
      const hasHcid =
        _appSession &&
        _appSession.searchParams &&
        _appSession.searchParams.identificationNumber &&
        _appSession.searchParams.identificationNumber !== '' &&
        _appSession.searchParams.identificationNumber !== null &&
        _appSession.searchParams.identificationNumber !== 'undefined';
      if (_appSession.customAddressSearch && _appSession?.addressObj?.customAddress) {
        criteria.streetAddress = _appSession.addressObj.customAddress.streetAddress;
        criteria.addressLine2 = _appSession.addressObj.customAddress.addressLine2;
        criteria.stateCode = _appSession.addressObj.customAddress.state;
        criteria.cityName = _appSession.addressObj.customAddress.city;
        criteria.postalCode = _appSession.addressObj.customAddress.zipCode;
      }
      criteria.customAddressSearch = _appSession.customAddressSearch;

      if ((CommonUtil.isMemberSecure(_appSession) || hasHcid) && _appSession.isFutureCoverage) {
        criteria.isFuturePlan = _appSession.isFutureCoverage;
      }
      criteria.includeVirtualProviders = (_appSession.hasVirtualUrgentCare && _appSession.feature?.customizedFeatures?.showKHealthFeatures) || false;
      criteria.recognitionCodes = searchParams.recognitionCodes;
    }
    searchRequest = {
      orchestrationRequest: true,
      pageNumber,
      pageSize,
      searchCriteria: criteria,
      sortOption,
      outputFlags: this.checkOutputFlags(_appSession)
    };
    return searchRequest;
  }
  static buildForwardFilterRequest(_appSession: AppSession, pageNumber: any, pageSize: any) {
    return this.buildProviderRequest(_appSession, pageNumber, pageSize);
  }
  static buildProviderLookUpRequest(_appSession: AppSession, _searchTerm: string) {
    let _searchRequest: ISearchRequest;
    let _lookUpRequestRequest: ISearchRequest;
    const _criteria = new SearchCriteria();
    const outputFlags: IOutputFlags = {} as IOutputFlags;

    _searchRequest = this.buildProviderRequest(_appSession, '1', '');

    if (_searchRequest && _searchRequest.searchCriteria) {
      _criteria.providerName = _searchTerm;
      if (_searchRequest.searchCriteria.providerTypeCodeList) {
        _criteria.providerTypeCodeList = _searchRequest.searchCriteria.providerTypeCodeList;
      }
      if (_searchRequest.searchCriteria.standAloneDental) {
        _criteria.standAloneDental = _searchRequest.searchCriteria.standAloneDental;
      }
      if (_searchRequest.searchCriteria.standAloneVision) {
        _criteria.standAloneVision = _searchRequest.searchCriteria.standAloneVision;
      }
      if (_searchRequest.searchCriteria.countyCode) {
        _criteria.countyCode = _searchRequest.searchCriteria.countyCode;
      }
      if (_searchRequest.searchCriteria.countyCode) {
        _criteria.countyCode = _searchRequest.searchCriteria.countyCode;
      }
      if (_searchRequest.searchCriteria.cityName) {
        _criteria.cityName = _searchRequest.searchCriteria.cityName;
      }
      if (_searchRequest.searchCriteria.postalCode) {
        _criteria.postalCode = _searchRequest.searchCriteria.postalCode;
      }
      if (_searchRequest.searchCriteria.stateCode) {
        _criteria.stateCode = _searchRequest.searchCriteria.stateCode;
      }
      if (_searchRequest.searchCriteria.latitude && _searchRequest.searchCriteria.longitude) {
        _criteria.latitude = _searchRequest.searchCriteria.latitude;
        _criteria.longitude = _searchRequest.searchCriteria.longitude;
      }
      if (_searchRequest.searchCriteria.brandCode) {
        _criteria.brandCode = _searchRequest.searchCriteria.brandCode;
      }
      if (_searchRequest.searchCriteria.distance) {
        _criteria.distance = _searchRequest.searchCriteria.distance;
      }
      if (_searchRequest.searchCriteria.planList) {
        _criteria.planList = _searchRequest.searchCriteria.planList;
      }
      if (_searchRequest.searchCriteria.networkList) {
        _criteria.networkList = _searchRequest.searchCriteria.networkList;
      }
      if (_searchRequest.searchCriteria.planStateCode) {
        _criteria.planStateCode = _searchRequest.searchCriteria.planStateCode;
      }
      if (_searchRequest.searchCriteria.alphaPrefix) {
        _criteria.alphaPrefix = _searchRequest.searchCriteria.alphaPrefix;
      }
      if (_searchRequest.searchCriteria.identificationNumber) {
        _criteria.identificationNumber = _searchRequest.searchCriteria.identificationNumber;
      }
      if (_searchRequest.searchCriteria.isFuturePlan) {
        _criteria.isFuturePlan = _searchRequest.searchCriteria.isFuturePlan;
      }
      if (_searchRequest.searchCriteria.mbrUid) {
        _criteria.mbrUid = _searchRequest.searchCriteria.mbrUid;
      }
      if (_searchRequest.searchCriteria.contractUid) {
        _criteria.contractUid = _searchRequest.searchCriteria.contractUid;
      }
      _criteria.includeVirtualProviders = _searchRequest.searchCriteria.includeVirtualProviders || false;
    }

    _lookUpRequestRequest = {
      searchCriteria: _criteria
    };

    if (_searchRequest && _searchRequest.outputFlags && _searchRequest.outputFlags.includeAllNetworks) {
      outputFlags.includeAllNetworks = _searchRequest.outputFlags.includeAllNetworks;
      _lookUpRequestRequest.outputFlags = outputFlags;
    }

    return _lookUpRequestRequest;
  }
  static getProviderTypeText(_appSession: AppSession, content: any) {
    const defaultName = content.searchCriteriaComponent.labels.careProviders;

    if (!_appSession.searchParams) {
      return defaultName;
    }

    const { coverageTypeCode, providerTypeCodeList } = _appSession.searchParams;
    const typeSelected = providerTypeCodeList?.[0];

    if (!typeSelected) {
      return defaultName;
    }

    const providerTypes = [PROVIDER_TYPE.DENTAL.toString(), PROVIDER_TYPE.VISION.toString()];
    const resultType = content.summaryComponent.resultsProvType[typeSelected + coverageTypeCode];

    if (providerTypes.includes(typeSelected) && coverageTypeCode === MEDICAL_COVERAGETYPE && resultType) {
      return resultType;
    }

    const providers = content.commonHealthCareContent.providers;
    const type = providers.find((x) => x.code === typeSelected);

    if (type?.name && !this.isDentalSearch(_appSession)) {
      return type.name;
    } else if (this.isDentalSearch(_appSession)) {
      return content.summaryComponent.resultsProvType[PROVIDER_TYPE.DENTAL];
    } else {
      return content.summaryComponent.resultsProvType[typeSelected] || defaultName;
    }
  }
  static getPlanNetworkPrefixText(_appSession: AppSession, content?: any, searchparams?: ISearchParameters) {
    let _prefixLbl = '';
    if (content && content.summaryComponent) {
      _prefixLbl = content.summaryComponent.resultsHeader.prefix;
    }
    if (_appSession.searchParams.plan.identifier && _appSession.searchParams.plan.name) {
      return _appSession.searchParams.plan.name;
    } else if (_appSession.deeplinkParams.alphaprefix && _appSession.searchParams.plan.name.toLocaleLowerCase() === _appSession.deeplinkParams.alphaprefix.toLocaleLowerCase()) {
      return _appSession.deeplinkParams.alphaprefix + ' ' + _prefixLbl;
    } else if (_appSession.deeplinkParams.alphaprefix && _appSession.searchParams.plan.name && _appSession.searchParams.plan.name !== '') {
      return _appSession.searchParams.plan.name;
    } else if (
      _appSession.metaData &&
      _appSession.metaData.appContract &&
      _appSession.metaData.appContract.coverageDetails &&
      _appSession.metaData.appContract.coverageDetails.productNames &&
      _appSession.metaData.appContract.coverageDetails.productNames.length > 0
    ) {
      let productNames: Array<IProductName>;

      // DSEPRECARE-16464 Dental plan name is displayed even on selecting Vision plan from slider (Single contract with mutiple coverages)
      if (_appSession.metaData.appContract.coverageDetails && _appSession.filterChangePlan && _appSession.filterChangePlan.careType) {
        productNames = _appSession.metaData.appContract.coverageDetails.productNames.filter((c) => c.type && c.type.toLocaleUpperCase() === _appSession.filterChangePlan.careType.toLocaleUpperCase());

        if (productNames.length > 0 && !_appSession.isPlanUnknown) {
          return productNames[0].name;
        } else {
          if (searchparams && searchparams.plan && searchparams.plan.name) {
            return searchparams.plan.name;
          }
        }
      } else if (
        _appSession.metaData.appContract.coverageDetails &&
        _appSession.metaData.appContract.coverageDetails.isDentalStandAlone &&
        (_appSession.integratedSearch || _appSession.searchParams.coverageTypeCode === DENTAL_ROUTINE_COVERAGETYPE)
      ) {
        productNames = _appSession.metaData.appContract.coverageDetails.productNames.filter((c) => c.type && c.type.toLocaleUpperCase() === COVERAGE_TYPE.DENTAL);

        if (productNames.length > 0 && !_appSession.isPlanUnknown) {
          return productNames[0].name;
        } else {
          if (searchparams && searchparams.plan && searchparams.plan.name) {
            return searchparams.plan.name;
          }
        }
      } else if (
        _appSession.metaData.appContract.coverageDetails &&
        _appSession.metaData.appContract.coverageDetails.isVisionStandAlone &&
        (_appSession.integratedSearch || _appSession.searchParams.coverageTypeCode === VISION_ROUTINE_COVERAGETYPE)
      ) {
        productNames = _appSession.metaData.appContract.coverageDetails.productNames.filter((c) => c.type && c.type.toLocaleUpperCase() === COVERAGE_TYPE.VISION);

        if (productNames.length > 0 && !_appSession.isPlanUnknown) {
          return productNames[0].name;
        } else {
          if (searchparams && searchparams.plan && searchparams.plan.name) {
            return searchparams.plan.name;
          }
        }
      } else if (
        _appSession.metaData.appContract.coverageDetails &&
        _appSession.metaData.appContract.coverageDetails.isPharmacyStandAlone &&
        (_appSession.integratedSearch || _appSession.searchParams.coverageTypeCode === PHARMACY_ROUTINE_COVERAGETYPE)
      ) {
        productNames = _appSession.metaData.appContract.coverageDetails.productNames.filter((c) => c.type && c.type.toLocaleUpperCase() === COVERAGE_TYPE.PHARMACY);

        if (productNames.length > 0 && !_appSession.isPlanUnknown) {
          return productNames[0].name;
        } else {
          if (searchparams && searchparams.plan && searchparams.plan.name) {
            return searchparams.plan.name;
          }
        }
      } else if (_appSession.metaData.appContract.coverageDetails && _appSession.metaData.appContract.coverageDetails.hasMedicalPlan) {
        productNames = _appSession.metaData.appContract.coverageDetails.productNames.filter((c) => c.type && c.type.toLocaleUpperCase() === COVERAGE_TYPE.MEDICAL);

        if (productNames.length > 0 && !_appSession.isPlanUnknown) {
          return productNames[0].name;
        } else {
          if (searchparams && searchparams.plan && searchparams.plan.name) {
            return searchparams.plan.name;
          }
        }
      }
    } else {
      return !_appSession.isPlanUnknown
        ? _appSession.searchParams.plan.name
        : searchparams && searchparams.plan && searchparams.plan.name
          ? searchparams.plan.name
          : _appSession.searchParams.plan.name; // Plan unknown scenario in secure state
    }
  }

  static setCommonSearchCriteria(_appSession: AppSession): SearchCriteria {
    const criteria = new SearchCriteria();
    if (_appSession.searchParams.providerTypeCodeList && _appSession.searchParams.providerTypeCodeList.length > 0) {
      criteria.providerTypeCodeList = _appSession.searchParams.providerTypeCodeList;

      if (_appSession.searchParams.standAloneDental) {
        criteria.standAloneDental = _appSession.searchParams.standAloneDental;
      }
      if (_appSession.searchParams.cmeValue) {
        criteria.cmeDesignationList = _appSession.searchParams.cmeValue;
      }
      if (_appSession.searchParams.standAloneVision) {
        criteria.standAloneVision = _appSession.searchParams.standAloneVision;
      }
      if (_appSession.searchParams.planTypeList) {
        criteria.planTypeList = _appSession.searchParams.planTypeList;
      }
    } else {
      criteria.providerTypeCodeList = [_appSession.searchParams.typeSelectNm];
      criteria.providerTypeCode = _appSession.searchParams.typeSelectNm;
    }
    criteria.distance = _appSession.searchParams.distanceNm;
    criteria.latitude = _appSession.searchParams.coordinates?.latitude.toString();
    criteria.longitude = _appSession.searchParams.coordinates?.longitude.toString();
    criteria.brandCode = _appSession.metaData.brandCd;
    if (_appSession.searchParams.stateCd && _appSession.searchParams.stateCd.code) {
      criteria.stateCode = _appSession.searchParams.stateCd.code;
    }
    if (_appSession.searchParams.zip) {
      criteria.postalCode = isNaN(Number(_appSession.searchParams.zip.trim())) ? '' : _appSession.searchParams.zip.trim();
    }
    if (_appSession.searchParams.city) {
      criteria.cityName = _appSession.searchParams.countyCode ? '' : _appSession.searchParams.city;
    }
    if (_appSession.searchParams.countyCode) {
      criteria.countyCode = _appSession.searchParams.countyCode;
    }
    if (_appSession.searchParams.bluePrecision) {
      criteria.bluePrecision = _appSession.searchParams.bluePrecision.toString();
    }
    if (_appSession.searchParams.blueDistinct) {
      criteria.blueDistinctionList = [];
      const bdc: IBlueDistinctCode = {
        code: _appSession.searchParams.blueDistinct
      };
      criteria.blueDistinctionList.push(bdc);
    }
    if (_appSession.searchParams.enhancedPersonalCare) {
      criteria.patientCenteredCare = _appSession.searchParams.enhancedPersonalCare.toString();
    }
    if (_appSession.searchParams.genderCodeList) {
      criteria.genderCodeList = _appSession.searchParams.genderCodeList;
    }
    criteria.locale = (_appSession.metaData && _appSession.metaData.locale && _appSession.metaData.locale.toString()) || '';
    if (_appSession.searchParams) {
      const recognition = {} as IRecognition;
      recognition.centerOfExcellenceOnly = _appSession.searchParams.centerOfExcellence;
      recognition.providerOfDistinctionOnly = _appSession.searchParams.providerOfDistinction;
      recognition.mspOnly = _appSession.searchParams.msp;
      recognition.valueBasedProviderOnly = _appSession.searchParams.valueBasedProvider;
      criteria.recognitions = recognition;
    }
    criteria.providerTypeCodeList = criteria.providerTypeCodeList?.filter((item) => item !== '');
    return criteria;
  }

  static getLocationText(_appSession: AppSession) {
    if (_appSession.customAddressSearch) {
      /*cahmo flow*/
      if (_appSession.searchParams.zip && _appSession.customAddressSearch) {
        if (!_appSession.searchParams.city) {
          return this.getZipcode(_appSession.searchParams.zip);
        } else if (this.hasAppSessionSearchParamsCityStateCdAndZip(_appSession)) {
          return this.getStateCdAndZip(_appSession);
        }
      } else if (!_appSession.customAddressSearch) {
        if (this.hasAppSessionSearchParamsCityAndStateCd(_appSession)) {
          return this.getCityAndStateCd(_appSession);
        } else if (_appSession.searchParams.zip) {
          return this.getZipcode(_appSession.searchParams.zip);
        }
      }
    } else {
      /*non-cahmo flow*/
      if (this.hasAppSessionSearchParamsCityAndStateCd(_appSession)) {
        return this.getCityAndStateCd(_appSession);
      }
      if (_appSession.searchParams.zip) {
        return this.getZipcode(_appSession.searchParams.zip);
      }
    }
  }

  static hasAppSessionSearchParamsCityAndStateCd(_appSession: AppSession): boolean {
    return _appSession.searchParams.stateCd?.code && _appSession.searchParams.city ? true : false;
  }

  static hasAppSessionSearchParamsCityStateCdAndZip(_appSession: AppSession): boolean {
    return _appSession.searchParams.stateCd?.code && _appSession.searchParams.city && _appSession.searchParams.zip ? true : false;
  }

  static getZipcode(zip: string): string {
    return isNaN(Number(zip.trim())) ? '' : zip.trim();
  }

  static getCityAndStateCd(_appSession: AppSession): string {
    return _appSession.searchParams?.city + ', ' + _appSession.searchParams?.stateCd?.code;
  }

  static getStateCdAndZip(_appSession: AppSession): string {
    return _appSession.searchParams?.stateCd?.code + ', ' + _appSession.searchParams.zip.trim();
  }

  static getAdobeDisclaimerTag(screenNo: any) {
    switch (screenNo) {
      case 1:
        return this.adobeTags.discResults;
      case 2:
        return this.adobeTags.discDetails;
      case 3:
        return this.adobeTags.discEycDetails;
      case 4:
        return this.adobeTags.discEycResults;
      default:
        return this.adobeTags.discResults;
    }
  }

  static isDentalSearch(appSession): boolean {
    return (
      appSession.hasDentalEYC &&
      appSession.searchParams &&
      appSession.searchParams.eycSearchParam &&
      appSession.searchParams.eycSearchParam.eycProcedureCriteria &&
      appSession.searchParams.eycSearchParam.eycProcedureCriteria.providerCategoryCode === PROVIDER_TYPE.DENTAL
    );
  }

  static checkOutputFlags(_appSession: AppSession): IOutputFlags {
    const outputFlags: IOutputFlags = {} as IOutputFlags;

    outputFlags.includeTaxonomy = true;
    outputFlags.includeVisibilityRules = providerOutputFlags.visibilityRulesFlag;
    outputFlags.includeReviewRatings = providerOutputFlags.reviewRatingsFlag;
    outputFlags.imposeAddressConsolidation = providerOutputFlags.imposeAddressConsolidation;
    outputFlags.improveSearchCriteria = providerOutputFlags.improveSearchCriteria;
    outputFlags.includeSpfInfo = providerOutputFlags.includeSpfInfo;
    outputFlags.includeProviderNetworks = providerOutputFlags.includeProviderNetworks;
    if (_appSession && _appSession.isMedicalCodeSearch) {
      outputFlags.includeDisclaimerRules = providerOutputFlags.includeDisclaimerRules;
    }

    /**
     * non-EYC initial search result should return prime group providers for
     * CA HMO members and following search will depend on the filter selection
     * Note: CA HMO validation will be performed in Node layer
     */
    if (_appSession && !_appSession.isEyc && _appSession.searchParams) {
      outputFlags.includePrimeGroupSearch = _appSession.searchParams.includePrimeGroupSearch;
      outputFlags.includeHealthSystemSearch = _appSession.searchParams.includeHealthSystemSearch;
    } else {
      outputFlags.includePrimeGroupSearch = false;
      outputFlags.includeHealthSystemSearch = false;
    }

    if (!_appSession.isEyc) {
      outputFlags.includeAllNetworks = (_appSession.hasStandaloneDentalContract || _appSession.hasStandaloneVisionContract) && _appSession.hasMedicalContracts;
    }

    return outputFlags;
  }

  static isEycSearch(appSession: AppSession): boolean {
    return Boolean(appSession.searchParams && appSession.searchParams.eycSearchParam && appSession.searchParams.eycSearchParam.eycProcedureCriteria);
  }
}
