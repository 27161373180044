import { TitleCasePipe } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { IAddress } from '../../../../../../app/care-circle/interfaces/iAddress';
import { Locale } from '../../../../../../app/common/constants/app-constants';
import { ADDCARETEAM_FAILURE_MESSAGE, ADDCARETEAM_SUCCESS_MESSAGE, ALERT_TYPE, PROVIDER_TYPE } from '../../../../../../app/fad/search-providers/values/providerSearchConstants';
import { IProvider } from '../../../../../care-circle/interfaces/iCareProvider';
import { CareCircleService } from '../../../../../care-circle/services/careCircleSvc';
import { InfoListService } from '../../../../../common/components/info-list/services/infoListSvc';
import { IAssignCareLoader } from '../../../../../common/interfaces/iAssignCare';
import { IBaseCode } from '../../../../../common/interfaces/iBaseCode';
import { IMessage } from '../../../../../common/interfaces/iMessage';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { ICareTeamRequest, IMemberOption } from '../../../../common/interfaces/iCareTeam';
import { IMember } from '../../../../common/interfaces/iMember';
import { CareteamUtility } from '../../../../common/utilities/careteamUtil';
import { BaseComponent } from './../../../../common/components/core/baseCmp';
@Component({
  moduleId: module.id,
  selector: 'app-fc-assign-care',
  templateUrl: './assignCareCmp.html'
})
export class AssignCareComponent extends BaseComponent implements OnInit {
  providerCardAddToCareContent = this.content?.result?.providerCardDetails?.addToCareTeam;
  addToCarePanelRef: ModalRef<HTMLElement>;
  @ViewChild('assignAddToCareModal')
  assignAddToCareModal: TemplateRef<HTMLElement>;
  memberOptions: IMemberOption[];
  selectedMbr: IMember;
  selectedMbrUid: string;
  isEmulation: boolean = false;
  locale: string;
  addProviderConfirm: string = '';
  ariaLabelForAddCareTeam: string = '';
  @Input()
  providerInfo: any;
  providerIndex: number = 0;
  @Output()
  loader: EventEmitter<IAssignCareLoader> = new EventEmitter<IAssignCareLoader>();
  @Output()
  addCareAlertMsg: EventEmitter<IMessage[]> = new EventEmitter<IMessage[]>();
  @Input() showIcon: boolean = false;
  @Input() isComparePage: boolean = false;
  @Input() dataAnalyticsTag: string = 'addTeamDetailFindCare';

  constructor(
    private _careTeamSvc: CareCircleService,
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _eventHandler: EventHandler,
    private _route: ActivatedRoute,
    private _sidePanel: SidePanel,
    private _titlecasePipe: TitleCasePipe,
    private _infoListSvc: InfoListService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    if (this._appSession) {
      this.isEmulation = this._appSession.isEmulation;
    }
  }

  bindMemberInfo() {
    if (this._appSession?.appState?.selectedEligibilityProduct?.selectedContract && this._appSession?.appState?.selectedEligibilityProduct?.members.length > 0) {
      const memberList = this._appSession.appState.selectedEligibilityProduct.members.filter((x: IMember) => x.mbrUid !== '');
      if (memberList && memberList.length > 0) {
        this.memberOptions = this.buildMemberOptions(memberList);
        this.selectedMbr = this.getSelectedMember(this._appSession.appState.selectedEligibilityProduct.selectedContract.mbrUid);
        this.selectedMbrUid = this.selectedMbr.mbrUid;

        const _memberName = this.titleCase(this.selectedMbr.firstNm) + ' ' + this.titleCase(this.selectedMbr.lastNm);
        const _providerName = this.getProviderName();
        this.addProviderConfirm = this.providerCardAddToCareContent?.addCareProviderContent.replace(/{PROVIDER}/gi, _providerName).replace(/{MEMBER}/gi, _memberName.trim());
        this.ariaLabelForAddCareTeam = this.providerCardAddToCareContent?.ariaLabelForAddCareTeam.replace(/{PROVIDER}/gi, _providerName).replace(/{MEMBER}/gi, _memberName.trim());
      }
    }
  }

  getProviderName() {
    return this.titleCase(this._appSession.selectedProvider?.providerName);
  }

  onMemberChange(mbr: IMemberOption) {
    this.selectedMbrUid = mbr.value;
    this.selectedMbr = this.getSelectedMember(this.selectedMbrUid);
  }

  buildMemberOptions(result: IMember[]) {
    const _memberOptions = [];
    result.forEach((x: IMember) => {
      _memberOptions.push({
        label:
          x.relationshipCd && x.relationshipCd !== null
            ? this.titleCase(x.firstNm) + ' ' + this.titleCase(x.lastNm) + ' (' + this.titleCase(x.relationshipCd) + ')'
            : this.titleCase(x.firstNm) + ' ' + this.titleCase(x.lastNm),
        value: x.mbrUid,
        id: 'rdb-mbr-id-' + x.mbrUid,
        name: 'rdb-mbr-name-' + x.mbrUid
      });
    });
    return _memberOptions;
  }

  getSelectedMember(mbrUid: string) {
    let _selectedMember: IMember;
    if (this._appSession?.appState?.selectedEligibilityProduct?.selectedContract && this._appSession?.appState?.selectedEligibilityProduct?.members.length > 0) {
      const _memberList = this._appSession.appState.selectedEligibilityProduct.members;
      mbrUid = mbrUid === '' ? this._appSession.appState.selectedEligibilityProduct.selectedContract.mbrUid : mbrUid;
      if (mbrUid) {
        const mbr = _memberList.filter((x: IMember) => x.mbrUid === mbrUid);
        if (mbr && mbr.length > 0) {
          _selectedMember = mbr[0];
        }
      }
      if (!_selectedMember) {
        _selectedMember = _memberList.find((x: IMember) => x.mbrUid === this.selectedMbrUid);
      }
    }
    return _selectedMember;
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  openSidePanel() {
    this.bindMemberInfo();
    this.addToCarePanelRef = this._sidePanel.open('right', this.assignAddToCareModal);
  }

  closeAssignProviderModal() {
    if (this.addToCarePanelRef) {
      this.addToCarePanelRef.close();
    }
  }

  addCareTeam() {
    const assignCareLoaderObj = {} as IAssignCareLoader;
    assignCareLoaderObj.loader = true;
    assignCareLoaderObj.apiError = false;
    this.loader.emit(assignCareLoaderObj);

    const _providerReq = {} as IProvider;
    this.providerIndex = this.providerInfo?.actualIndex;
    _providerReq.providerId = this.providerInfo?.providerIdentifier;
    _providerReq.providerDisplayNm = this.providerInfo?.providerName ? this.providerInfo.providerName : '';
    if (this.providerInfo?.firstName) {
      _providerReq.providerFirstNm = this.providerInfo.firstName;
      _providerReq.providerLastNm = this.providerInfo.lastName ? this.providerInfo.lastName : '';
      _providerReq.providerMiddleNm = this.providerInfo.middleName ? this.providerInfo.middleName : '';
    } else {
      _providerReq.providerFirstNm = _providerReq.providerDisplayNm;
    }

    _providerReq.nickName = '';
    _providerReq.nationalProviderId = this.providerInfo?.nationalProviderIdentifier;
    _providerReq.providerTypeCodes = this.providerInfo?.providerTypeCodeList;
    _providerReq.address = {
      addressId: this.providerInfo?.address?.addressId,
      addressLineOne: this.providerInfo?.address?.addressOne,
      latitude: this.providerInfo?.address?.latitude,
      longitude: this.providerInfo?.address?.longitude,
      stateCd: {
        code: this.providerInfo?.address?.state,
        name: this.providerInfo?.address?.state
      } as IBaseCode,
      city: this.providerInfo?.address?.city,
      zipCd: this.providerInfo?.address?.postalCode,
      phone: this.providerInfo?.address?.phone
    } as IAddress;

    if (this.providerInfo?.providerTypeCodeList && this.providerInfo?.providerTypeCodeList.length === 1 && this.providerInfo.providerTypeCodeList[0] === PROVIDER_TYPE.PHARMACY) {
      const _specialities: IBaseCode[] = [];
      _specialities.push({
        code: '',
        name: ''
      } as IBaseCode);
      _providerReq.specialtyCd = _specialities;
    } else {
      if (this.providerInfo?.specialities && this.providerInfo?.specialities.length > 0) {
        const _specialties: IBaseCode[] = [];
        for (const specialty of this.providerInfo.specialities || []) {
          _specialties.push({
            code: specialty.specialtyCode,
            name: this.locale === Locale.SPANISH ? specialty.specialtyCodeEnName : specialty.specialtyCodeName
          } as IBaseCode);
        }
        _providerReq.specialtyCd = _specialties;
      }
    }

    _providerReq.source = 'findcare';
    _providerReq.isRemoved = false;

    const _disclaimerCodes = this._infoListSvc.disclaimerCodes;
    this._infoListSvc.reset();
    let disclaimerCodes: IMessage[] = [];

    const _selectedMemberUid = this.selectedMbr?.mbrUid;
    const _selectedMemberContract = this._appSession?.appState?.selectedEligibilityProduct?.selectedContract?.contractUid;
    this._careTeamSvc.addCareTeam(CareteamUtility.buildAddCareTeamRequest(_selectedMemberContract, _providerReq, _selectedMemberUid)).then(
      (resp: ICareTeamRequest) => {
        if (resp) {
          // Assign care
        }

        const _index = _disclaimerCodes.findIndex((x) => x.contentId === ADDCARETEAM_SUCCESS_MESSAGE);
        if (_index > -1) {
          _disclaimerCodes.splice(_index, 1);
        }
        disclaimerCodes = _disclaimerCodes;
        const _message = {
          contentId: ADDCARETEAM_SUCCESS_MESSAGE,
          content: this.buildMessage(true),
          type: ALERT_TYPE.POSITIVE,
          focus: true
        } as IMessage;

        assignCareLoaderObj.loader = false;
        this.loader.emit(assignCareLoaderObj);
        this.closeAssignProviderModal();
        disclaimerCodes.unshift(_message);
        this._infoListSvc.setInfoList(disclaimerCodes);
        this.addCareAlertMsg.emit(disclaimerCodes);
        setTimeout(() => {
          this._infoListSvc.removeMsgById(ADDCARETEAM_SUCCESS_MESSAGE);
        }, 7000);
      },
      (error: any) => {
        assignCareLoaderObj.loader = false;
        assignCareLoaderObj.apiError = true;
        this.loader.emit(assignCareLoaderObj);

        const _index = _disclaimerCodes.findIndex((x) => x.contentId === ADDCARETEAM_FAILURE_MESSAGE);
        if (_index > -1) {
          _disclaimerCodes.splice(_index, 1);
        }
        disclaimerCodes = _disclaimerCodes;
        const _message = {
          contentId: ADDCARETEAM_FAILURE_MESSAGE,
          content: this.buildMessage(false),
          type: ALERT_TYPE.NEGATIVE,
          focus: true
        } as IMessage;
        this.closeAssignProviderModal();
        disclaimerCodes.unshift(_message);
        this._infoListSvc.setInfoList(disclaimerCodes);
        this.addCareAlertMsg.emit(disclaimerCodes);
      }
    );

    return false;
  }

  buildMessage(isSuccess: boolean) {
    let _message = '';
    if (this.selectedMbr) {
      const _memberName = this.titleCase(this.selectedMbr.firstNm) + ' ' + this.titleCase(this.selectedMbr.lastNm);
      const _providerName = this.getProviderName();

      if (isSuccess) {
        _message = this.providerCardAddToCareContent?.addCareTeamSuccessMsg;
      } else {
        _message = this.providerCardAddToCareContent?.addCareTeamFailureMsg;
      }

      _message = _message?.replace(/{MEMBER}/gi, _memberName.trim()).replace(/{PROVIDER}/gi, _providerName);
    }
    return _message;
  }
}
