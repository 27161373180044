import { EventEmitter } from '@angular/core';
import * as uuid from 'uuid';
import { AppExternalCommands } from '../../common/constants/app-constants';
import { AppEvents } from '../../common/enums/appEvents';
import { IntentScenario } from '../../common/enums/intent';
import { IEventDetail } from '../../common/interfaces/iEventDetail';
import { EventHandler } from '../../common/services/eventHandler';
import { AppSession } from '../../common/values/appSession';

export class SydneyChatbotEventChannelUtil {
  public static _pfAppCommandEvent: EventEmitter<IEventDetail> = new EventEmitter<IEventDetail>();

  static publishEventForIntent(userIntent: IntentScenario, searchTerm: string, appSession: AppSession, eventHandler: EventHandler) {
    this._pfAppCommandEvent = eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];
    if (userIntent === IntentScenario.IGNORE) {
      eventDetail.target = AppExternalCommands.RCP_LIVE_CHAT.CMD;
      eventDetail.message = AppExternalCommands.RCP_LIVE_CHAT.MSG;
  } else {
      eventDetail.target = AppExternalCommands.CHATBOT_INTERVENTION.CMD;
      eventDetail.message = AppExternalCommands.CHATBOT_INTERVENTION.MSG;
  }
    const threadId = uuid.v4();
    const formattedUserQuery = `${AppExternalCommands.PRECHAT_APPEND_TEXT}: '${searchTerm}'`;
    eventDetail.object = {
      preChatbotHandoverDetails: {
        contractUid: appSession?.appState?.selectedEligibilityProduct?.selectedContract?.contractUid ?? '',
        memberUid: appSession?.appState?.selectedEligibilityProduct?.selectedContract?.mbrUid ?? '',
        predictedClass: userIntent,
        zipCode: appSession?.addressObj?.homeAddress?.zipCode ?? '',
        userQuery: formattedUserQuery,
        threadId: threadId,
        isPCPEnabled: appSession.appState?.opsIndicator?.isPcpEligible ?? false
      }
    };
    this._pfAppCommandEvent.emit(eventDetail);
  }
}
