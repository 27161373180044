import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { BaseService } from '../../../common/services/baseService';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { ISecureDentalRequest } from '../../../findcare/common/interfaces/iDentalProcedureSearchRequest';
import { IDentalProcedureResponse } from '../../../findcare/common/interfaces/iDentalProcedureSearchResponse';

@Injectable({
  providedIn: 'root'
})
export class SecureV2TccDental extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  execute(searchReq: ISecureDentalRequest): Promise<IDentalProcedureResponse> {
    const apiUrl = apiNameList.restApi.secureV2TccDental;
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiUrl,
      data: searchReq,
      headers: this.getHeaders()
    });
  }
}
