import { TitleCasePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { UxSharedModule } from '../../../libs/uxd-shared/public_api';
import { IDeeplink } from '../../common/components/app-secure/models/iDeeplink';
import { IFilterChangePlan } from '../../common/components/change-plan/models/iChangePlanParam';
import { ChangePlanService } from '../../common/components/change-plan/services/changePlanSvc';
import { AppConstants, SERVICE_AREA_VALIDATION_SUCCESS_CODE } from '../../common/constants/app-constants';
import { AppNavigations } from '../../common/constants/app-navigations';
import { IMessage } from '../../common/interfaces/iMessage';
import { EventHandler } from '../../common/services/eventHandler';
import { NavigationService } from '../../common/services/navigationService';
import { AppSession } from '../../common/values/appSession';
import { ContentHelper } from '../../common/values/contentHelper';
import { ProviderUtilityService } from '../../fad/search-providers/services/providerUtilitySvc';
import { BaseComponent } from '../common/components/core/baseCmp';
import { AlertType, PROVIDER_SEARCH_DISTANCE, SERVICE_AREA_VALIDATION_ARIA_MESSAGE, SERVICE_AREA_VALIDATION_MESSAGE } from '../common/constants/common';
import { IPlanRule } from '../common/interfaces/iRulesParameters';
import { ISearchParameters } from '../common/interfaces/iSearchParameters';
import { ICountyDropDown, ICountyDropDownOptions, IZipcodeDropDown, IZipcodeDropDownOptions, IZipCodeListRequest, IZipCodesList } from '../common/interfaces/iZipCodeList';
import { CommonUtility } from '../common/utilities/commonUtil';
import { IPrefixMetadataResponse } from './interfaces/iPrefixMetadata';
import { IAddressInfoReq, IAddressInfoRes, ICountyResponse, IInputData, INetworkId, IServiceAreaValidationRequest, IServiceAreaValidationResponse } from './interfaces/iServiceAreaValidation';
import { SAVNavigationService } from './services/savNavigationSvc';

@Component({
  moduleId: module.id,
  selector: 'app-fc-service-area-validation',
  templateUrl: './serviceAreaValidationCmp.html'
})
export class ServiceAreaValidationComponent extends BaseComponent implements OnInit {
  planRule: IPlanRule;
  commonContent: any;
  _uxdShared: UxSharedModule;
  private _el: ElementRef;
  private _renderer: Renderer2;
  private readonly changePlanSaveClickSubscription: Subscription;

  get searchParams() {
    return this._appSession?.searchParams;
  }

  get isIntegratedMode() {
    return this._appSession.isIntegratedMode;
  }

  searchCriteria: ISearchParameters;
  info: IMessage;
  cbRepresentative = {
    id: 'representativeInd',
    name: 'representativeInd',
    trueValue: true,
    falseValue: false
  };
  county: ICountyDropDown;
  zipcode: IZipcodeDropDown;
  req: IServiceAreaValidationRequest;
  cityValue: boolean;
  countyValue: boolean;
  zipcodeValue: boolean;
  continueDisabled: boolean = true;
  isSAVFailed: boolean = false;
  countyEnabled: boolean = false;
  zipcodeEnabled: boolean = false;
  networks: IPrefixMetadataResponse;
  isCbChecked: boolean = false;
  zipcodeDisplay: string = '';
  countyDisplay: string = '';
  showPageProgress: boolean = true;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject(ContentHelper)
    private _contentHelper: ContentHelper,
    private _changePlanService: ChangePlanService,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _providerUtilityService: ProviderUtilityService,
    private _titlecasePipe?: TitleCasePipe,
    private _savNavigationSav?: SAVNavigationService
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.commonContent = this.content?.common;
    this.zipcodeDisplay = this.commonContent?.labels?.zipcodeLabel;
    this.countyDisplay = this.commonContent?.labels?.countyLabel;
    this.processInfoForAlert();
    this.setValues();
    if (!this._appSession?.providerTypes?.length) {
      this.getProviderTypes();
    }
  }

  getProviderTypes(): void {
    this._providerUtilityService.getProviderCodesList(CommonUtility.buildPrefixRuleRequest(this._appSession?.searchParams, this._appSession)).then((result) => {
      this._appSession.providerTypes = CommonUtility.populateProviderTypes(result, this._appSession, false);
    });
  }

  goToPlanSelection() {
    if (this._appSession?.deeplinkParams?.hcid) {
      this._appSession.deeplinkParams = {} as IDeeplink;
      this.searchParams.plan = null;
    }
    const isPlanUnknown =
      CommonUtility.isMemberSecure(this._appSession) && this._appSession.searchParams.plan.securePlanLabelKey === AppConstants.PlanUnknown && this.planRule && !this.planRule.isMedicareGov;

    if (CommonUtility.isMemberSecure(this._appSession)) {
      this._appSession.isPlanUnknown = isPlanUnknown;
    }
    if (!CommonUtility.isMemberSecure(this._appSession) && !this._appSession.isPlanUnknown) {
      this.resetSearchParams();
    }
    if (this._appSession && this._appSession.searchParams && this._appSession.searchParams.plan && this._appSession.searchParams.plan.securePlanLabelKey) {
      this.searchParams.securePlanLabelKey = this._appSession.searchParams.plan.securePlanLabelKey;
    }

    this._appSession.searchParams = this.searchParams;
    this._appSession.showSAVPage = false;
    this._appSession.resetGeoLocation();
    this._navigationService.navigateByUrl(AppNavigations.FCR_HOME_PATH);
  }

  resetSearchParams() {
    this.searchParams.stateCd = '';
    this.searchParams.provNm = '';
    this.searchParams.zip = '';
    this.searchParams.typeSelectNm = '';
    this.searchParams.providerTypeCodeList = [];
    this.searchParams.specialtySelectNm = [];
    this.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
    this.searchParams.city = '';
    this.searchParams.coordinates = {
      latitude: '',
      longitude: ''
    };
    this._appSession.deeplinkParams.alphaprefix = null;
    this._appSession.searchTerm = '';
    this._appSession.isSearchTerm = false;
    this._appSession.searchOption = '';
    this._appSession.isDentalStandAlone = false;
    this._appSession.isPharmacyStandAlone = false;
    this._appSession.isVisionStandAlone = false;
    this._appSession.filterChangePlan = {} as IFilterChangePlan;
    this._appSession.filterChangePlan.isPreviouState = false;
    this._appSession.isCaHmoDmhc = false;
    this._appSession.serviceAreaValidated = false;
  }

  processInfoForAlert(): void {
    const savAlert: string = this.commonContent?.alerts?.serviceAreaValidationAlert?.msgText;
    const savAlertId: string = SERVICE_AREA_VALIDATION_MESSAGE;
    const savAlertAriaLabelId: string = SERVICE_AREA_VALIDATION_ARIA_MESSAGE;
    this.info = {
      contentId: savAlertId,
      content: savAlert,
      type: AlertType.INFORMATION,
      contentAriaLabelId: savAlertAriaLabelId
    } as IMessage;
  }

  onCheckboxClick(event: Event): void {
    const chkBox = event.target as HTMLInputElement;
    if (chkBox != null && chkBox.checked !== undefined) {
      const isChecked = chkBox.checked;
      this.continueDisabled = !isChecked;
    }
  }

  closeInfo() {
    this.info = {};
  }

  goToSearchResults(): void {
    this._appSession.showSAVPage = false;
    this._appSession.serviceAreaValidated = true;
    this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
  }

  getCloseMessage(): string {
    return this.commonContent.labels.dismiss;
  }

  embedBaseUrl(): string {
    return this.info.content;
  }

  showApiError(error: any) {
    this.isSAVFailed = true;
    this.continueDisabled = true;
    this.isCbChecked = false;
    throw error;
  }

  async setValues() {
    if ((this._appSession.searchParams.countyCode == null && this._appSession.searchParams.city == '') || this._appSession.customAddressSearch) {
      this.zipcodeValue = true;
      this._savNavigationSav.getZipcodeBasedCounty().then(
        (result) => {
          if (result && !isEmpty(result)) {
            this.showPageProgress = false;
            this.filterValues(result?.outputData?.addressInfo, this._appSession?.searchParams?.zip);
          } else if (result && isEmpty(result)) {
            this._navigationService.navigateByUrl(AppNavigations.SEARCH_RESULTS_PATH);
          }
        },
        (error) => {
          try {
            this.showPageProgress = false;
            this.showApiError(error);
          } catch (e) {}
        }
      );
    } else {
      this.networks = await this._savNavigationSav.getPrefixData();
      if (this._appSession.searchParams.countyCode) {
        this.countyValue = true;
        this.getCountyBasedZip(this.countyValue);
      } else if (this._appSession.searchParams.countyCode == null && this._appSession.searchParams.city) {
        this.cityValue = true;
        this.getCityBasedZip();
      }
    }
  }

  getCountyBasedZip(countyValue?: boolean): void {
    this._providerUtilityService.getZipcodeList(this.buildZipcodeRequest(countyValue)).then(
      (result: IZipCodesList) => {
        this.showPageProgress = false;
        if (result?.zipCodesList?.length) {
          this.zipcodeEnabled = true;
        }
        this.buildZipcodeDropDown(result.zipCodesList);
      },
      (error: any) => {
        try {
          this.showPageProgress = false;
          this.showApiError(error);
        } catch (e) {}
      }
    );
  }

  getCityBasedZip(): void {
    this._providerUtilityService.getZipcodeList(this.buildZipcodeRequest()).then(
      (result: IZipCodesList) => {
        this.showPageProgress = false;
        if (result?.zipCodesList?.length) {
          this.zipcodeEnabled = true;
        }
        this.buildZipcodeDropDown(result.zipCodesList);
      },
      (error: any) => {
        try {
          this.showPageProgress = false;
          this.showApiError(error);
        } catch (e) {}
      }
    );
  }

  buildCountyBasedDropDown(addressInfo: IAddressInfoRes): void {
    this.county = {} as ICountyDropDown;
    this.county.options = [] as Array<ICountyDropDownOptions>;
    (addressInfo.savInfo || []).forEach((county) => {
      this.county.options.push({
        label: this._titlecasePipe.transform(county.countyNm),
        value: county.countyCd,
        savOutput: county.savOutput
      });
    });

    if (this.county?.options?.length === 1) {
      this.validateCountyIsSav(this.county.options[0].value);
    }
  }

  buildCountyDropDown(addressInfo: Array<IAddressInfoRes>): void {
    this.county = {} as ICountyDropDown;
    this.county.options = [] as Array<ICountyDropDownOptions>;
    if (addressInfo?.length) {
      addressInfo.forEach((address) => {
        (address.savInfo || []).forEach((value) => {
          this.county.options.push({
            label: this._titlecasePipe.transform(value.countyNm),
            value: value.countyCd,
            savOutput: value.savOutput
          });
        });
      });
      if (this.county?.options?.length > 1) {
        this.county.options.sort((a, b) => {
          return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
        });
      }
    }

    if (this.county?.options?.length === 1) {
      this.validateCountyIsSav(this.county.options[0].value);
    }

    if (!this.county?.options?.length) {
      this.isSAVFailed = true;
      this.isCbChecked = false;
    }
  }

  buildZipcodeDropDown(result: Array<string>): void {
    this.zipcode = {} as IZipcodeDropDown;
    this.zipcode.options = [] as Array<IZipcodeDropDownOptions>;
    result.sort();
    result.forEach((zipcodeNm) => {
      this.zipcode.options.push({
        label: zipcodeNm,
        value: zipcodeNm
      });
    });

    if (this.zipcode?.options?.length === 1 && this.cityValue) {
      this.getCountySavList(this.zipcode.options[0].value);
    } else if (this.zipcode?.options?.length === 1 && this.countyValue) {
      this.getZipcodeSavList(this.zipcode.options[0].value);
    }

    if (!result?.length) {
      this.isSAVFailed = true;
      this.isCbChecked = false;
    }
  }

  buildZipcodeRequest(countyValue?: boolean): IZipCodeListRequest {
    let req = {} as IZipCodeListRequest;
    if (countyValue) {
      req.countyCode = this._appSession?.searchParams?.countyCode || '';
      req.stateCode = this._appSession?.searchParams?.stateCd?.code;
    } else {
      req.cityName = this._appSession?.searchParams?.city;
      req.countyCode = this._appSession?.searchParams?.countyCode || '';
      req.stateCode = this._appSession?.searchParams?.stateCd?.code;
    }
    return req;
  }

  async getZipcodeSavList(zipcode: string): Promise<void> {
    if (zipcode !== '') {
      this.zipcodeDisplay = zipcode;
      let req = {} as IServiceAreaValidationRequest;
      req.inputData = {} as IInputData;
      req.inputData.networkIds = {} as INetworkId;
      req.inputData.addressInfo = [{}] as Array<IAddressInfoReq>;
      req.inputData.srcSystem = this._appSession?.metaData?.brandCd;
      req.inputData.networkIds.networkId = this._appSession?.searchParams?.plan?.networkList?.length ? this._appSession.searchParams.plan.networkList : this.networks.networkList;
      req.inputData.addressInfo.forEach((address) => {
        address.zipCd = zipcode;
        address.countyCd = this._appSession?.searchParams?.countyCode || '';
        address.stateCd = this._appSession?.searchParams?.stateCd?.code || '';
        address.addressLine1 = '';
        address.addressLine2 = '';
      });
      this._providerUtilityService.validateServiceArea(req).then(
        (result: IServiceAreaValidationResponse) => {
          this.filterZipcode(result, zipcode);
        },
        (error: any) => {
          try {
            this.showApiError(error);
          } catch (e) {}
        }
      );
    }
  }

  async getCountySavList(zipcode: string) {
    if (zipcode !== '') {
      this.zipcodeDisplay = zipcode;
      this.countyDisplay = this.commonContent?.labels?.countyLabel;
      let req = {} as IServiceAreaValidationRequest;
      req.inputData = {} as IInputData;
      req.inputData.networkIds = {} as INetworkId;
      req.inputData.addressInfo = [{}] as Array<IAddressInfoReq>;
      req.inputData.srcSystem = this._appSession.metaData.brandCd;
      req.inputData.networkIds.networkId = this._appSession?.searchParams?.plan?.networkList?.length ? this._appSession.searchParams.plan.networkList : this.networks.networkList;
      req.inputData.addressInfo.forEach((address) => {
        address.zipCd = zipcode || '';
        address.countyCd = '';
        address.stateCd = this._appSession?.searchParams?.stateCd?.code || '';
        address.addressLine1 = '';
        address.addressLine2 = '';
      });
      this._providerUtilityService.validateServiceArea(req).then(
        (result) => {
          this.filterCounty(result, zipcode);
        },
        (error: any) => {
          try {
            this.showApiError(error);
          } catch (e) {}
        }
      );
    }
  }

  validateCountyIsSav(countyCd: string): void {
    if (countyCd !== '') {
      this.isCbChecked = false;
      let selectedCounty = this.county?.options?.filter((county: ICountyDropDownOptions) => county?.value === countyCd)[0];
      this.countyDisplay = selectedCounty?.label;
      if (selectedCounty?.savOutput === SERVICE_AREA_VALIDATION_SUCCESS_CODE) {
        this.isSAVFailed = false;
        this.continueDisabled = false;
      } else {
        this.isSAVFailed = true;
        this.continueDisabled = true;
      }
    }
  }

  validateIsSav(result: Array<ICountyResponse>): boolean {
    if (result?.length) {
      return result.every((value) => value?.savOutput === SERVICE_AREA_VALIDATION_SUCCESS_CODE);
    }
  }

  filterValues(result: Array<IAddressInfoRes>, zipcode: string): void {
    let filterZipcode = result.filter((x) => x.zipCd === zipcode);
    this.isSAVFailed = false;
    this.countyEnabled = true;
    this.buildCountyDropDown(filterZipcode);
  }

  filterZipcode(result: IServiceAreaValidationResponse, zipcode: string): void {
    result?.outputData?.addressInfo?.forEach((addressInfo) => {
      if (addressInfo.zipCd === zipcode) {
        if (addressInfo?.savInfo?.some((value) => value?.savOutput !== SERVICE_AREA_VALIDATION_SUCCESS_CODE) || !addressInfo?.savInfo?.length) {
          this.isSAVFailed = true;
          this.continueDisabled = true;
          this.isCbChecked = false;
        } else {
          this.isSAVFailed = false;
          this.continueDisabled = false;
        }
      }
    });
  }

  filterCounty(result: IServiceAreaValidationResponse, zipcode: string): void {
    this.countyEnabled = false;
    this.isSAVFailed = false;
    this.continueDisabled = true;
    result?.outputData?.addressInfo?.forEach((addressInfo) => {
      if (addressInfo.zipCd === zipcode) {
        if (addressInfo?.savInfo?.every((value) => value?.savOutput === SERVICE_AREA_VALIDATION_SUCCESS_CODE)) {
          this.isSAVFailed = false;
          this.continueDisabled = false;
        } else if (addressInfo?.savInfo?.some((value) => value?.savOutput === SERVICE_AREA_VALIDATION_SUCCESS_CODE)) {
          this.countyEnabled = true;
          this.buildCountyBasedDropDown(addressInfo);
          this.isSAVFailed = false;
          this.continueDisabled = true;
        } else if (addressInfo?.savInfo?.every((value) => value.savOutput !== SERVICE_AREA_VALIDATION_SUCCESS_CODE)) {
          this.countyEnabled = true;
          this.buildCountyBasedDropDown(addressInfo);
        } else {
          this.isSAVFailed = true;
          this.isCbChecked = false;
        }
      }
    });
  }

  getCommonImagePath(imageName: string): string {
    return this.getCommonImageURL(imageName);
  }

  reloadSAVPage() {
    this._appSession.serviceAreaValidated = false;
    this.continueDisabled = true;
    this.isSAVFailed = false;
    this.countyEnabled = false;
    this.zipcodeEnabled = false;
    this.zipcodeDisplay = this.commonContent?.labels?.zipcodeLabel;
    this.countyDisplay = this.commonContent?.labels?.countyLabel;
    this.showPageProgress = true;
    this.setValues();
  }
}
