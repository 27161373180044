import { Injectable } from '@angular/core';
import { apiNameList } from '../../../../environments/api-name-list';
import { HttpMethod } from '../../../common/enums/httpMethodEnum';
import { HttpClientService } from '../../../common/services/httpClientService';
import { FcLabel } from '../contents/labels';
import { Content } from '../enums/content';
import { BaseService } from './../../../common/services/baseService';
import { AppUtility } from './../../../common/utilities/appUtil';
import { AppSession } from './../../../common/values/appSession';

@Injectable({
  providedIn: 'root'
})
export class ContentService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  async getContent(contentName: Content): Promise<FcLabel> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Get,
      url: this.baseURL + apiNameList.restApi.content,
      headers: this.getHeaders(),
      urlParams: [
        {
          name: 'name',
          value: contentName,
          isQueryParam: true
        },
        {
          name: 'locale',
          value: this.appSession.metaData.locale,
          isQueryParam: true
        }
      ]
    });
  }
}
