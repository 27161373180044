import { Injectable } from '@angular/core';
import { PROVIDER_CATEGORY_CODE } from '../../common/constants/providerSearchConstants';
import { ERR_MBR_AGE_NOT_IN_RANGE, SOURCE_SYSTEM } from './../../../common/constants/app-constants';
import { IException } from './../../../common/interfaces/iAppExceptions';
import { IBaseCode } from './../../../common/interfaces/iBaseCode';
import { IMbrAgeLimitRequest, IMbrAgeLimitResp } from './../../../common/interfaces/iMbrAgeLimit';
import { BOTH_COVERAGES } from './../../../common/interfaces/iPcpRequest';
import { ApiHelper } from './../../../common/services/apiHelper';
import { DataHelper } from './../../../common/services/dataHelper';
import { MbrAgeLimitService } from './../../../common/services/mbrAgeLimitSvc';
import { IPcpEligibleMemberContract } from './../models/iMemberPcpDetails';
import { MemberPcpDetailsApiHelper } from './memberPcpDetailsApiHlpr';

@Injectable()
export class MbrAgeLimitApiHelper extends ApiHelper<IMbrAgeLimitRequest, IMbrAgeLimitResp> {
  private _resp: IMbrAgeLimitResp;

  constructor(
    private _mbrAgeLimitSvc: MbrAgeLimitService,
    private _mbrPcpDtlsApi: MemberPcpDetailsApiHelper,
    private _dataHlpr: DataHelper
  ) {
    super();
  }
  callAPi(professional: { providerCategoryCode: IBaseCode; taxonomies: string[] }): Promise<IMbrAgeLimitResp> {
    if (!professional) {
      return Promise.reject(new Error('Invalid provider detail'));
    }
    // TODO : Write code to support both Active and Future contracts in Sprint 232.4
    const contract = this._mbrPcpDtlsApi.getContractByStatus(BOTH_COVERAGES);
    const isIndividual = this._dataHlpr.areEqualStrings(professional.providerCategoryCode?.code, PROVIDER_CATEGORY_CODE.Individual);
    const isWGS = this._dataHlpr.areEqualStrings(contract.sourceSystem, SOURCE_SYSTEM.WGS);
    const isISG = this._dataHlpr.areEqualStrings(contract.sourceSystem, SOURCE_SYSTEM.STAR);
    const request = {
      underwritingStateCd: contract.underwritingStateCode,
      taxonomies: professional.taxonomies
    };
    if (isIndividual && (isWGS || isISG) && this._mbrPcpDtlsApi.isSingleContract) {
      //When: Individual provider from WGS and ISG markets, call the remote api to get member-age-range
      return this.invoke(request);
    } else {
      //When: Not an Individual provider and Source system is other than WGS and ISG
      //Then: Do not call member-age-range API, instead have a local response, so that the age range check always passes.
      this.isProgress = false;
      this.isFailure = false;
      this.isSuccess = true;
      const response = {
        lowAgeRangeValue: 0,
        highAgeRangeValue: 999
      };
      this.onSuccess(response, request);
      return Promise.resolve(response);
    }
  }

  /**
   *
   * @deprecated Do not use this function.
   */
  validateAge() {
    //
    const excpn: IException = { code: ERR_MBR_AGE_NOT_IN_RANGE, message: '' };
    //When: API had failed
    if (this.isFailure) {
      throw excpn;
    }

    //When: API had not returned correct values
    const isAgeRangeMissingInDB = this._resp.lowAgeRangeValue === 0 && this._resp.highAgeRangeValue === 0;
    if (isAgeRangeMissingInDB) {
      throw excpn;
    }

    let age = 0;
    if (this._mbrPcpDtlsApi.isSingleContract) {
      let contract: IPcpEligibleMemberContract = this._mbrPcpDtlsApi.getContractByStatus(BOTH_COVERAGES);
      age = contract.members[0].age;
    }
    const isWithinRange = age >= this._resp.lowAgeRangeValue && age <= this._resp.highAgeRangeValue;
    if (!isWithinRange) {
      throw excpn;
    }
  }

  onSuccess(resp: IMbrAgeLimitResp, req?: IMbrAgeLimitRequest): void {
    this.validateResp(resp);
    this._resp = resp;
  }

  onFailure(errResp: any): void {
    this._resp = null;
  }

  protected execute(request: IMbrAgeLimitRequest): Promise<IMbrAgeLimitResp> {
    return this._mbrAgeLimitSvc.getMemberAgeLimit(request);
  }

  private validateResp(resp: IMbrAgeLimitResp) {
    if (isNaN(resp.highAgeRangeValue) || isNaN(resp.lowAgeRangeValue)) {
      throw new Error('Invalid response from member-age-limit API');
    }
  }
}
