import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { Subscription } from 'rxjs';
import { MemberPlanProgressService } from '../../../../../common/components/app-secure/services/memberPlanProgressSvc';
import { FindCareWebIntegrationClient, Locale } from '../../../../../common/constants/app-constants';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { DOLLAR_SYMBOL } from '../../../constants/common';
import { IAppContract } from '../../../interfaces/iAppContract';
import { IContract } from '../../../interfaces/iContract';
import { IMemberPlanProgressRequest } from '../../../interfaces/iMemberPlanProgressRequest';
import { FormattedMbrPlanProgress, IMemberPlanProgressResp } from '../../../interfaces/iMemberPlanProgressResponse';
import { TransformMemberPlanProgressPipe } from '../../../pipes/transformMemberPlanProgressPipe';
import { BootstrapService } from '../../../store/bootstrapSvc';
import { BaseComponent } from '../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-member-plan-progress-cmp',
  templateUrl: './memberPlanProgressCmp.html'
})
export class MemberPlanProgressComponent extends BaseComponent implements OnInit, OnDestroy {
  formattedMbrPlanProgressList: FormattedMbrPlanProgress[];
  currentIndex: number = 0;
  dollarSymbol: string = DOLLAR_SYMBOL;
  private _contractSubscription: Subscription;
  activeMember: IContract;
  showLoader: boolean = false;

  constructor(
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _bootstrapService: BootstrapService,
    private _memberPlanProgressSvc: MemberPlanProgressService,
    private _transformMemberPlanProgressPipe: TransformMemberPlanProgressPipe
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }

    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      this.activeMember = appContract?.selectedContract;
      this.getMemberPlanProgressDetails();
    });
  }

  getMemberPlanProgressDetails() {
    try {
      if (
        this.isSecureState &&
        ![
          FindCareWebIntegrationClient.PEGAWGS.valueOf(),
          FindCareWebIntegrationClient.EMPLOYER.valueOf(),
          FindCareWebIntegrationClient.HIP.valueOf(),
          FindCareWebIntegrationClient.ASPEN.valueOf(),
          FindCareWebIntegrationClient.SYDNEYMEDICAID.valueOf()
        ].includes(this._appSession?.deeplinkParams?.client)
      ) {
        this.showLoader = true;
        const memberplanProgressRequest: IMemberPlanProgressRequest = {
          mbrUid: this.activeMember.mbrUid,
          contractUid: this.activeMember.contractUid
        };
        this._memberPlanProgressSvc.getMemberPlanProgressData(memberplanProgressRequest).then(
          (planProgressResp: IMemberPlanProgressResp) => {
            this.currentIndex = 0;
            this.showLoader = false;
            if (!isEmpty(planProgressResp)) {
              this.formattedMbrPlanProgressList = this._transformMemberPlanProgressPipe.transform(planProgressResp, this.content, this._appSession?.metaData?.locale ? this._appSession.metaData.locale.toString() : Locale.ENGLISH
              );
            } else {
              this.formattedMbrPlanProgressList = [];
            }
          },
          (error: any) => {
            this.formattedMbrPlanProgressList = [];
            this.showLoader = false;
          }
        );
      }
    } catch (error) {
      this.formattedMbrPlanProgressList = [];
      this.showLoader = false;
      console.error(error);
    }
  }

  previousMbrBenefit() {
    this.currentIndex--;
    if (this.currentIndex < 0) {
      this.currentIndex = this.formattedMbrPlanProgressList.length - 1;
    }
  }

  nextMbrBenefit() {
    this.currentIndex++;
    if (this.currentIndex >= this.formattedMbrPlanProgressList.length) {
      this.currentIndex = 0;
    }
  }

  getCommonImagePath(imageName: string): string {
    return this.getCommonImageURL(imageName);
  }

  calculatePercentage(): number {
    const limit = Number(this.formattedMbrPlanProgressList[this.currentIndex].limit?.replace(/,/g, ''));
    const spent = Number(this.formattedMbrPlanProgressList[this.currentIndex].spent?.replace(/,/g, ''));
    if (isNaN(limit) || limit === 0) {
      return 0;
    }
    return (spent / limit) * 100;
  }

  ngOnDestroy(): void {
    if (this._contractSubscription) {
      this._contractSubscription.unsubscribe();
    }
  }
}
