import { Inject, Injectable } from '@angular/core';
import { MEDICAID_BRAND_CODE } from '../constants/app-constants';
import { IAppContract } from '../interfaces/iAppMetadata';
import { LocationResponse } from '../models/locationAddress';
import { HostnameService } from '../services/hostNameService';
import { IDeeplink } from './../components/app-secure/models/iDeeplink';
import { AppConstants } from './../constants/app-constants';
import { IWindow } from './../interfaces/iWindow';
import { AppSession } from './../values/appSession';

@Injectable()
export class AppUtility {
  private _urlParsingNode = window.document.createElement('a');

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    @Inject('Window')
    private _window: IWindow,
    private _hostnameService: HostnameService
  ) {}

  scrollToTop() {
    if (this._window && typeof this._window.scrollTo !== 'function') {
      return;
    }

    try {
      const el = !this._appSession.appState.opsIndicator?.isFcrEnabled ? document.getElementById('fad-position-to-scroll') : undefined;
      if (el) {
        if (this._appSession.state === 'secure') {
          let scrollDiv: number = 0;

          scrollDiv = el.offsetTop - 140;

          this.scrollToWindow(scrollDiv);
        } else {
          // DSEPRECARE-11972 Page is scrolled to the top and showing Find a Doctor
          let offsetTop: number = 140;

          if (this._window.isIE === true) {
            this._window.scrollTo(0, offsetTop);
          } else {
            setTimeout(() => {
              this.scrollToWindow(offsetTop);
            }, 400);
          }
        }
      } else {
        this.scrollToWindow(0);
      }
    } catch (e) {
      // TODO: Chromium 55 do not supporting scrollTo method.
    }
  }

  getBrandFromUrl() {
    const hostName = this._hostnameService.getHostname();
    let brand = 'ABCBS';
    let index: number;
    if (MEDICAID_BRAND_CODE.includes(this._appSession?.metaData?.brandCd)) {
      index = AppConstants.MEDICAID_BRAND.findIndex((x) => hostName.toLocaleLowerCase().indexOf(x.hostname.toLocaleLowerCase()) >= 0);
      if (index >= 0) {
        brand = AppConstants.MEDICAID_BRAND[index].brand;
      }
    } else {
      index = AppConstants.BRAND.findIndex((x) => hostName.toLocaleLowerCase().indexOf(x.hostname.toLocaleLowerCase()) >= 0);
      if (index >= 0) {
        brand = AppConstants.BRAND[index].brand;
      }
    }
    return brand;
  }

  /**
   * Identify browser software is Webkit based browser
   */
  isWebkitBrowser() {
    if (this.getBrowserName() === 'other') {
      return true;
    }
    return false;
  }

  /**
   * Identify browser name using user agent
   */
  getBrowserName() {
    try {
      const agent = window.navigator.userAgent.toLowerCase();

      switch (true) {
        case agent.indexOf('edge') > -1:
          return 'edge';
        case agent.indexOf('opr') > -1 && !!(window as any).opr:
          return 'opera';
        case agent.indexOf('chrome') > -1 && !!(window as any).chrome:
          return 'chrome';
        case agent.indexOf('trident') > -1:
          return 'ie';
        case agent.indexOf('firefox') > -1:
          return 'firefox';
        case agent.indexOf('safari') > -1:
          return 'safari';
        default:
          return 'other';
      }
    } catch (e) {
      // TODO:
    }
    return 'other';
  }

  /**
   * Reset app to public state if user selects BACK button from search criteria page
   * and any of the below conditions match
   */
  resetAppToPublicState() {
    if (this._appSession.isDeepLink === true) {
      const client = this._appSession.deeplinkParams.client;
      const deeplinkUrl = this._appSession.deeplinkParams.deeplinkUrl;
      this._appSession.isDeepLink = false;
      this._appSession.deeplinkParams = {} as IDeeplink;
      this._appSession.deeplinkParams.client = client;
      this._appSession.deeplinkParams.deeplinkUrl = deeplinkUrl;
    } else if (this._appSession.isSecureState === true) {
      this._appSession.landingTab = undefined;
      // this._appSession.provDataModifiedOn = '';
      //reset to public state for HCID search - DSEPRECARE-21132
      if (this._appSession.isIdentifactionSearch) {
        this._appSession.isSecureState = false;
      }
      // Reset to public state incase of error or no response from member summary
      const isAppContractNull = this._appSession.metaData.appContract && Object.keys(this._appSession.metaData.appContract).length === 0 ? true : false;
      // Reset to public state incase memberList is not present
      const isMemberListEmpty =
        !isAppContractNull && (this._appSession.metaData.appContract.memberList.length === 0 || typeof this._appSession.metaData.appContract.memberList === 'undefined') ? true : false;

      if (this._appSession.isPlanUnknown && (isMemberListEmpty || isAppContractNull)) {
        this._appSession.isSecureState = false;
        this._appSession.searchParams = undefined;
        // Clear member contract variables
        this._appSession.metaData.appContract = {} as IAppContract;
        this._appSession.hasEYC = false;
        this._appSession.hasDentalEYC = false;
      }
      this._appSession.hasRxCvsIndicator = false;
      this._appSession.hasRxEsiIndicator = false;
      this._appSession.isDentalStandAlone = false;
      this._appSession.isVisionStandAlone = false;
      this._appSession.hasPharmacyPlan = false;
      this._appSession.sourceSystem = '';
    } else if (typeof this._appSession.searchParams !== 'undefined' && this._appSession.searchParams !== null && this._appSession.searchParams.identificationNumber) {
      this._appSession.searchParams.identificationNumber = '';
    }
  }

  saveVcard(content: any, filename: string) {
    const file = new Blob([content], { type: 'text/vcard' });
    //Download for other browsers
    const lnkDownload = document.createElement('a');
    lnkDownload.style.display = 'none';
    const fileURL = URL.createObjectURL(file);
    lnkDownload.href = fileURL;
    lnkDownload.download = filename + `.vcf`;
    document.body.appendChild(lnkDownload);
    lnkDownload.click();
    // To make work with Firefox
    setTimeout(() => {
      document.body.removeChild(lnkDownload);
      window.URL.revokeObjectURL(fileURL);
    }, 100);
  }

  buildLocationResponse(geo: any): string[] {
    let objLocationdetail: LocationResponse;
    const locationArray: LocationResponse[] = [];
    if (geo && Object.keys(geo).length > 0) {
      ((geo && Object.keys(geo)) || []).forEach((key) => {
        if (geo[key]) {
          objLocationdetail = new LocationResponse();
          objLocationdetail.name = geo[key].name;
          objLocationdetail.lat = geo[key].latitude;
          objLocationdetail.long = geo[key].longitude;
          objLocationdetail.city = geo[key].city;
          objLocationdetail.stateCd = geo[key].stateCd;
          objLocationdetail.county = geo[key].county;
          locationArray.push(objLocationdetail);
        }
      });
    }
    return this.geoRespProcess(locationArray);
  }

  private geoRespProcess(result: any): string[] {
    const geoLocationList: any = [];
    if (result && Object.keys(result).length > 0) {
      for (let i = 0; i < result.length; i++) {
        geoLocationList[result[i].name] = result[i];
      }
    }
    return geoLocationList;
  }

  private scrollToWindow(offsetTop: number) {
    this._window.scrollTo({ top: offsetTop, left: 0, behavior: 'smooth' });
  }

  static strToBoolean(key: string | boolean): boolean {
    if (typeof key === 'boolean') {
      return key;
    }
    if (typeof key !== 'undefined' && key !== null) {
      switch (key.toLowerCase().trim()) {
        case 'true':
          return true;
        case 'false':
          return false;
        default:
          return false;
      }
    } else {
      return false;
    }
  }
}
