export const MemberPlanProgressLabels_en = {
  IND_DEDUCTIBLE_IN: 'In-Network Deductible',
  IND_OUTOFPOCKET_IN: 'In-Network Out-of-Pocket Max',
  IND_DEDUCTIBLE_OUT: 'Out-of-Network Deductible',
  IND_OUTOFPOCKET_OUT: 'Out-of-Network Out-of-Pocket Max',
  FAM_DEDUCTIBLE_IN: 'Family In-Network Deductible',
  FAM_OUTOFPOCKET_IN: 'Family In-Network Out-of-Pocket Max',
  FAM_DEDUCTIBLE_OUT: 'Family Out-of-Network Deductible',
  FAM_OUTOFPOCKET_OUT: 'Family Out-of-Network Out-of-Pocket Max',
  SPENDING_ACCOUNT_HSA: 'Health Savings Account (HSA)',
  SPENDING_ACCOUNT_HRA: 'Health Reimbursement Arrangement (HRA)',
  SPENDING_ACCOUNT_FSA: 'Flexible Spending Account (FSA)',
  SPENDING_ACCOUNT_LPFSA: 'Limited Purpose Flexible Spending Account (LPFSA)',
  SPENDING_ACCOUNT_HRA_WAGEWORKS: 'HealthEquity (HRA)',
  SPENDING_ACCOUNT_FSA_WAGEWORKS: 'HealthEquity (FSA)'
};

export const MemberPlanProgressLabels_es = {
  IND_DEDUCTIBLE_IN: 'Deducible dentro de la red',
  IND_OUTOFPOCKET_IN: 'Máximo de gastos de bolsillo dentro de la red',
  IND_DEDUCTIBLE_OUT: 'Deducible fuera de la red',
  IND_OUTOFPOCKET_OUT: 'Máximo de gastos de bolsillo fuera de la red',
  FAM_DEDUCTIBLE_IN: 'Deducible familiar dentro de la red',
  FAM_OUTOFPOCKET_IN: 'Máximo de gastos de bolsillo familiar dentro de la red',
  FAM_DEDUCTIBLE_OUT: 'Deducible familiar fuera de la red',
  FAM_OUTOFPOCKET_OUT: 'Máximo de gastos de bolsillo fuera de la red',
  SPENDING_ACCOUNT_HSA: 'Cuenta de ahorros para la salud (HSA)',
  SPENDING_ACCOUNT_HRA: 'Acuerdo de reembolso de gastos médicos (HRA)',
  SPENDING_ACCOUNT_FSA: 'Cuenta de gastos flexibles (FSA)',
  SPENDING_ACCOUNT_LPFSA: 'Cuenta de gastos flexibles de propósito limitado (LPFSA)',
  SPENDING_ACCOUNT_HRA_WAGEWORKS: 'HealthEquity (HRA)',
  SPENDING_ACCOUNT_FSA_WAGEWORKS: 'HealthEquity (FSA)'
};

export const BenefitType = {
  ERROR: 'error',
  BENEFIT: 'benefit',
  EXTERNALBENEFIT: 'external'
};

export const BenefitTypeKey = {
  SPENDING: 'SPENDING',
  FAMILY: 'FAM',
  IND: 'IND'
};

export const VendorUrl = {
  WAGEWORKS: 'https://www.healthequity.com/wageworks'
}
