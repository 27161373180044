import { Component, HostListener, Inject, OnInit } from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { IntentScenario } from '../../../../common/enums/intent';
import { EventHandler } from "../../../../common/services/eventHandler";
import { RouteUtil } from "../../../../common/utilities/routeUtil";
import { AppSession } from "../../../../common/values/appSession";
import { ContentHelper } from "../../../../common/values/contentHelper";
import { SydneyChatbotEventChannelUtil } from '../../../../fad/utilities/sydneyChatbotEventChannelUtil';
import { PAGES } from "../../../enums/pages";
import { IPageAlert } from "../../../interfaces/iScenarioAlert";
import { BaseComponent } from "../../base-component/baseCmp";
import { ScenarioAlertSvc } from "../services/pfScenarioAlertSvc";


@Component({
  moduleId: module.id,
  selector: 'app-pf-scenario-alert-cmp',
  templateUrl: './pfScenarioAlertCmp.html',
  providers: []
})
export class PfScenarioAlertComponent extends BaseComponent implements OnInit {
  private scenarioALertListChangeSubscription: Subscription;
  scenarioAlertList: IPageAlert[] = [];
  path: string = '';

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    @Inject(ContentHelper)
    _contentHelper: ContentHelper,
    private _scenarioAlertSvc: ScenarioAlertSvc,
    private _routeUtil: RouteUtil,
    private sanitizer: DomSanitizer
  ) {
    super(_route, _eventHandler, _appSession, _contentHelper);
    this.scenarioALertListChangeSubscription = this._scenarioAlertSvc.onScenarioAlertListChange.subscribe((list) => {
      this.onScenarioAlertListChange(list);
    })
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.path = this._routeUtil.getResolvedUrl(this._route.snapshot);
  }

  ngOnDestroy() {
    if (typeof this.scenarioALertListChangeSubscription !== 'undefined' && this.scenarioALertListChangeSubscription !== null) {
      this.scenarioALertListChangeSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.onScenarioAlertListChange();
  }

  getMessage(index: number) {
    return this.sanitizer.bypassSecurityTrustHtml(this.scenarioAlertList[index].message);
  }

  // ONLY ASK FOR THE CLOSE TAG FOR THE BARIATRIC ALERT
  getHomeDepotAlertCloseTag(index: number) {
    if (this.scenarioAlertList[index].id === 'TP_BARIATRIC_REPLACEMENT_ALERT' || this.scenarioAlertList[index].id === 'TP_BARIATRIC_ALERT') {
      return 'closeLanternAlertHomeFindCare';
    }
    return '';
  }

  onScenarioAlertListChange(list?: IPageAlert[]) {
    const page = PAGES[(this.path.replace('/', '') || '').toLocaleUpperCase()];
    this.scenarioAlertList = this._appSession.scenarioAlerts.filter((alrt) => alrt.pages.includes(page));
  }

  dismissAlert(id: string) {
    this.scenarioAlertList = this.scenarioAlertList.filter((scenarioAlert) => scenarioAlert.id !== id);
  }

  @HostListener('click', ['$event']) onSearchClick(event) {
    if (event?.target?.className === 'navigateToChatBtn') {
      SydneyChatbotEventChannelUtil.publishEventForIntent(IntentScenario.BH_NON_CRISIS, this._appSession.bhNonCrisisIntentQuery, this._appSession, this._eventHandler);
    }
  }

}
