export enum FindCareFlag {
  ANP_ELIGIBLE_SRC = 'ANP_ELIGIBLE_SRC',
  COLD_TCC_ENABLED = 'COLD_TCC_ENABLED',
  CA_HMO = 'CA_HMO',
  CDHP_PRODUCT = 'CDHP_PRODUCT',
  DENTAL_COVERAGE = 'DENTAL_COVERAGE',
  DENTAL_STANDALONE_CONTRACT = 'DENTAL_STANDALONE_CONTRACT',
  DENTAL_TCC_ENABLED = 'DENTAL_TCC_ENABLED',
  FCR = 'FCR',
  HAS_VPC = 'HAS_VPC',
  INITIATE_MEMBER_SELECTION = 'INITIATE_MEMBER_SELECTION',
  JAA_PRODUCT = 'JAA_PRODUCT',
  MEDICAL_COVERAGE = 'MEDICAL_COVERAGE',
  MEDICAL_STANDALONE_CONTRACT = 'MEDICAL_STANDALONE_CONTRACT',
  MSG_LIVE_CHAT = 'MSG_LIVE_CHAT',
  MSG_SEARCH_OON = 'MSG_SEARCH_OON',
  NATIONAL_PRODUCT = 'NATIONAL_PRODUCT',
  NSSS_PLAN_PROGRESS = 'NSS_PLAN_PROGRESS',
  OOP_ELIGIBLE = 'OOP_ELIGIBLE',
  PCP_CAPABLE = 'PCP_CAPABLE',
  PPO = 'PPO',
  RX_COVERAGE = 'RX_COVERAGE',
  RX_CVS = 'RX_CVS',
  RX_STANDALONE_CONTRACT = 'RX_STANDALONE_CONTRACT',
  SOCT_COE = 'SOCT_COE',
  TCC_SEARCH_ENABLED = 'TCC_SEARCH_ENABLED',
  VISION_COVERAGE = 'VISION_COVERAGE',
  VISION_PLUS = 'VISION_PLUS',
  VISION_STANDALONE_CONTRACT = 'VISION_STANDALONE_CONTRACT',
  COUPE_HEALTH_ONLY_PRODUCT = 'COUPE_HEALTH_ONLY_PRODUCT',
  MSG_COUPE_HEALTH_PRODUCT = 'MSG_COUPE_HEALTH_PRODUCT',
  BH_INTENT_RESOLUTION = 'BH_INTENT_RESOLUTION'
}

export enum ActionKey {
  MEMBER_MODAL_DURING_INITIALIZATION = 'member-modal'
}
