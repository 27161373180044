import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Locale } from '../../../common/constants/app-constants';
import { EventHandler } from '../../../common/services/eventHandler';
import { AppSession } from '../../../common/values/appSession';
import { BaseComponent } from '../../common/components/core/baseCmp';
import { ProgramCategory } from '../../common/constants/common';
import { PREFIX_TP } from '../../common/constants/providerSearchConstants';
import { ICard, ICardItem, ICardRequest, ICardResponse } from '../../common/interfaces/iProgramCard';
import { ProgramCardService } from '../../common/services/programCardSvc';
import { IMapLocation } from '../interfaces/iMap';

@Injectable({
  providedIn: 'root'
})
export class ProgramCardHandler extends BaseComponent {
  locationCoordinates: IMapLocation;
  cardContent: { [key: string]: any } = {};
  programCards: ICardItem[] = [];
  constructor(
    private _programCardSvc: ProgramCardService,
    @Inject(AppSession)
    private _appSession: AppSession,
    route: ActivatedRoute,
    private _eventHandler: EventHandler
  ) {
    super(route, _eventHandler, _appSession);
  }

  /** Method to get the program card
   * @param locationCoordinates Location coordinates.
   * @param _cardContent Card content.
   * @returns Program card items.
   */
  async getProgramCard(locationCoordinates: IMapLocation, _cardContent: any): Promise<ICardItem[]> {
    try {
      this.locationCoordinates = locationCoordinates;
      this.cardContent = _cardContent;
      const programCardRequest = this.buildRequest();
      const apiResponse = await this._programCardSvc.getProgramCards(programCardRequest);
      this.tranformProgramCardResponse(apiResponse);
      return this.programCards;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /** Method to build the program card request */
  private buildRequest(): ICardRequest {
    const { searchParams, metaData } = this._appSession;
    const programCardRequest: ICardRequest = {
      mbrUid: metaData?.mbrUid ?? '',
      contractUid: metaData?.contractUid ?? '',
      latitude: this.locationCoordinates?.latitude ?? '',
      longitude: this.locationCoordinates?.longitude ?? '',
      ableToServeAsPcp: searchParams?.ableToServePcp ?? false,
      specialities: searchParams?.specialtySelectNm?.length ? searchParams?.specialtySelectNm : [],
      taxonomies: searchParams?.taxonomySelectNm?.length ? searchParams?.taxonomySelectNm : [],
      procedureCode: searchParams?.eycSearchParam?.eycProcedureCriteria?.procedureCode ?? '',
      providerTypes: searchParams?.providerTypeCodeList?.length ? searchParams?.providerTypeCodeList : [],
      countyCode: searchParams?.countyCode ?? '',
      locale: metaData.locale || Locale.ENGLISH
    };

    return programCardRequest;
  }

  /** Transform the program card response
   * @param response Program card api response.
   */
  tranformProgramCardResponse(response: ICardResponse): void {
    this.programCards = [];
    this.updateProgramCardsByCategory(ProgramCategory.CLINICAL, response?.clinicalPrograms);
    this.updateProgramCardsByCategory(ProgramCategory.THIRD_PARTY, response?.thirdPartyPrograms);
    this.updateProgramCardsByCategory(ProgramCategory.ONSITE_CLINICS, response?.onsiteClinics);
    // update this.programCards with sorted data in category order
    if (this.programCards.length > 0) {
      this.programCards.sort((a, b) => {
        return this.pgmCategoryOrder[a.category] - this.pgmCategoryOrder[b.category];
      });
    }
  }

  /** Private property to hold the program category order */
  private pgmCategoryOrder: { [key: string]: number } = {
    [ProgramCategory.CLINICAL]: 1,
    [ProgramCategory.THIRD_PARTY]: 2,
    [ProgramCategory.ONSITE_CLINICS]: 3
  };

  /** Method to prepare the provider card
   * @param category Program category.
   * @param programs List of programs.
   */
  private updateProgramCardsByCategory(category: string, programs: ICard[]): void {
    if (programs?.length > 0) {
      for (const program of programs) {
        let _programCard = this.programCards.find((p) => {
          return p.id === program?.id && p.category === category && p.programName === program?.name;
        });

        if (!_programCard) {
          const newProgramCard = this.createProgramCard(category, program);
          this.programCards.push(newProgramCard);
        }
      }
    }
  }

  /** Method to create the program card
   * @param category Program category.
   * @param program Program data.
   * @returns Program card item.
   */
  private createProgramCard(category: string, program: ICard): ICardItem {
    let _description: string = '';
    let imgSrc: string = '';
    imgSrc = category === ProgramCategory.CLINICAL ? `${PREFIX_TP}${program?.id}.png` : (program?.image ?? '');
    if (imgSrc) {
      imgSrc = category === ProgramCategory.THIRD_PARTY ? this.getSemImageURL(imgSrc) : this.getCommonImageURL(imgSrc);
    }
    const aLabel = this.cardContent['ariaLabel'];
    if (category === ProgramCategory.ONSITE_CLINICS) {
      _description = program?.description ?? this.cardContent['onsiteClinicText'];
    } else {
      _description = program?.description ?? '';
    }

    return {
      id: program?.id ?? '',
      image: imgSrc,
      description: _description ?? '',
      ariaLabel: aLabel?.replace('{PROGRAM_NAME}', program?.name),
      category: category,
      dataAnalytics: program?.dataAnalytics ?? '',
      programName: program?.name ?? '',
      details: program
    };
  }
}
