export interface IAffiliationOption {
  providerIdentifier: string;
  providerName: string;
  addressSummary: IAddressSummary;
  extendedProviderInfo?: ExtendedProviderInfo;
}

export interface IAddressSummary {
  addressIdentifier: string;
  addressLine1Text: string;
  addressLine2Text: string;
  cityName: string;
  stateCode: IStateCode;
  postalCode: string;
  numberOfAddresses?: number;
}

export interface IStateCode {
  code: string;
  name: string;
}

export interface IAddressLocationRequest {
  providerIdentifier: string;
  providerTypeCode: string;
  numberOfAddresses?: string;
  latitude?: string;
  longitude?: string;
  radius?: number;
  countyCode?: string;
}

export interface IAddressSummaryResp extends IAddressSummary {
  phone: string;
  affiliationName: string;
}
export class AddressLocationResponse {
  locationsHeadLine?: string;
  addressList: Array<IAddressSummaryResp>;
}

export class ExtendedProviderInfo {
  isEligibleForHealthSystem?: boolean;
}
