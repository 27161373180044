import { Inject, Injectable } from '@angular/core';
import { ISelectedProvider } from '../../../app/fad/provider-details/models/iSelectedProvider';
import { IRulesResponse } from '../../../app/fad/rules/interfaces/iRulesResponse';
import { ICptLookUpList } from '../../../app/fad/search-providers/interfaces/iCptLookup';
import { CptLookupService } from '../../../app/fad/search-providers/services/cptUtilitySvc';
import { IGenericDataResponse } from '../../fad/search-providers/interfaces/iGenericDataResponse';
import { IPublicPlan } from '../../fad/search-providers/interfaces/iPublicPlan';
import { ILinkProcedure } from '../../fad/search-providers/interfaces/iQuickLinks';
import { ISearchParameters } from '../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from '../../fad/search-providers/services/providerUtilitySvc';
import { FeatureManagerUtility } from '../../findcare/common/utilities/featureManagerUtil';
import { IDeeplink } from '../components/app-secure/models/iDeeplink';
import { Locale, SEARCH_PARAMS, SERVE_PCP_T, SERVE_PCP_Y } from '../constants/app-constants';
import { AppNavigations } from '../constants/app-navigations';
import { SEARCH_SCENARIOS } from '../constants/strategy-search-constant';
import { IAppContract, IAppMetadata } from '../interfaces/iAppMetadata';
import { ICacheRequest } from '../interfaces/iCache';
import { ValidateTaxonomyCodesRequest, ValidateTaxonomyCodesResponse } from '../interfaces/iTaxonomyDeeplink';
import { RouteUtil } from '../utilities/routeUtil';
import { AppSession } from '../values/appSession';
import { ICounty, LocationResponse } from './../../common/models/locationAddress';
import { AppUtility } from './../../common/utilities/appUtil';
import { ProcedureCategoryService } from './../../eyc/cost-search/services/procedureCategorySvc';
import { CacheService } from './../../fad/cache/services/cacheSvc';
import { RulesService } from './../../fad/rules/services/RulesSvc';
import { ICptCode } from './../../fad/search-providers/interfaces/iCptLookup';
import { IPlan, IPlanCategory } from './../../fad/search-providers/interfaces/iPlan';
import { IProcedureCategoryList } from './../../fad/search-providers/interfaces/iQuickLinks';
import { ALL_PROV_TYPE_CODES, MEDICAL_PHARMACY, PROVIDER_SEARCH_DISTANCE, PROVIDER_TYPE, PROVIDER_TYPE_OF_CARE, RETAIL_PHARMACY } from './../../fad/search-providers/values/providerSearchConstants';
import { FOCUS_TYPE, LANDING_TAB_VIEW } from './../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from './../../fad/utilities/commonUtil';
import { ICountyLookupApiResp } from './../models/locationAddress';
import { DataHelper } from './dataHelper';
import { SearchSvc } from './searchSvc';

@Injectable()
export class DeeplinkHandler {
  private _promises: any[] = [];
  constructor(
    private _providerUtilityService: ProviderUtilityService,
    private _ruleService: RulesService,
    private _searchService: SearchSvc,
    private _cacheService: CacheService,
    private _procedureService: ProcedureCategoryService,
    private _routeUtil: RouteUtil,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _appUtility: AppUtility,
    private _cptLookupSvc: CptLookupService,
    private _dataHelper: DataHelper
  ) {}

  get getPromises(): any[] {
    return this._promises;
  }

  set resetPromises(object: any[]) {
    this._promises = object;
  }

  setPublicDeeplinkParams(dplUrl: string) {
    if (dplUrl) {
      dplUrl = decodeURIComponent(dplUrl.replace(/\+/g, '%20'));
      const deeplink = this.findDeeplinkParams(dplUrl);
      this._appSession.deeplinkParams = this.interpretDeeplinkParams(deeplink);
      this._appSession.isDeepLink = this.isDeepLink();

      this.bindsearchParams(deeplink);

      if (deeplink.alphaprefix) {
        this.getAlphaPrefix(deeplink.alphaprefix);
      } else if (deeplink.planstate && deeplink.plantype && deeplink.planname) {
        this.getPlan(deeplink.planstate, deeplink.planname, deeplink.plantype);
      } else if (deeplink.networks) {
        const selectedPlan: IPlan = {
          identifier: '',
          name: 'CUSTOM NETWORK',
          networkList: deeplink.networks.split('|')
        };
        this._appSession.searchParams.plan = selectedPlan;
      }

      this._appSession.searchParams.distanceNm = PROVIDER_SEARCH_DISTANCE;
      this._appSession.initialSearch = true;
      this._appSession.searchParams.cooperativeCareOnly = false;
      this._appSession.searchParams.sosOnly = false;
      this._appSession.searchParams.hasPersonalizedMatch = false;

      this._appSession.networkList = this._appSession?.searchParams?.plan?.networkList;
      const selectedProvider = {
        providerIdentifier: deeplink.provkey,
        providerName: deeplink.provname,
        location: {
          address: {
            addressId: deeplink.adrskey,
            city: deeplink.city,
            state: deeplink.state,
            postalCode: deeplink.zipcode
          }
        }
      } as ISelectedProvider;
      this._appSession.selectedProvider = selectedProvider;
      this.setFocus(deeplink);
    }
  }

  setFocus(deeplink: IDeeplink) {
    if (deeplink && deeplink.focus && deeplink.focus.toUpperCase() === FOCUS_TYPE.GUEST) {
      this._appSession.landingTab = LANDING_TAB_VIEW.GUEST_VIEW;
    } else {
      this._appSession.landingTab = LANDING_TAB_VIEW.MEMBER_VIEW;
    }
  }

  async setSecureDeeplinkParams(dplUrl: string, navigation: AppNavigations) {
    const url = decodeURIComponent(dplUrl.replace(/\+/g, '%20'));
    const deeplink = this.findDeeplinkParams(url);
    this._appSession.deeplinkParams = deeplink;
    if (deeplink && deeplink.cacheKey && this._routeUtil.isSearchResultRoute(navigation)) {
      this.bindCachedSearchParams(deeplink);
    } else {
      await this.bindsearchParams(this._appSession.deeplinkParams?.hcid ? this._appSession.deeplinkParams : deeplink);
    }
  }

  async bindsearchParams(deeplink: any) {
    if (deeplink) {
      const validProvTypeCodes: Array<string> = [];
      if (!this._dataHelper.isEmptyString(deeplink.provtype)) {
        const providerTypeCodes = deeplink.provtype.indexOf('|') === -1 ? [deeplink.provtype.toLocaleUpperCase()] : deeplink.provtype.split('|').map((x) => x.toLocaleUpperCase());
        for (const typeCode of providerTypeCodes) {
          if (ALL_PROV_TYPE_CODES.includes(typeCode.trim())) {
            validProvTypeCodes.push(typeCode);
          }
        }
        if (validProvTypeCodes.length) {
          this._appSession.searchParams.providerTypeCodeList = validProvTypeCodes;
          this._appSession.searchParams.typeSelectNm = validProvTypeCodes[0];

          if (this._dataHelper.areEqualStrings(validProvTypeCodes[0], PROVIDER_TYPE.PHARMACY)) {
            if (CommonUtil.isMemberSecure(this._appSession) && this._appSession.hasPharmacyPlan) {
              this._appSession.searchParams.coverageTypeCode = this._appSession.hasRxCvsIndicator || this._appSession.hasRxEsiIndicator ? RETAIL_PHARMACY : MEDICAL_PHARMACY;
            }
          }
        }
      }
      if (deeplink.provname) {
        this._appSession.searchParams.provNm = deeplink.provname;
      }
      if (deeplink.adrskey) {
        this._appSession.searchParams.adrskey = deeplink.adrskey;
        this._appSession.searchParams.ableToServePcp = true;
      }
      if (deeplink.zipcode) {
        this._appSession.searchParams.zip = deeplink.zipcode;
        this.getGeoCoordinates(deeplink.zipcode);
      }
      if (deeplink.splty) {
        this._appSession.searchParams.specialtySelectNm = [deeplink.splty];
      }
      if (deeplink.city && deeplink.state) {
        const cityState = deeplink.city + ', ' + deeplink.state;

        this._appSession.searchParams.zip = cityState;
        this.getGeoCoordinates(cityState);
      }
      if (deeplink.county && deeplink.state) {
        const countyState = deeplink.county + ', ' + deeplink.state;

        this._appSession.searchParams.zip = countyState;
        this.getGeoCoordinates(countyState);
      }
      if (deeplink.culture && deeplink.culture === 'mx') {
        this._appSession.metaData.locale = Locale.SPANISH;
      }
      if (deeplink.pcponly && [SERVE_PCP_Y, SERVE_PCP_T].includes(deeplink.pcponly.toLocaleLowerCase())) {
        this._appSession.searchParams.ableToServePcp = true;
      }
      if (deeplink.pcpid) {
        this._appSession.searchParams.pcpId = deeplink.pcpid;
        this._appSession.searchParams.ableToServePcp = true;
        if (!deeplink.provtype) {
          this._appSession.searchParams.providerTypeCodeList = [PROVIDER_TYPE.DOCTOR_PROFESSIONAL, PROVIDER_TYPE.MEDICAL_GROUP];
        }
      }
      if (deeplink.isvirtual) {
        this._appSession.searchParams.virtualOnly = deeplink.isvirtual.toLowerCase() === 'true' ? true : false;
      }
      if (deeplink.memberUid) {
        this._appSession.searchParams.mbrUid = deeplink.memberUid;
      }
      const providerType = this._appSession.searchParams.typeSelectNm?.toUpperCase().trim() as PROVIDER_TYPE;
      this._searchService.currentScenario = CommonUtil.isMemberSecure(this._appSession)
        ? CommonUtil.getSecureSpecialtyScenario([providerType] as PROVIDER_TYPE[])
        : SEARCH_SCENARIOS.PUBLIC_V1_SPECIALTY;
      if (deeplink.proccode) {
        this.getProcedureDetails(deeplink.proccode);
        this._appSession.isEyc = true;
      }
      if (!this._dataHelper.isEmptyString(deeplink.cptcode) && this._appSession.hasCostTransparency) {
        this.getCptDetailsFromCode(deeplink.cptcode);
      }
      if (!this._dataHelper.isEmptyString(deeplink.searchparams)) {
        let SearchFilterParms: Array<string> = deeplink.searchparams.split('|').map((x) => x?.toLocaleUpperCase());
        if (SearchFilterParms.length) {
          for (const filterParm of SearchFilterParms) {
            if (filterParm in SEARCH_PARAMS) this._appSession.searchParams[SEARCH_PARAMS[filterParm]] = true;
          }
        }
      }
      if (!this._dataHelper.isEmptyString(deeplink.taxonomies)) {
        const taxonomies = deeplink.taxonomies?.trim()?.split(',');
        const hasTaxonomy = await this.getTaxonomyCodes(taxonomies);
        if (!hasTaxonomy) {
          delete deeplink.taxonomies;
          this._appSession.searchParams.typeSelectNm = '';
          this._appSession.searchParams.providerTypeCodeList = [];
        }
      }
      if (deeplink.contractUid) {
        // TODO: Decommission this execution once members completly migrate to FCR. This is handled in BootstrapSvc in FCR.
        const isValidContract = (this._appSession.bootstrap?.contracts || []).some((c) => c.contractUid === deeplink.contractUid);
        if (isValidContract && this._appSession.metaData.appContract) {
          // required for legacy flow
          this._appSession.metaData = this._appSession.metaData ?? ({} as IAppMetadata);
          this._appSession.metaData.appContract = this._appSession.metaData.appContract ?? ({} as IAppContract);
          this._appSession.metaData.appContract.contractUid = deeplink.contractUid;
        }
      }
      this.getRuleResponse();
    }
  }

  bindCachedSearchParams(deeplink: any) {
    const _cacheReqObj = {} as ICacheRequest;
    _cacheReqObj.cacheKey = deeplink.cacheKey;

    const promiseRules = new Promise<void>((resolve, reject) => {
      this._cacheService.getCacheData(_cacheReqObj).then(
        (result: any) => {
          if (result.searchParameters) {
            this._appSession.searchParams = result.searchParameters;
            if (result.routeUrl && (result.routeUrl === AppNavigations.CARE_TEAM_PATH || result.routeUrl === AppNavigations.CARE_CIRCLE_PATH)) {
              this._appSession.isCareProvider = true;
            }
          }
          this.bindsearchParams(deeplink);
          resolve();
        },
        (error: any) => {
          throw error;
        }
      );
    });

    this._promises.push(promiseRules);
  }

  isDeepLink(): boolean {
    let _isDeepLink = false;
    if (this._appSession.deeplinkParams) {
      if (this._appSession.deeplinkParams.alphaprefix) {
        _isDeepLink = true;
      } else if (this._appSession.deeplinkParams.planstate && this._appSession.deeplinkParams.plantype && this._appSession.deeplinkParams.planname) {
        _isDeepLink = true;
      } else if (this._appSession.deeplinkParams.networks) {
        _isDeepLink = true;
      }
    }
    return _isDeepLink;
  }

  findDeeplinkParams(dplUrl: string): IDeeplink {
    let result: any = {};
    let params: string;

    try {
      if (dplUrl && dplUrl.indexOf('?') > 0) {
        result = {};

        params = dplUrl.substring(dplUrl.indexOf('?') + 1, dplUrl.length);

        if (params) {
          const table = params.split('&').map((pair) => pair.split('='));
          table.forEach(([key, value]) => (result[key] = value));
        }
        result.deeplinkUrl = dplUrl.substring(0, dplUrl.indexOf('?'));
        this._appSession.isLocationDeepLinkFlow = true;
      } else if (dplUrl) {
        result.deeplinkUrl = dplUrl;
      }
    } catch (error) {
      throw error;
    }

    return result;
  }

  async getTaxonomyCodes(taxonomyCodes: Array<string>): Promise<boolean> {
    const validateTaxonomyCodesRequest: ValidateTaxonomyCodesRequest = {
      codes: taxonomyCodes
    };

    const result: ValidateTaxonomyCodesResponse = await this._providerUtilityService.getTaxonomyCodes(validateTaxonomyCodesRequest);

    if (result?.taxonomies?.length > 0) {
      this._appSession.searchParams.taxonomySelectNm = result.taxonomies;
      this._appSession.searchParams.specialtySelectNm = result.specialtyCategories;
      this._appSession.searchParams.providerTypeCodeList = result.provTypes;
      return true;
    }

    return false;
  }

  getGeoCoordinates(inputText) {
    let _searchTerm = inputText;
    const _countyPattrn = /county/gi;
    const _match = _countyPattrn.exec(_searchTerm);
    const _isCounty = _match && _match.length ? true : false;
    if (_isCounty) {
      inputText = inputText.replace(_countyPattrn, '').trim();
    }
    if (inputText.match(/^[0-9]+$/) != null) {
      if (inputText.length >= 5) {
        const promiseAddressByZip = new Promise<void>((resolve, reject) => {
          this._providerUtilityService.getGeoLocation(inputText).then((result: LocationResponse[]) => {
            if (result && Object.keys(result).length > 0) {
              const results = this._appUtility.buildLocationResponse(result);
              const coordinates: any = results[Object.keys(results)[0]];
              this._appSession.searchParams = this._appSession?.searchParams ?? ({} as ISearchParameters);
              if (this._appSession.metaData && this._appSession.metaData.appContract) {
                const _county = this._appSession.metaData.appContract.memberCounty;
                if (!_county || _county === '') {
                  this._appSession.metaData.appContract.memberCounty = coordinates.county;
                }
              }
              if (this._appSession?.searchParams) {
                this._appSession.searchParams.coordinates = {
                  longitude: '',
                  latitude: ''
                };
                this._appSession.searchParams.coordinates.latitude = coordinates.lat;
                this._appSession.searchParams.coordinates.longitude = coordinates.long;
                this._appSession.searchParams.stateCd = {
                  code: coordinates.stateCd
                };
                this._appSession.searchParams.city = '';
                this._appSession.searchParams.countyCode = null;
              }
            }
            resolve();
          });
        });
        this._promises.push(promiseAddressByZip);
      }
    } else {
      if (inputText.length > 3) {
        const promiseAddressByCity = new Promise<void>((resolve, reject) => {
          // set location for county search
          if (_isCounty) {
            let locale = (this._appSession.metaData?.locale ?? Locale.ENGLISH).toString();

            this._providerUtilityService.getCountyLookupList({ searchTerm: inputText, locale }).then(
              (result: ICountyLookupApiResp) => {
                if (result && result.counties && result.counties.length > 0) {
                  const _keywords = inputText.split(',').map((c) => c.trim());
                  let county = {} as ICounty;
                  if (_keywords && _keywords.length > 1) {
                    county = result.counties.find((x) => this._dataHelper.areEqualStrings(x.countyName, _keywords[0]) && this._dataHelper.areEqualStrings(x.stateCode, _keywords[1]));
                  }
                  const _countyName = county.countyName + ' County';
                  this._appSession.searchParams = this._appSession?.searchParams ?? ({} as ISearchParameters);
                  this._appSession.searchParams.countyCode = county.countyCode;
                  this._appSession.searchParams.stateCd = {
                    code: county.stateCode
                  };
                  this._appSession.searchParams.coordinates = {
                    latitude: '0',
                    longitude: '0'
                  };
                  this._appSession.searchParams.zip = _countyName + ', ' + county.stateCode;
                }
                resolve();
              },
              (error: unknown) => {
                reject(error);
              }
            );
          } else {
            this._providerUtilityService.getGeoLocation(inputText).then(
              (result: LocationResponse[]) => {
                if (result && Object.keys(result).length > 0) {
                  const response = this._appUtility.buildLocationResponse(result);
                  const coordinates: any = response[Object.keys(response)[0]];
                  this._appSession.searchParams = this._appSession?.searchParams ?? ({} as ISearchParameters);
                  this._appSession.searchParams.coordinates = {
                    longitude: '',
                    latitude: ''
                  };
                  this._appSession.searchParams.zip = coordinates.name;
                  this._appSession.searchParams.coordinates.latitude = coordinates.lat;
                  this._appSession.searchParams.coordinates.longitude = coordinates.long;
                  this._appSession.searchParams.city = coordinates.city;
                  this._appSession.searchParams.stateCd = {
                    code: coordinates.stateCd
                  };
                  this._appSession.searchParams.countyCode = null;
                }
                resolve();
              },
              (error: unknown) => {
                reject(error);
              }
            );
          }
        });
        this._promises.push(promiseAddressByCity);
      }
    }
  }

  getRuleResponse() {
    const promiseRules = new Promise<void>((resolve, reject) => {
      this._ruleService.getRules(CommonUtil.buildRuleRequest(this._appSession.searchParams, this._appSession)).then(
        (result: IRulesResponse) => {
          this._appSession.feature = result;
          if (result && result.pcpRule) {
            this._appSession.pcpRule = result.pcpRule;
          }
          if (this._appSession.isChangePCP) {
            this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
            this._appSession.searchParams.ableToServePcp = true;
          }
          if (result.showBCP && result.showPreFilterBCP && this._appSession.searchParams) {
            this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
          }
          if (result?.customizedFeatures?.emulateMode) {
            this._appSession.isEmulation = result.customizedFeatures.emulateMode;
          }
          resolve();
        },
        (error: any) => {
          throw error;
        }
      );
    });

    this._promises.push(promiseRules);
  }

  deepLinkChangePcp(rule: IRulesResponse) {
    if (rule && rule.pcpRule && rule.pcpRule.providerType && rule.pcpRule.providerType.length > 0) {
      this._appSession.searchParams.typeSelectNm = rule.pcpRule.providerType[0];
      this._appSession.searchParams.providerTypeCodeList = rule.pcpRule.providerType;
      this._appSession.searchParams.specialtySelectNm = rule.pcpRule.specialities;
      this._appSession.searchParams.ableToServePcp = true;
    }
  }

  getAlphaPrefix(prefix: string) {
    const promisePrefix = new Promise<void>((resolve, reject) => {
      this._providerUtilityService.getAlphaPrefix(prefix, Locale.ENGLISH).then(
        (result: any) => {
          if (result && result.codeTypeList && result.codeTypeList.length > 0) {
            const _networkCode = result.codeTypeList[0].code;
            const _networkName = result.codeTypeList[0].name;
            const selectedPlan: IPlan = {
              identifier: '',
              name: _networkName ? _networkName : _networkCode,
              isNationalPlan: null,
              stateCd: '',
              networkList: [],
              alphaPrefix: prefix
            };
            this._appSession.searchParams.plan = selectedPlan;
          }
          resolve();
        },
        (error: any) => {
          throw error;
        }
      );
    });

    this._promises.push(promisePrefix);
  }

  getPlan(planState: string, planName: string, plantype: string): void {
    const promisePlan = new Promise<void>((resolve, reject) => {
      this._providerUtilityService.getAllPlanList(planState, this._appSession.metaData.brandCd).then(
        (result: IGenericDataResponse<Array<IPublicPlan>>) => {
          const planResult: number = (result?.data || []).findIndex((item: any) => {
            return item.name.replace(/[<>;,()]/g, '').toLocaleLowerCase() === planName.replace(/[<>;,()]/g, '').toLocaleLowerCase();
          });
          if (planResult !== -1) {
            let url = '';
            let prefix = '';
            let planCareType = '';
            let planCategory: IPlanCategory;

            if (result.data && result.data[planResult] && result.data[planResult].planType && result.data[planResult].planType.toLocaleLowerCase() === plantype.toLocaleLowerCase()) {
              let planNetworkList = [];
              const plan = result.data[planResult];
              let nationalPlanIndicator = false;
              if (plan) {
                url = plan.urlRedirect && plan.urlRedirect.trim() !== '' ? plan.urlRedirect.trim() : '';
                prefix = plan.prefix && plan.prefix.trim() !== '' ? plan.prefix.trim() : '';
                //planCareType = CommonUtil.setCareType(result.data[planResult].planType.trim().toUpperCase());
                planCareType = result.data[planResult].careType.trim().toUpperCase();
                if (plan.planCategoryList && plan.planCategoryList.length > 0) {
                  planCategory = {
                    catCode: plan.planCategoryList[0],
                    catName: plan.planCategoryList[0],
                    productPlanTypeCode: plan.planType
                  };
                }
                nationalPlanIndicator = plan.isNationalPlan;
                if (plan.networkList && plan.networkList.length) {
                  planNetworkList = plan.networkList;
                }

                const selectedPlan: IPlan = {
                  identifier: plan.id,
                  name: plan.name,
                  isNationalPlan: nationalPlanIndicator,
                  stateCd: planState.trim(),
                  networkList: planNetworkList,
                  urlRedirect: url,
                  careType: planCareType,
                  alphaPrefix: prefix,
                  category: planCategory
                };

                this._appSession.searchParams.typeOfCareNm = planCareType;
                this._appSession.searchParams.plan = selectedPlan;

                // Based on Care type set the provider type & standalone flag.
                switch (planCareType) {
                  case PROVIDER_TYPE_OF_CARE.DENTAL: {
                    this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DENTAL;
                    this._appSession.isDentalStandAlone = true;
                    break;
                  }
                  case PROVIDER_TYPE_OF_CARE.VISION: {
                    this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.VISION;
                    this._appSession.isVisionStandAlone = true;
                    break;
                  }
                  case PROVIDER_TYPE_OF_CARE.PHARMACY: {
                    this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.PHARMACY;
                    this._appSession.isPharmacyStandAlone = true;
                    break;
                  }
                }
                if (this._appSession.searchParams.typeSelectNm && this._appSession.searchParams.typeSelectNm.length > 0) {
                  this._appSession.searchParams.providerTypeCodeList = [this._appSession.searchParams.typeSelectNm];
                }
              }
            }
          }

          resolve();
        },
        (error: any) => {
          throw error;
        }
      );
    });

    this._promises.push(promisePlan);
  }

  getProcedureDetails(proccode: string) {
    const promiseProcedure = new Promise<void>((resolve, reject) => {
      this._procedureService.getProcedures(proccode).then(
        (result: IProcedureCategoryList) => {
          if (result && result.procedureCategoryList && result.procedureCategoryList.procedureList && result.procedureCategoryList.procedureList.length > 0) {
            const procedureDetails: ILinkProcedure = result.procedureCategoryList.procedureList.find((x) => x.procedureCode && x.procedureCode.code === proccode);
            if (procedureDetails && this._appSession && this._appSession.searchParams) {
              this._appSession.searchParams.eycSearchParam = {
                eycProcedureCriteria: {
                  procedureCode: procedureDetails.procedureCode?.code || '',
                  providerCategoryCode: procedureDetails.providerCategoryCode?.code || '',
                  procedureName: procedureDetails.procedureCode?.name || ''
                },
                procedureDescription: procedureDetails.procedureCode?.description || ''
              };
              this._searchService.currentScenario = CommonUtil.getSecureProcedureScenario(procedureDetails.providerCategoryCode?.code as PROVIDER_TYPE);
              if (!FeatureManagerUtility.isFcrEnabled(this._appSession)) {
                this._appSession.searchParams.typeSelectNm = CommonUtil.getEycProcProviderType(procedureDetails.providerCategoryCode?.code as PROVIDER_TYPE);
              }
            }
          }
          resolve();
        },
        (error: any) => {
          throw error;
        }
      );
    });
    this._promises.push(promiseProcedure);
  }

  private interpretDeeplinkParams(deeplink: IDeeplink) {
    deeplink = this.interpretProviderTypeCode(deeplink);
    deeplink = this.interpretPCPCode(deeplink);

    return deeplink;
  }

  // Found some deep links use ispcp to set default pcp selection.
  private interpretPCPCode(deeplink: IDeeplink) {
    if (deeplink && !deeplink.ispcp) {
      return deeplink;
    }

    if (deeplink.ispcp.toLocaleLowerCase() === 'true') {
      deeplink.pcponly = 'Y';
    }

    return deeplink;
  }

  // Found several deep links use different names for provider type.
  // This method will translate those names to provider type codes.
  private interpretProviderTypeCode(deeplink: IDeeplink) {
    try {
      if (deeplink && !deeplink.provtype) {
        return deeplink;
      }
      let provtype = '';
      for (const providertype of deeplink.provtype.split('|')) {
        switch (providertype.toLowerCase()) {
          case 'physician':
            provtype = provtype + PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
            break;
          case 'dental':
            provtype = provtype + PROVIDER_TYPE.DENTAL;
            break;
          case 'vision':
            provtype = provtype + PROVIDER_TYPE.VISION;
            break;
          case 'rx':
            provtype = provtype + PROVIDER_TYPE.PHARMACY;
            break;
          case 'bh':
            provtype = provtype + PROVIDER_TYPE.BEHAVIORAL_HEALTH;
            break;
          case 'group':
            provtype = provtype + PROVIDER_TYPE.MEDICAL_GROUP;
            break;
          case 'hospital':
            provtype = provtype + PROVIDER_TYPE.HOSPITAL;
            break;
          case 'lab':
          case 'radiology':
          case 'pathology':
            provtype = provtype + PROVIDER_TYPE.LAB;
            break;
          case 'equipment':
            provtype = provtype + PROVIDER_TYPE.MEDICAL_EQUIPMENT;
            break;
          case 'urgentcare':
            provtype = provtype + PROVIDER_TYPE.URGENT_CARE;
            break;
          case 'other':
            provtype = provtype + PROVIDER_TYPE.OTHER_MEDICAL;
            break;
          case 'otherservices':
            provtype = provtype + PROVIDER_TYPE.OTHER_SERVICES_PROVIDER;
            break;
          case 'hearing':
            provtype = provtype + PROVIDER_TYPE.HEARING;
            break;
          case 'retailclinic':
            provtype = provtype + PROVIDER_TYPE.RETAIL_CLINIC;
            break;
          default:
            provtype = provtype + providertype.toUpperCase();
            break;
        }
        provtype = provtype + '|';
      }
      deeplink.provtype = provtype.substring(0, provtype.length - 1);
    } catch (error) {
      deeplink.provtype = undefined;
    }
    return deeplink;
  }

  getCptDetailsFromCode(cptCode: string): void {
    const promiseProcedure = new Promise<void>((resolve, reject) => {
      this._cptLookupSvc.getCptLookupList({ searchTerm: encodeURI(cptCode), locale: Locale.ENGLISH }).then(
        (result: ICptLookUpList) => {
          if (result && result.cptCodes && result.cptCodes.length > 0) {
            const cptCode: ICptCode = result.cptCodes[0];
            if (cptCode && this._appSession && this._appSession.searchParams) {
              this._appSession.isMedicalCodeSearch = true;
              this._searchService.currentScenario = SEARCH_SCENARIOS.SECURE_V1_CPT_MEDICAL;
              this._appSession.billingType = cptCode.medicalTypeCd;
              this._appSession.medicalCode = cptCode.medicalCd;
              this._appSession.medicalModifierOptionCode = cptCode.medicalModifierCd;
              this._appSession.medicalPosCode = cptCode.medicalPosCd;
              if (!FeatureManagerUtility.isFcrEnabled(this._appSession)) {
                this._appSession.searchParams.typeSelectNm = PROVIDER_TYPE.DOCTOR_PROFESSIONAL;
              }
            }
          }
          resolve();
        },
        (error: unknown) => {
          reject(error);
        }
      );
    });
    this._promises.push(promiseProcedure);
  }
}
