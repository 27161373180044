import { Component, Inject, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isEmpty } from 'lodash';
import { BRAND_CODE } from '../../../../../common/constants/app-constants';
import { DataHelper } from '../../../../../common/services/dataHelper';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';
import { StateCode } from '../../../../common/constants/common';
import { ADOBE_TAGS_DISCLAIMER } from '../../../constants/result';
import { ICostDisclaimerResp, IDisclaimerRequest, IDisclaimerResponse } from '../../../interfaces/iDisclaimer';
import { DisclaimerSvc } from '../../../services/disclaimerSvc';
import { BaseComponent } from './../../../../common/components/core/baseCmp';
@Component({
  selector: 'app-disclaimer',
  templateUrl: './genericDisclaimersCmp.html'
})
export class DisclaimerCmp extends BaseComponent implements OnInit, OnChanges {
  @Input() ruleList = [];
  @Input() isVitalsRequired: boolean;
  @Input() screenNo: number = 1;
  costDisclaimers: ICostDisclaimerResp;
  disclaimers = [];
  dataDisclaimers: Array<string> = [];
  tabDisclaimers = [];

  showDisclaimer: boolean = false;
  startPage: number = 0;
  paginationLimit: number = 1;
  disclaimerCount: number = 0;
  showDisclaimerLoader: boolean = true;
  showDisclaimerError: boolean = false;
  importanttxt: string;
  adobeDisclaimerTag: string;
  constructor(
    private _disclaimerSvc: DisclaimerSvc,
    @Inject(DataHelper)
    private _dataHelper: DataHelper,
    private _eventHandler: EventHandler,
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
  }

  ngOnChanges(args: any) {
    if (args['ruleList'] && JSON.stringify(args['ruleList'].currentValue) !== JSON.stringify(args['ruleList'].previousValue)) {
      this.loadDisclaimerContent();
    }
  }

  loadDisclaimerContent() {
    //Resetting the disclaimer arrays to handle refreshing of disclaimers when multiple searches are clubbed
    this.disclaimers = [];
    this.dataDisclaimers = [];
    this.tabDisclaimers = [];

    this.adobeDisclaimerTag = this.getAdobeDisclaimerTag(this.screenNo);
    this.importanttxt = this.content?.result?.disclaimerComponent?.labels?.important;
    //Creation of Disclaimer Request
    const reqObj: IDisclaimerRequest = {
      isEyc: this._appSession?.isEyc,
      isCpt: this._appSession?.isMedicalCodeSearch,
      isTopRequired: true,
      isVitalRequired: this.isVitalsRequired ?? false,
      rules: this.ruleList
    };

    this._disclaimerSvc.loadDisclaimerIDs(reqObj).then(
      (disclaimers: IDisclaimerResponse) => {
        this.showDisclaimerLoader = false;
        //Generic Messages
        disclaimers.messages?.forEach((disclaimerId: string) => {
          const disclaimerMsg = this.content?.common?.disclaimer?.[disclaimerId] ?? null;
          if (disclaimerMsg !== null) {
            const genericMessage = this._dataHelper.isObject(disclaimerMsg) ? disclaimerMsg.content : disclaimerMsg;
            this.disclaimers.push(genericMessage);
          }
        });
        //CostDisclaimers Object -If its not empty , then parse for data and tab disclaimers
        if (!isEmpty(disclaimers.costDisclaimers)) {
          disclaimers.costDisclaimers?.dataDisclaimer?.forEach((disclaimerId: string) => {
            const disclaimerMsg = this.content?.common?.disclaimer?.[disclaimerId] ?? null;
            if (disclaimerMsg !== null) {
              this.dataDisclaimers.push(disclaimerMsg);
            }
          });

          disclaimers.costDisclaimers?.tabDisclaimer?.forEach((disclaimerId: string) => {
            const tempObj = this.content?.common?.disclaimer?.[disclaimerId] ?? null;
            const tabdisclaimerObj = {
              message: tempObj?.content,
              tabId: tempObj?.id,
              heading: tempObj?.heading
            };
            if (tabdisclaimerObj) {
              this.tabDisclaimers.push(tabdisclaimerObj);
            }
          });

          this.costDisclaimers = {
            dataDisclaimer: this.dataDisclaimers,
            tabDisclaimer: this.tabDisclaimers
          };
        }

        //If paginationLimit >= disclaimers.length(generic messages) then showMore link will be displayed
        this.disclaimerCount = this.disclaimers ? this.disclaimers.length - 1 : 0;
        if (this.disclaimers && this.disclaimers.length > 0) {
          this.paginationLimit = this.disclaimers.findIndex((element) => {
            return element.match(this.importanttxt);
          });

          this.paginationLimit += this.paginationLimit === -1 ? 2 : 1;
          if (
            this._dataHelper.areEqualStrings(this._appSession?.metaData?.brandCd, BRAND_CODE.ABC || BRAND_CODE.ABCBS) &&
            this._dataHelper.areEqualStrings(this._appSession?.searchParams?.stateCd?.code, StateCode.CA)
          ) {
            this.paginationLimit = this.paginationLimit + 1;
          }
        }
      },
      (error: any) => {
        this.onError(error);
      }
    );
  }

  onError(error: any) {
    this.showDisclaimerLoader = false;
    this.showDisclaimerError = true;
  }

  showMoreItems() {
    this.paginationLimit = Number(this.paginationLimit) + this.disclaimerCount;
  }

  showLessItems() {
    this.paginationLimit = Number(this.paginationLimit) - this.disclaimerCount;
  }

  embedBaseUrl(content: string) {
    return this.getDocumentURL(content);
  }

  getAdobeDisclaimerTag(screenNo: number) {
    switch (screenNo) {
      case 1:
        return ADOBE_TAGS_DISCLAIMER.discResults;
      case 2:
        return ADOBE_TAGS_DISCLAIMER.discEycResults;
      default:
        return ADOBE_TAGS_DISCLAIMER.discResults;
    }
  }
}
