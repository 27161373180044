import { Component, Inject, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IBaseCode, IBaseCodeOption } from '../../../../common/interfaces/iBaseCode';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IOption } from '../../../common/interfaces/iOption';
import { FilterCategory } from '../../constants/result';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';

@Component({
  moduleId: module.id,
  selector: 'app-fc-radio-button-list-cmp',
  templateUrl: './filterRadioButtonListCmp.html'
})

export class FilterRadioButtonListComponent extends BaseComponent {

  @Input() options: Array<IBaseCode> = [];
  @Input() category: FilterCategory;
  @Input() values: string[] = [];
  @Input() preSelectedCode: string = '';
  private appliedFilterRemove: Subscription;
  private appliedFiltersSubscription: Subscription;
  selectedOption: IBaseCodeOption = null;
  appliedFilters: IAppliedSearchFilter[] = [];

  constructor(
    @Inject(AppSession) private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute,
    private _appliedSearchFilter: AppliedSearchFilter
  ) {
    super(route, _eventHandler, _appSession);
  }

  ngOnInit(): void {
    this.bindFilterOptions(true);
    this.bindPreSelection();
    this.appliedFilterRemove = this._appliedSearchFilter.appliedFilterRemove.subscribe((filter: IAppliedSearchFilter) => {
      if (filter && filter.category === this.category) {
        this.bindFilterOptions();
      }
    });

    this.appliedFiltersSubscription = this._appliedSearchFilter?.getAppliedFilters?.subscribe((data) => {
      this.bindFilterOptions();
    });
  }

  private bindFilterOptions(initial: boolean = false) {
    const appliedCategoryFilters = this._appliedSearchFilter.mapAppliedFilterItemsByCategory(this.category);
    this.appliedFilters = appliedCategoryFilters?.[this.category] || [];
    if (initial) {
      (this.appliedFilters || []).forEach((appliedfilter: IAppliedSearchFilter) => {
        this._appliedSearchFilter.addInitialAppliedFilterItem(appliedfilter);
      });
    }
  }

  private bindPreSelection() {
    const option = this.options?.find((opt: IBaseCodeOption) => opt.code === this.preSelectedCode);
    if (option) {
      this.selectedOption = option;
    }
  }

  ngOnDestroy() {
    this.appliedFilterRemove?.unsubscribe();
    this.appliedFiltersSubscription?.unsubscribe();
  }

  onOptionChange(option: IBaseCodeOption) {
    this._appliedSearchFilter.removeFilterOption(this.category, this.getOptionObject(this.selectedOption?.code, this.selectedOption?.name));
    if (this.selectedOption?.code !== option.code && option.code !== 'ALL') {
      this._appliedSearchFilter.addFilterOption(this.category, this.getOptionObject(option.code, option.name), false);
    }
    this.selectedOption = option;
  }

  getOptionObject(value: string, name: string): IOption {
    return {
      label: name,
      value: value,
      id: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      name: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      trueValue: value,
      falseValue: '',
      isChecked: false
    } as IOption;
  }
}
