import { ANTHEM_DIAMOND_PREFIX, ProviderFlag } from '../../common/constants/common';
import { IReviewResponse } from '../interfaces/iProvider';

export class ProviderUtility {
  static isDiamondProvider(pgmCode: string): boolean {
    return pgmCode?.toUpperCase().includes(ANTHEM_DIAMOND_PREFIX) || false;
  }

  static hasReviews(review: IReviewResponse): boolean {
    const totalReviews = review?.totalReviews;
    return !isNaN(Number(totalReviews)) && parseInt(totalReviews) > 0;
  }

  static isVirtualProvider(providerFlags: ProviderFlag[]): boolean {
    return providerFlags?.map((flag) => flag.toUpperCase()).includes(ProviderFlag.VIRTUAL_PROVIDER) || false;
  }

  static isOnlineProvider(providerFlags: ProviderFlag[]): boolean {
    return providerFlags?.map((flag) => flag.toUpperCase()).includes(ProviderFlag.ONLINE_PROVIDER) || false;
  }
}
