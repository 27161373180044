import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { AppSession } from '../../../../../../common/values/appSession';
import { IRulesResponse } from '../../../../../../fad/rules/interfaces/iRulesResponse';
import { FindCareFeature } from '../../../../enums/findCareFeature';
import { IModalMetaInfo } from '../../../../interfaces/iModalContent';
import { FeatureManagerUtility } from '../../../../utilities/featureManagerUtil';

@Injectable({ providedIn: 'root' })
export class GenericModalService implements OnDestroy {
  modalSubject = new Subject<IModalMetaInfo>();
  sidePanleSubject = new Subject<IModalMetaInfo>();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  ngOnDestroy() {
    this.modalSubject.unsubscribe();
    this.sidePanleSubject.unsubscribe();
  }

  openGenericModal(modalMetaInfo: IModalMetaInfo) {
    this.modalSubject.next(modalMetaInfo);
  }

  openGenericSidePanel(modalMetaInfo: IModalMetaInfo) {
    this.sidePanleSubject.next(modalMetaInfo);
  }

  modalListener() {
    return this.modalSubject;
  }

  sidePanelListener() {
    return this.sidePanleSubject;
  }

  isActionBlockedByModal(rules: IRulesResponse = this._appSession.feature): boolean {
    let isActionBlocked = false;
    let modalMetaInfo = {} as IModalMetaInfo;
    if (rules) {
      if (rules.showTransportation) {
        isActionBlocked = true;
        modalMetaInfo.modalId = 'MD_TRANSPORTATION';
        if (this._appSession.planRule && this._appSession.planRule.transportationLocation) {
          const transportationlocations = Array.isArray(this._appSession.planRule.transportationLocation)
            ? this._appSession.planRule.transportationLocation
            : [this._appSession.planRule.transportationLocation];
          modalMetaInfo.modalValues = { transportationLocations: transportationlocations };
        } else {
          modalMetaInfo.modalValues = { transportationLocations: {} };
        }
        this.openGenericModal(modalMetaInfo);
      } else if (rules.isMyNexus) {
        isActionBlocked = true;
        if (this._appSession.isSecureState) {
          modalMetaInfo.modalId = 'MD_MY_NEXUS_SECURE';
        } else {
          modalMetaInfo.modalId = 'MD_MY_NEXUS_PUBLIC';
        }
        this.openGenericModal(modalMetaInfo);
      } else if (rules.showMedicareSupplement || FeatureManagerUtility.isFeatureLive(FindCareFeature.HAS_MEDSUPP, this._appSession)) {
        isActionBlocked = true;
        modalMetaInfo.modalId = 'MD_MEDICARE_SUPPLEMENT';
        this.openGenericSidePanel(modalMetaInfo);
      }
    }
    return isActionBlocked;
  }
}
