import { SEARCH_TYPE } from '../../../common/constants/app-constants';
import { ITranslation } from '../../../fad/search-providers/interfaces/iAdaListResp';
import { IFtsSuggestion } from '../interfaces/iFtsClientInteraction';

export class FtsUtility {
  static hasSpecialty(suggestion: IFtsSuggestion<ITranslation>): boolean {
    const criteria = suggestion?.criteria;
    return criteria?.specialtyCategoryList?.length > 0 || criteria?.taxonomyList?.length > 0 || criteria?.providerName !== '' || criteria?.npi !== '' || false;
  }

  static hasProcedure(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.procedureCode !== '' && suggestion?.metaData?.providerCategoryCode !== '' && suggestion?.criteria?.procedure?.name !== '') || false;
  }

  static hasMedicalCode(suggestion: IFtsSuggestion<ITranslation>): boolean {
    return (suggestion?.medicalCode !== '' && suggestion?.text !== '' && suggestion?.metaData?.medicalTypeCode !== '') || false;
  }

  static generateSpecialtyCriteriaText(suggestion: IFtsSuggestion<ITranslation>, freeTextSearchResultsContent): string {
    const criteriaDisplaySet = new Set<string>();
    (suggestion.criteria.specialtyCategoryList || []).forEach((category: ITranslation) => {
      criteriaDisplaySet.add(category.name);
    });
    (suggestion.criteria.taxonomyList || []).forEach((taxonomy: ITranslation) => {
      criteriaDisplaySet.add(taxonomy.name);
    });
    let searchCriteria = Array.from(criteriaDisplaySet);
    //Add NPI if any
    if (suggestion.criteria?.npi) {
      searchCriteria.push(freeTextSearchResultsContent?.npi + ' ' + suggestion.criteria?.npi);
    }
    //Add provider name if any
    if (suggestion.criteria?.providerName) {
      searchCriteria.push(freeTextSearchResultsContent?.name + suggestion.criteria?.providerName);
    }
    //Add gender if any
    if (suggestion.criteria?.genderList?.length === 1) {
      if (suggestion.criteria?.genderList[0] === 'F') {
        searchCriteria.push(freeTextSearchResultsContent?.femaleDoctor);
      } else {
        searchCriteria.push(freeTextSearchResultsContent?.maleDoctor);
      }
    }
    if (suggestion.criteria?.ableToServeAsPcp) {
      searchCriteria.push(freeTextSearchResultsContent?.serveAsPcp);
    }
    if (suggestion.criteria?.acceptsNewPatient) {
      searchCriteria.push(freeTextSearchResultsContent?.acceptsNewPatients);
    }
    if (suggestion.criteria?.languageList?.length > 0) {
      const criteriaLanguageSet = new Set<string>();
      (suggestion.criteria.languageList || []).forEach((language) => {
        criteriaLanguageSet.add(language.name);
      });
      searchCriteria.push(freeTextSearchResultsContent.whoSpeaks + Array.from(criteriaLanguageSet).join(', '));
    }
    return searchCriteria.join(', ');
  }

  static displaySearchCriteria(suggestion: IFtsSuggestion<ITranslation>, freeTextSearchResultsContent): string {
    switch (suggestion.type) {
      case SEARCH_TYPE.SPECIALTY:
      case SEARCH_TYPE.RETAIL_PHARMACY:
        return this.generateSpecialtyCriteriaText(suggestion, freeTextSearchResultsContent);
      case SEARCH_TYPE.PROCEDURE:
        return suggestion.criteria.procedure?.name;
      case SEARCH_TYPE.MEDICAL_CODE:
        return suggestion.text;
      default:
        return '';
    }
  }
}
