import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppExternalCommands } from '../../../../common/constants/app-constants';
import { AppEvents } from '../../../../common/enums/appEvents';
import { IAdobeSearchDetail } from '../../../../common/interfaces/iAdobe';
import { IEventDetail } from '../../../../common/interfaces/iEventDetail';
import { EventHandler } from '../../../../common/services/eventHandler';
import { SearchSvc } from '../../../../common/services/searchSvc';
import { AppSession } from '../../../../common/values/appSession';
import { ContentHelper } from '../../../../common/values/contentHelper';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { RulesService } from '../../../../fad/rules/services/RulesSvc';
import { IMoreOptionsRequest } from '../../../../fad/search-providers/interfaces/iMoreOptionsRequest';
import { MoreOptionsSvc } from '../../../../fad/search-providers/services/moreOptionsSvc';
import { SAVNavigationService } from '../../../../fad/search-providers/services/savNavigationSvc';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { IAppContract } from '../../../common/interfaces/iAppContract';
import { BootstrapService } from '../../../common/store/bootstrapSvc';
import { ActionType, CodeType, GetCareNow } from '../../constants/landing';
import { IMoreOption } from '../../interfaces/iMoreOptions';
import { MoreOptionsService } from '../../services/moreOptionSvc';
import { AppNavigations } from './../../../../common/constants/app-navigations';
import { NavigationService } from './../../../../common/services/navigationService';
import { ISearchParameters } from './../../../../fad/search-providers/interfaces/iSearchParameters';
import { GenericModalService } from './../../../common/components/shareable/modal/services/genericModalSvc';
import { IModalMetaInfo } from './../../../common/interfaces/iModalContent';
@Component({
  moduleId: module.id,
  selector: 'app-fc-more-option',
  templateUrl: './moreOption.html'
})
export class MoreOptionComponent extends BaseComponent implements OnInit {
  @Input() moreOptionsList: IMoreOption[] = [];
  @Output()
  modal: EventEmitter<string> = new EventEmitter<string>();
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);
  @Output()
  analytics: EventEmitter<IAdobeSearchDetail> = new EventEmitter<IAdobeSearchDetail>();
  locale: string;
  searchParams: ISearchParameters;
  isSingleMoreOption = false;
  private _contractSubscription: Subscription;
  activeMember: IAppContract = undefined;
  showLoader = false;
  @Output()
  locationError: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _optionSvc: MoreOptionsSvc,
    public _ruleService: RulesService,
    private _savNavigationService: SAVNavigationService,
    private _moreOptionsService: MoreOptionsService,
    private _navigationService: NavigationService,
    private _bootstrapService: BootstrapService,
    private _genericModalService: GenericModalService,
    private searchService: SearchSvc,
    public _contentHelper: ContentHelper
  ) {
    super(_route, _eventHandler, _appSession);
  }

  ngOnInit() {
    if (this.waitUntilAppReload) {
      return;
    }
    this.searchParams = this._appSession.searchParams;
    this._contractSubscription = this._bootstrapService.contract.subscribe((appContract: IAppContract) => {
      this.activeMember = appContract;
      this.getMoreOptions();
    });
  }

  /**
   * Get more options based on the care type and brand
   */
  getMoreOptions() {
    this.showLoader = true;
    const req: IMoreOptionsRequest = {
      careType: this.isSecureState ? (this._appSession.filterChangePlan?.careType ?? '') : this._appSession?.searchParams?.plan?.careType,
      brand: this._appSession.metaData?.brandCd,
      networks: this._appSession.searchParams?.plan?.networkList ?? [],
      prefix: CommonUtil.getPrefix(this._appSession.searchParams, this._appSession),
      member: {
        mbrUid: this.activeMember?.selectedContract?.mbrUid,
        contractUid: this.activeMember?.selectedContract?.contractUid,
        identification: this._appSession.searchParams?.identificationNumber
      },
      locale: this.locale
    };
    this._optionSvc.getMoreOptions(req).then((resp: Array<IMoreOption>) => {
      this.moreOptionsList = resp;
      this.showLoader = false;
      if (resp.length === 1 && this.isSecureState) {
        this.isSingleMoreOption = true;
      }
      // Set the more options list in the service
      this._moreOptionsService.setMoreOptionsList(resp);
    });
  }

  /**
   * Search by provider type based on the action type
   * @param options More option object
   * @param event Click event object
   */
  search(options: IMoreOption, event: Event) {
    if (this.isLocationValid()) {
      switch (options.actionType?.toUpperCase()) {
        case ActionType.SEARCH:
          this.searchByProviderType(options);
          break;
        case ActionType.SSO:
          this.onVideoVisit(event);
          break;
        case ActionType.EXT_NAV:
          this.navigateTo(options.url);
          break;
        case ActionType.INT_RCP_NAV:
          this.navigateToRCPPage(options);
          break;
        case ActionType.INT_NAV:
          this._navigationService.navigateByUrl(this.getUrl(options.code));
          break;
      }
    }
  }

  /**
   * Get the URL based on the action type
   * @param code String code
   * @returns string URL
   */
  getUrl(code: string) {
    return code === GetCareNow.GET_CARE_NOW ? AppNavigations.FCR_CARE_NOW : '';
  }

  /**
   * Navigate to the external URL
   * @param url URL to navigate
   */
  navigateTo(url: string) {
    window.open(url, '_blank');
  }

  /**
   * Navigate to the RCP page base on the provider type
   * @param providerType Provider type object
   */
  navigateToRCPPage(providerType: IMoreOption) {
    const eventDetail = {} as IEventDetail;
    eventDetail.type = AppEvents[AppEvents.PF_APP_CMD_NAV];

    if (providerType.code === CodeType.VIRTUAL_CARE) {
      eventDetail.message = AppExternalCommands.RCP_VIRTUAL_CARE.MSG;
      eventDetail.target = AppExternalCommands.RCP_VIRTUAL_CARE.CMD;
    }

    this._pfAppCommandEvent.emit(eventDetail);
  }

  /**
   * Trigger the video visit click event
   * @param event Click event object
   */
  onVideoVisit(event: any) {
    const videoVisitClick = event.currentTarget.querySelector('#fad-pf-sso-link-0');
    if (videoVisitClick) {
      videoVisitClick.click();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  isLeavingAnthem(providerTypeCode: string): boolean {
    const provType = (this.moreOptionsList || []).find((moreOption: IMoreOption) => {
      return moreOption?.searchCriteria?.providerTypeCodeList?.[0]?.toUpperCase() === providerTypeCode?.toUpperCase();
    });
    if (provType?.url) {
      const modalMetaInfo: IModalMetaInfo = {} as IModalMetaInfo;
      modalMetaInfo.modalId = 'MD_LEAVING_SITE';
      modalMetaInfo.urlToBeRedirected = provType.url;
      this._genericModalService.openGenericSidePanel(modalMetaInfo);
      return true;
    }
    return false;
  }

  /**
   * Search for more options based on the provider type
   * @param moreOptionsResponse More options response object
   */
  searchByProviderType(moreOptionsResponse: IMoreOption): void {
    const isLeavingAnthemDisclaimer = this.isLeavingAnthem(moreOptionsResponse?.searchCriteria?.providerTypeCodeList?.[0]);
    if (moreOptionsResponse && !isLeavingAnthemDisclaimer) {
      const { searchCriteria } = moreOptionsResponse;
      const { providerTypeCodeList, providerTier, specialtyCategoryCodes, recognitionCodeList } = searchCriteria || {};
      this._appSession.searchParams.providerTypeCodeList = providerTypeCodeList ?? [];
      Object.assign(this.searchParams, {
        ...this._appSession.searchParams,
        typeSelectNm: providerTypeCodeList?.[0],
        coverageTypeCode: '',
        providerTypeCodeList: providerTypeCodeList ?? [],
        ableToServePcp: false,
        providerTier: providerTier,
        specialtySelectNm: specialtyCategoryCodes,
        recognitionCodes: recognitionCodeList
      });
      this.resetAppSessionParams();

      this._ruleService.getRules(CommonUtil.buildRuleRequest(this.searchParams, this._appSession)).then(
        (result: IRulesResponse) => {
          const _result = result;
          if (_result) {
            if (!this._genericModalService.isActionBlockedByModal(_result)) {
              this._appSession.feature = _result;
              if (_result.showBCP && _result.showPreFilterBCP && this._appSession?.searchParams) {
                this._appSession.searchParams.blueCarePrimePreferredSpecialistOnly = true;
              }
              this._savNavigationService.navigate();
            }
          }
        },
        (error: any) => {
          this.onCategoryError('RULE', error);
        }
      );
    }
  }

  // Reset AppSession SearchParams
  resetAppSessionParams(eyc: boolean = false) {
    this.searchParams.provNm = '';
    Object.assign(this._appSession, {
      filterSearchParams: undefined,
      searchParams: { ...this.searchParams },
      initialSearch: true,
      integratedSearch: true,
      isEyc: eyc,
      isMedicalCodeSearch: false,
      billingType: undefined,
      medicalCode: undefined,
      medicalModifierOptionCode: undefined,
      medicalPosCode: undefined,
      providerTypeCodes: []
    });
  }

  isLocationValid(): boolean {
    let _isValid = true;
    if (
      !(
        this._appSession?.searchParams.coordinates &&
        this._appSession?.searchParams.coordinates.latitude !== '' &&
        this._appSession?.searchParams.coordinates.longitude !== '' &&
        this._appSession?.searchParams.zip !== ''
      )
    ) {
      _isValid = false;
      this.locationError.emit(true);
    }
    return _isValid;
  }

  ngOnChanges(args: SimpleChanges) {
    if (args['searchParams'] && args['searchParams'].currentValue) {
      this.searchParams = args['searchParams'].currentValue;
    }
  }

  /**
   * Throw error for the category
   * @param type Type of the category
   * @param error Error object
   */
  onCategoryError(type: string, error: any) {
    throw error;
  }

  /**
   * Cleans up the component by unsubscribing from the contract observable.
   * This method is called when the component is destroyed.
   */
  ngOnDestroy(): void {
    if (this._contractSubscription) {
      this._contractSubscription.unsubscribe();
    }
  }
}
