import { Inject, Injectable } from '@angular/core';
import { IAppMetadata } from '../../../common/interfaces/iAppMetadata';
import { IMemberCriteria } from '../../../common/interfaces/iCommonSearchRequest';
import { AppSession } from '../../../common/values/appSession';
import { IOutputFlags } from '../../../fad/provider-details/models/iProviderDetailsRequest';
import { OUT_OF_NETWORK, PROVIDER_TYPE } from '../../../fad/search-providers/values/providerSearchConstants';
import { providerOutputFlags } from '../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { SearchRequestUtil } from '../../../fad/utilities/searchRequestUtil';
import { ProviderFlag, SearchExecutionMode } from '../../common/constants/common';
import { IProviderDetailRequest } from '../../common/interfaces/iProviderDetail';
import { ProviderDetailService } from '../../common/services/providerDetailSvc';
import { IReportIncorrectDataRequest, IReportIncorrectDataResponse } from '../interfaces/iIncorrectData';
import { IAddress, IProvider } from '../interfaces/iProvider';
import { IDetail, IProviderOverview } from '../interfaces/iProviderDetail';
import { ProviderDetailsUtils } from './../../../fad/utilities/providerDetailsUtils';
import { IProviderDetailResponse } from './../../common/interfaces/iProviderDetail';

@Injectable({
  providedIn: 'root'
})
export class ProviderDetailHandler {
  constructor(
    private _providerDetailSvc: ProviderDetailService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  async getProviderDetail(providerIdentifier: string, addressIdentifier: string, selectedProvider: IProvider): Promise<IProviderOverview> {
    try {
      const detailsRequest = this.buildRequest(this._appSession, providerIdentifier, addressIdentifier, selectedProvider);
      const apiResponse = await this._providerDetailSvc.getProviderDetail(detailsRequest);
      return this.transform(apiResponse);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async getIncorrectDataInfo(providerIdentifier: string, addressIdentifier: string, providerDetail): Promise<IReportIncorrectDataResponse> {
    try {
      const detailsRequest = this.buildRequest(this._appSession, providerIdentifier, addressIdentifier, this._appSession.selectedProvider);
      if (providerDetail) {
        const incorrectDataRequest = this.buildIncorrectDataRequest(detailsRequest, providerDetail);
        const apiResponse = this._providerDetailSvc.getIncorrectEmailData(incorrectDataRequest);
        return Promise.resolve(apiResponse);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  private buildIncorrectDataRequest(detailsRequest: IProviderDetailRequest, providerDetail): IReportIncorrectDataRequest {
    const incorrectDataRequest: IReportIncorrectDataRequest = {
      networks: detailsRequest.networks ?? [],
      prefix: CommonUtil.getAlphaPrefix(this._appSession) ? [CommonUtil.getAlphaPrefix(this._appSession)] : [],
      state: this.getState(detailsRequest, providerDetail),

      mbuCode: this._appSession.searchParams?.plan?.category?.catName ? this._appSession.searchParams.plan.category.catName.toUpperCase() : '',
      providerSourceSystem: providerDetail?.sourceSystemName ? providerDetail?.sourceSystemName.toUpperCase() : '',
      providerNetworks: providerDetail?.providerNetworkList ? providerDetail?.providerNetworkList : []
    };

    if (this._appSession.appState?.executionMode === SearchExecutionMode.SECURE) {
      const selectedContract = this._appSession.appState?.selectedEligibilityProduct?.selectedContract;
      incorrectDataRequest.memberCriteria = {};
      incorrectDataRequest.memberCriteria.mbrUid = selectedContract?.mbrUid;
      incorrectDataRequest.memberCriteria.contractUid = selectedContract.contractUid;
    }

    return incorrectDataRequest;
  }

  private getState(detailsRequest: IProviderDetailRequest, providerDetail): string {
    if (providerDetail?.address?.state) {
      return providerDetail.address.state;
    } else if (this._appSession.searchParams?.plan?.stateCd) {
      return this._appSession.searchParams?.plan?.stateCd;
    } else if (this._appSession?.alphaPrefixStateCode) {
      return this._appSession.alphaPrefixStateCode;
    } else {
      return detailsRequest.planStateCode;
    }
  }

  private buildRequest(appSession: AppSession, providerIdentifier: string, addressIdentifier: string, selectedProvider: IProvider): IProviderDetailRequest {
    const searchParams = appSession?.searchParams;
    const metaData = appSession?.metaData;

    if (!searchParams) {
      return {} as IProviderDetailRequest;
    }

    const isPharmacyProvider = (selectedProvider?.providerTypeCodeList ? selectedProvider.providerTypeCodeList : searchParams?.providerTypeCodeList || []).includes(PROVIDER_TYPE.PHARMACY);
    const address: IAddress | undefined = isPharmacyProvider ? selectedProvider?.location?.address : undefined;

    const detailsRequest: IProviderDetailRequest = {
      id: providerIdentifier,
      addressId: addressIdentifier,
      distance: parseInt((searchParams?.distanceNm || '0').trim(), 10),
      planStateCode: searchParams?.plan?.stateCd || '',
      specialtyCategories: searchParams?.specialtySelectNm,
      alphaPrefix: CommonUtil.getAlphaPrefix(appSession),
      latitude: searchParams?.coordinates?.latitude?.toString() || '',
      longitude: searchParams?.coordinates?.longitude?.toString() || '',
      outputFlags: this.buildOutputFlags(appSession),
      typeCodes: selectedProvider?.providerTypeCodeList ? selectedProvider.providerTypeCodeList : searchParams?.providerTypeCodeList,
      brandCode: metaData?.brandCd,
      locale: metaData?.locale?.toString() || '',
      memberCriteria: this.buildMemberCriteria(appSession, metaData),
      coverageType: SearchRequestUtil.isOutOfNetwork(appSession) ? OUT_OF_NETWORK : undefined,
      isShipDirectToDoor: appSession?.selectedProvider?.isShipDirectToDoor,
      networks: searchParams.plan?.networkList || [],
      procedureCode: searchParams.eycSearchParam?.eycProcedureCriteria?.procedureCode || '',
      pdtKey: selectedProvider?.pdtKey
    };

    if (isPharmacyProvider) {
      detailsRequest.providerName = selectedProvider?.providerName;
      detailsRequest.pharmacyNumber = selectedProvider?.providerIdentifier;
      (detailsRequest.isShipDirectToDoor = selectedProvider?.isShipDirectToDoor), (detailsRequest.coverageTypeCode = searchParams?.coverageTypeCode);
      detailsRequest.city = address?.city ? (searchParams.countyCode ? undefined : searchParams.city) : '';
      detailsRequest.state = address?.state ? searchParams.stateCd?.code : '';
      detailsRequest.postalCode = address?.postalCode ? (isNaN(Number((searchParams?.zip || '').trim())) ? undefined : (searchParams?.zip || '').trim()) : '';
      // Setting public v1 details API payload
      if (!appSession.isSecureState) {
        detailsRequest.addressId = selectedProvider?.pharmacyNumber;
      }
    }
    return detailsRequest;
  }

  private buildOutputFlags(appSession: AppSession): IOutputFlags {
    if (providerOutputFlags) {
      return {
        includeReviewRatings: providerOutputFlags.reviewRatingsFlag,
        imposeAddressConsolidation: providerOutputFlags.imposeAddressConsolidation,
        includeProviderNetworks: providerOutputFlags.includeProviderNetworks,
        includeAllNetworks: !appSession.isEyc && (appSession.hasStandaloneDentalContract || appSession.hasStandaloneVisionContract) && appSession.hasMedicalContracts
      };
    }
    return {} as IOutputFlags;
  }

  private buildMemberCriteria(appSession: AppSession, metaData: IAppMetadata): IMemberCriteria {
    const memberCriteria: IMemberCriteria = {} as IMemberCriteria;

    if (!metaData || appSession.isPlanUnknown) {
      return memberCriteria;
    }

    const { appContract } = metaData;

    if (appContract?.mbrUid) {
      memberCriteria.mbrUid = appContract.mbrUid;
    }

    if (appContract?.contractUid) {
      memberCriteria.contractUid = appContract.contractUid;
    }

    return memberCriteria;
  }

  private transform(providerResponse: IProviderDetailResponse): IProviderOverview {
    if (!providerResponse || !providerResponse.providerDetail) {
      return {} as IProviderOverview;
    }

    const details = {
      acceptsNewPatients: providerResponse.providerDetail.anp?.code,
      accreditations: providerResponse.providerDetail.accreditation ? ProviderDetailsUtils.getAccreditations(providerResponse.providerDetail.accreditation) : undefined,
      adaAccessibilities: providerResponse.providerDetail.location?.adaAccessibilities,
      address: providerResponse.providerDetail.location?.address,
      addressIdentifier: providerResponse.providerDetail.location?.address?.addressId,
      agePrefHighRange: providerResponse.providerDetail.agePrefHighRange,
      agePrefLowRange: providerResponse.providerDetail.agePrefLowRange,
      anp: providerResponse.providerDetail.location?.anp,
      areaOfExpertises: providerResponse.providerDetail.areaOfExpertises,
      countiesList: providerResponse?.providerDetail?.location?.countiesList,
      avatar: providerResponse.providerDetail.avatar,
      careTeamProvider: providerResponse.providerDetail.location?.careTeamProvider,
      dispenseTypeCode: providerResponse.providerDetail.dispenseTypeCode,
      educations: providerResponse.providerDetail.educations,
      ethnicityCodes: providerResponse.providerDetail.ethnicityCodes,
      gender: providerResponse.providerDetail.gender,
      ihspProvider: providerResponse.providerDetail.providerFlags?.includes(ProviderFlag.IHSP),
      isOnlineProviderIndicator: providerResponse.providerDetail.providerFlags?.includes(ProviderFlag.ONLINE_PROVIDER),
      isRegionalProviderIndicator: providerResponse.providerDetail.providerFlags?.includes(ProviderFlag.REGIONAL_PROVIDER),
      isRemoteProviderIndicator: providerResponse.providerDetail.providerFlags?.includes(ProviderFlag.REMOTE_PROVIDER),
      isShipDirectToDoor: providerResponse.providerDetail.isShipDirectToDoor,
      isVirtualProvider: providerResponse.providerDetail.providerFlags?.includes(ProviderFlag.VIRTUAL_PROVIDER),
      languages: providerResponse.providerDetail.location?.languages,
      languageAtPractice: providerResponse.providerDetail.location?.languageAtPractice,
      levelOfCares: providerResponse.providerDetail.levelOfCares,
      licenseNumbers: providerResponse.providerDetail.licenseNumbers,
      licenseTypes: providerResponse.providerDetail.licenseTypes,
      name: {
        firstName: providerResponse.providerDetail.firstName,
        lastName: providerResponse.providerDetail.lastName,
        middleName: providerResponse.providerDetail.middleName,
        providerName: providerResponse.providerDetail.providerName
      },
      nationalProviderIdentifier: providerResponse.providerDetail.location?.npiIds,
      officeFeatureCodeList: providerResponse.providerDetail.location?.officeFeatureCodeList,
      officeHours: providerResponse.providerDetail.location?.officeHours,
      officeServiceList: providerResponse.providerDetail.officeServices,
      participatingNetworks: providerResponse.providerDetail.location?.participatingNetworks,
      patientGenderPreference: providerResponse.providerDetail.patientGenderPreference,
      pdtKey: providerResponse.providerDetail?.pdtKey,
      pcp: providerResponse.providerDetail.pcp,
      pcpInfoList: providerResponse.providerDetail.pcpInfoList,
      performance: providerResponse.providerDetail.location?.performance,
      pharmacyFeatures: providerResponse.providerDetail.pharmacyFeatures,
      providerCategoryCode: providerResponse.providerDetail.categoryCodes?.length ? { code: providerResponse.providerDetail.categoryCodes[0] } : undefined,
      providerIdentifier: providerResponse.providerDetail.id,
      providerName: providerResponse.providerDetail.providerName,
      providerNetworkStatus: providerResponse.providerDetail.location?.networkStatus,
      providerTier: providerResponse.providerDetail.providerTier,
      providerTypeCode: providerResponse.providerDetail.providerTypeCode,
      providerTypeCodeList: providerResponse.providerDetail.typeCodes,
      reviewRatings: providerResponse.providerDetail.location?.reviewRatings,
      sourceSystemName: providerResponse.providerDetail.sourceSystemName,
      stateLicense: providerResponse.providerDetail.stateLicense,
      specialities: providerResponse.providerDetail.specialty ? ProviderDetailsUtils.getSpecialty(providerResponse.providerDetail.specialty) : [],
      teleHealthProvider: providerResponse.providerDetail.providerFlags?.includes(ProviderFlag.TELEHEALTH_PROVIDER),
      titleList: providerResponse.providerDetail.titleList,
      ttyNumber: providerResponse.providerDetail.ttyNumber,
      visibilityRule: providerResponse.providerDetail.visibilityRule,
      visionServiceList: providerResponse.providerDetail.visionServices,
      providerNetworkList: providerResponse.providerDetail?.providerNetworkList
    } as IDetail;

    return { providerDetail: details, ruleList: providerResponse.ruleList } as IProviderOverview;
  }
}
