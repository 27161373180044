import { DOCUMENT } from '@angular/common';
import { Directive, EventEmitter, HostListener, Inject, Input } from '@angular/core';
import { ISSOEError } from '../../../../../common/components/sso/interfaces/iSsoError';
import { ISSOEvent } from '../../../../../common/components/sso/interfaces/iSsoEvent';
import { AppExternalCommands } from '../../../../../common/constants/app-constants';
import { AppEvents } from '../../../../../common/enums/appEvents';
import { SSOErrorType } from '../../../../../common/enums/ssoErrorType';
import { IEventDetail } from '../../../../../common/interfaces/iEventDetail';
import { IWindow } from '../../../../../common/interfaces/iWindow';
import { EventHandler } from '../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../common/values/appSession';

@Directive({ selector: '[app-fc-sso-link-dir]' })
export class SSOLinkDirective {
  @Input() linkTarget: string;
  @Input() ssoType: string;
  @Input() ssoSubType: string;
  @Input() ssoErrorType: string;
  @Input() ssoErrorClass: string;
  @Input() ssoErrorRedirectUrl: string;
  @Input() ssoErrorPageHeader: string;
  @Input() errorMessage: string;
  @Input() uniqueId: string;
  @Input() contractUid: string;
  @Input() newWindow: boolean = false;
  @Input() disclaimerEnabled: boolean = false;
  @Input() disclaimerContent: string;
  @Input() acctKey: string;
  @Input() flexAcctKey: string;
  @Input() acctOptions: string;
  @Input() acctTargetId: string;
  @Input() pdtStrKey: string;
  @Input() baseURL: string;
  @Input() careType: string;
  @Input() ssoKey: string;

  private _ssoEvent: EventEmitter<ISSOEvent> = this._eventHandler.get(AppEvents[AppEvents.SSO]);
  private _pfAppCommandEvent: EventEmitter<IEventDetail> = this._eventHandler.get(AppEvents[AppEvents.PF_APP_COMMAND]);

  constructor(
    private _eventHandler: EventHandler,
    @Inject(DOCUMENT) private _doc: any,
    @Inject(AppSession) private _appSession: AppSession,
    @Inject('Window') private _window: IWindow
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: any) {
    if (this._appSession.isEmulation) {
      this._window.open(`${this.baseURL}/ssoEmulated.html`, '_blank');
    } else if (this._appSession.isSecureState) {
      this._ssoEvent.emit(this.createSSOEvent());
    } else {
      this._pfAppCommandEvent.emit(this.createAppCommandEvent());
    }
    event.preventDefault();
    event.stopPropagation();
  }

  private createSSOEvent(): ISSOEvent {
    return {
      target: this.linkTarget || '_blank',
      type: this.ssoType,
      subType: this.ssoSubType,
      acctKey: this.acctKey || '',
      errorDefinition: this.getErrorDefinition(),
      contractUid: this.contractUid || '',
      newWindow: this.newWindow,
      disclaimerEnabled: this.disclaimerEnabled,
      disclaimerContent: this.disclaimerContent,
      acctOptions: this.acctOptions,
      acctTargetId: this.acctTargetId,
      flexAcctKey: this.flexAcctKey,
      pdtStrKey: this.pdtStrKey || '',
      careType: this.careType,
      ssoKey: this.ssoKey
    };
  }

  private createAppCommandEvent(): IEventDetail {
    return {
      type: AppEvents[AppEvents.PF_APP_CMD_NAV],
      message: AppExternalCommands.RCP_LOGIN.MSG,
      target: AppExternalCommands.RCP_LOGIN.CMD
    } as IEventDetail;
  }

  private getErrorDefinition(): ISSOEError {
    const errDef: ISSOEError = {
      type: parseInt(this.ssoErrorType || '0', 2),
      errorMessage: this.errorMessage
    };

    if (errDef.type === SSOErrorType.OUTSIDE && this.ssoErrorClass) {
      errDef.errorElement = this._doc.querySelector(`.${this.ssoErrorClass} .media-body p`);
    }

    if (errDef.type === SSOErrorType.INLINE && this.uniqueId) {
      errDef.errorElement = this._doc.querySelector(`#errorId${this.uniqueId}`);
    }

    if (errDef.type === SSOErrorType.REDIRECT && this.ssoErrorRedirectUrl) {
      errDef.redirectUrl = this.ssoErrorRedirectUrl;
      errDef.redirectPageHeader = this.ssoErrorPageHeader;
    }

    return errDef;
  }
}
