import { Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { AppNavigations } from '../../../../common/constants/app-navigations';
import { EventHandler } from '../../../../common/services/eventHandler';
import { NavigationService } from '../../../../common/services/navigationService';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-pcp-importance-cmp',
  templateUrl: './pcpImportanceCmp.html'
})
export class PcpImportanceComponent extends BaseComponent {
  careTeamContent = this.content?.common?.careTeams;
  pcpImportancePanelRef: ModalRef<HTMLElement>;
  @ViewChild('pcpImportanceModal') pcpImportanceModal: TemplateRef<HTMLElement>;

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _navigationService: NavigationService,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to open side panel
   */
  openSidePanel() {
    this.pcpImportancePanelRef = this._sidePanel.open('right', this.pcpImportanceModal);
  }

  /**
   * Close the PCP Importance Modal
   */
  onClose() {
    this.pcpImportancePanelRef.close();
  }

  /**
   * Method to navigate to search PCP
   */
  onSearchPcp() {
    this.pcpImportancePanelRef.close();
    this._appSession.searchParams.ableToServePcp = true;
    this._navigationService.navigateByUrl(AppNavigations.FCR_RESULT_PATH);
  }
}
