import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash';
import { Subscription } from 'rxjs';
import { SELECT_DEFAULT_ZERO } from '../../../../common/constants/strategy-search-constant';
import { ControlType } from '../../../../common/enums/controlType';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppConfig } from '../../../../common/values/appConfig';
import { AppSession } from '../../../../common/values/appSession';
import { IRulesResponse } from '../../../../fad/rules/interfaces/iRulesResponse';
import { IProviderSearchDdlValues } from '../../../../fad/search-providers/interfaces/iProviderSearchDdlValues';
import { IPillID } from '../../../../fad/search-providers/interfaces/iProviderType';
import { ISearchParameters } from '../../../../fad/search-providers/interfaces/iSearchParameters';
import { ProviderUtilityService } from '../../../../fad/search-providers/services/providerUtilitySvc';
import { IGroupAffiliations } from '../../../../fad/search-results/interfaces/iGroupAffiliations';
import { ProviderSummaryService } from '../../../../fad/search-results/services/providerSummarySvc';
import { SearchResultsFilterService } from '../../../../fad/search-results/services/searchResultsfilterSvc';
import { FILTER, PILL_ID } from '../../../../fad/search-results/values/providerSearchConstants';
import { CommonUtil } from '../../../../fad/utilities/commonUtil';
import { ResultsUtil } from '../../../../fad/utilities/resultsUtil';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { PROVIDER_TYPE } from '../../../common/constants/common';
import { IOption } from '../../../common/interfaces/iOption';
import { ProviderLocationHandler } from '../../classes/providerLocationHandler';
import { FilterCategory } from '../../constants/result';
import { IAffiliationData, IPillActionValue } from '../../interfaces/iAffiliations';
import { IBaseCode } from '../../interfaces/iProvider';
import { IAppliedSearchFilter } from '../../models/iAppliedSearchFilter';
import { AppliedSearchFilter } from '../../services/appliedSearchFilter';
import { ControlListComponent } from '../control-list/controlListCmp';
import { AddressLocationResponse, IAddressLocationRequest, IAffiliationOption } from '../control-list/interfaces/iAffiliationOption';

@Component({
  moduleId: module.id,
  selector: 'app-fc-affiliation-searchbar-cmp',
  templateUrl: './affiliationSearchCmp.html'
})
export class AffiliationSearchComponent extends BaseComponent implements OnInit, OnDestroy {
  showGroupAffLoader: boolean = false;
  hospitalAffiliationList: IProviderSearchDdlValues;
  ctrlType = ControlType;
  groupAffiliationList: IProviderSearchDdlValues;
  appConfig: AppConfig;
  hospitalSearch: string = '';
  showHospitalAffLoader: boolean = false;
  searchCriteria: ISearchParameters;
  noPreference = this.content?.result?.filter?.noPreference;
  noResultsMsg = this.content?.result?.summaryComponent?.filter.noResultMsg;
  noResultsHospitalSearch = false;
  noResultsMedicalSearch = false;
  pillID = PILL_ID;
  hasHealthSystem: boolean = false;
  isHealthSystemFormatDone: boolean = false;
  medicalGrpAffiliationList: IProviderSearchDdlValues;
  memberMedicalGrpAffltn?: string;
  isMedicalGrpAffiltnSearch = false;
  bindFilter: boolean = true;
  showCptFilters = false;

  @Output() selectedGroupFilter: EventEmitter<IAffiliationData> = new EventEmitter<IAffiliationData>();
  @Output() selectedHospitalFilter: EventEmitter<IAffiliationData> = new EventEmitter<IAffiliationData>();
  @ViewChild(ControlListComponent) controlListCmp: ControlListComponent;
  isGroupAffiliationSearch = false;
  feature: IRulesResponse;
  filter = FILTER;

  medicalGroupSearch: string = '';
  searchAffiliation: Subscription;
  removeFilter: Subscription;
  groupAffiliations: IGroupAffiliations;
  groupAffiliationExpand: boolean = false;
  hospitalAffiliationExpand: boolean = false;
  selectedHospitalAffiliation: string = '';
  selectedGroupAffiliation: string = '';

  @Input() itemChange: string;
  @Input() category: string;
  selectedGroupFilterData: string = '';
  selectedHospitalAffiliationData: string = '';
  appliedFilters: IAppliedSearchFilter[] = [];
  options: Array<IBaseCode> = [];
  hospitalOptionList: Array<string> = [];
  medicalOptionList: Array<string> = [];
  providerDetails: any;

  @Output()
  locationsEmit: EventEmitter<AddressLocationResponse>;
  controlListItem: IAffiliationOption;

  addressLocationResponse: AddressLocationResponse;
  locationsHeadLine: string;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    public _searchResultsFilterService: SearchResultsFilterService,
    private _providerSummaryService: ProviderSummaryService,
    public _appliedSearchFilter: AppliedSearchFilter,
    private _providerLocations: ProviderLocationHandler,
    private _providerUtilityService: ProviderUtilityService
  ) {
    super(_route, _eventHandler, _appSession);
    this.locationsEmit = new EventEmitter<AddressLocationResponse>();
  }

  ngOnInit(): void {
    this.feature = this._appSession.feature;
    this.hasHealthSystem = this._appSession?.hasHealthSystem;
    this.showCptFilters = CommonUtil.isCptSearch(this._appSession);

    if (this._appSession && this._appSession.searchParams) {
      this._appSession.searchParams.includePrimeGroupSearch = true;
      this._appSession.searchParams.includePrimeGroupSearch = true;
      this.searchCriteria = cloneDeep(this._appSession.searchParams);
      if (typeof this._appSession.filterSearchParams === 'undefined') {
        this._appSession.filterSearchParams = cloneDeep(this._appSession.searchParams);
      }
      this.feature = this._appSession.feature;
    }
    this.searchAffiliation = this._searchResultsFilterService.searchInput.subscribe((data) => {
      if (data) {
        this.loadAffiliations(data);
      }
    });
    this.hospitalSearch = this._appSession.hospSearchTerm ? this._appSession.hospSearchTerm : '';
    if (this.hospitalSearch) {
      this.validateSearchTerm(this.pillID.hospitalaffiliation, this.hospitalSearch);
    }
    this.medicalGroupSearch = this._appSession.medicalGrpSearchTerm ? this._appSession.medicalGrpSearchTerm : '';
    if (this.medicalGroupSearch) {
      this.validateSearchTerm(this.pillID.groupaffiliation, this.medicalGroupSearch);
    }
    const appliedCategoryFilters = this._appliedSearchFilter.mapAppliedFilterItemsByCategory(this.category);
    this.appliedFilters = appliedCategoryFilters?.[this.category] || [];
    (this.appliedFilters || []).forEach((appliedfilter: IAppliedSearchFilter) => {
      this._appliedSearchFilter.addInitialAppliedFilterItem(appliedfilter);
    });

    this.removeFilter = this._appliedSearchFilter.appliedFilterRemove.subscribe((data) => {
      if (data) {
        this.removeAppliedFilter(data);
      }
    });
  }

  removeAppliedFilter(data) {
    if (data?.category === 'medicalGroup' && this.selectedGroupAffiliation !== '') {
      this.selectedGroupAffiliation = SELECT_DEFAULT_ZERO;
    } else if (data?.category === 'hospitalAffiliation' && this.selectedHospitalAffiliation !== '') {
      this.selectedHospitalAffiliation = SELECT_DEFAULT_ZERO;
    }
    if (this.controlListCmp) {
      this.controlListCmp.onItemChange(SELECT_DEFAULT_ZERO);
    }
  }

  ngOnDestroy() {
    if (this.searchAffiliation) {
      this.searchAffiliation.unsubscribe();
    }

    if (this.removeFilter) {
      this.removeFilter.unsubscribe();
    }
  }

  /* load the locations for the selected affiliation
   * @param controlListItem: IAffiliationOption
   */

  async loadAffiliationLocations(controlListItem: IAffiliationOption): Promise<void> {
    if (!controlListItem?.addressSummary) {
      return;
    }

    this.controlListItem = controlListItem;
    const addressLocationReq = this.createAddressLocationRequest(controlListItem);
    try {
      this.addressLocationResponse = await this._providerUtilityService.getAffiliationLocationsList(addressLocationReq);
      this.handleAddressLocationResponse(addressLocationReq, this.addressLocationResponse);
    } catch (error) {
      this.onError('AddressLocation', error);
    }
  }

  /* handle the response from the address location service
   * @param addressLocationReq: IAddressLocationRequest
   * @param addressLocationResp: AddressLocationResponse
   */
  private handleAddressLocationResponse(addressLocationReq: IAddressLocationRequest, addressLocationResp: AddressLocationResponse): void {
    if (addressLocationResp?.addressList?.length > 0) {
      this.addressLocationResponse = addressLocationResp;
      this.addressLocationResponse.locationsHeadLine = this.content?.result?.summaryComponent?.resultsHeader?.summaryMsgLine1
        .replace(/{RDS}/gi, addressLocationReq.radius.toString())
        .replace(/{LOC}/gi, ResultsUtil.getLocationText(this._appSession))
        .replace(/{CNT}/gi, addressLocationResp.addressList.length.toString())
        .replace(/{MATCH}/gi, '');
      this.locationsEmit.emit(this.addressLocationResponse);
    }
  }

  /* create the address location request object
   * @param controlListItem: IAffiliationOption
   * @returns IAddressLocationRequest
   */
  private createAddressLocationRequest(controlListItem: IAffiliationOption): IAddressLocationRequest {
    const category = this.category;
    const addressLocationReq: IAddressLocationRequest = {
      providerIdentifier: controlListItem.providerIdentifier,
      providerTypeCode: category === FilterCategory.HOSPITALAFFILIATION ? PROVIDER_TYPE.HOSPITAL : PROVIDER_TYPE.MEDICAL_GROUP,
      numberOfAddresses: controlListItem.addressSummary?.numberOfAddresses ? controlListItem.addressSummary?.numberOfAddresses.toString() : '20'
    };

    if (this._appSession?.searchParams) {
      addressLocationReq.radius = this._appSession.searchParams.distanceNm ? parseInt(this._appSession.searchParams.distanceNm, 10) : 20;
      if (!['0', ''].includes(this._appSession.searchParams?.coordinates?.latitude?.toString()) && !['0', ''].includes(this._appSession.searchParams?.coordinates?.longitude?.toString())) {
        addressLocationReq.latitude = this._appSession.searchParams.coordinates?.latitude.toString();
        addressLocationReq.longitude = this._appSession.searchParams.coordinates?.longitude.toString();
      }
      if (this._appSession.searchParams.countyCode) {
        addressLocationReq.countyCode = this._appSession.searchParams.countyCode;
      }
    }

    return addressLocationReq;
  }

  loadAffiliations(pillAction: IPillActionValue) {
    this.showHospitalAffLoader = false;
    this.showGroupAffLoader = false;
    switch (pillAction.pill.label) {
      case this.pillID.hospitalaffiliation.label:
        if (pillAction.value.length > 2 && this.category === FilterCategory.HOSPITALAFFILIATION) {
          this.showHospitalAffLoader = true;
          this._appSession.hospSearchTerm = pillAction.value;
          this._appSession.isHospAffSearch = true;
          if (this.hospitalSearch) {
            this.getHospitalAffiliation();
          }
        }
        break;
      case this.pillID.groupaffiliation.label:
        if (pillAction.value.length > 2 && this.category === FilterCategory.MEDICALGROUP) {
          this.showGroupAffLoader = true;
          this._appSession.isMedicalAffSearch = true;
          this._appSession.medicalGrpSearchTerm = pillAction.value;
          if (this.medicalGroupSearch) {
            this.getGroupAffiliation();
          }
        }
        break;
      default:
        break;
    }
  }

  validateSearchTerm(pill: IPillID, value: string): void {
    value = value.trim().toLocaleLowerCase();
    const pillValue = { pill, value };
    if (value.length > 2) {
      this._searchResultsFilterService.setSearchInput(pillValue);
    } else {
      if (pill.label === this.pillID.hospitalaffiliation.label) {
        this.hospitalAffiliationList = {} as IProviderSearchDdlValues;
      } else if (pill.label === this.pillID.groupaffiliation.label) {
        this.groupAffiliationList = {} as IProviderSearchDdlValues;
      }
    }
  }

  getHospitalAffiliation() {
    let searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', this.appConfig?.paginationSize.hospitalAffiliationPage, 'H');
    searchRequest = this.removeSpecialty(searchRequest);
    this._providerSummaryService.getAffiliationOptions(searchRequest).then(
      (result) => {
        if (this.hospitalSearch) {
          this.onHospitalAffiliationSuccess(result);
        }
        this.showHospitalAffLoader = false;
      },
      (error) => {
        this.onError('HospitalAffiliation', error);
        this.showHospitalAffLoader = false;
      }
    );
  }

  onHospitalAffiliationSuccess(data) {
    if (data) {
      this.hospitalAffiliationList = CommonUtil.buildDropDownValues(data.affiliationList, 'rb_result_filter_hospitalaffiliation', this.noPreference, SELECT_DEFAULT_ZERO, 'provider', false);
      this._appSession.isHospAffSearch = false;
      if (this.hospitalAffiliationList?.options?.length === 1) {
        this.hospitalAffiliationList = CommonUtil.buildDropDownValues(data.affiliationList, 'rb_result_filter_hospitalaffiliation', '', SELECT_DEFAULT_ZERO, 'provider', false);
        this.noResultsHospitalSearch = true;
      } else {
        this.noResultsHospitalSearch = false;
      }

      if (this.searchCriteria.hospitalAffiliationSelectNm && this.searchCriteria.hospitalAffiliationSelectNm !== SELECT_DEFAULT_ZERO) {
        this.onHospitalAffiliationChange(this.searchCriteria.hospitalAffiliationSelectNm);
        this.selectedHospitalAffiliation = this.searchCriteria.hospitalAffiliationSelectNm;
      } else {
        this.onHospitalAffiliationChange(SELECT_DEFAULT_ZERO);
        this.selectedHospitalAffiliation = SELECT_DEFAULT_ZERO;
      }
    }
  }

  removeSpecialty(searchRequest) {
    if (searchRequest.searchCriteria) {
      delete searchRequest.searchCriteria.specialtyCategoryList;
      delete searchRequest.searchCriteria.subSpecialtyList;
      delete searchRequest.searchCriteria.taxonomyList;
      delete searchRequest.searchCriteria.ableToServeAsPCP;
    }
    return searchRequest;
  }

  onError(type: string, error) {
    throw error;
  }

  onHospitalAffiliationChange($event) {
    this.options = [];
    this.selectedHospitalAffiliationData = '';
    this.hospitalOptionList = [];
    let hospitalAffiliationSubscription;
    if ($event !== SELECT_DEFAULT_ZERO) {
      this.hospitalAffiliationList.controlListData.forEach((data) => {
        if (Object.values(data).includes($event)) {
          this.selectedHospitalAffiliationData = data.providerName;
          this.options = [{ code: this.selectedHospitalAffiliationData, name: this.selectedHospitalAffiliationData }];
          this.selectedHospitalFilter.emit(data);
          hospitalAffiliationSubscription = this._appliedSearchFilter?.getAppliedFilters?.subscribe((dataList) => {
            this.hospitalOptionList = dataList.filter((item) => item.category === 'hospitalAffiliation').map((item) => item.name);
            if (this.hospitalOptionList.length > 1) {
              this._appliedSearchFilter?.removeFilterOption(this.category, this.getOptionObject(this.hospitalOptionList[0], this.hospitalOptionList[0], false));
            }
          });
          if ($event !== '' && this.selectedHospitalAffiliationData) {
            this._appliedSearchFilter.addFilterOption(this.category, this.getOptionObject(this.category, this.selectedHospitalAffiliationData, true));
          }
        }
      });
    } else {
      hospitalAffiliationSubscription = this._appliedSearchFilter?.getAppliedFilters?.subscribe((dataList) => {
        this.hospitalOptionList = dataList.filter((item) => item.category === 'hospitalAffiliation').map((item) => item.name);
        this._appliedSearchFilter?.removeFilterOption(this.category, this.getOptionObject(this.hospitalOptionList[0], this.hospitalOptionList[0], false));
      });
    }

    if (hospitalAffiliationSubscription) {
      hospitalAffiliationSubscription.unsubscribe();
    }
  }

  onGroupAffiliationSuccess(groupAffiliations: IGroupAffiliations): void {
    if (groupAffiliations) {
      this.groupAffiliationList = CommonUtil.buildDropDownValues(groupAffiliations.affiliationList, 'cb_result_filter_groupaffiliation_', this.noPreference, SELECT_DEFAULT_ZERO, 'provider');
      this._appSession.isMedicalAffSearch = false;

      if (this.groupAffiliationList?.options?.length === 1) {
        this.groupAffiliationList = CommonUtil.buildDropDownValues(groupAffiliations.affiliationList, 'cb_result_filter_groupaffiliation_', '', SELECT_DEFAULT_ZERO, 'provider');
        this.noResultsMedicalSearch = true;
      } else {
        this.noResultsMedicalSearch = false;
      }
      if (this.searchCriteria.groupAffiliationSelectNm && this.searchCriteria.groupAffiliationSelectNm !== SELECT_DEFAULT_ZERO) {
        this.onGroupAffiliationChange(this.searchCriteria.groupAffiliationSelectNm);
        this.selectedGroupAffiliation = this.searchCriteria.groupAffiliationSelectNm;
      } else {
        this.onGroupAffiliationChange(SELECT_DEFAULT_ZERO);
        this.selectedGroupAffiliation = SELECT_DEFAULT_ZERO;
      }
    }
  }

  onGroupAffiliationChange($event): void {
    this.options = [];
    this.selectedGroupFilterData = '';
    this.medicalOptionList = [];
    let medicalAffiliationSubscription;
    if ($event !== SELECT_DEFAULT_ZERO) {
      this.groupAffiliationList?.controlListData.forEach((data) => {
        if (Object.values(data).includes($event)) {
          this.selectedGroupFilterData = data.providerName;
          this.options = [{ code: this.selectedGroupFilterData, name: this.selectedGroupFilterData }];
          this.selectedGroupFilter.emit(data);
          medicalAffiliationSubscription = this._appliedSearchFilter?.getAppliedFilters?.subscribe((dataList) => {
            this.medicalOptionList = dataList.filter((item) => item.category === 'medicalGroup').map((item) => item.name);
            if (this.medicalOptionList.length > 1) {
              this._appliedSearchFilter?.removeFilterOption(this.category, this.getOptionObject(this.medicalOptionList[0], this.medicalOptionList[0], false));
            }
          });
          if ($event !== '' && this.selectedGroupFilterData) {
            this._appliedSearchFilter.addFilterOption(this.category, this.getOptionObject(this.category, this.selectedGroupFilterData, false));
          }
        }
      });
    } else {
      medicalAffiliationSubscription = this._appliedSearchFilter?.getAppliedFilters?.subscribe((dataList) => {
        this.medicalOptionList = dataList.filter((item) => item.category === 'medicalGroup').map((item) => item.name);
        this._appliedSearchFilter?.removeFilterOption(this.category, this.getOptionObject(this.medicalOptionList[0], this.medicalOptionList[0], false));
      });
    }
    if (medicalAffiliationSubscription) {
      medicalAffiliationSubscription.unsubscribe();
    }
  }

  getGroupAffiliation() {
    let searchRequest = ResultsUtil.buildProviderRequest(this._appSession, '1', this.appConfig?.paginationSize.groupAffiliationPage, 'G');
    searchRequest = this.removeSpecialty(searchRequest);
    this._providerSummaryService.getAffiliationOptions(searchRequest).then(
      (result) => {
        if (this.medicalGroupSearch) {
          this.onGroupAffiliationSuccess(result);
          this._appSession.isMedicalAffSearch = false;
        }
        this.showGroupAffLoader = false;
      },
      (error: string) => {
        this.onError('GroupAffiliation', error);
        this.showGroupAffLoader = false;
      }
    );
  }

  getOptionObject(value: string, name: string, isChecked: boolean = false): IOption {
    return {
      label: name,
      value: value,
      id: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      name: `input-${this.category?.toLowerCase()}-${value?.toLowerCase()}`,
      trueValue: value,
      falseValue: '',
      isChecked: isChecked
    } as IOption;
  }
}
