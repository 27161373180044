import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { CommonAlertModule } from '../../../../src/app/common/components/common-alert/commonAlertModule';
import { InfoListModule } from '../../../../src/app/common/components/info-list/pfInfoListModule';
import { AssignPcpModule } from '../../../../src/app/common/components/pcp/pfAssignPcpModule';
import { SsoModule } from '../../common/components/sso/ssoModule';
import { CustomPipesModule } from '../../common/pipes/customPipesModule';
import { RatingsSlideOutModule } from '../../common/ratings-slide-out/ratingsSlideOutModule';
import { AssignPcpCtaModule } from '../common/components/shareable/assign-pcp-cta/assignPcpCtaModule';
import { CostSlideOutModule } from '../common/components/shareable/cost-slide-out/costSlideOutModule';
import { EmailModule } from '../common/components/shareable/email/emailModule';
import { GenericSidePanelModule } from '../common/components/shareable/generic-side-panel/genericSidePanelModule';
import { HealthwiseModule } from '../common/components/shareable/healthwise/healthwiseModule';
import { LoaderModule } from '../common/components/shareable/loader/loaderModule';
import { LookUpContainerModule } from '../common/components/shareable/look-up-container/lookUpContainerModule';
import { MemberPlanProgressModule } from '../common/components/shareable/member-plan-progress/memberPlanProgressModule';
import { GenericModalModule } from '../common/components/shareable/modal/genericModalModule';
import { PageAlertsModule } from '../common/components/shareable/page-alert/pageAlertsModules';
import { PaginationModule } from '../common/components/shareable/pagination/paginationModule';
import { SSOModule } from '../common/components/shareable/provider-sso/ssoModule';
import { SearchHeaderModule } from '../common/components/shareable/search-header/SearchHeaderModule';
import { SearchSlideOutModule } from '../common/components/shareable/search-slide-out/searchSlideOutModule';
import { JoinPipe } from '../common/pipes/joinPipe';
import { OfficeHoursPipe } from '../common/pipes/officeHoursPipe';
import { providerHoursPipe } from '../common/pipes/providerHoursPipe';
import { stripTagsHtmlPipe } from '../common/pipes/stripTagsHtmlPipe';
import { TransformMemberPlanProgressPipe } from '../common/pipes/transformMemberPlanProgressPipe';
import { TruncatePipe } from '../common/pipes/truncateNamePipe';
import { ServiceAreaValidationComponent } from '../serviceAreaValidation/serviceAreaValidationCmp';
import { ComapreProviderHandler } from './classes/comapreProviderHandler';
import { AffiliationLocationsComponent } from './components/affiliationLocations/affiliationLocationsCmp';
import { AffiliationSearchComponent } from './components/affiliationSearch/affiliationSearchCmp';
import { ControlListModule } from './components/control-list/controlListModule';
import { DisclaimersModule } from './components/disclaimers/disclaimersModule';
import { DistanceFilterComponent } from './components/distanceFilter/distanceFilter';
import { FilterAccordionComponent } from './components/filterAccordion/filterAccordionCmp';
import { FilterCheckboxListComponent } from './components/filterCheckboxList/filterCheckboxListCmp';
import { FilterDeselectionComponent } from './components/filterDeselection/filterDeselectionCmp';
import { FilterHeaderComponent } from './components/filterHeader/filterHeaderCmp';
import { FilterRadioButtonListComponent } from './components/filterRadioButtonList/filterRadioButtonListCmp';
import { FilterSidePanelComponent } from './components/filterSidePanel/filterSidePanelCmp';
import { OnlinePharmacyLabelCmpModule } from './components/onlinePharmacy/onlinePharmacyLabelCmpModule';
import { ProgramCardComponent } from './components/programCards/programCardCmp';
import { ProgramCardListComponent } from './components/programCards/programCardListCmp';
import { ProgramCardsModalComponent } from './components/programCards/programCardsModal/programCardsModalCmp';
import { ProviderAddressComponent } from './components/provider/provider-address/providerAddressCmp';
import { ProviderPhoneComponent } from './components/provider/provider-phone/providerPhoneCmp';
import { ProviderSpecialtyComponent } from './components/provider/provider-specialty/providerSpecialtyCmp';
import { ProviderCardComponent } from './components/providerCard/providerCardCmp';
import { AssignCareComponent } from './components/providerCardDetails/assignCare/assignCareCmp';
import { InfoListComponent } from './components/providerCardDetails/infoListAlert/infoListCmp';
import { PrintProviderCardComponent } from './components/providerCardDetails/printProviderCard/printProviderCardCmp';
import { ProviderCardDetailsComponent } from './components/providerCardDetails/providerCardDetailsCmp';
import { SelectProviderComponent } from './components/providerCardDetails/selectProvider/selectProviderCmp';
import { ViewLocationsComponent } from './components/providerCardDetails/viewLocations/viewLocationsCmp';
import { ProviderCompareComponent } from './components/providerCompare/providerCompareCmp';
import { ProviderCompareSidePanelComponent } from './components/providerCompareSidePanel/providerCompareSidePanelCmp';
import { ProviderCptCostInfoComponent } from './components/providerCostDetails/providerCptCostInfo/providerCptCostInfoCmp';
import { ProviderPricingDetailContainerCmp } from './components/providerCostDetails/providerPricingDetailContainer/providerPricingDetailContainerCmp';
import { ProviderProcedureCostInfoCmp } from './components/providerCostDetails/providerProcedureCostInfo/providerProcedureCostInfoCmp';
import { ProviderListComponent } from './components/providerList/providerListCmp';
import { ProviderMapComponent } from './components/providerMap/providerMapCmp';
import { InsuranceCardComponent } from './components/providerMoreDetails/insuranceCard/insuranceCardCmp';
import { LicenseCardComponent } from './components/providerMoreDetails/licenseCard/licenseCardCmp';
import { ProviderMoreDetailsComponent } from './components/providerMoreDetails/providerMoreDetailsCmp';
import { ServicesCardComponent } from './components/providerMoreDetails/servicesCard/servicesCardCmp';
import { AffiliationCardComponent } from './components/providerOverview/affiliationCard/affiliationCardCmp';
import { CountiesListComponent } from './components/providerOverview/countiesList/countiesListCmp';
import { CptCardComponent } from './components/providerOverview/cptCard/cptCardCmp';
import { IncorrectDataComponent } from './components/providerOverview/incorrectData/incorrectDataCmp';
import { PcpListComponent } from './components/providerOverview/pcpList/pcpListCmp';
import { ProcedureCardComponent } from './components/providerOverview/procedureCard/procedureCardCmp';
import { ProviderDisclaimerComponent } from './components/providerOverview/providerDisclaimer/providerDisclaimerCmp';
import { ProviderDisclaimerOverviewComponent } from './components/providerOverview/providerDisclaimerOverview/providerDisclaimerOverviewCmp';
import { ProviderOverviewComponent } from './components/providerOverview/providerOverviewCmp';
import { RecognitionCardComponent } from './components/providerOverview/recognitionCard/recognitionCardCmp';
import { SpecialityCardComponent } from './components/providerOverview/specialityCard/specialityCardCmp';
import { PharmacyCardComponent } from './components/providerPharmacy/pharmacyCardCmp';
import { PharmacyFeaturesCardComponent } from './components/providerPharmacy/pharmacyFeaturesCard/pharmacyFeaturesCardCmp';
import { ProviderRewardsComponent } from './components/providerRewards/providerRewardsCmp';
import { ResultComponent } from './components/resultCmp';
import { SelectedProviderComponent } from './components/selectedProvider/selectedProviderCmp';
import { TitleListCardComponent } from './components/titleListCard/titleListCardCmp';
import { VirtualConnectNowComponent } from './components/virtualConnectNowCmp/virtualConnectNowCmp';
import { NoResultComponent } from './no-results/noResultCmp';
@NgModule({
  declarations: [
    ResultComponent,
    ProviderListComponent,
    ProviderCardComponent,
    ProgramCardListComponent,
    ProgramCardComponent,
    ProviderMapComponent,
    ProviderSpecialtyComponent,
    ProviderPhoneComponent,
    ProviderAddressComponent,
    TransformMemberPlanProgressPipe,
    FilterHeaderComponent,
    FilterSidePanelComponent,
    ProgramCardsModalComponent,
    FilterAccordionComponent,
    FilterDeselectionComponent,
    FilterCheckboxListComponent,
    ProviderCardDetailsComponent,
    ProviderCompareComponent,
    ProviderCompareSidePanelComponent,
    ProviderOverviewComponent,
    ProviderDisclaimerComponent,
    ProviderDisclaimerOverviewComponent,
    ProviderMoreDetailsComponent,
    PcpListComponent,
    CptCardComponent,
    ProcedureCardComponent,
    RecognitionCardComponent,
    SpecialityCardComponent,
    CountiesListComponent,
    InsuranceCardComponent,
    ServicesCardComponent,
    AffiliationCardComponent,
    LicenseCardComponent,
    AssignCareComponent,
    SelectedProviderComponent,
    ProviderPricingDetailContainerCmp,
    ProviderCptCostInfoComponent,
    VirtualConnectNowComponent,
    ProviderProcedureCostInfoCmp,
    InfoListComponent,
    ProviderRewardsComponent,
    PharmacyCardComponent,
    PrintProviderCardComponent,
    TruncatePipe,
    OfficeHoursPipe,
    providerHoursPipe,
    JoinPipe,
    AffiliationSearchComponent,
    AffiliationLocationsComponent,
    ViewLocationsComponent,
    DistanceFilterComponent,
    stripTagsHtmlPipe,
    PharmacyFeaturesCardComponent,
    ServiceAreaValidationComponent,
    SelectProviderComponent,
    NoResultComponent,
    IncorrectDataComponent,
    SelectProviderComponent,
    FilterRadioButtonListComponent,
    TitleListCardComponent
  ],
  exports: [ResultComponent, TruncatePipe, OfficeHoursPipe, JoinPipe, providerHoursPipe, stripTagsHtmlPipe, ServiceAreaValidationComponent, NoResultComponent],
  imports: [
    UxSharedModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AssignPcpCtaModule,
    SearchHeaderModule,
    CostSlideOutModule,
    HealthwiseModule,
    MemberPlanProgressModule,
    SearchSlideOutModule,
    DisclaimersModule,
    PaginationModule,
    EmailModule,
    LookUpContainerModule,
    InfoListModule,
    CommonAlertModule,
    AssignPcpModule,
    PageAlertsModule,
    GenericSidePanelModule,
    GenericModalModule,
    ControlListModule,
    RatingsSlideOutModule,
    SSOModule,
    LoaderModule,
    OnlinePharmacyLabelCmpModule,
    SsoModule,
    CustomPipesModule
  ],
  providers: [TransformMemberPlanProgressPipe, ComapreProviderHandler]
})
export class ResultModule {}
