import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { RIGHT } from '../../../../../../../common/constants/app-constants';
import { CONTINUE, IEvent } from '../../../../../../../common/interfaces/iEvent';
import { EventHandler } from '../../../../../../../common/services/eventHandler';
import { AppSession } from '../../../../../../../common/values/appSession';
import { BaseComponent } from '../../../../core/baseCmp';

@Component({
  moduleId: module.id,
  selector: 'app-fc-ca-hmo-slider-alert-cmp',
  templateUrl: './caHmoSliderAlertCmp.html'
})
export class CaHmoSliderAlertComponent extends BaseComponent {
  modalRef: ModalRef<HTMLElement> = null;
  @ViewChild('SlideoutPanelTemplate') panelTmpltRef: TemplateRef<HTMLElement>;
  @Output() continue: EventEmitter<IEvent> = new EventEmitter<IEvent>();

  caHmoSliderAlertContent = this.content?.common?.caHmoPanelComponent;

  constructor(
    private _route: ActivatedRoute,
    private _eventHandler: EventHandler,
    private _appSession: AppSession,
    private _sidePanel: SidePanel
  ) {
    super(_route, _eventHandler, _appSession);
  }

  /**
   * Method to open side panel
   */
  openSidePanel() {
    this.modalRef = this._sidePanel.open(RIGHT, this.panelTmpltRef);
  }

  /**
   * Method to close side panel
   */
  onClose() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  /**
   * Method to handle continue button click event
   */
  onContinue() {
    this.continue.emit({ name: CONTINUE, value: this.continue });
    this.onClose();
  }
}
