import { TitleCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalRef, SidePanel } from '@anthem/uxd/modal';
import { EventHandler } from '../../../../common/services/eventHandler';
import { AppSession } from '../../../../common/values/appSession';
import { BaseComponent } from '../../../common/components/core/baseCmp';
import { HIGH_RANGE_AGE, LOW_RANGE_AGE } from '../../constants/compare';
import { ICompareProvider, ICompareResponse, IOfficeFeatureCodeList, IRecognitions } from '../../interfaces/iCompareProvider';

@Component({
  moduleId: module.id,
  selector: 'app-fc-provider-compare-side-panel-cmp',
  templateUrl: './providerCompareSidePanelCmp.html'
})
export class ProviderCompareSidePanelComponent extends BaseComponent {
  compareProvidersContent = this.content?.result?.compareProvidersComponent;
  pharmacyContent = this.content?.result?.pharmacy;
  providerDetailsContent: ICompareResponse[];

  @ViewChild('providerCompareSidePanelModal') providerCompareSidePanelModal: TemplateRef<HTMLElement>;
  sidePanelRef: ModalRef<HTMLElement, TemplateRef<HTMLElement>>;
  expandedIndex: number = -1;
  providerConentIndex: number = -1;
  showCompareResults: boolean = true;
  showRatings: boolean = false;
  showRecognitions: boolean = false;
  showTeleHealth: boolean = false;
  vitalsUrl: SafeResourceUrl;
  panelHeading: string;
  providerRecongnitionsData: IRecognitions;
  officeFeatureLabels = [];
  providerMapData = [];

  constructor(
    @Inject(AppSession)
    private _appSession: AppSession,
    private _eventHandler: EventHandler,
    route: ActivatedRoute,
    public _sidePanel: SidePanel,
    private _sanitizer: DomSanitizer,
    private _changeDetector: ChangeDetectorRef,
    private _titlecasePipe: TitleCasePipe
  ) {
    super(route, _eventHandler, _appSession);
  }

  /** Open side panel */
  openSidePanel(direction, data) {
    this.resetModalState();
    this.providerDetailsContent = data;
    this.getOfficeFeaturesData(data);
    this.getProviderMapData(data);
    this.sidePanelRef = this._sidePanel.open(direction, this.providerCompareSidePanelModal);
  }

  getProviderMapData(providers) {
    this.providerMapData = [];
    providers.forEach((provider) => {
      const mapData = [
        {
          addressSummary: {
            latitude: provider?.locations[0]?.address?.latitude,
            longitude: provider?.locations[0]?.address?.longitude
          }
        }
      ];

      this.providerMapData.push(mapData);
    });
  }

  /** Function to reset the state of the modal */
  resetModalState(): void {
    this.showRatings = false;
    this.showCompareResults = true;
    this.showRecognitions = false;
    this.showTeleHealth = false;
  }

  /** Close side panel */
  onClose() {
    this.sidePanelRef.close();
    this._changeDetector.detectChanges();
  }
  /** Function to execute on click of Replace button */
  onReplace() {
    this.onClose();
  }

  /** Functions to get provider name text */
  getProviderName(provider: ICompareProvider): string {
    return this.getProviderTitles(provider?.name?.titleList, this.titleCase(provider?.networkRegisteredName), this.titleCase(provider?.name?.providerName));
  }

  titleCase(name: string) {
    return this._titlecasePipe.transform(name);
  }

  getProviderTitles(titleList: Array<string>, networkRegisteredName: string, providerName: string): string {
    const result = titleList?.slice(0, 4).join(', ');
    const remainingCount = titleList?.length - 4;
    return networkRegisteredName ? networkRegisteredName : providerName + (result ? `, ${result}${remainingCount > 0 ? `, +${remainingCount}` : ''}` : '');
  }

  /** Function to get Network status text */
  getNetworkStatusText(codes: Array<string>) {
    let networkStatusText = '';
    codes?.forEach((code) => {
      switch (code) {
        case this.compareProvidersContent?.labels?.medical:
          networkStatusText += this.compareProvidersContent?.labels?.medicalNetwork + ', ';
          break;
        case this.compareProvidersContent?.labels?.dental:
          networkStatusText += this.compareProvidersContent?.labels?.dentalNetwork + ', ';
          break;
        case this.compareProvidersContent?.labels?.vision:
          networkStatusText += this.compareProvidersContent?.labels?.visionNetwork + ', ';
          break;
      }
    });
    networkStatusText = networkStatusText.slice(0, -2);
    return networkStatusText;
  }

  /** Function to get Age preference text */
  getAgePreference(lowRange: string, highRange: string): string {
    let agePreference = '';
    const lowRangeAge = lowRange !== undefined && lowRange !== '' ? Number(lowRange) : LOW_RANGE_AGE;
    const highRangeAge = highRange !== undefined && highRange !== '' ? Number(highRange) : LOW_RANGE_AGE;
    if (lowRangeAge > highRangeAge) {
      return agePreference;
    }
    if (lowRangeAge > LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
      agePreference = lowRangeAge.toString() + '-' + highRangeAge.toString() + this.compareProvidersContent?.labels?.yearsText;
    } else if (lowRangeAge > LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
      agePreference = lowRangeAge.toString() + ' ' + this.compareProvidersContent?.labels?.yearOlderText;
    } else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge > LOW_RANGE_AGE && highRangeAge < HIGH_RANGE_AGE) {
      agePreference = this.compareProvidersContent?.labels?.birthToText + ' ' + highRangeAge.toString() + ' ' + this.compareProvidersContent?.labels?.yearsText;
    } else if (lowRangeAge <= LOW_RANGE_AGE && highRangeAge >= HIGH_RANGE_AGE) {
      agePreference = this.compareProvidersContent?.labels?.birthOlderText;
    }
    return agePreference;
  }

  /** Functiton toget speciality text */
  getSpecialtyText(specialty): string {
    let value = '';
    if (specialty && specialty.specialtyCodeName) {
      value = specialty.specialtyCodeName;
      if (specialty.boardCertificationCode) {
        switch (specialty.boardCertificationCode.toUpperCase()) {
          case this.compareProvidersContent?.labels?.boardCertified:
            value += this.compareProvidersContent?.labels?.boardCertifiedMsg;
            break;
          case this.compareProvidersContent?.labels?.notBoardCertified:
            value += this.compareProvidersContent?.labels?.notBoardCertifiedMsg;
            break;
          case this.compareProvidersContent?.labels?.naBoardCertified:
            value += this.compareProvidersContent?.labels?.naBoardCertifiedMsg;
            break;
        }
      }
    }
    return value;
  }

  /** Function to get gender preference text */
  getGenderPreference(genderPreferences: string): string {
    let genderPreference = '';
    if (typeof genderPreferences !== 'undefined' && genderPreferences !== null && genderPreferences.trim() !== '') {
      switch (genderPreferences.toUpperCase()) {
        case this.compareProvidersContent?.labels?.male:
        case this.compareProvidersContent?.labels?.maleTxt:
          genderPreference = this.compareProvidersContent?.labels?.maleOnlyText;
          break;
        case this.compareProvidersContent?.labels?.female:
        case this.compareProvidersContent?.labels?.femaleTxt:
          genderPreference = this.compareProvidersContent?.labels?.femaleOnlyText;
          break;
        case this.compareProvidersContent?.labels?.both:
        case this.compareProvidersContent?.labels?.bothTxt:
          genderPreference = this.compareProvidersContent?.labels?.bothText;
          break;
        case this.compareProvidersContent?.labels?.all:
        case this.compareProvidersContent?.labels?.allTxt:
          genderPreference = this.compareProvidersContent?.labels?.allText;
          break;
      }
    }
    return genderPreference;
  }

  /** Function to get provider address text */
  getProviderAddress(address) {
    let addressText = '';
    let distance = '';
    addressText += address.addressOne + ' ' + address.addressTwo;
    distance = address.distance ? parseFloat(address.distance).toFixed(2) : '';
    if (distance) {
      return addressText + ' (' + distance + this.compareProvidersContent?.labels?.miles + ')';
    } else {
      return addressText;
    }
  }

  /** Function to toggle expanded state for affiliations */
  onAffliateDropdownChanges(index: number, providerIndex: number) {
    if (this.providerConentIndex === providerIndex && this.expandedIndex === index) {
      this.expandedIndex = this.providerConentIndex = -1;
    } else {
      this.expandedIndex = index;
      this.providerConentIndex = providerIndex;
    }
  }

  checkNum(review: string): boolean {
    return !isNaN(Number(review));
  }

  getVitalsUrl() {
    return this.vitalsUrl;
  }

  gotoRating(provider) {
    this.panelHeading = this.getProviderName(provider);
    const providerPdtStrKey = provider.reviewRatings?.[0]?.pdtStrKey;
    if (providerPdtStrKey) {
      this.vitalsUrl = this._sanitizer.bypassSecurityTrustResourceUrl(this._appSession.appConfig?.providerFinder.vitalsBase + providerPdtStrKey + '/en');
    }
    this.showRatings = true;
    this.showCompareResults = false;
    this.showRecognitions = false;
    this.showTeleHealth = false;
  }

  gotoCompare() {
    this.showRatings = false;
    this.showCompareResults = true;
    this.showRecognitions = false;
    this.showTeleHealth = false;
  }

  showProviderRecognitions(provider) {
    this.providerRecongnitionsData = provider?.performance?.recognition;
    this.panelHeading = this.getProviderName(provider);
    this.showRatings = false;
    this.showCompareResults = false;
    this.showRecognitions = true;
    this.showTeleHealth = false;
  }

  getOfficeFeaturesData(data) {
    data.forEach((provider) => {
      const officeFeatures = provider?.locations[0]?.address?.officeFeatureCodeList || [];
      officeFeatures
        .filter((officeFeature) => officeFeature?.name)
        .forEach((officeFeature) => {
          if (!this.officeFeatureLabels.includes(officeFeature.name)) {
            this.officeFeatureLabels.push(officeFeature.name);
          }
        });
    });
  }

  getOfficeFeatureValue(featureList: Array<IOfficeFeatureCodeList>, featureName: string): string {
    let labelText = this.compareProvidersContent?.labels?.notAvailable;
    if (featureList && featureList.length > 0) {
      const filterdFeature = featureList.filter((x) => x.name === featureName);
      if (filterdFeature && filterdFeature.length > 0 && filterdFeature[0].value !== '') {
        labelText = filterdFeature[0].value;
      }
    }
    return labelText;
  }

  getAccessibilityNames(list) {
    return list.map((obj) => obj.name).join(', ');
  }

  /** Function to go to telehealth */
  gotoTelehealth(provider) {
    this.panelHeading = this.getProviderName(provider);
    this.showRatings = false;
    this.showCompareResults = false;
    this.showRecognitions = false;
    this.showTeleHealth = true;
  }

  /** Function to print data */
  onPrint() {
    window.print();
  }

  /** Function to check for Diamond provider */
  isDiamondProvider(provider): boolean {
    if (!provider?.performance?.recognition) {
      return false;
    } else {
      for (let recognition of provider?.performance?.recognition) {
        if (recognition.code.name === this.compareProvidersContent?.labels?.diamondProvider) {
          return true;
        }
      }
      return false;
    }
  }
}
