import { Inject, Injectable } from '@angular/core';
import { CommonUtil } from '../../../fad/utilities/commonUtil';
import { PROVIDER_TYPE } from '../../common/constants/common';
import { ICompareRequest, ICompareResponse, IDetailsParameters, IPlanList } from '../interfaces/iCompareProvider';
import { IProvider } from '../interfaces/iProvider';
import { ProviderCompareService } from '../services/providerCompareSvc';
import { AppSession } from './../../../common/values/appSession';

@Injectable()
export class ComapreProviderHandler {
  selectedProvidersDetailsResponse: ICompareResponse[];
  constructor(
    private _compareService: ProviderCompareService,
    @Inject(AppSession)
    private _appSession: AppSession
  ) {}

  getProviders(providers: IProvider[]): Promise<any> {
    if (!providers || providers.length === 0) {
      return Promise.resolve([]);
    }

    return this._compareService.getCompare(this.buildCompareRequest(providers));
  }

  private buildCompareRequest(providers: IProvider[]) {
    const req = {} as ICompareRequest;
    req.providerDetail = [];
    const plan: IPlanList = {
      identifier: this._appSession.searchParams.plan.identifier,
      isNationalPlan: this._appSession.searchParams.plan.isNationalPlan
    };

    const netwrkList = [];
    for (const network of this._appSession.searchParams.plan.networkList || []) {
      const net = {
        code: network
      };
      netwrkList.push(net);
    }

    (providers || []).forEach((provider) => {
      const request: IDetailsParameters = {
        providerIdentifier: provider?.pharmacyNumber ? provider?.pharmacyNumber : provider.providerIdentifier,
        addressIdentifier: provider?.pharmacyNumber ? provider?.pharmacyNumber : provider.location.address.addressId,
        brandCode: this._appSession.metaData.brandCd,
        state: undefined,
        distance: this._appSession.searchParams.distanceNm ? parseInt(this._appSession.searchParams.distanceNm.trim(), 10) : 0,
        planStateCode: this._appSession.searchParams.plan.stateCd,
        rxChoiceNtwkIndicator: '',
        specialtyCategoryList: this._appSession.searchParams.specialtySelectNm,
        networkList: netwrkList,
        alphaPrefix: this._appSession?.searchParams?.plan?.alphaPrefix,
        location: {
          latitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.latitude : '',
          longitude: this._appSession.searchParams.coordinates ? this._appSession.searchParams.coordinates.longitude : ''
        },
        planList: new Array(plan),
        locale: (this._appSession.metaData && this._appSession.metaData.locale && this._appSession.metaData.locale.toString()) || ''
      };
      if (provider.providerTypeCodeList) {
        request.providerTypeCodeList = provider.providerTypeCodeList;
      }

      const selectedContract = this._appSession.appState?.selectedEligibilityProduct?.selectedContract;
      if (selectedContract) {
        request.mbrUid = selectedContract.mbrUid;
        request.contractUid = selectedContract.contractUid;
      }
      if (this._appSession.metaData && this._appSession.metaData.appContract && this._appSession.metaData.appContract.contractUid && !this._appSession.isPlanUnknown) {
        request.contractUid = this._appSession.metaData.appContract.contractUid;
      }
      if ((request.providerTypeCodeList || []).includes(PROVIDER_TYPE.PHARMACY)) {
        request.pharmacyName = provider?.providerName;
        request.pharmacyNumber = request.providerIdentifier;
        request.coverageTypeCode = this._appSession.searchParams.coverageTypeCode;

        if (this._appSession.searchParams.stateCd && this._appSession.searchParams.stateCd.code) {
          request.state = this._appSession.searchParams.stateCd.code;
        }
        if (this._appSession.searchParams.zip) {
          request.zipCode = isNaN(Number(this._appSession.searchParams.zip.trim())) ? undefined : this._appSession.searchParams.zip.trim();
        }
        if (this._appSession.searchParams.city) {
          request.city = this._appSession.searchParams.countyCode ? undefined : this._appSession.searchParams.city;
        }
      }
      const hasHcid = this._appSession && this._appSession.searchParams && this._appSession.searchParams.identificationNumber && this._appSession.searchParams.identificationNumber !== '';
      if ((CommonUtil.isMemberSecure(this._appSession) || hasHcid) && this._appSession.isFutureCoverage) {
        request.isFutureCoverage = this._appSession.isFutureCoverage;
      }
      request.isShipDirectToDoor = provider?.isShipDirectToDoor;
      request.pdtKey = provider?.pdtKey;
      req.providerDetail.push(request);
    });
    return req;
  }
}
