import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UxSharedModule } from '@anthem/uxd-shared';
import { AssignPcpNavigationService } from './services/assignPcpNavigationSvc';
import { MbrAgeLimitApiHelper } from './services/mbrAgeLimitApiHlpr';
import { MemberPcpDetailsApiHelper } from './services/memberPcpDetailsApiHlpr';
import { MemberPcpDetailsService } from './services/memberPcpDetailsSvc';
import { MemberPcpValidateApiHelper } from './services/memberPcpValidateApiHlpr';
import { ModifyPcpApiHelper } from './services/modifyPcpApiHlpr';
import { PcpReasonCodesApiHelper } from './services/pcpReasonCodesApiHlpr';
import { ProviderPcpInfoApiHelper } from './services/providerPcpInfoApiHlpr';
import { Stepper } from './services/stepper';

@NgModule({
  declarations: [],
  exports: [],
  imports: [CommonModule, UxSharedModule.forRoot(), FormsModule],
  providers: [
    ModifyPcpApiHelper,
    MemberPcpValidateApiHelper,
    MemberPcpDetailsApiHelper,
    MemberPcpDetailsService,
    AssignPcpNavigationService,
    Stepper,
    ProviderPcpInfoApiHelper,
    PcpReasonCodesApiHelper,
    MbrAgeLimitApiHelper
  ]
})
export class AssignPcpModule {}
