import { Injectable } from '@angular/core';
import { HttpClientService } from '../../../common/services/httpClientService';
import { AppUtility } from '../../../common/utilities/appUtil';
import { AppSession } from '../../../common/values/appSession';
import { apiNameList } from './../../../../environments/api-name-list';
import { HttpMethod } from './../../../common/enums/httpMethodEnum';
import { BaseService } from './../../../common/services/baseService';
import { IMemberPcpDetailsRequest, IMemberPcpDetailsResponse } from './../models/iMemberPcpDetails';

@Injectable({ providedIn: 'root' })
export class MemberPcpDetailsService extends BaseService {
  constructor(
    public appSession: AppSession,
    public appUtility: AppUtility,
    public httpClientSvc: HttpClientService
  ) {
    super(appSession, appUtility, httpClientSvc);
  }

  getMemberPcpDetails(reqObj: IMemberPcpDetailsRequest): Promise<IMemberPcpDetailsResponse> {
    return this.httpClientSvc.request({
      cancellable: true,
      method: HttpMethod.Post,
      url: this.baseURL + apiNameList.restApi.memberPcpDetails,
      data: reqObj,
      headers: this.getHeaders()
    });
  }
}
